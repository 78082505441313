import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import eng from "../Internationalization/English.json";
import arb from "../Internationalization/Arabic.json";
import { LANGUAGES } from "../Constants/Constants";

export const defaultNS = LANGUAGES.ENGLISH_ABBRIVATION;
export const resources = {
  en: {
    common: eng,
  },
  ar: {
    common: arb,
  },
} as const;

i18next.use(initReactI18next).init({
  lng: LANGUAGES.ENGLISH_ABBRIVATION,
  resources,
});

export default i18next;
