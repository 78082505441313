import { Card, MenuItem, Select } from "@mui/material";
import "./ManageReports.scss";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import { API_RESPONSE, COMMON_CONSTANTS, TOASTER_CONFIGS } from "../../Utilities/Constants/Constants";
import  moment from 'moment';
import { toast } from "react-toastify";
import { ERROR_MESSAGES } from "../../Utilities/Constants/Validations";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const ManageReports = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [userStartDate, setUserStartDate] = useState<any>(null);
  const [userEndDate, setUserEndDate] = useState<any>(null);
  const [ paymentSpType, setPaymentSpType] = useState(" ");
  const [ spType, setSpType] = useState(" ");
  const [ userType, setUserType] = useState(" ");

  const [paymentData, setPaymentData] = useState<any>();
  const [specifiedPaymentData, setSpecifiedPaymentData] = useState<any>();

  const [ paymentDownloadLink1, setPaymentDownloadLink1 ]= useState("");
  const [ paymentDownloadLink2, setPaymentDownloadLink2 ]= useState("");

  /* Handle start date change */
  const handleDateChange = (newDate: any) => {
    setStartDate(newDate);
  };

  useEffect(()=>{
     if(startDate && endDate && paymentSpType !==' '){
      getTotalPaymentData();
     }else if(startDate && endDate){
      getTotalPaymentData();
     }
  },[startDate,endDate,paymentSpType]);


  const getTotalPaymentData = ()=>{
    let providerType:any;
    if(paymentSpType===" "){
      providerType='';
    }else{
      providerType= paymentSpType=== "tutor" ? 1 : ("recreational_trainer" ? 2 : 3);
    }
    axiosInstance.get(API_END_POINTS.PAYMENT_PIE_DATA + `?from_date=` + `${moment(startDate).format("YYYY-MM-DD")}` + `&to_date=` + `${moment(endDate).format("YYYY-MM-DD")}` +`&service_provider_type=` + providerType)
    .then((response: any) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        if(providerType===''){
          setPaymentData(response.data.result);
          setPaymentDownloadLink1(response.data.result.report_file[COMMON_CONSTANTS.ZERO]);
        }else{
          setSpecifiedPaymentData(response.data.result);
          setPaymentDownloadLink2(response.data.result.report_file[COMMON_CONSTANTS.ZERO])
        }
      }
    })
  };

  /* Download payment report */
  const downloadPaymentReport = ()=>{
    if(startDate && endDate){
        axiosInstance.post(API_END_POINTS.PAYMENT_REPORT,{
            from_date: moment(startDate).format("YYYY-MM-DD"),
            to_date:moment(endDate).format("YYYY-MM-DD"),
            period:1
          }).then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
              const downloadAnchor = document.createElement('a');
              downloadAnchor.href = response.data.result.data[0];
              downloadAnchor.download = 'file.csv'; 
              downloadAnchor.click();
              toast.success(response.data.message, TOASTER_CONFIGS);
            }
          })
    }else{
        if(!startDate){
            toast.error(ERROR_MESSAGES.START_DATE);
        }else{
            toast.error(ERROR_MESSAGES.END_DATE);
        }
        
    }
     
  };

  /* Download user report */
  const downloadUserReport = ()=>{
    if(userStartDate && userEndDate && spType!==' '){

        axiosInstance.post(API_END_POINTS.USER_REPORT,{
            type:spType,
            user:userType === " "? "" : userType,
             from_date: moment(userStartDate).format("YYYY-MM-DD"),
            to_date:moment(userEndDate).format("YYYY-MM-DD"),
        }).then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                const downloadAnchor2 = document.createElement('a');
                downloadAnchor2.href = response.data.result.data[0];
                downloadAnchor2.download = 'file.csv';
                downloadAnchor2.click();
                toast.success(response.data.message, TOASTER_CONFIGS);
            }
          })
    }
    
  };

  const chartOptions = {
    chart: {
      type: 'pie',
      marginBottom: 100
    },
    title: {
      text: 'Payment Type Classification',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false, // Disable data labels (values inside slices)
        },
        connectorWidth: 0, // Disable connectors (arrow markers)
      },
    },
    series: [
      {
        name: 'Payment Report',
        data: [
          ['Online Payment', paymentData?.online_payment],
          ['Wallet Payment', paymentData?.wallet_payment],
          // ['Cancelled Slots', dashboardData?.completed_slots],
        ],

        showInLegend: true
      },
    ],
    exporting: {
      enabled: true,
      menuItemDefinitions: {
        // Custom definition
        viewFullscreen: {
          text: "fullscreen",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              text: "DownloadXLSX",
              onclick() {
                // this.downloadXLSX();
              },
            },
            {
              text: "DownloadCSV",
              onclick() {
                // this.getCSV();
              },
            },
          ],
        },
      },
    },
  };
  const chartOptions2 = {
    chart: {
      type: 'pie',
      marginBottom: 100
    },
    title: {
      text: 'Provider based Payment Types',
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false, // Disable data labels (values inside slices)
        },
        connectorWidth: 0, // Disable connectors (arrow markers)
      },
    },
    series: [
      {
        name: 'Payment Report',
        data: [
          ['Online Payment', specifiedPaymentData?.online_payment],
          ['Wallet Payment', specifiedPaymentData?.wallet_payment],
          // ['Cancelled Slots', dashboardData?.completed_slots],
        ],

        showInLegend: true
      },
    ],
    exporting: {
      enabled: true,
      menuItemDefinitions: {
        // Custom definition
        viewFullscreen: {
          text: "fullscreen",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              text: "DownloadXLSX",
              onclick() {
                // this.downloadXLSX();
              },
            },
            {
              text: "DownloadCSV",
              onclick() {
                // this.getCSV();
              },
            },
          ],
        },
      },
    },
  };

  const onDownloadPayementReport = (i:any)=>{
    let downloadLink = i === `${COMMON_CONSTANTS.ONE}` ? paymentDownloadLink1 : paymentDownloadLink2;
    const downloadAnchor = document.createElement('a');
    downloadAnchor.href = downloadLink;
    downloadAnchor.download = 'file.csv'; 
    downloadAnchor.click();
    toast.success( `Report downloaded successfully`, TOASTER_CONFIGS);
  };


  return (
    <div className="manage-reports-wrapper">
      <Card className="card-wrap">
        <div className="category_tabs_filter_container">
          <div className="filter-wrap">
            <h5>Payments Report</h5>
            <div className="price_filter_label_wrapper">
                <div className="calender-row">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="label-calender">
                    <label>Start Date:</label>
                    <DatePicker
                  className="date-picker-one"
                  value={startDate}
                  maxDate={new Date()}
                  onChange={handleDateChange}
                />
                    </div>
                    <div className="label-calender">
                    <label>End Date:</label>
                    <DatePicker
                  className="date-picker-two"
                  value={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  onChange={(e: any) => {
                    setEndDate(e);
                  }}
                />
                    </div>
                
              </LocalizationProvider>

              <Select className="report-select" value={paymentSpType} onChange={(e:any)=>{setPaymentSpType(e.target.value);setUserType(" ")}}>
               <MenuItem value={" "}>Service provider type</MenuItem>
                <MenuItem value={"tutor"}>Tutor</MenuItem>
                <MenuItem value={"recreational_trainer"}>Personal Trainer</MenuItem>
                <MenuItem value={"recreational_camp"}>Activity Camp</MenuItem>
              </Select>
                </div>
             

             {/*  <div className="filter_action_buttons">
                  <button className="apply-btn" onClick={downloadPaymentReport}>{"Download Report"}</button>
              </div> */}
            </div>
          </div>
        </div>

      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6">
        { paymentData && <div className="pie-chart-wrapper">
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          <button className="apply-btn" onClick={()=>onDownloadPayementReport('1')}>Download Report</button>
          </div>}
       
        </div>
        <div className="col-sm-12 col-xs-12 col-md-6 col-lg-6">
        { specifiedPaymentData && <div className="pie-chart-wrapper">
          <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
          <button className="apply-btn" onClick={()=>onDownloadPayementReport('2')}>Download Report</button>
          </div>}
        </div>
      </div>
        
      </Card>
      <Card className="card-wrap">
        <div className="category_tabs_filter_container">
          <div className="filter-wrap">
            <h5>User Report</h5>
            <div className="price_filter_label_wrapper">
                <div className="calender-row">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div className="label-calender">
                    <label>Start Date:</label>
                    <DatePicker
                      className="date-picker-one"
                      value={userStartDate}
                      maxDate={new Date()}
                      onChange={(e: any) => {
                        setUserStartDate(e);
                      }}
                    />
                  </div>
                  <div className="label-calender">
                    <label>End Date:</label>
                    <DatePicker
                  className="date-picker-two"
                  value={userEndDate}
                  minDate={userStartDate}
                  maxDate={new Date()}
                  onChange={(e: any) => {
                    setUserEndDate(e);
                  }}
                />
                    </div>
               
              </LocalizationProvider>
              <Select className="report-select" value={spType} onChange={(e:any)=>{setSpType(e.target.value);setUserType(" ")}}>
               <MenuItem value={" "}>Service provider type</MenuItem>
               <MenuItem value={"user"}>User</MenuItem>
                <MenuItem value={"tutor"}>Tutor</MenuItem>
                <MenuItem value={"recreational_trainer"}>Personal Trainer</MenuItem>
                <MenuItem value={"recreational_camp"}>Activity Camp</MenuItem>
              </Select>
              {spType !==' ' && spType==="user" && 
              <Select className="report-select" value={userType} onChange={(e:any)=>setUserType(e.target.value)}>
                <MenuItem value={" "}>User type</MenuItem>
                <MenuItem value={"parent"}>Parent</MenuItem>
                <MenuItem value={"child"}>Child</MenuItem>
              </Select>}
                </div>
             

              <div className="filter_action_buttons">
                <button className="apply-btn" onClick={downloadUserReport}>{"Download Report"}</button>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ManageReports;
