import "./CourseCategoryList.scss";
import CategoriesList from "../../../Shared/ManageCategories/CategoriesList/CategoriesList";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";

const CourseCategoryList = () => {
  return (
    <>
      <CategoriesList
        apiEndpoint={API_END_POINTS.GET_CATEGORY_LIST}
        courseType={VALIDATION_CONSTANT.COURSE}
      />
    </>
  );
};

export default CourseCategoryList;
