import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import KeywordsList from "../../../Shared/ManageKeywords/KeywordsList/KeywordsList";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";

const RecreationalKeywords = () => {
    return(
        <>
        <KeywordsList apiEndpoint={API_END_POINTS.GET_RECREATIONAL} courseType={VALIDATION_CONSTANT.RECREATION}/>
        </>
    )
};

export default RecreationalKeywords;