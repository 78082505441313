import { Dialog, DialogTitle, DialogContent, Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, I18_TRANSLATION_COMMON, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import "./CreatePrograms.scss";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";
import { ERROR_MESSAGES } from "../../../Utilities/Constants/Validations";

const CreatePrograms = (props:any) =>{
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [ programType, setProgramType ] = useState(" ");
    const [ fieldValue, setFieldValue ] = useState("");
    const [ formSubmitted,setFormSubmitted] = useState(API_RESPONSE.FALSE);
    const onClose = () => {
        setProgramType(" ");
        setFieldValue("");
        setFormSubmitted(API_RESPONSE.FALSE);
        return props.dialogStatus(API_RESPONSE.FALSE);
      };



      useEffect(()=>{
        if(props.data.mode === "Edit"){
            setProgramType(props.data.value);
            setFieldValue(props.data.value === SERVICES_STATUS.DEGREE_TYPE ? props.data.payload.programs_offered : ( props.data.value === SERVICES_STATUS.FIELD_OF_STUDY ? props.data.payload.programs_name : props.data.payload.sub_program_name))
        }else{
            setProgramType(" ");
            setFieldValue("");
            setFormSubmitted(API_RESPONSE.FALSE);
        }
      },[props])

      const setPlaceHolder = ()=>{
         if(programType === SERVICES_STATUS.DEGREE_TYPE){
           return "Enter Degree type"
         }else if(programType === SERVICES_STATUS.FIELD_OF_STUDY){
            return "Enter field of study"
         }else{
            return "Enter the specification"
         }
      };


      const submitApiCall = (API:any,payload:any) => {
        if(props.data.mode === "Edit"){
            payload.id = props.data.payload.id;
            axiosInstance
            .put(API, payload)
            .then((res) => {
              if (res.data.status === API_RESPONSE.TRUE) {
                toast.success(res.data.message, TOASTER_CONFIGS);
                setProgramType(" ");
                setFieldValue("");
                setFormSubmitted(API_RESPONSE.FALSE);
                onClose();
              }
            });
        }else{
            axiosInstance
            .post(API, payload)
            .then((res) => {
              if (res.data.status === API_RESPONSE.TRUE) {
                toast.success(res.data.message, TOASTER_CONFIGS);
                setProgramType(" ");
                setFieldValue("");
                setFormSubmitted(API_RESPONSE.FALSE);
              }
            });
        }
        
      }

      const onSubmit = () => {
        setFormSubmitted(API_RESPONSE.TRUE);
        if(nullUndefinedEmptyCheck(programType) && nullUndefinedEmptyCheck(fieldValue)){
            let data ={};
            if(programType === SERVICES_STATUS.DEGREE_TYPE){
               data={
                   "programs_offered": fieldValue
               }
               submitApiCall(API_END_POINTS.DEGREE_TYPE,data);
            }else if(programType === SERVICES_STATUS.FIELD_OF_STUDY){
               data={
                   "programs_name": fieldValue
               };
               submitApiCall(API_END_POINTS.FIELD_OF_STUDY,data);
            }else{
               data={
                   "sub_program_name": fieldValue
               };
               submitApiCall(API_END_POINTS.SPECIFICATION,data);
            }
        }
        
      }

      
    return (
        <div className="program-create-wrapper">
          <Dialog
        className="program-dialog"
        open={props.modalState}
        sx={{ "& .MuiDialog-paper": "cus-dialog" }}
      >
        <DialogTitle className="title-wrap">
          {"Create Program"}
          <div className="close-wrap">
            <CloseIcon className="close-icon" onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent>

           <div className="create-wrapper">
              <Select className="program-select w-100" value={programType} onChange={(e:any)=>{
                setProgramType(e.target.value);
              }}>
                 <MenuItem value={" "}>{"Please select the field"}</MenuItem>
                <MenuItem value={SERVICES_STATUS.DEGREE_TYPE}>{SERVICES_STATUS.DEGREE_TYPE}</MenuItem>
                <MenuItem value={SERVICES_STATUS.FIELD_OF_STUDY}>{SERVICES_STATUS.FIELD_OF_STUDY}</MenuItem>
                <MenuItem value={SERVICES_STATUS.SPECIFICATION}>{SERVICES_STATUS.SPECIFICATION}</MenuItem>
              </Select>
              <div className="error-message-wrap">
                      { formSubmitted && checkValueNullUndefinedEmpty(programType) ? (
                        <div className="errorMessage">
                          {`${ERROR_MESSAGES.TYPE_FIELD}`}
                        </div>
                      ) : null}
                    </div>

              <input className="program-input w-100 mt-3 p-2" placeholder={setPlaceHolder()} value={fieldValue} onChange={(e:any)=>{setFieldValue(e.target.value)}}/>
              <div className="error-message-wrap">
                      { formSubmitted && checkValueNullUndefinedEmpty(fieldValue) ? (
                        <div className="errorMessage">
                          {`${ERROR_MESSAGES.ENTER_VALUE}`}
                        </div>
                      ) : null}
                    </div>
              <div className="submit-btn-wrapper">
                <button type="button" className="btn btn-primary program-cancel-btn" onClick={onClose}>Cancel</button>
                <button type="button" className="btn btn-primary program-save-btn" onClick={onSubmit}>Save</button>
              </div>
           </div>
        </DialogContent>
        </Dialog>
        </div>
    )
};

export default CreatePrograms;