import "./ResetPassword.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ERROR_MESSAGES, REGEX } from "../../Utilities/Constants/Validations";
import loginBanner from "../../../Assets/Login/login_banner.svg";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import LanguageIcon from "@mui/icons-material/Language";
import {
  TOASTER_CONFIGS,
  API_RESPONSE,
  TOKENS,
  LANGUAGES,
  LOGIN_FIELDS,
  LAYOUTS,
  ADMIN_ROLES,
  ROUTES,
  VALIDATION_CONSTANT,
  I18_TRANSLATION_COMMON,
} from "../../Utilities/Constants/Constants";
import { useNavigate, useParams } from "react-router-dom";
import passwordVisible from "../../../Assets/Login/password_visible.svg";
import passwordInvisible from "../../../Assets/Login/password_invisible.svg";
import learnAndPlay from "../../../Assets/Login/phygital_Logo.svg";
import passwordTick from "../../../Assets/global/password_tick.svg";
import passwordCross from "../../../Assets/global/password_cross.svg";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [language, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);
  const { id }: any = useParams();
  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const strengthCard = useRef<HTMLDivElement>(null);
  const [oneSpecialCheck, setOneSpecialCheck] = useState(false);
  const [oneCapitalCheck, setOneCapitalCheck] = useState(false);
  const [oneSmallCheck, setOneSmallCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [minimumLengthCheck, setMinimumLengthCheck] = useState(false);
  const [maximumLengthCheck, setMaximumLengthCheck] = useState(false);

  const [showNewPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);

  useEffect(() => {
    let paramData = id.split("-**-");
    setUid(paramData[0]);
    setToken(paramData[1]);
  }, [id]);

  /* Defining validation using yup */
  const resetValidationSchema = yup.object({
    newPassword: yup
      .string()
      .required(ERROR_MESSAGES.PASSWORD_REQUIRED)
      .min(8, ERROR_MESSAGES.MINIMUM_PASSWORD)
      .max(16, ERROR_MESSAGES.MAXIMUM_PASSWORD)
      .matches(REGEX.PASSWORD, ERROR_MESSAGES.INVALID_PASSWORD)
      .trim(),
    confirmPassword: yup
      .string()
      .required(ERROR_MESSAGES.CONF_PASS_MSG)
      .min(8, ERROR_MESSAGES.MINIMUM_PASSWORD)
      .max(16, ERROR_MESSAGES.MAXIMUM_PASSWORD)
      .matches(REGEX.PASSWORD, ERROR_MESSAGES.INVALID_PASSWORD)
      .trim()
      .oneOf([yup.ref("newPassword")], ERROR_MESSAGES.PASSWORD_MATCH),
  });

  /*  Triggers while changing language */
  function onLanguageChange(lang: any) {
    i18n.changeLanguage(`${lang}`);
    setCurrentLanguage(
      lang === LANGUAGES.ARABIC_ABBRIVATION
        ? LANGUAGES.ARABIC
        : LANGUAGES.ENGLISH
    );
  }

  /* Handling submit & API call */
  function onHandleSubmit(values: any) {
    let payload = {
      password: values.newPassword,
      confirm_password: values.confirmPassword,
      uid: uid,
      token: token,
    };
    axiosInstance
      .post(API_END_POINTS.RESET_PASSWORD, payload)
      .then((res: any) => {
        toast.success(res.data.message, TOASTER_CONFIGS);
        navigate(ROUTES.LOGIN);
      })
      .catch((error: any) => {
        toast.error(error);
      });
  }
  const hidePasswordStrength = () => {
    const elementStyle = strengthCard.current?.style;
    elementStyle!.setProperty("display", "none");
  };
  const showPasswordStrength = () => {
    const elementStyle = strengthCard.current?.style;
    elementStyle!.setProperty("display", "block");
  };
  const checkPasswordStrength = (event: any) => {
    let passwordValue = event.target.value;
    setOneCapitalCheck(REGEX.UPPER_CASE_REGEX.test(passwordValue));
    setOneSmallCheck(REGEX.LOWER_CASE_REGEX.test(passwordValue));
    setOneNumberCheck(REGEX.NUMBER_REGEX.test(passwordValue));
    setOneSpecialCheck(REGEX.SPECIAL_CHARACTERS_REGEX.test(passwordValue));
    setMinimumLengthCheck(passwordValue.length > 7);
    setMaximumLengthCheck(passwordValue.length < 16);
  };
  return (
    <>
      <Formik
        validationSchema={resetValidationSchema}
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        onSubmit={(values) => {
          onHandleSubmit(values);
        }}
      >
        {(props) => {
          return (
            <div
              className="resetMainWrapper"
              dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
            >
              <div className="background_color_wrapper">
                <div className="background_fix">
                  <div className="background_color_container">
                    <div className="yellow_round"></div>
                    <div className="violet_round"></div>
                    <div className="blue_round"></div>
                  </div>
                </div>
              </div>

              <div className="login_wrapper">
                <img
                  className="login_banner"
                  src={loginBanner}
                  alt="Learn and Play"
                />
                <div className="resetPassContainer">
                  <div className="lang_switch_header">
                    <div className=" lang_switch_contianer">
                      <LanguageIcon className="language_icon" />
                      <button
                        className="language_button"
                        onClick={() => {
                          onLanguageChange(
                            language === LANGUAGES.ENGLISH
                              ? LANGUAGES.ARABIC_ABBRIVATION
                              : LANGUAGES.ENGLISH_ABBRIVATION
                          );
                        }}
                      >
                        {language === LANGUAGES.ENGLISH
                          ? LANGUAGES.ARABIC
                          : LANGUAGES.ENGLISH}
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="learn_and_play_logo">
                      <img
                        className="learn_and_play"
                        src={learnAndPlay}
                        alt="Learn and Play"
                      />
                    </div>
                    <div className="reset_card_container">
                      <div className="reset_wrapper">
                        <div className="resetContainer ">
                          <div className="resetTextContainer">
                            <div className="reset_password_title">
                              {t("Reset Your Password")}
                            </div>
                            <div className="inputBox">
                              <div className="input_error_container">
                                <div className="input-wrap">
                                  <input
                                    className="passInputForm"
                                    name="newPassword"
                                    placeholder={t("New Password") || ""}
                                    onChange={(event: any) => {
                                      props.handleChange(event);
                                      checkPasswordStrength(event);
                                    }}
                                    onBlur={(event: any) => {
                                      props.handleBlur(event);
                                      hidePasswordStrength();
                                    }}
                                    onFocus={showPasswordStrength}
                                    type={showNewPassword ? "text" : "password"}
                                  />

                                  <img
                                    className={
                                      language === LANGUAGES.ENGLISH
                                        ? "password_visibility_rp"
                                        : "password_visibility_arabic_rp"
                                    }
                                    onClick={() => {
                                      setNewPassword(!showNewPassword);
                                    }}
                                    src={
                                      showNewPassword
                                        ? passwordInvisible
                                        : passwordVisible
                                    }
                                    alt=""
                                  />
                                </div>

                                {props.touched.newPassword &&
                                  props.errors.newPassword && (
                                    <p className="validation">
                                      {t(
                                        `${
                                          props.touched.newPassword &&
                                          props.errors.newPassword
                                        }`
                                      )}
                                    </p>
                                  )}
                                <div
                                  ref={strengthCard}
                                  className="password_strength_wrapper"
                                >
                                  <div className="password_title">
                                    Password must meet the following
                                    requirements:
                                  </div>
                                  <div className="password_status_wrapper">
                                    {oneSpecialCheck ? (
                                      <img
                                        className="password_tick"
                                        src={passwordTick}
                                        alt="Learn and Play"
                                      />
                                    ) : (
                                      <img
                                        className="password_cross"
                                        src={passwordCross}
                                        alt="Learn and Play"
                                      />
                                    )}
                                    <div
                                      className={
                                        oneSpecialCheck
                                          ? "password_satus_pass"
                                          : "password_satus_fail"
                                      }
                                    >
                                      At least 1 Special character
                                    </div>
                                  </div>
                                  <div className="password_status_wrapper">
                                    {oneCapitalCheck ? (
                                      <img
                                        className="password_tick"
                                        src={passwordTick}
                                        alt="Learn and Play"
                                      />
                                    ) : (
                                      <img
                                        className="password_cross"
                                        src={passwordCross}
                                        alt="Learn and Play"
                                      />
                                    )}
                                    <div
                                      className={
                                        oneCapitalCheck
                                          ? "password_satus_pass"
                                          : "password_satus_fail"
                                      }
                                    >
                                      At least 1 Uppercase letter
                                    </div>
                                  </div>
                                  <div className="password_status_wrapper">
                                    {oneSmallCheck ? (
                                      <img
                                        className="password_tick"
                                        src={passwordTick}
                                        alt="Learn and Play"
                                      />
                                    ) : (
                                      <img
                                        className="password_cross"
                                        src={passwordCross}
                                        alt="Learn and Play"
                                      />
                                    )}
                                    <div
                                      className={
                                        oneSmallCheck
                                          ? "password_satus_pass"
                                          : "password_satus_fail"
                                      }
                                    >
                                      At least 1 Lowercase letter
                                    </div>
                                  </div>
                                  <div className="password_status_wrapper">
                                    {oneNumberCheck ? (
                                      <img
                                        className="password_tick"
                                        src={passwordTick}
                                        alt="Learn and Play"
                                      />
                                    ) : (
                                      <img
                                        className="password_cross"
                                        src={passwordCross}
                                        alt="Learn and Play"
                                      />
                                    )}
                                    <div
                                      className={
                                        oneNumberCheck
                                          ? "password_satus_pass"
                                          : "password_satus_fail"
                                      }
                                    >
                                      At least 1 number
                                    </div>
                                  </div>
                                  <div className="password_status_wrapper">
                                    {minimumLengthCheck ? (
                                      <img
                                        className="password_tick"
                                        src={passwordTick}
                                        alt="Learn and Play"
                                      />
                                    ) : (
                                      <img
                                        className="password_cross"
                                        src={passwordCross}
                                        alt="Learn and Play"
                                      />
                                    )}
                                    <div
                                      className={
                                        minimumLengthCheck
                                          ? "password_satus_pass"
                                          : "password_satus_fail"
                                      }
                                    >
                                      Be at least 8 characters
                                    </div>
                                  </div>
                                  <div className="password_status_wrapper">
                                    {minimumLengthCheck &&
                                    maximumLengthCheck ? (
                                      <img
                                        className="password_tick"
                                        src={passwordTick}
                                        alt="Learn and Play"
                                      />
                                    ) : (
                                      <img
                                        className="password_cross"
                                        src={passwordCross}
                                        alt="Learn and Play"
                                      />
                                    )}
                                    <div
                                      className={
                                        minimumLengthCheck && maximumLengthCheck
                                          ? "password_satus_pass"
                                          : "password_satus_fail"
                                      }
                                    >
                                      Be at most 16 characters
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="input_error_container">
                                <div className="input-wrap">
                                  <input
                                    className="passInputForm"
                                    name="confirmPassword"
                                    placeholder={t("Confirm Password") || ""}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                  />

                                  <img
                                    className={
                                      language === LANGUAGES.ENGLISH
                                        ? "password_visibility_rp"
                                        : "password_visibility_arabic_rp"
                                    }
                                    onClick={() => {
                                      setConfirmPassword(!showConfirmPassword);
                                    }}
                                    src={
                                      showConfirmPassword
                                        ? passwordInvisible
                                        : passwordVisible
                                    }
                                    alt=""
                                  />
                                </div>

                                {props.touched.confirmPassword &&
                                  props.errors.confirmPassword && (
                                    <p className="validation">
                                      {t(
                                        `${
                                          props.touched.confirmPassword &&
                                          props.errors.confirmPassword
                                        }`
                                      )}
                                    </p>
                                  )}
                              </div>
                              <div className="reset_button_wrapper">
                                <button
                                  className="forgot-back-button"
                                  onClick={() => {
                                    navigate("/login");
                                  }}
                                >
                                  {t("Back to Login")}
                                </button>
                                <button
                                  disabled={
                                    props.isValid &&
                                    Object.keys(props.touched).length != 0
                                      ? false
                                      : true
                                  }
                                  className={
                                    props.isValid &&
                                    Object.keys(props.touched).length != 0
                                      ? "resetSubmitBtn"
                                      : "resetSubmitBtnDisabled"
                                  }
                                  type="submit"
                                  onClick={() => {
                                    props.handleSubmit();
                                  }}
                                >
                                  {t("Submit")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ResetPassword;
