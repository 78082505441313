import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from "@mui/material";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import "./ExpertsList.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  I18_TRANSLATION_COMMON,
  API_RESPONSE,
  NUMBER_CONSTANTS,
} from "../../../Utilities/Constants/Constants";
import edit from "../../../../Assets/global/edit.svg";
import { checkEmpty } from "../../../Services/Helpers/Helper";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import CreateExpert from "../CreateExpert/CreateExpert";

const ExpertsList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [expertsList, setExpertsList] = useState([]);
  const [hasProducts, setHasProducts] = useState(API_RESPONSE.TRUE);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalCount] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    getExpertsList();
  }, [page, rowPerPage]);

  const onRowChange = (event: any) => {
    setPage(NUMBER_CONSTANTS.ONE);
    setRowPerPage(parseInt(event.target.value));
};

const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Getting experts list */
  const getExpertsList = () => {
    axiosInstance.get(API_END_POINTS.EXPERTS_LIST+
      "?page=" +
      page +
      "&limit=" +
      rowPerPage).then((response: any) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setExpertsList(response.data.result.data);
        setTotalCount(response.data.result.total_page);
        setHasProducts(checkEmpty(response.data.result.data));
      }
    });
  };

  /* Handling dialog close */
  const onCloseDialog = () => {
    setDialogOpenStatus(false);
    getExpertsList();
  };

  return (
    <div className="experts-list-wrapper">
      <CreateExpert
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
      />
      <div className="table-wrapper">
        <div className="submit-btn-wrapper">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={() => {
              setDialogOpenStatus(true);
            }}
          >
            Add Educational Expert
          </button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell>{t("First Name")}</TableCell>
                <TableCell>{t("Last Name")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Phone Number")}</TableCell>
                {/* <TableCell>{t("Actions")}</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {expertsList.map((expertData: any, index: any) => {
                return (
                  <>
                    <TableRow key={index + 1}>
                      <TableCell>{page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}</TableCell>
                      <TableCell>{expertData.firstname}</TableCell>
                      <TableCell>{expertData.lastname}</TableCell>
                      <TableCell>{expertData.email}</TableCell>
                      <TableCell>{expertData.phone_number}</TableCell>
                      {/* <TableCell>
                            <img
                              onClick={() => {
                              navigate(`/manage-roles/${expertData.id}`)
                              }}
                              className="view_edit_icons"
                              src={edit}
                              alt="Learn and Play"
                            />
                        </TableCell> */}
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
          {!hasProducts && <NoProducts />}
        </TableContainer>
        {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ExpertsList;
