import {
  ERROR_MESSAGES_MODELS,
  REGEX_MODELS,
  THEMEPARK_MODELS,
} from "../Models/ValidationModels";

const REGEX: REGEX_MODELS = {
  EMAIL: new RegExp(
    "^[_a-z0-9-]+(\\.[_a-z0-9-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,4})$"
  ),
  PASSWORD: new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])"
  ),
  COMMISSION_REGEX: new RegExp("^(d{1,2}(.d{1,2})?|100(.0{1,2})?)$"),
  PHONE_VALIDATION: new RegExp("^(?:\\+971|00971|0)?(?:2|3|4|6|7|9)\\d{7}$"),
  ENGLISH_INPUT_VALIDATION: new RegExp("^[a-zA-Z\\s]*$"), //"^[a-zA-Z0-9\\s]+$"
  ARABIC_INPUT_VALIDATION: new RegExp("^[\\u0600-\\u06FF\\s]+$"),
  DECIMAL_VALIDATION: new RegExp("^\\d+(\\.\\d*)?$"),
  DECIMAL_RESTRICTION: new RegExp(/^\d*\.?\d{0,2}$/),
  URL_VALIDATION: new RegExp(
    "((https?):\\/\\/)?(www\\.)?[a-z0-9-]+(\\.[a-z]{2,}){1,3}(\\:[0-9]{1,5})?(\/[-a-zA-Z0-9@:%._\+~#=]*)*?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$"
  ),
  LATITUDE_VALIDATION: new RegExp("^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?)$"),
  LONGITUDE_VALIDATION: new RegExp(
    "^[-+]?((1[0-7]|[1-9])?\\d(\\.\\d+)?|180(\\.0+)?)$"
  ),
  UPPER_CASE_REGEX: new RegExp("^(?=.*?[A-Z])"),
  LOWER_CASE_REGEX: new RegExp("^(?=.*?[a-z])"),
  NUMBER_REGEX: new RegExp("^(?!-).*[0-9].*$"),
  SPECIAL_CHARACTERS_REGEX: new RegExp("^(?=.*?[#?!@$%^&*-])"),
};
const ERROR_MESSAGES: ERROR_MESSAGES_MODELS = {
  EMAIL_REQUIRED: "Please enter the E-Mail ID",
  INVALID_EMAIL: "Please enter a valid E-mail ID",
  PASSWORD_REQUIRED: "Please enter the Password",
  MINIMUM_PASSWORD: "Password should be more than 8 characters",
  MAXIMUM_PASSWORD: "Password should be less than 16 characters",
  INVALID_PASSWORD:
    "Password should contain at least 1 uppercase, 1 lowercase letter, 1 number, 1 special character",
  OLD_PASS_MSG: "Old Password should not be empty",
  NEW_PASS_MSG: "New Password should not be empty",
  CONF_PASS_MSG: "Confirm Password should not be empty",
  CATEGORY_NAME: "Course category name is required",
  ARABIC_CATEGORY_NAME: "Arabic Course category name is required",
  MAX_PRICE_MSG: "Maximum price is required",
  MIN_PRICE_MSG: "Minimum price is required",
  MIN_EXP_MSG: "Minimum Experience is required",
  MAX_EXP_MSG: "Maximum Experience is required",
  MIN_GRADE: "Minimum grade is required",
  MAX_GRADE: "Maximum grade is required",
  MIN_YEAR: "Minimum year is required",
  MAX_YEAR: "Maximum year is required",
  FIRST_NAME: "First Name is required",
  LAST_NAME: "Last Name is required",
  PHONE_NUMBER: "Phone Number is required",
  PHONE_NUMBER_FORMAT: "Invalid Phone number format",
  PASSWORD_MATCH: "New password and confirm password doesn't match",
  MIN_MAX_MATCH: "Maximum price should be greater than minimum price",
  EXPERIENCE_MATCH: "Maximum experience should be greater than minimum experience",
  GRADE_MISMATCH: "Maximum grade should be greater than minimum grade",
  YEAR_MISMATCH: "Maximum year should be greater than minimum year",
  COMMISSION_ERR: "Commission percentage should not be greater than 100",
  TAG_VALIDATION: "Tag name already exists!",
  COMMISSION_REQUIRED: "Commission percentage is required",
  AMOUNT_FORMAT: "Please enter correct amount format",
  EVENT_NAME: "Please enter event name",
  PERCENTAGE_FORMAT: "Please enter a correct percentage format",
  ACCEPT_SUBJECT: "Please accept/reject all subjects",
  OFFLINE_INCENTIVE: "On-premises incentive is required",
  ONLINE_INCENTIVE: "Online incentive is required",
  UPLOAD_IMAGE: "Please upload card image",
  ADD_TEXT: "Please add card text",
  CAROUSAL_IMAGE: "Please upload carousal image",
  CAROUSAL_TEXT: "Please add carousal text",
  CAROUSAL_TEXT_ARABIC: "Please add carousal text Arabic",
  VERIFICATION_ERROR: "You don't have permission for proceeding to next step",
  PRINCIPAL_NAME: "Please enter valid principal name",
  FREE_SLOTS: "Free slots should be less than buying slots",
  START_DATE: "Please select start date",
  END_DATE: "Please select end date",
  SELECT_ORDER: "Please select carousal order",
  TYPE_FIELD: "Please select the type field",
  ENTER_VALUE: "Please enter the valid data",
  MAXIMUM_UPLOAD: "Maximum upload limit exceeded",
  DISCOUNT_PRICE: "Discount price should not be more than ticket price"
};

const ADD_SCHOOLS_ERROR_MESSAGES: any = {
  // ONLY_NUMBERS: "Please enter a number",
  INVALID_PHONE_NUMBER: "Please enter valid phone number",
  PHONE_NUMBER_REQUIRED: "Please enter a phone number",
  SCHOOL_NAME_REQUIRED: "Please enter the school name",
  FOUNDED_YEAR_REQUIRED: "Please enter the founded year",
  LOCATION_REQUIRED: "Please enter the location",
  CURRICULUM_REQUIRED: "Please enter the curriculum",
  SCHOOL_IMAGES_REQUIRED: "Please Upload the images of the school",
  WEBLINK_OF_SCHOOL_REQUIRED: "Please enter the web link of school",
  EMAIL_REQUIRED: "Please enter the email",
  INVALID_EMAIL: "Please enter a valid email",
  PRINCIPAL_REQUIRED: "Please enter the principal name",
  DESCRIPTION_REQUIRED: "Please enter the description",
  GRADE_YEAR_REQUIRED: "Please enter the grade year",
  TUITION_FEES_REQUIRED: "Please enter the tuition fees",
  OPTIONAL_SERVICE_FEES_REQUIRED: "Please enter the optional service fees",
  ENROLLMENT_FEES_REQUIRED: "Please enter the enrollment fees",
  RE_ENROLLMENT_FEES_REQUIRED: "Please enter the re-enrollment fees",
  APPLICATION_FEES_REQUIRED: "Please enter the application fees",
  TOTAL_FEES_REQUIRED: "Please enter the total fees",
  DISCOUNT_ON_TUITION_FEES_REQUIRED:
    "Please enter the discount on tuition fees",
  START_OF_THE_ACADEMIC_YEAR_REQUIRED:
    "Please enter the start of the academic year",
  END_OF_THE_ACADEMIC_YEAR_REQUIRED:
    "Please enter the end of the academic year",
  INVALID_END_YEAR: "End year cannot be less than start year",
  INVALID_DATE: "End date cannot be less than start date",
  WINTER_BREAK_START_DATE_REQUIRED: "Please enter the winter break start date",
  WINTER_BREAK_END_DATE_REQUIRED: "Please enter the winter break end date",
  SPRING_BREAK_START_DATE_REQUIRED: "Please enter the spring break end date",
  SPRING_BREAK_END_DATE_REQUIRED: "Please enter the spring break end date",
  SUMMER_BREAK_START_DATE_REQUIRED: "Please enter the summer break start date",
  SUMMER_BREAK_END_DATE_REQUIRED: "Please enter the summer break end date",
  OTHER_HOLIDAYS_REQUIRED: "Please enter the other holidays",
  PUBLIC_HOLIDAYS_REQUIRED: "Please enter the public holidays",
  DSIB_INSPECTION_REPORT_REQUIRED: "Please enter the DSIB inspection report",
  DSIB_RATING_REQUIRED: "Please select the DSIB rating",
  RATING_REQUIRED: "Please select the rating",
  RANKING_REQUIRED: "Please enter the ranking",
  END_DATE_GREATER: "End Date should be greater than start date",
  WINTER_END_DATE_GREATER:
    "Winter break End Date should be greater than start date",
  SPRING_END_DATE_GREATER:
    "Spring break End Date should be greater than start date",
  SUMMER_END_DATE_GREATER:
    "Summer break End Date should be greater than start date",
  TEST_SUPERIOR: "superior",
  APPLICATION_PROCESS: "Please add application process",
  ELIGIBILITY_CRITERIA: "Please add eligibility criteria",
  FEE_STRUCTURE: "Please add the fee structure",
  ONLY_NUMBERS: "Please enter valid number",
  VALID_RANKING: "Please enter valid ranking",
  VALID_STUDENTS: "Please enter valid students count",
  MIN_GRADE: "Please select minimum year/grade",
  MAX_GRADE: "Please select maximum year/grade",
  EMIRATES: "Please select the emirate",
  CITY: "Please select the city",
  COUNTRY: "Please enter the country"
};

const EDIT_UNIVERSITY_ERROR_MESSAGE: any = {
  UNIVERSITY_NAME: "Please enter the university name",
  QUALITY_ASSURANCE: "Please enter the Quality Assurance",
  LOCATION_REQUIRED: "Please enter the Location",
  WEBSITE_ADDRESS: "Please enter the Website link",
  WEBSITE_ADDRESS_CHECK: "Please enter valid URL",
  PRINCIPAL: "Please enter the principal name",
  EMAIL_REQUIRED: "Please enter a valid email",
  DEGREES_AND_FEES: "Please enter the degrees and fees",
  PROGRAMS_OFFERED: "Please add the programs offered",
  TEACHING_RATING: "Please enter teaching rating",
  EMPLOYABILITY_RATING: "Please enter the employability rating",
  RESEARCH: "Please enter the reaserch",
  RATING: "Please enter the rating",
  RANKING: "Please enter the ranking",
  UNIVERSITY_IMAGES: "Please Upload the images of the university",
  INTERNATIONALIZATION_RATING: "Please enter the interntionalization rating",
  FACILITIES_RATING: "Please enter the facilities rating",
  PROGRAMME_STRENGTH_RATING: "Please enter the programme strength rating",
  INCLUSIVENESS_RATING: "Please enter the inclusiveness rating",
  ENVIRONMENTAL: "Please enter the environmental rating",
  IMPACT_RATING: "Please enter the impact rating",
  OVERALL_RATING: "Please enter the overall rating",
  NO_OF_STUDENTS: "Please enter the number of students",
  TOTAL_GRADUATES: "Please enter the total graduates",
  NO_OF_EMIRATES: "Please enter the number of emirates",
  STUDENTS: "Please enter the studente field",
  NO_OF_EXPATS: "Please enter the number of expacts",
  MALE: "Please enter the male count",
  FEMALE: "Please enter the female count",
  TRANSGENDER: "Please enter the transgender count",
  TOTAL_EDUCATORS: "Please enter the total educators",
  MAXIMUM_RATING: "Rating should be maximum 5",
  VALID_URL: "Please enter valid URL",
  IMAGE_LIMIT: "Should upload atleast 5 university images",
  VALID_FORMAT: "Please enter valid format",
  INVALID_PHONE_NUMBER: "Please enter valid phone number",
  PHONE_NUMBER_REQUIRED: "Please enter a phone number",
  TOTAL_COURSES: "Please enter the total courses",
  SHANGAI_RATING: "Please enter the shangai ranking",
  GLOBAL_RATING: "Please enter the global ranking",
  AVAILABLE_MEDIUM: "Please enter the language of instruction",
  ACADEMIC_CALENDER: "Please upoad the academic calender",
  DESCRIPTION_REQUIRED: "Please enter the description",
  VALID_GLOBAL_RANKING: "Please enter valid global ranking",
  VALID_SHANGAI_RANKING: "Please enter valid shangai ranking",
  VALID_TOTAL_COURSES: "Please enter valid total courses",
};

const THEMEPARK_MESSAGES: THEMEPARK_MODELS = {
  THEMEPARK_NAME: "Please enter the theme park name",
  ADDRESS_ERR: "Please enter an address",
  CITY_ERR: "Please enter city",
  LATITUDE: "Please enter the latitude",
  LONGITUDE: "Please enter the longitude",
  DESCRIPTION: "Please enter the description",
  DESC_PLACEHOLDER: "Add description about the theme park",
  HIGHLIGHT_POINT: "Add highlights in points about the theme park",
  EVENT_NAME: "Please enter an event name",
  EVENT_IMAGE: "Please upload an event image",
  HIGHLIGHT_PLACEHOLDER: "Please enter the highlight point",
  HIGHLIGHT_POINT_VAL: "Please add atleast one highlight",
  TITCKET_TYPE_VAL: "Please add atleast one Ticket type",
  HIGHLIGHT_POINT_REQ: "Please add atleast one event",
  IMAGE_FORMAT: "Please upload in image format",
  LATITUDE_FORMAT: "Please enter valid latitude format",
  LONGITUDE_FORMAT: "Please enter valid longitude format",
  RATING_ERROR: "Please enter the rating",
  SPLASH_ERROR: "Please enter the splash sequence",
  MIN_PRICE: "Please enter the minimum price",
  MAX_PRICE: "Please enter the maximum price",
  TICKET_PRICE: "Please enter the ticket price",
  DISCOUNT_PRICE: "Please enter the discount price",
  NUMBER_FORMAT: "Please enter valid number",
  EXPIRY_DATE: "Please select expiry date",
  UPLOAD_VALIDATION: "Should upload atleast one ticket",
  SELECT_THEMEPARK: "Please select theme park",
  UPLOAD_TYPE: "Please upload files of same upload type",
  TICKET_TYPE: "Please enter the ticket type",
  TICKET_IMAGE: "Please upload ticket Image",
  SELECT_CATEGORY: "Please select the  Category",
  TICKET_OFFER_PRICE: "Please enter the ticket offer price",
  EXPIRY_DATE_VAL: "Please select valid date",
  BOOKED_TICKET_ERROR: "Tickets have already been booked for this ticket type. Editing is unavailable."
};

const CONTENT_ERROR_MESSAGES: any = {
  SCHOOL_HEADER: "School header is required",
  SCHOOL_DESCRIPTION: "School description is required",
  UNIVERSITY_HEADER: "University header is required",
  UNIVERSITY_DESCRIPTION: "University description is required",
  TUTOR_HEADER: "Tutor header is required",
  TUTOR_DESCRIPTION: "Tutor description is required",
  ACTIVITIES_HEADER: "Activities header is required",
  ACTIVITIES_DESCRIPTION: "Activities description is required",
  TRAINER_HEADER: "Trainer header is required",
  TRAINER_DESCRIPTION: "Trainer description is required",
  THEMEPARK_HEADER: "Themepark header is required",
  THEMEPARK_DESCRIPTION: "Themepark description is required",
  //
  SCHOOL_HEADER_ARABIC: "Arabic School header is required",
  SCHOOL_DESCRIPTION_ARABIC: "Arabic School description is required",
  UNIVERSITY_HEADER_ARABIC: "Arabic University header is required",
  UNIVERSITY_DESCRIPTION_ARABIC: "Arabic University description is required",
  TUTOR_HEADER_ARABIC: "Arabic Tutor header is required",
  TUTOR_DESCRIPTION_ARABIC: "Arabic Tutor description is required",
  ACTIVITIES_HEADER_ARABIC: "Arabic Activities header is required",
  ACTIVITIES_DESCRIPTION_ARABIC: "Arabic Activities description is required",
  TRAINER_HEADER_ARABIC: "Arabic Trainer header is required",
  TRAINER_DESCRIPTION_ARABIC: "Arabic Trainer description is required",
  THEMEPARK_HEADER_ARABIC: "Arabic Themepark header is required",
  THEMEPARK_DESCRIPTION_ARABIC: "Arabic Themepark description is required",

}

const PROMOTIONAL_CONTENT_ERROR_MESSAGES: any = {
  DESCRIPTION_REQUIRED: "Please enter description",
  DESCRIPTION_REQUIRED_ARABIC: "Please enter Arabic description",
  WEB_LINK_REQUIRED: "Please enter web URL",
  MOBILE_LINK_REQUIRED: "Please enter mobile URL",
  INVALID_WEB_LINK: "Please enter the valid Web URL",
  INVALID_MOBILE_LINK: "Please enter the valid Mobile URL",
  IMAGE_REQUIRED: "Please upload the image",

}

export {
  REGEX,
  ERROR_MESSAGES,
  ADD_SCHOOLS_ERROR_MESSAGES,
  EDIT_UNIVERSITY_ERROR_MESSAGE,
  THEMEPARK_MESSAGES,
  CONTENT_ERROR_MESSAGES,
  PROMOTIONAL_CONTENT_ERROR_MESSAGES
};
