import { Field, Formik, useFormikContext } from "formik";
import "./AddSchools.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import * as yup from "yup";
import {
  ADD_SCHOOLS,
  ADD_UNIVERSITY_FIELDS,
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../../Utilities/Constants/Constants";
import {
  ADD_SCHOOLS_ERROR_MESSAGES,
  EDIT_UNIVERSITY_ERROR_MESSAGE,
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import {
  numberOnly,
  nullUndefinedEmptyCheck,
  S3DataGetApi,
} from "../../../Services/Helpers/Helper";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import backButton from "../../../../Assets/schools/back_button.svg";
import eyeIcon from "../../../../Assets/schools/eye.svg";
import uploadFile from "../../../../Assets/schools/upload.svg";
import upload_image from "../../../../Assets/schools/upload_image.svg";
import close from "../../../../Assets/schools/close.svg";
import moment from "moment";
import { List, ListItemButton, ListItemText, MenuItem, Select } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import fileIcon from "../../../../Assets/TutorVerification/pdfIcon.svg";
import FileViewerDialog from "../../../Shared/FileViewerDialog/FileViewerDialog";
// import Autocomplete from "react-google-autocomplete";
import { GoogleMap, Marker, MarkerF, useLoadScript, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import RoomIcon from '@mui/icons-material/Room';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { store } from "../../../Services/Redux/Store/Store";
import { setLoader } from "../../../Services/Redux/Reducer/Reducer";

const libraries: any = ['places'];
const AddSchools = () => {
  const { isLoaded }: any = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries
  });
  const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : '';
  const uploadSchoolImages = useRef<HTMLInputElement | null>(null);
  const dsibDocs = useRef<HTMLInputElement | null>(null);
  const formikRef = useRef<any>();
  const inputRef = useRef<any>(null);
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const [schoolImages, setSchoolImages]: any = useState([]);
  const [dsibDoc, setDsibDoc]: any = useState("");
  const [dsibDocName, setDsibDocName]: any = useState("");
  const [validNumber, setValidNumber] = useState(true);
  const [appProcessPoints, setAppProcessPoints]: any = useState("");
  const [applicationProcess, setApplicationProcess] = useState<any>([]);
  const [eligibilityPoints, setEligibilityPoints]: any = useState(null);
  const [eligibilityCriteria, setEligibilityCriteria] = useState<any>([]);
  const [gradeKey, setGradeKeyValue] = useState("");
  const [gradeValue, setGradeValue] = useState("");
  const [gradeFee, setGradeFee]: any = useState([]);
  const [feeKey, setFeeKeyValue] = useState("");
  const [feeValue, setFeeValue] = useState("");
  const [feeStructure, setFeeStructure]: any = useState([]);
  const [gradesList, setGradesList]: any = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(" ");
  const [gradeWiseFee, setGradeWiseFee] = useState("")
  const [imageBanner, setImageBanner] = useState<any>("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const bannerImage = useRef<HTMLInputElement | null>(null);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");
  const [currentLocation, setCurrentLocation]: any = useState();
  const [mapEnabled, setMapEnabled] = useState(API_RESPONSE.TRUE);
  const [EmiratesList, setEmiratesList]: any = useState<any>([]);
  const [curriculamList, setCurriculamList]: any = useState([]);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const ratingTerms = [
    "Excellent",
    "Outstanding",
    "Good",
    "Acceptable",
    "Unsatisfactory"
  ]


  /* Translation hook**/
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  /* Navigatoin hook**/
  const navigate = useNavigate();
  const { id } = useParams();

  interface SchoolsData {
    [key: string]: any;
  }

  /** Grad YearFor Radio Buttons **/
  const [selectedOption, setSelectedOption] = useState<string>("Year"); // Default to "year"

  useEffect(() => {
    getGradesList();
  }, [selectedOption]);

  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });
  const [schoolsData, setSchoolsData] = useState<SchoolsData>({
    [ADD_SCHOOLS.SCHOOL_NAME]: "",
    [ADD_SCHOOLS.FOUNDED_YEAR]: id ? "N/A" : null,
    [ADD_SCHOOLS.LOCATION]: "",
    [ADD_SCHOOLS.COUNTRY]: "",
    [ADD_SCHOOLS.CURRICULUM]: "",
    [ADD_SCHOOLS.SCHOOL_IMAGES]: "",
    [ADD_SCHOOLS.WEBLINK_OF_SCHOOL]: "",
    [ADD_SCHOOLS.EMAIL]: "",
    [ADD_SCHOOLS.PHONE_NUMBER]: "",
    [ADD_SCHOOLS.PRINCIPAL]: id ? "N/A" : null,
    // [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL]: "Nil",
    [ADD_SCHOOLS.RANKING]: id ? "N/A" : null,
    [ADD_SCHOOLS.DSIB_RATING]: "",
    [ADD_SCHOOLS.RATING]: "",
    [ADD_SCHOOLS.DESCRIPTION]: "",
    [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL]: id ? "N/A" : null,
    [ADD_SCHOOLS.MAX_GRADE_NAME]: "",
    [ADD_SCHOOLS.MIN_GRADE_NAME]: "",
    [ADD_SCHOOLS.EMIRATES]: "",
    [ADD_SCHOOLS.CITY]: ""
  });
  const loginValidationSchema = yup.object({
    [ADD_SCHOOLS.SCHOOL_NAME]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.SCHOOL_NAME_REQUIRED),
    // [ADD_SCHOOLS.FOUNDED_YEAR]: yup
    //   .string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.FOUNDED_YEAR_REQUIRED),
    [ADD_SCHOOLS.LOCATION]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.LOCATION_REQUIRED),
    [ADD_SCHOOLS.CURRICULUM]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.CURRICULUM_REQUIRED),
    [ADD_SCHOOLS.SCHOOL_IMAGES]: yup
      .array()
      .typeError("")
      .min(1, ADD_SCHOOLS_ERROR_MESSAGES.SCHOOL_IMAGES_REQUIRED),
    [ADD_SCHOOLS.WEBLINK_OF_SCHOOL]: yup
      .string()
      /* .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
    ) */
      .matches(REGEX.URL_VALIDATION, EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_URL)
      .required(ADD_SCHOOLS_ERROR_MESSAGES.WEBLINK_OF_SCHOOL_REQUIRED),
    [ADD_SCHOOLS.EMAIL]: yup
      .string()
      .matches(REGEX.EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
      .required(ADD_SCHOOLS_ERROR_MESSAGES.EMAIL_REQUIRED),
    // .email(ADD_SCHOOLS_ERROR_MESSAGES.INVALID_EMAIL),
    [ADD_SCHOOLS.PHONE_NUMBER]: yup
      .string()
      // .matches(REGEX.PHONE_VALIDATION, ERROR_MESSAGES.PHONE_NUMBER_FORMAT)
      .required(ERROR_MESSAGES.PHONE_NUMBER),
    [ADD_SCHOOLS.DSIB_RATING]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.DSIB_RATING_REQUIRED),
    [ADD_SCHOOLS.RATING]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.RATING_REQUIRED),
    [ADD_SCHOOLS.DESCRIPTION]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.DESCRIPTION_REQUIRED),
    [ADD_SCHOOLS.EMIRATES]: yup
      .string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.EMIRATES),
    [ADD_SCHOOLS.CITY]: yup
      .string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.CITY),
    [ADD_SCHOOLS.MIN_GRADE_NAME]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.MIN_GRADE),
    [ADD_SCHOOLS.MAX_GRADE_NAME]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.MAX_GRADE),
    [ADD_SCHOOLS.COUNTRY]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.COUNTRY),
  });
  //format dates
  const formatDate = (payload: any) => {
    payload["academic_year_start"] = format(
      new Date(payload["academic_year_start"]),
      "yyyy-MM-dd"
    );
    payload["academic_year_end"] = format(
      new Date(payload["academic_year_end"]),
      "yyyy-MM-dd"
    );
    payload["winter_break_start"] = format(
      new Date(payload["winter_break_start"]),
      "yyyy-MM-dd"
    );
    payload["winter_break_end"] = format(
      new Date(payload["winter_break_end"]),
      "yyyy-MM-dd"
    );
    payload["spring_break_start"] = format(
      new Date(payload["spring_break_start"]),
      "yyyy-MM-dd"
    );
    payload["spring_break_end"] = format(
      new Date(payload["spring_break_end"]),
      "yyyy-MM-dd"
    );
    payload["summer_break_start"] = format(
      new Date(payload["summer_break_start"]),
      "yyyy-MM-dd"
    );
    payload["summer_break_end"] = format(
      new Date(payload["summer_break_end"]),
      "yyyy-MM-dd"
    );
    return payload;
  };

  useEffect(() => {
    // Get current user's location using the browser's Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
      );
    }
  }, [])

  useEffect(() => {
    getCurriculamList();
    if (id)
      getSchoolDetail();

    // getGradesList();
    getEmiratesList();
    // getSchoolDetaila
  }, [])

  const getGradesList = () => {
    // const url = API_END_POINTS.GET_GRADES_LIST + "?is_type=" + (selectedOption === "Year" ? "True" : "False") + (selectedOption === "Grade" ? "&is_grade=True" : "");
    const url = API_END_POINTS.GET_GRADES_LIST + "?is_type=False";

    axiosInstance.get(url).then(response => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setGradesList([...response.data.result.data]);
      }
    });
  };


  /* Getting curriculam list */
  const getCurriculamList = () => {
    axiosInstance.get(API_END_POINTS.CURRICULAM_LIST).then((response: any) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setCurriculamList(response.data.result.data);
      }
    })
  }

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  /** Grad YearFor Checkbox **/
  // const [isCheckedYear, setIsCheckedYear] = useState<boolean>(true);
  // const [isCheckedGrade, setIsCheckedGrade] = useState<boolean>(false);


  // useEffect(() => {
  //   getGradesList();
  // }, [isCheckedYear, isCheckedGrade]);


  // const getGradesList = () => {
  //   const url = API_END_POINTS.GET_GRADES_LIST + "?is_type=" + (isCheckedYear ? "True" : "False") + (isCheckedGrade ? "&is_grade=True" : "");

  //   axiosInstance.get(url).then(response => {
  //     if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
  //       setGradesList(response.data.result.data);
  //     }
  //   });
  // };

  // const handleYearCheckboxChange = () => {
  //   setIsCheckedYear(prevState => {
  //     setIsCheckedGrade(false);
  //     return !prevState;
  //   });
  // };

  // const handleGradeCheckboxChange = () => {
  //   setIsCheckedGrade(prevState => {
  //     setIsCheckedYear(false);
  //     return !prevState;
  //   });
  // };


  // const getGradesList = () => {
  //   axiosInstance.get(API_END_POINTS.GET_GRADES_LIST + "?is_type=" + "True").then(response => {
  //     if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
  //       setGradesList(response.data.result.data)
  //     }
  //   })
  // };

  const getSchoolDetail = () => {
    axiosInstance
      .get(API_END_POINTS.SCHOOL_BULK_UPLOAD + "?id=" + id)
      .then((res: any) => {
        if (res.data.status === true) {
          setSchoolImages(res.data.result.data.images);
          setDsibDoc(res.data.result.data.dsib_inspection_report);
          setSchoolDetail(res.data.result.data);
        }
      });
  };

  const setSchoolDetail = (data: any) => {
    setDsibDocName(
      data.dsib_inspection_report
        ? data.dsib_inspection_report.split("/").slice(-1)
        : ""
    );
    data.is_type ? setSelectedOption("Year") : setSelectedOption("Grade");
    setSchoolImages(data.images);
    setValidNumber(isValidPhoneNumber(data?.phone_number));
    setSchoolsData({
      [ADD_SCHOOLS.SCHOOL_NAME]: data.school_name,
      [ADD_SCHOOLS.FOUNDED_YEAR]: data.founded_year ? data.founded_year : null,
      [ADD_SCHOOLS.LOCATION]: data.address,
      [ADD_SCHOOLS.COUNTRY]: data.country,
      [ADD_SCHOOLS.CURRICULUM]: data.curriculam,
      [ADD_SCHOOLS.SCHOOL_IMAGES]: data.images,
      [ADD_SCHOOLS.WEBLINK_OF_SCHOOL]: data.web_link,
      [ADD_SCHOOLS.EMAIL]: data.email,
      [ADD_SCHOOLS.PHONE_NUMBER]: data.phone_number,
      [ADD_SCHOOLS.PRINCIPAL]: data.principal != "" ? data.principal : "N/A",
      [ADD_SCHOOLS.DSIB_RATING]: data.dsip_rating ? data.dsip_rating : "",
      [ADD_SCHOOLS.EMIRATES]: data.emirate ? data.emirate : "",
      [ADD_SCHOOLS.CITY]: data.city ? data.city : "",
      [ADD_SCHOOLS.RATING]: `${data.rating}`,
      [ADD_SCHOOLS.RANKING]: data.ranking ? `${data.ranking}` : null,
      [ADD_SCHOOLS.DESCRIPTION]: `${data.description}`,
      [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL]: data.student_count ? `${data.student_count}` : null,
      [ADD_SCHOOLS.MAX_GRADE_NAME]: data?.max_grade_name ? `${data?.max_grade_name}` : "",
      [ADD_SCHOOLS.MIN_GRADE_NAME]: data?.min_grade_name ? `${data?.min_grade_name}` : ""
    });
    // payload.fees_per_year = gradeFee;
    setApplicationProcess(data.application_process);
    setEligibilityCriteria(data.eligibility_criteria);
    setFeeStructure(data.fees_structure);
    setGradeFee(data.fees_per_year);
    setCurrentLocation({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
    /*  let isMaxIncluded = gradesList?.includes(data?.max_grade_name);
     if(!isMaxIncluded && gradesList.length>0){
       selectedOption === "Year"  ? setSelectedOption("Grade")  : setSelectedOption("Year");
     } */
  };

  const addSchoolDetail = (payload: any) => {
    payload.is_type = selectedOption === "Year" ? true : false;
    payload.fees_per_year = gradeFee;
    payload.fees_structure = feeStructure;
    payload.eligibility_criteria = eligibilityCriteria;
    payload.application_process = applicationProcess;
    payload.latitude = currentLocation.lat;
    payload.longitude = currentLocation.lng;
    payload[ADD_SCHOOLS.CITY] = payload[ADD_SCHOOLS.COUNTRY] === 'United Arab Emirates' ? payload[ADD_SCHOOLS.CITY] : "";
    payload[ADD_SCHOOLS.EMIRATES] = payload[ADD_SCHOOLS.COUNTRY] === 'United Arab Emirates' ? payload[ADD_SCHOOLS.EMIRATES] : "";
    payload[ADD_SCHOOLS.FOUNDED_YEAR] = payload[ADD_SCHOOLS.FOUNDED_YEAR] === "N/A" ? null : payload[ADD_SCHOOLS.FOUNDED_YEAR];
    payload[ADD_SCHOOLS.PRINCIPAL] = payload[ADD_SCHOOLS.PRINCIPAL] === "N/A" ? null : payload[ADD_SCHOOLS.PRINCIPAL];
    payload[ADD_SCHOOLS.RANKING] = payload[ADD_SCHOOLS.RANKING] === "N/A" ? null : payload[ADD_SCHOOLS.RANKING];
    payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL] = payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL] === "N/A" ? null : payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL];
    // payload.ranking = payload.ranking !=="N/A" ? payload.ranking  : null;
    payload.student_count = payload.student_count ? nullUndefinedEmptyCheck(payload.student_count.trim()) ? payload.student_count : null : null;
    if (id) {
      axiosInstance
        // .put(API_END_POINTS.SCHOOL_BULK_UPLOAD + "?id=" + id, payload)
        .put(API_END_POINTS.ADD_SCHOOL + "?id=" + id, payload)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message, TOASTER_CONFIGS);
            navigate(ROUTES.MANAGE_SCHOOLS);
          }
        }).catch((error) => { console.log(error) });
    } else {
      axiosInstance
        .post(API_END_POINTS.ADD_SCHOOL, payload)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message, TOASTER_CONFIGS);
            navigate(ROUTES.MANAGE_SCHOOLS);
          }
        }).catch((error) => { console.log(error) });
    }
    // }


  };

  const currentYear = new Date().getFullYear();

  const shouldDisableYear = (year: any) => {
    return new Date(year).getFullYear() > currentYear;
  };

  useEffect(() => {
    formikRef.current.setFieldValue(ADD_SCHOOLS.SCHOOL_IMAGES, schoolImages);
  }, [schoolImages]);

  const handleChange = async (event: any) => {
    const selectedFile = event.target.files[0];
    const maxSizeInBytes = 200 * 180;
    // if (selectedFile.size < maxSizeInBytes) {
    const files = event.target.files;

    // Convert FileList to an array and iterate over each file
    let typeValidation: any = Array.from(files).map((file: any) => {
      return file.type.split('/')[0] === "image"
    });
    try {
      if (schoolImages.length + event.target.files.length <= 10 && !typeValidation.includes(false) && event.target.files.length !== 0) {
        store.dispatch(setLoader(true));
        for (let i = 0; i < event.target.files.length; i++) {
          const uploadedImage = await S3DataGetApi(event);
          // schoolImages.length <=5  ?
          setTimeout(() => {
            setSchoolImages((schoolImages: any) => [
              ...schoolImages,
              uploadedImage,
            ]);
            store.dispatch(setLoader(false));
          }, 0);
        }
      } else {
        if (event.target.files.length !== 0)
          schoolImages.length + event.target.files.length >= 10 ? toast.error("Can upload only upto 10 Images") : toast.error("Please upload only images");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    // } else {
    //   toast.error("Please Recommended resolutions 200 * 180 ");
    // }
  };

  // const { setFieldValue } = useFormikContext();
  const onUploadDsibRecord = async (event: any) => {
    try {
      if (event.target.files[0].type.split("/")[1] === "pdf") {
        const uploadedImage = await S3DataGetApi(event);
        setDsibDocName(event.target.files[0].name);
        formikRef.current.setFieldValue(
          ADD_SCHOOLS.DSIB_INSPECTION_REPORT,
          uploadedImage
        );
        setDsibDoc(uploadedImage);
      } else {
        toast.error("Please upload in pdf format");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const getEmiratesList = () => {
    axiosInstance.get(API_END_POINTS.EMIRATE_LIST).then((response) => {
      setEmiratesList(response?.data?.result?.data)
    }).catch(() => { })
  }


  /**Handle file viewer open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  /* Delete tag from taglist */
  const handleDelete = (i: any) => () => {
    setSchoolImages(
      schoolImages.filter((item: any, index: any) => index !== i)
    );
  };

  /**Handle file viewer close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(false);
    setViewerFile("");
  };

  const validateEnglish = (e: any, formik: any) => {
    if (
      REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value) &&
      !/[0-9.,]/.test(e.key)
    ) {
      formik.setFieldValue(
        ADD_SCHOOLS.PRINCIPAL,
        e.target.value
      );
      return;
    } else {
      e.preventDefault();
      return;
    }
  };


  /* Updating event image */
  const onUploadEventImage = async (event: any, eventName: string) => {
    try {
      if (event.target.files[0].type.split("/")[1] === "pdf") {
        store.dispatch(setLoader(true));
        const uploadedImage = await S3DataGetApi(event);
        setImageBanner(uploadedImage);
        store.dispatch(setLoader(false));
      } else {
        toast.error("Please upload pdf format");
      }
    } catch (error) { }
  };

  const onAddGradefeeStructure = () => {
    if (nullUndefinedEmptyCheck(selectedGrade) && nullUndefinedEmptyCheck(gradeWiseFee) && nullUndefinedEmptyCheck(imageBanner)) {
      let data = gradeFee;
      data.push({
        grade: selectedGrade,
        fee: gradeWiseFee,
        detail: imageBanner
      });
      setGradeFee([...data]);
      setSelectedGrade(" ");
      // setGradeFee("");
      setGradeWiseFee("");
      setImageBanner("");
    }

  }

  const setCurrentLatLng = (latitude: any, longitude: any) => {
    setCurrentLocation({ lat: latitude, lng: longitude });
  }

  const getFormattedAddress = (lat: any, lng: any, formik: any, check: boolean) => {
    geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
      if (status === "OK") {
        if (results[COMMON_CONSTANTS.ZERO]) {
          let filterCountry = results[COMMON_CONSTANTS.ZERO]?.address_components?.filter((res: any) => {
            return res.types[0] === 'country'
          });

          if (check) {
            formik.setFieldValue(
              ADD_UNIVERSITY_FIELDS.LOCATION,
              results[COMMON_CONSTANTS.ZERO].formatted_address
            )
          }
          formik.setFieldValue(
            ADD_SCHOOLS.COUNTRY,
            filterCountry[0]?.long_name ? filterCountry[0]?.long_name : "N/A"
          )

        }
      }
    });
  }

  function numberOnlyWithDecimal(event: any) {
    const input = event.target;
    const value = input.value;

    // Remove non-digit and non-decimal characters except the first dot
    const sanitizedValue = value.replace(
      /[^\d.]/g,
      (match: any, offset: any) => {
        if (match === "." && offset === 0) {
          return match;
        }
        return "";
      }
    );

    // Limit to two digits after the decimal point
    const decimalIndex = sanitizedValue.indexOf(".");
    if (decimalIndex !== -1 && sanitizedValue.length - decimalIndex > 2 + 1) {
      input.value = sanitizedValue.slice(0, decimalIndex + 3);
    } else {
      input.value = sanitizedValue;
    }
  }

  const handleRemoveGradeFee = (index: any) => {
    const updatedData = [...gradeFee];
    updatedData.splice(index, 1);
    setGradeFee(updatedData);
  };

  const onPlaceChanged = (formik: any) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place?.geometry?.location;
        const lat = location.lat();
        const lng = location.lng();
        setCurrentLatLng(lat, lng);
        getFormattedAddress(lat, lng, formik, false);
        if (inputRef?.current) {
          const inputValue = inputRef.current.value;
          formik.setFieldValue(ADD_SCHOOLS.LOCATION, inputValue);
        }
      }
    }
  };

  const onAutocompleteLoad = (autoC: any) => {
    setAutocomplete(autoC); // Save the autocomplete instance
  };


  return (
    <>
      <FileViewerDialog
        open={fileViewerOpen}
        handleClose={onFilerViewerDialogClose}
        viewerFile={viewerFile}
      ></FileViewerDialog>
      <Formik
        innerRef={formikRef}
        initialValues={schoolsData}
        validationSchema={loginValidationSchema}
        onSubmit={async (values, actions) => {
          try {
            await addSchoolDetail(values);
          } catch (error) { }
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <div
            className="school_fields_container"
          // onSubmit={formik.handleSubmit}
          >
            <div className="arrow-wrap">
              <img
                className="school-back"
                onClick={() => {
                  navigate(ROUTES.MANAGE_SCHOOLS);
                }}
                src={backButton}
                alt="Learn and Play"
              />
              <h5 className="name-title">
                {id ? t("Edit School") : t("Add School")}
              </h5>
            </div>
            <div className="row school-row-wrap">
              <div id="card-title">{t("Basic Details")}</div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("School Name")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("School Name")}
                      name={ADD_SCHOOLS.SCHOOL_NAME}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.SCHOOL_NAME] &&
                        formik.errors[ADD_SCHOOLS.SCHOOL_NAME] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.SCHOOL_NAME]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Founded Year")}</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className="date_pickers"
                        label={t("Founded Year")}
                        shouldDisableYear={shouldDisableYear}
                        openTo="year"
                        views={["year"]}
                        // value={
                        //   new Date(formik.values[ADD_SCHOOLS.FOUNDED_YEAR])
                        // }
                        value={
                          formik.values[ADD_SCHOOLS.FOUNDED_YEAR] ?
                            new Date(formik.values[ADD_SCHOOLS.FOUNDED_YEAR])
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate = date
                            ? format(date, "yyyy")
                            : "";
                          formik.setFieldValue(
                            ADD_SCHOOLS.FOUNDED_YEAR,
                            formattedDate
                          );
                        }}
                      /* onChange={(foundedYear: any) =>
                    formik.setFieldValue(
                      ADD_SCHOOLS.FOUNDED_YEAR,
                      foundedYear
                    )
                  } */
                      />
                    </LocalizationProvider>
                    <div className="error-message-wrap"></div>
                  </div>

                  <div className="fields_label_wrapper mt-4">
                    <label className="fields_label">
                      {t("Name of the Principal")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Name of the Principal")}
                      name={ADD_SCHOOLS.PRINCIPAL}
                    // initialValues={id ? (formik.values[ADD_SCHOOLS.PRINCIPAL] || "N/A"):""}
                    /* onChange={(e:any)=>{
                  formik.setFieldValue(
                    ADD_SCHOOLS.PRINCIPAL,
                    e.target.value
                  );
                }} */
                    /*  onInput={(event: any) => {
                     validateEnglish(event,formik);
                   }} */
                    />
                    {/*  <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.PRINCIPAL] &&
                        formik.errors[ADD_SCHOOLS.PRINCIPAL] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.PRINCIPAL]}`)}
                        </div>
                      ) : null}
                    </div> */}
                    <div className="error-message-wrap"></div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Curriculum")}</label>
                    {/* <Field
                      className="form_inputs"
                      placeholder={t("Curriculum")}
                      name={ADD_SCHOOLS.CURRICULUM}
                      maxLength={25}
                    /> */}
                    <div className="select-wrapper">
                      <Field
                        className="basic-select-menu"
                        as="select"
                        defaultValue={" "}
                        name={ADD_SCHOOLS.CURRICULUM}
                      >
                        <option value=" ">Please select the curriculam</option>
                        <option value="N/A">{"N/A"}</option>
                        {curriculamList.map((curriculam: any, index: any) => {
                          return <option value={curriculam}>{curriculam}</option>;
                        })}
                      </Field>
                    </div>
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.CURRICULUM] &&
                        formik.errors[ADD_SCHOOLS.CURRICULUM] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.CURRICULUM]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Rating")}</label>
                    <div className="select-wrapper">
                      <Field
                        className="basic-select-menu"
                        as="select"
                        defaultValue={" "}
                        name={ADD_SCHOOLS.RATING}
                      >
                        <option value=" ">Please select the rating</option>
                        <option value="N/A">{"N/A"}</option>
                        {ratingTerms.map((rating: any, index: any) => {
                          return <option value={rating}>{rating}</option>;
                        })}
                      </Field>
                    </div>
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.RATING] &&
                        formik.errors[ADD_SCHOOLS.RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.RATING]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* Radio Button For Year or Grad */}
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Ranking")}</label>
                    <Field
                      className="basic-select-menu"
                      placeholder={"Ranking"}
                      maxLength={6}
                      onInput={numberOnly}
                      name={ADD_SCHOOLS.RANKING}
                    />

                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.RANKING] &&
                        formik.errors[ADD_SCHOOLS.RANKING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.RANKING]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* For Chechbox */}
                  {/* <div className="year_grade_checkbox">
                    <label className="year_grade_checkbox_label year_grade_first">
                      <input
                        className="year_grade_checkbox_input year_grade_one"
                        type="checkbox"
                        checked={isCheckedYear}
                        onChange={handleYearCheckboxChange}
                      />
                      Year
                    </label>
                    <label className="year_grade_checkbox_label">
                      <input
                        className="year_grade_checkbox_input year_grade_one"
                        type="checkbox"
                        checked={isCheckedGrade}
                        onChange={handleGradeCheckboxChange}
                      />
                      Grade
                    </label>
                  </div> */}

                  <div className="year_grade_checkbox">
                    <label className="year_grade_checkbox_label year_grade_first">
                      <input
                        className="year_grade_checkbox_input year_grade_one"
                        type="radio"
                        value="Year"
                        checked={selectedOption === "Year"}
                        onChange={handleOptionChange}
                      />
                      Year
                    </label>
                    <label className="year_grade_checkbox_label">
                      <input
                        className="year_grade_checkbox_input year_grade_one"
                        type="radio"
                        value="Grade"
                        checked={selectedOption === "Grade"}
                        onChange={handleOptionChange}
                      />
                      Grade
                    </label>
                  </div>

                  {/* For Input field */}
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t(`Minimum Grade/Year`)}</label>
                    <div className="select-wrapper">
                      <Field
                        className="basic-select-menu"
                        as="select"
                        placeholder={`Minimum Grade/Year`}
                        name={ADD_SCHOOLS.MIN_GRADE_NAME}
                      >
                        <option value="">Please select Grade/Year</option>
                        {gradesList.map((grade: any, index: any) => {
                          return <option value={grade}>{grade}</option>;
                        })}
                      </Field>
                    </div>

                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.MIN_GRADE_NAME] &&
                        formik.errors[ADD_SCHOOLS.MIN_GRADE_NAME] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.MIN_GRADE_NAME]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t(`Maximum Grade/Year`)}</label>
                    <div className="select-wrapper">
                      <Field
                        className="basic-select-menu"
                        as="select"
                        placeholder={`Maximum Grade/Year`}
                        name={ADD_SCHOOLS.MAX_GRADE_NAME}
                      >
                        <option value="">Please select Grade/Year</option>
                        {gradesList.map((grade: any, index: any) => {
                          return <option value={grade}>{grade}</option>;
                        })}
                      </Field>
                    </div>

                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.MAX_GRADE_NAME] &&
                        formik.errors[ADD_SCHOOLS.MAX_GRADE_NAME] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.MAX_GRADE_NAME]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Number of Students")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Number of Students")}
                      maxLength={6}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL}
                    />
                    {/* <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL
                      ] &&
                        formik.errors[
                        ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL
                        ] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[
                            ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS_SCHOOL
                            ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  <div className="fields_label_wrapper mt-4">
                    <label className="fields_label mandatory_fields">{t("DSIB Rating")}</label>
                    <div className="select-wrapper">
                      <Field
                        as="select"
                        className="basic-select-menu"
                        name={ADD_SCHOOLS.DSIB_RATING}
                        defaultValue={" "}
                      >
                        <option value=" ">Please select the DSIB rating</option>
                        <option value="N/A">{"N/A"}</option>
                        {ratingTerms.map((rating: any, index: any) => {
                          return <option value={rating}>{rating}</option>;
                        })}
                      </Field>
                    </div>

                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.DSIB_RATING] &&
                        formik.errors[ADD_SCHOOLS.DSIB_RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.DSIB_RATING]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Email")}</label>
                    <Field
                      className="form_inputs"
                      placeholder="e.g., name@example.com"
                      name={ADD_SCHOOLS.EMAIL}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.EMAIL] &&
                        formik.errors[ADD_SCHOOLS.EMAIL] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.EMAIL]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Phone Number")}</label>
                    <PhoneInput
                      inputProps={{
                        className: "",
                        name: `${ADD_SCHOOLS.PHONE_NUMBER}`,
                      }}
                      placeholder="Phone Number"
                      country={"ae"}
                      value={formik.values[ADD_SCHOOLS.PHONE_NUMBER]}
                      countryCodeEditable={true} // Ensures country code is fixed
                      enableSearch={true}
                      onChange={(e) => {
                        formik.setFieldValue(ADD_SCHOOLS.PHONE_NUMBER, `+${e}`);
                        setTimeout(() => {
                          formik.validateField(ADD_SCHOOLS.PHONE_NUMBER);
                          setValidNumber(isValidPhoneNumber(`+${e}`));
                        }, 0);
                      }}
                    // onBlur={() => {
                    //   formik.validateField(ADD_SCHOOLS.PHONE_NUMBER);
                    //   setValidNumber(isValidPhoneNumber(formik.values[ADD_SCHOOLS.PHONE_NUMBER]));
                    // }}
                    />
                    <p className="mb-0 phone-validation">{COMMON_CONSTANTS.PHONE_FORMAT}</p>
                    <div className="error-message-wrap">
                      {validNumber === false &&
                        formik.touched[ADD_SCHOOLS.PHONE_NUMBER] ? (
                        <div className="errorMessage">
                          {t(ADD_SCHOOLS_ERROR_MESSAGES.INVALID_PHONE_NUMBER)}
                        </div>
                      ) : null}
                      {formik.values[ADD_SCHOOLS.PHONE_NUMBER] == "" &&
                        validNumber === true &&
                        formik.touched[ADD_SCHOOLS.PHONE_NUMBER] ? (
                        <div className="errorMessage">
                          {t(ADD_SCHOOLS_ERROR_MESSAGES.PHONE_NUMBER_REQUIRED)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">
                      {t("Weblink of School")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder="e.g., https://schoolname.com"
                      name={ADD_SCHOOLS.WEBLINK_OF_SCHOOL}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.WEBLINK_OF_SCHOOL] &&
                        formik.errors[ADD_SCHOOLS.WEBLINK_OF_SCHOOL] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.WEBLINK_OF_SCHOOL]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>


                  <div className="fields_label_wrappers">
                    <label className="fields_label mandatory_fields">{t("Location")}</label>
                    <div className="address-wrapper">
                      {isLoaded && (
                        // <Autocomplete
                        //   className="auto-complete"
                        //   id={ADD_SCHOOLS.LOCATION}
                        //   apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        //   value={formik.values[ADD_SCHOOLS.LOCATION]}
                        //   onBlur={formik.handleBlur}
                        //   onChange={(e: any) => {
                        //     formik.setFieldValue(
                        //       ADD_SCHOOLS.LOCATION,
                        //       e.target.value
                        //     );
                        //   }}
                        //   onPlaceSelected={(place: any) => {
                        //     setCurrentLatLng(
                        //       place.geometry.location.lat(),
                        //       place.geometry.location.lng()
                        //     );
                        //     getFormattedAddress(
                        //       place.geometry.location.lat(),
                        //       place.geometry.location.lng(),
                        //       formik
                        //     );
                        //   }}
                        // />

                        <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={() => onPlaceChanged(formik)}>
                          <input
                            type="text"
                            className="auto-complete"
                            name={ADD_SCHOOLS.LOCATION}
                            placeholder="Enter a place"
                            ref={inputRef}
                            value={formik.values[ADD_SCHOOLS.LOCATION]} // Bind formik value
                            onChange={formik.handleChange}
                          />
                        </Autocomplete>
                      )}
                      {mapEnabled && (
                        <RoomIcon
                          className="room-icon"
                          onClick={() => {
                            setMapEnabled(!mapEnabled);
                          }}
                        />
                      )}
                      {!mapEnabled && (
                        <LocationOffIcon
                          className="room-icon"
                          onClick={() => {
                            setMapEnabled(!mapEnabled);
                          }}
                        />
                      )}
                    </div>
                    {isLoaded && mapEnabled && (
                      <GoogleMap
                        mapContainerClassName="map-container"
                        center={currentLocation}
                        zoom={10}
                      >
                        <MarkerF
                          position={currentLocation}
                          draggable={true}
                          onDragEnd={(e: any) => {
                            setCurrentLatLng(e.latLng.lat(), e.latLng.lng());
                            getFormattedAddress(
                              e.latLng.lat(),
                              e.latLng.lng(),
                              formik, true
                            );
                          }}
                        />
                      </GoogleMap>
                    )}
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.LOCATION] &&
                        formik.errors[ADD_SCHOOLS.LOCATION] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.LOCATION]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Country")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Country")}
                      onBlur={formik.handleBlur}
                      value={formik.values[ADD_SCHOOLS.COUNTRY]}
                      name={ADD_SCHOOLS.COUNTRY}
                      disabled
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.COUNTRY] &&
                        formik.errors[ADD_SCHOOLS.COUNTRY] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.COUNTRY]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper mb-0">
                    <label className="form-label">{t("States/Emirates")}</label>
                    <div className="select-wrapper">
                      <Field
                        as="select"
                        name={ADD_SCHOOLS.EMIRATES}
                        value={formik.values[ADD_SCHOOLS.EMIRATES]}
                        onBlur={formik.handleBlur}
                        className="basic-select-menu"
                        // disabled={mode === MODES.VIEW ? true : false}

                        onChange={(e: any) => {
                          formik.setFieldValue(ADD_SCHOOLS.EMIRATES, e.target.value)
                        }}
                      >
                        <option value={" "}>{"Please select the emirate"}</option>
                        {formik.values[ADD_SCHOOLS.COUNTRY] === 'United Arab Emirates' && EmiratesList?.map((item: any) => {
                          return (
                            <option value={item.emirate_city}>{item.emirate_city}</option>
                          )
                        })}
                      </Field>
                    </div>


                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.EMIRATES] &&
                        formik.errors[ADD_SCHOOLS.EMIRATES] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.EMIRATES]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="form-label">{t("City")}</label>
                    <div className="select-wrapper">
                      <Field
                        as="select"
                        name={ADD_SCHOOLS.CITY}
                        value={formik.values[ADD_SCHOOLS.CITY]}
                        onBlur={formik.handleBlur}
                        className="basic-select-menu"
                        // disabled={mode === MODES.VIEW ? true : false}

                        onChange={(e: any) => {
                          formik.setFieldValue(ADD_SCHOOLS.CITY, e.target.value)
                        }}
                      >
                        <option value={" "}>{"Please select the city"}</option>
                        {formik.values[ADD_SCHOOLS.COUNTRY] === 'United Arab Emirates' && EmiratesList?.map((item: any) => {
                          return (
                            <option value={item.emirate_city}>{item.emirate_city}</option>
                          )
                        })}
                      </Field>
                    </div>


                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.CITY] &&
                        formik.errors[ADD_SCHOOLS.CITY] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.CITY]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                </div>
              </div>
              <div className="fields_label_wrapper mt-3" id="desc-card-wrap">
                <label className="fields_label mandatory_fields">{t("Description")}</label>
                <Field
                  className="description-testarea"
                  placeholder="Enter Description"
                  as="textarea"
                  name={ADD_SCHOOLS.DESCRIPTION}
                  onBlur={formik.handleBlur}
                  onKeyPress={(event: any) => {
                    if (250 - formik.values[ADD_SCHOOLS.DESCRIPTION].length <= 0)
                      event?.preventDefault();
                  }}
                  onPaste={(event: any) => {
                    const remainingCharacters = 250 - formik.values[ADD_SCHOOLS.DESCRIPTION].length;
                    const pastedTextLength = event.clipboardData.getData('text/plain').length;
                    if (remainingCharacters <= 0 || remainingCharacters < pastedTextLength)
                      event.preventDefault();
                  }}
                  rows={4}
                ></Field>
                <p className="characters-left">

                  You have {250 - formik.values[ADD_SCHOOLS.DESCRIPTION]?.length}{" "} characters left

                </p>
                <div className="error-message-wrap">
                  {formik.touched[ADD_SCHOOLS.DESCRIPTION] &&
                    formik.errors[ADD_SCHOOLS.DESCRIPTION] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[ADD_SCHOOLS.DESCRIPTION]}`)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row school-row-wrap">
              <div id="card-title">{t("Application Process")}</div>
              <div className="col-md-12">
                <div id="card-wrap">
                  <div className="application-btn-wrapper">
                    <input
                      className="form_inputs"
                      placeholder={t(
                        "Please add application process as points"
                      )}
                      onChange={(e: any) => {
                        setAppProcessPoints(e.target.value);
                      }}
                      value={appProcessPoints}
                      maxLength={750}
                    />
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        if (nullUndefinedEmptyCheck(appProcessPoints)) {
                          let data: any = applicationProcess;
                          data.push(appProcessPoints);
                          setApplicationProcess([...data]);
                          setAppProcessPoints("");
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  {/* <p className="characters-left">
                    You have {750 - appProcessPoints?.length}{" "} characters left

                  </p> */}
                  {applicationProcess?.length > 0 && (
                    <div className="event-wrap">
                      <List>
                        {applicationProcess.map((point: any, index: any) => {
                          return (
                            <div>
                              <ListItemButton className="list-btn" key={index}>
                                <ListItemText primary={point} />
                                <DeleteIcon
                                  onClick={() => {
                                    let data: any = applicationProcess;
                                    data.splice(index, 1);
                                    setApplicationProcess([...data]);
                                    // onDeleteHighlight(index);
                                  }}
                                />
                              </ListItemButton>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  )}

                  {/* <div className="error-message-wrap mt-2">
                    {applicationProcess?.length === 0 && formSubmitted ? (
                      <div className="errorMessage">
                        {t(`${ADD_SCHOOLS_ERROR_MESSAGES.APPLICATION_PROCESS}`)}
                      </div>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row school-row-wrap">
              <div id="card-title">{t("Eligibility Criteria")}</div>
              <div className="col-md-12">
                <div id="card-wrap">
                  <div className="application-btn-wrapper">
                    <input
                      className="form_inputs"
                      placeholder={t(
                        "Please add eligibility criteria as points"
                      )}
                      onChange={(e: any) => {
                        setEligibilityPoints(e.target.value);
                      }}
                      maxLength={750}
                      value={eligibilityPoints}
                    />
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        if (nullUndefinedEmptyCheck(eligibilityPoints)) {
                          let data: any = eligibilityCriteria;
                          data.push(eligibilityPoints);
                          setEligibilityCriteria([...data]);
                          setEligibilityPoints("");
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  {eligibilityCriteria?.length > 0 && (
                    <div className="event-wrap">
                      <List>
                        {eligibilityCriteria.map((point: any, index: any) => {
                          return (
                            <div>
                              <ListItemButton className="list-btn" key={index}>
                                <ListItemText primary={point} />
                                <DeleteIcon
                                  onClick={() => {
                                    let data: any = eligibilityCriteria;
                                    data.splice(index, 1);
                                    setEligibilityCriteria([...data]);
                                    // onDeleteHighlight(index);
                                  }}
                                />
                              </ListItemButton>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  )}
                  {/*  <div className="error-message-wrap mt-2">
                    {eligibilityCriteria?.length === 0 && formSubmitted ? (
                      <div className="errorMessage">
                        {t(
                          `${ADD_SCHOOLS_ERROR_MESSAGES.ELIGIBILITY_CRITERIA}`
                        )}
                      </div>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row school-row-wrap">
              <div id="card-title" className="mandatory_fields">{t("Fee Structure")}</div>
              <div className="col-md-12">
                <div id="card-wrap">
                  <div className="application-btn-wrapper">
                    <input
                      className="form_inputs"
                      placeholder={t("Fee Type")}
                      onChange={(e: any) => {
                        setFeeKeyValue(e.target.value);
                      }}
                      value={feeKey}
                    />
                    <input
                      className="form_inputs"
                      placeholder={t("Amount in AED")}
                      onChange={(e: any) => {
                        setFeeValue(e.target.value);
                      }}
                      onInput={numberOnlyWithDecimal}
                      value={feeValue}
                      maxLength={6}
                    />
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        if (
                          nullUndefinedEmptyCheck(feeKey) &&
                          nullUndefinedEmptyCheck(feeValue)
                        ) {
                          let data: any = feeStructure;
                          let obj = {
                            [feeKey]: feeValue,
                          };
                          data.push(obj);
                          setFeeStructure([...data]);
                          setFeeKeyValue("");
                          setFeeValue("");
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  {feeStructure?.length > 0 && (
                    <div className="event-wrap">
                      <List>
                        {feeStructure.map((point: any, index: any) => {
                          const [key, value] = Object.entries(point)[0];
                          return (
                            <div key={index}>
                              <ListItemButton className="list-btn">
                                <ListItemText primary={`${key} : ${value}`} />
                                <DeleteIcon
                                  onClick={() => {
                                    let data: any = feeStructure.slice();
                                    data.splice(index, 1);
                                    setFeeStructure(data);
                                  }}
                                />
                              </ListItemButton>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  )}
                  <div className="error-message-wrap mt-2">
                    {feeStructure?.length === 0 && formSubmitted ? (
                      <div className="errorMessage">
                        {t(`${ADD_SCHOOLS_ERROR_MESSAGES.FEE_STRUCTURE}`)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row school-row-wrap">
              <div id="card-title" className="mandatory_fields">{t(`${selectedOption} wise Fee Structure`)}</div>
              <div className="col-md-12">
                <div>
                  <div className="application-btn-wrapper grade-wrapper">
                    <select
                      className="select-menu grade-fee-input"
                      defaultValue={""}
                      value={selectedGrade}
                      onChange={(e: any) => {
                        setSelectedGrade(e.target.value);
                      }}
                    >
                      <option value="">Please select {selectedOption}</option>
                      {gradesList.map((grade: any, index: any) => {
                        return <option value={grade}>{grade}</option>;
                      })}
                    </select>
                    <input
                      className="form_inputs grade-fee-input"
                      placeholder={t(`${selectedOption} fee in AED`)}
                      onChange={(e: any) => {
                        setGradeWiseFee(e.target.value);
                      }}
                      onInput={numberOnlyWithDecimal}
                      value={gradeWiseFee}
                      maxLength={6}
                    />

                    <div className="grade-pdf-wrapper">
                      <input
                        className="form-input grade-fee-input"
                        value={
                          imageBanner?.split("/")[
                          imageBanner?.split("/").length - 1
                          ]
                        }
                        disabled
                        placeholder="Upload Pay structure"
                      />
                      <input
                        className="image_input"
                        type="file"
                        accept="application/pdf"
                        onChange={(e: any) => {
                          onUploadEventImage(e, "banner");
                        }}
                        ref={bannerImage}
                      />
                      <FileUploadIcon
                        className={"upload-icon"}
                        onClick={() => bannerImage.current?.click()}
                      />
                    </div>

                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        /* if(nullUndefinedEmptyCheck(eligibilityPoints)){
                        let data:any = eligibilityCriteria;
                        data.push(eligibilityPoints);
                        setEligibilityCriteria([...data]);
                        setEligibilityPoints("");
                      } */
                        onAddGradefeeStructure();
                      }}
                    >
                      Add
                    </button>
                  </div>
                  <div className="error-message-wrap mt-2">
                    {feeStructure?.length === 0 && formSubmitted ? (
                      <div className="errorMessage">
                        {t(`${ADD_SCHOOLS_ERROR_MESSAGES.FEE_STRUCTURE}`)}
                      </div>
                    ) : null}
                  </div>
                </div>
                {gradeFee?.length > 0 && (
                  <div className="event-wrap">
                    <table>
                      <tr>
                        <th>Grade</th>
                        <th>Fee</th>
                        <th>Fee Structure</th>
                        <th>Action</th>
                      </tr>
                    </table>

                    <table>
                      {gradeFee.map((grade: any, i: any) => {
                        return (
                          <>
                            <tr>
                              <td>{grade.grade}</td>
                              <td>{grade.fee}</td>
                              <td>
                                <div className="tutor_files_icon_wrapper">
                                  <img
                                    className="tutor_file"
                                    src={fileIcon}
                                    alt="Learn and Play"
                                    onClick={() => {
                                      window.open(grade.detail, "_blank");
                                      // onFilerViewerDialogOpen(grade.detail);
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                {" "}
                                <DeleteIcon
                                  className="RemoveText"
                                  onClick={() => handleRemoveGradeFee(i)}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                )}
              </div>
            </div>

            {/*  <div className="row school-row-wrap">
              <div id="card-title">{t("Fees Details")}</div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Tuition Fees")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Tuition Fees")}
                      name={ADD_SCHOOLS.TUITION_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.TUITION_FEES] &&
                      formik.errors[ADD_SCHOOLS.TUITION_FEES] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.TUITION_FEES]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Enrollment Fees")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Enrollment Fees")}
                      name={ADD_SCHOOLS.ENROLLMENT_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.ENROLLMENT_FEES] &&
                      formik.errors[ADD_SCHOOLS.ENROLLMENT_FEES] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.ENROLLMENT_FEES]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Application Fees")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Application Fees")}
                      name={ADD_SCHOOLS.APPLICATION_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.APPLICATION_FEES] &&
                      formik.errors[ADD_SCHOOLS.APPLICATION_FEES] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.APPLICATION_FEES]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Discount on Tuition Fees")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("School Fees")}
                      name={ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES] &&
                      formik.errors[ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Optional Service Fees")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Optional Service Fees")}
                      name={ADD_SCHOOLS.OPTIONAL_SERVICE_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.OPTIONAL_SERVICE_FEES] &&
                      formik.errors[ADD_SCHOOLS.OPTIONAL_SERVICE_FEES] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_SCHOOLS.OPTIONAL_SERVICE_FEES]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Re-Enrollment Fees")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Re-Enrollment Fees")}
                      name={ADD_SCHOOLS.RE_ENROLLMENT_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.RE_ENROLLMENT_FEES] &&
                      formik.errors[ADD_SCHOOLS.RE_ENROLLMENT_FEES] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[ADD_SCHOOLS.RE_ENROLLMENT_FEES]}`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Total Fees")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Total Fees")}
                      name={ADD_SCHOOLS.TOTAL_FEES}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.TOTAL_FEES] &&
                      formik.errors[ADD_SCHOOLS.TOTAL_FEES] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.TOTAL_FEES]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="row school-row-wrap">
              <div id="card-title">{t("Academic and Vacation Details")}</div>
              <div id="card-wrap">
                <div className="row">
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Start of the Academic Year")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="date_pickers"
                          label={t("Start of the Academic Year")}
                          format="dd-MM-yyyy"
                          value={
                            new Date(
                              formik.values[
                                ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR
                              ]
                            )
                          }
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[
                          ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR
                        ] &&
                        formik.errors[
                          ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR
                        ] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[
                                  ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR
                                ]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("End of the Academic Year")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="date_pickers"
                          label={t("End of the Academic Year")}
                          format="dd-MM-yyyy"
                          value={
                            new Date(
                              formik.values[
                                ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR
                              ]
                            )
                          }
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR] &&
                        formik.errors[ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[
                                  ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR
                                ]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Winter Break Start Date")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          className="date_pickers"
                          label={t("Winter Break Start Date")}
                          value={
                            new Date(
                              formik.values[ADD_SCHOOLS.WINTER_BREAK_START_DATE]
                            )
                          }
                          format="dd-MM-yyyy"
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.WINTER_BREAK_START_DATE,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.WINTER_BREAK_START_DATE] &&
                        formik.errors[ADD_SCHOOLS.WINTER_BREAK_START_DATE] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[
                                  ADD_SCHOOLS.WINTER_BREAK_START_DATE
                                ]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Winter Break End Date")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="date_pickers"
                          label={t("Winter Break End Date")}
                          format="dd-MM-yyyy"
                          value={
                            new Date(
                              formik.values[ADD_SCHOOLS.WINTER_BREAK_END_DATE]
                            )
                          }
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";

                            formik.setFieldValue(
                              ADD_SCHOOLS.WINTER_BREAK_END_DATE,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.WINTER_BREAK_END_DATE] &&
                        formik.errors[ADD_SCHOOLS.WINTER_BREAK_END_DATE] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[ADD_SCHOOLS.WINTER_BREAK_END_DATE]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Spring Break Start Date")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          className="date_pickers"
                          label={t("Spring Break Start Date")}
                          value={
                            new Date(
                              formik.values[ADD_SCHOOLS.SPRING_BREAK_START_DATE]
                            )
                          }
                          format="dd-MM-yyyy"
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.SPRING_BREAK_START_DATE,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.SPRING_BREAK_START_DATE] &&
                        formik.errors[ADD_SCHOOLS.SPRING_BREAK_START_DATE] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[
                                  ADD_SCHOOLS.SPRING_BREAK_START_DATE
                                ]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Spring Break End Date")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="date_pickers"
                          label={t("Spring Break End Date")}
                          value={
                            new Date(
                              formik.values[ADD_SCHOOLS.SPRING_BREAK_END_DATE]
                            )
                          }
                          format="dd-MM-yyyy"
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.SPRING_BREAK_END_DATE,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.SPRING_BREAK_END_DATE] &&
                        formik.errors[ADD_SCHOOLS.SPRING_BREAK_END_DATE] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[ADD_SCHOOLS.SPRING_BREAK_END_DATE]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Summer Break Start Date")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="date_pickers"
                          label={t("Summer Break Start Date")}
                          value={
                            new Date(
                              formik.values[ADD_SCHOOLS.SUMMER_BREAK_START_DATE]
                            )
                          }
                          format="dd-MM-yyyy"
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.SUMMER_BREAK_START_DATE,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.SUMMER_BREAK_START_DATE] &&
                        formik.errors[ADD_SCHOOLS.SUMMER_BREAK_START_DATE] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[
                                  ADD_SCHOOLS.SUMMER_BREAK_START_DATE
                                ]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 acedemic_dates_container">
                    <div className="fields_label_wrapper">
                      <label className="fields_label">
                        {t("Summer Break End Date")}
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          className="date_pickers"
                          label={t("Summer Break End Date")}
                          value={
                            new Date(
                              formik.values[ADD_SCHOOLS.SUMMER_BREAK_END_DATE]
                            )
                          }
                          format="dd-MM-yyyy"
                          onChange={(date) => {
                            const formattedDate = date
                              ? format(date, "yyyy-MM-dd")
                              : "";
                            formik.setFieldValue(
                              ADD_SCHOOLS.SUMMER_BREAK_END_DATE,
                              new Date(formattedDate)
                            );
                          }}
                        />
                      </LocalizationProvider>
                      <div className="error-message-wrap">
                        {formik.touched[ADD_SCHOOLS.SUMMER_BREAK_END_DATE] &&
                        formik.errors[ADD_SCHOOLS.SUMMER_BREAK_END_DATE] ? (
                          <div className="errorMessage">
                            {t(
                              `${
                                formik.errors[ADD_SCHOOLS.SUMMER_BREAK_END_DATE]
                              }`
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*  <div className="row school-row-wrap">
              <div className="col-md-6">
                <div id="card-wrap">
                  <div id="holiday-card-title">{t("Holiday Count")}</div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Public Holidays")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Public Holidays")}
                      name={ADD_SCHOOLS.PUBLIC_HOLIDAYS}
                      onKeyPress={(event: any) => {
                        event.target.value.length <= 2
                          ? numberOnly(event)
                          : event.preventDefault();
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.PUBLIC_HOLIDAYS] &&
                      formik.errors[ADD_SCHOOLS.PUBLIC_HOLIDAYS] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.PUBLIC_HOLIDAYS]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Other Holidays")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Other Holidays")}
                      name={ADD_SCHOOLS.OTHER_HOLIDAYS}
                      onKeyPress={(event: any) => {
                        event.target.value.length <= 2
                          ? numberOnly(event)
                          : event.preventDefault();
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.OTHER_HOLIDAYS] &&
                      formik.errors[ADD_SCHOOLS.OTHER_HOLIDAYS] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.OTHER_HOLIDAYS]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div id="holiday-card-title">{t("DSIB Report & Rating")}</div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("DSIB Inspection Report")}
                    </label>

                    <Field
                      disabled
                      className={
                        language === "English"
                          ? "form_inputs dsib-doc"
                          : "form_inputs dsib-doc-ar"
                      }
                      placeholder={t("DSIB Inspection Report")}
                      name={ADD_SCHOOLS.DSIB_INSPECTION_REPORT}
                      value={dsibDocName}
                    />
                    <input
                      className="image_input"
                      type="file"
                      accept=""
                      onChange={onUploadDsibRecord}
                      ref={dsibDocs}
                    />
                    {dsibDocName != "" && (
                      <img
                        src={eyeIcon}
                        alt="Learn and Play"
                        className={
                          language === "English"
                            ? "dsib-eye-icon"
                            : "dsib-eye-icon-ar"
                        }
                        onClick={() => {
                          window.open(dsibDoc);
                        }}
                      />
                    )}
                    <img
                      src={uploadFile}
                      alt="Learn and Play"
                      className={
                        language === "English"
                          ? "dsib-upload-icon"
                          : "dsib-upload-icon-ar"
                      }
                      onClick={() => dsibDocs.current?.click()}
                    />

                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.DSIB_INSPECTION_REPORT] &&
                      formik.errors[ADD_SCHOOLS.DSIB_INSPECTION_REPORT] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_SCHOOLS.DSIB_INSPECTION_REPORT]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("DSIB Rating")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("DSIB Rating")}
                      name={ADD_SCHOOLS.DSIB_RATING}
                      as="select"
                    >
                      <option hidden value="default">
                        {t("--Please select--")}
                      </option>
                      <option value="Outstanding">Outstanding</option>
                      <option value="VeryGood">Very Good</option>
                      <option value="Good">Good</option>
                      <option value="Acceptable">Acceptable</option>
                      <option value="Weak">Weak</option>
                      <option value="VeryWeak">Very Weak</option>
                      <option value="NotApplicable">Not Applicable</option>
                    </Field>
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.DSIB_RATING] &&
                      formik.errors[ADD_SCHOOLS.DSIB_RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.DSIB_RATING]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row school-row-wrap">
              <div className="col-md-12">
                <div className="fields_label_wrapper">
                  {/* <label className="fields_label">{t("School Images")}</label> */}
                  <input
                    className="image_input"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg,image/svg,.mov,.mp4,.mkv"
                    multiple
                    onChange={handleChange}
                    ref={uploadSchoolImages}
                  />
                  <div className="add_images_wrapper">
                    <div
                      className="add_images_cotainer"
                      onClick={() => uploadSchoolImages.current?.click()}
                    >
                      <img src={upload_image} alt="Learn and Play" />
                      <div>{t("Add Image")}</div>
                    </div>
                    <div className="text_contant">
                      Recommended size: 780x586 with JPEG,PNG
                    </div>
                  </div>

                  <div className="error-message-wrap">
                    {formik.touched[ADD_SCHOOLS.SCHOOL_IMAGES] &&
                      formik.errors[ADD_SCHOOLS.SCHOOL_IMAGES] ? (
                      <div className="errorMessage">
                        {t(`${formik.errors[ADD_SCHOOLS.SCHOOL_IMAGES]}`)}
                      </div>
                    ) : null}
                  </div>
                </div>
                {schoolImages.length > 0 && (
                  <div className="image-array-wrap">
                    {schoolImages.map((image: any, index: any) => {
                      return (
                        <div className="image-wrap" key={index}>
                          <img
                            src={image}
                            key={index}
                            className="schoolimage-list"
                            alt="Learn and Play"
                          />
                          <img
                            className="cancel-icon"
                            onClick={handleDelete(index)}
                            src={close}
                            alt="Learn and Play"
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="actions_button_container">
              <button
                className="cancel_button"
                onClick={() => {
                  navigate("/manage-schools");
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className="update_button"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setFormSubmitted(true);
                }}
              >
                {id ? t("Update") : t("Add")}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default AddSchools;
