import { useParams, useNavigate } from 'react-router-dom';
import './ViewCourse.scss';
import { Card, TextField } from "@mui/material";
import { axiosInstance } from '../../../Services/Interceptor/Interceptor';
import API_END_POINTS from '../../../Utilities/Constants/ApiEndpoints';
import { useEffect, useState } from 'react';
import login from "../../../../Assets/Login/login_banner.svg";
import GradeIcon from '@mui/icons-material/Grade';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { dateTimeConversion } from '../../../Services/Helpers/Helper';
import { toast } from 'react-toastify';
import { I18_TRANSLATION_COMMON, TOASTER_CONFIGS, TRANSLATION_CONSTANT, VALIDATION_CONSTANT } from '../../../Utilities/Constants/Constants';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import BackArrow from "../../../../Assets/schools/back_button.svg";


const ViewCourse = () => {
    const { id } = useParams();
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [courseData, setCourseData]: any = useState();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [currentUser, setCurrentUser] = useState('');
    const [dialogOpenStatus, setDialogOpenStatus] = useState(false);


    const getCourseDetail = () => {
        axiosInstance.get(API_END_POINTS.MANAGE_COURSE + "?id=" + id).then(res => {
            if (res.data.status === true) {
                setCourseData(res.data.result.data[0]);
            }

        })
    }

    useEffect(() => {
        getCourseDetail();
    }, []);

    const approveRejectTutor = (payload: any) => {
        axiosInstance.put(API_END_POINTS.MANAGE_COURSE + "?id=" + id, payload).then((res: any) => {
            if (res.data.status === true) {
                toast.success(res.data.message, TOASTER_CONFIGS);
                getCourseDetail();
            }
        })
    };

    useEffect(() => {

    }, [currentUser])

    const onCloseDialog = () => {
        setDialogOpenStatus(false);
    }

    const onSubmitReason = (value: any) => {
        let payload = {
            status: VALIDATION_CONSTANT.REJECTED,
            reason: value.reason
        };
        setDialogOpenStatus(false);
        approveRejectTutor(payload);
    }



    return (
        <>
            <div className=" view-course-wrap">
                {/* <div className='course-title-wrap'>
            <ArrowBackIcon className='back_arrow_icon' onClick={()=>{navigate('/manage-courses')}} />
            <h5>{t('Course Detail')}</h5>
            </div> */}

                <div className="row">
                    <div className="col-md-12">
                        <div className='arrow-wrap'>
                            <img src={BackArrow} className='EditSchool-backarrow' onClick={() => { navigate('/manage-courses') }} />
                            <h5 className='name-title mt-1 ms-2'>{t('Course Detail')}</h5>
                        </div>

                    </div>
                </div>
                {courseData?.status === VALIDATION_CONSTANT.PENDING && <div className='button-wrap-vc'>
                    <button className='btn btn-danger reject-btn-vc' onClick={() => { setDialogOpenStatus(true); setCurrentUser(courseData?.id); }}>{t('Reject')}</button>
                    <button className='btn btn-success accept-btn-vc' onClick={() => { setCurrentUser(courseData?.id); approveRejectTutor({ "status": VALIDATION_CONSTANT.APPROVED }) }}>{t('Accept')}</button>
                </div>}
                {courseData?.status === VALIDATION_CONSTANT.REJECTED && <div className="button-wrap-vc">
                    <button className="btn btn-danger rejected-btn">{t('Rejected')}</button>
                </div>}
                {courseData?.status === VALIDATION_CONSTANT.APPROVED && <div className="button-wrap-vc">
                    <button className="btn btn-success accepted-btn">{t('Approved')}</button>
                </div>}

                <Card className='card-data'>
                    <div className='row'>

                        <div className="col-md-5">
                            <div className='row'>
                                <label id='course-label-name' className='col-md-6 '>{t('Course Name')}:</label>
                                <p className='col-md-6 values'>{courseData?.title}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Course Category')}:</label>
                                <p className='col-md-6 values'>{courseData?.category}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Course Mode')}:</label>
                                <p className='col-md-6 values'>{courseData?.course_mode_value == 'Offline' ? 'On-premises' : courseData?.course_mode_value}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Course Type')}:</label>
                                <p className='col-md-6 values'>{courseData?.course_type_value}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Course Duration')}:</label>
                                <p className='col-md-6 values'>{courseData?.duration}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Batch Size')}:</label>
                                <p className='col-md-6 values'>{courseData?.batch_size}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Created At')}:</label>
                                <p className='col-md-6 values'>{dateTimeConversion(courseData?.created_on)}</p>



                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className='row'>
                                <label id='course-label-name' className='col-md-6'>{t('Course Fees')}:</label>
                                <p className='col-md-6 values'>{courseData?.fees}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Description')}:</label>
                                <p className='col-md-6 values'>{courseData?.description}</p>

                                <label id='course-label-name' className='col-md-6'>{t('Tutor')}:</label>
                                <div className='tutor-outer-div mt-3 col-md-6'>
                                    <img
                                        className='tutor-profile-img'
                                        src={
                                            courseData?.user_details.profile_image !== '' ?
                                                courseData?.user_details.profile_image :
                                                login
                                        }
                                    />

                                    <div className='tutor-detail'>
                                        <p className='tutor-name'>{courseData?.user_details.profile_details.firstname}</p>
                                        <div>
                                            <VerifiedIcon className='verification-icon' />
                                            {courseData?.status === VALIDATION_CONSTANT.APPROVED ? 'verified' : 'Not verified'}

                                        </div>



                                        {/* <GradeIcon className='grade-icon'/>
                            5 */}
                                        <LocationOnIcon className="location-icon" />{courseData?.user_details.city}

                                    </div>

                                </div>

                                {courseData?.status === VALIDATION_CONSTANT.REJECTED && <div>
                                    <label id='course-label-name'>{t('Rejected Reason')}:</label>
                                    <p>{courseData?.reason}</p>
                                </div>}

                            </div>
                        </div>
                    </div>

                </Card>

            </div>
        </>
    )
};

export default ViewCourse;