import { Navigate } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import { TOKENS } from "../Constants/Constants";
import { GetSessionStorageData } from "../../Services/Helpers/Helper";
import { ProSidebarProvider } from "react-pro-sidebar";

const PrivateRoutesCheck = () => {
  const token = GetSessionStorageData(TOKENS.ACCESS_TOKEN);
  return token ? (
    <>
      <ProSidebarProvider>
        <Layout />
      </ProSidebarProvider>
    </>
  ) : (
    <>
      <Navigate to="/login" />
    </>
  );
};

export default PrivateRoutesCheck;
