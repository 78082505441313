import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import loginBanner from "../../../Assets/Login/login_banner.svg";
import passwordVisible from "../../../Assets/Login/password_visible.svg";
import passwordInvisible from "../../../Assets/Login/password_invisible.svg";
import phygitalLogo from "../../../Assets/Login/phygital_Logo.svg";
import "./Login.scss";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setPermissionData } from "../../Services/Redux/Reducer/Reducer";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import { toast } from "react-toastify";
import {
  TOASTER_CONFIGS,
  API_RESPONSE,
  TOKENS,
  LANGUAGES,
  LOGIN_FIELDS,
  LAYOUTS,
  ADMIN_ROLES,
  ROUTES,
  TRANSLATION_CONSTANT,
  I18_TRANSLATION_COMMON,
  COMMON_CONSTANTS,
  NUMBER_CONSTANTS,
} from "../../Utilities/Constants/Constants";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import { SetSessionStorageData } from "../../Services/Helpers/Helper";
import { ERROR_MESSAGES, REGEX } from "../../Utilities/Constants/Validations";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";

const Login = () => {
  /* Password visibility states**/
  const [showPassword, setShowPassword] = useState(false);
  /* Translation hook**/
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  /* Navigatoin hook**/
  const navigate = useNavigate();
  /* Toggle language**/
  const changeLanguage = (languageCode: string) => {
    dispatch(
      setLanguage(
        language === LANGUAGES.ENGLISH ? LANGUAGES.ARABIC : LANGUAGES.ENGLISH
      )
    );
    i18n.changeLanguage(languageCode);
  };
  /* set and get language from redux**/
  const dispatch = useDispatch();
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /* store cookies hook**/
  const [cookies, setCookie] = useCookies([
    LOGIN_FIELDS.EMAIL,
    LOGIN_FIELDS.PASSWORD,
    LOGIN_FIELDS.REMEMBER_ME,
  ]);
  /* Login field initial values **/
  let loginInitialValue = {
    email: cookies.email ? cookies.email : "",
    password: cookies.password ? cookies.password : "",
    rememberMe: cookies.rememberMe === "true" ? true : false,
  };

  /* login fields validation schema **/
  const loginValidationSchema = yup.object({
    email: yup
      .string()
      .matches(REGEX.EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
      .required(ERROR_MESSAGES.EMAIL_REQUIRED)
      .trim(),
    password: yup
      .string()
      .required(ERROR_MESSAGES.PASSWORD_REQUIRED)
      .min(8, ERROR_MESSAGES.MINIMUM_PASSWORD)
      .max(16, ERROR_MESSAGES.MAXIMUM_PASSWORD),
  });
  /* set data in cookies **/
  const setCookiesData = (values: any) => {
    if (values.rememberMe === true) {
      setCookie(LOGIN_FIELDS.EMAIL, values.email);
      setCookie(LOGIN_FIELDS.PASSWORD, values.password);
      setCookie(LOGIN_FIELDS.REMEMBER_ME, values.rememberMe);
    } else {
      setCookie(LOGIN_FIELDS.EMAIL, "");
      setCookie(LOGIN_FIELDS.PASSWORD, "");
      setCookie(LOGIN_FIELDS.REMEMBER_ME, "");
    }
  };
  /* login API call **/
  const login = (values: any, actions: any) => {
    let loginPayload = {
      email: values[LOGIN_FIELDS.EMAIL].trim(),
      password: values[LOGIN_FIELDS.PASSWORD],
      role: ADMIN_ROLES.ROLE,
      login_type: "1",
      social_id: "",
    };
    axiosInstance
      .post(`${API_END_POINTS.LOGIN}`, loginPayload)
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {          
          SetSessionStorageData(
            COMMON_CONSTANTS.ROLE_ID,
            response.data.result.data.user_info.role_id
          );
          SetSessionStorageData(
            TOKENS.ACCESS_TOKEN,
            response.data.result.data.access_token
          );
          SetSessionStorageData(
            TOKENS.REFRESH_TOKEN,
            response.data.result.data.refresh_token
          );
          SetSessionStorageData(
            COMMON_CONSTANTS.IS_SUPER_ADMIN,
            response.data.result.data.is_super_admin
          );
          SetSessionStorageData(
            COMMON_CONSTANTS.ID,
            response.data.result.data.user_info.id
          );
          setCookiesData(values);
          if(!response.data.result.data.is_super_admin){
           
            if(response.data.result.data.user_info.role_id===NUMBER_CONSTANTS.TWELVE){
              dispatch(setPermissionData(
               [{
                  id: 19,
                  name: "Manage Chats",
                  permission_name: "chats",
                  reference_code: "REF19",
                  checked: true,
                  data: []
                  
            }]
                ));
              toast.success(response.data.message, TOASTER_CONFIGS);
              navigate(ROUTES.MANAGE_CHAT);          
            }else{
              response.data.result.data.permissions.unshift({
                id: 0,
                name: "Dashboard",
                permission_name: "dashboard",
                reference_code: "REF0",
                checked: true,
                data: []
                
          });
              dispatch(setPermissionData(response.data.result.data.permissions));
              toast.success(response.data.message, TOASTER_CONFIGS);
              navigate(ROUTES.DASHBOARD);
            }

          }else{
            toast.success(response.data.message, TOASTER_CONFIGS);
            navigate(ROUTES.DASHBOARD);
          }
         
          
        }
      })
      .catch((error: any) => {
        // toast.error(error.response.data.message, TOASTER_CONFIGS);
      });
  };
  return (
    <>
      <div
        className="login_container"
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
      >
        <div className="background_color_wrapper">
          <div className="background_fix">
            <div className="background_color_container">
              <div className="yellow_round"></div>
              <div className="violet_round"></div>
              <div className="blue_round"></div>
            </div>
          </div>
        </div>

        <div className="login_wrapper ">
          <img className="login_banner" src={loginBanner} alt="" />
          <div className="login_fields_wrapper">
            <div className="language_switch_header">
              <div className=" language_switch_contianer">
                <LanguageIcon className="language_icon" />
                <button
                  className="language_button"
                  onClick={() => {
                    changeLanguage(
                      language === LANGUAGES.ENGLISH
                        ? LANGUAGES.ARABIC_ABBRIVATION
                        : LANGUAGES.ENGLISH_ABBRIVATION
                    );
                  }}
                >
                  {language === LANGUAGES.ENGLISH
                    ? LANGUAGES.ARABIC
                    : LANGUAGES.ENGLISH}
                </button>
              </div>
            </div>
            <div>
              <div className="logo-wrapper">
                <img
                  className="logo_image"
                  src={phygitalLogo}
                  alt="Learn and Play"
                />
              </div>

              <div className="login_fields_container fields_wrapup">
                <div className="login_label_fields">
                  <p className="welcome_label">{t("Welcome Back")}</p>
                  <p className="login_description ">
                    {t("Login to your account")}
                  </p>
                  <Formik
                    initialValues={loginInitialValue}
                    validationSchema={loginValidationSchema}
                    onSubmit={(values, actions) => {
                      login(values, actions);
                    }}
                    enableReinitialize={true}
                  >
                    {(formik) => (
                      <Form>
                        <div className="email_password_fields_container">
                          <div className="email_password_fields">
                            <Field
                              placeholder={t("Email")}
                              name={LOGIN_FIELDS.EMAIL}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="errorMessage">
                                {t(`${formik.errors.email}`)}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={
                              language === LANGUAGES.ENGLISH
                                ? "password_field"
                                : "password_field_arabic"
                            }
                          >
                            <div className="password_container">
                              <Field
                                placeholder={t("Password")}
                                type={
                                  showPassword ? "text" : LOGIN_FIELDS.PASSWORD
                                }
                                name={LOGIN_FIELDS.PASSWORD}
                              />
                              <img
                                className={
                                  language === LANGUAGES.ENGLISH
                                    ? "password_visibility"
                                    : "password_visibility_arabic"
                                }
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                                src={
                                  showPassword
                                    ? passwordInvisible
                                    : passwordVisible
                                }
                                alt=""
                              />
                            </div>
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="errorMessage">
                                {t(`${formik.errors.password}`)}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="remember_me_forget_password ">
                          <div className="remember_me_label_container">
                            <Field
                              type="checkbox"
                              name={LOGIN_FIELDS.REMEMBER_ME}
                            />
                            <label className="remember_me_label">
                              {t("Remember me")}
                            </label>
                          </div>
                          <button
                            type="button"
                            onClick={() => navigate(ROUTES.FORGET_PASSWORD)}
                            className="forget_password_button"
                          >
                            {t("Forgot password?")}
                          </button>
                        </div>
                        <div className="login-submit-btn-wrap">
                          <button
                            disabled={
                              formik.isValid &&
                              (formik.values.rememberMe
                                ? true
                                : Object.keys(formik.touched).length != 0)
                                ? false
                                : true
                            }
                            className={
                              formik.isValid &&
                              (formik.values.rememberMe
                                ? true
                                : Object.keys(formik.touched).length != 0)
                                ? "submit_button"
                                : "submit_button_disabled"
                            }
                            type="submit"
                          >
                            {t("Submit")}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
