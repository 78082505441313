import { Dialog, DialogTitle, DialogContent, Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, I18_TRANSLATION_COMMON, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import "./AddEditThemeParkTicket.scss";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck, numberOnlyWithDecimal } from "../../../Services/Helpers/Helper";
import { ERROR_MESSAGES, REGEX, THEMEPARK_MESSAGES } from "../../../Utilities/Constants/Validations";
import * as yup from "yup";
import { Field, Formik, yupToFormErrors } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";

const AddEditThemeParkTicket = (props: any) => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [fieldValue, setFieldValue] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
    const [InitialValues, setInitialValues]: any = useState({
        ticket_type: "",
        ticket_price: "",
        description: "",
        discount_price: "",
        expiry_on: "",
    });

    /* Yup validation schema */
    const ticketsValidationSchema = yup.object({
        ticket_type: yup
        .string()
        .required(THEMEPARK_MESSAGES.TICKET_TYPE),
        ticket_price: yup
        .string()
        .required(THEMEPARK_MESSAGES.TICKET_PRICE),
        expiry_on: yup
        .date()
        .test("superior", THEMEPARK_MESSAGES.EXPIRY_DATE_VAL, function (value: any) {
            return new Date(value) > new Date;
          })
        .required(THEMEPARK_MESSAGES.EXPIRY_DATE),
        description: yup
        .string()
        .required(THEMEPARK_MESSAGES.DESCRIPTION),
        discount_price: yup
        .string()
        .test("superior", ERROR_MESSAGES.DISCOUNT_PRICE, function (value: any) {
            const ref: any = yup.ref("ticket_price");
            return parseFloat(value) < parseFloat(this.resolve(ref));
          })
        .required(THEMEPARK_MESSAGES.DISCOUNT_PRICE),
    });
    const onClose = () => {
        setFieldValue("");
        setFormSubmitted(API_RESPONSE.FALSE);
        return props.dialogStatus(API_RESPONSE.FALSE);
    };



    useEffect(() => {
        if (props.data.mode === "Edit") {
            setInitialValues(props.data.payload)
        } else {
            setInitialValues({
                ticket_type: "",
                ticket_price: "",
                description: "",
                discount_price: "",
                expiry_on: "",
            })
            setFormSubmitted(API_RESPONSE.FALSE);
        }
    }, [props])



    const submitApiCall = (API: any, payload: any) => {
        if (props.data.mode === "Edit") {
            axiosInstance
                .put(API + "?id=" + payload?.id, payload)
                .then((res) => {
                    if (res.data.status === API_RESPONSE.TRUE) {
                        toast.success(res.data.message, TOASTER_CONFIGS);
                        setFieldValue("");
                        setFormSubmitted(API_RESPONSE.FALSE);
                        onClose();
                    }
                });
        } else {
            axiosInstance
                .post(API, payload)
                .then((res) => {
                    if (res.data.status === API_RESPONSE.TRUE) {
                        toast.success(res.data.message, TOASTER_CONFIGS);
                        setFieldValue("");
                        setFormSubmitted(API_RESPONSE.FALSE);
                        onClose();
                    }
                });
        }

    }

    const onSubmit = (payload: any) => {
        setFormSubmitted(API_RESPONSE.TRUE);
        payload.themepark_id = props?.data?.themePark_id;
        payload.image = "https://phygital-lp.s3.ap-south-1.amazonaws.com/dubai_college.jpg";

        submitApiCall(API_END_POINTS.THEME_PARK_TICKET_TYPE_CREATE, payload)
    }


    return (
        <div className="program-create-wrapper">
            <Dialog
                className="program-dialog"
                open={props.modalState}
                sx={{ "& .MuiDialog-paper": "cus-dialog" }}
            >
                <DialogTitle className="title-wrap">
                    {props.data.mode === "Edit" ? "Edit Ticket type" : "Create Ticket type"}
                    <div className="close-wrap">
                        <CloseIcon className="close-icon" onClick={onClose} />
                    </div>
                </DialogTitle>

                <DialogContent>

                    <div className="create-wrapper">
                        <Formik
                            validationSchema={ticketsValidationSchema}
                            initialValues={InitialValues}
                            onSubmit={(values: any, { resetForm }) => {
                                onSubmit(values)
                                resetForm();
                            }}
                            enableReinitialize
                        >
                            {(formik: any) => {
                                return (
                                    <>
                                        <div className="categoryWrap">
                                            <label className="nameLabel">
                                                {t("Ticket type")}:
                                            </label>
                                            <input
                                                name="ticket_type"
                                                className="program-input w-100 p-2 ticket_input"
                                                placeholder="Ticket type"
                                                value={formik.values["ticket_type"]}
                                                maxLength={50}
                                                onChange={(e) => {
                                                    formik.setFieldValue("ticket_type", e.target.value)
                                                }}
                                            />
                                            <p className="note mb-0">Note: Max length 50 characters</p>

                                            <div className="errorMessage">
                                                {formik.touched["ticket_type"] &&
                                                    formik.errors["ticket_type"] && (
                                                        <p>{t(`${formik.errors["ticket_type"]}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="categoryWrap">
                                            <label className="nameLabel">
                                                {t("Ticket Price(AED)")}:
                                            </label>
                                            <input
                                                name="ticket_price"
                                                className="program-input w-100 p-2 ticket_input"
                                                placeholder="Ticket Price"
                                                onInput={numberOnlyWithDecimal}
                                                value={formik.values["ticket_price"]}
                                                maxLength={6}
                                                onChange={(e) => {
                                                    formik.setFieldValue("ticket_price", e.target.value)
                                                }}
                                            />

                                            <div className="errorMessage">
                                                {formik.touched.ticket_price &&
                                                    formik.errors.ticket_price && (
                                                        <p>{t(`${formik.errors.ticket_price}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="categoryWrap">
                                            <label className="nameLabel">
                                                {t("Discount Price(AED)")}:
                                            </label>
                                            <input
                                                name="discount_price"
                                                className="program-input w-100 p-2 ticket_input"
                                                value={formik.values["discount_price"]}
                                                onInput={numberOnlyWithDecimal}
                                                maxLength={6}
                                                placeholder="Discount Price"
                                                onChange={(e) => {
                                                    formik.setFieldValue("discount_price", e.target.value)
                                                }}
                                            />
                                           <div className="error-message-wrap">
                                                {formik.touched.discount_price &&
                                                    formik.errors.discount_price ? (
                                                    <div className="errorMessage">
                                                    {t(`${formik.errors.discount_price}`)}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="categoryWrap">
                                            <label className="nameLabel">
                                                {t("Description")}:
                                            </label>
                                            <textarea
                                                name="description"
                                                className="program-input w-100 p-2 ticket_input"
                                                value={formik.values["description"]}
                                                rows={3}
                                                maxLength={120}
                                                onChange={(e) => {
                                                    formik.setFieldValue("description", e.target.value)
                                                }}
                                            />
                                            <p className="characters-left">
                                                {120 - formik.values.description?.length}{" "}
                                                {t("characters left")}
                                            </p>

                                            <div className="errorMessage">
                                                {formik.touched.description &&
                                                    formik.errors.description && (
                                                        <p>{t(`${formik.errors.description}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                        <div className="fields_label_wrapper">
                                            <label className="fields_label">
                                                {t("Expiry Date")}:
                                            </label> <br />
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    className="tickets_type_date_pickers"
                                                    openTo="year"
                                                    views={["year","month","day"]}
                                                    value={new Date(formik.values["expiry_on"])}
                                                    minDate={new Date()}
                                                    onChange={(date) => {
                                                        const formattedDate = date
                                                            ? format(date, "yyyy-MM-dd")
                                                            : "";
                                                        formik.setFieldValue("expiry_on", formattedDate);
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            <div className="errorMessage">
                                                {formik.touched.expiry_on &&
                                                    formik.errors.expiry_on && (
                                                        <p>{t(`${formik.errors.expiry_on}`)}</p>
                                                    )}
                                            </div>
                                        </div>

                                        <div className="submit-btn-wrapper">
                                            <button type="button" className="btn btn-primary program-cancel-btn" onClick={onClose}>Cancel</button>
                                            <button type="submit" className="btn btn-primary program-save-btn" onClick={() => {
                                                formik.handleSubmit();
                                            }} >Save</button>
                                        </div>
                                    </>
                                );
                            }}
                        </Formik>
                    </div>

                </DialogContent>
            </Dialog>
        </div >
    )
};

export default AddEditThemeParkTicket;