import "./ViewKeywords.scss";
import { Card, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import {
  I18_TRANSLATION_COMMON,
  ROUTES,
  UPLOAD_TICKET_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import BackArrow from "../../../../Assets/schools/back_button.svg";

const ViewKeywords = () => {
  const { id, type } = useParams();
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [data, setData] = useState([]);
  const [course, setCourse] = useState("");
  const navigate = useNavigate();
  const [courseType, setCourseType] = useState("");
  const location = useLocation();

  useEffect(() => {
    location.pathname.split("/")[1] ===
    UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL
      ? setCourseType(VALIDATION_CONSTANT.RECREATION)
      : setCourseType(VALIDATION_CONSTANT.COURSE);

    // }
  }, []);

  const tageDetail = () => {
    let payload = {
      category_type: courseType,
      category_id: "",
    };
    axiosInstance
      .get(
        API_END_POINTS.GET_TAG_DETAIL +
          "?category_type=" +
          courseType +
          "&category_id=" +
          id
      )
      .then((res: any) => {
        if (res.data.status) {
          setData(res.data?.result?.data);
          setCourse(res?.data?.result?.category);
        }
      });
  };
  useEffect(() => {
    if (courseType) tageDetail();
  }, [courseType]);

  return (
    <div className="Viewtag">
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              onClick={() => {
                courseType === VALIDATION_CONSTANT.RECREATION
                  ? navigate(ROUTES.RECREATIONAL_KEYWORDS)
                  : navigate(ROUTES.COURSE_KEYWORDS);
              }}
              alt="Learn and Play"
            />
            <h5 className="name-title mt-1 ms-2">{t("tagDetails")}</h5>
          </div>
        </div>
      </div>

      <Card className="Viewtag-card">
        <div className="row">
          <div className=" col-md-6">
            <div className="detail-wrap">
              <label className="Viewtag-label">{t("Course Type:")}</label>
              <input
                className="Viewtag-input-tag"
                value={courseType}
                disabled
              />
            </div>
          </div>
          <div className=" col-md-6">
            <div className="detail-wrap">
              <label className="Viewtag-label">{t("Category Type:")}</label>
              <input className="Viewtag-input-tag" value={course} disabled />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="view_tag_container col-md-6">
            <label className="Viewtag-label">{t("English Tags")}</label>
            <div className="tag-edit-div">
              {data?.map((tag: any, index: any) => {
                return (
                  <Chip
                    className="tag-chip"
                    label={tag?.tag}
                    variant="outlined"
                  />
                );
              })}
            </div>
          </div>
          <div className="view_tag_container col-md-6">
            <label className="Viewtag-label">{t("Arabic Tags")}</label>
            <div className="tag-edit-div">
              {data?.map((tag: any, index: any) => {
                return (
                  <Chip
                    className="tag-chip tag_chip_arabic"
                    label={tag?.arabic_tag_name}
                    variant="outlined"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ViewKeywords;
