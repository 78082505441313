import { useNavigate, useParams } from "react-router-dom";
import { checkEmpty, nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { ADD_SCHOOLS, API_RESPONSE, I18_TRANSLATION_COMMON, ROUTES } from "../../../Utilities/Constants/Constants";
import "./UserDetail.scss";
import { useEffect, useState } from "react";
import { Card, List, ListItemButton, ListItemText } from "@mui/material";
// import { Field } from "formik";
import { useTranslation } from "react-i18next";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import profile_default from "../../../../Assets/profile/profile_default.svg";

const UserDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [ parentDetail, setParentDetail ] = useState<any>();
    const [ secondaryParent, setSecondaryParent ] = useState<any>();
    const [ childDetail, setChildDetail ] = useState<any>();
    
    useEffect(()=>{
        getUsersList();
    },[])
    
    const getUsersList = () => {
        axiosInstance
          .get(API_END_POINTS.USERS_LIST + `?id=${id}`)
          .then((response: any) => {
            if (response.data.status === API_RESPONSE.TRUE) {
                setParentDetail(response.data.result.data.auth_user[0]);
                setSecondaryParent(response.data.result.data?.another_user ? response.data.result.data?.another_user[0] : [])
                setChildDetail(response.data.result.data.child_users);
            } 
          });
      };
      
    const onNavigate = (id:any) => {
        navigate(`/manage-users/${id}/bookings`);
    }

    return(
        <div className="userdetail-wrapper">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="arrow-wrap">
                  <img
                    alt="Learn and Play"
                    src={BackArrow}
                    className="EditSchool-backarrow"
                    onClick={() => {
                         navigate(ROUTES.MANAGE_USERS);
                    }}
                  />
                  <h5 className="name-title mt-2 ms-1">{t("User Detail")}</h5>
                </div>
              </div>
            </div>
            <div className="parent-wrapper">
                
               <Card className="parent-card">
               <div className="approval-label">
                    Parent
                    </div>
                   <div className="image-wrap">
                      <img className="parent-image" src={
                        nullUndefinedEmptyCheck(parentDetail?.image) ? parentDetail?.image : profile_default
                        }/>
                   </div>
                   <h5>{parentDetail?.firstname + parentDetail?.lastname }</h5>
                   <p>{parentDetail?.email}</p>
                   <p>{parentDetail?.phone_number}</p>
                   <div className="btn-wrap mt-2">
                            <button className="bnt btn-primary" onClick={()=>onNavigate(parentDetail?.id)}>View Bookings</button>
                            </div>
                </Card>
                {secondaryParent?.firstname && <Card className="parent-card">
                  <div className="approval-label">
                    Parent
                    </div>
            
                    <div className="image-wrap">
                        <img className="parent-image" src={
                            nullUndefinedEmptyCheck(secondaryParent?.image) ? secondaryParent?.image : profile_default
                            
                            }/>
                    </div>
                    <h5>{secondaryParent?.firstname + secondaryParent?.lastname }</h5>
                    <p>{secondaryParent?.email}</p>
                    <p>{secondaryParent?.phone_number}</p>
                    <div className="btn-wrap">
                            <button className="bnt btn-primary" onClick={()=>onNavigate(secondaryParent?.id)}>View Bookings</button>
                            </div>
                </Card>}
                {
                    childDetail?.map((child:any,index:number)=>{
                        return(
                        <Card className="parent-card">
                            <div className="approval-label">
                                Child
                            </div>
                            <div className="image-wrap">
                                <img className="parent-image" src={
                                    nullUndefinedEmptyCheck(child?.image) ? child?.image : profile_default
                                    
                                    }/>
                            </div>
                            <h5>{child?.firstname + child?.lastname }</h5>
                            <p>{child?.email}</p>
                            <p>{child?.phone_number}</p>
                            <div className="btn-wrap">
                            <button className="bnt btn-primary" onClick={()=>onNavigate(child?.id)}>View Bookings</button>
                            </div>
                            
                        </Card>
                        )
                    })
                }
                
            </div>
        </div>
    )
};

export default UserDetail;