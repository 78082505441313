import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { TOKENS } from "../Constants/Constants";
import { GetSessionStorageData } from "../../Services/Helpers/Helper";

const PublicRoutesCheck = () => {
  const token = GetSessionStorageData(TOKENS.ACCESS_TOKEN);
 return token ? (
    <>
      <Navigate to="/dashboard" />
    </>
  ) : (
    <>
      {" "}
      <Outlet />
    </>
  );
};

export default PublicRoutesCheck;
