import { Dialog, DialogTitle, DialogContent, RadioGroup, FormControlLabel } from "@mui/material";
import { Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Radio } from "react-loader-spinner";
import { API_RESPONSE, I18_TRANSLATION_COMMON, TOASTER_CONFIGS } from "../../../Utilities/Constants/Constants";
import "./CreateEditCampDiscount.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";

const CreateEditCampDiscount = (props:any) => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [initialValue, setInitialValue] = useState({
        buy_sessions: '',
        discount: ''
      })


      useEffect(()=>{
        if(props.mode==='edit'){
            setInitialValue({
                buy_sessions : props.data.buy_sessions,
                discount     : props.data.discount
            })
        }
      },[props])

      const discountValidationSchema = yup.object({
        buy_sessions: yup.number().min(1,"Count should be minimum 1").required("Please enter the child count").typeError("Please enter valid number"),
        discount: yup.number().min(0,"Discount should be minimum 0").max(100,"Discount should be maximum 100").required("Please enter the discount percentage").typeError("Please enter valid number")
      });

    const onClose = () => {
        return props.dialogStatus(false);
      };

    const onCreateDiscount = (payload:any) => {
       payload.discount_type = 3;
       if(props.mode==='edit'){
        axiosInstance.put(API_END_POINTS.MANAGE_CAMPS_DISCOUNT+ `?id=${props.data.id}`,payload).then((response: any) => {
            responseAction(response);
          });
       }else{
        axiosInstance.post(API_END_POINTS.MANAGE_CAMPS_DISCOUNT,payload).then((response: any) => {
            responseAction(response);
          });
       }      
    };

    const responseAction = (response:any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
            toast.success(response.data.message, TOASTER_CONFIGS);
            onClose();
          };
    };



    return(
        <div className="camp-discount-wrapper">
            <Dialog
        className="create-expert-dialog"
        open={props.modalState}
        sx={{ "& .MuiDialog-paper": "cus-dialog" }}
      >
        <DialogTitle className="title-wrap">
          {t(props.data?.buy_slots ? "Update Discount" : "Create Discount")}
          <div className="close-wrap">
            <CloseIcon className="close-icon" onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={discountValidationSchema}
            initialValues={initialValue}
            onSubmit={(values) => {
              onCreateDiscount(values);
            }}
          >
            {(formProps) => {
              return (
                <div className="discount-form-wrapper">
                  <div className="input-wrap">
                    <p className="input-label">Child Count</p>
                    <Field
                      name="buy_sessions"
                      className="firstname input"
                      placeholder="Enter count of child"
                    //   onKeyPress={validateEnglish}
                    />
                    <div className="errorMessage">
                      {formProps.touched.buy_sessions &&
                        formProps.errors.buy_sessions && (
                          <p>{t(`${formProps.errors.buy_sessions}`)}</p>
                        )}
                    </div>
                  </div>

                  <div className="input-wrap">
                    <p className="input-label">Discount in %</p>
                    <Field
                      name="discount"
                      className="firstname input"
                      placeholder="Enter discount"
                    //   onKeyPress={validateEnglish}
                    />
                    <div className="errorMessage">
                      {formProps.touched.discount &&
                        formProps.errors.discount && (
                          <p>{t(`${formProps.errors.discount}`)}</p>
                        )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="button-wrap-profile">
                      <button
                        className="btn  cancel-btn  mt-2"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="btn save-btn  mt-2"
                        type="submit"
                        onClick={() => {
                        //   setFormSubmitted(true);
                          formProps.handleSubmit();
                        }}
                      >
                        {t(props.data?.buy_slots ? "Update" : "Save")}
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
        </div>
    )
};

export default CreateEditCampDiscount;

