import "./TutorsList.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog/ConfirmationDialog";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  MenuItem,
  Select,
  Slider,
  TextField,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import {
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  ROUTES,
  SORTING_CONSTANT,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ReasonDialog from "../../../Shared/ReasonDialog/ReasonDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Switch from "@mui/material/Switch";
import { TableSortLabel } from "@mui/material";
import { checkValueNullUndefinedEmpty } from "../../../Services/Helpers/Helper";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import view from "../../../../Assets/global/view.svg";
import edit from "../../../../Assets/global/edit.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const ManageTutors = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [page, setPage] = useState(1);
  const [tutorsList, setTutorsList]: any = useState([]);
  const [currentElement, setCurrentElement] = useState(COMMON_CONSTANTS.ZERO);
  const [modalStatus, setmodalStatus] = useState(false);
  const [totalPage, setTotalCount] = useState(COMMON_CONSTANTS.ZERO);
  const navigate = useNavigate();
  const dialogContent = DIALOG_CONSTANT.DELETE_CONTENT;
  const [searchValue, setSearchValue] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [initialRender, setInitialRender] = useState(true);
  const [rowPerPage, setRowPerPage] = useState<any>(10);
  const [currentDialog, setCurrentDialog] = useState("");
  const [hasProducts, setHasProducts] = useState(true);
  const dialogTitle = DIALOG_CONSTANT.TITLE_BLOCK;
  const [filterApplied, setFilterApplied] = useState(false);
  const [sliderValue, setSliderValue] = React.useState<number[]>([]);
  const [experienceRange, setExperienceRange] = useState<number[]>([]);
  const [tutorTypeList, setTutorTypeList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [currentExperienceRange, setCurrentExperienceRange] = useState<
    number[]
  >([
    experienceRange[COMMON_CONSTANTS.ZERO],
    experienceRange[COMMON_CONSTANTS.ONE],
  ]);
  const [averageFees, setAverageFees] = useState<number[]>([]);
  const [tutorType, setTutorType]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [gender, setGender]: any = useState([]);
  const [teachingMode, setTeachingMode]: any = useState([]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [courseCategoryList, setCourseCategoryList]: any = useState([]);
  const [currentCategoryList, setCurrentCategoryList]: any = useState([]);
  const [searchPayloadValue, setSearchPayloadValue]: any = useState("");
  const [selectedLanguage, setCurrentLanguage]: any = useState([]);
  const [ratingList, setRatingList]: any = useState([]);
  const location = useLocation();
  useEffect(() => {
    getTutorsList();
  }, [page, currentSort, rowPerPage, filterPayload, idSort, currentSort]);

  /* Updated on change in search value */

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      getFilterValues();
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        getTutorsList();
      }
    }
  }, [searchValue]);

  /** Tutor list payload*/
  const tutorListPayload = () => {
    return {
      page: page,
      limit: parseInt(rowPerPage),
      status: "approved",
      sort: currentSort,
      search: searchValue.trim(),
      start_date: "",
      end_date: "",
      filter: filterPayload,
    };
  };
  const getTutorsList = () => {
    axiosInstance
      .post(API_END_POINTS.GET_TUTORS_LIST, tutorListPayload())
      .then((response) => {
        setTutorsList(response.data.result.data);
        tutorLengthCheck(response.data.result.data);
        setTotalCount(response.data.result.total_page);
      });
  };

  const getFilterValues = () => {
    axiosInstance
      .get(API_END_POINTS.FILTER_INITIAL_VALUS)
      .then((filterResponse: any) => {
        if (filterResponse.data.status === true) {
          let data = filterResponse.data.result.data[COMMON_CONSTANTS.ZERO];
          setExperienceRange([parseInt(data.experience.Min), parseInt(data.experience.Max)]);
          setCurrentExperienceRange([data.experience.Min, data.experience.Max])
          setTutorTypeList(data.tutor_type);
          setLanguageList(data.teach_languages);
          setCourseCategoryList(data.course_category);
          setRatingList(data.rating);
        }
      });
  };

  const tutorLengthCheck = (data: any) => {
    data.length > 0 ? setHasProducts(true) : setHasProducts(false);
  };

  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  /* Setting sort type */
  const onChangeSelect = (e: any) => {
    setCurrentSort(e.target.value);
  };

  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Delete category API call */
  const onDelCategory = (data: any) => {
    setmodalStatus(false);
    if (data === true) {
      axiosInstance
        .delete(API_END_POINTS.DELETE_CATEGORY + "?id=")
        .then((res) => {
          if (res.data.status === true) {
            getTutorsList();
          }
        });
    }
  };

  const approveRejectTutor = (payload: any, id: any) => {
    axiosInstance
      .put(API_END_POINTS.MANAGE_TUTOR + "?id=" + id, payload)
      .then((res: any) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getTutorsList();
        }
      });
  };

  const blockUnblockTutor = (id: any, status: any) => {
    if (status === false) {
      setDialogOpenStatus(true);
    } else {
      axiosInstance
        .put(
          API_END_POINTS.BLOCK_TUTOR +
          "?id=" +
          id +
          "&block_type=" +
          VALIDATION_CONSTANT.TUTOR + "&reason="
        )
        .then((res) => {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getTutorsList();
        });
    }
  };

  const onCloseDialog = () => {
    setDialogOpenStatus(false);
  };

  const onSubmitReason = (value: any) => {
    if (currentDialog === VALIDATION_CONSTANT.APPROVED_USER) {
      let payload = {
        status: VALIDATION_CONSTANT.REJECTED,
        reason: value.reason,
      };
      setDialogOpenStatus(false);
      approveRejectTutor(payload, currentUser);
    } else {
      let payload = {
        reason: value.reason,
      };
      setDialogOpenStatus(false);
      axiosInstance
        .put(
          API_END_POINTS.BLOCK_TUTOR +
          "?id=" +
          currentUser +
          "&block_type=" +
          VALIDATION_CONSTANT.TUTOR +
          "&reason=" + value.reason,
          payload
        )
        .then((res) => {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getTutorsList();
        });
    }
  };

  const hadleExperienceSlider = (event: Event, newValue: number | number[]) => {
    setCurrentExperienceRange(newValue as number[]);
    // if (Array.isArray(newValue)) {
    //   const formattedRange: any = newValue?.map((value) => {
    //     const nearest = parseInt(value.toString());
    //     return nearest;
    //   });
    //   setCurrentExperienceRange(formattedRange);
    // }
  };

  // const hadleFeesSlider = (event: Event, newValue: number | number[]) => {
  //   if (Array.isArray(newValue)) {
  //     const formattedRange: any = newValue?.map((value) => {
  //       const nearest = parseInt(value.toString());
  //       return nearest;
  //     });
  //     setAverageFees(formattedRange);
  //   }
  // };

  function valuetext(value: number) {
    return `${value}`;
  }

  const onSetRating = (e: any) => {
    setRating(e.target.value);
  };

  //  const onSetTutorType

  const onChangeGender = (e: any) => {
    setGender(e.target.value);
  };

  const onChangeTeachingMode = (e: any) => {
    setTeachingMode(e.target.value);
  };

  const onChangeTutorType = (e: any) => {
    setTutorType(e.target.value);
  };

  const setCategoryList = (e: any) => {
    setCurrentCategoryList(e.target.value);
  };

  const onSetLanguage = (e: any) => {
    setCurrentLanguage(e.target.value);
  };

  useEffect(() => {
    if (filterApplied && currentCategoryList.length === COMMON_CONSTANTS.ZERO) {
      onApplyFilter();
    }
  }, [currentCategoryList]);

  const resetFilter = () => {
    setTeachingMode([]);
    // setTabData([]);
    setTutorType([]);
    setCurrentLanguage([]);
    setRating([]);
    setGender([]);
    setCurrentExperienceRange([
      experienceRange[COMMON_CONSTANTS.ZERO],
      experienceRange[COMMON_CONSTANTS.ONE],
    ]);

    setCurrentCategoryList([]);
  };

  /* Handling ID sort */
  const handleSortID = () => {
    idSort == SORTING_CONSTANT.ASC
      ? setIdSort(SORTING_CONSTANT.DSC)
      : setIdSort(SORTING_CONSTANT.ASC);
    idSort == SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_DESCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_ASCENDING);
    getTutorsList();
  };

  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE);
    setRowPerPage(event.target.value);
  };

  const onApplyFilter = () => {
    let teachingPayload: any = [];
    teachingMode?.map((value: any) => {
      teachingPayload.push(
        value === "Online"
          ? COMMON_CONSTANTS.ONE
          : COMMON_CONSTANTS.TWO
      );
    });

    setFilterPayload({
      experience: [
        {
          start: currentExperienceRange[COMMON_CONSTANTS.ZERO],
          end: currentExperienceRange[COMMON_CONSTANTS.ONE],
        },
      ],
      teaching_mode: teachingPayload,
      // avg_fees: [
      //   {
      //     start: averageFees[COMMON_CONSTANTS.ZERO],
      //     end: averageFees[COMMON_CONSTANTS.ONE],
      //   },
      // ],
      // tutor_type: tutorType,
      // teach_lang: selectedLanguage,
      rating: rating,
      course_category: currentCategoryList,
    });
  };

  return (
    <div
      className="listMainWrapper"
      dir={currentLanguage === "English" ? "ltr" : "rtl"}
    >
      <ConfirmationDialog
        content={dialogContent}
        modalState={modalStatus}
        dialogStatus={onDelCategory}
      />
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
        submitReason={onSubmitReason}
        title={dialogTitle}
      />
      <div className="select-wrap">
        <div className="btn-wrap">
          <div>
            <Box sx={{ width: "100%" }}></Box>
          </div>
          <div className="search-wrapup">
            <div
              className={
                currentLanguage === "English"
                  ? "custom_tutor_search"
                  : "custom_tutor_search-rtl"
              }
            >
              <TextField
                id="outlined-basic"
                placeholder={t("Search") || ""}
                variant="outlined"
                value={searchValue}
                onChange={onUpdateSearch}
                onKeyUp={(event: any) => {
                  if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                    getTutorsList();
                    setPage(1);
                  }
                }}
              />
              <SearchIcon
                className="icon"
                onClick={() => {
                  getTutorsList();
                }}
              />
            </div>
          </div>
          <div
            className="filter-wrap"
            onClick={() => {
              setFilterApplied(!filterApplied);
            }}
          >
            {!filterApplied && <FilterAltIcon />}
            {filterApplied && <FilterAltOffIcon />}
          </div>
        </div>
      </div>

      {filterApplied && (
        <div className="filter-wrap-container">
          <div className="filter-items-wrap">
            <div className="filter-item">
              <Box className="slider-box">
                <label className="filter-label">{t("Experience")}:</label>
                <Slider
                  min={experienceRange[COMMON_CONSTANTS.ZERO]}
                  step={COMMON_CONSTANTS.ONE}
                  max={experienceRange[COMMON_CONSTANTS.ONE]}
                  getAriaLabel={() => "Price Range"}
                  value={currentExperienceRange}
                  onChange={hadleExperienceSlider}
                  valueLabelDisplay={"on"}
                  // marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory mt-4"
                />
              </Box>
            </div>


            <div className="filter-item">
              <label className="filter-label">{t("Teaching Mode")}</label>
              <Select
                multiple
                value={teachingMode}
                className="filter-select"
                onChange={onChangeTeachingMode}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="Online">
                  <Checkbox checked={teachingMode.includes("Online")} />
                  {t("Online")}
                </MenuItem>
                <MenuItem value="Offline">
                  {" "}
                  <Checkbox checked={teachingMode.includes("Offline")} />
                  {t("On-premises")}
                </MenuItem>
              </Select>
              {/*  <select className="filter-select">
                <option>-None-</option>
                <option value={"1"}>Online</option>
                <option value={"2"}>Offline</option>
              </select> */}
            </div>
            {/* <div className="filter-item">
              <label className="filter-label">{t("Tutor Type")}</label>
              <Select
                multiple
                value={tutorType}
                className="filter-select"
                onChange={onChangeTutorType}
                renderValue={(selected) => selected.join(", ")}
              >
                {tutorTypeList.map((type: any, index: any) => {
                  return (
                    <MenuItem value={type}>
                      <Checkbox checked={tutorType.includes(`${type}`)} />
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </div> */}
            {/* <div className="filter-item">
              <label className="filter-label">{t("Teaching Language")}</label>
              <Select
                multiple
                value={selectedLanguage}
                className="filter-select"
                onChange={onSetLanguage}
                renderValue={(selected) => selected.join(", ")}
              >
                {languageList.map((language: any, index: any) => {
                  return (
                    <MenuItem value={language}>
                      <Checkbox
                        checked={selectedLanguage.includes(`${language}`)}
                      />
                      {language}
                    </MenuItem>
                  );
                })}
              </Select>
            </div> */}
            <div className="filter-item">
              <label className="filter-label">{t("Rating")}</label>
              <Select
                multiple
                value={rating}
                className="filter-select"
                onChange={onSetRating}
                renderValue={(selected) => selected.join(", ")}
              >
                {ratingList?.map((ratings: any, index: any) => {
                  return (
                    <MenuItem value={ratings}>
                      <Checkbox
                        checked={rating?.includes(ratings)}
                      />
                      {ratings}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {/* <div className="filter-item">
              <label className="filter-label">{t("Gender")}</label>
              <Select
                multiple
                value={gender}
                className="filter-select"
                onChange={onChangeGender}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="male">
                  <Checkbox checked={gender.includes("male")} />
                  {t("Male")}
                </MenuItem>
                <MenuItem value="female">
                  {" "}
                  <Checkbox checked={gender.includes("female")} />
                  {t("Female")}
                </MenuItem>
                <MenuItem value="others">
                  {" "}
                  <Checkbox checked={gender.includes("others")} />
                  {t("Others")}
                </MenuItem>
              </Select>
            </div> */}
            <div className="filter-item">
              <label className="filter-label">{t("Category")}</label>
              <Select
                multiple
                value={currentCategoryList}
                className="filter-select"
                onChange={setCategoryList}
                renderValue={(selected) => selected.join(", ")}
              >
                {courseCategoryList?.map((course: any, index: any) => {
                  return (
                    <MenuItem value={course}>
                      <Checkbox
                        checked={currentCategoryList.includes(`${course}`)}
                      />
                      {course}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="filter-buttons-wrap">
            <button className="btn btn-primary" onClick={resetFilter}>
              {t("RESET")}
            </button>
            <button className="btn btn-secondary" onClick={onApplyFilter}>
              {t("APPLY")}
            </button>
          </div>
        </div>
      )}

      <div className="tableWrapper">
        <div>
          <TableContainer component={Paper} className="categoryTable">
            <Table>
              <TableHead className="">
                <TableRow className="">
                  <TableCell>{t("S.NO")}</TableCell>

                  <TableCell onClick={() => handleSortID()}>
                    <TableSortLabel active={true} direction={idSort}>
                      {t("Tutor ID")}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>{t("Name")}</TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Phone")}</TableCell>
                  <TableCell>{t("Location")}</TableCell>
                  <TableCell>{t("Highest Qualification")}</TableCell>
                  <TableCell>{t("Rating")}</TableCell>
                  {/* <TableCell>{t("Status")}</TableCell> */}
                  {location.pathname == ROUTES.TUTOR_BOOKINGS && (
                    <TableCell>{t("Active Status")}</TableCell>
                  )}
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hasProducts &&
                  tutorsList?.map((tutor: any, index: any) => {
                    return (
                      <TableRow key={index + 1}>
                        {/* <TableCell>{  index + 1 + (totalPage * rowPerPage) }</TableCell> */}
                        <TableCell>
                          {page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}
                        </TableCell>

                        <TableCell>{tutor.id}</TableCell>

                        <TableCell className="name-cell row-capitalize">
                          {tutor.profile_details.firstname}{" "}
                          {tutor.profile_details.lastname}
                        </TableCell>
                        <TableCell>{tutor.profile_details.email}</TableCell>
                        <TableCell>
                          {tutor.profile_details.phone_number}
                        </TableCell>
                        <TableCell>{tutor.address}</TableCell>
                        <TableCell>{tutor.high_education}</TableCell>
                        <TableCell>{tutor?.rating}</TableCell>
                        {/* <TableCell>
                          {" "}
                          {
                            tutor.status === VALIDATION_CONSTANT.PENDING ? (
                              <Chip
                                className="row-capitalize"
                                label={tutor.status}
                                color="warning"
                              />
                            ) : tutor.status ===
                              VALIDATION_CONSTANT.APPROVED ? (
                              <Chip
                                className="row-capitalize"
                                label={tutor.status}
                                color="success"
                              />
                            ) : (
                              <Chip
                                className="row-capitalize"
                                label={tutor.status}
                                color="error"
                              />
                            )
                          }
                        </TableCell> */}
                        {location.pathname == ROUTES.TUTOR_BOOKINGS && (
                          <TableCell>
                            {tutor?.is_block === true
                              ? t("Blocked")
                              : t("Active")}
                          </TableCell>
                        )}
                        <TableCell>
                          <div className="action-wrap">
                            <Tooltip
                              title={t("View Course")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  location.pathname === ROUTES.TUTOR_BOOKINGS ?
                                    navigate(`/manage-tutors/bookings/${tutor.user}`)
                                    :
                                    navigate(`/manage-tutors/${tutor.user}`);
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                            {location.pathname !== ROUTES.TUTOR_BOOKINGS && <div>
                              {tutor?.is_block === true ? (
                                <div
                                  className="reject_btn_wrapper"
                                  onClick={() => {
                                    setCurrentUser(tutor.user);
                                    blockUnblockTutor(
                                      tutor.user,
                                      tutor?.is_block
                                    );
                                  }}
                                >
                                  <div className="accept_btn_label">
                                    {t("Unblock")}
                                  </div>
                                  <div className="lock_icon_wrapper">
                                    <HttpsIcon className="lock_icon" />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="accept_btn_wrapper"
                                  onClick={() => {
                                    setCurrentUser(tutor.user);
                                    blockUnblockTutor(
                                      tutor.user,
                                      tutor?.is_block
                                    );
                                  }}
                                >
                                  <div className="unlock_icon_wrapper">
                                    <LockOpenTwoToneIcon className="unlock_icon" />
                                  </div>
                                  <div className="accept_btn_label">
                                    {t("Block")}
                                  </div>
                                </div>
                              )}
                            </div>}

                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasProducts && <NoProducts />}
        </div>
      </div>
    </div>
  );
};
export default ManageTutors;
