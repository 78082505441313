import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import "./SchoolStatusList.scss";
import NoProducts from "../../../../../Shared/NoProducts/NoProducts";
import { useTranslation } from "react-i18next";
import view from "../../../../../../Assets/global/view.svg";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import {
  API_RESPONSE,
  I18_TRANSLATION_COMMON,
  SERVICES_STATUS,
  VALIDATION_CONSTANT,
} from "../../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../../Utilities/Constants/ApiEndpoints";
import { checkEmpty } from "../../../../../Services/Helpers/Helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const SchoolStatusList = (schoolListProps: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [schoolsData, setSchoolsData] = useState([]);
  const [hasProducts, setHasProducts] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    schoolListProps.totalCount(0)
  setSchoolsData([]);
    getSchoolList();
  }, [
    schoolListProps.tabValue,
    schoolListProps.rowPerPage,
    schoolListProps.page,
    schoolListProps.statusUpdated,
    ,
    schoolListProps.filterPayload,
    schoolListProps.search,
  ]);

  /**Tutors list payload */
  const getSchoolStatusListPayload = () => {
    return {
      page: schoolListProps.page,
      limit: parseInt(schoolListProps.rowPerPage),
      status: schoolListProps.tabValue.toLowerCase(),
      sort: schoolListProps.currentSort,
      search: schoolListProps.search,
      curriculam: [],
      filter: schoolListProps.filterPayload,
    };
  };

  /**School list */
  const getSchoolList = () => {
    axiosInstance
      .post(API_END_POINTS.GET_SCHOOL_LIST, getSchoolStatusListPayload())
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        )
        setSchoolsData(recreationalStatusResponse.data.result.data);
        schoolListProps.totalCount(recreationalStatusResponse.data.result.total_page)
        setHasProducts(checkEmpty(recreationalStatusResponse.data.result.data));
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("S.NO")}</TableCell>
              <TableCell onClick={() => schoolListProps.handleCodeSort()}>
                <TableSortLabel
                  active={true}
                  direction={schoolListProps.idSort}
                >
                  <p className="sort_label">{t("School Name")}</p>
                </TableSortLabel>
              </TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell>{t("Location")}</TableCell>
              <TableCell className="table_actions_head">
                {t("Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          {hasProducts && (
            <TableBody>
              {schoolsData.map((schoolDataElements: any, index: any) => {
                return (
                  <>
                    <TableRow key={index + 1}>
                      <TableCell>
                        {schoolListProps.page - 1 === 0
                          ? index + 1
                          : (schoolListProps.page - 1) *
                              schoolListProps.rowPerPage +
                            (index + 1)}
                      </TableCell>
                      <TableCell className="name_label">{schoolDataElements.school_name}</TableCell>
                      <TableCell>{schoolDataElements.email}</TableCell>
                      <TableCell>
                        {schoolDataElements?.authority_details[0] ? schoolDataElements?.authority_details[0].address : schoolDataElements?.address }
                      </TableCell>
                      <TableCell>
                        <div className="table_actions">
                          <Tooltip
                            title={t("View Category")}
                            placement="bottom"
                          >
                            {schoolListProps.tabValue=== 
                            SERVICES_STATUS.PENDING ? <button className="verify_button_wrapper" 
                            onClick={()=>{
                              navigate(
                                `/school-status-list/${schoolDataElements.id}`
                              );
                            }}
                            >Verify</button> : 
                            <img
                              className="view_edit_icons"
                              src={view}
                              alt="Learn and Play"
                              onClick={() => {
                                navigate(
                                  `/school-status-list/view/${schoolDataElements.id}`
                                );
                              }}
                            />}
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {hasProducts && (
        <div className="tablePagination">
          <div className="row">
            <div className="col-md-7">
              <div className="pagination-wrap">
                <Pagination
                  count={schoolListProps.totalPage}
                  page={schoolListProps.page}
                  onChange={schoolListProps.handleChangePage}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="rows-per-page">
                <p>{t("Rows per page")}:</p>
                <select
                  className="rows-select"
                  value={schoolListProps.rowPerPage}
                  onChange={schoolListProps.onRowChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hasProducts && <NoProducts></NoProducts>}
    </>
  );
};
export default SchoolStatusList;
