import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./SlotsList.scss";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { API_RESPONSE, COMMON_CONSTANTS, NUMBER_CONSTANTS } from "../../../Utilities/Constants/Constants";
import { Card } from "@mui/material";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import NoProducts from "../../NoProducts/NoProducts";
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const SlotsList = () => {

  const { id, slot_id } = useParams();
  const navigate = useNavigate();
  const [slotsList, setSlotsList] = useState<any>([])
  const [hasProducts, setHasProducts] = useState(API_RESPONSE.TRUE);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlParam = searchParams.get(COMMON_CONSTANTS.TYPE);

  /* Get slots list  */
  useEffect(() => {
    if (id)
      getSlotsList();
  }, [id]);

  /* Getting slots list API call */
  const getSlotsList = () => {
    axiosInstance.post(API_END_POINTS.SLOTS_LIST + `?id=` + id, {
      "page": 1,
      "limit": 10
    }).then((response: any) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setSlotsList([...response.data.result.data]);
        response.data.result.data.length > 0 ? setHasProducts(API_RESPONSE.TRUE) : setHasProducts(API_RESPONSE.FALSE);
      }
    })
  };

  return (
    <div className="slots-list-wrapper">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              alt="Learn and Play"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="name-title mt-1 ms-2">
              {"Sessions List"}
            </h5>
          </div>
        </div>
      </div>
      <div className="row">
        {
          slotsList?.map((slot: any, id: any) => {
            return (
              <div key={id + 1} className={(location.pathname.split("/")[1] === "manage-tutors" ||  urlParam==="personal") ? "col-md-4 slot-card-wrap" :"col-md-4 slot-card-wrap"} onClick={() => {
                location.pathname.split("/")[1] === "manage-tutors" ?
                  navigate(`/manage-tutors/bookings/0/booked/${slot.id}`) :
                  navigate(`/manage-recreational/bookings/0/${urlParam}/${slot.id}`)
              }}>
                {(location.pathname.split("/")[1] === "manage-tutors" ||  urlParam==="personal") && <Card className="card-data">
                  <p className="slot-title">{slot.session_title}</p>
                  <p className="slots-label">Slots:</p>
                  <div className="count-wrapper">
                    <div className="count-card">
                      <p className="card-count">{slot?.total_slots_count < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.total_slots_count : slot?.total_slots_count}</p>
                      <p>Total</p>
                    </div>
                    <div className="count-card">
                      <p className="card-count">{slot?.booked_slots_count < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.booked_slots_count : slot?.booked_slots_count}</p>
                      <p>Booked</p>
                    </div>
                    <div className="count-card">
                      <p className="card-count">{slot?.unbooked_slots_count < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.unbooked_slots_count : slot?.unbooked_slots_count}</p>
                      <p>Available</p>
                    </div>
                    {location.pathname.split("/")[1] === "manage-tutors" && <div className="count-card">
                      <p className="card-count">{slot?.did_not_attend < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.did_not_attend : slot?.did_not_attend}</p>
                      <p>Not Attended</p>
                    </div>}
                  </div>
                </Card>}
                {urlParam==="camp"  && <Card className="card-data">
                  <p className="slots-label">{slot.camp_session_title}</p>
                  <div className="date-time-wrap">
                  {/* <DateRangeIcon/> */}
                  <p className="slot-date mb-0">{slot.start_date} - {slot.end_date}</p>
                  </div>
                  {/* <div className="date-time-wrap">
                  <AccessTimeIcon/>
                  <p className="slot-date mb-0">{slot.start_time} - {slot.end_time}</p>
                  </div> */}
                  
                  
                  <div className="camp-count-wrapper">
                  <div className="count-card">
                      <p className="card-count">{slot?.total_class < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.total_class : slot?.total_class}</p>
                      <p className="card-text">Total Class</p>
                    </div>
                    <div className="count-card">
                      <p className="card-count">{slot?.total_batch_size < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.total_batch_size : slot?.total_batch_size}</p>
                      <p className="card-text">Batch Size</p>
                    </div>
                    <div className="count-card">
                      <p className="card-count">{slot?.booked_session < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.booked_session : slot?.booked_session}</p>
                      <p className="card-text">Size Occupied</p>
                    </div>
                    <div className="count-card">
                      <p className="card-count">{slot?.batch_size < NUMBER_CONSTANTS.TEN ? `${NUMBER_CONSTANTS.ZERO}` + slot?.batch_size : slot?.batch_size}</p>
                      <p className="card-text">Available</p>
                    </div>
                  </div>
                </Card>}
              </div>
            )
          })
        }
        {
          !hasProducts && <div>
            <NoProducts />
          </div>
        }
      </div>
    </div>
  )
};

export default SlotsList;