import { useEffect, useState } from "react";
import ReasonDialog from "../../../../../Shared/ReasonDialog/ReasonDialog";
import "./TutorVerificationStatus.scss";
import {
  DIALOG_CONSTANT,
  SERVICES_STATUS,
} from "../../../../../Utilities/Constants/Constants";
const TutorVerificationStatus = (tutorStatusProps: any) => {
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const dialogTitle = DIALOG_CONSTANT.TUTOR_REJECT_REASON;
  const [tutorDetails, setTutorDetails] = useState<any>({});
  const [questions, setQuestions] = useState<any>([]);

  const onCloseDialogBox = () => {
    setDialogOpenStatus(false);
  };
  const onSubmitReason = (value: any) => {
    setDialogOpenStatus(false);
    tutorStatusProps.updateTutorverification({
      status: "rejected",
      profile_verified: false,
      reason: value.reason,
    });
  };
  const openRejectDialog = () => {
    setDialogOpenStatus(true);
  };

  useEffect(() => {
    tutorStatusProps.getTutorDetails();
  }, []);

  useEffect(() => {
    setTutorDetails(tutorStatusProps.tutorDetail);
    setQuestions(Object.keys(tutorStatusProps.tutorDetail.question));
  }, [tutorStatusProps.tutorDetail]);

  return (
    <>
      <div className="tutor_verification_summary_wrapper">
        <ReasonDialog
          modalState={dialogOpenStatus}
          dialogStatus={onCloseDialogBox}
          submitReason={onSubmitReason}
          title={dialogTitle}
        />
        {questions?.length !== 0 && (
          <div className="certificates_wrapper tutor_info_label_wrapper">
            <div className="col-md-12 row">
              {questions?.map((questionsElement: any, index: any) => {
                return (
                  <>
                    <div className="tutor_info_icon_label">
                      {questionsElement}
                    </div>
                    <div className="tutor_info">
                      {tutorDetails.question[questionsElement]}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
        <div className="col-md-12 tutor_info_label_wrapper">
          <div className="tutor_info_icon_label">About Me</div>
          <div className="tutor_info">{tutorDetails.about_me}</div>
        </div>
        {/* <div className="col-md-12 tutor_info_label_wrapper">
          <div className="tutor_info_icon_label">Additional Notes</div>
          <div className="tutor_info">{tutorDetails.additional_notes ? tutorDetails.additional_notes : "No Data Available"}</div>
        </div> */}
        {tutorDetails.status === SERVICES_STATUS.REJECTED.toLowerCase() && (
          <div className="col-md-12 tutor_info_label_wrapper">
            <div className="tutor_info_icon_label">Rejected Reason</div>
            <div className="tutor_info">{tutorDetails.reason}</div>
          </div>
        )}
        {tutorDetails.basic_review_comment && (
          <div className="col-md-12 tutor_info_label_wrapper">
            <div className="tutor_info_icon_label">
              Comments on Personal and professional Verification
            </div>
            <div className="tutor_info">
              {tutorDetails.basic_review_comment}
            </div>
          </div>
        )}
        {tutorDetails.professional_review_comment && (
          <div className="col-md-12 tutor_info_label_wrapper">
            <div className="tutor_info_icon_label">
              Comments on Documents and Identity Verification
            </div>
            <div className="tutor_info">
              {tutorDetails.professional_review_comment}
            </div>
          </div>
        )}
        {(tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase() || tutorDetails.is_new_request) && (
          <div className="approve_reject_button_wrapper">
            <button className="reject_button" onClick={openRejectDialog}>
              Reject
            </button>
            <button
              className="approve_button"
              onClick={() => {
                tutorStatusProps.updateTutorverification({
                  status: "approved",
                  profile_verified: true,
                  reason: "",
                });
              }}
            >
              Approve
            </button>
          </div>
        )}
        <div className="stepper_actions_wrapper">
          <button className="back_button" onClick={tutorStatusProps.handleBack}>
            Back
          </button>
        </div>
      </div>
    </>
  );
};
export default TutorVerificationStatus;
