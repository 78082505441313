import { Dialog, DialogTitle, DialogContent, Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, I18_TRANSLATION_COMMON, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS } from "../../../Utilities/Constants/Constants";
import { useEffect, useRef, useState } from "react";
import "./AddEditBannerImg.scss";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { S3DataGetApi, checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck, uploadFileToS3 } from "../../../Services/Helpers/Helper";
import { ERROR_MESSAGES, THEMEPARK_MESSAGES } from "../../../Utilities/Constants/Validations";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Field } from "formik";
import upload_image from "../../../../Assets/schools/upload_image.svg";
import close from "../../../../Assets/schools/close.svg";
import { useSelector } from "react-redux";

const AddEditBannerImg = (props: any) => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [fieldValue, setFieldValue] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
    const eventImage = useRef<HTMLInputElement | null>(null);
    const [currentImage, setCurrentEventImage]: any = useState([]);
    const onClose = () => {
        setFieldValue("");
        setFormSubmitted(API_RESPONSE.FALSE);
        return props.dialogStatus(API_RESPONSE.FALSE);
    };
    const aws: any = useSelector((state: any) => {
        return state.awsReducer.aws;
    });


    useEffect(() => {
        getBannerImage();
        if (props.data.mode === "Edit") {
            getBannerImage();
            setFieldValue(props.data.payload.category_name)
        } else {
            setFieldValue("");
            setFormSubmitted(API_RESPONSE.FALSE);
        }
    }, [props]);

    const getBannerImage = () => {
        axiosInstance
            .get(API_END_POINTS.THEME_PARK_BANNER_CREATE)
            .then((res) => {
                if (res.data.status === API_RESPONSE.TRUE) {
                    setCurrentEventImage(res.data.result?.data ? res.data.result?.data : []);
                }
            });
    }



    const submitApiCall = (API: any, payload: any) => {
        axiosInstance
            .post(API, payload)
            .then((res) => {
                if (res.data.status === API_RESPONSE.TRUE) {
                    toast.success(res.data.message, TOASTER_CONFIGS);
                    setFieldValue("");
                    setFormSubmitted(API_RESPONSE.FALSE);
                    onClose();
                }
            });

    }

    const onSubmit = () => {
        setFormSubmitted(API_RESPONSE.TRUE);
        var payload = {
            banner: currentImage
        }
        submitApiCall(API_END_POINTS.THEME_PARK_BANNER_CREATE, payload)
    }

    /* Delete tag from taglist */
    const handleDelete = (i: any) => () => {
        setCurrentEventImage(
            currentImage.filter((item: any, index: any) => index !== i)
        );
    };

    /* Updating event image */
    const onUploadEventImage = async (event: any) => {
        try {
            if (event.target.files[0].type.split("/")[0] === "image") {
                const uploadedImage = await S3DataGetApi(event);
                setCurrentEventImage((prev: any) => [
                    ...prev,
                    uploadedImage,
                ]);
            } else {
                toast.error(t(THEMEPARK_MESSAGES.IMAGE_FORMAT));
            }
        } catch (error) {
        }
    };


    return (
        <div className="program-create-wrapper">
            <Dialog
                className="program-dialog"
                open={props.modalState}
                sx={{ "& .MuiDialog-paper": "cus-dialog" }}
            >
                <DialogTitle className="title-wrap">
                    {props.data.mode === "Edit" ? "Edit Banner Image" : "Add Banner Image"}
                    <div className="close-wrap">
                        <CloseIcon className="close-icon" onClick={onClose} />
                    </div>
                </DialogTitle>

                <DialogContent>

                    <div className="create-wrapper">
                        <div className="ThemePark__Banner_img__wapper">
                            <input
                                className="image_input"
                                type="file"
                                accept="image/png,image/jpg,image/jpeg,image/svg"
                                onChange={onUploadEventImage}
                                ref={eventImage}
                            />
                            <div className="add_images_wrapper">
                                <div
                                    className="add_images_cotainer"
                                    onClick={() => eventImage.current?.click()}
                                >
                                    <img src={upload_image} alt="Learn and Play" />
                                    <div>{t("Add Image")}</div>
                                </div>
                                <div className="text_contant">Recommended size: 1920x496 with JPEG,PNG</div>
                            </div>
                        </div>
                        <div className="error-message-wrap">
                            {formSubmitted && checkValueNullUndefinedEmpty(fieldValue) ? (
                                <div className="errorMessage">
                                    {`${ERROR_MESSAGES.ENTER_VALUE}`}
                                </div>
                            ) : null}
                        </div>
                        {currentImage?.length > 0 && (
                            <div className="BannerImg__image-array-wrap">
                                {currentImage?.map((image: any, index: any) => {
                                    return (
                                        <div className="BannerImg__image-wrap" key={index}>
                                            <img
                                                src={image}
                                                key={index}
                                                className="BannerImglist"
                                                alt="Learn and Play"
                                            />
                                            <img
                                                className="cancel-icon"
                                                onClick={handleDelete(index)}
                                                src={close}
                                                alt="Learn and Play"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        <div className="submit-btn-wrapper">
                            <button type="button" className="btn btn-primary program-cancel-btn" onClick={onClose}>Cancel</button>
                            <button type="button" className="btn btn-primary program-save-btn" onClick={onSubmit}>Save</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default AddEditBannerImg;