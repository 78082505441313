import "./AddKeywords.scss";
import { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { Field, Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import {
  VALIDATION_CONSTANT,
  TOASTER_CONFIGS,
  ROUTES,
  UPLOAD_TICKET_CONSTANT,
  I18_TRANSLATION_COMMON,
} from "../../../Utilities/Constants/Constants";
import {
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import plus from "../../../../Assets/tags/plus.svg";
import backButton from "../../../../Assets/schools/back_button.svg";

const AddKeywords = () => {
  const { id } = useParams();
  const [courseType, setCourseType] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList]: any = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  const [currentCategory, setCurrentCategory]: any = useState();
  const navigate = useNavigate();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [course, setCourse] = useState("");
  const [initialRender, setInitialRender] = useState(true);

  const location = useLocation();

  const isPathActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    location.pathname.split("/")[1] ===
    UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL
      ? setCourseType(VALIDATION_CONSTANT.RECREATION)
      : setCourseType(VALIDATION_CONSTANT.COURSE);
  }, []);

  /* Updates while setting courseType */
  useEffect(() => {
    if (courseType) {
      if (id) {
        tageDetail();
      } else {
        getCategoryList();
      }
    }
  }, [courseType]);

  /* Tag Detail GET API */
  const tageDetail = () => {
    axiosInstance
      .get(
        API_END_POINTS.GET_TAG_DETAIL +
          "?category_type=" +
          courseType +
          "&category_id=" +
          id
      )
      .then((res: any) => {
        if (res.data.status === true) {
          setTagList(res.data.result.data);
          setCurrentCategory(res.data.result.category);
          setCourse(res.data.result.category);
        }
      });
  };

  /* Updating while course type change */
  const onTypeChange = (event: any) => {
    setCourseType(event.target.value);
    setTagList([]);
    setCurrentCategory("");
    setCategoryList([]);
  };

  /* Setting category */
  const onCategorySelect = (event: any) => {
    setTagList([]);
    setCurrentCategory(event.target.value);
  };

  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Get Category list API call */
  const getCategoryList = () => {
    if (courseType === VALIDATION_CONSTANT.COURSE) {
      axiosInstance.get(API_END_POINTS.GET_ALLCOURSE_LIST).then((res: any) => {
        if (res.data.status === true) {
          setCategoryList(res.data.result.data);
          // setPage(res.data.result.currentPageNo-1);
          // setTotalCount(res.data.result.total_count);
        }
      });
    } else if (courseType === VALIDATION_CONSTANT.RECREATION) {
      axiosInstance
        .get(API_END_POINTS.GET_ALLRECREATIONAL_LIST)
        .then((res: any) => {
          if (res.data.status === true) {
            setCategoryList(res.data.result.data);
            // setPage(res.data.result.currentPageNo-1);
            // setTotalCount(res.data.result.total_count);
          }
        });
    }
  };

  /* Adding tag to taglist */
  const onAddTag = () => {
    if (
      currentTag.trim() !== "" &&
      tagList.filter((item: any) => item === currentTag).length === 0
    ) {
      setTagList([...tagList, currentTag]);
    } else {
      if (currentTag.trim() !== "") toast.error(ERROR_MESSAGES.TAG_VALIDATION);
    }
    setCurrentTag("");
  };

  /* Updates while change in taglist */
  useEffect(() => {}, [tagList]);

  /* Setting current input value */
  const setInputValue = (e: any) => {
    if (
      REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value) &&
      !/[0-9.]/.test(e.key)
    ) {
      setCurrentTag(e.target.value);
    }
  };

  /* Deleting tag from taglist */
  const handleDelete = (i: any) => () => {
    setTagList(tagList.filter((item: any) => item !== tagList[i]));
  };

  /* Handling enter keypress */
  const handleKeyPress = (event: any) => {
    if (
      event.key === VALIDATION_CONSTANT.KEY_TYPE &&
      event.target.value.trim() !== ""
    ) {
      onAddTag();
    }
  };

  /* Save tag API call */
  const onSaveTags = () => {
    let payload = {
      category_type: courseType,
      category: currentCategory,
      tag: tagList,
    };
    axiosInstance.post(API_END_POINTS.CREATE_TAGS, payload).then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message, TOASTER_CONFIGS);
        courseType === VALIDATION_CONSTANT.RECREATION
          ? navigate(ROUTES.RECREATIONAL_KEYWORDS)
          : navigate(ROUTES.COURSE_KEYWORDS);
      }
    });
  };

  return (
    <div
      className="categories_wrapper"
      dir={currentLanguage === "English" ? "ltr" : "rtl"}
    >
      <div className="page-title">
        <img
          className="arrow_icon"
          src={backButton}
          alt="Learn and Play"
          onClick={() => {
            courseType === VALIDATION_CONSTANT.RECREATION
              ? navigate(ROUTES.RECREATIONAL_KEYWORDS)
              : navigate(ROUTES.COURSE_KEYWORDS);
          }}
        />
        <h5 className="name-title">{t("Add Tags")}</h5>
      </div>
      <div className=" tags-wrap box-wrapper">
        <div className="row">
          <div className="col-md-5">
            <div className="tag-select-wrap">
              <label className="name-label">{t("Category Type")}</label>
              <input
                className="tag-input category-type"
                value={courseType}
                disabled
              />
            </div>

            {courseType == "course" && !id && (
              <div className="tag-select-wrap category-select">
                <label className="name-label">{t("Category")}:</label>
                <select
                  className="course-type-selection"
                  onChange={onCategorySelect}
                >
                  <option value="">{t("Please select category")}</option>
                  {categoryList.map((element: any, index: any) => {
                    return (
                      <option
                        className="categorylist-option"
                        value={element.id}
                      >
                        {currentLanguage == "English"
                          ? element.course_category_name
                          : element.arabic_coursecategory_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {courseType == "recreation" && !id && (
              <div className="tag-select-wrap category-select">
                <label className="name-label">{t("Category")}:</label>
                <select
                  className="course-type-selection"
                  onChange={onCategorySelect}
                >
                  <option value="">{t("Please select category")}</option>
                  {categoryList.map((element: any, index: any) => {
                    return (
                      <option value={element.id}>
                        {element.category_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}

            {courseType == "course" && id && (
              <div className="tag-select-wrap category-select">
                <label className="name-label">{t("Category")}:</label>
                <input
                  className="tag-input category-type"
                  value={course}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="col-md-7">
            {(courseType === "recreation" || courseType == "course") &&
              currentCategory &&
              currentCategory !== "" && (
                <div className="add-tags-wrap">
                  <label className="name-label">{t("Add English Tags")}:</label>
                  <div>
                    <Formik
                      initialValues={{ tag_name: "" }}
                      onSubmit={(values: any) => {
                        onAddTag();
                      }}
                    >
                      {(props) => {
                        return (
                          <div className="category-input-wrap">
                            <Field
                              className="tag-input"
                              value={currentTag}
                              name="tag_name"
                              onKeyPress={(event: any) => {
                                handleKeyPress(event);
                              }}
                              onChange={setInputValue}
                            />
                            <Field hidden onClick={props.handleSubmit} />
                            <img
                              onClick={() => props.handleSubmit()}
                              className="add-icon"
                              src={plus}
                              alt="Learn and Play"
                            />
                          </div>
                        );
                      }}
                    </Formik>
                  </div>

                  <div className="tag-div">
                    {tagList.map((tag: any, index: any) => {
                      return (
                        <Chip
                          className="tag-chip"
                          label={id ? tag.tag : tag}
                          variant="outlined"
                          onDelete={handleDelete(index)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="save-tag">
        <button
          className="cancel_button"
          onClick={() => {
            courseType === VALIDATION_CONSTANT.RECREATION
              ? navigate(ROUTES.RECREATIONAL_KEYWORDS)
              : navigate(ROUTES.COURSE_KEYWORDS);
          }}
        >
          {t("Cancel")}
        </button>
        <button type="submit" className="save_button" onClick={onSaveTags}>
          {t("Save")}
        </button>
      </div>
    </div>
  );
};

export default AddKeywords;
