import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import "./ViewUniversity.scss";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import {
  ADD_UNIVERSITY_FIELDS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  ONBOARDING_ROUTES,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../../Utilities/Constants/Constants";
import * as yup from "yup";
import {
  EDIT_UNIVERSITY_ERROR_MESSAGE,
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import { Field, Formik } from "formik";
import { Card, List, ListItemButton, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const ViewUniversity = () => {
  const [universityImage, setUniversityImage]: any = useState();
  const [universityId, setUniversityId] = useState();
  const [ universityData, setUniversityData] = useState<any>();
  const [universityDetail, setUniversityDetail] = useState({
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME]: "",
    [ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE]: "",
    [ADD_UNIVERSITY_FIELDS.LOCATION]: "",
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES]: "",
    [ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS]: "",
    [ADD_UNIVERSITY_FIELDS.EMAIL]: "",
    [ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]: "",
    [ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES]: "",
    [ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED]: "",
    [ADD_UNIVERSITY_FIELDS.TEACHING_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.RESEARCH]: "",
    [ADD_UNIVERSITY_FIELDS.RATING]: "",
    [ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.FACILITIES_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL]: "",
    [ADD_UNIVERSITY_FIELDS.IMPACT_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.OVERALL_RATING]: "",
    [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS]: "",
    [ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES]: "",
    [ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES]: "",
    [ADD_UNIVERSITY_FIELDS.STUDENTS]: "",
    [ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS]: "",
    [ADD_UNIVERSITY_FIELDS.MALE]: 0,
    [ADD_UNIVERSITY_FIELDS.FEMALE]: 0,
    [ADD_UNIVERSITY_FIELDS.TRANSGENDER]: 0,
    [ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS]: "",
  });
  const { id } = useParams();
  const location = useLocation();

  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const dialogContent = DIALOG_CONSTANT.UNIVERSITY_INVITE;
  const [isFromStatus, setIsFromStatus] = useState(false);
  const [modalStatus, setmodalStatus] = useState(false);
  useEffect(() => {
    setIsFromStatus(
      location.pathname.split("/")[1] === ONBOARDING_ROUTES.UNIVERSITY_STATUS
    );
    getUniiversityDetail();
  }, []);

  const getUniiversityDetail = () => {
    axiosInstance
      .get(API_END_POINTS.UNIVERSITY_API + "?id=" + id)
      .then((res) => {
        setUniversityImage(res.data.result.data.images);
        setUniversityValues(res.data.result.data);
        setUniversityData(res.data.result.data);        
        setUniversityId(res.data.result.data.user);
      });
  };

  const updateUniversityDetail = (payload: any) => {
    axiosInstance
      .put(API_END_POINTS.UNIVERSITY_API + "?id=" + id, payload)
      .then((res) => {});
  };

  const onInviteUniversity = (status: any) => {
    if (status === true) {
      axiosInstance
        .put(API_END_POINTS.INVITE_INSTITUTIONS + "?id=" + universityId)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message, TOASTER_CONFIGS);
          }
        });
    }
    setmodalStatus(false);
  };

  const universityValidationSchema = yup.object({
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.UNIVERSITY_NAME),
    [ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE]: yup.string(),
    //    .required(EDIT_UNIVERSITY_ERROR_MESSAGE.QUALITY_ASSURANCE),
    [ADD_UNIVERSITY_FIELDS.LOCATION]: yup.string(),
    //    .required(EDIT_UNIVERSITY_ERROR_MESSAGE.LOCATION_REQUIRED),
    // [ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES]: yup
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.UNIVERSITY_IMAGES),
    [ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS]: yup.string(),
    //    .required(EDIT_UNIVERSITY_ERROR_MESSAGE.WEBSITE_ADDRESS),

    [ADD_UNIVERSITY_FIELDS.PRINCIPAL]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.PRINCIPAL),

    [ADD_UNIVERSITY_FIELDS.EMAIL]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.EMAIL_REQUIRED),
    //   .email(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.INVALID_EMAIL),
    [ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]: yup.string(),
    //   .matches(REGEX.PHONE_VALIDATION,ERROR_MESSAGES.PHONE_NUMBER_FORMAT)
    //    .required(ERROR_MESSAGES.PHONE_NUMBER),
    [ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.DEGREES_AND_FEES),
    [ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED]: yup.string(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.PROGRAMS_OFFERED),

    [ADD_UNIVERSITY_FIELDS.TEACHING_RATING]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
    //    .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TEACHING_RATING),

    [ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
    // .required(EDIT_UNIVERSITY_ERROR_MESSAGE.EMPLOYABILITY_RATING),

    [ADD_UNIVERSITY_FIELDS.RESEARCH]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
    // .required(EDIT_UNIVERSITY_ERROR_MESSAGE.RESEARCH),
    [ADD_UNIVERSITY_FIELDS.RATING]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
    // .required(EDIT_UNIVERSITY_ERROR_MESSAGE.RATING),

    [ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
    //  .required(EDIT_UNIVERSITY_ERROR_MESSAGE.INTERNATIONALIZATION_RATING),

    [ADD_UNIVERSITY_FIELDS.FACILITIES_RATING]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.FACILITIES_RATING),

    [ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING]: yup.number(),
    //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(EDIT_UNIVERSITY_ERROR_MESSAGE.PROGRAMME_STRENGTH_RATING),

    [ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.INCLUSIVENESS_RATING),

    [ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.ENVIRONMENTAL),

    [ADD_UNIVERSITY_FIELDS.IMPACT_RATING]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.IMPACT_RATING),

    [ADD_UNIVERSITY_FIELDS.OVERALL_RATING]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.OVERALL_RATING),

    [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.NO_OF_STUDENTS),
    [ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TOTAL_GRADUATES),

    [ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.NO_OF_EMIRATES),

    [ADD_UNIVERSITY_FIELDS.STUDENTS]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.STUDENTS),

    [ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.NO_OF_EXPATS),
    [ADD_UNIVERSITY_FIELDS.MALE]: yup.number(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.MALE),
    [ADD_UNIVERSITY_FIELDS.FEMALE]: yup.number(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.FEMALE),
    [ADD_UNIVERSITY_FIELDS.TRANSGENDER]: yup.number(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TRANSGENDER),
    [ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS]: yup.string(),
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TOTAL_EDUCATORS),
  });

  const setUniversityValues = (data: any) => {
    setUniversityDetail({
      [ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME]: data.university_name,
      [ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE]: data.quality_assurance,
      [ADD_UNIVERSITY_FIELDS.LOCATION]: data.address,
      [ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES]: data.images,
      [ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS]: data.web_link,
      [ADD_UNIVERSITY_FIELDS.PRINCIPAL]: data.principal,

      [ADD_UNIVERSITY_FIELDS.EMAIL]: data.email,
      [ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]: data.phone_number,
      [ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES]: "",
      [ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED]: data.programs_offered
        ? data.programs_offered
        : "",
      [ADD_UNIVERSITY_FIELDS.TEACHING_RATING]: data.teaching_rating
        ? data.teaching_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING]: data.employability_rating
        ? data.employability_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.RESEARCH]: data.research ? data.research : "",
      [ADD_UNIVERSITY_FIELDS.RATING]: data.rating ? data.rating : "",
      [ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING]:
        data.international_rating ? data.international_rating : "",
      [ADD_UNIVERSITY_FIELDS.FACILITIES_RATING]: data.facility_rating
        ? data.facility_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING]:
        data.programme_strength_rating ? data.programme_strength_rating : "",
      [ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING]: data.inclusiveness_rating
        ? data.inclusiveness_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL]: data.environmental
        ? data.environmental
        : "",
      [ADD_UNIVERSITY_FIELDS.IMPACT_RATING]: data.impact_rating
        ? data.impact_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.OVERALL_RATING]: data.overall_rating
        ? data.overall_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS]: data.students_count
        ? data.students_count
        : "",
      [ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES]: data.total_graduates
        ? data.total_graduates
        : "",
      [ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES]: data.emirates_count
        ? data.emirates_count
        : "",
      [ADD_UNIVERSITY_FIELDS.STUDENTS]: data.student_percentage
        ? data.student_percentage
        : "",
      [ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS]: data.expats_percentage
        ? data.expats_percentage
        : "",
      [ADD_UNIVERSITY_FIELDS.MALE]: data.male,
      [ADD_UNIVERSITY_FIELDS.FEMALE]: data.female,
      [ADD_UNIVERSITY_FIELDS.TRANSGENDER]: data.transgender,
      [ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS]: data.total_educators
        ? data.total_educators
        : "",
    });
  };

  return (
    <>
      <Formik
        initialValues={universityDetail}
        validationSchema={universityValidationSchema}
        onSubmit={(values, actions) => {
          updateUniversityDetail(values);
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <form
            className="school_fields_container"
            onSubmit={formik.handleSubmit}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="arrow-wrap">
                  <img
                    src={BackArrow}
                    className="EditSchool-backarrow"
                    alt="Learn and Play"
                    onClick={() => {
                     /*  isFromStatus
                        ? navigate(ROUTES.UNIVERSITIES_STATUS_LIST)
                        : navigate(ROUTES.MANAGE_UNIVERSITIES); */
                        navigate(-1);
                    }}
                  />
                  <h5 className="name-title mt-1 ms-2">
                    {t("University Detail")}
                  </h5>
                </div>
              </div>
             {/*  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="invite-btn-wrap">
                  <button
                    className="btn btn-secondary invite-btn"
                    onClick={() => {
                      setmodalStatus(true);
                    }}
                  >
                    {t("Invite University")} <GroupAddIcon />
                  </button>
                </div>
              </div> */}
            </div>

            <ConfirmationDialog
              content={dialogContent}
              modalState={modalStatus}
              dialogStatus={onInviteUniversity}
            />

            <Card className="EditSchool-cardHead">
              <div className="EditSchool-cardTitle">{t("Basic Details")}</div>
              <div id="card-wrap" className="detail-row-wrap">
                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("University Name")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Email")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        name={ADD_UNIVERSITY_FIELDS.EMAIL}
                      />

                      {/* <label className="fields_label col-md-6 mt-3">{t('Phone Number')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.PHONE_NUMBER}
                      />
 */}
                    </div>
                  </div>
                </div>

                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Principal")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.PRINCIPAL}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Phone Number")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.PHONE_NUMBER}
                      />
                    </div>
                  </div>
                </div>

                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Location")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.LOCATION}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Weblink of University")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        name={ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS}
                      />
                    </div>
                  </div>
              </div>
              <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Total Students")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        name={ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS}
                        value={universityData?.student_count}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Rating")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        value={universityData?.rating}
                      />
                    </div>
                  </div>
                  </div>
                  <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Global Ranking")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        value={universityData?.global_ranking}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Shangai Ranking")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        value={universityData?.shangai_ranking}
                      />
                    </div>
                  </div>
                  </div>
                  <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Total Courses")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        value={universityData?.shangai_ranking}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Available medium")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-6 mt-3 field-input"
                        value={universityData?.available_mediums}
                      />
                    </div>
                  </div>
                  </div>
                  
                  <div className="row basic-detail-row">
                  <div className="col-md-12">
                    <div className="row">
                      <label className="fields_label col-md-3 mt-3">
                        {t("Description")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input col-md-9 mt-3 field-input"
                        value={universityData?.description}
                      />
                    </div>
                  </div>
                 
                  </div>
                </div>
            </Card>
            <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Application Process")}
              </div>
              <div id="card-wrap" className="detail-row-wrap">
                 
                <List>
                {universityData?.application_process?.length===0 && <div>No Data Available</div>}
                  {universityData?.application_process?.length > 0 && <>
                    {universityData?.application_process?.map((point: any, index: any) => {
                    return (
                      <div>
                        <ListItemButton className="list-btn" key={index}>
                          <ListItemText primary={point} />
                          
                        </ListItemButton>
                      </div>
                    );
                  })}
                  </>}
                  {/* {universityData?.application_process?.length === 0 && <> No Data Available</>} */}
                </List>
                </div>
              </Card>
            <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Eligibility Criteria")}
              </div>
              
              <div id="card-wrap" className="detail-row-wrap">
                <div className="event-wrap">
                 </div>
                <List>
                {universityData?.eligibility_criteria?.length===0 && <div>No Data Available</div>}
                    { universityData?.eligibility_criteria?.map((point: any, index: any) => {
                      return (
                        <div>
                          <ListItemButton className="list-btn" key={index}>
                            <ListItemText primary={point} />
                            
                          </ListItemButton>
                        </div>
                      );
                    })}
                  </List>
                </div>
              </Card>
            <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Programs Offered")}
              </div>
              <div id="card-wrap" className="detail-row-wrap">
                 
              <div className="event-wrap">
                {universityData?.program_offered?.length===0 && <div>No Data Available</div>}
                  {universityData?.program_offered?.length > 0 &&<table>
                    <tr>
                      <th>Program Type</th>
                      <th>Course Domain</th>
                      <th>Course Name</th>
                      <th>Course Fee</th>
                    
                    </tr>
                  </table>}
                  <table>
                    {universityData?.program_offered?.map((grade: any, i: any) => {
                      return (
                        <>
                          <tr>
                            <td>{grade.program_class_name}</td>
                            <td>{grade.university_program_name}</td>
                            <td>{grade.sub_program_class_name}</td>
                            <td>{grade.price}</td>
                            
                          </tr>
                        </>
                      );
                    })}
                    </table>
                </div>
                </div>
              </Card>

        {/*     <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Ratings")}
              </div>
              <div id="card-wrap">
                <div className="row detail-row-wrap">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Teaching Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.TEACHING_RATING}
                      />
                      <label className="fields_label col-md-6 mt-3">
                        {t("Employability Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Program Strength Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Inclusiveness Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Impact Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.IMPACT_RATING}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Quality Assurance")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("International Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Facilities Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.FACILITIES_RATING}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Overall Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.OVERALL_RATING}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Environmental")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}

           {/*  <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Number Counts")}
              </div>
              <div id="card-wrap">
                <div className="row detail-row-wrap">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Number of Students")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS}
                      />
                      <label className="fields_label col-md-6 mt-3">
                        {t("Total Graduates")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Number of Emirates")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Students")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.STUDENTS}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Male")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.MALE}
                      />
                      <label className="fields_label col-md-6 mt-3">
                        {t("Female")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.FEMALE}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Transgender")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.TRANSGENDER}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Degrees and Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Number of Expats")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Total Educators")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Programms Offered")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Research")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.RESEARCH}
                      />
                      <label className="fields_label col-md-6 mt-3">
                        {t("Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_UNIVERSITY_FIELDS.RATING}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}

            <label className="fields_label mt-3">{t("Images")}</label>
            <div className="row">
              {universityImage?.map((image: any, index: any) => {
                return (
                  <div className="col-md-4 mt-3 col-sm-6">
                    <img
                      src={image}
                      key={index}
                      className="EditSchool-schoolimage"
                    />
                  </div>
                );
              })}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ViewUniversity;
