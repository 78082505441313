import { Card, Chip, Step, StepLabel, Stepper } from "@mui/material";
import "./ActivitiesPersonalVerificationLayout.scss";
import BackArrow from "../../../../../Assets/schools/back_button.svg";
import { useEffect, useState } from "react";
import {
  API_RESPONSE,
  ColorlibConnector,
  ActivityStepIcon,
  ROUTES,
  TOASTER_CONFIGS,
  PERSONAL_VERIFICATION_STEPPER_LABEL,
  VALIDATION_CONSTANT,
  COMMON_CONSTANTS,
} from "../../../../Utilities/Constants/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import ActivitiesPersonalApplicationOverview from "../Shared/ActivitiesPersonalVerification/ActivitiesPersonalApplicationOverview/ActivitiesPersonalApplicationOverview";
import ActivitiesPersonalApplicationReview from "../Shared/ActivitiesPersonalVerification/ActivitiesPersonalApplicationReview/ActivitiesPersonalApplicationReview";
import ActivitiesPersonalVerificationStatus from "../Shared/ActivitiesPersonalVerification/ActivitiesPersonalVerificationStatus/ActivitiesPersonalVerificationStatus";
import ActivitiesPersonalCourseReview from "../Shared/ActivitiesPersonalVerification/ActivitiesPersonalCourseReview/ActivitiesPersonalCourseReview";
import { useSelector } from "react-redux";
import { GetSessionStorageData } from "../../../../Services/Helpers/Helper";
import { ERROR_MESSAGES } from "../../../../Utilities/Constants/Validations";

const ActivitiesPersonalVerificationLayout = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [tutorDetail, setTutorDetail] = useState({});
  const [currentStatus, setCurrentStatus] = useState("");
  const navigate = useNavigate();
  const [ isSuperAdmin, setIsSuperAdmin ]:any= useState(API_RESPONSE.FALSE);
  const [ tabOnePermission, setTabOnePermission ]= useState(API_RESPONSE.TRUE);
  const [ tabTwoPermission, setTabTwoPermission ]= useState(API_RESPONSE.TRUE);
  const [ tabThreePermission, setTabThreePermission ]= useState(API_RESPONSE.TRUE);
  const [ tabFourPermission, setTabFourPermission ]= useState(API_RESPONSE.TRUE);
  
   /* Getting permission data from reducer store */
   const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  });

    /* Getting data from local storage */
    useEffect(()=>{
      setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
   },[])
  
     /* Setting permission based on permission data */
     useEffect(()=>{
       if(isSuperAdmin===`${API_RESPONSE.TRUE}`){
         setTabOnePermission(API_RESPONSE.TRUE);
         setTabTwoPermission(API_RESPONSE.TRUE);
         setTabThreePermission(API_RESPONSE.TRUE);
         setTabFourPermission(API_RESPONSE.TRUE);
       }else{
         setTabOnePermission(permissions[1]?.data[2].data[1].data[0].checked);
         setTabTwoPermission(permissions[1]?.data[2].data[1].data[1].checked);
         setTabThreePermission(permissions[1]?.data[2].data[1].data[2].checked);
         setTabFourPermission(permissions[1]?.data[2].data[1].data[3].checked);
       }
     },[isSuperAdmin])

  /**Handle next steper */
  const handleNext = () => {
    if((permissions[1]?.data[2].data[1].data[activeStep+1].checked===API_RESPONSE.TRUE) || isSuperAdmin){
    if (activeStep + 1 < 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }else{
    if (activeStep + 1 < 4){
      toast.error(ERROR_MESSAGES.VERIFICATION_ERROR);
    }
  }
  };

  /**Handle previous steper */
  const handleBack = () => {
    if (activeStep - 1 > -1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    getTutorDetails();
  }, []);

  /**Get tutor details */
  const getTutorDetails = () => {
    axiosInstance
      .get(API_END_POINTS.GET_RECREATIONAL_DETAIL + `?id=${id}&type=personal`)
      .then((activitiesDetailResponse: any) => {
        if (
          activitiesDetailResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        ) {
          setTutorDetail(activitiesDetailResponse.data.result.data[0]);
          setCurrentStatus(activitiesDetailResponse.data.result.data[0].status);
        }
      });
  };

  /**Update activities verifiaction status */
  const updateTutorverification = (payload: any) => {
    axiosInstance
      .put(
        `${API_END_POINTS.GET_RECREATIONAL_DETAIL}` +
          `?id=${id}&type=personal` +
          `&page=${activeStep + 1}`,
        payload
      )
      .then((tutorDetailResponse: any) => {
        if (
          tutorDetailResponse.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE
        ) {
          toast.success(tutorDetailResponse.data.message, TOASTER_CONFIGS);
          if (activeStep + 1 < 4) {
            handleNext();
          } else {
            navigate(ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST);
          }
        }
      });
  };

  return (
    <>
      <div className="row tutor_verification_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="arrow_status_wrap">
              <div className="arrow-wrap">
                <img
                  src={BackArrow}
                  alt="Learn and Play"
                  className="EditSchool-backarrow"
                  onClick={() => {
                    navigate(ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST);
                  }}
                />
                <h5 className="name-title mt-1 ms-2">
                  Personal Activities Detail
                </h5>
              </div>
              {currentStatus === VALIDATION_CONSTANT.PENDING ? (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="warning"
                />
              ) : currentStatus === VALIDATION_CONSTANT.APPROVED ? (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="success"
                />
              ) : (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="error"
                />
              )}
            </div>
          </div>
        </div>
        <Card className="card-data">
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {PERSONAL_VERIFICATION_STEPPER_LABEL.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ActivityStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <ActivitiesPersonalApplicationOverview
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
            ></ActivitiesPersonalApplicationOverview>
          )}
          {activeStep === 1 && (
            <ActivitiesPersonalApplicationReview
              handleBack={handleBack}
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
            ></ActivitiesPersonalApplicationReview>
          )}
          {activeStep === 2 && (
            <ActivitiesPersonalCourseReview
              handleBack={handleBack}
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
            ></ActivitiesPersonalCourseReview>
          )}
          {activeStep === 3 && (
            <ActivitiesPersonalVerificationStatus
              handleBack={handleBack}
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
              getTutorDetails={getTutorDetails}
            ></ActivitiesPersonalVerificationStatus>
          )}
        </Card>
      </div>
    </>
  );
};
export default ActivitiesPersonalVerificationLayout;
