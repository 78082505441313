import { useEffect, useState } from "react";
import "./SessionDetail.scss";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_RESPONSE, DIALOG_CONSTANT, I18_TRANSLATION_COMMON, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import moment from 'moment';
import BackArrow from "../../../../Assets/schools/back_button.svg";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReasonDialog from "../../ReasonDialog/ReasonDialog";

const SessionDetail = () => {
  const { id } = useParams();
  const [sessionDetail, setSessionDetail]: any = useState();
  const [ categoryType, setCategoryType ]:any=useState();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const navigate = useNavigate();
  const dialogTitle = DIALOG_CONSTANT.TITLE_REJECTION;

  
  /* Calling session detail API on initial render */
  useEffect(() => {
    if (id) getSessionDetail();
  }, []);

  /* Getting session detail API call */
  const getSessionDetail = () => {
    axiosInstance
      .get(API_END_POINTS.GET_SESSION_DETAIL + `?id=${id}`)
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setSessionDetail(response.data.result.data[0]);
        }
      });
  };


  /* Closing reaon dialog */
  const onCloseDialog = () => {
    setDialogOpenStatus(false);
  };

  /* Getting reason from dialog box */
  const onSubmitReason = (value: any) => {
    let payload = {
      status: VALIDATION_CONSTANT.REJECTED,
      reason: value.reason,
    };
    setDialogOpenStatus(false);
    onApproveReject(payload);
  };

  /* Navigate to manage sessions */
  const onNavigate = ()=>{
    navigate(ROUTES.TUTOR_SESSIONS);
  }

  /* Approve/Reject session API call */
  const onApproveReject = (payload:any)=>{
    axiosInstance.put(API_END_POINTS.GET_SESSION_DETAIL + `?id=${id}`,payload).then((response:any)=>{
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        toast.success(response.data.message, TOASTER_CONFIGS);
        onNavigate();
      }
    })
  }

    /* Format time  */
    const formatDate= (time:any)=>{
        return moment(time, 'HH:mm').format('h:mm A');
        }

  return (
    <div className="session-detail-wrapper">
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
        submitReason={onSubmitReason}
        title={dialogTitle}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              onClick={onNavigate}
              alt="Learn and Play"
            />
            <h5 className="name-title mt-1 ms-2">{t("Session Detail")}</h5>
          </div>
        </div>
      </div>
      {sessionDetail && sessionDetail.status===VALIDATION_CONSTANT.PENDING && <div className="row">
        <div className="col-md-12">
          <div className="approve-btn-wrap">
            <button className="btn btn-danger" onClick={()=>{setDialogOpenStatus(true)}}>Reject</button>
            <button className="btn btn-success" onClick={()=>{ onApproveReject({status:VALIDATION_CONSTANT.APPROVED})}}>Approve</button>
          </div>
        </div>
      </div>}
      <div className="basic_detail_wrapper">
        <div className="row row-wrapper">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_label">Course</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_value">
                  {sessionDetail?.category_name}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div className="session_label">Session ID</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div className="session_value">{sessionDetail?.id}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-wrapper">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_label">Topic</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_value">
                  {sessionDetail?.session_title}{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div className="slot_counts_label">Number of Slot</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div className="slot_counts">{sessionDetail?.slot_count}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-wrapper">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_label">Location</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_value">Chennai</div>
              </div>
            </div>
          </div>
          {sessionDetail?.status===VALIDATION_CONSTANT.REJECTED && <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_label">Reason</div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="session_value">{sessionDetail?.reason}</div>
              </div>
            </div>
          </div>}
        </div>
      </div>

      <div className="slot-list mt-2">
        <h4>List of Slots</h4>
        <div className="row list-wrapper">
          {sessionDetail?.slots.map((session: any) => {
            return (
              <div className="col-md-6">
                <div className="session_card_container row-wrap">
                  <div className="session_slot_count_wrapper">
                    {/* <div className="session_slot_count">{session.slot_count}</div> */}
                    <div className="session_slot_count">
                      <div className="session-count">{session.id}</div>
                      <div className="slot-text">Slot ID</div>
                    </div>
                  </div>
                  <div className="session_date_details_container">
                    {/* <div className="session_date">08/05/2006 - 20/07/2006</div> */}
                    <div className="session_subject">{session.start_date}-{session.end_date}</div>
                    <div className="session_grade">
                      {formatDate(session.start_time)} - {formatDate(session.end_time)} 
                    </div>
                    {/* <div className="session_grade">{"300"} AED</div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SessionDetail;
