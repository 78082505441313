import { Card, Chip, Step, StepLabel, Stepper } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../../../../Assets/schools/back_button.svg";
import { API_RESPONSE, BULK_UPLOAD_TYPE, COMMON_CONSTANTS, ColorlibConnector, ColorlibStepIconSU, ONBOARDING_ROUTES, ROUTES, SCHOOLS_VERIFICATION_STEPPER_LABEL, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import "./SchoolsUniversityVerificationLayout.scss";
import SchoolsUniversityBasicInfo from "../SchoolsUniversityBasicInfo/SchoolsUniversityBasicInfo";
import SchoolsUniversityPersonalDetail from "../SchoolsUniversityPersonalDetail/SchoolsUniversityPersonalDetail";
import SchoolsUniversityApproval from "../SchoolsUniversityApproval/SchoolsUniversityApproval";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GetSessionStorageData } from "../../../../Services/Helpers/Helper";

const SchoolsUniversityVerificationLayout = () => {
    const navigate = useNavigate();
    const [currentStatus, setCurrentStatus] = useState("");
    const [ currentVerification, setCurrentVerification ] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [ schoolUniversityDetail, setSchoolUniversityDetail ] = useState();
    const location = useLocation();
    const { id } = useParams();
    const [ isSuperAdmin, setIsSuperAdmin ]:any= useState(API_RESPONSE.FALSE);
    const [ tabOnePermission, setTabOnePermission ]= useState(API_RESPONSE.TRUE);
    const [ tabTwoPermission, setTabTwoPermission ]= useState(API_RESPONSE.TRUE);
    const [ tabThreePermission, setTabThreePermission ]= useState(API_RESPONSE.TRUE);

  /* Finding which type, whether school/university */
    useEffect(()=>{
        if(location.pathname.split('/')[1]===ONBOARDING_ROUTES.SCHOOLS_STATUS){
            setCurrentVerification(BULK_UPLOAD_TYPE.SCHOOL);
        }else{
            setCurrentVerification(BULK_UPLOAD_TYPE.UNIVERSITY);
        };
        
    },[]);

  /* Getting permission data from reducer store */
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  });

 /* Getting data from local storage */
  useEffect(()=>{
     setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
  },[])

    /* Setting permission based on permission data */
    useEffect(()=>{
      if(isSuperAdmin===`${API_RESPONSE.TRUE}`){
        setTabOnePermission(API_RESPONSE.TRUE);
        setTabTwoPermission(API_RESPONSE.TRUE);
        setTabThreePermission(API_RESPONSE.TRUE);
      }else{
        setTabOnePermission(permissions[1]?.data[currentVerification===BULK_UPLOAD_TYPE.SCHOOL ? 0 : 1].data[1].data[0].checked);
        setTabTwoPermission(permissions[1]?.data[currentVerification===BULK_UPLOAD_TYPE.SCHOOL ? 0 : 1].data[1].data[1].checked);
        setTabThreePermission(permissions[1]?.data[currentVerification===BULK_UPLOAD_TYPE.SCHOOL ? 0 : 1].data[1].data[2].checked);
      }
    },[currentVerification])

    useEffect(()=>{
      if(currentVerification)
        getDetail();
    },[currentVerification])

  /*Go to next stepper */
  const handleNext = () => {
    if((permissions[1]?.data[currentVerification===BULK_UPLOAD_TYPE.SCHOOL ? 0 : 1].data[1].data[activeStep+1].checked===API_RESPONSE.TRUE) || isSuperAdmin){
      if (activeStep + 1 < 3) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }else{
      if (activeStep + 1 < 3){
        toast.error(`You don't have permission for proceeding to next step`);
      }
    }
    
  };

  
  /**Go to previous stepper */
  const handleBack = () => {
    if (activeStep - 1 > -1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  /* Getting detail based on school/university */
  const getDetail = () => {
    axiosInstance
      .get((currentVerification=== BULK_UPLOAD_TYPE.SCHOOL ? API_END_POINTS.SCHOOL_BULK_UPLOAD : API_END_POINTS.UNIVERSITY_API) + "?id=" + id)
      .then((response: any) => {
        if (response.data.status === true) {
            setCurrentStatus(response.data.result.data.status)
            setSchoolUniversityDetail(response.data.result.data);
        }
      }) 
  };

  /* Navigating back based on current type */
  const navigateBack = () =>{
     currentVerification === BULK_UPLOAD_TYPE.SCHOOL ?
      navigate(ROUTES.SCHOOL_STATUS_LIST) : navigate(ROUTES.UNIVERSITIES_STATUS_LIST);
  }


    /**Update tutor verifiaction status */
    const updateTutorverification = (payload: any) => {
        getDetail();
        axiosInstance
          .put(
            `${API_END_POINTS.APPROVE_SCHOOL_UNIVERSITY}` +
              `?id=${id}` +
              `&page=${activeStep + 1}` +
              `&type_info=${currentVerification===BULK_UPLOAD_TYPE.SCHOOL ? 1 : 2}`,
            payload
          )
          .then((tutorDetailResponse: any) => {
            if (
              tutorDetailResponse.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE
            ) {
              toast.success(tutorDetailResponse.data.message, TOASTER_CONFIGS);
              if (activeStep + 1 < 3) {
                handleNext();
              } else {
                navigateBack();
              }
            }
          });
      };
    
    return(
        <div className="school-university-verification-layout">
            <div className="row">
                <div className="col-md-12">
                <div className="arrow_status_wrap">
              <div className="arrow-wrap">
                <img
                  src={BackArrow}
                  alt="Learn and Play"
                  className="EditSchool-backarrow"
                  onClick={() => {
                    navigateBack()
                  }}
                />
                <h5 className="name-title mt-1 ms-2">{ currentVerification === BULK_UPLOAD_TYPE.SCHOOL ? 'School Verification' : 'University Verification'}</h5>
              </div>
              {currentStatus === VALIDATION_CONSTANT.PENDING ? (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="warning"
                />
              ) : currentStatus === VALIDATION_CONSTANT.APPROVED ? (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="success"
                />
              ) : (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="error"
                />
              )}
            </div>
                </div>
            </div>

            <Card className="verification-card-data">
            <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {SCHOOLS_VERIFICATION_STEPPER_LABEL.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIconSU}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <SchoolsUniversityBasicInfo
              type={currentVerification}
              superAdmin={isSuperAdmin}
              schoolUnivDetail={schoolUniversityDetail}
              permission={{one:tabOnePermission,two:tabTwoPermission,three:tabThreePermission}}
              updateTutorverification={updateTutorverification}
              handleNext={handleNext}
              handleBack={handleBack}
            ></SchoolsUniversityBasicInfo>
          )}
          {activeStep === 1 && (
            <SchoolsUniversityPersonalDetail
            type={currentVerification}
            superAdmin={isSuperAdmin}
            permission={{one:tabOnePermission,two:tabTwoPermission,three:tabThreePermission}}
             schoolUnivDetail={schoolUniversityDetail}
             updateTutorverification={updateTutorverification} 
             handleNext={handleNext}
             handleBack={handleBack}
            ></SchoolsUniversityPersonalDetail>
          )}
          {activeStep === 2 && (
            <SchoolsUniversityApproval
             type={currentVerification}
             superAdmin={isSuperAdmin}
             permission={{one:tabOnePermission,two:tabTwoPermission,three:tabThreePermission}}
             schoolUnivDetail={schoolUniversityDetail}
             updateTutorverification={updateTutorverification} 
             handleNext={handleNext}
             handleBack={handleBack}
            ></SchoolsUniversityApproval>
          )}
            </Card>
        </div>
    )
};

export default SchoolsUniversityVerificationLayout;