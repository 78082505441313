import "./CoursesList.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Chip from "@mui/material/Chip";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog/ConfirmationDialog";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import ReasonDialog from "../../../Shared/ReasonDialog/ReasonDialog";
import { toast } from "react-toastify";
import { DIALOG_CONSTANT, I18_TRANSLATION_COMMON, SORTING_CONSTANT, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination, TableSortLabel, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { checkValueNullUndefinedEmpty } from "../../../Services/Helpers/Helper";

const CoursesList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [page, setPage] = useState(1);
  const [categoryType, setCategoryType] = useState(VALIDATION_CONSTANT.COURSE);
  const [CoursesList, setCoursesList] = useState([]);
  const [currentElement, setCurrentElement] = useState(0);
  const [modalStatus, setmodalStatus] = useState(false);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const [totalPage, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const dialogContent = DIALOG_CONSTANT.DELETE_CONTENT;
  const [searchValue, setSearchValue] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [value, setValue] = React.useState(VALIDATION_CONSTANT.COURSE);
  const [sliderValue, setSliderValue] = React.useState<number[]>([0, 1000]);
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [initialRender, setInitialRender] = useState(true);
  const [rowPerPage, setRowPerPage] = useState(10);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setCategoryType(newValue);
    setSearchValue("");
    setPage(1);
    setCurrentSort("");
    setSliderValue([0, 1000]);
  };
  const [currentUser, setCurrentUser] = useState("");

  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Getting Category list while component loads */
  useEffect(() => {
    getCoursesList();
  }, [categoryType, page, currentSort]);

  /* Updated on change in search value */
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        setPage(1);
        getCoursesList();
      }
    }
  }, [searchValue]);


  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Get Category list API call */
  const getCoursesList = () => {
    axiosInstance
      .get(
        API_END_POINTS.GET_COURSE_LIST +
        "?page=" +
        page +
        "&limit=" +
        10 +
        "&search=" +
        searchValue +
        "&sort=" +
        currentSort
      )
      .then((res: any) => {
        setCoursesList(res.data.result.data);
        setTotalCount(res.data.result.total_page);
      });
  };

  const approveRejectCourse = (payload: any, id: any) => {
    axiosInstance
      .put(API_END_POINTS.MANAGE_COURSE + "?id=" + id, payload)
      .then((res: any) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getCoursesList();
        }
      });
  };

  /* Opening Confirmation dialog box */
  const openDialogBox = (id: any) => {
    setCurrentElement(id);
    setmodalStatus(true);
  };

  /* Delete category API call */
  const onDelCategory = (data: any) => {
    setmodalStatus(false);
    if (data === true) {
      axiosInstance
        .delete(
          API_END_POINTS.DELETE_CATEGORY +
          "?id=" +
          currentElement +
          "&category_type=" +
          categoryType
        )
        .then((res) => {
          if (res.data.status === true) {
            getCoursesList();
          }
        });
    }
  };

  /* Edit category Redirection */
  const onEditCategory = (id: any) => {
    navigate(`/edit-category/${id}/${categoryType}`);
  };

  /* Updating Category type change */
  const onTypeChange = (event: any) => {
    setCategoryType(event.target.value);
  };

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  /* Calling Search API */
  // const onSearchData = () => {
  //   getCoursesList();
  // };

  /* Setting sort type */
  const onChangeSelect = (e: any) => {
    setCurrentSort(e.target.value);
  };

  /* Handling min-max price slide change */
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as []);
  };

  function valuetext(value: number) {
    return `${value}`;
  }

  /* Initializing min-max price marks in sider */
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1000,
      label: "1000",
    },
  ];


  const onCloseDialog = () => {
    setDialogOpenStatus(false);
  };

  const onSubmitReason = (value: any) => {
    let payload = {
      status: VALIDATION_CONSTANT.REJECTED,
      reason: value.reason,
    };
    setDialogOpenStatus(false);
    approveRejectCourse(payload, currentUser);
  };

  /* Handling ID sort */
  const handleSortID = () => {
    idSort == SORTING_CONSTANT.ASC ? setIdSort(SORTING_CONSTANT.DSC) : setIdSort(SORTING_CONSTANT.ASC);
    idSort == SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_DESCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_ASCENDING);
  };

  return (
    <div
      className="listMainWrapper"
      dir={currentLanguage === "English" ? "ltr" : "rtl"}
    >
      <ConfirmationDialog
        content={dialogContent}
        modalState={modalStatus}
        dialogStatus={onDelCategory}
      />
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
        submitReason={onSubmitReason}
        // title={}
      />
      <div className="select-wrap">
        <div className="btn-wrap">
          <div className="search-wrapup">
            <div
              className={
                currentLanguage === "English"
                  ? "custom__search"
                  : "custom__search-rtl"
              }
            >
              <TextField
                id="outlined-basic"
                placeholder={t("Search") || ""}
                variant="outlined"
                value={searchValue}
                onChange={onUpdateSearch}
                onKeyPress={(event: any) => {
                  if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                    setPage(1);
                    setTimeout(() => {
                      getCoursesList();
                    }, 0)

                  }
                }}
              />
              <SearchIcon
                className= "icon"
                onClick={() => {
                  setPage(1); setTimeout(() => {
                    getCoursesList();
                  }, 0)
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="tableWrapper">
        <TableContainer component={Paper} className="categoryTable">
          <Table>
            <TableHead className="categoryTableHead">
              <TableRow className="categoryTableRow">
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell onClick={() => handleSortID()}>
                  <TableSortLabel active={true} direction={idSort}>
                    {t("Course Code")}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t("Course Name")}</TableCell>
                <TableCell>{t("Description")}</TableCell>
                <TableCell>{t("Mode Value")}</TableCell>
                <TableCell>{t("Type Value")}</TableCell>
                <TableCell>{t("Duration")}</TableCell>
                <TableCell>{t("Batch Size")}</TableCell>
                <TableCell>{t("Fees")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {CoursesList.map((course: any, index: any) => {
                return (
                  <TableRow key={index + 1}>
                    {/* <TableCell>{index + 1}</TableCell> */}
                    <TableCell>{(page - 1) === 0 ? (index + 1) : ((page - 1) * rowPerPage) + (index + 1)}</TableCell>
                    <TableCell>{course.course_code}</TableCell>
                    <TableCell className="row-capitalize">
                      {course.title}
                    </TableCell>
                    <TableCell className="row-capitalize course-description">
                      {course.description}
                    </TableCell>
                    <TableCell>{course.course_mode_value}</TableCell>
                    <TableCell>{course.course_type_value}</TableCell>
                    <TableCell>{course.duration}</TableCell>
                    <TableCell>{course.batch_size}</TableCell>
                    <TableCell>{course.fees}</TableCell>
                    <TableCell>
                      {" "}
                      {course.status === VALIDATION_CONSTANT.PENDING ? (
                        <Chip
                          className="row-capitalize"
                          label={course.status}
                          color="warning"
                        />
                      ) : course.status === VALIDATION_CONSTANT.APPROVED ? (
                        <Chip
                          className="row-capitalize"
                          label={course.status}
                          color="success"
                        />
                      ) : (
                        <Chip
                          className="row-capitalize"
                          label={course.status}
                          color="error"
                        />
                      )}
                    </TableCell>
                    <TableCell className="action-wrap">
                      <Tooltip
                        title={t("View Course")}
                        placement="bottom"
                        onClick={() => {
                          navigate(`/manage-courses/${course.id}`);
                        }}
                      >
                        <VisibilityIcon className="tag-edit-icon" />
                      </Tooltip>
                      {course.status == VALIDATION_CONSTANT.PENDING && (
                        <Tooltip
                          className="course-tooltip-action approve"
                          title={t("Approve Course")}
                          placement="bottom"
                          onClick={() => {
                            setCurrentUser(course.id);
                            approveRejectCourse({ status: VALIDATION_CONSTANT.APPROVED }, course.id);
                          }}
                        >
                          <CheckCircleIcon />
                        </Tooltip>
                      )}
                      {course.status == VALIDATION_CONSTANT.PENDING && (
                        <Tooltip
                          className="course-tooltip-action reject"
                          title={t("Reject Course")}
                          placement="bottom"
                          onClick={() => {
                            setDialogOpenStatus(true);
                            setCurrentUser(course.id);
                          }}
                        >
                          <DoDisturbIcon />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="tablePagination">
          <Pagination
            count={totalPage}
            page={page}
            onChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default CoursesList;
