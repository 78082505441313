import "./ReasonDialog.scss";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Field, Formik } from "formik";
import * as yup from "yup";
import { DIALOG_CONSTANT, I18_TRANSLATION_COMMON } from "../../Utilities/Constants/Constants";

const ReasonDialog = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const onClose = () => {
    return props.dialogStatus(false);
  };

  const reasonValidationSchema: any = yup.object({
    reason: yup.string().required(DIALOG_CONSTANT.VAIDATION_CONTENT).trim(),
  });

  const onSubmitReason = (values: any) => {
    return props.submitReason(values);
  };
  return (
    <>
      <Dialog
        className="reason-dialog"
        open={props.modalState}
        sx={{ "& .MuiDialog-paper": "cus-dialog" }}
      >
        <DialogTitle className="title-wrap">
          {t(props.title)}
          <div className="close-wrap">
            <CloseIcon className="close-icon" onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            initialValues={{ reason: "" }}
            validationSchema={reasonValidationSchema}
            onSubmit={(values) => {
              onSubmitReason(values);
            }}
          >
            {(formik: any) => {
              return (
                <>
                  <Field
                    as="textarea"
                    className="reason-textarea"
                    row="20"
                    name="reason"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reason}
                  />
                  {formik.touched.reason && formik.errors.reason && (
                    <p className="validation">
                      {t(`${formik.touched.reason && formik.errors.reason}`)}
                    </p>
                  )}
                  <div className="reason-button-wrap">
                    <button
                      className="btn btn-primary cancel-btn"
                      onClick={onClose}
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      className="btn btn-secondary submit-btn"
                      type="submit"
                      onClick={formik.handleSubmit}
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReasonDialog;
