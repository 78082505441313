import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import {
  API_RESPONSE,
  DASHBOARD,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  ROUTES,
  SERVICES_STATUS,
  TRANSLATION_CONSTANT,
} from "../../Utilities/Constants/Constants";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import downloadCSV from "highcharts/modules/export-data";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import tutorCount from "../../../Assets/Dashboard/tutor_count.svg";
import courseCount from "../../../Assets/Dashboard/courses_count.svg";
import schoolCount from "../../../Assets/Dashboard/school_count.svg";
import universityCount from "../../../Assets/Dashboard/university_count.svg";
import themeCount from "../../../Assets/Dashboard/theme_count.svg";
import recreationalCount from "../../../Assets/Dashboard/recreational_count.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { ar } from "date-fns/locale";
import { format } from "date-fns";
import { setTabData } from "../../Services/Redux/Reducer/Reducer";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [data, setData] = useState<any>([]);
  /* Translation hook**/
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  /* get language from redux**/
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  });


  const [noFilter, setNoFilter] = useState(true);
  const [dateSelected, setDateSelected] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (noFilter) {
      getDashboardData();
    }
  }, [noFilter]);
  const dashboardCardIcons: any = [
    tutorCount,
    schoolCount,
    universityCount,
    recreationalCount,
    courseCount,
    themeCount,
  ];
  const dashboardCardColor: any = [
    "tutor_count",
    "school_count",
    "university_count",
    "recreational_count",
    "course_count",
    "theme_count",
  ];
  /* get dashboard data**/
  const getDashboardData = () => {
    axiosInstance
      .get(
        noFilter
          ? `${API_END_POINTS.DASHBOARD}` + "?start_date=&end_date="
          : `${API_END_POINTS.DASHBOARD}` +
          `?start_date=${format(dateSelected[0].startDate, "yyyy-MM-dd")}` +
          `&end_date=${format(dateSelected[0].endDate, "yyyy-MM-dd")}`
      )
      .then((dashboardResponse: any) => {
        if (dashboardResponse.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setData(dashboardResponse.data.result.data[0]);
        }
        // loadDashboardData(dashboardResponse);
        setNoFilter(false);
      })
      .catch((error) => {
      });
  };
  /**modify dashboard data */
  const loadDashboardData = (dashboardResponse: any) => {
    if (dashboardResponse.data) {
      if (dashboardResponse.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        let countKeys: any = Object.keys(dashboardResponse.data.result.data);
        let reportsIndex: any = countKeys.indexOf(DASHBOARD.REPORTS);
        if (reportsIndex > -1) {
          countKeys.splice(reportsIndex, 1);
        }
        let value = countKeys.map((elementKeys: any, i: any) => {
          return {
            count: dashboardResponse.data.result.data[elementKeys],
            label: elementKeys.split("_").join(" "),
            icon: dashboardCardIcons[i],
            colorBg: dashboardCardColor[i],
          };
        });
        setData(value);
      }
    }
  };
  const applyFilter = () => {
    getDashboardData();
  };
  const resetFilter = () => {
    if (!noFilter) {
      setNoFilter(true);
      setDateSelected([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  };
  const handleSelect = (ranges: any) => { };
  HC_exporting(Highcharts);

  const barState: any = {
    chart: {
      type: "column",
    },
    title: {
      text: "User Rate",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "No. of Users",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    exporting: {
      enabled: true,
      menuItemDefinitions: {
        // Custom definition
        viewFullscreen: {
          text: "fullscreen",
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            {
              text: "DownloadXLSX",
              onclick() {
                // this.downloadXLSX();
              },
            },
            {
              text: "DownloadCSV",
              onclick() {
                // this.getCSV();
              },
            },
          ],
        },
      },
    },
    series: [
      {
        name: "Users",
        data: [
          49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
          95.6, 54.4,
        ],
      },
    ],
  };

  return (
    <>
      <div
        className={
          language === LANGUAGES.ENGLISH
            ? "dashboard_container"
            : "dashboard_container_rtl"
        }
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
      >
        <div
          className={
            language === LANGUAGES.ENGLISH
              ? "cards_graph_container"
              : "cards_graph_container_rtl"
          }
        >
          {/* <div className="cards_calender_wrapper"> */}
          <div className="row card_contents_wrapper">
            {/* {data.map((elements: any, index: any) => {
                return (
                  <div
                    className="card_wrapper col-md-6 col-lg-4 col-sm-12"
                    key={index}
                  >
                    <div className={elements.colorBg}>
                      <div className="count_label_container">
                        <div className="count">{elements.count}</div>
                      </div>
                      <div className="count_label">{t(elements.label)}</div>
                      <img
                        className={
                          language === LANGUAGES.ENGLISH
                            ? "dashboard-icon"
                            : "dashboard-icon-rtl"
                        }
                        src={elements.icon}
                        alt="Learn and Play"
                      />
                    </div>
                  </div>
                );
              })} */}
            <div className="col-md-6 card-wrapper">
              <div className="count-card-wrapper">
                <div className="name-wrap">
                  <h5>Tutor</h5>
                </div>
                <div className="icon-wrap">
                  <img className="dashboard_icon" src={tutorCount} />
                </div>
                <div className="count-wrap">
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.PENDING));
                      navigate(ROUTES.TUTORS_STATUS_LIST)
                    }}><span className="count-label">{data?.tutors?.pending}</span> PENDING</p>
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.APPROVED));
                      navigate(ROUTES.TUTORS_STATUS_LIST)
                    }}><span className="count-label">{data?.tutors?.approved}</span> APPROVED</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-wrapper">
              <div className="count-card-wrapper">
                <div className="name-wrap">
                  <h5>Activity - Personal</h5>
                </div>
                <div className="icon-wrap">
                  <img className="dashboard_icon" src={recreationalCount} />
                </div>
                <div className="count-wrap">
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.PENDING));
                      navigate(ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST)
                    }}><span className="count-label">{data?.recreational_personal_user?.pending}</span> PENDING</p>
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.APPROVED));
                      navigate(ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST)
                    }}><span className="count-label">{data?.recreational_personal_user?.approved}</span> APPROVED</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-wrapper">
              <div className="count-card-wrapper">
                <div className="name-wrap">
                  <h5>Activity - Camp</h5>
                </div>
                <div className="icon-wrap">
                  <img className="dashboard_icon" src={recreationalCount} />
                </div>
                <div className="count-wrap">
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.PENDING));
                      navigate(ROUTES.ACTIVITIES_CAMPUS_STATUS_LIST)
                    }}><span className="count-label">{data?.recreational_camps_user?.pending}</span> PENDING</p>
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.APPROVED));
                      navigate(ROUTES.ACTIVITIES_CAMPUS_STATUS_LIST)
                    }}><span className="count-label">{data?.recreational_camps_user?.approved}</span> APPROVED</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-wrapper">
              <div className="count-card-wrapper">
                <div className="name-wrap">
                  <h5>University</h5>
                </div>
                <div className="icon-wrap">
                  <img className="dashboard_icon" src={universityCount} />
                </div>
                <div className="count-wrap">
                  {/* <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.PENDING));
                      navigate(ROUTES.UNIVERSITIES_STATUS_LIST)
                    }}><span className="count-label">
                      {data?.universities?.pending}</span> PENDING</p> */}
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.APPROVED));
                      navigate(ROUTES.MANAGE_UNIVERSITIES)
                    }}><span className="count-label">{data?.universities?.approved}</span> APPROVED</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-wrapper">
              <div className="count-card-wrapper">
                <div className="name-wrap">
                  <h5>School</h5>
                </div>
                <div className="icon-wrap">
                  <img className="dashboard_icon" src={schoolCount} />
                </div>
                <div className="count-wrap">
                  {/* <p  className="pending_approve_btn" onClick={
                    ()=>{
                      dispatch(setTabData(SERVICES_STATUS.PENDING));
                      navigate(ROUTES.MANAGE_SCHOOLS)
                      }}><span className="count-label">{data?.schools?.pending}</span> PENDING</p> */}
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.APPROVED));
                      navigate(ROUTES.MANAGE_SCHOOLS)
                    }}><span className="count-label">{data?.schools?.approved}</span> APPROVED</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 card-wrapper">
              <div className="count-card-wrapper">
                <div className="name-wrap">
                  <h5>Theme Park</h5>
                </div>
                <div className="icon-wrap">
                  <img className="dashboard_icon" src={themeCount} />
                </div>
                <div className="count-wrap">
                  {/* <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.PENDING));
                      navigate(ROUTES.MANAGE_THEMEPARK)
                    }}><span className="count-label">{data?.theme_parks?.pending}</span> PENDING</p> */}
                  <p className="pending_approve_btn" onClick={
                    () => {
                      dispatch(setTabData(SERVICES_STATUS.APPROVED));
                      navigate(ROUTES.MANAGE_THEMEPARK)
                    }}><span className="count-label">{data?.theme_parks?.approved}</span> APPROVED</p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="pie_bar_chart_container">
            <HighchartsReact
              className="dashboard-highchart"
              highcharts={Highcharts}
              options={barState}
            />
          </div>
        </div>
        <div
          className={
            language === LANGUAGES.ENGLISH
              ? "calender_wrapper"
              : "calender_wrapper-rtl"
          }
        >
          <div className="reset_button_wrapper">
            <button onClick={resetFilter} className="reset_button">
              {t('Reset')}
            </button>
            <button onClick={applyFilter} className="apply_button">
              {t('Apply')}
            </button>
          </div>
          <DateRangePicker
            // locale={ar}
            onChange={(event: any) => {
              setDateSelected([event.selection]);
            }}
            className="calender_container"
            fixedHeight={true}
            maxDate={new Date()}
            direction="horizontal"
            ranges={dateSelected}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
