import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import { useTranslation } from "react-i18next";
import { I18_TRANSLATION_COMMON, ROUTES } from "../../../Utilities/Constants/Constants";
import Card from "@mui/material/Card";
import "./ViewTickets.scss";
import borderBottom from "../../../../Assets/Tickets/ticket-border-bottom.svg"
import NoProducts from "../../../Shared/NoProducts/NoProducts";

const ViewTickets = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [totalPage, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(90);

  const getThemeparkTickets = () => {
    let payload = {
      ticket_type_id: id,
      page: page,
      limit: rowPerPage
    }
    axiosInstance
      .post(API_END_POINTS.TICKETS_LIST, payload)
      .then((res) => {
        data.length > 0 ?
          setData([...data, ...res?.data?.result?.data]) :
          setData(res?.data?.result?.data);
        // setData(res?.data?.result?.data);
        setTotalCount(res.data.result.total_page);
      });
  };

  useEffect(() => {
    getThemeparkTickets();
  }, [page]);
  const onNavigate = () => {
    navigate(ROUTES.MANAGE_THEMEPARK);
  };

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (page + 1 <= totalPage) {
        setPage(page + 1);
      }
    }
  }



  return (
    <div className="Viewtag">
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              onClick={onNavigate}
            />
            <h5 className="name-title mt-1 ms-2">{t("ticket details")}</h5>
          </div>
        </div>
      </div>
      <Card className="EditSchool-cardHead mt-3">
        <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
          {t("tickets")}
        </div>
        <div id="card-wrap" onScroll={handleScroll}>
          {data?.length > 0 && (
            <div className="row">
              {data?.map((val: any, i: any) => {
                return (
                  <div className="col-md-4">
                    {/*  <div className="row">
                    <div className="col-md-12"> */}
                    <div className="ticket-wrap">
                      <div className="ticket-top-view">
                        <div className="label-data-wrap">

                          <label >
                            {t('Code')} 
                          </label>
                          <p className="col-md-6" id="ticket-values">:&nbsp;{val.ticket_code}</p>


                        </div>
                        <div className="label-data-wrap">

                          <label >
                            {t('Price')} 
                          </label >
                          <p id="ticket-values" >:&nbsp;{val.ticket_price}</p>


                        </div>
                        <div className="label-data-wrap">

                          <label >
                            {t('Status')} 
                          </label>
                          <p id="ticket-values" >:&nbsp;{t(`${val.status}`)}</p>


                        </div>
                        {val?.status==="sold" && <div className="label-data-wrap">

                          <label >
                            {t('Email')} 
                          </label>
                          <p id="ticket-values-email" >:&nbsp;{t(`${val.email}`)}</p>


                        </div>}

                        <span className="border-span"><img className="border-image" src={borderBottom} /></span>
                        <div className="left-hole"></div>
                        <div className="right-hole"></div>

                        <div className="ticket-image-wrap">
                          <img className="ticket-image" src={val.image} />
                        </div>
                      </div>


                    </div>
                    {/*  </div>
                    </div> */}

                  </div>

                );
              })}
            </div>
          )}
          {data?.length === 0 && (
            <NoProducts />
          )}
        </div>
      </Card>

      {/* <div className="ticket-wrap">
           <div className="ticket-top-view">
       
           <div className="left-hole">
              </div> 
              <div className="right-hole">
                 
            </div> 
           </div> 
                 
           
      </div> */}


      

        {/* <div className="ticket-wrap">
           <div className="ticket-top-view">
              <div className="label-data-wrap">
                <label>
                  Ticket Code :
                </label>
                <p>12121</p>
              </div>
              <div className="label-data-wrap">
                <label>
                  Price  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </label>
                <p>12121</p>
              </div>
              <div className="label-data-wrap">
                <label>
                  Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                </label>
                <p>Available</p>
              </div>

       
           <div className="left-hole"></div> 
           <div className="right-hole"></div> 
           </div> 
                 
           
      </div>
 */}
    </div>
  );
};

export default ViewTickets;
