import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import "./AdminsList.scss";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, I18_TRANSLATION_COMMON, NUMBER_CONSTANTS, ROUTES, TOASTER_CONFIGS } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import { checkEmpty } from "../../../Services/Helpers/Helper";
import edit from "../../../../Assets/global/edit.svg";
import { useNavigate } from "react-router-dom";

const AdminsList = ()=>{
   const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
   const [ adminsList, setAdminsList ] = useState([]);
   const [hasProducts, setHasProducts] = useState(API_RESPONSE.FALSE);
   const navigate = useNavigate();
   const [page, setPage] = useState(1);
    const [totalPage, setTotalCount] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(10);
  
   useEffect(()=>{
    getAdminsList();
 },[page,rowPerPage]);


   /* Getting admins list */
   const getAdminsList = ()=> {
      axiosInstance
      .get(API_END_POINTS.ADMIN_LIST + "?page=" + page + "&limit=" + rowPerPage)
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setAdminsList(response.data.result.data);
          setTotalCount(response.data.result.total_page);
          setHasProducts(checkEmpty(response.data.result.data));
        }
      });
   };

    /**Handle row per page change */
    const onRowChange = (event: any) => {
      setPage(NUMBER_CONSTANTS.ONE);
      setRowPerPage(parseInt(event.target.value));
  };

  /* Handle page change */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };



   return(
    <div className="admin-list-wrapper">
      <div className="table-wrapper">
      <div className="submit-btn-wrapper">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => {
                     navigate(ROUTES.CREATE_ADMIN)
                  }}
                >
                  Add Admin
                </button>
              </div>
         <TableContainer component={Paper}>
         <Table>
          <TableHead>
            <TableRow>
            <TableCell>{t("S.NO")}</TableCell>
            <TableCell>{t("Admin ID")}</TableCell>
            <TableCell>{t("First Name")}</TableCell>
            <TableCell>{t("Last Name")}</TableCell>
            <TableCell>{t("Email")}</TableCell>
            <TableCell>{t("Actions")}</TableCell>
               </TableRow>
               </TableHead>
               <TableBody>
               {adminsList.map((universityDataElement: any, index: any) => {
                    return(
                     <>
                     <TableRow key={index + 1}>
                        <TableCell>
                             { page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}
                        </TableCell>
                        <TableCell>
                              {universityDataElement.id}
                        </TableCell>
                        <TableCell>
                              {universityDataElement.firstname}
                        </TableCell>
                        <TableCell>
                             {universityDataElement.lastname}
                        </TableCell>
                        <TableCell>
                        {universityDataElement.email}
                        </TableCell>
                        <TableCell>
                            <img
                              onClick={() => {
                               navigate(`/manage-roles/${universityDataElement.id}`)
                              }}
                              className="view_edit_icons"
                              src={edit}
                              alt="Learn and Play"
                            />
                        </TableCell>

                     </TableRow>
                     </>
                    )
               })}
               

               </TableBody>
              
               </Table>
               {!hasProducts && <NoProducts/>}
         </TableContainer>
         {hasProducts && (
          <div className="tablePagination">
            <div className="row">
              <div className="col-md-7">
                <div className="pagination-wrap">
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="rows-per-page">
                  <p>{t("Rows per page")}:</p>
                  <select
                    className="rows-select"
                    value={rowPerPage}
                    onChange={onRowChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
   )
};

export default AdminsList;