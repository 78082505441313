import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Select } from "@mui/material";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import "./RequestedExpert.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { I18_TRANSLATION_COMMON, API_RESPONSE, TOASTER_CONFIGS, NUMBER_CONSTANTS } from "../../../Utilities/Constants/Constants";
import { checkEmpty } from "../../../Services/Helpers/Helper";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { MenuItem } from "@mui/material";
import { toast } from "react-toastify";

const RequestedExpert = ()=>{
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [expertsList, setExpertsList] = useState<any>([]);
    const [requestList, setRequestList] = useState<any>([]);
    const [hasProducts, setHasProducts] = useState(API_RESPONSE.TRUE);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalCount] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(10);

    useEffect(() => {
      getExpertsList();
      getRequestList();
      }, [page, rowPerPage]);

      useEffect(()=>{
        getExpertsList();
          getRequestList();
      },[])


            /**Handle row per page change */
        const onRowChange = (event: any) => {
            setPage(NUMBER_CONSTANTS.ONE);
            setRowPerPage(parseInt(event.target.value));
        };

        /* Handle page change */
        const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
            setPage(newPage);
          };

    
      /* Getting experts list */
      const getExpertsList = () => {
        axiosInstance.get(API_END_POINTS.EXPERTS_LIST_REQUEST).then((response: any) => {
          if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
            setExpertsList(response.data.result.data);
            getRequestList();
          }
        });
      };

      /* Get requested users list */
      const getRequestList = ()=>{
          axiosInstance.get(API_END_POINTS.EXPERTS_REQUEST_LIST+
        "?page=" +
        page +
        "&limit=" +
        rowPerPage).then((response:any)=>{
              setRequestList(response.data.result.data)
              tutorLengthCheck(response.data.result.data);
              setTotalCount(response.data.result.total_page);
              setHasProducts(checkEmpty(response.data.result.data));
          });
      };

      const tutorLengthCheck = (data: any) => {
        data.length > 0 ? setHasProducts(true) : setHasProducts(false);
      };

      /* Assign Expert API call */
      const assignExpert = (expert:any,i:any)=>{
        let payload ={
            user_id:requestList[i].user,
            expert_id:expert
        }
            axiosInstance.put(API_END_POINTS.EXPERTS_REQUEST_LIST,payload).then((response:any)=>{
                if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                    getExpertsList();
                    // getRequestList();
                    toast.success(response.data.message, TOASTER_CONFIGS);
                };              
            });
      };

      
    return(
        <div className="experts-list-wrapper">
        <div className="table-wrapper">

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("S.NO")}</TableCell>
                  <TableCell>{t("User Name")}</TableCell>
                  <TableCell>{t("Request Message")}</TableCell>
                  <TableCell>{t("Expert")}</TableCell>
                  <TableCell>{t("Asignee")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                </TableRow>
              </TableHead>
              {hasProducts && <TableBody>
                {requestList.map((expertData: any, index: any) => {
                  return (
                    <>
                      <TableRow key={index + 1}>
                        <TableCell>{page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}</TableCell>
                        <TableCell className="username-cell">{expertData.user_name}</TableCell>
                        <TableCell>{expertData.request_message ? expertData.request_message :"N/A"}</TableCell>
                        <TableCell>
                            <Select className="table-select" onChange={(e:any)=>{
                                if(e.target.value !== ''){
                                    assignExpert(e.target.value,index);
                                }
                            }}  value={requestList[index]?.expert || " "}>
                              {/*  ? setSelectValue(requestList[index]) : " " */}
                            <MenuItem value={" "}>{"Please select expert"}</MenuItem>
                                {
                                    expertsList.map((expert:any,i:any)=>{
                                        return(
                                            <MenuItem key={i+1} value={expert.id}>{expert.firstname}</MenuItem>
                                        )
                                    })
                                }
                              
                            </Select>
                        </TableCell>
                        <TableCell>{expertData.is_expert ? expertData.expert_name : 'Not Assigned'}</TableCell>
                        <TableCell>{expertData.status}</TableCell>
                        {/* <TableCell>
                              <img
                                onClick={() => {
                                navigate(`/manage-roles/${expertData.id}`)
                                }}
                                className="view_edit_icons"
                                src={edit}
                                alt="Learn and Play"
                              />
                          </TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>}
            </Table>
            {!hasProducts && <NoProducts />}
          </TableContainer>
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
};

export default RequestedExpert;