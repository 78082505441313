import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import "./UniversityStatusList.scss";
import NoProducts from "../../../../../Shared/NoProducts/NoProducts";
import { useTranslation } from "react-i18next";
import view from "../../../../../../Assets/global/view.svg";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import {
  API_RESPONSE,
  I18_TRANSLATION_COMMON,
  SERVICES_STATUS,
  VALIDATION_CONSTANT,
} from "../../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../../Utilities/Constants/ApiEndpoints";
import { checkEmpty } from "../../../../../Services/Helpers/Helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const UniversityStatusList = (schoolListProps: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [UniversityData, setUniversityData] = useState([]);
  const [hasProducts, setHasProducts] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setUniversityData([]);
    getUniversityList();
  }, [
    schoolListProps.tabValue,
    schoolListProps.rowPerPage,
    schoolListProps.page,
    schoolListProps.statusUpdated,
    schoolListProps.filterPayload,
    schoolListProps.search,
  ]);

  /**Tutors list payload */
  const getUniversityStatusListPayload = () => {
    return {
      page: schoolListProps.page,
      limit: parseInt(schoolListProps.rowPerPage),
      status: schoolListProps.tabValue.toLowerCase(),
      sort: schoolListProps.currentSort,
      search: schoolListProps.search,
      curriculam: [],
      filter: schoolListProps.filterPayload,
    };
  };

  /**School list */
  const getUniversityList = () => {
    axiosInstance
      .post(API_END_POINTS.UNIVERSITY_LIST, getUniversityStatusListPayload())
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        )
        setUniversityData(recreationalStatusResponse.data.result.data);
        schoolListProps.totalCount(recreationalStatusResponse.data.result.total_page)
        setHasProducts(checkEmpty(recreationalStatusResponse.data.result.data));
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("S.NO")}</TableCell>
              <TableCell onClick={() => schoolListProps.handleCodeSort()}>
                <TableSortLabel
                  active={true}
                  direction={schoolListProps.idSort}
                >
                  <p className="sort_label">{t("University Name")}</p>
                </TableSortLabel>
              </TableCell>
              <TableCell>{t("Email")}</TableCell>
              {/* <TableCell>{t("Phone")}</TableCell> */}
              <TableCell>{t("Location")}</TableCell>
              {/* <TableCell>{t("Website")}</TableCell> */}
              <TableCell className="table_actions_head">
                {t("Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          {hasProducts && (
            <TableBody>
              {UniversityData.map((universityDataElement: any, index: any) => {
                return (
                  <>
                    <TableRow key={index + 1}>
                      <TableCell>
                        {schoolListProps.page - 1 === 0
                          ? index + 1
                          : (schoolListProps.page - 1) *
                              schoolListProps.rowPerPage +
                            (index + 1)}
                      </TableCell>
                      <TableCell>
                        {universityDataElement.university_name}
                      </TableCell>
                      <TableCell>{universityDataElement.email}</TableCell>
                     {/*  <TableCell>
                        {universityDataElement.phone_number}
                      </TableCell> */}
                      <TableCell>
                      {universityDataElement?.authority_details[0] ? universityDataElement?.authority_details[0].address : universityDataElement?.address }
                      </TableCell>
                      {/* <TableCell>{universityDataElement.web_link}</TableCell> */}
                      <TableCell>
                        <div className="table_actions">
                          <Tooltip
                            title={t("View Category")}
                            placement="bottom"
                          >

                           {schoolListProps.tabValue=== 
                            SERVICES_STATUS.PENDING ? <button className="verify_button_wrapper" 
                            onClick={()=>{
                              navigate(
                                `/universities-status-list/${universityDataElement.id}`
                              );
                            }}
                            >Verify</button> : 
                            <img
                              onClick={() => {
                                navigate(
                                 `/universities-status-list/view/${universityDataElement.id}`
                                );
                              }}
                              className="view_edit_icons"
                              src={view}
                              alt="Learn and Play"
                            />}

                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {hasProducts && (
        <div className="tablePagination">
          <div className="row">
            <div className="col-md-7">
              <div className="pagination-wrap">
                <Pagination
                  count={schoolListProps.totalPage}
                  page={schoolListProps.page}
                  onChange={schoolListProps.handleChangePage}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="rows-per-page">
                <p>{t("Rows per page")}:</p>
                <select
                  className="rows-select"
                  value={schoolListProps.rowPerPage}
                  onChange={schoolListProps.onRowChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hasProducts && <NoProducts></NoProducts>}
    </>
  );
};
export default UniversityStatusList;
