import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import "./TutorsStatusList.scss";
import NoProducts from "../../../../../Shared/NoProducts/NoProducts";
import { useTranslation } from "react-i18next";
import view from "../../../../../../Assets/global/view.svg";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  SERVICES_STATUS,
  SORTING_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../../Utilities/Constants/ApiEndpoints";
import { checkEmpty, checkValueNullUndefinedEmpty } from "../../../../../Services/Helpers/Helper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

const TutorsStatusList = (schoolListProps: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [tutorsData, setTutorsData]: any = useState([]);
  const [hasProducts, setHasProducts] = useState(true);

  useEffect(() => {
    schoolListProps.totalCount(COMMON_CONSTANTS.ZERO);
    setTutorsData([]);
    getTutorsList();
  }, [
    schoolListProps.tabValue,
    schoolListProps.rowPerPage,
    schoolListProps.page,
    schoolListProps.filterPayload,
    schoolListProps.statusUpdated,
    schoolListProps.search,
    schoolListProps.currentSort,
  ]);

  /**Tutors list payload */
  const getRecreationalStatusListPayload = () => {
    return {
      page: schoolListProps.page,
      limit: schoolListProps.rowPerPage,
      status: checkValueNullUndefinedEmpty(schoolListProps.tabValue) ? "pending" : schoolListProps.tabValue.toLowerCase(),
      sort: schoolListProps.currentSort,
      search: schoolListProps.search,
      filter: schoolListProps.filterPayload,
    };
  };

  /**Tutors list */
  const getTutorsList = () => {
    axiosInstance
      .post(API_END_POINTS.GET_TUTORS_LIST, getRecreationalStatusListPayload())
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        )
          setTutorsData([...recreationalStatusResponse.data.result.data]);
        schoolListProps.totalCount(recreationalStatusResponse.data.result.total_page)
        setHasProducts(checkEmpty(recreationalStatusResponse.data.result.data));
      });
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("S.NO")}</TableCell>
              <TableCell onClick={() => schoolListProps.handleCodeSort()}>
                <TableSortLabel
                  active={true}
                  direction={schoolListProps.idSort}
                >
                  <p className="sort_label">{t("Tutor ID")}</p>
                </TableSortLabel>
              </TableCell>
              {schoolListProps.tabValue === SERVICES_STATUS.APPROVED && <TableCell>{t("New Service Request")}</TableCell>}
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell>{t("Phone")}</TableCell>
              <TableCell>{t("Location")}</TableCell>
              <TableCell>{t("Highest Qualification")}</TableCell>
              <TableCell>{t("Rating")}</TableCell>

              <TableCell className="table_actions_head">
                {t("Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          {hasProducts && (
            <TableBody>
              {tutorsData.map((tutorsDataElements: any, index: any) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>
                        {schoolListProps.page - 1 === 0
                          ? index + 1
                          : (schoolListProps.page - 1) *
                          schoolListProps.rowPerPage +
                          (index + 1)}
                      </TableCell>
                      <TableCell>{tutorsDataElements.id}</TableCell>
                      {schoolListProps.tabValue === SERVICES_STATUS.APPROVED && <TableCell>{tutorsDataElements.is_new_request ? <div  className="req_btn_wrapper">New Request</div> : "Completed"}</TableCell>}
                      <TableCell className="name_label">
                        {`${tutorsDataElements.profile_details.firstname}` +
                          " " +
                          `${tutorsDataElements.profile_details.lastname}`}
                      </TableCell>
                      <TableCell>
                        {tutorsDataElements.profile_details.email}
                      </TableCell>
                      <TableCell>
                        {tutorsDataElements.profile_details.phone_number}{" "}
                      </TableCell>
                      <TableCell>
                        {`${tutorsDataElements.address},` +
                          " " +
                          `${tutorsDataElements.city},` +
                          " " +
                          `${tutorsDataElements.state},` +
                          " " +
                          `${tutorsDataElements.country}`}
                      </TableCell>
                      <TableCell>{tutorsDataElements?.high_education}</TableCell>
                      <TableCell>{tutorsDataElements?.rating}</TableCell>

                      <TableCell>
                        <div className="table_actions">
                          {schoolListProps.tabValue.toLowerCase() !==
                            VALIDATION_CONSTANT.PENDING ? (
                            <>
                              <Tooltip
                                title={t("View Category")}
                                placement="bottom"
                              >
                                <img
                                  onClick={() =>
                                    schoolListProps.viewcategory(
                                      tutorsDataElements.user
                                    )
                                  }
                                  className="view_edit_icons"
                                  src={view}
                                  alt="Learn and Play"
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <div
                              onClick={() =>
                                schoolListProps.viewcategory(
                                  tutorsDataElements.user
                                )
                              }
                              className="verify_button_wrapper"
                            >
                              Verify
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {hasProducts && (
        <div className="tablePagination">
          <div className="row">
            <div className="col-md-7">
              <div className="pagination-wrap">
                <Pagination
                  count={schoolListProps.totalPage}
                  page={schoolListProps.page}
                  onChange={schoolListProps.handleChangePage}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="rows-per-page">
                <p>{t("Rows per page")}:</p>
                <select
                  className="rows-select"
                  value={schoolListProps.rowPerPage}
                  onChange={schoolListProps.onRowChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hasProducts && <NoProducts></NoProducts>}
    </>
  );
};
export default TutorsStatusList;
