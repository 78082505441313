import "./SchoolDetails.scss";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import * as yup from "yup";
import {
  ADD_SCHOOLS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  ONBOARDING_ROUTES,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../Utilities/Constants/Constants";
import {
  ADD_SCHOOLS_ERROR_MESSAGES,
  ERROR_MESSAGES,
  REGEX,
} from "../../Utilities/Constants/Validations";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Card, List, ListItemButton, ListItemText } from "@mui/material";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DriveImg from "../../../Assets/schools/drive.svg";
import BackArrow from "../../../Assets/schools/back_button.svg";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import fileIcon from "../../../Assets/TutorVerification/pdfIcon.svg";
import { checkEmpty } from "../../Services/Helpers/Helper";

const SchoolDetails = () => {
  const uploadSchoolImages = useRef<HTMLInputElement | null>(null);
  const [schoolImage, setSchoolImage]: any = useState();
  const [schoolId, setSchoolId] = useState();
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  /* Translation hook**/
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  /* Navigatoin hook**/
  const navigate = useNavigate();

  const { id } = useParams();
  const dialogContent = DIALOG_CONSTANT.SCHOOL_INVITE;
  const [modalStatus, setmodalStatus] = useState(false);
  const [isFromStatus, setIsFromStatus] = useState(false);
  const [schoolData, setSchoolData] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    setIsFromStatus(
      location.pathname.split("/")[1] === ONBOARDING_ROUTES.SCHOOLS_STATUS
    );
    getSchoolDetail();
  }, []);

  const [schoolsData, setSchoolsData] = useState<any>({
    [ADD_SCHOOLS.SCHOOL_NAME]: "",
    [ADD_SCHOOLS.FOUNDED_YEAR]: "",
    [ADD_SCHOOLS.LOCATION]: "",
    [ADD_SCHOOLS.CURRICULUM]: "",
    // [ADD_SCHOOLS.SCHOOL_IMAGES]: "",
    [ADD_SCHOOLS.WEBLINK_OF_SCHOOL]: "",
    [ADD_SCHOOLS.EMAIL]: "",
    [ADD_SCHOOLS.PHONE_NUMBER]: "",
    [ADD_SCHOOLS.PRINCIPAL]: "",
    [ADD_SCHOOLS.GRADE_YEAR]: "",
    [ADD_SCHOOLS.TUITION_FEES]: "",
    [ADD_SCHOOLS.OPTIONAL_SERVICE_FEES]: "",
    [ADD_SCHOOLS.ENROLLMENT_FEES]: "",
    [ADD_SCHOOLS.RE_ENROLLMENT_FEES]: "",
    [ADD_SCHOOLS.APPLICATION_FEES]: "",
    [ADD_SCHOOLS.TOTAL_FEES]: "",
    [ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES]: "",
    [ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR]: "",
    [ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR]: "",
    [ADD_SCHOOLS.WINTER_BREAK_START_DATE]: "",
    [ADD_SCHOOLS.WINTER_BREAK_END_DATE]: "",
    [ADD_SCHOOLS.SPRING_BREAK_START_DATE]: "",
    [ADD_SCHOOLS.SPRING_BREAK_END_DATE]: "",
    [ADD_SCHOOLS.SUMMER_BREAK_START_DATE]: "",
    [ADD_SCHOOLS.SUMMER_BREAK_END_DATE]: "",
    [ADD_SCHOOLS.OTHER_HOLIDAYS]: "",
    [ADD_SCHOOLS.PUBLIC_HOLIDAYS]: "",
    [ADD_SCHOOLS.DSIB_INSPECTION_REPORT]: "",
    [ADD_SCHOOLS.DSIB_RATING]: "",
  });
  const loginValidationSchema = yup.object({
    [ADD_SCHOOLS.SCHOOL_NAME]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.SCHOOL_NAME_REQUIRED),
    [ADD_SCHOOLS.FOUNDED_YEAR]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.FOUNDED_YEAR_REQUIRED),
    [ADD_SCHOOLS.LOCATION]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.LOCATION_REQUIRED),
    [ADD_SCHOOLS.CURRICULUM]: yup.string(),
    /* .required(ADD_SCHOOLS_ERROR_MESSAGES.CURRICULUM_REQUIRED),
          [ADD_SCHOOLS.SCHOOL_IMAGES]: yup
            .string()
            .required(ADD_SCHOOLS_ERROR_MESSAGES.SCHOOL_IMAGES_REQUIRED) */ [ADD_SCHOOLS.WEBLINK_OF_SCHOOL]:
      yup
        .string()
        .required(ADD_SCHOOLS_ERROR_MESSAGES.WEBLINK_OF_SCHOOL_REQUIRED),
    [ADD_SCHOOLS.EMAIL]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.EMAIL_REQUIRED)
      .email(ADD_SCHOOLS_ERROR_MESSAGES.INVALID_EMAIL),
    [ADD_SCHOOLS.PHONE_NUMBER]: yup
      .string()
      .matches(REGEX.PHONE_VALIDATION, ERROR_MESSAGES.PHONE_NUMBER_FORMAT)
      .required(ERROR_MESSAGES.PHONE_NUMBER),
    [ADD_SCHOOLS.PRINCIPAL]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.PRINCIPAL_REQUIRED),
    [ADD_SCHOOLS.GRADE_YEAR]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS)
      .required(ADD_SCHOOLS_ERROR_MESSAGES.GRADE_YEAR_REQUIRED),
    [ADD_SCHOOLS.TUITION_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.TUITION_FEES_REQUIRED),
    [ADD_SCHOOLS.OPTIONAL_SERVICE_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.OPTIONAL_SERVICE_FEES_REQUIRED),
    [ADD_SCHOOLS.ENROLLMENT_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.ENROLLMENT_FEES_REQUIRED),
    [ADD_SCHOOLS.RE_ENROLLMENT_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.RE_ENROLLMENT_FEES_REQUIRED),
    [ADD_SCHOOLS.APPLICATION_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.APPLICATION_FEES_REQUIRED),
    [ADD_SCHOOLS.TOTAL_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.TOTAL_FEES_REQUIRED),
    [ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES]: yup
      .number()
      .typeError(ADD_SCHOOLS_ERROR_MESSAGES.ONLY_NUMBERS)
      .required(ADD_SCHOOLS_ERROR_MESSAGES.DISCOUNT_ON_TUITION_FEES_REQUIRED),
    [ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.START_OF_THE_ACADEMIC_YEAR_REQUIRED),
    [ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.END_OF_THE_ACADEMIC_YEAR_REQUIRED),
    [ADD_SCHOOLS.WINTER_BREAK_START_DATE]: yup
      .date()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.WINTER_BREAK_START_DATE_REQUIRED),
    [ADD_SCHOOLS.WINTER_BREAK_END_DATE]: yup
      .date()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.WINTER_BREAK_END_DATE_REQUIRED),
    [ADD_SCHOOLS.SPRING_BREAK_START_DATE]: yup
      .date()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.SPRING_BREAK_START_DATE_REQUIRED),
    [ADD_SCHOOLS.SPRING_BREAK_END_DATE]: yup
      .date()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.SPRING_BREAK_END_DATE_REQUIRED),
    [ADD_SCHOOLS.SUMMER_BREAK_START_DATE]: yup
      .date()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.SUMMER_BREAK_START_DATE_REQUIRED),
    [ADD_SCHOOLS.SUMMER_BREAK_END_DATE]: yup
      .date()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.SUMMER_BREAK_END_DATE_REQUIRED),
    [ADD_SCHOOLS.OTHER_HOLIDAYS]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.OTHER_HOLIDAYS_REQUIRED),
    [ADD_SCHOOLS.PUBLIC_HOLIDAYS]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.PUBLIC_HOLIDAYS_REQUIRED),
    [ADD_SCHOOLS.DSIB_INSPECTION_REPORT]: yup.string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.DSIB_INSPECTION_REPORT_REQUIRED),
    [ADD_SCHOOLS.DSIB_RATING]: yup.string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.DSIB_RATING_REQUIRED),
  });

  const getSchoolDetail = () => {
    axiosInstance
      .get(API_END_POINTS.SCHOOL_BULK_UPLOAD + "?id=" + id)
      .then((res: any) => {
        if (res.data.status === true)
          setSchoolImage(res.data.result.data.images);
        setSchoolDetail(res.data.result.data);
        setSchoolData(res.data.result.data);
        setSchoolId(res.data.result.data.user);
      });
  };

  const updateSchoolDetail = (payload: any) => {
    payload.grades = [
      {
        id: 1,
        grade: "a",
        tuition_fee: "1000",
        optional_service_fee: "200",
        enrollment_fee: "40",
        re_enrollment_fee: "90",
        application_fee: "30",
        total_fee: "10",
        discount_tuition_fee: "20",
      },
      {
        id: 2,
        grade: "b",
        tuition_fee: "2000",
        optional_service_fee: "200",
        enrollment_fee: "40",
        re_enrollment_fee: "90",
        application_fee: "30",
        total_fee: "10",
        discount_tuition_fee: "20",
      },
    ];
    axiosInstance
      .put(API_END_POINTS.SCHOOL_BULK_UPLOAD + "?id=" + id, payload)
      .then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
        }
      });
  };

  const onInviteSchool = (status: any) => {
    if (status === true) {
      axiosInstance
        .put(API_END_POINTS.INVITE_INSTITUTIONS + "?id=" + schoolId)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message, TOASTER_CONFIGS);
          }
        });
    }
    setmodalStatus(false);
  };

  const setSchoolDetail = (data: any) => {
    setSchoolsData({
      [ADD_SCHOOLS.SCHOOL_NAME]: data.school_name,
      [ADD_SCHOOLS.FOUNDED_YEAR]: data.founded_year,
      [ADD_SCHOOLS.LOCATION]: data.address,
      [ADD_SCHOOLS.CURRICULUM]: data.curriculam,
      // [ADD_SCHOOLS.SCHOOL_IMAGES]: ["https://www.google.com/url?sa=i&url=https%3A%2F%2Fpixabay.com%2Fimages%2Fsearch%2Fbackground%2F&psig=AOvVaw0a1iPRXHcjOtOWeT40anN-&ust=1684503395547000&source=images&cd=vfe&ved=0CBEQjRxqFwoTCPi1gqb-_v4CFQAAAAAdAAAAABAD"],
      [ADD_SCHOOLS.WEBLINK_OF_SCHOOL]: data.web_link,
      [ADD_SCHOOLS.EMAIL]: data.email,
      [ADD_SCHOOLS.PHONE_NUMBER]: data.phone_number,
      [ADD_SCHOOLS.PRINCIPAL]: data.principal,
      [ADD_SCHOOLS.GRADE_YEAR]: "",
      [ADD_SCHOOLS.TUITION_FEES]: "",
      [ADD_SCHOOLS.OPTIONAL_SERVICE_FEES]: "",
      [ADD_SCHOOLS.ENROLLMENT_FEES]: "",
      [ADD_SCHOOLS.RE_ENROLLMENT_FEES]: "",
      [ADD_SCHOOLS.APPLICATION_FEES]: "",
      [ADD_SCHOOLS.TOTAL_FEES]: "",
      [ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES]: "",
      [ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR]: data.academic_year_start,
      [ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR]: data.academic_year_end,
      [ADD_SCHOOLS.WINTER_BREAK_START_DATE]: data.winter_break_start,
      [ADD_SCHOOLS.WINTER_BREAK_END_DATE]: data.winter_break_end,
      [ADD_SCHOOLS.SPRING_BREAK_START_DATE]: data.spring_break_start,
      [ADD_SCHOOLS.SPRING_BREAK_END_DATE]: data.spring_break_end,
      [ADD_SCHOOLS.SUMMER_BREAK_START_DATE]: data.summer_break_start,
      [ADD_SCHOOLS.SUMMER_BREAK_END_DATE]: data.summer_break_end,
      [ADD_SCHOOLS.OTHER_HOLIDAYS]:
        data.other_holidays === "" ? "0" : data.other_holidays,
      [ADD_SCHOOLS.PUBLIC_HOLIDAYS]:
        data.public_holidays === "" ? 0 : data.public_holidays,
      [ADD_SCHOOLS.DSIB_INSPECTION_REPORT]: data.dsib_inspection_report,
      [ADD_SCHOOLS.DSIB_RATING]: data.dsip_rating ? data.dsip_rating : "",
      [ADD_SCHOOLS.RATING]: `${data.rating}`,
      [ADD_SCHOOLS.RANKING]: `${data.ranking}`,
    });
  };

  return (
    <>
      <Formik
        initialValues={schoolsData}
        validationSchema={loginValidationSchema}
        onSubmit={(values, actions) => {
          updateSchoolDetail(values);
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <form
            className="school_details_container"
            onSubmit={formik.handleSubmit}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="arrow-wrap">
                  <img
                    alt="Learn and Play"
                    src={BackArrow}
                    className="EditSchool-backarrow"
                    onClick={() => {
                      isFromStatus
                        ? navigate(ROUTES.SCHOOL_STATUS_LIST)
                        : navigate(ROUTES.MANAGE_SCHOOLS);
                    }}
                  />
                  <h5 className="name-title mt-1 ms-2">{t("School Detail")}</h5>
                </div>
              </div>
              {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="invite-btn-wrap">
                  <button
                    className="btn btn-secondary invite-btn"
                    onClick={() => {
                      setmodalStatus(true);
                    }}
                  >
                    {t("Invite School")} <GroupAddIcon />
                  </button>
                </div>
              </div> */}
            </div>

            <ConfirmationDialog
              content={dialogContent}
              modalState={modalStatus}
              dialogStatus={onInviteSchool}
            />

            {/* <Card className='EditSchool-cardHead'>
              <div className="EditSchool-cardTitle">
                {t('Basic Details')}
              </div>
              <div id="card-wrap">

                <div className="row detail-row-wrap">
                  <div className="col-md-6">

                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">{t('School Name')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.SCHOOL_NAME}
                      />
                      <label className="fields_label col-md-6 mt-3">{t('Founded Year')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.FOUNDED_YEAR}
                      />

                      <label className="fields_label col-md-6 mt-3">{t('Name of the Principal')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.PRINCIPAL}
                      />

                      <label className="fields_label col-md-6 mt-3">{t('Grade Year')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.GRADE_YEAR}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">{t('Email')}</label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input field-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.EMAIL}
                      />

                      <label className="fields_label col-md-6 mt-3">{t('Phone Number')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.PHONE_NUMBER}
                      />

                      <label className="fields_label col-md-6 mt-3">{t('Weblink of school')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.WEBLINK_OF_SCHOOL}
                      />

                      <label className="fields_label col-md-6 mt-3">{t('Location')}</label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.LOCATION}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}

            <Card className="EditSchool-cardHead">
              <div className="EditSchool-cardTitle">{t("Basic Details")}</div>
              <div id="card-wrap" className="detail-row-wrap">
                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("School Name")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.SCHOOL_NAME}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Email")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input field-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.EMAIL}
                      />
                    </div>
                  </div>
                </div>

                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Founded Year")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.FOUNDED_YEAR}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Phone Number")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.PHONE_NUMBER}
                      />
                    </div>
                  </div>
                </div>

                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Name of the Principal")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.PRINCIPAL}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Weblink of school")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input field-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.WEBLINK_OF_SCHOOL}
                      />
                    </div>
                  </div>
                </div>

                <div className="row basic-detail-row">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Rating")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.RATING}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("DSIB Rating")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input field-input col-md-6 mt-3"
                        value={schoolData?.dsip_rating}
                      />
                    </div>
                  </div>
                </div>
                <div className="row basic-detail-row">
                  <div className="col-md-12">
                    <div className="row">
                      <label className="fields_label col-md-3 mt-3">
                        {t("Location")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input field-input col-md-9 mt-3"
                        name={ADD_SCHOOLS.LOCATION}
                      />
                    </div>
                  </div>
                </div>
                <div className="row basic-detail-row">
                  <div className="col-md-12">
                    <div className="row">
                      <label className="fields_label col-md-3 mt-3">
                        {t("Description")}
                      </label>
                      <Field
                        as="textarea"
                        disabled
                        className="EditSchool-input field-input col-md-9 mt-3"
                        value={schoolData?.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            {/*  <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Fees Details")}
              </div>
              <div id="card-wrap">
                <div className="row detail-row-wrap">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Tuition Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.TUITION_FEES}
                      />
                      <label className="fields_label col-md-6 mt-3">
                        {t("Enrollment Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.ENROLLMENT_FEES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Application Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.APPLICATION_FEES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Discount on Tuition Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.DISCOUNT_ON_TUITION_FEES}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Optional Service Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.OPTIONAL_SERVICE_FEES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Re-Enrollment Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.RE_ENROLLMENT_FEES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Total Fees")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.TOTAL_FEES}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Curriculum")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.CURRICULUM}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}

            {/* <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Academic and Vacation Details")}
              </div>
              <div id="card-wrap">
                <div className="row detail-row-wrap">
                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {t("Start of the Academic Year")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.START_OF_THE_ACADEMIC_YEAR}
                      />
                      <label className="fields_label col-md-6 mt-3">
                        {t("Winter Break Start Date")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.WINTER_BREAK_START_DATE}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Spring Break Start Date")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.SPRING_BREAK_START_DATE}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Summer Break Start Date")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.SUMMER_BREAK_START_DATE}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <label className="fields_label col-md-6 mt-3">
                        {" "}
                        {t("End of the Academic Year")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.END_OF_THE_ACADEMIC_YEAR}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Winter Break End Date")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.WINTER_BREAK_END_DATE}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Spring Break End Date")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.SPRING_BREAK_END_DATE}
                      />

                      <label className="fields_label col-md-6 mt-3">
                        {t("Summer Break End Date")}
                      </label>
                      <Field
                        disabled
                        className="EditSchool-input col-md-6 mt-3"
                        name={ADD_SCHOOLS.SUMMER_BREAK_END_DATE}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card> */}

            {/* <div className="row">
              <div className="col-md-6 mt-3">
                <Card className="EditSchool-countcard">
                  <div className="EditSchool-HolidayCardTitle">
                    {t("Holiday Count")}
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6 holiday-count">
                      <Field
                        disabled
                        className="EditSchool-count"
                        name={ADD_SCHOOLS.PUBLIC_HOLIDAYS}
                      />
                      <label className="fields_label text-center">
                        {t("Public Holidays")}
                      </label>
                    </div>
                    <div className="col-md-6 holiday-count">
                      <Field
                        disabled
                        className="EditSchool-count"
                        name={ADD_SCHOOLS.OTHER_HOLIDAYS}
                      />
                      <label className="fields_label text-center">
                        {t("Other Holidays")}
                      </label>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-md-6 mt-3">
                <Card className="EditSchool-countcard">
                  <div className="EditSchool-HolidayCardTitle">
                    {t("DSIB Report & Rating")}
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6 holiday-count">
                      <img
                        alt="Learn and Play"
                        src={DriveImg}
                        className="d-block mx-auto"
                      />
                      <label className="fields_label text-center mt-2">
                        {t("DSIB Inspection Report")}
                      </label>
                    </div>
                    <div className="col-md-6 holiday-count">
                      <Field
                        disabled
                        className="EditSchool-count"
                        name={ADD_SCHOOLS.DSIB_RATING}
                      />
                      <label className="fields_label text-center">
                        {t("DSIB Rating")}
                      </label>
                    </div>
                  </div>
                </Card>
              </div>
            </div> */}

            <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Application Process")}
              </div>
              <div id="card-wrap" className="detail-row-wrap">

                <List>
                  {schoolData?.application_process?.map((point: any, index: any) => {
                    return (
                      <div>
                        <ListItemButton className="list-btn" key={index}>
                          <ListItemText primary={point} />

                        </ListItemButton>
                      </div>
                    );
                  })}
                </List>
              </div>
            </Card>
            <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Eligibility Criteria")}
              </div>
              <div id="card-wrap" className="detail-row-wrap">

                {schoolData?.eligibility_criteria?.length > 0 && <List>
                  {schoolData?.eligibility_criteria?.map((point: any, index: any) => {
                    return (
                      <div>
                        <ListItemButton className="list-btn" key={index}>
                          <ListItemText primary={point} />

                        </ListItemButton>
                      </div>
                    );
                  })}
                </List>}
                {
                  schoolData?.eligibility_criteria?.length === 0 && <div className="m-3 p-3">N/A</div>
                }
              </div>
            </Card>
            <Card className="EditSchool-cardHead mt-3">
              <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
                {t("Eligibility Criteria")}
              </div>
              <div id="card-wrap" className="detail-row-wrap">

                <div className="event-wrap">
                  {schoolData?.fees_per_year?.length === 0 && <>No Data Available</>}
                  {schoolData?.fees_per_year?.length > 0 && <table>
                    <tr>
                      <th>Grade</th>
                      <th>Fee</th>
                      <th>Fee Structure</th>
                    </tr>
                  </table>}


                  <table>
                    {
                      schoolData?.fees_per_year?.map((grade: any, i: any) => {
                        return (
                          <>
                            <tr>
                              <td>{grade.grade}</td>
                              <td>{grade.fee}</td>
                              <td><div className="tutor_files_icon_wrapper">
                                <img
                                  className="tutor_file"
                                  src={
                                    fileIcon
                                  }
                                  alt="Learn and Play"
                                  onClick={() => {
                                    window.open(grade.detail, "_blank")
                                  }}
                                />
                              </div></td>
                            </tr>

                          </>
                        )
                      })

                    }
                  </table>




                </div>
              </div>
            </Card>

            <div className="row detail-row-wrap mt-3">
              <label className="fields_label">{t("School Images")}</label>
              {schoolImage?.map((image: any, index: any) => {
                return (
                  <div className="col-md-4 mt-3 col-sm-6">
                    <img
                      alt="Learn and Play"
                      src={image}
                      key={index}
                      className="EditSchool-schoolimage"
                    />
                  </div>
                );
              })}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SchoolDetails;
