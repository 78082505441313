import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import "./UploadTickets.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Field, Formik, yupToFormErrors } from "formik";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  S3DataGetApi,
  checkValueNullUndefinedEmpty,
  nullUndefinedEmptyCheck,
  numberOnlyWithDecimal,
  uploadFileToS3,
} from "../../../Services/Helpers/Helper";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import {
  ERROR_MESSAGES,
  REGEX,
  THEMEPARK_MESSAGES,
} from "../../../Utilities/Constants/Validations";
import {
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  TOASTER_CONFIGS,
  UPLOAD_TICKET_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import folderImg from "../../../../Assets/bulkUploadDialog/folder.svg";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog/ConfirmationDialog";
import moment from "moment";
import { useSelector } from "react-redux";

const UploadTickets = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const uploadParkTicketImages = useRef<HTMLInputElement | null>(null);
  const [ticketImages, setTicketImages]: any = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [uploadType, setUploadType] = useState(
    UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES
  );
  const [typeOfUpload, setTypeOfUpload] = useState(
    UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES
  );
  const dialogContent = DIALOG_CONSTANT.CHANGE_UPLOAD_TYPE;
  const [modalStatus, setmodalStatus] = useState(false);
  const [temporarySelectValue, setTemporarySelectValue] = useState("");
  const [themeParkList, setThemeparkList] = useState([]);
  const [themeParkId, setThemepakrId]: any = useState();
  const [eventsList, setEventsList] = useState<any>([]);
  const [initialRender, setInitialRender] = useState(true);
  const [currentEvent, setCurrentEvent]: any = useState();
  const [InitialValues, setInitialValues]: any = useState({
    ticket_price: "",
    discount_price: "",
    expiry_on: "",
  });
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });

  /* Yup validation schema */
  const ticketsValidationSchema = yup.object({
    ticket_price: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.TICKET_PRICE,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value);
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value.toString()) &&
            !value.toString().endsWith(".")
          );
        }
      )
      // .typeError("typo")
      .required(THEMEPARK_MESSAGES.TICKET_PRICE)
      .nullable(),
    expiry_on: yup.date().required(THEMEPARK_MESSAGES.EXPIRY_DATE),
  });

  const getThemeparkList = () => {
    let payload = {
      page: 1,
      limit: 100,
      search: "",
      sort: "",
    };
    axiosInstance.post(API_END_POINTS.THEME_PARK_LIST, payload).then((res) => {
      if (res.data.status === true) setThemeparkList(res.data.result.data);
    });
  };

  useEffect(() => {
    getThemeparkList();
  }, []);

  /* Check whether upload contents are of same data type */
  const checkUploadTypes = (event: any) => {
    return new Promise((resolve, reject) => {
      const isValid: any = [...event.target.files].every((media: any) => {
        if (
          (uploadType === UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES && media.type.split('/')[0] === UPLOAD_TICKET_CONSTANT.IMAGE) ||
          (uploadType !== UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES && media.type.split('/')[1] === UPLOAD_TICKET_CONSTANT.PDF)
        ) {
          return true;
        }
        return false
      });
      if (isValid) {
        resolve(true);
      } else {
        reject(false);
      }
    });

  }

  /* Handle file upload */
  const handleChange = async (event: any) => {
    try {
      const uploadedFiles = event.target.files;
      checkUploadTypes(event).then(async (status) => {

        for (let i = 0; i < uploadedFiles.length; i++) {
          const uploadedImage = await S3DataGetApi(event);
          setUploadedCount(uploadedCount + 1);


          setTimeout(() => {
            setTicketImages((ticket: any) => [...ticket, uploadedImage]);

            setProgress(((i + 1) / event.target.files.length) * 100);
          }, 0);
        }

      }).catch(err => {
        toast.error(THEMEPARK_MESSAGES.UPLOAD_TYPE)
      })

    } catch (error) {
    }

  };

  /* Progress bar */
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  /* Selecting type of upload */
  const onSelectUploadType = (event: any) => {
    setTemporarySelectValue(event.target.value);
    // event.target.value==='UPLOAD_TICKET_CONSTANT.SINGLE_PDF' ? setTypeOfUpload('single') : setTypeOfUpload('multiple')
    if (ticketImages.length > 0) {
      setmodalStatus(true);
    } else {
      setTypeOfUpload(event.target.value);
      setUploadType(event.target.value);
    }
  };

  /* Changing Select type based on confirmation dialog */
  const onChangeType = (data: any) => {
    setmodalStatus(false);
    if (data === true) {
      setTypeOfUpload(temporarySelectValue);
      setUploadType(temporarySelectValue);
      setTicketImages([]);
    }
  };


  const getThemeparkEvents = () => {
    axiosInstance.get(API_END_POINTS.GET_THEMEPARK_EVENTS + "?themepark_id=" + themeParkId).then(res => {
      if (res.data.status === true) {
        setEventsList(res.data.result.data);
      }
    })
  };

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
    } else {
      if (nullUndefinedEmptyCheck(themeParkId)) {
        getThemeparkEvents();
      } else {
        setCurrentEvent("");
      }

    }

  }, [themeParkId])

  const onSelectThemepark = (event: any) => {
    setThemepakrId(event.target.value);
  };


  const onSelectEvent = (event: any) => {
    setCurrentEvent(event.target.value);
    eventsList?.map((item: any) => {
      if (item?.id == event.target.value) {
        setInitialValues({
          ticket_price: item?.ticket_price,
          discount_price: item?.discount_price,
          expiry_on: item?.expiry_on,
        })
      }
    })
  };

  /*   const resetData = ()=>{
      setTemporarySelectValue("");
      setThemeparkList([]);
      setThemepakrId();
      setEventsList([]);
      setCurrentEvent('');
    } */

  /* Upload Ticket API call */
  const onUploadTickets = (payload: any) => {
    if (ticketImages.length > 0 && themeParkId) {
      payload.themepark_id = themeParkId;
      payload.qr_images = ticketImages;
      payload.ticket_type_id = currentEvent;
      let upload_type = uploadType === UPLOAD_TICKET_CONSTANT.SINGLE_PDF ? '1' : (uploadType === UPLOAD_TICKET_CONSTANT.MULTIPLE_PDF ? '2' : '3');
      axiosInstance.post(API_END_POINTS.UPLOAD_TICKETS + "?upload_type=" + upload_type, payload).then((res) => {
        if (res.data.status === true)
          toast.success(res.data.message, TOASTER_CONFIGS);
        props.handleClose();
        setThemepakrId('')
        setTicketImages([]);
      });
    } else {
      if (!themeParkId) {
        toast.error(THEMEPARK_MESSAGES.SELECT_THEMEPARK);
      } else if (ticketImages.length === 0) {
        toast.error(THEMEPARK_MESSAGES.UPLOAD_VALIDATION);
      }
    }
  };

  return (
    <>
      <Dialog open={props.dialogState} className="dialog-wrap">
        <DialogTitle className="title-wrap">
          {t("Upload Themepark Tickets")}
          <div className="close-wrap">
            <CloseIcon
              className="close-icon"
              onClick={() => {
                props.handleClose();
                setThemepakrId('')
                setTicketImages([]);
              }}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            validationSchema={ticketsValidationSchema}
            initialValues={InitialValues}
            onSubmit={(values: any) => {
              onUploadTickets(values);
            }}
            enableReinitialize
          >
            {(formik: any) => {
              return (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="fields_label_wrapper">
                        <label className="fields_label">
                          {t("Theme Park")}:
                        </label>

                        <select
                          className="type-select"
                          onChange={onSelectThemepark}
                        >
                          <option value={""}>
                            {t("Please select themepark")}
                          </option>
                          {themeParkList.map((park: any, index: any) => {
                            return (
                              <option className="themepark_name" value={park.id}>
                                {park.themepark_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {themeParkId && <div className="categoryWrap">
                        <label className="nameLabel">{t("Ticket type")}:</label>
                        <select
                          name="event_name"
                          className="type-select ticket_price input"
                          onChange={onSelectEvent}
                        >
                          <option value={""}>
                            {t("Please select themepark")}
                          </option>
                          {eventsList.map((event: any, index: any) => {
                            return (
                              <option className="themepark_name" value={event.id}>
                                {event.ticket_type}
                              </option>
                            );
                          })}
                        </select>
                      </div>}

                      {currentEvent && <>
                        <div className="categoryWrap">
                          <label className="nameLabel">
                            {t("Ticket Price(AED)")}:
                          </label>
                          <Field
                            name="ticket_price"
                            className="ticket_price input"
                            onInput={numberOnlyWithDecimal}
                            disabled
                          />

                          <div className="errorMessage">
                            {formik.touched.ticket_price &&
                              formik.errors.ticket_price && (
                                <p>{t(`${formik.errors.ticket_price}`)}</p>
                              )}
                          </div>
                        </div>

                        <div className="categoryWrap">
                          <label className="nameLabel">
                            {t("Discount Price(AED)")}:
                          </label>
                          <Field
                            name="discount_price"
                            className="ticket_price input"
                            onInput={numberOnlyWithDecimal}
                            disabled
                          />
                          <div className="errorMessage">
                          </div>
                        </div>

                        <div className="fields_label_wrapper">
                          <label className="fields_label">
                            {t("Expiry Date")}:
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              className="tickets_date_pickers"
                              openTo="day"
                              views={["day", "month", "year"]}
                              value={new Date(formik.values["expiry_on"])}
                              minDate={new Date()}
                              disabled
                              onChange={(date) => {
                                const formattedDate = date
                                  ? format(date, "yyyy-MM-dd")
                                  : "";
                                formik.setFieldValue("expiry_on", formattedDate);
                              }}
                            />
                          </LocalizationProvider>
                          <div className="errorMessage">
                            {formik.touched.expiry_on &&
                              formik.errors.expiry_on && (
                                <p>{t(`${formik.errors.expiry_on}`)}</p>
                              )}
                          </div>
                        </div>
                        {/* <div className="fields_label_wrapper">
                          <label className="fields_label">
                            {t("Upload Type")}:
                          </label>

                          <select
                            className="type-select"
                            value={typeOfUpload}
                            onChange={onSelectUploadType}
                          >

                            <option value={UPLOAD_TICKET_CONSTANT.SINGLE_PDF}>
                              {t("Single PDF")}
                            </option>
                            <option value={UPLOAD_TICKET_CONSTANT.MULTIPLE_PDF}>
                              {t("Multiple PDF")}
                            </option>
                            
                            <option
                              value={UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES}
                            >
                              {t("Multiple Images")}
                            </option>
                          </select>
                        </div> */}

                        <div>
                          <label className="fields_label">
                            {t("Upload Tickets")}:
                          </label>
                          {(typeOfUpload ===
                            UPLOAD_TICKET_CONSTANT.MULTIPLE_PDF ||
                            typeOfUpload ===
                            UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES) && (
                              <input
                                className="image_input"
                                type="file"
                                accept={
                                  typeOfUpload ===
                                    UPLOAD_TICKET_CONSTANT.MULTIPLE_IMAGES
                                    ? "image/png,image/jpg,image/jpeg,image/svg,.mov,.mp4,.mkv"
                                    : "application/pdf"
                                }
                                multiple
                                onChange={handleChange}
                                ref={uploadParkTicketImages}
                              />
                            )}
                          {typeOfUpload === UPLOAD_TICKET_CONSTANT.SINGLE_PDF && (
                            <input
                              className="image_input"
                              type="file"
                              accept="application/pdf"
                              onChange={handleChange}
                              ref={uploadParkTicketImages}
                            />
                          )}
                          <div className="ticket-upload-wrap">
                            <img
                              src={folderImg}
                              className="ticket-upload-btn"
                              alt="Learn and Play"
                              onClick={() =>
                                uploadParkTicketImages.current?.click()
                              }
                            />
                            <span className="choose-flile-quote">
                              {t("Choose your file from device")}
                              {t(` - ${ticketImages.length} Images`)}
                              {/* {t(` - ${uploadedCount} Images`)} */}
                            </span>
                          </div>
                        </div>
                        {progress !== 0 && ticketImages.length !== 0 && (
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress
                              className="progress-bar mt-3"
                              variant="determinate"
                              value={progress}
                            />
                          </Box>
                        )}
                        {progress !== 0 && ticketImages.length !== 0 && (
                          <p className="progress-number">{progress}%</p>
                        )}
                        <div className="tickets-btn-wrap">
                          <button
                            className="btn btn-secondary ticket-cancel-btn"
                            onClick={() => {
                              props.handleClose();
                              setThemepakrId('')
                              setTicketImages([]);
                            }}
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-secondary ticket-submit-btn"
                            onClick={() => {
                              formik.handleSubmit();
                            }}
                          >
                            {t("Submit")}
                          </button>
                        </div>
                      </>}
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </DialogContent>
        <ConfirmationDialog
          content={dialogContent}
          modalState={modalStatus}
          dialogStatus={onChangeType}
        />
      </Dialog>
    </>
  );
};

export default UploadTickets;
