import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import "./ActivitiesStatusList.scss";
import NoProducts from "../../../../../Shared/NoProducts/NoProducts";
import { useTranslation } from "react-i18next";
import view from "../../../../../../Assets/global/view.svg";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ONBOARDING_ROUTES,
  ROUTES,
  SERVICES_STATUS,
  VALIDATION_CONSTANT,
} from "../../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../../Services/Interceptor/Interceptor";
import { useEffect, useState } from "react";
import API_END_POINTS from "../../../../../Utilities/Constants/ApiEndpoints";
import { checkEmpty } from "../../../../../Services/Helpers/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";

const ActivitiesStatusList = (schoolListProps: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [activitiesData, setActivitiesData] = useState([]);
  const [hasProducts, setHasProducts] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    /* schoolListProps.tabValue !==SERVICES_STATUS.APPROVED ?
     setHasProducts(API_RESPONSE.FALSE)
   :
     setHasProducts(API_RESPONSE.TRUE); */
    setHasProducts(API_RESPONSE.TRUE);
    setActivitiesData([]);
    getRecreationalList();
  }, [
    schoolListProps.tabValue,
    schoolListProps.rowPerPage,
    schoolListProps.page,
    // location,
    schoolListProps.statusUpdated,
    schoolListProps.filterPayload,
    schoolListProps.search,
    schoolListProps.userType,
    schoolListProps.currentSort
  ]);

  /**API Payload for activities status update */
  const getRecreationalStatusListPayload = () => {
    return {
      page: schoolListProps.page,
      limit: schoolListProps.rowPerPage,
      status: schoolListProps.tabValue ==="" ? "pending" : schoolListProps.tabValue.toLowerCase(),
      sort: schoolListProps.currentSort,
      search: schoolListProps.search,
      filter: schoolListProps.filterPayload
    };
  };

  /**Get detail of a particular recreational user */
  const getRecreationalList = () => {
    axiosInstance
      .post(
        location.pathname.split("/")[1] ===
          ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST
          ? API_END_POINTS.GET_RECREATIONAL_PERSONAL_STATUS_LIST
          : API_END_POINTS.GET_RECREATIONAL_CAMPUS_STATUS_LIST,
        getRecreationalStatusListPayload()
      )
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        )
          setActivitiesData(recreationalStatusResponse.data.result.data);
        schoolListProps.setTotalPage(recreationalStatusResponse.data.result?.total_page)
        setHasProducts(checkEmpty(recreationalStatusResponse.data.result.data));
      });
  };

  /**Navigate to detail view of a particular recreational user */
  const goToView = (id: any) => {
    return location.pathname.split("/")[1] ===
      ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST
      ? `/activities-personal-status-list/${id}`
      : `/activities-campus-status-list/${id}`;
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("S.NO")}</TableCell>
              <TableCell>{t("ID")}</TableCell>
              {schoolListProps.tabValue === SERVICES_STATUS.APPROVED && <TableCell>{t("New Service Request")}</TableCell>}
              <TableCell onClick={() => schoolListProps.handleCodeSort()}>
                <TableSortLabel
                  active={true}
                  direction={schoolListProps.currentSort}
                >
                  <p className="sort_label">{t("Institute Name")}</p>
                </TableSortLabel>
              </TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell>{t("Phone")}</TableCell>
              <TableCell>{t("Location")}</TableCell>
              <TableCell className="table_actions_head">
                {t("Actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          {hasProducts && (
            <TableBody>
              {activitiesData.map((activitiesDataElements: any, index: any) => {
                return (
                  <>
                    <TableRow key={index + 1} className={activitiesDataElements.is_new_request ? "new-req-row" :""}>
                      <TableCell>
                        {schoolListProps.page - 1 === 0
                          ? index + 1
                          : (schoolListProps.page - 1) *
                          schoolListProps.rowPerPage +
                          (index + 1)}
                      </TableCell>
                      <TableCell>{activitiesDataElements.id}</TableCell>
                      {schoolListProps.tabValue === SERVICES_STATUS.APPROVED && <TableCell className="btn-cell">{activitiesDataElements.is_new_request ? <div  className="req_btn_wrapper">New Request</div> : "Completed"}</TableCell>}
                      <TableCell className="name-cell">
                        {`${activitiesDataElements.profile_details.firstname}` +
                          " " +
                          `${activitiesDataElements.profile_details.lastname}`}
                      </TableCell>
                      <TableCell>
                        {activitiesDataElements.profile_details.email}
                      </TableCell>
                      <TableCell>
                        {activitiesDataElements.profile_details.phone_number}
                      </TableCell>
                      <TableCell>
                        {`${activitiesDataElements.address},` +
                          " " +
                          `${activitiesDataElements.city},` +
                          " " +
                          `${activitiesDataElements.state},` +
                          " " +
                          `${activitiesDataElements.country}`}
                      </TableCell>
                      <TableCell>
                        <div className="table_actions">
                          {schoolListProps?.tabValue?.toLowerCase() !==
                            VALIDATION_CONSTANT.PENDING ? (
                            <>
                              <Tooltip
                                title={t("View Category")}
                                placement="bottom"
                              >
                                <img
                                  onClick={() => {
                                    navigate(
                                      goToView(activitiesDataElements.user)
                                    );
                                  }}
                                  className="view_edit_icons"
                                  src={view}
                                  alt="Learn and Play"
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <div
                              onClick={() =>
                                schoolListProps.viewcategory(
                                  activitiesDataElements.user
                                )
                              }
                              className="verify_button_wrapper"
                            >
                              Verify
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {hasProducts && (
        <div className="tablePagination">
          <div className="row">
            <div className="col-md-7">
              <div className="pagination-wrap">
                <Pagination
                  count={schoolListProps.totalPage}
                  page={schoolListProps.page}
                  onChange={schoolListProps.handleChangePage}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="rows-per-page">
                <p>{t("Rows per page")}:</p>
                <select
                  className="rows-select"
                  value={schoolListProps.rowPerPage}
                  onChange={schoolListProps.onRowChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      {!hasProducts && <NoProducts></NoProducts>}
    </>
  );
};
export default ActivitiesStatusList;
