import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Formik, Field } from "formik";
import "./CreateExpert.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  TOASTER_CONFIGS,
} from "../../../Utilities/Constants/Constants";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../Utilities/Constants/Validations";
import { useState } from "react";
import { validateEnglish } from "../../../Services/Helpers/Helper";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { isValidPhoneNumber } from "libphonenumber-js";

const CreateExpert = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber]: any = useState(true);
  const [adminData, setAdminData] = useState({
    first_name: "",
    email: "",
    last_name: "",
  });
  const onClose = () => {
    setPhoneNumber("");
    return props.dialogStatus(false);
  };

  /* Form validation schema */
  const profileValidationSchema = yup.object({
    first_name: yup.string().min(4).required(ERROR_MESSAGES.FIRST_NAME).trim(),
    email: yup
      .string()
      .required(ERROR_MESSAGES.EMAIL_REQUIRED)
      .email(ERROR_MESSAGES.INVALID_EMAIL)
      .trim(),
    last_name: yup.string()/* .min().required(ERROR_MESSAGES.LAST_NAME) */.trim(),
    // phone_number:yup.string().matches(REGEX.PHONE_VALIDATION,ERROR_MESSAGES.PHONE_NUMBER_FORMAT).required(ERROR_MESSAGES.PHONE_NUMBER),
  });

  const onSubmitReason = (values: any) => {
    return props.submitReason(values);
  };

  /* Validating phone number */
  const validatePhoneNumber = (e: any) => {
    isValidPhoneNumber(e);
    isValidPhoneNumber(e) === true
      ? setValidPhoneNumber(true)
      : setValidPhoneNumber(false);
    return isValidPhoneNumber(e);
  };

  /* Profile update API call */
  const handleSubmit = (values: any) => {
    phoneNumber.split("+").length > 1
      ? (values.phone_number = phoneNumber)
      : (values.phone_number = "+" + phoneNumber);

    if (validPhoneNumber) {
      axiosInstance.post(API_END_POINTS.EXPERTS_LIST, values).then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          onClose();
          //  onAdminDataChange(values)
        }
      });
    }
  };

  return (
    <div className="create-expert-wrapup">
      <Dialog
        className="create-expert-dialog"
        open={props.modalState}
        sx={{ "& .MuiDialog-paper": "cus-dialog" }}
      >
        <DialogTitle className="title-wrap">
          {t("Create Expert")}
          <div className="close-wrap">
            <CloseIcon className="close-icon" onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={profileValidationSchema}
            initialValues={{
              first_name: adminData.first_name,
              email: adminData.email,
              last_name: adminData.last_name,
            }}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => {
              return (
                <div>
                  <div className="profile-parent">
                    <div className="form-wrapper">
                      <div className="row row-input">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="categoryWrap">
                            <label className="nameLabel">
                              {t("First Name:")}
                            </label>
                            <Field
                              name="first_name"
                              className="firstname input"
                              onKeyPress={validateEnglish}
                            />
                            <div className="errorMessage">
                              {props.touched.first_name &&
                                props.errors.first_name && (
                                  <p>{t(`${props.errors.first_name}`)}</p>
                                )}
                            </div>
                          </div>

                          <div className="categoryWrap">
                            <label className="nameLabel">{t("Email")}</label>
                            <Field name="email" className="email input" />
                            <div className="errorMessage">
                              {props.touched.email && props.errors.email && (
                                <p>{t(`${props.errors.email}`)}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <div className="categoryWrap">
                            <label className="nameLabel">
                              {t("Last Name:")}
                            </label>
                            <Field
                              name="last_name"
                              className="lastname input"
                              onKeyPress={validateEnglish}
                            />
                            <div className="errorMessage">
                              {props.touched.last_name &&
                                props.errors.last_name && (
                                  <p>{t(`${props.errors.last_name}`)}</p>
                                )}
                            </div>
                          </div>
                          <div className="categoryWrapPhone categoryWrap">
                            <label className="nameLabel">
                              {t("Phone Number:")}{" "}
                            </label>

                            <PhoneInput
                              inputProps={{
                                className: " input phone_num_input",
                              }}
                              country={"ae"}
                              enableSearch={true}
                              value={phoneNumber}
                              onChange={(e: any) => {
                                setPhoneNumber(e);
                                setTimeout(() => {
                                  validatePhoneNumber("+" + e);
                                }, 0);
                              }}
                              countryCodeEditable={false}
                            />
                              <p className="mb-0 phone-validation">{COMMON_CONSTANTS.PHONE_FORMAT}</p>
                            <div className="errorMessage">
                              {validPhoneNumber === false && (
                                <p>{"Please enter valid phone number"}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-wrap-profile">
                      <button
                        className="btn  cancel-btn  mt-2"
                        onClick={() => {
                          onClose();
                          //   navigate("/dashboard");
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="btn save-btn  mt-2"
                        type="submit"
                        onClick={() => {
                          validatePhoneNumber("+" + phoneNumber);
                          props.handleSubmit();
                        }}
                      >
                        {t("Save")}
                      </button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateExpert;
