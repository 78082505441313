import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import {
  I18_TRANSLATION_COMMON,
  ROUTES,
  UPLOAD_TICKET_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { Card, Chip } from "@mui/material";
import "./ViewCategory.scss";
import { useDispatch, useSelector } from "react-redux";
import { nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";

const ViewCategory = () => {
  const { id } = useParams();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [data, setData] = useState<any>([]);
  const [course, setCourse] = useState("");
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const tabsData: any = useSelector((state: any) => {
    return state.tabsReducer.tab;
  });
  const location = useLocation();

  /* To get id from params */
  useEffect(() => {
    location.pathname.split("/")[1] ===
      UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL
      ? setType(VALIDATION_CONSTANT.RECREATION)
      : setType(VALIDATION_CONSTANT.COURSE);
  }, []);
  useEffect(() => {
    if (nullUndefinedEmptyCheck(id) && nullUndefinedEmptyCheck(type)) {
      getCategory(id);
    }
  }, [type]);
  const getCategory = (id: any) => {
    axiosInstance
      .get(
        API_END_POINTS.GET_CATEGORY_DATA +
        "?id=" +
        id +
        "&category_type=" +
        type
      )
      .then((resp) => {
        if (resp.data.status === true) {
          let datas = resp.data.result.data;
          setCourse(
            type == "recreation"
              ? resp.data.result.data?.category_name
              : resp.data.result.data?.course_category_name
          );
          setData(datas);
        }
      });
  };

  const onNavigate = () => {
    type === VALIDATION_CONSTANT.RECREATION
      ? navigate(ROUTES.RECREATIONAL_CATEGORY)
      : navigate(ROUTES.COURSE_CATEGOY_LIST);
  };

  return (
    <div className="Viewtag">
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              onClick={onNavigate}
              alt="Learn and Play"
            />
            <h5 className="name-title mt-1 ms-2">{t("categoryDetails")}</h5>
          </div>
        </div>
      </div>

      <Card className="Viewtag-card">
        <div className="row">
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Category Type")}
              </label>
              <input className="Viewtag-input col-md-6" value={type} disabled />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("English Category Name")}:
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={course}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Minimum Experience in Year")}:
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={`${data?.min_experience}`}
                disabled
              />
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Maximum Experience in Year")}:
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={`${data?.max_experience}`}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Minimum Price")}:
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={`${data?.min_price} AED`}
                disabled
              />
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Maximum Price")}:
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={`${data?.max_price} AED`}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Arabic Category Name")}:
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={
                  type == "recreation"
                    ? data?.arabic_category_name
                    : data?.arabic_coursecategory_name
                }
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-6">
                {t("Commission Percentage")}
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={data?.commission}
                disabled
              />
            </div>
          </div>
          {type === VALIDATION_CONSTANT.COURSE &&
            <>
              <div className="col-md-6 mt-3">
                <div className="row">
                  <label className="Viewtag-label col-md-6">
                    {t("Minimum Grade")}
                  </label>
                  <input
                    className="Viewtag-input col-md-6"
                    value={data?.min_grade}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="row">
                  <label className="Viewtag-label col-md-6">
                    {t("Maximum Grade")}
                  </label>
                  <input
                    className="Viewtag-input col-md-6"
                    value={data?.max_grade}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="row">
                  <label className="Viewtag-label col-md-6">
                    {t("Online Incentive")}
                  </label>
                  <input
                    className="Viewtag-input col-md-6"
                    value={data?.online_incentive + " " + "AED"}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="row">
                  <label className="Viewtag-label col-md-6">
                    {t("On-premises Incentive")}
                  </label>
                  <input
                    className="Viewtag-input col-md-6"
                    value={data?.offline_incentive + " " + "AED"}
                    disabled
                  />
                </div>
              </div>
            </>
          }
        </div>
      </Card>
    </div>
  );
};

export default ViewCategory;
