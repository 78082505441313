import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import "./ViewActivitiesCamp.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@mui/material";
import { nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";
import {
  API_RESPONSE,
  ROUTES,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { useTranslation } from "react-i18next";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import profile_default from "../../../../Assets/profile/profile_default.svg";
import emiratesID from "../../../../Assets/TutorVerification/emigrantID.svg";
import immigrantID from "../../../../Assets/TutorVerification/immigrantID.svg";
import filePDF from "../../../../Assets/TutorVerification/filePDF.svg";
import FileViewerDialog from "../../../Shared/FileViewerDialog/FileViewerDialog";

const ViewActivitiesCamp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [recreationalData, setRecreationalData]: any = useState();
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");

  useEffect(() => {
    getRecreationalData();
  }, []);

  /**Get particular recreational user data based on id */
  const getRecreationalData = () => {
    axiosInstance
      .get(API_END_POINTS.GET_RECREATIONAL_DETAIL + `?id=${id}&type=camp`)
      .then((recreationalCampResponse: any) => {
        if (
          recreationalCampResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        ) {
          setRecreationalData(recreationalCampResponse.data.result.data[0]);
        }
      });
  };

  /**Handle file viewer dialog close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(false);
    setViewerFile("");
  };

  /**Handle file viewer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    if (url) {
      setViewerFile(url);
      setFileViewerOpen(true);
    }
  };

  return (
    <>
      <div className="view-course-wrap">
        <FileViewerDialog
          open={fileViewerOpen}
          handleClose={onFilerViewerDialogClose}
          viewerFile={viewerFile}
        ></FileViewerDialog>
        <div className="row">
          <div className="col-md-12">
            <div className="arrow-wrap">
              <img
                src={BackArrow}
                alt="Learn and Play"
                className="EditSchool-backarrow"
                onClick={() => {
                  navigate(ROUTES.MANAGE_RECREATIONAL_CAMP);
                }}
              />
              <h5 className="name-title mt-1 ms-2">
                {t("Personal Activites Detail")}
              </h5>
            </div>
          </div>
        </div>
        {recreationalData?.status === VALIDATION_CONSTANT.REJECTED && (
          <div className="button-wrap-vc">
            <button className="btn btn-danger rejected-btn">
              {t("Rejected")}
            </button>
          </div>
        )}
        {recreationalData?.status === VALIDATION_CONSTANT.APPROVED && (
          <div className="button-wrap-vc">
            <button className="btn btn-success accepted-btn">
              {t("Approved")}
            </button>
          </div>
        )}
        <Card className="EditSchool-cardHead">
          <div className="EditSchool-cardTitle">
            {t("Camp Manager Details")}
          </div>
          <div id="card-wrap" className="detail-row-wrap">
            <div className="profile_image_wrapper">
              <img
                src={
                  nullUndefinedEmptyCheck(
                    recreationalData?.profile_details.image
                  )
                    ? recreationalData?.profile_details.image
                    : profile_default
                }
                alt="Learn and Play"
              />
              {recreationalData?.intro_video && (
                <div>
                  <div className="fields_label">Introduction Video:</div> <br />
                  <video controls width="300" height="150" autoPlay>
                    <source src={recreationalData?.intro_video} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>
            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("First Name")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.profile_details.firstname}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Last Name")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.profile_details.lastname}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Email")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.profile_details.email}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Phone Number")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.profile_details.phone_number}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {/* <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Language")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.profile_details.language}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Camp Rating")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.rating ? recreationalData?.rating : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card className="EditSchool-cardHead">
          <div className="EditSchool-cardTitle">{t("Camp Details")}</div>
          <div id="card-wrap" className="detail-row-wrap">
            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Camp Name")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.campname}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Activity")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.activity}
                  </div>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Camp for")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.gender}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              {/* <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Fees")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.average_fees}
                  </div>
                </div>
              </div> */}
              
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <label className="fields_label_address  mt-4">
                    {t("Activities Offered")}
                  </label>
                  <div className="field_values_chips mt-3">
                    {recreationalData?.activities_offered.length>0 && recreationalData?.activities_offered.map(
                      (amenitiesElement: any, index: any) => {
                        return (
                          <>
                            <div key={index} className="amenities_chips">
                              {amenitiesElement}
                            </div>
                          </>
                        );
                      }
                    )}
                    {recreationalData?.activities_offered.length===0 && "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="EditSchool-cardHead">
          <div className="EditSchool-cardTitle">{t("Address Details")}</div>
          <div id="card-wrap" className="detail-row-wrap">
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <label className="fields_label_address  mt-3">
                    {t("Address")}
                  </label>
                  <div className="field_values_address  mt-3">
                    {recreationalData?.address}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("City")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.city}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("State")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.state}
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Country")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.country ? recreationalData?.country : "N/A"}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Pincode")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.pincode}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="EditSchool-cardHead">
          <div className="EditSchool-cardTitle">{t("Documents")}</div>
          <div id="card-wrap" className="detail-row-wrap">
            <div className="row ">
              <div className="col-md-6 tutor_files_wrapper">
                <img
                  onClick={() => {
                    onFilerViewerDialogOpen(recreationalData.emirates_front);
                  }}
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
                <div className="tutor_info_icon_label">ID Proof Front</div>
              </div>
              <div className="col-md-6 tutor_files_wrapper">
                <img
                  onClick={() => {
                    onFilerViewerDialogOpen(recreationalData.emirates_back);
                  }}
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
                <div className="tutor_info_icon_label">ID Proof Back</div>
              </div>
            </div>
            <div className="row ">
              {recreationalData?.academy_proof_back && <div className="col-md-6 tutor_files_wrapper">
                <img
                  onClick={() => {
                    onFilerViewerDialogOpen(
                      recreationalData.academy_proof_front
                    );
                  }}
                  className="tutor_files_icon"
                  src={immigrantID}
                  alt="Learn and Play"
                />
                <div className="tutor_info_icon_label">Academy Proof Front</div>
              </div>}
             {recreationalData?.academy_proof_back && <div className="col-md-6 tutor_files_wrapper">
                <img
                  onClick={() => {
                    onFilerViewerDialogOpen(
                      recreationalData.academy_proof_back
                    );
                  }}
                  className="tutor_files_icon"
                  src={immigrantID}
                  alt="Learn and Play"
                />
                <div className="tutor_info_icon_label">Academy Proof Back</div>
              </div>}
            </div>

            {recreationalData?.certificates?.length !== 0 && (
              <div className="certificates_wrapper">
                <div className="tutor_info_icon_label">Certificates</div>
                <div className="col-md-12 row">
                  {recreationalData?.certificates?.map(
                    (tutorDetailsElement: any, index: any) => {
                      return (
                        <>
                          {tutorDetailsElement.split(".").slice(-1)[0] ===
                            "pdf" ? (
                            <img
                              key={index + 1}
                              onClick={() => {
                                onFilerViewerDialogOpen(tutorDetailsElement);
                              }}
                              className="tutor_files_icon_preview"
                              src={filePDF}
                              alt="Learn and Play"
                            />
                          ) : (
                            <img
                              key={index + 1}
                              onClick={() => {
                                onFilerViewerDialogOpen(tutorDetailsElement);
                              }}
                              className="tutor_files_icon_preview"
                              src={tutorDetailsElement}
                              alt="Learn and Play"
                            />
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </Card>
        <Card className="EditSchool-cardHead">
          <div className="EditSchool-cardTitle">{t("Banking Information")}</div>
          <div id="card-wrap" className="detail-row-wrap">
            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Bank Name")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.bank_name}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Bank branch name")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.bank_branch_name}
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Account holder name")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.account_holder_name}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("Account Number")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.bank_ac_number}
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("SWIFT Code")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.bank_ac_swift_code}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="fields_label col-md-6 mt-3">
                    {t("IBAN Number")}
                  </label>
                  <div className="field_values col-md-6 mt-3">
                    {recreationalData?.bank_iban_number}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ViewActivitiesCamp;
