import "./KeywordsList.scss";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Pagination,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { setTabData } from "../../../Services/Redux/Reducer/Reducer";
import {
  VALIDATION_CONSTANT,
  SORTING_CONSTANT,
  ROUTES,
  I18_TRANSLATION_COMMON,
  COMMON_CONSTANTS,
} from "../../../Utilities/Constants/Constants";
import NoProducts from "../../NoProducts/NoProducts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import view from "../../../../Assets/global/view.svg";
import edit from "../../../../Assets/global/edit.svg";

const KeywordsList = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);

  const [categoryList, setCategoryList] = useState([]);
  const [totalPage, settotalPage] = useState(0);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [value, setValue] = React.useState(VALIDATION_CONSTANT.COURSE);
  const [page, setPage] = useState(1);
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [nameSort, setNameSort]: any = useState(SORTING_CONSTANT.ASC);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [hasProducts, setHasProducts] = useState(true);
  const [categoryType, setCategoryType] = useState(props.courseType);

  useEffect(() => {
    setCategoryType(props.courseType);
  }, [props.courseType]);

  /* Handling page change */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  const dispatch = useDispatch();

  /**Initial API call */
  useEffect(() => {
    getCategoryList();
  }, [page, categoryType, currentSort, rowPerPage]);

  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Get Category list API call */
  const getCategoryList = () => {
    let payload = {
      page: page,
      limit: rowPerPage,
      search: searchValue.trim(),
      sort: currentSort,
      tags: COMMON_CONSTANTS.TRUE,
    };
    axiosInstance.post(props.apiEndpoint, payload).then((res: any) => {
      setCategoryList(res.data.result.data);
      tagDataCheck(res.data.result.data);
      // setPage(res.data.result.currentPageNo-1);
      settotalPage(res.data.result.total_page);
    });
  };
  const tagDataCheck = (tagsData: any) => {
    if (tagsData?.length > 0) {
      setHasProducts(true);
    } else {
      setHasProducts(false);
    }
  };

  /* Handling tag code Sorting */
  const handleCodeSort = () => {
    idSort === SORTING_CONSTANT.ASC
      ? setIdSort(SORTING_CONSTANT.DSC)
      : setIdSort(SORTING_CONSTANT.ASC);
    idSort === SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_ASCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_DESCENDING);
  };

  /* Handling Tag name sorting */
  const handleNameSort = () => {
    nameSort === SORTING_CONSTANT.ASC
      ? setNameSort(SORTING_CONSTANT.DSC)
      : setNameSort(SORTING_CONSTANT.ASC);
    nameSort === SORTING_CONSTANT.ASC
      ? setCurrentSort("category_ascending")
      : setCurrentSort("category_decending");
  };

  /* Handling Change Row */
  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE);
    setRowPerPage(parseInt(event.target.value));
  };

  return (
    <>
      <div className="tag-list-wrap">
        <div className="addbtn-div">
          <button
            className="add_tags_button"
            onClick={() => {
              // dispatch(setTabData(value));
              categoryType === VALIDATION_CONSTANT.RECREATION
                ? navigate(ROUTES.RECREATIONAL_ADD_KEYWORDS)
                : navigate(ROUTES.COURSE_ADD_KEYWORDS);
            }}
          >
            {t("Add Tags")}
          </button>
        </div>
        <div className="filter_table_wrapper">
          <TableContainer component={Paper} className="tag-list-table">
            <Table>
              <TableHead className="categoryTableHead">
                <TableRow className="categoryTableRow">
                  <TableCell>{t("S.NO")}</TableCell>
                  <TableCell onClick={() => handleCodeSort()}>
                    <TableSortLabel active={true} direction={idSort}>
                      {t("Category Code")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell onClick={() => handleNameSort()}>
                    <TableSortLabel active={true} direction={nameSort}>
                      {t("Category Name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>{t("Arabic Tags")}</TableCell>
                  <TableCell>{t("English Tags")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              {hasProducts && (
                <TableBody>
                  {categoryList.map((category: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}</TableCell>
                        <TableCell>
                          {categoryType == VALIDATION_CONSTANT.COURSE
                            ? category.course_category_code
                            : category.recreational_code}
                        </TableCell>
                        {categoryType == VALIDATION_CONSTANT.COURSE && (
                          <TableCell className="category-name">
                            {currentLanguage === "English"
                              ? category.course_category_name
                              : category.arabic_coursecategory_name}
                          </TableCell>
                        )}
                        {categoryType != VALIDATION_CONSTANT.COURSE && (
                          <TableCell className="category-name">
                            {currentLanguage === "English"
                              ? category.category_name
                              : category.arabic_category_name}
                          </TableCell>
                        )}
                        <TableCell className="arabic_tablecell">
                          <div>
                            {category["tags"]?.length > 3
                              ? category["tags"]
                                  ?.slice(0, 3)
                                  .map((tag: any, index: any) => {
                                    return tag.arabic_tag_name ? (
                                      <span key={index}>
                                        {tag.arabic_tag_name},
                                      </span>
                                    ) : (
                                      ""
                                    );
                                  })
                              : category["tags"]?.map(
                                  (tag: any, index: any) => {
                                    return tag.arabic_tag_name ? (
                                      <>
                                        <span key={index}>
                                          {tag.arabic_tag_name}
                                          {category["tags"]?.length !=
                                            index + 1 && <span>,</span>}
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    );
                                  }
                                )}
                            {category["tags"]?.length > 2 &&
                            category["tags"]?.length - 3 > 0 ? (
                              <div className="tag-count">
                                +{category["tags"]?.length - 3}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </TableCell>
                        <TableCell className="arabic_tablecell">
                          <div>
                            {category["tags"]?.length > 3
                              ? category["tags"]
                                  ?.slice(0, 3)
                                  .map((tag: any, index: any) => {
                                    return tag.tag ? (
                                      <span key={index}>{tag.tag},</span>
                                    ) : (
                                      ""
                                    );
                                  })
                              : category["tags"]?.map(
                                  (tag: any, index: any) => {
                                    return tag.tag ? (
                                      <>
                                        <span key={index}>
                                          {tag.tag}
                                          {category["tags"]?.length !=
                                            index + 1 && <span>,</span>}
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    );
                                  }
                                )}
                            {category["tags"]?.length > 2 &&
                            category["tags"]?.length - 3 > 0 ? (
                              <div className="tag-count">
                                +{category["tags"]?.length - 3}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="action-wrap">
                            <Tooltip title={t("View Tags")} placement="bottom">
                              <img
                                onClick={() => {
                                  dispatch(setTabData(value));
                                  categoryType == VALIDATION_CONSTANT.COURSE
                                    ? navigate(
                                        `/manage-tutors/keywords/${category.id}`
                                      )
                                    : navigate(
                                        `/manage-recreational/keywords/${category.id}`
                                      );
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                            <Tooltip title={t("Edit Tags")} placement="bottom">
                              <img
                                onClick={() => {
                                  dispatch(setTabData(value));
                                  categoryType == VALIDATION_CONSTANT.COURSE
                                    ? navigate(
                                        `/manage-tutors/keywords/edit/${category.id}`
                                      )
                                    : navigate(
                                        `/manage-recreational/keywords/edit/${category.id}`
                                      );
                                }}
                                className="view_edit_icons"
                                src={edit}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* <div className="tablePagination">
              <Pagination
                count={totalPage}
                page={page}
                onChange={handleChangePage}
              />
            </div> */}
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasProducts && <NoProducts></NoProducts>}
        </div>
      </div>
    </>
  );
};

export default KeywordsList;
