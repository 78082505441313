import "./App.scss";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import PublicRoutesCheck from "./App/Utilities/Routes/PublicRoutes";
import PrivateRoutesCheck from "./App/Utilities/Routes/PrivateRoutes";
import Dashboard from "./App/Components/Dashboard/Dashboard";
import ForgotPassword from "./App/Components/ForgotPassword/ForgotPassword";
import ResetPassword from "./App/Components/ResetPassword/ResetPassword";
import Login from "./App/Components/Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { LANGUAGES, ROUTES } from "./App/Utilities/Constants/Constants";
import SchoolList from "./App/Components/ManageSchools/SchoolList/SchoolList";
import UniversitiesList from "./App/Components/ManageUniversities/UniversitiesList/UniversitiesList";
import EditSchools from "./App/Components/ManageSchools/EditSchools/EditSchools";
import Profile from "./App/Components/ManageProfile/Profile/Profile";
import SchoolDetails from "./App/Shared/SchoolDetails/SchoolDetails";
import ViewUniversity from "./App/Components/ManageUniversities/ViewUniversity/ViewUniversity";
import EditUniversity from "./App/Components/ManageUniversities/EditUniversity/EditUniversity";
import ManageTutors from "./App/Components/ManageTutors/TutorsList/TutorsList";
import CoursesList from "./App/Components/ManageCourses/CoursesList/CoursesList";
import ViewCourse from "./App/Components/ManageCourses/ViewCourse/ViewCourse";
import TutorsDetail from "./App/Components/ManageTutors/TutorsDetail/TutorsDetail";
import TicketsList from "./App/Components/ManageTickets/TicketsList/TicketsList";
import RecreationalList from "./App/Components/ManageRecreational/RecreationalList/RecreationalList";
import CreateThemePark from "./App/Components/ManageTickets/CreateThemepark/CreateThemepark";
import Loader from "./App/Shared/Loader/Loader";
import ViewCategory from "./App/Shared/ManageCategories/ViewCategory/ViewCategory";
import ViewTickets from "./App/Components/ManageTickets/ViewTickets/ViewTickets";
import AddSchools from "./App/Components/ManageSchools/AddSchools/AddSchools";
import AddUniversity from "./App/Components/ManageUniversities/AddUniversity/AddUniversity";
import SchoolStatusListLayout from "./App/Components/ManageServices/Schools/SchoolStatusListLayout/SchoolStatusListLayout";
import UniversityStatusListLayout from "./App/Components/ManageServices/University/UniversityStatusListLayout/UniversityStatusListLayout";
import ActivitiesStatusListLayout from "./App/Components/ManageServices/Activities/ActivitiesStatusListLayout/ActivitiesStatusListLayout";
import TutorsStatusListLayout from "./App/Components/ManageServices/Tutors/TutorsStatusListLayout/TutorsStatusListLayout";
import ViewThemepark from "./App/Components/ManageTickets/ViewThemepark/ViewThemepark";
import RecreationalKeywords from "./App/Components/ManageRecreational/ManageRecreationalKeywords/RecreationalKeywords";
import CourseKeywordsList from "./App/Components/ManageTutors/CourseKeywordsList/CourseKeywordsList";
import CourseCategoryList from "./App/Components/ManageTutors/CourseCategoryList/CourseCategoryList";
import ManageBookings from "./App/Shared/ManageBookings/ManageBookings";
import ManageSessions from "./App/Shared/ManageSessions/ManageSessions";
import RecreationalCategory from "./App/Components/ManageRecreational/RecreationalCateogry/RecreationalCategory";
import EditKeywords from "./App/Shared/ManageKeywords/EditKeywords/EditKeywords";
import ViewKeywords from "./App/Shared/ManageKeywords/ViewKeywords/ViewKeywords";
import AddKeywords from "./App/Shared/ManageKeywords/AddKeywords/AddKeywords";
import TutorVerificationLayout from "./App/Components/ManageServices/Tutors/TutorVerificationLayout/TutorVerificationLayout";
import AddEditCategories from "./App/Shared/ManageCategories/AddEditCategories/AddEditCategories";
import ActivitiesCampVerificationLayout from "./App/Components/ManageServices/Activities/ActivitiesCampVerificationLayout/ActivitiesCampVerificationLayout";
import ActivitiesPersonalVerificationLayout from "./App/Components/ManageServices/Activities/ActivitiesPersonalVerificationLayout/ActivitiesPersonalVerificationLayout";
import ViewActivitiesCamp from "./App/Components/ManageRecreational/ViewActivitiesCamp/ViewActivitiesCamp";
import ViewActivitiesPersonal from "./App/Components/ManageRecreational/ViewActivitiesPersonal/ViewActivitiesPersonal";
import SessionDetail from "./App/Shared/ManageSessions/SessionDetail/SessionDetail";
import SchoolsUniversityVerificationLayout from "./App/Components/ManageServices/SchoolsUniversityVerification/SchoolsUniversityVerificationLayout/SchoolsUniversityVerificationLayout";
import CreateAdmin from "./App/Components/ManageRoles/CreateAdmin/CreateAdmin";
import ViewHomePageContent from "./App/Components/ManageContents/HomePage/ViewHomePageContent/ViewHomePageContent";
import CreateEditHomePageContent from "./App/Components/ManageContents/HomePage/CreateEditHomePageContent/CreateEditHomePageContent";
import AdminsList from "./App/Components/ManageRoles/AdminsList/AdminsList";
import SlotsList from "./App/Shared/ManageBookings/SlotsList/SlotsList";
import BookingsList from "./App/Shared/ManageBookings/BookingsList/BookingsList";
import HomePageList from "./App/Components/ManageContents/HomePage/HomePageList/HomePageList";
import Notifications from "./App/Components/Notifications/Notifications";
import PaymentList from "./App/Components/ManagePayments/PaymentList/PaymentList";
import PaymentDetail from "./App/Components/ManagePayments/PaymentDetail/PaymentDetail";
import ExpertsList from "./App/Components/ManageExperts/ExpertsList/ExpertsList";
import RequestedExpert from "./App/Components/ManageExperts/RequestedExpert/RequestedExpert";
import DiscountList from "./App/Components/ManageDiscounts/DiscountsList/DiscountsList";
import ManageChat from "./App/Components/ManageChat/ManageChat";
import ManageReports from "./App/Components/ManageReports/ManageReports";
import ChatRequest from "./App/Components/ChatRequest/ChatRequest";
import ProvidersList from "./App/Components/ManageInvoice/ProvidersList/ProvidersList";
import ManagePrograms from "./App/Components/ManageUniversities/ManagePrograms/ManagePrograms";
import CategoriesThemepark from "./App/Components/ManageTickets/CategoriesThemepark/CategoriesThemepark";
import BannerImageThemepark from "./App/Components/ManageTickets/BannerImageThemepark/BannerImageThemepark";
import ThemeparkTicketList from "./App/Components/ManageTickets/ThemeParkTicketList/ThemeParkTicketList";
import UsersList from "./App/Components/ManageUsers/UsersList/UsersList";
import UserDetail from "./App/Components/ManageUsers/UserDetail/UserDetail";
import BookingDetail from "./App/Components/ManageUsers/BookingDetail/BookingDetail";
import ManageCancelations from "./App/Shared/ManageCancelation/ManageCancelation";
import PromotionalContentList from "./App/Components/ManageTutors/PromotionalContentList/PromotionalContentList";
import AddEditContent from "./App/Shared/ManagePromotionalContent/AddEditContent/AddEditContent";
import ViewContent from "./App/Shared/ManagePromotionalContent/ViewContent/ViewContent";
import TutorPromotionalContentList from "./App/Components/ManageTutors/PromotionalContentList/PromotionalContentList";
import RecreationalPromotionalContentList from "./App/Components/ManageRecreational/PromotionalContentList/PromotionalContentList";

const App = () => {
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const routes = [
    {
      path: "",
      element: (
        <>
          <Navigate to={ROUTES.DASHBOARD} />
        </>
      ),
    },
    {
      path: "",
      element: <PublicRoutesCheck />,
      children: [
        {
          path: ROUTES.LOGIN,
          element: <Login />,
        },
        {
          path: ROUTES.FORGET_PASSWORD,
          element: <ForgotPassword />,
        },
        {
          path: ROUTES.RESET_PASSWORD,
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "",
      element: <PrivateRoutesCheck />,
      children: [
        {
          path: ROUTES.DASHBOARD,
          name: "dashboard",
          element: <Dashboard />,
        },
        {
          path: ROUTES.MANAGE_SCHOOLS,
          element: <SchoolList />,
        },
        {
          path: ROUTES.ADD_SCHOOLS,
          element: <AddSchools />,
        },
        {
          path: ROUTES.EDIT_SCHOOLS,
          element: <AddSchools />,
        },
        /* {
          path: ROUTES.EDIT_SCHOOLS,
          element: <EditSchools />,
        }, */
        {
          path: ROUTES.SCHOOL_DETAILS,
          element: <SchoolDetails />,
        },
        {
          path: ROUTES.SCHOOL_STATUS_VIEW_DETAILS,
          element: <SchoolDetails />,
        },
        {
          path: ROUTES.SCHOOL_STATUS_DETAILS,
          element: <SchoolsUniversityVerificationLayout />,
        },
        {
          path: ROUTES.MANAGE_UNIVERSITIES,
          element: <UniversitiesList />,
        },
        {
          path: ROUTES.VIEW_UNIVERSITY,
          element: <ViewUniversity />,
        },
        {
          path: ROUTES.VIEW_UNIVERSITY_STATUS_DETAIL,
          element: <ViewUniversity />,
        },
        {
          path: ROUTES.VIEW_UNIVERSITY_STATUS,
          element: <SchoolsUniversityVerificationLayout />,
        },
        {
          path: ROUTES.EDIT_UNIVERSITY,
          element: <AddUniversity />,
        },
        {
          path: ROUTES.MANAGE_PROGRAMS,
          element: <ManagePrograms />,
        },
        /* {
          path: ROUTES.EDIT_UNIVERSITY,
          element: <EditUniversity />,
        }, */
        {
          path: ROUTES.ADD_UNIVERSITY,
          element: <AddUniversity />,
        },
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
        {
          path: ROUTES.MANAGE_COURSES,
          element: <CoursesList />,
        },
        {
          path: ROUTES.MANAGE_TUTORS,
          element: <ManageTutors />,
        },
        {
          path: ROUTES.COURSE_DETAIL,
          element: <ViewCourse />,
        },
        {
          path: ROUTES.MANAGE_TUTORS,
          element: <ManageTutors />,
        },
        {
          path: ROUTES.TUTOR_DETAIL,
          element: <TutorsDetail />,
        },
        {
          path: ROUTES.TUTOR_BOOKINGS,
          element: <ManageBookings />,
        },
        {
          path: ROUTES.TUTOR_CANCELATIONS,
          element: <ManageCancelations />,
        },

        {
          path: ROUTES.TUTOR_PROMOTIONAL_CONTENT_VIEW,
          element: <ViewContent />,
        },
        {
          path: ROUTES.TUTOR_PROMOTIONAL_CONTENT_ADD,
          element: <AddEditContent />,
        },
        {
          path: ROUTES.TUTOR_PROMOTIONAL_CONTENT_EDIT,
          element: <AddEditContent />,
        },
        {
          path: ROUTES.TUTOR_PROMOTIONAL_CONTENT_LIST,
          element: <TutorPromotionalContentList />,
        },

        {
          path: ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_VIEW,
          element: <ViewContent />,
        },
        {
          path: ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_ADD,
          element: <AddEditContent />,
        },
        {
          path: ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_EDIT,
          element: <AddEditContent />,
        },
        {
          path: ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_LIST,
          element: <RecreationalPromotionalContentList />,
        },

        {
          path: ROUTES.RECREATIONAL_CANCELATIONS,
          element: <ManageCancelations />,
        },
        {
          path: ROUTES.TUTOR_BOOKED_LIST,
          element: <BookingsList />,
        },
        {
          path: ROUTES.RECREATIONAL_BOOKED_SLOTS,
          element: <BookingsList />,
        },
        {
          path: ROUTES.TUTOR_SLOTS,
          element: <SlotsList />,
        },
        {
          path: ROUTES.RECREATIONAL_SLOTS,
          element: <SlotsList />,
        },
        {
          path: ROUTES.TUTOR_SESSIONS,
          element: <ManageSessions />,
        },
        {
          path: ROUTES.COURSE_KEYWORDS,
          element: <CourseKeywordsList />,
        },
        {
          path: ROUTES.COURSE_VIEW_KEYWORDS,
          element: <ViewKeywords />,
        },
        {
          path: ROUTES.COURSE_ADD_KEYWORDS,
          element: <AddKeywords />,
        },
        {
          path: ROUTES.COURSE_EDIT_KEYWORDS,
          element: <EditKeywords />,
        },
        {
          path: ROUTES.COURSE_VIEW_CATEGORY,
          element: <ViewCategory />,
        },
        {
          path: ROUTES.COURSE_ADD_CATEGORY,
          element: <AddEditCategories />,
        },
        {
          path: ROUTES.COURSE_EDIT_CATEGORY,
          element: <AddEditCategories />,
        },
        {
          path: ROUTES.COURSE_CATEGOY_LIST,
          element: <CourseCategoryList />,
        },
        {
          path: ROUTES.MANAGE_THEMEPARK_CATEGORY,
          element: <CategoriesThemepark />,
        },
        {
          path: ROUTES.MANAGE_THEMEPARK_BANNERLIST,
          element: <BannerImageThemepark />,
        },
        {
          path: ROUTES.MANAGE_THEMEPARK_TICKETLIST,
          element: <ThemeparkTicketList />,
        },

        {
          path: ROUTES.MANAGE_THEMEPARK,
          element: <TicketsList />,
        },
        {
          path: ROUTES.CREATE_THEMEPARK,
          element: <CreateThemePark />,
        },
        {
          path: ROUTES.EDIT_THEMEPARK,
          element: <CreateThemePark />,
        },
        {
          path: ROUTES.VIEW_THEMEPARK,
          element: <ViewThemepark />,
        },
        {
          path: ROUTES.VIEW_TICKETS,
          element: <ViewTickets />,
        },
        {
          path: ROUTES.MANAGE_RECREATIONAL_PERSONAL,
          element: <RecreationalList />,
        },
        {
          path: ROUTES.MANAGE_RECREATIONAL_CAMP,
          element: <RecreationalList />,
        },
        {
          path: ROUTES.ACTIVITIES_CAMP_DETAIL,
          element: <ViewActivitiesCamp />,
        },
        {
          path: ROUTES.ACTIVITIES_PERSONAL_DETAIL,
          element: <ViewActivitiesPersonal />,
        },
        {
          path: ROUTES.RECREATIONAL_KEYWORDS,
          element: <RecreationalKeywords />,
        },
        {
          path: ROUTES.RECREATIONAL_ADD_KEYWORDS,
          element: <AddKeywords />,
        },
        {
          path: ROUTES.RECREATIONAL_VIEW_KEYWORDS,
          element: <ViewKeywords />,
        },
        {
          path: ROUTES.RECREATIONAL_EDIT_KEYWORDS,
          element: <EditKeywords />,
        },
        {
          path: ROUTES.RECREATIONAL_PERSONAL_STATUS_DETAIL,
          element: <ActivitiesPersonalVerificationLayout />,
        },
        {
          path: ROUTES.RECREATIONAL_CAMP_STATUS_DETAIL,
          element: <ActivitiesCampVerificationLayout />,
        },
        {
          path: ROUTES.RECREATIONAL_ADD_CATEGORY,
          element: <AddEditCategories />,
        },
        {
          path: ROUTES.RECREATIONAL_EDIT_CATEGORY,
          element: <AddEditCategories />,
        },
        {
          path: ROUTES.RECREATIONAL_VIEW_CATEGORY,
          element: <ViewCategory />,
        },
        {
          path: ROUTES.RECREATIONAL_CATEGORY,
          element: <RecreationalCategory />,
        },
        {
          path: ROUTES.RECREATIONAL_BOOKINGS,
          element: <ManageBookings />,
        },

        {
          path: ROUTES.RECREATIONAL_SESSIONS,
          element: <ManageSessions />,
        },
        {
          path: ROUTES.SESSION_DETAIL,
          element: <SessionDetail />,
        },
        {
          path: ROUTES.SCHOOL_STATUS_LIST,
          element: <SchoolStatusListLayout />,
        },
        {
          path: ROUTES.UNIVERSITIES_STATUS_LIST,
          element: <UniversityStatusListLayout />,
        },
        {
          path: ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST,
          element: <ActivitiesStatusListLayout />,
        },
        {
          path: ROUTES.ACTIVITIES_CAMPUS_STATUS_LIST,
          element: <ActivitiesStatusListLayout />,
        },
        {
          path: ROUTES.TUTORS_STATUS_LIST,
          element: <TutorsStatusListLayout />,
        },
        {
          path: ROUTES.TUTORS_VERIFICATION,
          element: <TutorVerificationLayout />,
        },
        {
          path: ROUTES.MANAGE_ROLES,
          element: <AdminsList />
        },
        {
          path: ROUTES.CREATE_ADMIN,
          element: <CreateAdmin />
        },
        {
          path: ROUTES.EDIT_ADMIN,
          element: <CreateAdmin />
        },
        {
          path: ROUTES.HOME_PAGE_CONTENTS,
          element: <HomePageList />
        },
        {
          path: ROUTES.CREATE_HOME_PAGE_CONTENTS,
          element: <CreateEditHomePageContent />
        },
        {
          path: ROUTES.CREATE_HOME_PAGE_CONTENTS_TWO,
          element: <CreateEditHomePageContent />
        },
        {
          path: ROUTES.EDIT_HOME_PAGE_CONTENTS,
          element: <CreateEditHomePageContent />
        },
        {
          path: ROUTES.NOTIFICATIONS,
          element: <Notifications />
        },
        {
          path: ROUTES.PAYMENT_LIST,
          element: <PaymentList />
        },
        {
          path: ROUTES.PAYMENT_DETAIL,
          element: <PaymentDetail />
        },
        {
          path: ROUTES.MANAGE_EXPERTS,
          element: <ExpertsList />
        },
        {
          path: ROUTES.REQUESTED_EXPERTS,
          element: <RequestedExpert />
        },
        {
          path: ROUTES.DISCOUNT_LIST,
          element: <DiscountList />
        },
        {
          path: ROUTES.MANAGE_CHAT,
          element: <ManageChat />
        },
        {
          path: ROUTES.MANAGE_CHAT_USER,
          element: <ManageChat />
        },
        {
          path: ROUTES.MANAGE_CHAT_REQUEST,
          element: <ChatRequest />
        },
        {
          path: ROUTES.MANAGE_REPORT,
          element: <ManageReports />
        },
        {
          path: ROUTES.MANAGE_INVOICE,
          element: <ProvidersList />
        },
        {
          path: ROUTES.MANAGE_USERS,
          element: <UsersList/>
        },
        {
          path : ROUTES.USER_DETAIL,
          element: <UserDetail/>
        },
        {
          path : ROUTES.USER_DETAIL,
          element: <UserDetail/>
        },
        {
          path : ROUTES.USER_BOOKINGS,
          element: <BookingDetail/>
        },

      ],
    },
    {
      path: "*",
      element: (
        <>
          <Navigate to={ROUTES.DASHBOARD} />
        </>
      ),
    },
  ];

  const routers = createBrowserRouter(routes);

  return (
    <>
      <div
        className={
          language === LANGUAGES.ENGLISH
            ? "english_container"
            : "arabic_container"
        }
      >
        <Loader></Loader>
        <RouterProvider router={routers} />
        <ToastContainer rtl={language === "English" ? false : true} />
      </div>
    </>
  );
};

export default App;
