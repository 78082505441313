import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { BulkUploadDialogModel } from "../../Utilities/Models/ConstantModels";
import "./BulkUploadDialog.scss";
import close from "../../../Assets/bulkUploadDialog/close.svg";
import folder from "../../../Assets/bulkUploadDialog/folder.svg";
import download from "../../../Assets/bulkUploadDialog/download.svg";
import warning from "../../../Assets/bulkUploadDialog/warning.svg";
import uploadSuccessfull from "../../../Assets/bulkUploadDialog/uploadSuccessfull.svg";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import {
  BULK_UPLOAD_CSV_LINKS,
  BULK_UPLOAD_TYPE,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  TOASTER_CONFIGS,
} from "../../Utilities/Constants/Constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { S3DataGetApi } from "../../Services/Helpers/Helper";

const BulkUploadDialog = (dialogData: BulkUploadDialogModel) => {
  const uploadExcel = useRef<HTMLInputElement | null>(null);
  const { isOpen, onCloseDialog, uploadType } = dialogData;
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [hasError, setHasError] = useState(false);
  const [fileUploadSuccess, setfileUploadSuccess] = useState(false);
  const [fileUploadData, setfileUploadData] = useState('');
  const [errorFileLink, seterrorFileLink] = useState("");
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  useEffect(() => {
  }, [dialogData])

  const uploadSchoolUniversityFee = (formData: any) => {
    /* axiosInstance
    .post(
      uploadType == BULK_UPLOAD_TYPE.SCHOOL
        ? API_END_POINTS.UPLOAD_FEE_STRUCTURE
        : API_END_POINTS.UPLOAD_FEE_STRUCTURE,
      formData
    )
    .then((bulkUploadResponse: any) => {
      if (bulkUploadResponse.data.result.error_key) {
        seterrorFileLink(bulkUploadResponse.data.result.download_error_url);
        setHasError(true);
      } else {
        setfileUploadSuccess(true);
      }
    })
    .catch((error: any) => {}); */
  }

  const uploadSchoolUniversity = (formData: any) => {
    axiosInstance
      .post(
        uploadType == BULK_UPLOAD_TYPE.SCHOOL
          ? API_END_POINTS.SCHOOL_BULK_UPLOAD
          : API_END_POINTS.UNIVERSITY_API,
        formData
      )
      .then((bulkUploadResponse: any) => {
        if (bulkUploadResponse.data.result.error_key) {
          seterrorFileLink(bulkUploadResponse.data.result.download_error_url);
          setHasError(true);
        } else {
          setfileUploadSuccess(true);
        }
      })
      .catch((error: any) => { });
  }

  /**Bulk upload API */
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {

    if (!event.target.files) {
      return;
    }
    let formData = new FormData();
    formData.append("csv", event?.target.files[0]);
    // if(dialogData.uploadFunctionType==='feeData'){
    uploadSchoolUniversity(formData);
    console.log('formData', formData)
    // }else{
    // uploadSchoolUniversity(formData);
    // }

  };

  const handleFileChange1 = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const uploadedImage: any = await S3DataGetApi(event);
      setfileUploadData(uploadedImage);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };


  const closeDialog = () => {
    setHasError(false);
    setfileUploadSuccess(false);
    seterrorFileLink("");
  };
  return (
    <>
      <Dialog
        onClose={() => {
          closeDialog();
          onCloseDialog();
        }}
        open={isOpen}
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
        className="bulk_upload_dialog_wrapper"
      >
        <DialogTitle className="close_wrapper">
          <img
            onClick={() => {
              closeDialog();
              onCloseDialog();
            }}
            src={close}
            alt="Learn and Play"
          />
        </DialogTitle>
        {!hasError && !fileUploadSuccess && (
          <DialogContent>
            <div className="upload_files_title">{t("Upload your files")}</div>
            <input
              className="bulk_upload_input"
              type="file"
              accept=".csv, text/csv"
              onChange={handleFileChange}
              ref={uploadExcel}
            />
            <div
              className="upload_file_wrapper"
              onClick={() => {
                uploadExcel.current!.value = "";
                uploadExcel.current?.click();
              }}
            >
              <img src={folder} alt="Learn and Play" />
              <p className="choose_file_label">
                {t("Choose your file from device")}
              </p>
            </div>

            {!fileUploadData ? (
              <a
                href={
                  uploadType == BULK_UPLOAD_TYPE.SCHOOL
                    ? BULK_UPLOAD_CSV_LINKS.SCHOOL_DATA
                    : BULK_UPLOAD_CSV_LINKS.UNIVERSITY_DATA
                }
                // href={fileUploadData ?? ''}
                target="_blank"
                download="csv"
                className="download_sample_file_button_wrapper"
              >
                <img src={download} alt="Learn and Play" />
                <div className="download_sample_file_label">
                  {t("Download Sample File")}
                </div>
              </a>
            ) : (
              <div className="download_sample_file_button_wrapper disable_btn">
                <img src={download} alt="Learn and Play" />
                <div className="download_sample_file_label">
                  {t("Download Sample File")}
                </div>
              </div>
            )}
          </DialogContent>
        )}
        {hasError && (
          <DialogContent>
            <div className="error_message_file_wrapper">
              <div className="error_message_container">
                <img src={warning} alt="" />
                <p className="warning_message">
                  {t(
                    "Please check the error file & upload the correct version"
                  )}
                </p>
              </div>
              <a
                href={errorFileLink}
                download="csv"
                className="download_sample_file_button_wrapper"
              >
                <img src={download} alt="Learn and Play" />
                <div className="download_sample_file_label">
                  {t("Download Error File")}
                </div>
              </a>
            </div>
          </DialogContent>
        )}
        {!hasError && fileUploadSuccess && (
          <DialogContent>
            <div className="error_message_container">
              <img src={uploadSuccessfull} alt="" />
              <p className="warning_message">
                {t("File uploaded successfully")}
              </p>
            </div>
            <div
              onClick={() => {
                closeDialog();
                onCloseDialog();
              }}
              className="upload_done_wrapper"
            >
              {t("Done")}
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
export default BulkUploadDialog;
