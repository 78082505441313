import { useEffect, useState } from "react";
import "./SchoolsUniversityBasicInfo.scss";
import { API_RESPONSE, BULK_UPLOAD_TYPE, COMMON_CONSTANTS, SERVICES_STATUS } from "../../../../Utilities/Constants/Constants";


const SchoolsUniversityBasicInfo = (basicProps:any)=>{
    const [ schoolUniversityDetail, setSchoolUniversityDetail ]= useState<any>({});
    const [ comments, setComments ]= useState("");
    const [ currentType, setCurrentType ] = useState("");
    const [commentsTouched, setCommentsTouched] = useState<any>(API_RESPONSE.FALSE);
    useEffect(()=>{
        if(basicProps.schoolUnivDetail){
            setSchoolUniversityDetail(basicProps.schoolUnivDetail);
            setCurrentType(basicProps.type)
            basicProps.schoolUnivDetail.basic_review_comment ? setComments( basicProps.schoolUnivDetail.basic_review_comment) : setComments("")
        }
         
    },[basicProps.schoolUnivDetail])
    return(
        <div className="basic-info-wrapper">
            { schoolUniversityDetail?.authority_details && <div>
            <div className="row data-row">
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">{ currentType===BULK_UPLOAD_TYPE.SCHOOL ? 'School Name' : 'University Name'}</div>
                     <div className="col-md-6 tutor_info">
                        {currentType===BULK_UPLOAD_TYPE.SCHOOL ?  schoolUniversityDetail?.school_name : schoolUniversityDetail?.university_name}
                     </div>
              </div>
                </div>
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">{ currentType===BULK_UPLOAD_TYPE.SCHOOL ? 'School Email' : 'University Email'}</div>
                     <div className="col-md-6 tutor_info">
                        { schoolUniversityDetail?.email }
                     </div>
              </div>
                </div>
            </div>
            <div className="row data-row">
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">Authority Name</div>
                     <div className="col-md-6 tutor_info">
                        { schoolUniversityDetail?.authority_details[0].authority_name }
                     </div>
              </div>
                </div>
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">Authority Email</div>
                     <div className="col-md-6 tutor_info">
                     { schoolUniversityDetail?.authority_details[0]?.authority_email }
                     </div>
              </div>
                </div>
            </div>
            <div className="row data-row">
                <div className="col-md-12">
                <div className="row">
                    <div className="col-md-3 tutor_info_label">Address</div>
                     <div className="col-md-9 tutor_info">
                     { schoolUniversityDetail?.authority_details[0]?.address }
                     </div>
              </div>
                </div>
                
            </div>

            <div className="row data-row">
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">Authority Phone number</div>
                     <div className="col-md-6 tutor_info">
                        { schoolUniversityDetail?.authority_details[0]?.authority_phonenumber }
                     </div>
              </div>
                </div>
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">City</div>
                     <div className="col-md-6 tutor_info">
                     { schoolUniversityDetail?.authority_details[0]?.city }
                     </div>
              </div>
                </div>
            </div>

            <div className="row data-row">
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">State</div>
                     <div className="col-md-6 tutor_info">
                        { schoolUniversityDetail?.authority_details[0]?.state }
                     </div>
              </div>
                </div>
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">Country</div>
                     <div className="col-md-6 tutor_info">
                     { schoolUniversityDetail?.authority_details[0]?.country }
                     </div>
              </div>
                </div>
            </div>

            <div className="row data-row">
                <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6 tutor_info_label">Pincode</div>
                     <div className="col-md-6 tutor_info">
                        { schoolUniversityDetail?.authority_details[0]?.pincode }
                     </div>
              </div>
                </div>
            </div>


            <div className="row tutor_info_label_wrapper">
          <div className="col-md-12">
            <div className="tutor_info_label">Comments</div>
            <textarea
              value={comments}
              onChange={(event: any) => {
                setComments(event.target.value);
              }}
              onBlur={() => {
                setCommentsTouched(true);
              }}
              placeholder="Please enter your comments"
             /*  disabled={
                tutorDetails.status !== SERVICES_STATUS.PENDING.toLowerCase()
              } */
            ></textarea>
            {(comments?.length === 0 || comments == null) &&
              commentsTouched && (
                <div className="error_message">
                  Please enter some comments about the school and authority details
                </div>
              )}
          </div>
        </div>
            </div>}
            <div className="next-btn-wrapper">
            <button
          className="next_button"
          onClick={() => {
            if(basicProps.schoolUnivDetail.status === SERVICES_STATUS.PENDING.toLowerCase()){
                /* comments?.length === 0 || comments == null
                ? setCommentsTouched(true)
                : */ basicProps.updateTutorverification({
                    basic_review: true,
                    basic_review_comment: comments,
                  });
            }else{
                basicProps.handleNext();
            }
            
          }}
        >
          {/* {schoolUniversityDetail.status === SERVICES_STATUS.PENDING.toLowerCase()
            ? ("Update & Go to Documents")
            : "Go to Documents"} */}
            Next
        </button>
            </div>
           

        </div>
    )
};

export default SchoolsUniversityBasicInfo;