import { useNavigate } from "react-router-dom";
import "./Profile.scss";
import { Formik, Field } from "formik";
import Avatar from "@mui/material/Avatar";
import profile_default from "../../../../Assets/profile/profile_default.svg";
import ChangePassword from "../ChangePassword/ChangePassword";
import { useContext, useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import {
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  TOASTER_CONFIGS,
  TRANSLATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import * as yup from "yup";
import {
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setProfileData } from "../../../Services/Redux/Reducer/Reducer";
import {
  GetSessionStorageData,
  S3DataGetApi,
  nullUndefinedEmptyCheck,
  numberOnly,
  uploadFileToS3,
} from "../../../Services/Helpers/Helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import editIcon from "../../../../Assets/profile/edit_icon.svg";

const Profile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [adminData, setAdminData] = useState({
    firstname: "",
    email: "",
    lastname: "",
  });
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });
  const dispatch = useDispatch();

  /* Getting admin detail on initialize */
  useEffect(() => {
    getAdminDetail();
  }, []);

  /* Admin getail get API call */
  const getAdminDetail = () => {
    const roleId = GetSessionStorageData(COMMON_CONSTANTS.ID);
    axiosInstance.get(API_END_POINTS.ADMIN_DETAIL + `?id=${roleId}`).then((res) => {
      setAdminData(res.data.result.data[0]);
      setPhoneNumber(res.data.result.data[0]?.phone_number);
      setProfilePicture(res.data.result.data[0].image);
      validatePhoneNumber(res.data.result.data[0].phone_number);
    });
  };

  /* Form validation schema */
  const profileValidationSchema = yup.object({
    firstname: yup.string().min(4).required(ERROR_MESSAGES.FIRST_NAME).trim(),
    email: yup
      .string()
      .required(ERROR_MESSAGES.EMAIL_REQUIRED)
      .email(ERROR_MESSAGES.INVALID_EMAIL)
      .trim(),
    lastname: yup.string()/* .min(4).required(ERROR_MESSAGES.LAST_NAME) */.trim(),
    // phone_number:yup.string().matches(REGEX.PHONE_VALIDATION,ERROR_MESSAGES.PHONE_NUMBER_FORMAT).required(ERROR_MESSAGES.PHONE_NUMBER),
  });

  /* Profile update API call */
  const handleSubmit = (values: any) => {
    const roleId = GetSessionStorageData(COMMON_CONSTANTS.ID);
    values.id = roleId;
    values.image = profilePicture;
    phoneNumber.split("+").length > 1
      ? (values.phone_number = phoneNumber)
      : (values.phone_number = "+" + phoneNumber);

    if (validatePhoneNumber(values.phone_number)) {
      axiosInstance.put(API_END_POINTS.ADMIN_DETAIL, values).then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getAdminDetail();
          dispatch(setProfileData(values));
          //  onAdminDataChange(values)
        }
      });
    };
  };

  const validatePhoneNumber = (e: any) => {
    isValidPhoneNumber(e);
    isValidPhoneNumber(e) === true
      ? setValidPhoneNumber(true)
      : setValidPhoneNumber(false);
    return isValidPhoneNumber(e);
  };
  /* Restrict to numbers */
  /* const numberOnly=(event:any)=> {
        //  const dotCount = (event.target.value.match(/\./g) || []).length 
        //|| dotCount>=1 && event.key=='.'
          if (!/[0-9+]/.test(event.key) ) {
              event.preventDefault();
              return false;
            }else{
              return true;
            }
      
        } */

  /* Validating English enter in input */
  const validateEnglish = (e: any) => {
    if (
      REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value) &&
      !/[0-9.,]/.test(e.key)
    ) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  /* Setting Dialog open status */
  function handleCheck() {
    setDialogStatus(false);
  }

  const uploadSchoolImages = useRef<HTMLInputElement | null>(null);

  const handleClick = (event: any) => {
    uploadSchoolImages.current?.click();
  };

  const handleChange = async (event: any) => {
    if (event.target.files[0].type.split("/")[0] === "image") {
      try {
        const uploadedImage: any = await S3DataGetApi(event);
        setProfilePicture(uploadedImage);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div className="profile-wrapup">
      <div className="change-password">
        <button
          className="btn btn-primary change_password_button "
          onClick={() => {
            setDialogStatus(true);
          }}
        >
          {t("Change Password")}
        </button>
      </div>
      <Formik
        enableReinitialize
        validationSchema={profileValidationSchema}
        initialValues={{
          firstname: adminData.firstname,
          email: adminData.email,
          lastname: adminData.lastname,
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          return (
            <div>
              <div className="profile-parent">
                <div className="form-wrapper">
                  <ChangePassword
                    dialogState={dialogStatus}
                    handleCheck={handleCheck}
                  />
                  <div className="profile-cover">
                    <div className="profile_wrapper">
                      <Avatar
                        alt="Admin"
                        className="profile-avatar"
                        src={
                          nullUndefinedEmptyCheck(profilePicture)
                            ? profilePicture
                            : profile_default
                        }
                        sx={{ width: 110, height: 110 }}
                      />
                      <div className="edit_icon" onClick={handleClick}>
                        <img src={editIcon} alt="Learn and Play" />
                      </div>
                    </div>
                  </div>
                  <div className="profile-cover-btn">
                    <input
                      type="file"
                      accept="image/png,image/jpg,image/jpeg,image/svg"
                      className="input-upload"
                      onChange={handleChange}
                      ref={uploadSchoolImages}
                    />
                  </div>

                  <div className="row row-input">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="categoryWrap">
                        <label className="nameLabel">{t("First Name:")}</label>
                        <Field
                          name="firstname"
                          className="firstname input"
                          onKeyPress={validateEnglish}
                        />
                        <div className="errorMessage">
                          {props.touched.firstname &&
                            props.errors.firstname && (
                              <p>{t(`${props.errors.firstname}`)}</p>
                            )}
                        </div>
                      </div>

                      <div className="categoryWrap">
                        <label className="nameLabel">{t("Email")}</label>
                        <Field
                          name="email"
                          className="email input"
                          disabled={true}
                        />
                        <div className="errorMessage">
                          {props.touched.email && props.errors.email && (
                            <p>{t(`${props.errors.email}`)}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <div className="categoryWrap">
                        <label className="nameLabel">{t("Last Name:")}</label>
                        <Field
                          name="lastname"
                          className="lastname input"
                          onKeyPress={validateEnglish}
                        />
                        <div className="errorMessage">
                          {props.touched.lastname && props.errors.lastname && (
                            <p>{t(`${props.errors.lastname}`)}</p>
                          )}
                        </div>
                      </div>
                      <div className="categoryWrapPhone">
                        <label className="nameLabel">
                          {t("Phone Number:")}{" "}
                        </label>

                        <PhoneInput
                          inputProps={{
                            className: " input phone_num_input",
                          }}
                          country={"ae"}
                          enableSearch={true}
                          value={phoneNumber}
                          onChange={(e: any) => {
                            setPhoneNumber(e);
                            setTimeout(() => {
                              validatePhoneNumber("+" + e);
                            }, 0);
                          }}
                          countryCodeEditable={false}
                        />
                        <p className="mb-0 phone-validation">{COMMON_CONSTANTS.PHONE_FORMAT}</p>
                        <div className="errorMessage">
                          {validPhoneNumber === false && (
                            <p>{"Please enter valid phone number"}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="button-wrap-profile">
                  <button
                    className="btn  cancel-btn  mt-2"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn save-btn  mt-2"
                    type="submit"
                    onClick={() => {
                      props.handleSubmit();
                    }}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default Profile;
