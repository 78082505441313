import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ThemeParkTicketList.scss";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import edit from "../../../../Assets/global/edit.svg";
import AddEditThemeParkTicket from "../AddEditThemeParkTicket/AddEditThemeParkTicket";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import { toast } from "react-toastify";
import { THEMEPARK_MESSAGES } from "../../../Utilities/Constants/Validations";

const ThemeparkTicketList = () => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [dialogOpenStatus, setDialogOpenStatus] = useState(API_RESPONSE.FALSE);
    const [TicketTypeList, setTicketTypeList] = useState<any>([]);
    const [mode, setMode] = useState(COMMON_CONSTANTS.CREATE);
    const [editPayload, setEditPaylod] = useState<any>()
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getTicketTypeApiCall();
    }, [])

    const getTicketTypeApiCall = () => {
        axiosInstance
            .get(API_END_POINTS.THEME_PARK_TICKET_TYPE_LIST + id)
            .then((res: any) => {
                if (res.data.status === true) {
                    setTicketTypeList(res.data.result.data);
                }
            });
    }

    /**Reason dialog close */
    const onCloseDialog = () => {
        setDialogOpenStatus(API_RESPONSE.FALSE);
        getTicketTypeApiCall();
    };

    const onSubmitReason = (value: any) => {
    };

    return (
        <div className="manage-process">

            <AddEditThemeParkTicket
                modalState={dialogOpenStatus}
                dialogStatus={onCloseDialog}
                submitReason={onSubmitReason}
                data={{ mode: mode, payload: editPayload, themePark_id: id }}
            />
            <div className="arrow-wrap">
                <img
                    src={BackArrow}
                    className="EditSchool-backarrow"
                    onClick={() => {
                        navigate(ROUTES.MANAGE_THEMEPARK);
                    }}
                    alt="Learn and Play"
                />
                <h5 className="name-title">
                    {t("Back to Theme Park")}
                </h5>
            </div>

            <div className="btn-wrapper">
                <button type="button" className="btn btn-primary" onClick={() => { setMode(COMMON_CONSTANTS.CREATE); setDialogOpenStatus(API_RESPONSE.TRUE); }}>Add Ticket type</button>
            </div>
            <div className="filter_category_list_container">
                <TableContainer component={Paper} className="categoryTable">
                    <Table>
                        <TableHead className="categoryTableHead">
                            <TableRow className="categoryTableRow">
                                <TableCell>{t("S.NO")}</TableCell>
                                <TableCell>{t("Ticket Type")}</TableCell>
                                <TableCell>{t("Ticket Price")}</TableCell>
                                <TableCell>{t("Discount Price")}</TableCell>
                                <TableCell>{t("Description")}</TableCell>
                                <TableCell>{t("Expiry Date")}</TableCell>
                                <TableCell>{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {TicketTypeList?.length > 0 && TicketTypeList?.map((course: any, index: any) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell className="name-cell">
                                            {course?.ticket_type}
                                        </TableCell>
                                        <TableCell className="name-cell">
                                            {course?.ticket_price}
                                        </TableCell>
                                        <TableCell className="name-cell">
                                            {course?.discount_price}
                                        </TableCell>
                                        <TableCell className="name-cell">
                                            {course?.description}
                                        </TableCell>
                                        <TableCell className="name-cell row-capitalize">
                                            {moment(course?.expiry_on).format("DD-MM-YYYY")}
                                        </TableCell>
                                        <TableCell>
                                            <div className="action-wrap">
                                                 <img
                                                    onClick={() => { 
                                                        if(course?.is_booked){
                                                            toast.error(THEMEPARK_MESSAGES.BOOKED_TICKET_ERROR);                                                            
                                                        }else{
                                                            setEditPaylod(course); setMode(COMMON_CONSTANTS.EDIT); setDialogOpenStatus(API_RESPONSE.TRUE);
                                                        }
                                                         }}
                                                    className="view_edit_icons"
                                                    src={edit}
                                                    alt="Learn and Play"
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            
                        </TableBody>
                       
                    </Table>
                    {
                                TicketTypeList?.length === 0 && <NoProducts/>
                            }
                </TableContainer>
            </div>
        </div>
    )
}



export default ThemeparkTicketList;