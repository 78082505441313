import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { API_RESPONSE, NUMBER_CONSTANTS } from "../../../Utilities/Constants/Constants";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Tooltip } from "@mui/material";
import { t } from "i18next";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import { checkEmpty } from "../../../Services/Helpers/Helper";
import view from "../../../../Assets/global/view.svg";
import { useNavigate } from "react-router-dom";

const UsersList = () =>{
    const navigate = useNavigate();
    const [ usersList , setUsersList ] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalCount] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [hasProducts, setHasProducts] = useState(API_RESPONSE.FALSE);

    useEffect(()=>{
        getUsersList();
     },[page,rowPerPage]);

     /**Handle row per page change */
     const onRowChange = (event: any) => {
        setPage(NUMBER_CONSTANTS.ONE);
        setRowPerPage(parseInt(event.target.value));
    };
  
    /* Handle page change */
    const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
     setPage(newPage);
    };

    const getUsersList = () => {
        axiosInstance
          .post(API_END_POINTS.USERS_LIST,{page: page, limit : rowPerPage})
          .then((response: any) => {
            if (response.data.status === API_RESPONSE.TRUE) {
                setUsersList(response.data.result.data);
                setTotalCount(response.data.result.total_page);
                setHasProducts(checkEmpty(response.data.result.data));
            }
          });
      };
      
    return(<div className="admin-list-wrapper">
    <div className="table-wrapper">
       <TableContainer component={Paper}>
       <Table>
        <TableHead>
          <TableRow>
          <TableCell>{t("S.NO")}</TableCell>
          <TableCell>{t("First Name")}</TableCell>
          <TableCell>{t("Last Name")}</TableCell>
          <TableCell>{t("Email")}</TableCell>
          <TableCell>{t("Phone Number")}</TableCell>
          <TableCell>{t("Actions")}</TableCell>
             </TableRow>
             </TableHead>
             <TableBody>
             {hasProducts && usersList?.map((universityDataElement: any, index: any) => {
                  return(
                   <>
                   <TableRow key={index + 1}>
                      <TableCell>
                           { page - 1 === 0
                        ? index + 1
                        : (page - 1) * rowPerPage + (index + 1)}
                      </TableCell>
                      <TableCell>
                            {universityDataElement.firstname}
                      </TableCell>
                      <TableCell>
                           {universityDataElement.lastname}
                      </TableCell>
                      <TableCell>
                      {universityDataElement.email}
                      </TableCell>
                      <TableCell>
                      {universityDataElement.phone_number ? universityDataElement.phone_number :'N/A'}
                      </TableCell>
                      <TableCell>
                           <Tooltip
                              title={t("View User")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  navigate(`/manage-users/${universityDataElement.id}`);
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                      </TableCell>

                   </TableRow>
                   </>
                  )
             })}
             

             </TableBody>
            
             </Table>
             {!hasProducts && <NoProducts/>}
       </TableContainer>
       {hasProducts && (
        <div className="tablePagination">
          <div className="row">
            <div className="col-md-7">
              <div className="pagination-wrap">
                <Pagination
                  count={totalPage}
                  page={page}
                  onChange={handleChangePage}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className="rows-per-page">
                <p>{t("Rows per page")}:</p>
                <select
                  className="rows-select"
                  value={rowPerPage}
                  onChange={onRowChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
    )
};

export default UsersList;
