import "./ActivitiesCampApplicationReview.scss";
import emiratesID from "../../../../../../../Assets/TutorVerification/emigrantID.svg";
import immigrantID from "../../../../../../../Assets/TutorVerification/immigrantID.svg";
import filePDF from "../../../../../../../Assets/TutorVerification/filePDF.svg";
import FileViewerDialog from "../../../../../../Shared/FileViewerDialog/FileViewerDialog";
import { useEffect, useState } from "react";
import VideoPlayerDialog from "../../../../../../Shared/VideoPlayerDialog/VideoPlayerDialog";
import {
  checkValueNullUndefinedEmpty,
  nullUndefinedEmptyCheck,
} from "../../../../../../Services/Helpers/Helper";
import { SERVICES_STATUS } from "../../../../../../Utilities/Constants/Constants";
import fileIcon from "../../../../../../../Assets/TutorVerification/pdfIcon.svg";

const ActivitiesCampApplicationReview = (reviewProps: any) => {
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const [videoFile, setvideoFile] = useState("");
  const [tutorDetails, setTutorDetails] = useState<any>({});
  const [comments, setComments] = useState<any>("");
  const [commentsTouched, setCommentsTouched] = useState<any>(false);

  /**Handle file viewer close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(false);
    setViewerFile("");
  };

  /**Handle file viewer open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  /**Handle Video viewer close */
  const onVideoPlayerDialogClose = () => {
    setVideoPlayerOpen(false);
    setvideoFile("");
  };

  /**Handle video viewer open */
  const onVideoPlayerDialogOpen = (url: any) => {
    setvideoFile(url);
    setVideoPlayerOpen(true);
  };

  useEffect(() => {
    setTutorDetails(reviewProps.tutorDetail);
    setComments(
      nullUndefinedEmptyCheck(
        reviewProps.tutorDetail.professional_review_comment
      )
        ? reviewProps.tutorDetail.professional_review_comment
        : ""
    );
  }, [reviewProps.tutorDetail]);

  /**Check for pdf file */
  const checkPdf = (viewerFile: any) => {
    if (nullUndefinedEmptyCheck(viewerFile)) {
      return viewerFile?.split(".").slice(-1)[0] === "pdf";
    }
    return false;
  };

  return (
    <>
      <div className="tutor_application_review_wrapper">
        <FileViewerDialog
          open={fileViewerOpen}
          handleClose={onFilerViewerDialogClose}
          viewerFile={viewerFile}
        ></FileViewerDialog>
        <VideoPlayerDialog
          open={videoPlayerOpen}
          handleClose={onVideoPlayerDialogClose}
          viewerFile={videoFile}
        ></VideoPlayerDialog>
        <div className="tutor_files_card_wrap">
          {/* <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">ID Proof Front</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.id_proof_front);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.id_proof_front)
                    ? fileIcon
                    : tutorDetails.id_proof_front
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.id_proof_front);
                }}
              />
            </div>
          </div> 
          <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">ID Proof Back</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.id_proof_back);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.id_proof_back)
                    ? fileIcon
                    : tutorDetails.id_proof_back
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.id_proof_back);
                }}
              />
            </div>
              </div> */}
        </div>
        <div className="tutor_files_card_wrap">
          <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">ID Front</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_front);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.emirates_front)
                    ? fileIcon
                    : tutorDetails.emirates_front
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_front);
                }}
              />
            </div>
          </div>
          <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">ID Back</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_back);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.emirates_back)
                    ? fileIcon
                    : tutorDetails.emirates_back
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_back);
                }}
              />
            </div>
          </div>
        </div>
        <div className="tutor_files_card_wrap">
          {tutorDetails.immigrant_front && <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">Passport Front</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_front);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.immigrant_front)
                    ? fileIcon
                    : tutorDetails.immigrant_front
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_front);
                }}
              />
            </div>
          </div>}
          {tutorDetails.immigrant_back && <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">Passport Back</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_back);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.immigrant_back)
                    ? fileIcon
                    : tutorDetails.immigrant_back
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_back);
                }}
              />
            </div>
          </div>}
        </div>
        <div className="tutor_files_card_wrap">
          {tutorDetails?.camp_broucher && <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">Camp Broucher</div>
              <div
                className="view_file_button"
                onClick={() => {
                  // onFilerViewerDialogOpen(tutorDetails?.camp_broucher[0]);
                  window.open(tutorDetails?.camp_broucher[0], '_blank');
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails?.camp_broucher[0])
                    ? fileIcon
                    : tutorDetails?.camp_broucher[0]
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails?.camp_broucher[0]);
                }}
              />
            </div>
          </div>}
          {/* <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">Academy Proof Back</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.academy_proof_back);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.academy_proof_back)
                    ? fileIcon
                    : tutorDetails.academy_proof_back
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.academy_proof_back);
                }}
              />
            </div>
          </div> */}
        </div>
        {tutorDetails.certificates?.length !== 0 && (
          <div className="certificates_wrapper">
            <div className="tutor_info_icon_label">Certificates</div>
            <div className="col-md-12 row">
              {tutorDetails.certificates?.map(
                (tutorDetailsElement: any, index: any) => {
                  return (
                    <>
                      <img
                        key={index + 1}
                        onClick={() => {
                          onFilerViewerDialogOpen(tutorDetailsElement);
                        }}
                        className="tutor_files_icon_preview"
                        src={
                          checkPdf(tutorDetailsElement)
                            ? filePDF
                            : tutorDetailsElement
                        }
                        alt="Learn and Play"
                      />
                    </>
                  );
                }
              )}
            </div>
          </div>
        )}
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-12">
            <div className="tutor_info_label_comment">Comments</div>
            <textarea
              value={comments}
              onChange={(event: any) => {
                setComments(event.target.value);
              }}
              onBlur={() => {
                setCommentsTouched(true);
              }}
              placeholder="Please enter your comments"
              disabled={
                tutorDetails.status !== SERVICES_STATUS.PENDING.toLowerCase()
              }
            ></textarea>
            {(comments?.length === 0 || comments == null) &&
              commentsTouched && (
                <div className="error_message">
                  Please enter some comments about the Tutor's personal and
                  professional details
                </div>
              )}
          </div>
        </div>
        <div className="stepper_actions_wrapper">
          <button className="back_button" onClick={reviewProps.handleBack}>
            Back
          </button>
          <button
            className="next_button"
            onClick={() => {
              if (tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase()) {
                /* comments?.length === 0 || comments == null
                  ? setCommentsTouched(true)
                  : */ reviewProps.updateTutorverification({
                    professional_review: true,
                    professional_review_comment: comments,
                  });
              } else {
                reviewProps.handleNext();
              }
            }}
          >
            {/* {tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase()
              ? "Update & Go to Summary"
              : "Go to Summary"} */}
              Next
          </button>
        </div>
      </div>
    </>
  );
};
export default ActivitiesCampApplicationReview;
