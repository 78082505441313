import "./CreateEditHomePageContent.scss";
import ContentSectionOne from "./ContentSectionOne/ContentSectionOne";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON, ROUTES } from "../../../../Utilities/Constants/Constants";
import ContentSectionTwo from "./ContentSectionTwo/ContentSectionTwo";
import { useTranslation } from "react-i18next";
import BackArrow from "../../../../../Assets/schools/back_button.svg";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
const CreateEditHomePageContent = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const location = useLocation();
  const navigate = useNavigate();
  const [ activeStep, setActiveStep ] = useState(`${COMMON_CONSTANTS.ONE}`)
  const { id } = useParams();

  useEffect(()=>{
      location.pathname === ROUTES.CREATE_HOME_PAGE_CONTENTS ? setActiveStep(`${COMMON_CONSTANTS.ONE}`) : setActiveStep(`${COMMON_CONSTANTS.TWO}`)
  },[])
  
  return(
    <div className="create-edit-content-wrapper">
         <div className="row">
                      <div className="col-md-12">
                          <div className='arrow-wrap'>
                              <img src={BackArrow} className='EditSchool-backarrow' onClick={() => { navigate(-1) }} />
                              <h5 className='name-title mt-1 ms-2'>{ id ?  t('Edit Homepage Content')  : t('Add Homepage Content')}</h5>
                          </div>
  
                      </div>
                      <div className="content-form-wrapper mx-auto">
                      <ContentSectionOne />
                    {/*   {activeStep=== `${COMMON_CONSTANTS.TWO}` && <ContentSectionTwo/>} */}
                      </div>
          </div>
   

    </div>
  )
     
};

export default CreateEditHomePageContent;
