import { useDispatch, useSelector } from "react-redux";
import "./Header.scss";
import { setAwsData, setLanguage, setNotificationData, setPermissionData } from "../../Services/Redux/Reducer/Reducer";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { COMMON_CONSTANTS, I18_TRANSLATION_COMMON, LANGUAGES, LAYOUTS, ROUTES } from "../../Utilities/Constants/Constants";
import Avatar from "@mui/material/Avatar";
import img from "../../../Assets/Login/loginBanner.png";
import React from "react";
import { GetSessionStorageData, SetSessionStorageData, clearSessionStorage } from "../../Services/Helpers/Helper";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import LanguageIcon from "@mui/icons-material/Language";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Header = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { collapseSidebar } = useProSidebar();
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
  };
  const [showRedDot, setShowRedDot] = useState(false);
  const dispatch = useDispatch();
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  let id: any = GetSessionStorageData(COMMON_CONSTANTS.ID);
  let socket: any = new WebSocket(process.env.REACT_APP_NOTIFICATION_SOCKET_URL + id + '/');
  // const socket: any = new WebSocket(`wss://learnandplay.way2smile.ae:8001/ws/notification/${GetSessionStorageData(COMMON_CONSTANTS.ID)}/`);
  // const socket: any = new WebSocket(`wss://phygital.way2smile.ae:8001/ws/notification/${GetSessionStorageData(COMMON_CONSTANTS.ID)}/`);

  /* Binding language from session storage */
  useEffect(() => {
    // getAwsCredentials();
    if (GetSessionStorageData(LANGUAGES.LANGUAGE)) {
      onChangeLanguage(GetSessionStorageData(LANGUAGES.LANGUAGE));
    };

    socket.onopen = (event: any) => {
    };

    /* Listen to events for socket server */
    socket.onmessage = (event: any) => {
      if (event.data) {
        dispatch(
          setNotificationData(
            JSON.parse(event.data)
          ));
      }
      setShowRedDot(true);
    };
  }, []);

  const getAwsCredentials = () => {
    axiosInstance.get(API_END_POINTS.AWS_CREDENTIALS_LIST).then((response: any) => {
      let payload = {
        accessKey: "",
        secretKey: ""
      }
      dispatch(
        setAwsData(
          response.data.result.data
        ))
    })
  }


  const [adminData, setAdminData] = useState({
    firstname: "",
    email: "",
    lastname: "",
    phone_number: "",
    image: "",
  });

  const userData = useSelector((state: any) => {
    return state.profileReducer.profile;
  });


  /* Setting data whenever changes in admin data */
  useEffect(() => {
    setAdminData(userData);
  }, [userData]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  useEffect(() => {
    getAdminDetail();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogOut = () => {
    handleClose();
    clearSessionStorage();
    navigate(ROUTES.LOGIN);
  };

  // let currentTitle="";
  const [currentTitle, setCurrentTitle] = useState("");
  const defineRoutingName = () => {
    switch (props.currentPage) {
      case "dashboard":
        setCurrentTitle("Dashboard");
        break;

      case "manage-category":
        setCurrentTitle("Manage Categories");
        break;
      case "school-status-list":
        setCurrentTitle("Manage Services");
        break;
      case "manage-tags":
        setCurrentTitle("Manage Tags");
        break;

      case "activities-personal-status-list":
        setCurrentTitle("Manage  Onboarding");
        break;
      case "activities-campus-status-list":
        setCurrentTitle("Manage  Onboarding");
        break;
      case "tutors-status-list":
        setCurrentTitle("Manage  Onboarding");
        break;

      case "manage-schools":
        setCurrentTitle("Manage Schools");
        break;

      case "manage-universities":
        setCurrentTitle("Manage Universities");
        break;

      case "manage-courses":
        setCurrentTitle("Manage Courses");
        break;

      case "manage-recreational":
        setCurrentTitle("Manage Recreational");
        break;
      case "manage-tutors":
        setCurrentTitle("Manage Tutors");
        break;
      case "manage-themepark":
        setCurrentTitle("Manage Themeparks");
        break;
      case "profile":
        setCurrentTitle("Profile");
        break;
      case "manage-roles":
        setCurrentTitle("Manage Roles");
        break;
      case "manage-experts":
        setCurrentTitle("Manage Educational Experts");
        break;
      case "manage-payments":
        setCurrentTitle("Manage Payments");
        break;
      case "manage-discounts":
        setCurrentTitle("Manage Discounts");
        break;
      case "manage-contents":
        setCurrentTitle("Manage Contents");
        break;
      case "manage-chat":
        setCurrentTitle("Manage Chats");
        break;
      case "user-chat-request":
        setCurrentTitle("Manage Chats");
        break;
      case "manage-users":
        setCurrentTitle("Manage Users");
        break;
    }
  };

  useEffect(() => {
    defineRoutingName();
  }, [props]);


  /* Admin getail get API call */
  const getAdminDetail = () => {
    const roleId = GetSessionStorageData(COMMON_CONSTANTS.ID);
    axiosInstance.get(API_END_POINTS.ADMIN_DETAIL + `?id=${roleId}`).then((res) => {
      setAdminData(res.data.result.data[0]);
    });
  };

  /* Handling Language change */
  const onChangeLanguage = (languageToChange: any) => {
    dispatch(
      setLanguage(languageToChange === "English" ? "English" : "Arabic")
    );
    SetSessionStorageData('language', languageToChange === "English" ? "English" : "Arabic");
    changeLanguage(languageToChange === "English" ? "en" : "ar");
  };

  const onClickNotification = () => {
    setShowRedDot(false);
    navigate(ROUTES.NOTIFICATIONS);
  }

  return (
    <>
      <div
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
        className="header_wrapper"
      >
        <div className="title-wrap-header">{t(`${currentTitle}`)}</div>
        <div className="header_container">
          <MenuIcon
            className="sidebar_menu_icon_toggle"
            onClick={() => collapseSidebar()}
          />
          <div className="not-icon-wrapper">
            {/* <div className="red-dot">199</div> */}
            <NotificationsNoneIcon className="header-notification-icon" onClick={onClickNotification} />
            {showRedDot && <FiberManualRecordIcon className="red-dot" />}
          </div>
          <div className="language_toggle_button_container">
            {/* {language === "English" ? "Arabic" : "English"} */}
            {/*  <div
              onClick={() =>
                onChangeLanguage(
                  language === LANGUAGES.ENGLISH
                    ? LANGUAGES.ARABIC
                    : LANGUAGES.ENGLISH
                )
              }
              className="language-selector"
            >
              <LanguageIcon className="language_icon_header" />
              <div>
                {language === LANGUAGES.ENGLISH
                  ? LANGUAGES.ARABIC
                  : LANGUAGES.ENGLISH}
              </div>
            </div> */}
            <div
              /* onClick={() =>
                onChangeLanguage(
                  language === LANGUAGES.ENGLISH
                    ? LANGUAGES.ARABIC
                    : LANGUAGES.ENGLISH
                )
              } */
              className="language-selector"
            >
              <LanguageIcon className="language_icon_header" />
              <div>
                {LANGUAGES.ENGLISH}
              </div>
            </div>
          </div>
          <div className="admin-avatar-wrap">
            <Avatar
              alt="Admin"
              className="avatar"
              src={adminData.image === "" ? img : adminData.image}
            />

            <div>
              <div
                className="admin-name"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {adminData.firstname}
                <ArrowDropDownIcon />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/profile");
                    setAnchorEl(null);
                  }}
                >
                  {t("Profile")}
                </MenuItem>
                <MenuItem onClick={onLogOut}>{t("Logout")}</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
