import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./SchoolList.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  ROUTES,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Checkbox,
  MenuItem,
  Pagination,
  Select,
  Switch,
  TablePagination,
  TextField,
} from "@mui/material";
import BulkUploadDialog from "../../../Shared/BulkUploadDialog/BulkUploadDialog";
import ReasonDialog from "../../../Shared/ReasonDialog/ReasonDialog";
import phygitalLogo from "../../../../Assets/Login/login_banner.svg";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import SearchIcon from "@mui/icons-material/Search";
import view from "../../../../Assets/global/view.svg";
import edit from "../../../../Assets/global/edit.svg";
import { checkValueNullUndefinedEmpty } from "../../../Services/Helpers/Helper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const SchoolList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const [schoolsList, setSchoolsList] = useState([]);
  const [totalPage, settotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState<any>(10);
  const [isBulkUploadDialogOpen, setisBulkUploadDialogOpen] = useState(false);
  const [isBulkUploadFeeDialogOpen, setisBulkUploadFeeDialogOpen] = useState(false);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const [currentDialog, setCurrentDialog] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const dialogTitle = DIALOG_CONSTANT.TITLE_BLOCK;
  const [hasProducts, setHasProducts] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [initialRender, setInitialRender] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [curriculamList, setCurriculamList] = useState([]);
  const [selectedCurriculam, setSelectedCurriculam]: any = useState([]);
  const [rankingList, setRankingList]: any = useState([]);
  const [ranking, setRanking]: any = useState([]);
  const [foundedYearList, setFoundedYearsList]: any = useState([]);
  const [foundedYear, setFoundedYear]: any = useState([]);
  const [ratingList, setRatingList]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [searchPayloadValue, setSearchPayloadValue]: any = useState("");

  useEffect(() => {
    getSchoolsList();
  }, [page, rowPerPage, filterPayload]);

  /* Updated on change in search value */
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      getFilterValues();
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        setPage(1);
        getSchoolsList();
        getFilterValues();
      }
    }
  }, [searchValue]);

  const getFilterValues = () => {
    axiosInstance
      .get(API_END_POINTS.SCHOOL_INITIAL_FILTERS)
      .then((response) => {
        setCurriculamList(response.data.result.data[0].curriculam);
        setFoundedYearsList(response.data.result.data[0].founded_year);
        setRankingList(response.data.result.data[0].ranking);
        setRatingList(response.data.result.data[0].rating);
      });
  };

  /* Handling page change */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /**School list payload */
  const schoolListPayload = () => {
    return {
      page: page,
      limit: parseInt(rowPerPage),
      search: searchValue.trim(),
      curriculam: [],
      filter: filterPayload,
      status: "approved",
    };
  };
  /**Schools list API */
  const getSchoolsList = () => {
    axiosInstance
      .post(API_END_POINTS.GET_SCHOOL_LIST, schoolListPayload())
      .then((response) => {
        setSchoolsList(response.data.result.data);
        schoolListCheck(response.data.result.data);
        settotalPage(response.data.result.total_page);
      });
  };
  const schoolListCheck = (schoolData: any) => {
    if (schoolData.length > 0) {
      setHasProducts(true);
    } else {
      setHasProducts(false);
    }
  };
  /**Open bulk Upload dialog */
  const openBulkUploadDialog = () => {
    setisBulkUploadDialogOpen(true);
  };

  /**Open bulk Upload dialog */
  const openFeeBulkUploadDialog = () => {
    setisBulkUploadFeeDialogOpen(true);
  };
  /**Close bulk upload dialog */
  const closeBulkUploadDialog = () => {
    setisBulkUploadDialogOpen(false);
    getSchoolsList();
  };

  /**Close bulk upload dialog */
  const closeFeeBulkUploadDialog = () => {
    setisBulkUploadFeeDialogOpen(false);
    getSchoolsList();
  };

  const blockUnblockSchool = (id: any, status: any) => {
    if (status === false) {
      setDialogOpenStatus(true);
    } else {
      axiosInstance
        .put(
          API_END_POINTS.BLOCK_TUTOR +
          "?id=" +
          id +
          "&block_type=" +
          VALIDATION_CONSTANT.SCHOOL + "&reason="
        )
        .then((response) => {
          toast.success(response.data.message, TOASTER_CONFIGS);
          getSchoolsList();
        });
    }
  };

  const onCloseDialogBox = () => {
    setDialogOpenStatus(false);
  };

  const onSubmitReason = (value: any) => {
    let payload = {
      reason: value.reason,
    };
    setDialogOpenStatus(false);
    axiosInstance
      .put(
        API_END_POINTS.BLOCK_TUTOR +
        "?id=" +
        currentUser +
        "&block_type=" +
        VALIDATION_CONSTANT.SCHOOL +
        "&reason=" + value.reason,
        payload
      )
      .then((response) => {
        toast.success(response.data.message, TOASTER_CONFIGS);
        getSchoolsList();
      });
  };

  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE);
    setRowPerPage(event.target.value);
  };
  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  /* Calling Search API */
  const onSearchData = () => {
    getSchoolsList();
    setPage(1);
  };

  /* Set selected curriculam */
  const onSelectCurriculam = (e: any) => {
    setSelectedCurriculam(e.target.value);
  };

  /* Set selected rating */
  const onChangeRating = (e: any) => {
    setRating(e.target.value);
  };

  /* Set selected ranking */
  const onChangeRanking = (e: any) => {
    setRanking(e.target.value);
  };

  const onChangeYear = (e: any) => {
    setFoundedYear(e.target.value);
  };

  useEffect(() => {
    if (filterApplied && selectedCurriculam.length === 0) {
      onApplyFilter();
    }
  }, [selectedCurriculam]);

  const onResetFilter = () => {
    setRating([]);
    setRanking([]);
    setFoundedYear([]);
    setSelectedCurriculam([]);
  };

  const onApplyFilter = () => {
    setFilterPayload({
      ranking: ranking,
      rating: rating,
      curriculam: selectedCurriculam,
      founded_year: foundedYear
    });
  };

  return (
    <>
      <div
        className="school_wrapper"
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
      >
        <ReasonDialog
          modalState={dialogOpenStatus}
          dialogStatus={onCloseDialogBox}
          submitReason={onSubmitReason}
          title={dialogTitle}
        />
        <div className="search_add_wrapper">
          <div className="search-filter-wrapper">
            <div
              className={
                language === LANGUAGES.ENGLISH
                  ? "custom__search"
                  : "custom__search-rtl"
              }
            >
              <TextField
                id="outlined-basic"
                placeholder={t("Search") || ""}
                variant="outlined"
                value={searchValue}
                onChange={onUpdateSearch}
                onKeyUp={(event: any) => {
                  if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                    onSearchData();
                  }
                }}
              />
              <SearchIcon className="icon" onClick={onSearchData} />
            </div>
            <div
              className="filter-wrap"
              onClick={() => {
                setFilterApplied(!filterApplied);
              }}
            >
              {!filterApplied && <FilterAltIcon />}
              {filterApplied && <FilterAltOffIcon />}
            </div>
          </div>

          <div className="upload_buttons_wrapper">
            <div className="bulk_upload_wrapper">
              <button
                onClick={openBulkUploadDialog}
                className="bulk_upload_button"
              >
                {t("Upload Schools data")}
              </button>
              <BulkUploadDialog
                onCloseDialog={closeBulkUploadDialog}
                isOpen={isBulkUploadDialogOpen}
                uploadType={"school"}
                uploadFunctionType={"schoolData"}
              />
            </div>
            {/* <div className="bulk_upload_wrapper">
              <button
                onClick={openFeeBulkUploadDialog}
                className="bulk_upload_button"
              >
                {t("Upload Fee structure")}
              </button>
              <BulkUploadDialog
                onCloseDialog={closeFeeBulkUploadDialog}
                isOpen={isBulkUploadFeeDialogOpen}
                uploadType={"school"}
                uploadFunctionType={"feeData"}
              />
            </div> */}
            <button
              onClick={() => navigate(ROUTES.ADD_SCHOOLS)}
              className="bulk_upload_button"
            >
              {t("Add School")}
            </button>
          </div>
        </div>

        {filterApplied && (
          <div className="filter-wrap-container">
            <div className="filter-items-wrap">
              <div className="filter-item">
                <label className="filter-label">{t("Curriculam")}</label>
                <Select
                  multiple
                  value={selectedCurriculam}
                  className="filter-select"
                  onChange={onSelectCurriculam}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {curriculamList.map((curriculam: any, index: any) => {
                    return (
                      <MenuItem value={curriculam}>
                        <Checkbox
                          checked={selectedCurriculam.includes(`${curriculam}`)}
                        />
                        {curriculam}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Rating")}</label>
                <Select
                  multiple
                  value={rating}
                  className="filter-select"
                  onChange={onChangeRating}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {ratingList.map((ratings: any, index: any) => {
                    return (
                      <MenuItem value={ratings}>
                        <Checkbox
                          checked={rating.includes(`${ratings}`)}
                        />
                        {ratings}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Ranking")}</label>
                <Select
                  multiple
                  value={ranking}
                  className="filter-select"
                  onChange={onChangeRanking}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {rankingList.map((rankings: any, index: any) => {
                    return (
                      <MenuItem value={rankings}>
                        <Checkbox
                          checked={ranking.includes(`${rankings}`)}
                        />
                        {rankings}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Founded Year")}</label>
                <Select
                  multiple
                  value={foundedYear}
                  className="filter-select"
                  onChange={onChangeYear}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {foundedYearList.map((year: any, index: any) => {
                    return (
                      <MenuItem value={year}>
                        <Checkbox
                          checked={foundedYear.includes(`${year}`)}
                        />
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-buttons-wrap">
                <button className="btn btn-primary" onClick={onResetFilter}>
                  {t("RESET")}
                </button>
                <button className="btn btn-secondary" onClick={onApplyFilter}>
                  {t("APPLY")}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="table_container">
          <TableContainer component={Paper} className="categoryTable">
            <Table className="table-wrap">
              <TableHead className="categoryTableHead">
                <TableRow className="categoryTableRow">
                  <TableCell className="s_no_row">{t("S.NO")}</TableCell>
                  <TableCell >
                    {t("School ID")}
                  </TableCell>
                  <TableCell className="school_name">
                    {t("School Name")}
                  </TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Location")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>
              {hasProducts && (
                <TableBody>
                  {schoolsList.map((school: any, i: any) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          {page - 1 === 0
                            ? i + 1
                            : (page - 1) * rowPerPage + (i + 1)}
                        </TableCell>
                        <TableCell>{school.id}</TableCell>
                        <TableCell className="cell-capitalize">
                          {school.school_name}
                        </TableCell>
                        <TableCell>{school.email}</TableCell>
                        <TableCell>{school?.authority_details[0] ? school?.authority_details[0].address : school?.address}</TableCell>
                        <TableCell>
                          <div className="action-wrap">
                            <Tooltip
                              title={t("View School")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  navigate(`/manage-schools/${school.id}`);
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                            <Tooltip
                              title={t("Edit School")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  navigate(`/manage-schools/edit/${school.id}`);
                                }}
                                className="view_edit_icons"
                                src={edit}
                                alt="Learn and Play"
                              />
                            </Tooltip>

                            {school?.is_block === true ? (
                              <div
                                className="reject_btn_wrapper"
                                onClick={() => {
                                  setCurrentUser(school.user);
                                  blockUnblockSchool(
                                    school.user,
                                    school?.is_block
                                  );
                                }}
                              >
                                <div className="accept_btn_label">
                                  {t("Unblock")}
                                </div>
                                <div className="lock_icon_wrapper">
                                  <HttpsIcon className="lock_icon" />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="accept_btn_wrapper"
                                onClick={() => {
                                  setCurrentUser(school.user);
                                  blockUnblockSchool(
                                    school.user,
                                    school?.is_block
                                  );
                                }}
                              >
                                <div className="unlock_icon_wrapper">
                                  <LockOpenTwoToneIcon className="unlock_icon" />
                                </div>
                                <div className="accept_btn_label">
                                  {t("Block")}
                                </div>
                              </div>
                            )}
                          </div>

                          {/* </Tooltip> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasProducts && <NoProducts></NoProducts>}
        </div>
      </div>
    </>
  );
};

export default SchoolList;
