import { Box, Tab, Tabs } from "@mui/material";
import "./ManageSessions.scss";
import { useState } from "react";
import {
  I18_TRANSLATION_COMMON,
  SERVICES_STATUS,
} from "../../Utilities/Constants/Constants";
import { useTranslation } from "react-i18next";
import SessionsList from "./SessionsList/SessionsLIst";

const ManageSessions = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [value, setValue] = useState(SERVICES_STATUS.APPROVED);

  /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="sessions-list-wrapper">
      <div className="filter_category_list_container">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
              <Tab
                value={SERVICES_STATUS.APPROVED}
                label={t(SERVICES_STATUS.APPROVED)}
              />
              <Tab
                value={SERVICES_STATUS.PENDING}
                label={t(SERVICES_STATUS.PENDING)}
              />
              <Tab
                value={SERVICES_STATUS.REJECTED}
                label={t(SERVICES_STATUS.REJECTED)}
              />
            </Tabs>
          </Box>
        </div>
        <SessionsList tabValue={value} rowPerPage={5} page={1}/>
      </div>
    </div>
  );
};

export default ManageSessions;
