import { Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import "./CreateDiscount.scss"
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, I18_TRANSLATION_COMMON, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../Utilities/Constants/Validations";
import { Field, Formik } from "formik";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";

const CreateDiscount = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [categoryType, setCategoryType] = useState<any>("");
  const [ formSubmitted, setFormSubmitted ] = useState(false);
  const [initialValue, setInitialValue] = useState({
    buy_slots: '',
    free_slots: '',
    usage_count: ''
  })
  const onClose = () => {
    return props.dialogStatus(false);
  };

  useEffect(() => {
    if (props.data?.buy_slots) {
      setInitialValue({
        buy_slots: props.data.buy_slots,
        free_slots: props.data.free_slots,
        usage_count: props.data.usage_count
      });
      setCategoryType(props.data.discount_type);
    }
  }, [props])

  const discountValidationSchema = yup.object({
    buy_slots: yup.number().min(1).required("Please enter count of buy sessions").typeError("Please enter valid number"),
    free_slots: yup.number().min(1).required("Please enter count of free slots")
      .test("superior", ERROR_MESSAGES.FREE_SLOTS, function (value: any) {
        const ref: any = yup.ref(VALIDATION_CONSTANT.BUY_SLOTS);
        return parseFloat(value) < parseFloat(this.resolve(ref));
      }).typeError("Please enter valid number"),
    usage_count: yup.number().min(1).required("Please enter count of usage count per user").typeError("Please enter valid number")
  });

  const onCreateDiscount = (payload: any) => {
    if(nullUndefinedEmptyCheck(categoryType)){
      payload.discount_type = categoryType;
      if (props.data?.buy_slots) {
        axiosInstance.put(API_END_POINTS.CREATE_EDIT_DISCOUNT + "?id=" + props.data?.id, payload).then((response: any) => {
          apiResponseAction(response);
          setCategoryType("");
          setFormSubmitted(false);
        });
      } else {
        axiosInstance.post(API_END_POINTS.CREATE_EDIT_DISCOUNT, payload).then((response: any) => {
          apiResponseAction(response);
          setCategoryType("");
          setFormSubmitted(false);
        });
      }
    }
  };

  const apiResponseAction = (response: any) => {
    if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
      toast.success(response.data.message, TOASTER_CONFIGS);
      onClose();
    };
  }
  return (
    <div className="create-discount-wrapper">
      <Dialog
        className="create-expert-dialog"
        open={props.modalState}
        sx={{ "& .MuiDialog-paper": "cus-dialog" }}
      >
        <DialogTitle className="title-wrap">
          {t(props.data?.buy_slots ? "Update Discount" : "Create Discount")}
          <div className="close-wrap">
            <CloseIcon className="close-icon" onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            enableReinitialize
            validationSchema={discountValidationSchema}
            initialValues={initialValue}
            onSubmit={(values) => {
              onCreateDiscount(values);
            }}
          >
            {(formProps) => {
              return (
                <div className="discount-form-wrapper">

                  <div className="input-wrap">
                        {/* <FormControl> */}
                              {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                              <p id="demo-row-radio-buttons-group-label" className="input-label">Category</p>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={categoryType}
                                onChange={(e:any)=>{
                                  setCategoryType(e.target.value);
                                }}
                              >
                                <FormControlLabel className="discount-form-label" value={1} control={<Radio className="radio-btn" />} label="Tutor" />
                                <FormControlLabel className="discount-form-label" value={2} control={<Radio className="radio-btn"/>} label="Recreational" />
                              </RadioGroup>
                              <div className="errorMessage">
                                {formSubmitted && categoryType==="" &&(
                                    <p>{t(`Please select discount category`)}</p>
                                  )}
                             </div>

                         {/* </FormControl> */}
                  </div>
              
    
                  <div className="input-wrap">
                    <p className="input-label">Count of buying slots</p>
                    <Field
                      name="buy_slots"
                      className="firstname input"
                      placeholder="Enter Count of buying slots"
                    //   onKeyPress={validateEnglish}
                    />
                    <div className="errorMessage">
                      {formProps.touched.buy_slots &&
                        formProps.errors.buy_slots && (
                          <p>{t(`${formProps.errors.buy_slots}`)}</p>
                        )}
                    </div>
                  </div>

                  <div className="input-wrap">
                    <p className="input-label">Count of free slots</p>
                    <Field
                      name="free_slots"
                      className="firstname input"
                      placeholder="Enter Count of free slots"
                    //   onKeyPress={validateEnglish}
                    />
                    <div className="errorMessage">
                      {formProps.touched.free_slots &&
                        formProps.errors.free_slots && (
                          <p>{t(`${formProps.errors.free_slots}`)}</p>
                        )}
                    </div>
                  </div>

                  <div className="input-wrap">
                    <p className="input-label">Maximum usage per user</p>
                    <Field
                      name="usage_count"
                      className="firstname input"
                      placeholder="Enter Maximum usage per user"
                    />
                    <div className="errorMessage">
                      {formProps.touched.usage_count &&
                        formProps.errors.usage_count && (
                          <p>{t(`${formProps.errors.usage_count}`)}</p>
                        )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="button-wrap-profile">
                      <button
                        className="btn  cancel-btn  mt-2"
                        onClick={() => {
                          onClose();
                        }}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="btn save-btn  mt-2"
                        type="submit"
                        onClick={() => {
                          setFormSubmitted(true);
                          formProps.handleSubmit();
                        }}
                      >
                        {t(props.data?.buy_slots ? "Update" : "Save")}
                      </button>
                    </div>
                  </div>
                </div>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default CreateDiscount;
