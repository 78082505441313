import { useEffect, useState } from "react";
import "./Sidebar.scss";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON, NUMBER_CONSTANTS, SIDEBAR } from "../../Utilities/Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import img from "../../../Assets/Login/phygital_Logo.svg";
import mobile_logo from "../../../Assets/sidebar/main_logo_mobile.svg";
import menu from "../../../Assets/sidebar/menu_icon_3.svg";
import menu_mobile from "../../../Assets/sidebar/menu_mobile.svg";
import { setPermissionData, setTabData } from "../../Services/Redux/Reducer/Reducer";
import { GetSessionStorageData } from "../../Services/Helpers/Helper";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";

const SideBar = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [isMobile, setMobileScreen] = useState(API_RESPONSE.FALSE);
  const [isSuperAdmin, setIsSuperAdmin]: any = useState('');
  const [roleId, setRoleId] = useState<any>("");
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  })


  useEffect(() => {
  }, [isSuperAdmin])

  useEffect(() => {
    // getPermissionsList();
    setRoleId(GetSessionStorageData(COMMON_CONSTANTS.ROLE_ID));
    setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
  }, [])
  useEffect(() => {
    window.screen.width <= 960 ? collapseSidebar(API_RESPONSE.TRUE) : collapseSidebar(API_RESPONSE.FALSE);
  }, [collapseSidebar]);

  /*   const getPermissionsList = () => {
      axiosInstance
        .get(API_END_POINTS.ADMIN_PERMISSIONS)
        .then((response: any) => {
          if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
            // setPermissionData(response.data.result.data);
            dispatch(setPermissionData(response.data.result.data));
          }
        });
    }; */

  const detectWindowSize = () => {
    if (window.innerWidth <= 960) {
      collapseSidebar(API_RESPONSE.TRUE);
      setMobileScreen(API_RESPONSE.TRUE);
    } else {
      collapseSidebar(API_RESPONSE.FALSE);
      setMobileScreen(API_RESPONSE.FALSE);
    }
    if (window.innerWidth <= 576) {
      setMobileScreen(API_RESPONSE.TRUE);
    } else {
      setMobileScreen(API_RESPONSE.FALSE);
    }
  };

  window.onresize = detectWindowSize;
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const location = useLocation();

  const isPathActive = (path: string) => {
    return location.pathname.startsWith(path);
  };


  const subMenuMapping = (subMenuElements: any, subIndex: any) => {
    return (
      <>
        <MenuItem
          key={subIndex}
          className={
            isPathActive(subMenuElements.PATH)
              ? "menu-item menu_active"
              : "menu-item menu_inactive"
          }
          active={isPathActive(subMenuElements.PATH)}
          onClick={() => {
            dispatch(setTabData(""));
            navigate(subMenuElements.PATH);
          }}
        >
          {t(`${subMenuElements.NAME}`)}{" "}
        </MenuItem>
      </>
    );
  };

  const [submenuOpenState, setSubmenuOpenState] = useState<any>(false);
  const sidebarMapping = (sidebarElements: any, index: any) => {
    return (
      <>
        {sidebarElements.SUB_MENU.length === 0 ? (
          <MenuItem
            key={index}
            className={
              isPathActive(sidebarElements.PATH)
                ? "menu-item menu_active"
                : "menu-item menu_inactive"
            }
            active={isPathActive(sidebarElements.PATH)}
            onClick={() => {
              dispatch(setTabData(""));
              navigate(sidebarElements.PATH);
            }}
            icon={
              <img
                src={
                  isPathActive(sidebarElements.PATH)
                    ? sidebarElements.ACTIVE_ICON
                    : sidebarElements.ICON
                }
                className="menu-icon"
                alt={sidebarElements.NAME}
              />
            }
          >
            {t(`${sidebarElements.NAME}`)}{" "}
          </MenuItem>
        ) : (
          <SubMenu
            key={index}
            className="sub_menu_wrapper"
            label={t(`${sidebarElements.NAME}`)}
            icon={
              <img
                src={sidebarElements.ICON}
                alt={sidebarElements.NAME}
              />
            }
            /* onOpenChange={(open:any)=>{
              if (open) {
                setSubmenuOpenState(index); // Set the index of the open submenu
              } else {
                setSubmenuOpenState(null); // Reset when submenu is closed
              }
            }} */
            /*  open={
               submenuOpenState 
               ? submenuOpenState === index 
               : sidebarElements.SUB_MENU.filter((subMenu: any) => {
                 return isPathActive(subMenu.PATH);
               }).length != 0
               
               // sidebarElements.SUB_MENU.filter((subMenu: any) => {
               //   return isPathActive(subMenu.PATH);
               // }).length != 0
               // true
             } */
            defaultOpen={
              sidebarElements.SUB_MENU.filter((subMenu: any) => {
                return isPathActive(subMenu.PATH);
              }).length != 0
            }
          >
            {sidebarElements.SUB_MENU.map(
              (subMenuElements: any, subIndex: any) => {
                if (isSuperAdmin === `${API_RESPONSE.TRUE}`) {
                  return (
                    subMenuMapping(subMenuElements, subIndex)
                  )
                } else {
                  if (permissions[index]?.data[subIndex]?.checked) {
                    return (
                      subMenuMapping(subMenuElements, subIndex)
                    )
                  }
                }
              }
            )}
          </SubMenu>
        )}
      </>
    )
  }

  return (
    <>
      <Sidebar
        className="sidbar-wrap"
        collapsedWidth={isMobile ? "0px" : "80px"}
        rtl={language === "English" ? false : true}
      >
        <div
          className={
            collapsed
              ? "main_logo_menu_wrapper_mobile"
              : "main_logo_menu_wrapper"
          }
        >
          {collapsed ? (
            <img
              className=""
              src={mobile_logo}
              alt="Learn and Play"
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          ) : (
            <img
              className="sidebar_logo_icon"
              src={img}
              alt="Learn and Play"
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          )}
          {collapsed ? (
            <img
              src={menu_mobile}
              className={
                language === "English" ? "menu_toggle_ltr" : "menu_toggle_rtl"
              }
              onClick={() => collapseSidebar()}
              alt="Learn and Play"
            />
          ) : (
            <img
              src={menu}
              className={
                language === "English" ? "menu_toggle_ltr" : "menu_toggle_rtl"
              }
              onClick={() => collapseSidebar()}
              alt="Learn and Play"
            />
          )}
        </div>
        <Menu>

          {roleId !== `${NUMBER_CONSTANTS.TWELVE}` ? SIDEBAR.map((sidebarElements: any, index: any) => {
            if (isSuperAdmin === `${API_RESPONSE.TRUE}`) {
              return sidebarMapping(sidebarElements, index);
            } else {
              /* if(roleId === `${NUMBER_CONSTANTS.TWELVE}`){
                // if (permissions[SIDEBAR.length-1]?.checked===API_RESPONSE.TRUE) 
                    //  return sidebarMapping(SIDEBAR[SIDEBAR.length-1],SIDEBAR.length-1);
              }else{ */
              if (permissions[index]?.checked === API_RESPONSE.TRUE) {
                return sidebarMapping(sidebarElements, index);
              }
              // }
            }
          })
            :
            sidebarMapping(SIDEBAR[SIDEBAR.length - 1], SIDEBAR.length - 1)
          }
        </Menu>
      </Sidebar>
    </>
  );
};

export default SideBar;
