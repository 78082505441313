import "./TutorApplicationReview.scss";
import emiratesID from "../../../../../../Assets/TutorVerification/emigrantID.svg";
import immigrantID from "../../../../../../Assets/TutorVerification/immigrantID.svg";
import certificate from "../../../../../../Assets/TutorVerification/certificate.svg";
import introVideo from "../../../../../../Assets/TutorVerification/introVideo.svg";
import video from "../../../../../../Assets/TutorVerification/video.svg";
import fileIcon from "../../../../../../Assets/TutorVerification/pdfIcon.svg";
import FileViewerDialog from "../../../../../Shared/FileViewerDialog/FileViewerDialog";
import { useEffect, useState } from "react";
import VideoPlayerDialog from "../../../../../Shared/VideoPlayerDialog/VideoPlayerDialog";
import { API_RESPONSE, SERVICES_STATUS, UPLOAD_TICKET_CONSTANT } from "../../../../../Utilities/Constants/Constants";
import { checkValueNullUndefinedEmpty } from "../../../../../Services/Helpers/Helper";
import pdfIcon from "../../../../../../Assets/global/pdf_icon.svg";

const TutorApplicationReview = (reviewProps: any) => {
  const [fileViewerOpen, setFileViewerOpen] = useState(API_RESPONSE.FALSE);
  const [viewerFile, setViewerFile] = useState("");
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(API_RESPONSE.FALSE);
  const [videoFile, setvideoFile] = useState("");
  const [tutorDetails, setTutorDetails] = useState<any>({});
  const [comments, setComments] = useState<any>("");
  const [commentsTouched, setCommentsTouched] = useState<any>(API_RESPONSE.FALSE);

  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(API_RESPONSE.FALSE);
    setViewerFile("");
  };
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(API_RESPONSE.TRUE);
  };
  const onVideoPlayerDialogClose = () => {
    setVideoPlayerOpen(API_RESPONSE.FALSE);
    setvideoFile("");
  };
  const onVideoPlayerDialogOpen = (url: any) => {
    setvideoFile(url);
    setVideoPlayerOpen(API_RESPONSE.TRUE);
  };

  useEffect(() => {
    setTutorDetails(reviewProps.tutorDetail);
    setComments(reviewProps.tutorDetail.professional_review_comment);
  }, [reviewProps.tutorDetail, reviewProps?.tutorDetail?.professional_review_comment]);

  /**Check for pdf file */
  const checkPdf = (viewerFile: any) => {
    if (checkValueNullUndefinedEmpty(viewerFile)) {
      return viewerFile?.split(".").slice(-1)[0] === UPLOAD_TICKET_CONSTANT.PDF;
    }
    return API_RESPONSE.FALSE;
  };

  return (
    <>
      <div className="tutor_application_review_wrapper">
        <FileViewerDialog
          open={fileViewerOpen}
          handleClose={onFilerViewerDialogClose}
          viewerFile={viewerFile}
        ></FileViewerDialog>
        <VideoPlayerDialog
          open={videoPlayerOpen}
          handleClose={onVideoPlayerDialogClose}
          viewerFile={videoFile}
        ></VideoPlayerDialog>
        <div className=" tutor_info_label_wrapper">
          <div className=" id_number_wrapper">
            <div className=" tutor_info_label">ID Number</div>
            <div className=" tutor_info">{tutorDetails.emirates_id}</div>
          </div>
          {tutorDetails.immigrant_id && <div className=" id_number_wrapper">
            <div className=" tutor_info_label">ID Number</div>
            <div className=" tutor_info">{tutorDetails.immigrant_id}</div>
          </div>}
        </div>
        <div className="tutor_files_card_wrap">
          <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>
              <div className="tutor_info_icon_label">ID Front</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_front);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.emirates_front)
                    ? fileIcon
                    : tutorDetails.emirates_front
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_front);
                }}
              />
            </div>
          </div>
          <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">ID Back</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_back);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.emirates_back)
                    ? fileIcon
                    : tutorDetails.emirates_back
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.emirates_back);
                }}
              />
            </div>
          </div>
        </div>
        <div className="tutor_files_card_wrap">
          {tutorDetails.immigrant_front && <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">Passport Front</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_front);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.immigrant_front)
                    ? fileIcon
                    : tutorDetails.immigrant_front
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_front);
                }}
              />
            </div>
          </div>}
          {tutorDetails.immigrant_back && <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">Passport Back</div>
              <div
                className="view_file_button"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_back);
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  checkPdf(tutorDetails.immigrant_back)
                    ? fileIcon
                    : tutorDetails.immigrant_back
                }
                alt="Learn and Play"
                onClick={() => {
                  onFilerViewerDialogOpen(tutorDetails.immigrant_back);
                }}
              />
            </div>
          </div>}
        </div>
        <div className="tutor_files_card_wrap">

          <div className="tutor_files_wrapper">
            <div className="tutor_files_actions_container">
              <div className="tutor_file_icon_Wrapper">
                <img
                  className="tutor_files_icon"
                  src={emiratesID}
                  alt="Learn and Play"
                />
              </div>

              <div className="tutor_info_icon_label">CV</div>
              <div
                className="view_file_button"
                onClick={() => {
                  // onFilerViewerDialogOpen(tutorDetails.tutor_cv);/
                  window.open(tutorDetails.tutor_cv, "_blank");
                }}
              >
                View File
              </div>
            </div>
            <div className="tutor_files_icon_wrapper">
              <img
                className="tutor_file"
                src={
                  fileIcon

                }
                alt="Learn and Play"
                onClick={() => {
                  // onFilerViewerDialogOpen(tutorDetails.tutor_cv);
                  window.open(tutorDetails.tutor_cv, "_blank");
                }}
              />
            </div>
          </div>
        </div>
        {tutorDetails.certificates?.length !== 0 && (
          <div className="certificates_wrapper">
            <div className="tutor_info_icon_label">Certificates</div>
            <div className="col-md-12 row">
              {tutorDetails.certificates?.map(
                (tutorDetailsElement: any, index: any) => {
                  return (
                    <>
                      <img
                        key={index + 1}
                        onClick={() => {
                          onFilerViewerDialogOpen(tutorDetailsElement);
                        }}
                        className="tutor_files_icon_preview"
                        src={
                          checkPdf(tutorDetailsElement)
                            ? fileIcon
                            : tutorDetailsElement
                        }
                        alt="Learn and Play"
                      />
                    </>
                  );
                }
              )}
            </div>
          </div>
        )}
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-12">
            <div className="tutor_info_label_comment">Comments</div>
            <textarea
              value={comments}
              onChange={(event: any) => {
                setComments(event.target.value);
              }}
              onBlur={() => {
                setCommentsTouched(API_RESPONSE.TRUE);
              }}
              placeholder="Please enter your comments"
              disabled={
                tutorDetails.status !== SERVICES_STATUS.PENDING.toLowerCase()
              }
            ></textarea>
            {(comments?.length === 0 || comments == null) &&
              commentsTouched && (
                <div className="error_message">
                  Please enter some comments about the Tutor's personal and
                  professional details
                </div>
              )}
          </div>
        </div>
        <div className="stepper_actions_wrapper">
          <button className="back_button" onClick={reviewProps.handleBack}>
            Back
          </button>
          <button
            className="next_button"
            onClick={() => {
              if (tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase()) {
                /* comments?.length === 0 || comments == null
                  ? setCommentsTouched(API_RESPONSE.TRUE)
                  : */ reviewProps.updateTutorverification({
                    professional_review: API_RESPONSE.TRUE,
                    professional_review_comment: comments,
                  });
              } else {
                reviewProps.handleNext();
              }
            }}
          >
            {/* {tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase()
              ? "Update & Go to Verify subjects"
              : "Go to Verify subjects"} */}
              Next
          </button>
        </div>
      </div>
    </>
  );
};
export default TutorApplicationReview;
