import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import {
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  UPLOAD_TICKET_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { Card, Chip } from "@mui/material";
import "./ViewContent.scss";
import { useDispatch, useSelector } from "react-redux";
import { nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";

const ViewContent = () => {
  const { id } = useParams();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [data, setData] = useState<any>([]);
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [type, setType] = useState(null);
  const dispatch = useDispatch();
  const tabsData: any = useSelector((state: any) => {
    return state.tabsReducer.tab;
  });
  const location = useLocation();
  const [viewerFile, setViewerFile] = useState("");
  const [fileViewerOpen, setFileViewerOpen] = useState(false);

  /* To get id from params */
  useEffect(() => {

    if (location.pathname.split("/")[1] === UPLOAD_TICKET_CONSTANT.MANAGE_TUTORS) {
      setType(COMMON_CONSTANTS.ONE)
    } else if (location.pathname.split("/")[1] === UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL) {
      setType(COMMON_CONSTANTS.TWO)
    }

  }, []);
  useEffect(() => {
    if (nullUndefinedEmptyCheck(id) && nullUndefinedEmptyCheck(type)) {
      getContent(id);
    }
  }, [type]);
  const getContent = (id: any) => {
    axiosInstance
      .get(
        API_END_POINTS.PROMOTIONAL_CONTENT +
        "?id=" +
        id +
        "&service_provider_type=" +
        type
      )
      .then((resp) => {
        if (resp.data.status === true) {
          let datas = resp.data.result.data;
          //   setContent(
          //     type === COMMON_CONSTANTS.ONE
          //       ? resp.data.result.data?.category_name
          //       : resp.data.result.data?.course_category_name
          //   );
          setData(datas);
        }
      });
  };

  const onNavigate = () => {
    if (type === COMMON_CONSTANTS.ONE) {
      navigate(ROUTES.TUTOR_PROMOTIONAL_CONTENT_LIST)
    } else if (type === COMMON_CONSTANTS.TWO) {
      navigate(ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_LIST)
    }

  };

  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  return (
    <div className="Viewtag">
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              onClick={onNavigate}
              alt="Learn and Play"
            />
            <h5 className="name-title mt-1 ms-2">{t("promotionalContentDetails")}</h5>
          </div>
        </div>
      </div>

      <Card className="Viewtag-card">
        <div className="row">
          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-4">
                {t("Description")}:
              </label>
              <input
                className="Viewtag-input col-md-8"
                value={data?.description}
                disabled
              />
            </div>
          </div>
          

          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-4">
                {type === COMMON_CONSTANTS.ONE ? t("Tutor Id") : type === COMMON_CONSTANTS.TWO ? t("Personal Trainer Id") : ""} :
              </label>
              <input
                className="Viewtag-input col-md-6"
                value={`${data?.provider_id}`}
                disabled
              />
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-4">
                {t("Image")}:
              </label>
              {/* <input
                className="Viewtag-input col-md-6"
                value={`${data?.image}`}
                disabled
              /> */}
              <img
                className="table-image col-md-8"
                src={data?.image}
                onClick={() => {
                  onFilerViewerDialogOpen(data?.image);
                }}
              />
            </div>
          </div>

          {/* <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-4">
                {t("Web Link")}:
              </label>
              <a href={data?.web_link} target="_blank" className="Viewtag-link col-md-8" >{data?.web_link}</a>
            </div>
          </div>

          <div className="col-md-6 mt-3">
            <div className="row">
              <label className="Viewtag-label col-md-4">
                {t("Mobile Link")}:
              </label>
              <input
                className="Viewtag-input col-md-8"
                value={`${data?.mobile_link} AED`}
                disabled
              />

            </div>
          </div> */}


        </div>
      </Card>
    </div>
  );
};

export default ViewContent;
