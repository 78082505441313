import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Switch,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import "./DiscountsList.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  I18_TRANSLATION_COMMON,
  API_RESPONSE,
  DIALOG_CONSTANT,
  TOASTER_CONFIGS,
  COMMON_CONSTANTS,
  SERVICES_STATUS,
} from "../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { checkEmpty } from "../../../Services/Helpers/Helper";
import CreateDiscount from "../CreateEditDiscount/CreateDiscount";
import edit from "../../../../Assets/global/edit.svg";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import CreateEditCampDiscount from "../CreateEditCampDiscount/CreateEditCampDiscount";

const DiscountList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [discountsList, setDiscountsList] = useState([]);
  const [hasProducts, setHasProducts] = useState(API_RESPONSE.TRUE);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const [campDialogStatus, setCampDialogStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalCount] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [discountCreateMode, setDiscountCreateMode] = useState("create");
  const [discountData, setDiscountData] = useState<any>({});
  const [modalStatus, setmodalStatus] = useState(false);
  const [userType, setUserType] = useState(1);
  const navigate = useNavigate();
  const [dialogContent, setDialogContent] = useState(
    DIALOG_CONSTANT.ACTIVATE_DISCOUNT
  );
  const [currentData, setCurrentData] = useState<any>({});
  // const dialogContent = DIALOG_CONSTANT.DELETE_CONTENT;
  useEffect(() => {
    getDiscountsList();
  }, [page,rowPerPage,userType]);

  const getDiscountsList = () => {
    if(userType === 3){
      axiosInstance
      .get(
        API_END_POINTS.CAMP_DISCOUNT_LIST + "?page=" + page + "&limit=" + rowPerPage /* + "&discount_type=" + userType */ 
      )
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setDiscountsList(response.data.result.data);
          setHasProducts(checkEmpty(response.data.result.data));
          tutorLengthCheck(response.data.result.data);
          setTotalCount(response.data.result.total_page);
        }
      });
    }else{
      axiosInstance
      .get(
        API_END_POINTS.DISCOUNTS_LIST + "?page=" + page + "&limit=" + rowPerPage + "&discount_type=" + userType 
      )
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setDiscountsList(response.data.result.data);
          setHasProducts(checkEmpty(response.data.result.data));
          tutorLengthCheck(response.data.result.data);
          setTotalCount(response.data.result.total_page);
        }
      });
    }    
  };

  const tutorLengthCheck = (data: any) => {
    data.length > 0 ? setHasProducts(true) : setHasProducts(false);
  };

  /**Handle row per page change */
  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE)
    setRowPerPage(parseInt(event.target.value));
  };

  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Handling dialog close */
  const onCloseDialog = () => {
    setDiscountData({});
    setDialogOpenStatus(false);
    getDiscountsList();
  };
  /* Handling dialog close */
  const onCloseCampDialog = () => {
    setDiscountData({});
    setCampDialogStatus(false);
    getDiscountsList();
  };

  const onCheckSwitch = (event: any, data: any) => {
    setCurrentData(data);
    if (data.is_active) {
      setDialogContent(DIALOG_CONSTANT.DEACTIVATE_DISCOUNT);
      
    }else{
        setDialogContent(DIALOG_CONSTANT.ACTIVATE_DISCOUNT);
    };
    setmodalStatus(API_RESPONSE.TRUE);
  };

  const onActivateDeactivate = (status: any) => {
    if (status === API_RESPONSE.TRUE) {
      axiosInstance
        .delete((userType=== 3 ? API_END_POINTS.MANAGE_CAMPS_DISCOUNT : API_END_POINTS.CREATE_EDIT_DISCOUNT) + "?id=" + currentData.id)
        .then((response: any) => {
          if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
            toast.success(response.data.message, TOASTER_CONFIGS);
            getDiscountsList();
          }
        });
    }
    setmodalStatus(API_RESPONSE.FALSE);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(COMMON_CONSTANTS.ONE)
    setUserType(newValue);
    // setSearchValue("");
    // setPage(1);
    // setCurrentSort("");
  };


  return (
    <div className="experts-list-wrapper discount-wrap">
      <div className="table-wrapper">
      
        
        <div className="discount-submit-btn-wrapper">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={userType}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
               <Tab
                value={COMMON_CONSTANTS.ONE}
                label={t(SERVICES_STATUS.TUTOR)}
              />
               <Tab
                value={COMMON_CONSTANTS.TWO}
                label={t(SERVICES_STATUS.RECREATIONAL)}
              />           
               {/* <Tab
                value={COMMON_CONSTANTS.THREE}
                label={t(SERVICES_STATUS.ACTIVITY_CAMP)}
              />    */}        
              
            </Tabs>
          </Box>
        </div>

          <button
            type="submit"
            className="btn btn-secondary"
            onClick={() => {
              setDiscountCreateMode("create");
              userType===3 ? setCampDialogStatus(true) : setDialogOpenStatus(true);
            }}
          >
            Create Discount
          </button>
        </div>
        <CreateDiscount
          modalState={dialogOpenStatus}
          mode={discountCreateMode}
          data={discountData}
          dialogStatus={onCloseDialog}
        />

        <CreateEditCampDiscount
        modalState={campDialogStatus}
        mode={discountCreateMode}
        data={discountData}
        dialogStatus={onCloseCampDialog}
        />

        <ConfirmationDialog
          content={dialogContent}
          modalState={modalStatus}
          dialogStatus={onActivateDeactivate}
        />

        {userType !== 3 && <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell>{t("Buy Slot")}</TableCell>
                <TableCell>{t("Free Slot")}</TableCell>
                <TableCell>{t("Maximum usage per user")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discountsList.map((expertData: any, index: any) => {
                return (
                  <>
                    <TableRow key={index + 1}>
                      <TableCell> {page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}</TableCell>
                      <TableCell>{expertData.buy_slots}</TableCell>
                      <TableCell>{expertData.free_slots}</TableCell>
                      <TableCell>{expertData.usage_count}</TableCell>

                      <TableCell>
                        <img
                          onClick={() => {
                            setDiscountData(expertData);
                            // setDiscountCreateMode('edit');
                            setDialogOpenStatus(true);
                          }}
                          className="view_edit_icons"
                          src={edit}
                          alt="Learn and Play"
                        />
                        <Switch
                          onChange={(e: any) => onCheckSwitch(e, expertData)}
                          checked={expertData.is_active}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
          {!hasProducts && <NoProducts />}
        </TableContainer>}
        {userType === 3 && <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell>{t("Child Count")}</TableCell>
                <TableCell>{t("Discount in %")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discountsList?.map((expertData: any, index: any) => {
                return (
                  <>
                    <TableRow key={index + 1}>
                      <TableCell> {page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}</TableCell>
                      <TableCell>{expertData.buy_sessions}</TableCell>
                      <TableCell>{expertData.discount}</TableCell>
                      <TableCell>
                        <img
                          onClick={() => {
                            setDiscountData(expertData);
                            setDiscountCreateMode('edit');
                            setCampDialogStatus(true);
                          }}
                          className="view_edit_icons"
                          src={edit}
                          alt="Learn and Play"
                        />
                        <Switch
                          onChange={(e: any) => onCheckSwitch(e, expertData)}
                          checked={expertData.is_active}
                        />
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
          {!hasProducts && <NoProducts />}
        </TableContainer>}
        {hasProducts && (
          <div className="tablePagination">
            <div className="row">
              <div className="col-md-7">
                <div className="pagination-wrap">
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="rows-per-page">
                  <p>{t("Rows per page")}:</p>
                  <select
                    className="rows-select"
                    value={rowPerPage}
                    onChange={onRowChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscountList;
