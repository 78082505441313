import { useEffect, useState } from "react";
import "./ActivitiesPersonalCourseReview.scss";
import { toast } from "react-toastify";
import ReasonDialog from "../../../../../../Shared/ReasonDialog/ReasonDialog";
import { DIALOG_CONSTANT, SERVICES_STATUS, API_RESPONSE } from "../../../../../../Utilities/Constants/Constants";
import { ERROR_MESSAGES } from "../../../../../../Utilities/Constants/Validations";

const ActivitiesPersonalCourseReview = (personalProps:any)=>{
    const [ activityDetail , setActivityDetail]:any = useState([]);
    const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
    const [ currentIndex, setCurrentIndex]:any= useState();
    const [comments, setComments] = useState<any>("");
    const [commentsTouched, setCommentsTouched] = useState<any>(false);
    const dialogTitle=DIALOG_CONSTANT.TUTOR_REJECT_REASON;

    useEffect(()=>{
        setComments(personalProps.tutorDetail.price_review_comment)
        setActivityDetail(personalProps.tutorDetail.activities_and_experience);
    },[]);

    /* Handling dialog open */
    const openRejectDialog = (i:any) => {
        setCurrentIndex(i);
        setDialogOpenStatus(true);
      };
    
      /* Checking whether all subjects are accepted/rejected */
      const checkSubjectValid = ()=>{
        return new Promise((resolve,reject)=>{
          let isValid = activityDetail.every((sub:any)=>{
            if(sub.status===SERVICES_STATUS.PENDING.toLowerCase()){
             return API_RESPONSE.FALSE;
            }else{
             return API_RESPONSE.TRUE
            }
        });
        if(isValid===API_RESPONSE.TRUE){
           resolve(API_RESPONSE.TRUE);
        }else{
           reject(API_RESPONSE.FALSE);
        }
        })
      }

      /* On Accept course */
      const onAcceptCourse = (index:any)=>{
        const newArray = [...activityDetail];   
        newArray[index].is_approved = true;
        newArray[index].status =SERVICES_STATUS.APPROVED.toLowerCase();
        setActivityDetail(newArray)
      }

      /* Handling dialog close */
      const onCloseDialogBox = () => {
        setDialogOpenStatus(false);
      };

      /* Getting reason through dialog */
      const onSubmitReason = (value: any) => {
        const newArray = [...activityDetail];   
        newArray[currentIndex].reason = value.reason;
        newArray[currentIndex].is_approved = API_RESPONSE.FALSE;
        newArray[currentIndex].status =  SERVICES_STATUS.REJECTED.toLowerCase();
        setActivityDetail(newArray)
        setDialogOpenStatus(API_RESPONSE.FALSE);
      };

      /* Submitting payload through props */
      const onSubmitData = ()=>{
        let data={
         price_review: API_RESPONSE.TRUE,
         price_review_comment: comments,
         activities_and_experience:activityDetail
        };
        checkSubjectValid().then(response=>{
            personalProps.updateTutorverification(data);
        }).catch(error=>{
         toast.error(ERROR_MESSAGES.ACCEPT_SUBJECT)
        })
     
        
       }
      
    return(
        <>
        <div className="activity-course-review-wrapper">
      <ReasonDialog
          modalState={dialogOpenStatus}
          dialogStatus={onCloseDialogBox}
          submitReason={onSubmitReason}
          title={dialogTitle}
        />
        <div className="row course-row-wrapper">
           { activityDetail?.map((subject:any,index:any)=>{
             return (

         <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 course-detail-wrapper">
             { subject.status===SERVICES_STATUS.APPROVED.toLowerCase() && <div className="approval-label">
              Approved
             </div>}
             { subject.status===SERVICES_STATUS.PENDING.toLowerCase() && <div className="approval-label">
              Approval Pending
             </div>}
             { subject.status===SERVICES_STATUS.REJECTED.toLowerCase() && <div className="approval-label">
              Rejected
             </div>}
             <div className="row row-wrap">
               <div className="col-md-5 course-label">
                   Activity                  
               </div>
               <div className="col-md-7 course-data">
                  {subject.activity}
               </div>
             </div>
             <div className="row row-wrap">
               <div className="col-md-5 course-label">
                  Experience
               </div>
               <div className="col-md-7">
                   {subject.experience} <span className="">{subject.experience===1 ? 'year' : 'years'}</span>
               </div>
             </div>
             <div className="row row-wrap">
               <div className="col-md-5 course-label">
                SP Expected Fee
               </div>
               <div className="col-md-7">
                  {parseFloat(subject.expected_fee).toFixed(2)} <span className="amount_label">AED</span>
               </div>
             </div>
             {/* <div className="row row-wrap">
               <div className="col-md-5 course-label">
               Maximum Category Fee
               </div>
               <div className="col-md-7">
                  {parseFloat(subject?.admin_estimated_sp_fee).toFixed(2)} <span className="amount_label">AED</span>
               </div>
             </div> */}
             <div className="row row-wrap">
               <div className="col-md-5 course-label">
                   L&P Commission
               </div>
               <div className="col-md-7">
                  {parseFloat(subject?.admin_commission).toFixed(2)} <span className="amount_label">AED</span>
               </div>
             </div>
             <div className="row row-wrap">
               <div className="col-md-5 course-label">
               SP Fee with incentive
               </div>
               <div className="col-md-7">
                  {parseFloat(subject?.admin_calc_sp_fee).toFixed(2)} <span className="amount_label">AED</span>
               </div>
             </div>
             {!subject.reason && <div className="row-wrap-reason"></div>}
             {subject.reason && <div className="row row-wrap-reason">
               <div className="col-md-5 course-label">
                  Reason
               </div>
               <div className="col-md-7">
                 {subject.reason}
               </div>
             </div>}
             <div className="row row-wrap">
               <div className="button-wrapper">
                   { subject.status===SERVICES_STATUS.PENDING.toLowerCase() &&(
                    <>
                    <button className="btn btn-danger" onClick={()=>{openRejectDialog(index)}}>Reject</button>
                   <button className="btn btn-success" onClick={()=>{onAcceptCourse(index)}}>Accept</button>
                    </> )}

               </div>
             </div>
           
             
           </div>
              // </div>
              
             )
           })}
           
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-12">
            <div className="tutor_info_label_comment">Comments</div>
            <textarea
              value={comments}
              onChange={(event: any) => {
                 setComments(event.target.value);
              }}
              onBlur={() => {
                 setCommentsTouched(true);
              }}
              placeholder="Please enter your comments"
              // disabled={
              //   tutorDetails.status !== SERVICES_STATUS.PENDING.toLowerCase()
              // }
            ></textarea>
            {(comments?.length === 0 || comments == null) &&
              commentsTouched && (
                <div className="error_message">
                  Please enter some comments about the Tutor's personal and
                  professional details
                </div>
              )}
          </div>
        </div>
        <div className="stepper_actions_wrapper">
          <button className="back_button" onClick={personalProps.handleBack}>
            Back
          </button>
          <button
            className="next_button"
            onClick={() => {
              if(personalProps.tutorDetail.status === SERVICES_STATUS.PENDING.toLowerCase()){
             /*  comments?.length === 0 || comments == null
                ? setCommentsTouched(true)
                : */ onSubmitData();
              }else{
                if(personalProps.tutorDetail.status === SERVICES_STATUS.APPROVED.toLowerCase() && personalProps.tutorDetail.is_new_request===API_RESPONSE.TRUE){
                  onSubmitData()
                }else{
                  personalProps.handleNext();
                }
                
                
              }
            }}
          >
           Next
          {/*   {tutorDetail.status === SERVICES_STATUS.PENDING.toLowerCase()
              ? "Update & Go to Summary"
              : "Go to Summary"} */}
          </button>
        </div>
       {/*  <button className="btn btn-secondary" onClick={personalProps.handleBack}>
          Back
        </button> */}
      </div>
        </>
    )
};

export default ActivitiesPersonalCourseReview;