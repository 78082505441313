import CategoriesList from "../../../Shared/ManageCategories/CategoriesList/CategoriesList";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";

const RecreationalCategory = () => {
  return (
    <CategoriesList
      apiEndpoint={API_END_POINTS.GET_RECREATIONAL}
      courseType={VALIDATION_CONSTANT.RECREATION}
    />
  );
};

export default RecreationalCategory;
