import "./ContentList.scss";
import {
  TextField,
  Box,
  Tabs,
  Tab,
  Slider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Pagination,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  nullUndefinedEmptyCheck,
  checkValueNullUndefinedEmpty,
} from "../../../Services/Helpers/Helper";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { setTabData } from "../../../Services/Redux/Reducer/Reducer";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import {
  VALIDATION_CONSTANT,
  DIALOG_CONSTANT,
  SORTING_CONSTANT,
  ROUTES,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
} from "../../../Utilities/Constants/Constants";
import ConfirmationDialog from "../../ConfirmationDialog/ConfirmationDialog";
import NoProducts from "../../NoProducts/NoProducts";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import view from "../../../../Assets/global/view.svg";
import edit from "../../../../Assets/global/edit.svg";
import delete_icon from "../../../../Assets/global/delete.svg";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const ConentList = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [page, setPage] = useState(1);

  const [contentList, setContentList] = useState([]);
  const [currentElement, setCurrentElement] = useState(0);
  const [modalStatus, setmodalStatus] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const dialogContent = DIALOG_CONSTANT.DELETE_PROMOTIONAL_CONTENT;
  const [searchValue, setSearchValue] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [value, setValue] = React.useState(VALIDATION_CONSTANT.COURSE);
  const [sliderValue, setSliderValue] = React.useState<number[]>([]);
  const [currentSliderValue, setCurrentSliderValue] = React.useState<number[]>([
    sliderValue[0],
    sliderValue[1],
  ]);
  const [filterStatus, setFilterStatus] = useState(false);
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [nameSort, setNameSort]: any = useState(SORTING_CONSTANT.ASC);
  const [initialRender, setInitialRender] = useState(true);
  const [rowPerPage, setRowPerPage] = useState<any>(10);
  const [hasProducts, setHasProducts] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);

  const dispatch = useDispatch();
  const tabsData: any = useSelector((state: any) => {
    return state.tabsReducer.tabPosition;
  });

  const [serviceProviderType, setServiceProviderType] = useState('');
  useEffect(() => {
    setServiceProviderType(props.serviceProviderType);
    getFilterValues();
  }, []);
  const [viewerFile, setViewerFile] = useState("");

  const getFilterValues = () => {
    // axiosInstance
    //   .get(API_END_POINTS.GET_CONTENT_FILTERS + "?service_provider_type=" + props.serviceProviderType)
    //   .then((response) => {
    //     // if (props.serviceProviderType === COMMON_CONSTANTS.ONE) {
    //     //   setSliderValue([
    //     //     response.data.result.data[0].course_category.Min,
    //     //     response.data.result.data[0].course_category.Max,
    //     //   ]);
    //     //   setCurrentSliderValue([
    //     //     response.data.result.data[0].course_category.Min,
    //     //     response.data.result.data[0].course_category.Max,
    //     //   ]);
    //     // } else {
    //     //   setSliderValue([
    //     //     response.data.result.data[0].recreational_category.Min,
    //     //     response.data.result.data[0].recreational_category.Max,
    //     //   ]);
    //     //   setCurrentSliderValue([
    //     //     response.data.result.data[0].recreational_category.Min,
    //     //     response.data.result.data[0].recreational_category.Max,
    //     //   ]);
    //     // }
    //   });
  };


  /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setFilterStatus(false);
    setServiceProviderType(newValue);
    setSearchValue("");
    setPage(1);
    setCurrentSort("");
    setCurrentSliderValue([0, 1000]);
  };

  /**Update page number */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Updated on change in search value */

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        getContentList();
      }
    }
  }, [searchValue]);

  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Get Content list API call */
  const getContentList = () => {
    // let payload =
    //   filterStatus === true
    //     ? {
    //       service_provider_type: searchValue.trim(),
    //       description: currentSort,
    //       image: currentSliderValue[COMMON_CONSTANTS.ZERO],
    //       web_link: currentSliderValue[COMMON_CONSTANTS.ONE],
    //       mobile_link: COMMON_CONSTANTS.FALSE,
    //     }
    //     : {
    //       service_provider_type: searchValue.trim(),
    //       description: currentSort,
    //       image: currentSliderValue[COMMON_CONSTANTS.ZERO],
    //       web_link: currentSliderValue[COMMON_CONSTANTS.ONE],
    //       mobile_link: COMMON_CONSTANTS.FALSE,
    //     };
    // axiosInstance.post(props.apiEndpoint, payload).then((response: any) => {
    //   setContentList(response.data.result.data);
    //   checkDataLength(response.data.result.data);
    //   setTotalPage(response.data.result.total_page);
    // });

    axiosInstance.get(props.apiEndpoint + "?service_provider_type=" + serviceProviderType).then((response: any) => {
      let data = response.data.result.data.data;
      data.sort((a:any, b:any) => a.id - b.id);
      setContentList(data);
      checkDataLength(data);
      setTotalPage(response.data.result.data.total_page);
    });

  };

  const checkDataLength = (contentData: any) => {
    if (contentData.length > 0) {
      setHasProducts(true);
    } else {
      setHasProducts(false);
    }
  };

  /* Opening Confirmation dialog box */
  const openDialogBox = (id: any) => {
    setCurrentElement(id);
    setmodalStatus(true);
  };

  /* Delete content API call */
  const onDelContent = (data: any) => {
    setmodalStatus(false);
    if (data === true) {
      axiosInstance
        .delete(
          API_END_POINTS.PROMOTIONAL_CONTENT +
          "?id=" +
          currentElement +
          "&service_provider_type=" +
          serviceProviderType
        )
        .then((response) => {
          if (response.data.status === true) {
            getContentList();
          }
        });
    }
  };

  /* Edit content Redirection */
  const onEditContent = (content: any, index: any) => {
    dispatch(setTabData(value));
    if (serviceProviderType === COMMON_CONSTANTS.ONE) {
      navigate(`/manage-tutors/promotional-content/edit/${content.id}`, {
        state: { index,
          userType:{
            value:serviceProviderType
          }
         },
      })
    } else if (serviceProviderType === COMMON_CONSTANTS.TWO) {
      navigate(`/manage-recreational/promotional-content/edit/${content.id}`, {
        state: { index,
          userType:{
            value:content?.service_provider_type
          }
         },
      })
    }
  };

  const viewContent = (id: any) => {
    dispatch(setTabData(value));

    if (serviceProviderType === COMMON_CONSTANTS.ONE) {
      navigate(`/manage-tutors/promotional-content/view/${id}`)
    } else if (serviceProviderType === COMMON_CONSTANTS.TWO) {
      navigate(`/manage-recreational/promotional-content/view/${id}`)
    }


  };

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  /* Calling Search API */
  const onSearchData = () => {
    getContentList();
    setPage(1);
  };

  /* Setting sort type */
  const onChangeSelect = (e: any) => {
    setCurrentSort(e.target.value);
  };

  /* Handling min-max price slide change */
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setCurrentSliderValue(newValue as []);
  };

  function valuetext(value: number) {
    return `${value}`;
  }

  /* Initializing min-max price marks in sider */
  const marks = [
    {
      value: sliderValue[COMMON_CONSTANTS.ZERO],
      label: sliderValue[COMMON_CONSTANTS.ZERO],
    },
    {
      value: sliderValue[COMMON_CONSTANTS.ONE],
      label: sliderValue[COMMON_CONSTANTS.ONE],
    },
  ];

  /**Initial API call */
  useEffect(() => {
    if (serviceProviderType) {
      getContentList();
    }
  }, [page, serviceProviderType, filterStatus, currentSort, rowPerPage]);

  /* API call on applying filter */
  const onApplyFilter = () => {
    setPage(1);
    setFilterStatus(!filterStatus);
    // getContentList();
  };

  /**Reset filter */
  const onResetFilter = () => {
    setCurrentSliderValue([
      sliderValue[COMMON_CONSTANTS.ZERO],
      sliderValue[COMMON_CONSTANTS.ONE],
    ]);
    setSearchValue("");
    setFilterStatus(false);
  };

  /* Handling content code sorting */
  const handleDescriptionSort = () => {
    // idSort === SORTING_CONSTANT.ASC
    //   ? setIdSort(SORTING_CONSTANT.DSC)
    //   : setIdSort(SORTING_CONSTANT.ASC);
    // idSort === SORTING_CONSTANT.ASC
    //   ? setCurrentSort(SORTING_CONSTANT.ID_ASCENDING)
    //   : setCurrentSort(SORTING_CONSTANT.ID_DESCENDING);
  };

  /* Handling Name Sorting */
  const hanle = () => {
    // nameSort === SORTING_CONSTANT.ASC
    //   ? setNameSort(SORTING_CONSTANT.DSC)
    //   : setNameSort(SORTING_CONSTANT.ASC);
    // nameSort === SORTING_CONSTANT.ASC
    //   ? setCurrentSort(SORTING_CONSTANT.CATEGORY_ASCENDING)
    //   : setCurrentSort(SORTING_CONSTANT.CATEGORY_DESCENDING);
  };

  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE);
    setRowPerPage(event.target.value);
  };

  /**Handle file viwer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  return (
    <div
      className="categories_wrapper"
      dir={currentLanguage === "English" ? "ltr" : "rtl"}
    >
      <ConfirmationDialog
        content={dialogContent}
        modalState={modalStatus}
        dialogStatus={onDelContent}
      />

      {(serviceProviderType === COMMON_CONSTANTS.ONE && contentList.length <= 3) || (serviceProviderType === COMMON_CONSTANTS.TWO && contentList.length <= 2) ?
        <div className="search_add_wrapper">
          <button
            className="add-category-btn"
            onClick={() => {
              dispatch(setTabData(value));

              if (serviceProviderType === COMMON_CONSTANTS.ONE) {
                navigate(ROUTES.TUTOR_PROMOTIONAL_CONTENT_ADD)
              } else if (serviceProviderType === COMMON_CONSTANTS.TWO) {
                navigate(ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_ADD)
              }
            }}
          >
            {t("Add Promotional Content")}
          </button>
        </div>
        : ""}

      {filterApplied && (
        <div className="category_tabs_filter_container">
          <div className="filter-wrap">
            <div className="price_filter_label_wrapper">
              <Box className="slider-box">
                <label className="filter-label">{t("Price Range")}:</label>
                <Slider
                  min={sliderValue[0]}
                  step={1}
                  max={sliderValue[1]}
                  getAriaLabel={() => "Price Range"}
                  value={currentSliderValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay={
                    currentSliderValue[0] > sliderValue[0] ||
                      currentSliderValue[1] < sliderValue[1]
                      ? "on"
                      : "auto"
                  }
                  marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory"
                />
              </Box>
            </div>
            <div className="filter_action_buttons">
              <button className="reset-btn" onClick={onResetFilter}>
                {t("Reset")}
              </button>
              <button className="apply-btn" onClick={onApplyFilter}>
                {t("Apply")}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="filter_category_list_container">
        <TableContainer component={Paper} className="categoryTable">
          <Table>
            <TableHead className="categoryTableHead">
              <TableRow className="categoryTableRow">
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell>{t("Description")}</TableCell>
                <TableCell>{t("Image")}</TableCell>


                <TableCell className="table_actions_head">
                  {t("Actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            {hasProducts && (
              <TableBody>
                {contentList.map((content: any, index: any) => {
                  return (
                    <TableRow key={index + 1}>
                      <TableCell>
                        {page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}
                      </TableCell>
                      <TableCell>
                        {content.description}
                      </TableCell>

                      <TableCell> <img
                        className="table-image"
                        src={content?.image}
                        onClick={() => {
                          onFilerViewerDialogOpen(content?.image);
                        }}
                      /></TableCell>

                      <TableCell>
                        <div className="table_actions">
                          <Tooltip
                            title={t("View Promotional Content")}
                            placement="bottom"
                            onClick={() => viewContent(content.id)}
                          >
                            <img
                              className="view_edit_icons"
                              onClick={() => viewContent(content.id)}
                              src={view}
                              alt="Learn and Play"
                            />
                          </Tooltip>
                          <Tooltip
                            title={t("Edit Category")}
                            placement="bottom"
                          >
                            <img
                              className="view_edit_icons"
                              onClick={() =>{onEditContent(content, index);}}
                              src={edit}
                              alt="Learn and Play"
                            />
                          </Tooltip>
                          {/* <Tooltip
                            title={t("Delete Promotional Content")}
                            placement="bottom"
                          >
                            <img
                              className="view_edit_icons"
                              onClick={() => openDialogBox(content.id)}
                              src={delete_icon}
                              alt="Learn and Play"
                            />
                          </Tooltip> */}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {hasProducts && (
          <div className="tablePagination">
            <div className="row">
              <div className="col-md-7">
                <div className="pagination-wrap">
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="rows-per-page">
                  <p>{t("Rows per page")}:</p>
                  <select
                    className="rows-select"
                    value={rowPerPage}
                    onChange={onRowChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {!hasProducts && <NoProducts></NoProducts>}
      </div>
    </div>
  );
};

export default ConentList;
