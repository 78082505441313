import { useState, useEffect } from "react";
import { checkValueNullUndefinedEmpty } from "../../../../Services/Helpers/Helper";
import FileViewerDialog from "../../../../Shared/FileViewerDialog/FileViewerDialog";
import VideoPlayerDialog from "../../../../Shared/VideoPlayerDialog/VideoPlayerDialog";
import { API_RESPONSE, SERVICES_STATUS, UPLOAD_TICKET_CONSTANT } from "../../../../Utilities/Constants/Constants";
import emiratesID from "../../../../../Assets/TutorVerification/emigrantID.svg";
import fileIcon from "../../../../../Assets/TutorVerification/pdfIcon.svg";

const SchoolsUniversityPersonalDetail = (documentProps:any)=>{
    const [fileViewerOpen, setFileViewerOpen] = useState(API_RESPONSE.FALSE);
    const [viewerFile, setViewerFile] = useState("");
    const [videoPlayerOpen, setVideoPlayerOpen] = useState(API_RESPONSE.FALSE);
    const [videoFile, setvideoFile] = useState("");
    const [schoolUnivDetails, setschoolUnivDetails] = useState<any>({});
    const [comments, setComments] = useState<any>("");
    const [commentsTouched, setCommentsTouched] = useState<any>(API_RESPONSE.FALSE);
  
    const onFilerViewerDialogClose = () => {
      setFileViewerOpen(API_RESPONSE.FALSE);
      setViewerFile("");
    };
    const onFilerViewerDialogOpen = (url: any) => {
      setViewerFile(url);
      setFileViewerOpen(API_RESPONSE.TRUE);
    };
    const onVideoPlayerDialogClose = () => {
      setVideoPlayerOpen(API_RESPONSE.FALSE);
      setvideoFile("");
    };
    const onVideoPlayerDialogOpen = (url: any) => {
      setvideoFile(url);
      setVideoPlayerOpen(API_RESPONSE.TRUE);
    };
  
    useEffect(() => {
      setschoolUnivDetails(documentProps.schoolUnivDetail);
      documentProps.schoolUnivDetail.documentation_review_comment ?setComments(documentProps.schoolUnivDetail.documentation_review_comment) : setComments("");
    }, [documentProps.schoolUnivDetail]);
  
    /**Check for pdf file */
    const checkPdf = (viewerFile: any) => {
      if (checkValueNullUndefinedEmpty(viewerFile)) {
        return viewerFile?.split(".").slice(-1)[0] === UPLOAD_TICKET_CONSTANT.PDF;
      }
      return API_RESPONSE.FALSE;
    };
  
    return (
      <>
       {schoolUnivDetails.authority_details &&<div className="tutor_application_review_wrapper">
          <FileViewerDialog
            open={fileViewerOpen}
            handleClose={onFilerViewerDialogClose}
            viewerFile={viewerFile}
          ></FileViewerDialog>
          <VideoPlayerDialog
            open={videoPlayerOpen}
            handleClose={onVideoPlayerDialogClose}
            viewerFile={videoFile}
          ></VideoPlayerDialog>
          <div className=" tutor_info_label_wrapper">
            <div className=" id_number_wrapper">
              <div className=" tutor_info_label">Emirates ID Number</div>
              <div className=" tutor_info">{schoolUnivDetails.authority_details[0].emirates_id}</div>
            </div>
            {schoolUnivDetails.immigrant_id && <div className=" id_number_wrapper">
              <div className=" tutor_info_label">Immigrant ID Number</div>
              <div className=" tutor_info">{schoolUnivDetails.authority_details[0].immigrant_id}</div>
            </div>}
          </div>
          <div className="tutor_files_card_wrap">
            <div className="tutor_files_wrapper">
              <div className="tutor_files_actions_container">
                <div className="tutor_file_icon_Wrapper">
                  <img
                    className="tutor_files_icon"
                    src={emiratesID}
                    alt="Learn and Play"
                  />
                </div>
                <div className="tutor_info_icon_label">ID Front</div>
                <div
                  className="view_file_button"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].emirates_front);
                  }}
                >
                  View File
                </div>
              </div>
              <div className="tutor_files_icon_wrapper">
                <img
                  className="tutor_file"
                  src={
                    checkPdf(schoolUnivDetails.authority_details[0].emirates_front)
                      ? fileIcon
                      : schoolUnivDetails.authority_details[0].emirates_front
                  }
                  alt="Learn and Play"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].emirates_front);
                  }}
                />
              </div>
            </div>
            <div className="tutor_files_wrapper">
              <div className="tutor_files_actions_container">
                <div className="tutor_file_icon_Wrapper">
                  <img
                    className="tutor_files_icon"
                    src={emiratesID}
                    alt="Learn and Play"
                  />
                </div>
  
                <div className="tutor_info_icon_label">ID Back</div>
                <div
                  className="view_file_button"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].emirates_back);
                  }}
                >
                  View File
                </div>
              </div>
              <div className="tutor_files_icon_wrapper">
                <img
                  className="tutor_file"
                  src={
                    checkPdf(schoolUnivDetails.authority_details[0].emirates_back)
                      ? fileIcon
                      : schoolUnivDetails.authority_details[0].emirates_back
                  }
                  alt="Learn and Play"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].emirates_back);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="tutor_files_card_wrap">
            {schoolUnivDetails.immigrant_front && <div className="tutor_files_wrapper">
              <div className="tutor_files_actions_container">
                <div className="tutor_file_icon_Wrapper">
                  <img
                    className="tutor_files_icon"
                    src={emiratesID}
                    alt="Learn and Play"
                  />
                </div>
  
                <div className="tutor_info_icon_label">Passport Front</div>
                <div
                  className="view_file_button"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].immigrant_front);
                  }}
                >
                  View File
                </div>
              </div>
              <div className="tutor_files_icon_wrapper">
                <img
                  className="tutor_file"
                  src={
                    checkPdf(schoolUnivDetails.authority_details[0].immigrant_front)
                      ? fileIcon
                      : schoolUnivDetails.authority_details[0].immigrant_front
                  }
                  alt="Learn and Play"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].immigrant_front);
                  }}
                />
              </div>
            </div>}
            {schoolUnivDetails.authority_details[0].immigrant_back && <div className="tutor_files_wrapper">
              <div className="tutor_files_actions_container">
                <div className="tutor_file_icon_Wrapper">
                  <img
                    className="tutor_files_icon"
                    src={emiratesID}
                    alt="Learn and Play"
                  />
                </div>
  
                <div className="tutor_info_icon_label">Passport Back</div>
                <div
                  className="view_file_button"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].immigrant_back);
                  }}
                >
                  View File
                </div>
              </div>
              <div className="tutor_files_icon_wrapper">
                <img
                  className="tutor_file"
                  src={
                    checkPdf(schoolUnivDetails.authority_details[0].immigrant_back)
                      ? fileIcon
                      : schoolUnivDetails.authority_details[0].immigrant_back
                  }
                  alt="Learn and Play"
                  onClick={() => {
                    onFilerViewerDialogOpen(schoolUnivDetails.authority_details[0].immigrant_back);
                  }}
                />
              </div>
            </div>}
          </div>

         {/*  {schoolUnivDetails.certificates?.length !== 0 && (
            <div className="certificates_wrapper">
              <div className="tutor_info_icon_label">Certificates</div>
              <div className="col-md-12 row">
                {schoolUnivDetails.certificates?.map(
                  (schoolUnivDetailsElement: any, index: any) => {
                    return (
                      <>
                        <img
                          key={index + 1}
                          onClick={() => {
                            onFilerViewerDialogOpen(schoolUnivDetailsElement);
                          }}
                          className="tutor_files_icon_preview"
                          src={
                            checkPdf(schoolUnivDetailsElement)
                              ? fileIcon
                              : schoolUnivDetailsElement
                          }
                          alt="Learn and Play"
                        />
                      </>
                    );
                  }
                )}
              </div>
            </div>
          )} */}
          <div className="row tutor_info_label_wrapper">
            <div className="col-md-12">
              <div className="tutor_info_label_comment">Comments</div>
              <textarea
                value={comments}
                onChange={(event: any) => {
                  setComments(event.target.value);
                }}
                onBlur={() => {
                  setCommentsTouched(API_RESPONSE.TRUE);
                }}
                placeholder="Please enter your comments"
                disabled={
                  schoolUnivDetails.status !== SERVICES_STATUS.PENDING.toLowerCase()
                }
              ></textarea>
              {(comments?.length === 0 || comments == null) &&
                commentsTouched && (
                  <div className="error_message">
                    Please enter some comments about the Authority documents and Identity details
                  </div>
                )}
            </div>
          </div>
          <div className="stepper_actions_wrapper">
            <button className="back_button" onClick={documentProps.handleBack}>
              Back
            </button>
            <button
              className="next_button"
              onClick={() => {
                if(schoolUnivDetails.status === SERVICES_STATUS.PENDING.toLowerCase()){
                   /*  comments?.length === 0 || comments == null
                    ? setCommentsTouched(API_RESPONSE.TRUE)
                    : */ documentProps.updateTutorverification({
                      documentation_review: API_RESPONSE.TRUE,
                      documentation_review_comment: comments,
                      });
                }else{
                    documentProps.handleNext();
                }
                
              }}
            >
              {/* {schoolUnivDetails.status === SERVICES_STATUS.PENDING.toLowerCase()
                ? "Update & Go to Summary"
                : "Go to Summary"} */}
                Next
            </button>
          </div>
        </div>}
      </>
    );
};

export default SchoolsUniversityPersonalDetail;