import "./AddEditCategories.scss";
import { Formik, Field } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import { toast } from "react-toastify";
import {
  API_RESPONSE,
  I18_TRANSLATION_COMMON,
  ROUTES,
  TOASTER_CONFIGS,
  UPLOAD_TICKET_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import {
  S3DataGetApi,
  checkValueNullUndefinedEmpty,
  nullUndefinedEmptyCheck,
  uploadFileToS3,
} from "../../../Services/Helpers/Helper";
import backButton from "../../../../Assets/schools/back_button.svg";
import FileViewerDialog from "../../FileViewerDialog/FileViewerDialog";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "../../../Services/Redux/Store/Store";
import { setLoader } from "../../../Services/Redux/Reducer/Reducer";

const AddEditCategories = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const [categoryValue, setCategoryValue] = useState({
    arabic_coursecategory_name: "",
    course_category_code: "",
    course_category_name: "",
    max_price: "",
    min_price: "",
    commission: "",
    min_experience: "",
    max_experience: "",
    // min_year:"",
    // max_year:"",
    min_grade: "",
    max_grade: "",
    online_incentive: "",
    offline_incentive: ""
  });
  // min_experience, max_experience
  const [minPrice, setMinPrice] = useState();
  const [categoryType, setCategoryType] = useState("none");
  const [currentEngName, setCurrentEngName] = useState("");
  const [currentArabicName, setCurrentArabicName] = useState("");
  const [categoryIcon, setCategoryIcon] = useState<any>("");
  const [displayImage, setDisplayImage] = useState<any>("");
  const uploadDisplayImage = useRef<HTMLInputElement | null>(null);
  const uploadIconImage = useRef<HTMLInputElement | null>(null);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
  const [gradesList, setGradesList]: any = useState([]);
  const [type, setType] = useState("");
  const { id } = useParams();
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const location = useLocation();
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });
  /* To get id from params */
  useEffect(() => {
    getGradesList();
    location.pathname.split("/")[1] ===
      UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL
      ? setType(VALIDATION_CONSTANT.RECREATION)
      : setType(VALIDATION_CONSTANT.COURSE);
  }, []);
  useEffect(() => {
    if (nullUndefinedEmptyCheck(id) && nullUndefinedEmptyCheck(type)) {
      getCategory(id);
    } else {
      setCategoryValue({
        arabic_coursecategory_name: "",
        course_category_code: "",
        course_category_name: "",
        max_price: "",
        min_price: "",
        commission: "",
        min_experience: "",
        max_experience: "",
        // min_year:"",
        // max_year:"",
        min_grade: "",
        max_grade: "",
        online_incentive: "",
        offline_incentive: ""
      });
    }
  }, [type]);

  /* To restrict inputs for only numbers */
  function numberOnly(event: any) {
    const input = event.target;
    const value = input.value;

    // Remove non-digit and non-decimal characters except the first dot
    const sanitizedValue = value.replace(
      /[^\d.]/g,
      (match: any, offset: any) => {
        if (match === "." && offset === 0) {
          return match;
        }
        return "";
      }
    );

    // Limit to two digits after the decimal point
    const decimalIndex = sanitizedValue.indexOf(".");
    if (decimalIndex !== -1 && sanitizedValue.length - decimalIndex > 2 + 1) {
      input.value = sanitizedValue.slice(0, decimalIndex + 3);
    } else {
      input.value = sanitizedValue;
    }
  }

  /* Form Validation using yup */
  const categoryValidationSchema = yup.object({
    course_category_name: yup
      .string()
      .required(ERROR_MESSAGES.CATEGORY_NAME)
      .trim(),
    arabic_coursecategory_name: yup
      .string()
      .required(ERROR_MESSAGES.ARABIC_CATEGORY_NAME)
      .trim(),
    min_price: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value); // Allow empty value
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .required(ERROR_MESSAGES.MIN_PRICE_MSG),
    max_price: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value);
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .test("superior", ERROR_MESSAGES.MIN_MAX_MATCH, function (value: any) {
        const ref: any = yup.ref(VALIDATION_CONSTANT.MIN_PRICE);
        return parseFloat(value) > parseFloat(this.resolve(ref));
      })
      .typeError("typo")
      .required(ERROR_MESSAGES.MAX_PRICE_MSG)
      .nullable(),
    min_experience: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value); // Allow empty value
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .required(ERROR_MESSAGES.MIN_EXP_MSG),
    max_experience: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        // ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value);
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .test("superior", ERROR_MESSAGES.EXPERIENCE_MATCH, function (value: any) {
        const ref: any = yup.ref(VALIDATION_CONSTANT.MIN_EXPERIENCE);
        return parseFloat(value) > parseFloat(this.resolve(ref));
      })
      .typeError("typo")
      .required(ERROR_MESSAGES.MAX_EXP_MSG)
      .nullable(),

    min_grade: type === VALIDATION_CONSTANT.COURSE ? yup
      .mixed()
      /* .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value); // Allow empty value
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      ) */
      .required(ERROR_MESSAGES.MIN_GRADE) :
      yup
        .mixed(),

    max_grade: type === VALIDATION_CONSTANT.COURSE ? yup
      .mixed()
      /* .test("superior", ERROR_MESSAGES.GRADE_MISMATCH, function (value: any) {
        const ref: any = yup.ref(VALIDATION_CONSTANT.MIN_GRADE);
        return parseFloat(value) > parseFloat(this.resolve(ref));
      }) */
      .typeError("typo")
      .required(ERROR_MESSAGES.MAX_GRADE)
      .nullable() :
      yup
        .mixed()
        .nullable(),
    /*   min_year : type === VALIDATION_CONSTANT.COURSE ? yup
       .mixed()
       .test(
         VALIDATION_CONSTANT.VALID_NUMBER,
         ERROR_MESSAGES.AMOUNT_FORMAT,
         function (value: any) {
           checkValueNullUndefinedEmpty(value); // Allow empty value
           const decimalRegex = REGEX.DECIMAL_VALIDATION;
           return (
             decimalRegex.test(value?.toString()) &&
             !value?.toString().endsWith  (".")
           );
         }
       )
       .required(ERROR_MESSAGES.MIN_YEAR) :
       yup
       .mixed(),
 
        max_year : type===VALIDATION_CONSTANT.COURSE ? yup
       .mixed()
       .test(
         VALIDATION_CONSTANT.VALID_NUMBER,
         // ERROR_MESSAGES.AMOUNT_FORMAT,Fm
         function (value: any) {
           checkValueNullUndefinedEmpty(value);
           const decimalRegex = REGEX.DECIMAL_VALIDATION;
           return (
             decimalRegex.test(value?.toString()) &&
             !value?.toString().endsWith(".")
           );
         }
       )
       .test("superior", ERROR_MESSAGES.YEAR_MISMATCH, function (value: any) {
         const ref: any = yup.ref(VALIDATION_CONSTANT.MIN_YEAR);
         return parseFloat(value) > parseFloat(this.resolve(ref));
       })
       .typeError("typo")
       .required(ERROR_MESSAGES.MAX_YEAR)
       .nullable() :
       yup
       .string()
       .nullable(),
  */
    online_incentive: type === VALIDATION_CONSTANT.COURSE ? yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value);
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .typeError("typo")
      .required(ERROR_MESSAGES.ONLINE_INCENTIVE)
      .nullable() :
      yup
        .string()
        .nullable(),
    offline_incentive: type === VALIDATION_CONSTANT.COURSE ? yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value);
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .typeError('Please enter valid number')
      .required(ERROR_MESSAGES.OFFLINE_INCENTIVE)
      .nullable() :
      yup
        .string()
        .nullable(),
    commission: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.PERCENTAGE_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value); // Allow empty value
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value?.toString()) &&
            !value?.toString().endsWith(".")
          );
        }
      )
      .test(
        VALIDATION_CONSTANT.VALID_COMMISSION,
        ERROR_MESSAGES.COMMISSION_ERR,
        function (value) {
          const commissionType = this.resolve(
            yup.ref(VALIDATION_CONSTANT.COMMISSION)
          );
          if (commissionType) {
            return yup
              .number()
              .max(100, ERROR_MESSAGES.COMMISSION_ERR)
              .isValidSync(value);
          }
          return true;
        }
      )
      .transform(function (value, originalValue) {
        if (checkValueNullUndefinedEmpty(value)) {
          return null; // Convert empty values to null
        }
        return originalValue; // Return the original value
      })
      .required(ERROR_MESSAGES.COMMISSION_REQUIRED)
      .nullable(),
  });

  /* Get Cateogry API call */
  const getCategory = (id: any) => {
    axiosInstance
      .get(
        API_END_POINTS.GET_CATEGORY_DATA +
        "?id=" +
        id +
        "&category_type=" +
        type
      )
      .then((resp) => {
        if (resp.data.status === true) {
          let data = resp.data.result.data;
          setCategoryIcon(resp.data.result.data?.coursecategory_icon);
          setDisplayImage(resp.data.result.data?.display_image)
          if (type === VALIDATION_CONSTANT.RECREATION) {
            data.arabic_coursecategory_name = data.arabic_category_name;
            data.course_category_name = data.category_name;
            setCategoryIcon(resp.data.result.data?.recreationalcategory_icon);
          }
          setCategoryValue(data);
        }
      });
  };

  useEffect(() => {
  }, [gradesList])

  const getGradesList = () => {
    axiosInstance.get(API_END_POINTS.GET_GRADES_LIST + "?is_type=False").then(response => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setGradesList(response.data.result.data)
      }
    })
  }

  /* Save Category API call */
  function onSaveCategory(event: any) {
    let data = event;
    let paramType = VALIDATION_CONSTANT.COURSE;
    data.display_image = displayImage;
    if (type === VALIDATION_CONSTANT.RECREATION) {
      data.arabic_category_name = event.arabic_coursecategory_name;
      data.category_name = event.course_category_name;
      data.recreationalcategory_icon = categoryIcon;
      paramType = VALIDATION_CONSTANT.RECREATION;
    } else {
      data.coursecategory_icon = categoryIcon;
    }
    axiosInstance
      .post(
        API_END_POINTS.CREATE_CATEGORY + "?category_type=" + paramType,
        data
      )
      .then((res: any) => {
        if (res.data.status === true) {
          onNavigate();
          toast.success(res.data.message, TOASTER_CONFIGS);
        }
      });
  }

  /* Edit Category API call */
  function onEditCategory(event: any) {
    let data = event;
    if (type === VALIDATION_CONSTANT.RECREATION) {
      data.arabic_category_name = event?.arabic_coursecategory_name;
      data.category_name = event?.course_category_name;
      data.recreationalcategory_icon = categoryIcon;
    }
    data.coursecategory_icon = categoryIcon;
    data.display_image = displayImage;
    data.course_category_code = categoryValue.course_category_code;
    axiosInstance
      .put(
        API_END_POINTS.CREATE_CATEGORY +
        "?id=" +
        id +
        "&category_type=" +
        type,
        data
      )
      .then((res: any) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          onNavigate();
        }
      });
  }

  /* For Navigating */
  const onNavigate = () => {
    type === VALIDATION_CONSTANT.RECREATION
      ? navigate(ROUTES.RECREATIONAL_CATEGORY)
      : navigate(ROUTES.COURSE_CATEGOY_LIST);
  };

  const handleChange = (event: any) => {
    setCategoryType(event.target.value);
  };

  const handleImageChange = async (event: any) => {
    store.dispatch(setLoader(true));
    const uploadedImage = await S3DataGetApi(event);
    setDisplayImage(uploadedImage)
    store.dispatch(setLoader(false));
  }

  const handleIconChange = async (event: any) => {
    store.dispatch(setLoader(true));
    const uploadedImage = await S3DataGetApi(event);
    setCategoryIcon(uploadedImage);
    store.dispatch(setLoader(false));
  }

  const validateArabic = (e: any) => {
    if (REGEX.ARABIC_INPUT_VALIDATION.test(e.target.value)) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  const validateArabicPaste = (e: any) => {
    if (REGEX.ARABIC_INPUT_VALIDATION.test(e.clipboardData.getData("text"))) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  const validateEnglish = (e: any) => {
    if (
      REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value) &&
      !/[0-9.,]/.test(e.key)
    ) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  const validateEnglishPaste = (e: any) => {
    if (REGEX.ENGLISH_INPUT_VALIDATION.test(e.clipboardData.getData("text"))) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  const onEnglishNameChange = (event: any) => {
    if (REGEX.ENGLISH_INPUT_VALIDATION.test(event.target.value)) {
      //event.target.value.trim() !='' &&
      setCurrentEngName(event.target.value);
    }
  };

  const onArabicNameChange = (event: any) => {
    if (REGEX.ARABIC_INPUT_VALIDATION.test(event.target.value)) {
      setCurrentArabicName(event.target.value);
    }
  };

  /**Handle file viwer dialog close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(API_RESPONSE.FALSE);
    setViewerFile("");
  };

  /**Handle file viwer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  return (
    <div
      className="add-category-container"
      dir={currentLanguage === "English" ? "ltr" : "rtl"}
    >
      <div className="page-title">
        <img
          className="arrow_icon"
          src={backButton}
          alt="Learn and Play"
          onClick={onNavigate}
        />
        <h5 className="name-title">
          {id ? t("Edit") : t("Add")} {t("Category")}
        </h5>
      </div>

      <FileViewerDialog
        open={fileViewerOpen}
        handleClose={onFilerViewerDialogClose}
        viewerFile={viewerFile}
      ></FileViewerDialog>

      <Formik
        enableReinitialize
        validationSchema={categoryValidationSchema}
        initialValues={{
          course_type: type === "course" ? "Course" : "Recreational Activity",
          course_category_name: categoryValue.course_category_name,
          arabic_coursecategory_name: categoryValue.arabic_coursecategory_name,
          min_price: categoryValue.min_price,
          max_price: categoryValue.max_price,
          commission: categoryValue.commission,
          min_experience: categoryValue.min_experience,
          max_experience: categoryValue.max_experience,
          min_grade: categoryValue?.min_grade,
          max_grade: categoryValue?.max_grade,
          // min_year : categoryValue.max_experience,
          // max_year : categoryValue.max_experience,
          offline_incentive: categoryValue.offline_incentive,
          online_incentive: categoryValue.online_incentive
        }}
        onSubmit={(values) => {
          setFormSubmitted(API_RESPONSE.FALSE)
          if (id) {
            onEditCategory(values);
          } else {
            onSaveCategory(values);
          }
        }}
      >
        {(props) => {
          return (
            <div className="">
              <div className="box-wrapper">
                <div className="row">
                  <div className="col-md-6">
                    <div className="categoryWrap">
                      <label className="nameLabel">{t("Category Type")}:</label>

                      <Field
                        // as="select"
                        disabled={true}
                        name="course_type"
                        placeholder="Enter Category Type"
                        onChange={handleChange}
                        keypress="numberOnly($event)"
                        className="course_type"
                        value={type === VALIDATION_CONSTANT.RECREATION ? "Recreational Activity" : "Course"}
                        onKeyPress={(event: any) => {
                          numberOnly(event);
                        }}
                      >
                      </Field>
                      <div className="errorMessage"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="categoryWrap">
                      <label className="nameLabel">{t("Category Name")}</label>
                      <Field
                        name="course_category_name"
                        className="course_category_name input"
                        placeholder="Enter Category Name"
                        // onChange={onEnglishNameChange}
                        onKeyPress={validateEnglish}
                        onPaste={validateEnglishPaste}
                        maxLength={50}
                      // value={currentEngName}
                      />
                      <p className="category-note mb-1">Note: Max limit 50 char </p>
                      <div className="errorMessage">
                        {props.touched.course_category_name &&
                          props.errors.course_category_name && (
                            <p>{t(`${props.errors.course_category_name}`)}</p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Arabic Category Name")}:
                      </label>
                      <Field
                        name="arabic_coursecategory_name"
                        className="arabic_coursecategory_name input"
                        placeholder={t("Enter Arabic Category Name")}
                        dir="rtl"
                        onKeyPress={validateArabic}
                        onPaste={validateArabicPaste}
                      />
                      {/* <p className="category-note mb-1">Note: Category name should be maximum 50 characters</p> */}
                      <div className="errorMessage">
                        {props.touched.arabic_coursecategory_name &&
                          props.errors.arabic_coursecategory_name && (
                            <p>
                              {t(`${props.errors.arabic_coursecategory_name}`)}
                            </p>
                          )}
                      </div>
                    </div>

                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Minimum Experience")}:
                      </label>
                      <Field
                        name="min_experience"
                        keypress="numberOnly($event)"
                        placeholder="Enter Minimum Experience"
                        className="min_price input"
                        onChange={(e: any) => {
                          if (e.target.value <= 100) {
                            props.setFieldValue("min_experience", e.target.value)
                          }
                        }}
                        // value={minPrice}
                        onInput={numberOnly}
                      />
                      <div className="errorMessage">
                        {props.touched.min_experience && props.errors.min_experience && (
                          <p>{t(`${props.errors.min_experience}`)}</p>
                        )}
                      </div>
                    </div>

                    {type === VALIDATION_CONSTANT.COURSE && <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Minimum Grade")}:
                      </label>
                      <Field
                        as="select"
                        name="min_grade"
                        placeholder="Enter Minimum Grade"
                        onChange={(e: any) => {
                          props.setFieldValue("min_grade", e.target.value)
                        }}
                        onBlur={props.handleBlur}
                        keypress="numberOnly($event)"
                        className="course_type input"
                        value={props?.values["min_grade"]}
                        onKeyPress={(event: any) => {
                          numberOnly(event);
                        }}
                      >
                        <option selected value="none">
                          {t("Please select the category type")}
                        </option>
                        {
                          gradesList?.map((grade: any, index: any) => {
                            return (
                              <option selected value={grade}>
                                {grade}
                              </option>
                            )
                          })
                        }
                      </Field>
                      <div className="errorMessage">
                        {props.touched.min_grade && props.errors.min_grade && (
                          <p>{t(`${props.errors.min_grade}`)}</p>
                        )}
                      </div>
                    </div>}

                    {/* {type===VALIDATION_CONSTANT.COURSE && <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Minimum Year")}:
                      </label>
                      <Field
                        name="min_year"
                        keypress="numberOnly($event)"
                        className="min_price input"
                        // onChange={validateMinPrice}
                        // value={minPrice}
                        onInput={numberOnly}
                      />
                      <div className="errorMessage">
                        {props.touched.min_year && props.errors.min_year && (
                          <p>{t(`${props.errors.min_year}`)}</p>
                        )}
                      </div>
                    </div>} */}
                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Minimum Price(AED)")}:
                      </label>
                      <Field
                        name="min_price"
                        keypress="numberOnly($event)"
                        className="min_price input"
                        placeholder="Enter Minimum Price"
                        // onChange={validateMinPrice}
                        // value={minPrice}
                        maxLength={6}
                        onInput={numberOnly}
                      />
                      <div className="errorMessage">
                        {props.touched.min_price && props.errors.min_price && (
                          <p>{t(`${props.errors.min_price}`)}</p>
                        )}
                      </div>
                    </div>
                    {type === VALIDATION_CONSTANT.COURSE && <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("On-premises Incentive")}:
                      </label>
                      <Field
                        name="offline_incentive"
                        keypress="numberOnly($event)"
                        className="min_price input"
                        placeholder="Enter On-premises Incentive"
                        // onChange={validateMinPrice}
                        // value={minPrice}
                        maxLength={6}
                        onInput={numberOnly}
                      />
                      <div className="errorMessage">
                        {props.touched.offline_incentive && props.errors.offline_incentive && (
                          <p>{t(`${props.errors.offline_incentive}`)}</p>
                        )}
                      </div>
                    </div>}
                    <input
                      className="image_input"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg,image/svg,.mov,.mp4,.mkv"
                      multiple
                      onChange={handleIconChange}
                      ref={uploadIconImage}
                    />
                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Category Icon")}:
                      </label>
                      <div className="display-img-wrapper">
                        {categoryIcon &&
                          <div className="image-close-wrapper">
                            <img className="display-image" src={categoryIcon} onClick={() => {
                              onFilerViewerDialogOpen(categoryIcon);
                            }} />
                            <CloseIcon className="image-close-icon" onClick={() => {
                              setCategoryIcon("");
                            }} />
                          </div>}
                        <button className="btn btn-secondary" onClick={() => uploadIconImage.current?.click()}>Upload Icon</button>
                      </div>
                      <div className="text_contant">Recommended size: 60x60 with JPEG,PNG</div>
                    </div>
                    <div className="errorMessage">
                      {formSubmitted && categoryIcon === '' && (
                        <p>{t(`Category icon is required`)}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">


                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Commission Percentage")}:
                      </label>
                      <Field
                        name="commission"
                        keypress="numberOnly($event)"
                        placeholder="Enter Commission Percentage"
                        className="commission input"
                        onInput={numberOnly}
                        onChange={(e: any) => {
                          if (e.target.value <= 100) {
                            props.setFieldValue("commission", e.target.value)
                          }
                        }}
                      />
                      <div className="errorMessage">
                        {props.touched.commission &&
                          props.errors.commission && (
                            <p>{t(`${props.errors.commission}`)}</p>
                          )}
                      </div>
                    </div>

                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Maximum Experience")}:
                      </label>
                      <Field
                        name="max_experience"
                        keypress="numberOnly($event)"
                        className="min_price input"
                        placeholder="Enter Maximum Experience"
                        onChange={(e: any) => {
                          if (e.target.value <= 100) {
                            props.setFieldValue("max_experience", e.target.value)
                          }
                        }}
                        // value={minPrice}
                        onInput={numberOnly}
                      />
                      <div className="errorMessage">
                        {props.touched.max_experience && props.errors.max_experience && (
                          <p>{t(`${props.errors.max_experience}`)}</p>
                        )}
                      </div>
                    </div>

                    {type === VALIDATION_CONSTANT.COURSE && <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Maximum Grade")}:
                      </label>
                      <Field
                        as="select"
                        name="max_grade"
                        onChange={(e: any) => {
                          props.setFieldValue("max_grade", e.target.value)
                        }}
                        onBlur={props.handleBlur}
                        placeholder="Enter Maximum Grade"
                        keypress="numberOnly($event)"
                        className="course_type input"
                        value={props.values["max_grade"]}
                        onKeyPress={(event: any) => {
                          numberOnly(event);
                        }}
                      >
                        <option selected value="none">
                          {t("Please select the category type")}
                        </option>
                        {
                          gradesList.map((grade: any, index: any) => {
                            return (
                              <option selected value={grade}>
                                {grade}
                              </option>
                            )
                          })
                        }
                      </Field>
                      <div className="errorMessage">
                        {props.touched.max_grade && props.errors.max_grade && (
                          <p>{t(`${props.errors.max_grade}`)}</p>
                        )}
                      </div>
                    </div>}

                    {/*  {type===VALIDATION_CONSTANT.COURSE && <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Maximum Year")}:
                      </label>
                      <Field
                        name="max_year"
                        keypress="numberOnly($event)"
                        className="min_p`rice input"
                        // onChange={validateMinPrice}
                        // value={minPrice}
                        onInput={numberOnly}
                      />
                      <div className="errorMessage">
                        {props.touched.max_year && props.errors.max_year && (
                          <p>{t(`${props.errors.max_year}`)}</p>
                        )}
                      </div>
                    </div>} */}

                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Maximum Price(AED)")}:
                      </label>
                      <Field
                        name="max_price"
                        keypress="numberOnly($event)"
                        placeholder="Enter Maximum Price"
                        className="max_price input"
                        onInput={numberOnly}
                        maxLength={6}
                      />
                      <div className="errorMessage">
                        {props.touched.max_price && props.errors.max_price && (
                          <p>{t(`${props.errors.max_price}`)}</p>
                        )}
                      </div>
                    </div>

                    {type === VALIDATION_CONSTANT.COURSE && <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Online Incentives")}:
                      </label>
                      <Field
                        name="online_incentive"
                        placeholder="Enter Online Incentives"
                        keypress="numberOnly($event)"
                        className="max_price input"
                        onInput={numberOnly}
                        maxLength={6}
                      />
                      <div className="errorMessage">
                        {props.touched.online_incentive && props.errors.online_incentive && (
                          <p>{t(`${props.errors.online_incentive}`)}</p>
                        )}
                      </div>
                    </div>}
                    <div className="categoryWrap">
                      <label className="nameLabel">
                        {t("Display Image")}:
                      </label>
                      <input
                        className="image_input"
                        type="file"
                        accept="image/png,image/jpg,image/jpeg,image/svg,.mov,.mp4,.mkv"
                        multiple
                        onChange={handleImageChange}
                        ref={uploadDisplayImage}
                      />
                      <div className="display-img-wrapper">
                        {displayImage &&
                          <div className="image-close-wrapper">
                            <img className="display-image" src={displayImage} onClick={() => {
                              onFilerViewerDialogOpen(displayImage);
                            }} />
                            <CloseIcon className="image-close-icon" onClick={() => {
                              setDisplayImage("");
                            }} />
                          </div>}
                        <button className="btn btn-secondary upload-btn" onClick={() => uploadDisplayImage.current?.click()}>Upload Image</button>
                      </div>
                      <div className="text_contant">Recommended size: 160x160 with JPEG,PNG</div>
                    </div>
                    <div className="errorMessage">
                      {formSubmitted && displayImage === '' && (
                        <p>{t(`Display image is required`)}</p>
                      )}
                    </div>

                  </div>
                </div>
              </div>
              <div className="button-wrap">
                <button className="cancel_button" onClick={onNavigate}>
                  {t("Cancel")}
                </button>
                <button
                  className="save_button"
                  type="submit"
                  onClick={() => {
                    props.handleSubmit();
                    setFormSubmitted(API_RESPONSE.TRUE)
                  }}
                >
                  {id ? t("Update") : t("Save")}
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddEditCategories;
