import { Card } from "@mui/material";
import "./Notifications.scss";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import { API_RESPONSE, NOTIFICATION_CONSTANTS, NUMBER_CONSTANTS } from "../../Utilities/Constants/Constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Notifications = () => {
  const [newNotifications, setNewNotifications] = useState<any>([]);
  const [seenNotifications, setSeenNotifications] = useState<any>([]);
  const [ notificationDetail, setNotificationDetail ] = useState<any>({});
  const [newIndex, setNewIndex] = useState(API_RESPONSE.NULL);
  const [seenIndex,setSeenIndex] = useState(API_RESPONSE.NULL);  
  const navigate = useNavigate();
  const notifiactionUser: any = useSelector((state: any) => {
    return state.notificationReducer.notification;
  });
  
  /* useEffect(() => {
    getNotifications();
  }, []); */
  useEffect(()=>{
    getNotifications();  
  },[notifiactionUser])

  const getNotifications = () => {
    axiosInstance
      .get(API_END_POINTS.NOTIFICATIONS_LIST)
      .then((response: any) => {
        if (response.data.status === API_RESPONSE.TRUE) {
          setNewNotifications(response.data.result.data.new);
          setSeenNotifications(response.data.result.data.seen);
        }
      });
  };

  /* Time calculation logic to show in list  */
  const calculateTime = (value: any) => {
    const now: any = new Date();
    const time: any = new Date(value);
    const timeDifference = now - time;
    const seconds = Math.floor(timeDifference / NUMBER_CONSTANTS.THOUSAND);
    const minutes = Math.floor(seconds / NUMBER_CONSTANTS.SIXTY);
    const hours = Math.floor(minutes /  NUMBER_CONSTANTS.SIXTY);
    const days = Math.floor(hours / NUMBER_CONSTANTS.TWENTY_FOUR);
    if (days >= NUMBER_CONSTANTS.ONE) {
        return days === NUMBER_CONSTANTS.ONE ? NOTIFICATION_CONSTANTS.ONE_DAY : `${days}` + NOTIFICATION_CONSTANTS.DAYS_AGO;
    } else if (hours >= NUMBER_CONSTANTS.ONE) {
        return hours === NUMBER_CONSTANTS.ONE ? NOTIFICATION_CONSTANTS.ONE_HOUR : `${hours} `+NOTIFICATION_CONSTANTS.HOURS_AGO;
    } else if (minutes >= NUMBER_CONSTANTS.ONE) {
        return minutes === NUMBER_CONSTANTS.ONE ? NOTIFICATION_CONSTANTS.ONE_MINUTE : `${minutes}` + NOTIFICATION_CONSTANTS.MINUTES_AGO;
    } else {
        return `${NOTIFICATION_CONSTANTS.JUST_NOW}`;
    }
  };

  const onViewNotification = (index:any,type:any)=> {
    if(type===NOTIFICATION_CONSTANTS.NEW){
        setNewIndex(index);
        setSeenIndex(API_RESPONSE.NULL);
        setNotificationDetail(newNotifications[index]);
        markNotificationRead(newNotifications[index].id);
    }else{
        setNewIndex(API_RESPONSE.NULL);
        setSeenIndex(index);
        setNotificationDetail(seenNotifications[index]);
    }
  };

  const markNotificationRead = (id:any)=>{
    axiosInstance.get(API_END_POINTS.NOTIFICATION_READ + "?id=" + id).then((response:any)=>{
        if (response.data.status === API_RESPONSE.TRUE) {

        }
    })
  };

  const onRedirect = () =>{
    switch(notificationDetail?.redirect_info?.admin_path){
      case "profile":
        navigate(`/profile`);
        break;

      case "manage_onboardings":
      case "on_boarding":
          if((notificationDetail?.redirect_info?.key === "tutor_info_update") || (notificationDetail?.redirect_info?.key === "tutor_register")){
            navigate(`/tutors-status-list/tutor-verification/${notificationDetail?.sent_by}`);
          }else
          if((notificationDetail?.redirect_info?.key==="trainer_info_update") || (notificationDetail?.redirect_info?.key==="trainer_register") || (notificationDetail?.redirect_info?.key==="personal_trainer_register")){
            navigate(`/activities-personal-status-list/${notificationDetail?.sent_by}`);
          }else
          if((notificationDetail?.redirect_info?.key==="camp_info_update") || (notificationDetail?.redirect_info?.key==="camp_register")){
            navigate(`/activities-campus-status-list/${notificationDetail?.sent_by}`);
          }
       break;
      
      case "slot_booking":
        if(notificationDetail?.redirect_info?.teach_service_provider_type==="tutor"){
          navigate(`/manage-tutors/bookings/0/booked/${notificationDetail?.redirect_info?.session_id}`);
        }else
        if(notificationDetail?.redirect_info?.teach_service_provider_type==="camp"){
          navigate(`/manage-recreational/bookings/0/camp/${notificationDetail?.redirect_info?.session_id}`);
        }else
        if(notificationDetail?.redirect_info?.teach_service_provider_type==="trainer"){
          navigate(`/manage-recreational/bookings/0/personal/${notificationDetail?.redirect_info?.session_id}`);
        }
      break;

      case "cancelations":
        if(notificationDetail?.redirect_info?.teach_type==="tutor"){
          navigate('/manage-tutors/cancelations')
        }else{
          navigate(`/manage-recreational/cancelations`);
        }
      break;

      case "new_user_register":
        navigate(`/manage-users/${notificationDetail?.sent_by}`);
        break;

      case "chat":
        navigate(`/manage-chat/${notificationDetail?.redirect_info?.room_name}`);
        break;
        
      case "expert_chat":
        navigate(`/manage-chat/${notificationDetail?.redirect_info?.room_name}`);
        break;

      case "themepark_booking":
        navigate("/manage-themepark/list/ticket-list/" + notificationDetail?.redirect_info?.themepark_id);
        // navigate("/manage-themepark/tickets/list/" + notificationDetail?.redirect_info?.id);
        break;
    }
  };
  

  return (
    <div className="notification-wrapper">
      <div className="row">
        <div className="col-md-7">
          <div className="new-notifications-wrapper">
            <h5 className="ps-2">New</h5>
            <Card className="new-not-card">
               {newNotifications.length>NUMBER_CONSTANTS.ZERO && <div>
               {
                newNotifications.map((notification:any,index:any)=>{
                return(
                    <div key={index+NUMBER_CONSTANTS.ONE} className={newIndex===index ? "seen-notification-data-active" :"notification-data"} onClick={()=>{onViewNotification(index,'new')}}>
                        <div className="notification-image">
                        <img
                            className="user-profile"
                            src={notification?.image ? notification?.image : "https://fixxerapp.s3.ap-south-1.amazonaws.com/pexels-pixabay-220453%20%281%29%20%281%29.jpg"}
                        />
                        </div>
                        <div className="notification-content">
                        <p className="notification-header">
                           {notification?.title}
                        </p>
                        <p className="notification-time">{calculateTime(notification?.created_on)}</p>
                        </div>
                    </div>
                )
                })
              }
               </div>}
               {
                newNotifications.length===NUMBER_CONSTANTS.ZERO &&  <>
                   <div className="d-flex justify-content-center p-5">
                         No New Notifications available
                   </div>
                </>
               }
             
              
            </Card>
          </div>
          <div className="seen-notifications-wrapper">
            <h5 className="ps-2">Seen</h5>
            <Card className="new-not-card">
              {seenNotifications.length>NUMBER_CONSTANTS.ZERO && <div>
              {
                    seenNotifications.map((data:any,i:any)=>{
                        return(
                            <div key={i+NUMBER_CONSTANTS.ONE} className={seenIndex===i ? "seen-notification-data-active" :"notification-data"}  onClick={()=>{onViewNotification(i,'seen')}}> 
                                <div className="notification-image">
                                <img
                                    className="user-profile"
                                    src={data?.image ? data?.image : "https://fixxerapp.s3.ap-south-1.amazonaws.com/pexels-pixabay-220453%20%281%29%20%281%29.jpg"}
                                />
                                </div>
                                <div className="notification-content">
                                <p className="notification-header">
                                   {data?.title}
                                </p>
                                <p className="notification-time">{calculateTime(data?.created_on)}</p>
                                </div>
                                <hr className="horizontal-line" />
                            </div>
                        )
                    })
                }
              </div>}
              
              {
                seenNotifications.length===NUMBER_CONSTANTS.ZERO &&  <>
                   <div className="d-flex justify-content-center p-5">
                         No notifications available!
                   </div>
                </>
               }
              
            </Card>
          </div>
        </div>
        <div className="col-md-5">
          {notificationDetail?.created_on && <Card className="new-not-card-right" onClick={onRedirect}>
            <div className="notification-detail">
              <img
                className="not-detail-profile"
                src={notificationDetail?.image ? notificationDetail?.image : "https://fixxerapp.s3.ap-south-1.amazonaws.com/pexels-pixabay-220453%20%281%29%20%281%29.jpg"}
              />
              <p className="notification-detail-content">
                {notificationDetail?.description}
              </p>
              <div className="timer-wrap">
               <p className="notification-detail-time">{calculateTime(notificationDetail?.created_on)}</p>
               <p className="click-text">click here</p>
              </div>
            </div>
          </Card>}
        </div>
      </div>
    </div>
  );
};

export default Notifications;