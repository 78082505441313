import { Card, Chip, Step, StepLabel, Stepper } from "@mui/material";
import "./TutorVerificationLayout.scss";

import TutorApplicationOverview from "../Shared/TutorApplicationOverview/TutorApplicationOverview";
import BackArrow from "../../../../../Assets/schools/back_button.svg";
import { useEffect, useState } from "react";
import TutorApplicationReview from "./../Shared/TutorApplicationReview/TutorApplicationReview";
import TutorVerificationStatus from "../Shared/TutorVerificationStatus/TutorVerificationStatus";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  ColorlibConnector,
  ColorlibStepIcon,
  ROUTES,
  TOASTER_CONFIGS,
  TUTOR_VERIFICATION_STEPPER_LABEL,
  VALIDATION_CONSTANT,
} from "../../../../Utilities/Constants/Constants";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import TutorCourseReview from "../Shared/TutorCourseReview/TutorCourseReview";
import { useSelector } from "react-redux";
import { GetSessionStorageData } from "../../../../Services/Helpers/Helper";

const TutorVerificationLayout = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [tutorDetail, setTutorDetail] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [ isSuperAdmin, setIsSuperAdmin ]:any= useState(API_RESPONSE.FALSE);
  const [ tabOnePermission, setTabOnePermission ]= useState(API_RESPONSE.TRUE);
  const [ tabTwoPermission, setTabTwoPermission ]= useState(API_RESPONSE.TRUE);
  const [ tabThreePermission, setTabThreePermission ]= useState(API_RESPONSE.TRUE);
  const [ tabFourPermission, setTabFourPermission ]= useState(API_RESPONSE.TRUE);
  const navigate = useNavigate();

/* Getting permission data from reducer store */
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  })

  /* Getting data from local storage */
  useEffect(()=>{
    setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
 },[])

   /* Setting permission based on permission data */
  useEffect(()=>{
  
    if(isSuperAdmin===`${API_RESPONSE.TRUE}`){
      setTabOnePermission(API_RESPONSE.TRUE);
      setTabTwoPermission(API_RESPONSE.TRUE);
      setTabThreePermission(API_RESPONSE.TRUE);
      setTabFourPermission(API_RESPONSE.TRUE);
    }else{
      setTabOnePermission(permissions[1]?.data[4].data[1].data[0].checked);
      setTabTwoPermission(permissions[1]?.data[4].data[1].data[1].checked);
      setTabThreePermission(permissions[1]?.data[4].data[1].data[2].checked);
      setTabFourPermission(permissions[1]?.data[4].data[1].data[3].checked);
    }
  },[isSuperAdmin]);

  /**Go to next stepper */
  const handleNext = () => {
    if((permissions[1]?.data[4].data[1].data[activeStep+1].checked===API_RESPONSE.TRUE) || isSuperAdmin){
    if (activeStep + 1 < 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }else{
    if (activeStep + 1 < 4){
      toast.error(`You don't have permission for proceeding to next step`);
    }
  }
  };

  /**Go to previous stepper */
  const handleBack = () => {
    if (activeStep - 1 > -1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    getTutorDetails();
  }, []);

  /**Get tutor details */
  const getTutorDetails = () => {
    axiosInstance
      .get(`${API_END_POINTS.GET_TUTOR_DETAILS}` + `?id=${id}`)
      .then((tutorDetailResponse: any) => {
        if (
          tutorDetailResponse.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE
        ) {
          setTutorDetail(tutorDetailResponse.data.result.data[0]);
          setCurrentStatus(tutorDetailResponse.data.result.data[0].status);
        }
      });
  };

  /**Update tutor verifiaction status */
  const updateTutorverification = (payload: any) => {
    axiosInstance
      .put(
        `${API_END_POINTS.UPDATE_TUTOR_VERFICATION_DETAILS}` +
          `?id=${id}` +
          `&page=${activeStep + 1}`,
        payload
      )
      .then((tutorDetailResponse: any) => {
        if (
          tutorDetailResponse.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE
        ) {
          toast.success(tutorDetailResponse.data.message, TOASTER_CONFIGS);
          if (activeStep + 1 < 4) {
            handleNext();
          } else {
            navigate(ROUTES.TUTORS_STATUS_LIST);
          }
        }
      });
  };

  return (
    <>
      <div className="row tutor_verification_wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="arrow_status_wrap">
              <div className="arrow-wrap">
                <img
                  src={BackArrow}
                  alt="Learn and Play"
                  className="EditSchool-backarrow"
                  onClick={() => {
                    navigate(ROUTES.TUTORS_STATUS_LIST);
                  }}
                />
                <h5 className="name-title mt-1 ms-2">Tutor Verification</h5>
              </div>
              {currentStatus === VALIDATION_CONSTANT.PENDING ? (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="warning"
                />
              ) : currentStatus === VALIDATION_CONSTANT.APPROVED ? (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="success"
                />
              ) : (
                <Chip
                  className="row-capitalize"
                  label={currentStatus}
                  color="error"
                />
              )}
            </div>
          </div>
        </div>
        <Card className="card-data">
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {TUTOR_VERIFICATION_STEPPER_LABEL.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <TutorApplicationOverview
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
            ></TutorApplicationOverview>
          )}
          {activeStep === 1 && (
            <TutorApplicationReview
              handleBack={handleBack}
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
            ></TutorApplicationReview>
          )}
          {activeStep === 2 && (
            <TutorCourseReview
              handleBack={handleBack}
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
              getTutorDetails={getTutorDetails}
            ></TutorCourseReview>
          )}
          {activeStep === 3 && (
            <TutorVerificationStatus
              handleBack={handleBack}
              handleNext={handleNext}
              tutorDetail={tutorDetail}
              updateTutorverification={updateTutorverification}
              getTutorDetails={getTutorDetails}
            ></TutorVerificationStatus>
          )}
        </Card>
      </div>
    </>
  );
};
export default TutorVerificationLayout;
