import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { GetSessionStorageData, S3DataGetApi, checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck, uploadFileToS3 } from "../../Services/Helpers/Helper";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import driveImg from "../../../Assets/global/drive.svg";
import { NUMBER_CONSTANTS, API_RESPONSE, ADMIN_ROLES, VALIDATION_CONSTANT, COMMON_CONSTANTS } from "../../Utilities/Constants/Constants";
import "./ManageChat.scss";
import { setLoader, setNotificationData } from "../../Services/Redux/Reducer/Reducer";
import profile_default from "../../../Assets/profile/profile_default.svg";
import { store } from "../../Services/Redux/Store/Store";

const ManageChat = () => {

  const [chatsList, setChatsList] = useState<any>([]);
  const [chatterData, setChatterData] = useState<any>()
  const [textMessage, setTextMessage] = useState("");
  const [activeIndex, setActiveIndex] = useState(NUMBER_CONSTANTS.MINUS_ONE);
  const [currentRoomData, setCurrentRoomData] = useState<any>({});
  const [chatHistory, setChatHistory] = useState<any>([]);
  const [userId, setUserId] = useState<any>("");
  const [socketInstance, setSocketInstance] = useState<any>();
  const [currentPage, setCurrentPage] = useState(NUMBER_CONSTANTS.ONE);
  const [totalPages, setTotalPages] = useState(NUMBER_CONSTANTS.ZERO);
  const [roleId, setRoleId] = useState<any>("");
  const profileBtnRef = useRef<HTMLInputElement | null>(null);
  const [fileViewerOpen, setFileViewerOpen] = useState(API_RESPONSE.FALSE);
  const [viewerFile, setViewerFile] = useState("");
  const [newMessage, setNewMessage] = useState(API_RESPONSE.FALSE);
  const [prevChatRoom, setPrevChatRoom] = useState<any>(null);
  const [userClicked, setUserClicked] = useState(false);
  const dispatch = useDispatch();
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const chatContainerRef: any = useRef(API_RESPONSE.NULL);
  let prevScrollHeight: any = useRef(NUMBER_CONSTANTS.ZERO);

  const notifiactionUser: any = useSelector((state: any) => {
    return state.notificationReducer.notification;
  });

  useEffect(() => {
    /* if(id !== notifiactionUser?.chat_room){
      let data= chatsList.findIndex((user:any,index:any)=>{
        return user?.sender == notifiactionUser.sender;
      });
      let userList = chatsList;
      if(userList[data])
        userList[data].is_unread_msg_available = true;
 
      setChatsList([...userList]);
    } */
    if (id !== notifiactionUser?.chat_room) {
      getChatsList();
    }


  }, [notifiactionUser, roleId])

  useEffect(() => {
    if (id) {
      setPrevChatRoom(id);
      getChatsList();
    }
  }, [id])


  useEffect(() => {
    moment.locale('en');
    setRoleId(GetSessionStorageData(COMMON_CONSTANTS.ROLE_ID));
    setUserId(GetSessionStorageData(COMMON_CONSTANTS.ID));
    getChatsList();
  }, []);

  /*  useEffect(()=>{
      if(nullUndefinedEmptyCheck(roleId)){
       getChatsList();
      }
     
   },[roleId]) */

  useEffect(() => {
    /*  if (chatContainerRef.current && chatHistory?.length <= 10 ) {
         chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
       }else{
         if(chatContainerRef?.current)
           chatContainerRef.current.scrollTop = 100;
       } */
    if (chatContainerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 200;

      if (isScrolledToBottom || newMessage || currentPage === NUMBER_CONSTANTS.ONE) {
        chatContainerRef.current.scrollTop = scrollHeight - 200;
        setNewMessage(API_RESPONSE.FALSE);
      } else {
        chatContainerRef.current.scrollTop = 100;
      }
    }
  }, [chatHistory]);

  /* Handling Scroll */
  const handleScroll = () => {
    const chatContainer = chatContainerRef?.current;

    if (chatContainer.scrollTop === NUMBER_CONSTANTS.ZERO) {
      // When the user scrolls to the top, load more messages
      if (currentPage + 1 <= totalPages) {
        setCurrentPage(currentPage + 1)
      }
    }
  };

  useEffect(() => {
    if (currentRoomData && id && currentPage !== NUMBER_CONSTANTS.ONE && chatterData)
      getChatHistory(currentRoomData);
  }, [currentPage, chatterData]);

  useEffect(() => {
    if (id) {
      if (socketInstance) {
        socketInstance.close();
      };
      let socket: any = new WebSocket(`${process.env.REACT_APP_SOCKET_URL + id}/`);
      // let socket = new WebSocket(`wss://learnandplay.way2smile.ae:8001/ws/chat/${id}/`);
      // let socket = new WebSocket(`wss://phygital.way2smile.ae:8001/ws/chat/${id}/`);
      setSocketInstance(socket)
      setMessageRead();
    }
  }, [id])

  /* chat read api*/
  const setMessageRead = () => {
    axiosInstance.put(API_END_POINTS.SET_USER_READ_CHAT, { "room_id": id })
      .then((response) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) { }
      })
  }

  useEffect(() => {
    if (socketInstance) {
      socketInstance.onopen = (event: any) => {
      };

      /* Listen to events for socket server */
      socketInstance.onmessage = (event: any) => {
        setChatHistory((prevChatHistory: any) => [...prevChatHistory, JSON.parse(event.data)]);
      };

      /* Closes socket on killing component */
      return () => {
        socketInstance.close();
      }
    }

  }, [socketInstance])

  useEffect(() => {
  }, [chatsList])

  /* Getting users list for chat */
  const getChatsList = () => {
    let role = GetSessionStorageData(COMMON_CONSTANTS.ROLE_ID);
    let endPoint = role === `${COMMON_CONSTANTS.TWELVE}` ? API_END_POINTS.EXPERT_CHAT_USERS_LIST : API_END_POINTS.CHAT_USERS_LIST;
    axiosInstance.get(endPoint + "?page=" + COMMON_CONSTANTS.ONE + "&limit=" + COMMON_CONSTANTS.HUNDRED).then((response) => {
      if (response?.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        store.dispatch(setLoader(false));
        if (id && response?.data?.result?.data?.length > 0 /* && checkValueNullUndefinedEmpty(chatterData) */) {
          // setCurrentRoomData(id);
          let filterData = response?.data?.result?.data.findIndex((user: any, i: number) => {
            return user.chat_room === id;
          });
          setActiveIndex(response?.data?.result?.data[filterData]?.user);
          if (!chatterData && prevChatRoom !== null) {
            setChatterData(response?.data?.result?.data[filterData]);
          }

          // }
          //  onClickUser(response?.data?.result?.data[filterData],filterData);

        }
        setChatsList([...response?.data?.result?.data]);
      }
    })
    /*  let spType = GetSessionStorageData(COMMON_CONSTANTS.ROLE_ID) === ADMIN_ROLES.TUTOR ? NUMBER_CONSTANTS.ONE :  ADMIN_ROLES.RECREATIONAL_PERSONAL ? NUMBER_CONSTANTS.TWO : NUMBER_CONSTANTS.THREE;
     axiosInstance.get(API_END_POINTS.CHAT_USERS_LIST + "?service_provider_type=" + spType).then((response) => {
         if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
               setChatsList([...chatsList,...response.data.result.data]);
         }
 }) */
  };

  /* Sending message using socket */
  const onSendMessage = () => {
    setNewMessage(API_RESPONSE.TRUE)
    if (textMessage.trim() !== '') {
      socketInstance.send(JSON.stringify({
        sender: parseInt(userId),
        receiver: chatterData.user,
        message: textMessage,
        image: null,
        document: null,
        room_id: chatterData.room_id
      }));
      setTextMessage("");

    }
  };

  const onSendMessageDoc = (imgMsg: any, dataType: any) => {
    setNewMessage(API_RESPONSE.TRUE)
    if (imgMsg.trim() !== '') {
      socketInstance.send(JSON.stringify({
        sender: parseInt(userId),
        receiver: chatterData.user,
        message: null,
        image: dataType === 'image' ? imgMsg : null,
        document: dataType === 'document' ? imgMsg : null,
        room_id: chatterData.room_id
      }));

      setTextMessage("");
    }
  };


  const onUploadEventImage = async (event: any) => {
    try {
      const uploadedImage = await S3DataGetApi(event);
      if (event.target.files[0].type.split("/")[0] === "image") {
        onSendMessageDoc(uploadedImage, 'image');
      } else {
        onSendMessageDoc(uploadedImage, 'document');
      }
    } catch (error) { }
  };


  /**Handle file viewer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(API_RESPONSE.TRUE);
  };

  useEffect(() => {
    // if(chatHistory.length> NUMBER_CONSTANTS)
    // navigate(`/manage-chat/${chatterData.room_name}`);
    if (chatterData /* && prevChatRoom !==id */) {
      navigate(`/manage-chat/${chatterData.room_name}`);
      getChatHistory(chatterData.room_id);
    }

  }, [chatterData])


  /* Creating chat room on click user */
  const onClickUser = (data: any, index: any) => {
    setUserClicked(true);
    if (chatterData !== data) {
      if (socketInstance) {
        socketInstance.close();
      }
      let userList: any = chatsList;
      userList[index].is_unread_msg_available = false;
      setChatsList([...userList]);
      /* dispatch(
      setNotificationData(
      ""
      ));  */

      setChatHistory([]);

      setTotalPages(NUMBER_CONSTANTS.ZERO);
      setCurrentPage(NUMBER_CONSTANTS.ONE);
      setActiveIndex(userList[index]?.user);
      setTextMessage("");
      setChatterData(data);
    }

    //   navigate(`/manage-chat/${data.room_name}`);
  };

  const createRoom = () => {
    /* let spType =
    GetSessionStorageData(COMMON_CONSTANTS.ROLE_ID) === ADMIN_ROLES.TUTOR
      ? NUMBER_CONSTANTS.ONE
      : ADMIN_ROLES.RECREATIONAL_PERSONAL
      ? NUMBER_CONSTANTS.TWO
      : NUMBER_CONSTANTS.THREE;
  let userType =
    GetSessionStorageData(COMMON_CONSTANTS.ROLE_ID) === ADMIN_ROLES.TUTOR
      ? VALIDATION_CONSTANT.TUTOR
      : ADMIN_ROLES.RECREATIONAL_PERSONAL
      ? VALIDATION_CONSTANT.RECREATIONAL_PERSONAL
      : VALIDATION_CONSTANT.RECREATIONAL_CAMP;
  axiosInstance
    .post(API_END_POINTS.CREATE_CHAT_ROOM, {
      service_provider_type: spType,
      booking_id: chatterData.booking_id,
      type: userType,
      recipient_id: chatterData.child_auth_id,
    })
    .then((response) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setCurrentRoomData(response.data.result.data);
        navigate(`/chat/${response.data.result.data.room_name}`);
      }
    }); */
  }

  const getChatHistory = (data: any) => {
    let endPoint = roleId === `${COMMON_CONSTANTS.TWELVE}` ? API_END_POINTS.EXPERTS_CHAT_HISTORY : API_END_POINTS.CHAT_HISTORY;
    axiosInstance.post(endPoint + "?room_id=" + chatterData.room_id, {
      page: currentPage,
      limit: NUMBER_CONSTANTS.TEN
    }).then((response) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        prevScrollHeight = chatContainerRef.current.scrollHeight;
        let responseData = response.data.result.data.reverse();
        setChatHistory([...responseData, ...chatHistory]);
        setTotalPages(response.data.result.total_page);

      }
    })
  }

  /* Coverting intoo railway time */
  const convertRailwayTime = (time: any) => {
    moment.locale('en');
    return moment(time, COMMON_CONSTANTS.HMA_FORMAT).format(COMMON_CONSTANTS.HM_FORMAT);
  };

  return (
    <div className="chat-wrapper">
      <div className="row">
        <div className="col-md-5">
          <div>
            <input className="search-input" placeholder="Search" />
          </div>
          <div className="chat-list-wrapper">
            {
              chatsList.map((user: any, index: any) => {
                return (
                  // <div>
                  <div key={index + 1} className={activeIndex === user.user ? "chat-list-active" : "chat-list"} onClick={() => { onClickUser(user, index) }}>
                    <div className={user.is_unread_msg_available ? "profile-wrap" : 'profile-wrap-empty'}>
                      <img className="chat-user-profile" src={user.image ? user.image : profile_default} />
                    </div>
                    <div className="name-desc-wrapper">
                      <p className="user-name-left">{user.user_name}</p>
                      {/* <p className="parent-name"><span>Messaging by: </span>{user.parent_name}</p> */}
                      <p className="last-message">{user.last_message}</p>
                    </div>
                    <div>
                      <p className="timer">{user?.last_msg_time ?
                        moment(user?.last_msg_time).format(COMMON_CONSTANTS.HM_FORMAT)
                        : ""}</p>
                    </div>
                    <hr className="" />
                  </div>
                )
              })
            }
          </div>

        </div>
        <div className="col-md-7">
          <div className="chat-detail-wrapper">
            <div className="user-name">
              <h5>{chatterData ? chatterData?.firstname : ''}</h5>
            </div>
            <div className="user-chat">
              {activeIndex != -1 &&
                <div className="chats" ref={chatContainerRef} onScroll={handleScroll}>
                  {chatHistory.map((chat: any, i: any) => {
                    return (
                      <>
                        {chat.sender != userId &&
                          <>
                            {chat.message && <div className="end-chat-time-wrapper">
                              <div className="end-user-chat">
                                <p className="end-user-message">{chat.message}</p>

                              </div>
                              <p className="end-time">{moment(chat.created_on).format(COMMON_CONSTANTS.HM_FORMAT)}</p>
                            </div>}

                            <div className="end-chat-time-wrapper">
                              {chat.image && <div className="end-user-chat-img">
                                <img className="chat-image" src={chat.image} onClick={() => {
                                  onFilerViewerDialogOpen(chat.image);
                                }} />
                              </div>}
                            </div>

                            <div className="end-chat-time-wrapper">
                              {chat.document && <div className="end-user-chat-img">
                                <img className="chat-document" src={driveImg} onClick={() => {
                                  // onFilerViewerDialogOpen(chat.message);
                                  window.open(chat.document, "_blank");
                                }} />
                                <p className="document-text">{chat.document.split("/")[
                                  chat.document.split("/").length - 1
                                ]
                                }</p>
                              </div>}


                            </div>

                          </>
                        }
                        {chat.sender == userId &&
                          <>
                            {chat.message && <div className="user-chat-time-wrapper">
                              <div className="user-one-chat">
                                <p>{chat.message}</p>

                                <p className="end-time">{moment(chat.created_on).format(COMMON_CONSTANTS.HM_FORMAT)}</p>
                              </div>


                            </div>}
                            <div className="user-chat-time-wrapper">
                              {chat.image && <div className="user-one-chat-img">
                                <img className="chat-image" src={chat.image} onClick={() => {
                                  onFilerViewerDialogOpen(chat.image);
                                }} />
                              </div>}


                            </div>

                            <div className="user-chat-time-wrapper">
                              {chat.document && <div className="user-one-chat-img">
                                <img className="chat-document" src={driveImg} onClick={() => {
                                  // onFilerViewerDialogOpen(chat.message);
                                  window.open(chat.document, "_blank");
                                }} />
                                <p className="document-text">{chat.document?.split("/")[
                                  chat.document?.split("/").length - 1
                                ]
                                }</p>
                              </div>}


                            </div>
                          </>


                        }
                      </>

                    )


                  })}
                </div>

              }
              {activeIndex != -1 && <div className="message-input-wrapper">
                <div className="input-send-wrapper">
                  <div className="input-icon-wrapper">
                    <textarea placeholder="Write your message..." rows={NUMBER_CONSTANTS.ONE} value={textMessage} onChange={(e: any) => {
                      setTextMessage(e.target.value);
                    }}
                      onKeyPress={(e: any) => {
                        if (e.code === VALIDATION_CONSTANT.KEY_TYPE) {
                          if (textMessage.trim() === '') {
                            e.preventDefault();
                          } else {
                            onSendMessage();
                            e.preventDefault();
                          }
                        }

                      }}
                    />
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/svg+xml, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      className="input-upload"
                      onChange={(e: any) => {
                        onUploadEventImage(e);
                      }}
                      ref={profileBtnRef}
                      style={{ display: "none" }}
                    />

                    <AttachFileIcon className="attach-file-icon"
                      onClick={() => {
                        profileBtnRef.current?.click();
                      }} />
                  </div>

                  <button className="btn btn-primary send-btn" onClick={onSendMessage}>Send <SendIcon className="send-icon" /></button>
                </div>
              </div>}

              {/* {
                                activeIndex === -1 && <div className="initial-image-wrapper">
                                    <img src={chatImg} className="chat-image"/>
                                    <p className="start-msg">Please click on the users to start chat</p>
                                </div>
                            } */}
            </div>

          </div>
        </div>

      </div>
    </div>
  )
};

export default ManageChat;
