import {
  ADD_SCHOOLS_MODEL,
  ADD_UNIVERSITY_MODEL,
  ADMIN_ROLES_MODEL,
  API_RESPONSE_MODEL,
  DASHBOARD_MODEL,
  LANGUAGES_MODEL,
  LAYOUTS_MODEL,
  LOGIN_FIELDS_MODEL,
  ROUTES_MODEL,
  SIDEBAR_MODEL,
  TOKENS_MODEL,
  I18_TRANSLATION_COMMON_MODEL,
  TOAST_ERRORS_MODELS,
} from "../Models/ConstantModels";
import applied from "../../../Assets/TutorVerification/Applied.svg";
import review from "../../../Assets/TutorVerification/review.svg";
import verified from "../../../Assets/TutorVerification/verified.svg";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

//sidebar icons
import dashboard from "../../../Assets/sidebar/dashboard.svg";
import schools from "../../../Assets/sidebar/schools.svg";
import university from "../../../Assets/sidebar/university.svg";
import courses from "../../../Assets/sidebar/courses.svg";
import recreational from "../../../Assets/sidebar/recreational.svg";
import tutors from "../../../Assets/sidebar/tutors.svg";
import tickets from "../../../Assets/sidebar/tickets.svg";
import dashboard_active from "../../../Assets/sidebar/dashboard_active.svg";
import schools_active from "../../../Assets/sidebar/school_active.svg";
import university_active from "../../../Assets/sidebar/university_active.svg";
import courses_active from "../../../Assets/sidebar/course_active.svg";
import recreational_active from "../../../Assets/sidebar/recreational_active.svg";
import tutors_active from "../../../Assets/sidebar/tutor_active.svg";
import tickets_active from "../../../Assets/sidebar/tickets_active.svg";
import roles_active from "../../../Assets/sidebar/Roles_active.svg";
import content_active from "../../../Assets/sidebar/Content_active.svg";
import roles from "../../../Assets/sidebar/Roles.svg";
import content from "../../../Assets/sidebar/Content.svg";
import discounts_inactive from "../../../Assets/sidebar/discounts_inactive.svg";
import discounts_active from "../../../Assets/sidebar/discounts_active.svg";
import experts_inactive from "../../../Assets/sidebar/experts_inactive.svg";
import experts_active from "../../../Assets/sidebar/experts_active.svg";
import invoice_inactive from "../../../Assets/sidebar/invoice_inactive.svg";
import invoice_active from "../../../Assets/sidebar/invoice_active.svg";
import user_inactive from "../../../Assets/sidebar/onboarding_inactive.svg";
import user_active from "../../../Assets/sidebar/onboarding_active.svg";
import payment_inactive from "../../../Assets/sidebar/payment_inactive.svg";
import payment_active from "../../../Assets/sidebar/payment_active.svg";
import chat_inactive from "../../../Assets/sidebar/chats_inactive.svg";
import chat_active from "../../../Assets/sidebar/chats_active.svg";
import onboarding_active from "../../../Assets/sidebar/Onboard-active.svg";
import onboard_inactive from "../../../Assets/sidebar/Onboard.svg";

import {
  StepConnector,
  StepIconProps,
  stepConnectorClasses,
  styled,
} from "@mui/material";

const BULK_UPLOAD_CSV_LINKS: any = {
  SCHOOL_DATA:
    "https://phygital-lp.s3.ap-south-1.amazonaws.com/school_new.csv",
  // "https://phygital-lp.s3.ap-south-1.amazonaws.com/school_data.csv",
  UNIVERSITY_DATA:
    "https://phygital-lp.s3.ap-south-1.amazonaws.com/university_data.csv",
  // "https://phygital-lp.s3.ap-south-1.amazonaws.com/university_data.csv",
};
const BULK_UPLOAD_TYPE: any = {
  SCHOOL: "school",
  UNIVERSITY: "UNIVERSITY",
};
const DASHBOARD: DASHBOARD_MODEL = {
  REPORTS: "reports",
};
const I18_TRANSLATION_COMMON: I18_TRANSLATION_COMMON_MODEL = {
  COMMON: "common",
};
const TOAST_ERRORS: TOAST_ERRORS_MODELS = {
  IMAGES_LIMIT: "Can upload only upto 10 Images",
  IMAGE_UPLOAD_ERROR: "Error uploading file:",
};
const ROUTES: ROUTES_MODEL = {
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password/:id",
  FORGET_PASSWORD: "/forget-password",
  MANAGE_SCHOOLS: "/manage-schools",
  MANAGE_UNIVERSITIES: "/manage-universities",
  VIEW_UNIVERSITY: "/manage-universities/:id",
  VIEW_UNIVERSITY_STATUS: "/universities-status-list/:id",
  VIEW_UNIVERSITY_STATUS_DETAIL: "/universities-status-list/view/:id",
  EDIT_UNIVERSITY: "/manage-universities/edit/:id",
  ADD_UNIVERSITY: "/manage-universities/add",
  EDIT_SCHOOLS: "/manage-schools/edit/:id",
  ADD_SCHOOLS: "/manage-schools/add/",
  SCHOOL_DETAILS: "/manage-schools/:id",
  SCHOOL_STATUS_DETAILS: "/school-status-list/:id",
  SCHOOL_STATUS_VIEW_DETAILS: "/school-status-list/view/:id",
  PROFILE: "/profile",
  MANAGE_COURSES: "/manage-courses",
  COURSE_DETAIL: "/manage-courses/:id",
  MANAGE_TUTORS: "/manage-tutors/tutors",
  TUTOR_DETAIL: "/manage-tutors/:id",
  TUTOR_BOOKINGS: "/manage-tutors/bookings",
  TUTOR_CANCELATIONS: "/manage-tutors/cancelations",
  TUTOR_SLOTS: "/manage-tutors/bookings/:id",
  TUTOR_BOOKED_LIST: "/manage-tutors/bookings/:id/booked/:slot_id",
  TUTOR_SESSIONS: "/manage-tutors/sessions",
  MANAGE_THEMEPARK: "/manage-themepark/list",
  MANAGE_THEMEPARK_CATEGORY: "/manage-themepark/category",
  MANAGE_THEMEPARK_BANNERLIST: "/manage-themepark/banner-list",
  MANAGE_THEMEPARK_TICKETLIST: "/manage-themepark/list/ticket-list/:id",
  MANAGE_RECREATIONAL_PERSONAL: "/manage-activities-personal-training",
  MANAGE_RECREATIONAL_CAMP: "/manage-activities-camp",
  ACTIVITIES_PERSONAL_DETAIL:
    "/manage-activities-personal-training/activities-personal/:id",
  ACTIVITIES_CAMP_DETAIL: "/manage-activities-camp/activities-camp/:id",
  RECREATIONAL_PERSONAL_STATUS_DETAIL: "/activities-personal-status-list/:id",
  RECREATIONAL_CAMP_STATUS_DETAIL: "/activities-campus-status-list/:id",
  RECREATIONAL_KEYWORDS: "/manage-recreational/keywords",
  RECREATIONAL_VIEW_KEYWORDS: "/manage-recreational/keywords/:id",
  RECREATIONAL_CATEGORY: "/manage-recreational/category",
  RECREATIONAL_ADD_CATEGORY: "/manage-recreational/category/add",
  RECREATIONAL_EDIT_CATEGORY: "/manage-recreational/category/edit/:id",
  RECREATIONAL_VIEW_CATEGORY: "/manage-recreational/category/view/:id",
  RECREATIONAL_ADD_KEYWORDS: "/manage-recreational/keywords/add",
  RECREATIONAL_EDIT_KEYWORDS: "/manage-recreational/keywords/edit/:id",
  RECREATIONAL_BOOKINGS: "/manage-recreational/bookings",
  RECREATIONAL_SLOTS: "/manage-recreational/bookings/:id",
  RECREATIONAL_BOOKED_SLOTS: "/manage-recreational/bookings/:id/:user_type/:slot_id",
  RECREATIONAL_SESSIONS: "/manage-recreational/sessions",
  SESSION_DETAIL: "/manage-recreational/sessions/:id",
  RECREATIONAL_CANCELATIONS: "/manage-recreational/cancelations",
  RECREATIONAL_PROMOTIONAL_CONTENT_ADD: "/manage-recreational/promotional-content/add",
  RECREATIONAL_PROMOTIONAL_CONTENT_EDIT: "/manage-recreational/promotional-content/edit/:id",
  RECREATIONAL_PROMOTIONAL_CONTENT_VIEW: "/manage-recreational/promotional-content/view/:id",
  RECREATIONAL_PROMOTIONAL_CONTENT_LIST: "/manage-recreational/promotional-content",
  CREATE_THEMEPARK: "/manage-themepark/list/new",
  VIEW_THEMEPARK: "/manage-themepark/list/:id",
  EDIT_THEMEPARK: "/manage-themepark/list/theme-park/:id",
  VIEW_TICKETS: "/manage-themepark/tickets/list/:id",
  SCHOOL_STATUS_LIST: "/school-status-list",
  UNIVERSITIES_STATUS_LIST: "/universities-status-list",
  ACTIVITIES_PERSONAL_STATUS_LIST: "/activities-personal-status-list",
  ACTIVITIES_CAMPUS_STATUS_LIST: "/activities-campus-status-list",
  TUTORS_STATUS_LIST: "/tutors-status-list",
  COURSE_KEYWORDS: "/manage-tutors/keywords",
  COURSE_ADD_KEYWORDS: "/manage-tutors/keywords/add",
  COURSE_VIEW_KEYWORDS: "/manage-tutors/keywords/:id",
  COURSE_EDIT_KEYWORDS: "/manage-tutors/keywords/edit/:id",
  COURSE_ADD_CATEGORY: "/manage-tutors/category/add",
  COURSE_EDIT_CATEGORY: "/manage-tutors/category/edit/:id",
  COURSE_VIEW_CATEGORY: "/manage-tutors/category/view/:id",
  COURSE_CATEGOY_LIST: "/manage-tutors/category",
  TUTOR_PROMOTIONAL_CONTENT_ADD: "/manage-tutors/promotional-content/add",
  TUTOR_PROMOTIONAL_CONTENT_EDIT: "/manage-tutors/promotional-content/edit/:id",
  TUTOR_PROMOTIONAL_CONTENT_VIEW: "/manage-tutors/promotional-content/view/:id",
  TUTOR_PROMOTIONAL_CONTENT_LIST: "/manage-tutors/promotional-content",
  TUTORS_VERIFICATION: "/tutors-status-list/tutor-verification/:id",
  MANAGE_ROLES: "/manage-roles",
  CREATE_ADMIN: "/manage-roles/new",
  EDIT_ADMIN: "/manage-roles/:id",
  HOME_PAGE_CONTENTS: "/manage-contents/homepage",
  CREATE_HOME_PAGE_CONTENTS: "/manage-contents/homepage/new",
  CREATE_HOME_PAGE_CONTENTS_TWO: "/manage-contents/homepage/new/2",
  EDIT_HOME_PAGE_CONTENTS: "/manage-contents/homepage/:id",
  NOTIFICATIONS: "/notifications",
  PAYMENT_LIST: "/manage-payments",
  PAYMENT_DETAIL: "/manage-payments/:id",
  MANAGE_INVOICE: "/manage-invoice",
  MANAGE_EXPERTS: "/manage-experts/experts",
  REQUESTED_EXPERTS: "/manage-experts/requests",
  MANAGE_DISCOUNTS: "/manage-discounts/list",
  DISCOUNT_LIST: "/manage-discounts/list",
  MANAGE_CHAT: "/manage-chat",
  MANAGE_CHAT_USER: "/manage-chat/:id",
  MANAGE_REPORT: "/manage-reports",
  MANAGE_CHAT_REQUEST: "/user-chat-request",
  MANAGE_PROGRAMS: "/manage-programs",
  MANAGE_USERS: "/manage-users",
  USER_DETAIL: "/manage-users/:id",
  USER_BOOKINGS: "/manage-users/:id/bookings"
};
const TOKENS: TOKENS_MODEL = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
};

const NUMBER_CONSTANTS: any = {
  MINUS_ONE: -1,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWELVE: 12,
  SIXTEEN: 16,
  TWENTY_FOUR: 24,
  ONE_TWENTY: 120,
  SIXTY: 60,
  NINETY: 90,
  THOUSAND: 1000,
  THOUSAND_TWENTYFOUR: 1024
};

const SIDEBAR: SIDEBAR_MODEL[] = [
  {
    ICON: dashboard,
    ACTIVE_ICON: dashboard_active,
    PATH: ROUTES.DASHBOARD,
    NAME: "Dashboard",
    SUB_MENU: [],
  },
  {
    ICON: onboard_inactive,
    ACTIVE_ICON: onboarding_active,
    PATH: ROUTES.SCHOOL_STATUS_LIST,
    NAME: "Manage Onboarding",
    SUB_MENU: [
      // {
      //   PATH: ROUTES.SCHOOL_STATUS_LIST,
      //   NAME: "Schools",
      // },
      // {
      //   PATH: ROUTES.UNIVERSITIES_STATUS_LIST,
      //   NAME: "Universities",
      // },
      {
        PATH: ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST,
        NAME: "Personal Trainer",
      },
      {
        PATH: ROUTES.ACTIVITIES_CAMPUS_STATUS_LIST,
        NAME: "Activities Camps",
      },
      {
        PATH: ROUTES.TUTORS_STATUS_LIST,
        NAME: "Tutor",
      },
    ],
  },
  {
    ICON: schools,
    ACTIVE_ICON: schools_active,
    PATH: ROUTES.MANAGE_SCHOOLS,
    NAME: "Manage Schools",
    SUB_MENU: [],
  },
  {
    ICON: university,
    ACTIVE_ICON: university_active,
    PATH: ROUTES.MANAGE_UNIVERSITIES,
    NAME: "Manage Universities",
    SUB_MENU: [
      {
        PATH: ROUTES.MANAGE_UNIVERSITIES,
        NAME: "Universities List",
      },
      {
        PATH: ROUTES.MANAGE_PROGRAMS,
        NAME: "Manage Programs",
      },
    ],
  },
  {
    ICON: user_inactive,
    ACTIVE_ICON: user_active,
    PATH: ROUTES.MANAGE_UNIVERSITIES,
    NAME: "Manage Users",
    SUB_MENU: [
      {
        PATH: ROUTES.MANAGE_USERS,
        NAME: "Users List",
      },
    ],
  },
  // {
  //   ICON: courses,
  //   ACTIVE_ICON: courses_active,
  //   PATH: ROUTES.MANAGE_COURSES,
  //   NAME: "Manage Courses",
  // },
  {
    ICON: recreational,
    ACTIVE_ICON: recreational_active,
    PATH: ROUTES.MANAGE_RECREATIONAL_CAMP,
    NAME: "Manage Recreational",
    SUB_MENU: [
      {
        PATH: ROUTES.RECREATIONAL_CATEGORY,
        NAME: "Categories",
      },
      {
        PATH: ROUTES.RECREATIONAL_KEYWORDS,
        NAME: "Keywords",
      },
      {
        PATH: ROUTES.MANAGE_RECREATIONAL_PERSONAL,
        NAME: "Personal Trainer",
      },
      {
        PATH: ROUTES.MANAGE_RECREATIONAL_CAMP,
        NAME: "Activities Camps",
      },
      /* {
        PATH: ROUTES.RECREATIONAL_SESSIONS,
        NAME: "Sessions",
      }, */
      {
        PATH: ROUTES.RECREATIONAL_BOOKINGS,
        NAME: "Bookings",
      },
      {
        PATH: ROUTES.RECREATIONAL_CANCELATIONS,
        NAME: "Cancelations",
      },
      {
        PATH: ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_LIST,
        NAME: "Promotional Content",
      },
    ],
  },
  {
    ICON: tutors,
    ACTIVE_ICON: tutors_active,
    PATH: ROUTES.MANAGE_TUTORS,
    NAME: "Manage Tutors",
    SUB_MENU: [
      {
        PATH: ROUTES.COURSE_CATEGOY_LIST,
        NAME: "Categories",
      },
      {
        PATH: ROUTES.COURSE_KEYWORDS,
        NAME: "Keywords",
      },
      {
        PATH: ROUTES.MANAGE_TUTORS,
        NAME: "Tutors",
      },
      /*   {
          PATH: ROUTES.TUTOR_SESSIONS,
          NAME: "Sessions",
        }, */
      {
        PATH: ROUTES.TUTOR_BOOKINGS,
        NAME: "Bookings",
      },
      {
        PATH: ROUTES.TUTOR_CANCELATIONS,
        NAME: "Cancelations",
      },
      {
        PATH: ROUTES.TUTOR_PROMOTIONAL_CONTENT_LIST,
        NAME: "Promotional Content",
      },
    ],
  },
  {
    ICON: tickets,
    ACTIVE_ICON: tickets_active,
    PATH: ROUTES.MANAGE_THEMEPARK,
    NAME: "Manage Themepark",
    SUB_MENU: [
      {
        PATH: ROUTES.MANAGE_THEMEPARK_CATEGORY,
        NAME: "Categories",
      },
      // {
      //   PATH: ROUTES.MANAGE_THEMEPARK_BANNERLIST,
      //   NAME: "Banner Image",
      // },
      {
        PATH: ROUTES.MANAGE_THEMEPARK,
        NAME: "Themeparks List",
      },
    ],
  },
  // {
  //   ICON: payment_inactive,
  //   ACTIVE_ICON: payment_active,
  //   PATH: ROUTES.PAYMENT_LIST,
  //   NAME: "Manage Payments",
  //   SUB_MENU: [
  //     {
  //       PATH: ROUTES.PAYMENT_LIST,
  //       NAME: "Payment History",
  //     },
  //     /* {
  //       PATH: ROUTES.COURSE_CATEGOY_LIST,
  //       NAME: "Payment History",
  //     } */
  //   ],
  // },
  /*  {
    ICON: tutors,
    ACTIVE_ICON: tutors_active,
    PATH: ROUTES.MANAGE_REPORT,
    NAME: "Manage Reports",
    SUB_MENU: [
    ],
  },
{
    ICON: invoice_inactive,
    ACTIVE_ICON: invoice_active,
    PATH: ROUTES.MANAGE_INVOICE,
    NAME: "Manage Invoice",
    SUB_MENU: [
    ],
  },
   {
    ICON: roles,
    ACTIVE_ICON: roles_active,
    PATH: ROUTES.MANAGE_ROLES,
    NAME: "Manage Roles",
    SUB_MENU: [],
  }, */
  {
    ICON: experts_inactive,
    ACTIVE_ICON: experts_active,
    PATH: ROUTES.MANAGE_EXPERTS,
    NAME: "Manage Experts",
    SUB_MENU: [
      {
        PATH: ROUTES.MANAGE_EXPERTS,
        NAME: "Experts List",
      },
      /* {
        PATH: ROUTES.REQUESTED_EXPERTS,
        NAME: "Experts Request",
      } */,
    ],
  },
  {
    ICON: discounts_inactive,
    ACTIVE_ICON: discounts_active,
    PATH: ROUTES.MANAGE_DISCOUNTS,
    NAME: "Manage Discounts",
    SUB_MENU: [
      {
        PATH: ROUTES.DISCOUNT_LIST,
        NAME: "Discounts",
      }
    ],
  },
  {
    ICON: content,
    ACTIVE_ICON: content_active,
    PATH: ROUTES.MANAGE_ROLES,
    NAME: "Manage Contents",
    SUB_MENU: [
      {
        PATH: ROUTES.HOME_PAGE_CONTENTS,
        NAME: "Homepage",
      },
    ],
  },
  {
    ICON: chat_inactive,
    ACTIVE_ICON: chat_active,
    PATH: ROUTES.MANAGE_CHAT,
    NAME: "Manage Chat",
    SUB_MENU: []
    /*  SUB_MENU: [
       {
         PATH: ROUTES.MANAGE_CHAT,
         NAME: "Chat",
       },
       {
         PATH: ROUTES.MANAGE_CHAT_REQUEST,
         NAME: "Support Request",
       },
     ], */
  },
];
const TOASTER_CONFIGS: any = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
const API_RESPONSE: API_RESPONSE_MODEL = {
  STATUS: "status",
  TRUE: true,
  FALSE: false,
  NULL: null
};
const LANGUAGES: LANGUAGES_MODEL = {
  ENGLISH: "English",
  ARABIC: "Arabic",
  ENGLISH_ABBRIVATION: "en",
  ARABIC_ABBRIVATION: "ar",
  LANGUAGE: "language",
};
const LOGIN_FIELDS: LOGIN_FIELDS_MODEL = {
  EMAIL: "email",
  PASSWORD: "password",
  REMEMBER_ME: "rememberMe",
};
const ADD_SCHOOLS: ADD_SCHOOLS_MODEL = {
  SCHOOL_NAME: "school_name",
  FOUNDED_YEAR: "founded_year",
  LOCATION: "address",
  CURRICULUM: "curriculam",
  SCHOOL_IMAGES: "images",
  WEBLINK_OF_SCHOOL: "web_link",
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  PRINCIPAL: "principal",
  GRADE_YEAR: "gradeYear",
  TUITION_FEES: "tuitionFees",
  OPTIONAL_SERVICE_FEES: "optionalServiceFees",
  ENROLLMENT_FEES: "enrollmentFees",
  RE_ENROLLMENT_FEES: "reEnrollmentFees",
  APPLICATION_FEES: "applicationFees",
  TOTAL_FEES: "totalFees",
  DISCOUNT_ON_TUITION_FEES: "discountOnTuitionFees",
  START_OF_THE_ACADEMIC_YEAR: "academic_year_start",
  END_OF_THE_ACADEMIC_YEAR: "academic_year_end",
  WINTER_BREAK_START_DATE: "winter_break_start",
  WINTER_BREAK_END_DATE: "winter_break_end",
  SPRING_BREAK_START_DATE: "spring_break_start",
  SPRING_BREAK_END_DATE: "spring_break_end",
  SUMMER_BREAK_START_DATE: "summer_break_start",
  SUMMER_BREAK_END_DATE: "summer_break_end",
  OTHER_HOLIDAYS: "other_holidays",
  PUBLIC_HOLIDAYS: "public_holidays",
  DSIB_INSPECTION_REPORT: "dsib_inspection_report",
  DSIB_RATING: "dsip_rating",
  RATING: "rating",
  RANKING: "ranking",
  DESCRIPTION: "description",
  MIN_GRADE_NAME: "min_grade_name",
  MAX_GRADE_NAME: "max_grade_name",
  EMIRATES: "emirate",
  CITY: "city",
  COUNTRY: "country"
};

const ADD_UNIVERSITY_FIELDS: ADD_UNIVERSITY_MODEL = {
  UNIVERSITY_NAME: "university_name",
  QUALITY_ASSURANCE: "quality_assurance",
  LOCATION: "address",
  UNIVERSITY_IMAGES: "images",
  WEBSITE_ADDRESS: "web_link",
  PRINCIPAL: "principal",
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  DEGREES_AND_FEES: "degreesAndFees",
  PROGRAMS_OFFERED: "programs_offered",
  TEACHING_RATING: "teaching_rating",
  EMPLOYABILITY_RATING: "employability_rating",
  RESEARCH: "research",
  RATING: "rating",
  INTERNATIONALIZATION_RATING: "international_rating",
  FACILITIES_RATING: "facility_rating",
  PROGRAMME_STRENGTH_RATING: "programme_strength_rating",
  INCLUSIVENESS_RATING: "inclusiveness_rating",
  ENVIRONMENTAL: "environmental",
  IMPACT_RATING: "impact_rating",
  OVERALL_RATING: "overall_rating",
  NO_OF_STUDENTS: "students_count",
  NO_OF_STUDENTS_SCHOOL: "student_count",
  TOTAL_GRADUATES: "total_graduates",
  NO_OF_EMIRATES: "emirates_count",
  STUDENTS: "student_percentage",
  NO_OF_EXPATS: "expats_percentage",
  MALE: "male",
  FEMALE: "female",
  TRANSGENDER: "transgender",
  TOTAL_EDUCATORS: "total_educators",
  TOTAL_COURSES: "courses",
  SHANGAI_RANKING: "shangai_ranking",
  GLOBAL_RANKING: "global_ranking",
  AVAILABLE_MEDIUM: "available_mediums",
  TOTAL_STUDENTS: "student_count",
  DESCRIPTION: "description",
  EMIRATES: "emirate",
  CITY: "city",
  QS_RANKING: "QS_ranking",
  THE_RANKING: "THE_ranking",
  SH_RANKING: "SH_ranking",
  COUNTRY: "country"
};

const VALIDATION_CONSTANT: any = {
  COMMISSION: "commission",
  MAX_PRICE: "max_price",
  MIN_PRICE: "min_price",
  MIN_EXPERIENCE: "min_experience",
  MAX_EXPERIENCE: "max_experience",
  RECREATION: "recreation",
  TRAINER: "trainer",
  COURSE: "course",
  VALID_NUMBER: "valid-number",
  VALID_COMMISSION: "valid-commission",
  KEY_TYPE: "Enter",
  TICKET_PRICE: "ticket_price",
  APPROVED: "approved",
  PENDING: "pending",
  REJECTED: "rejected",
  BLOCKED_USER: "blockUser",
  APPROVED_USER: "approveUser",
  UNIVERSITY: "university",
  SCHOOL: "school",
  TUTOR: "tutor",
  MIN_GRADE: "min_grade",
  MAX_GRADE: "max_grade",
  MIN_YEAR: "min_year",
  MAX_YEAR: "max_year",
  BUY_SLOTS: "buy_slots",
  CAMP: "camp"
};

const MANAGE_ROLES: any = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  EMAIL: 'email',
  PROFILE_IMAGE: "profile_image"

}

export const GLOBAL_CONSTANTS: any = {
  LANGUAGES: {
    ENGLISH: "English",
    ARABIC: "Arabic",
    ENGLISH_ABBRIVATION: "en",
    ARABIC_ABBRIVATION: "ar",
    LANGUAGE: "language",
  },
  LAYOUT_DIRECTION: {
    LTR: "ltr",
    RTL: "rtl",
  },
  REGEX: {
    EMAIL: new RegExp(
      "^[_a-z0-9-]+(\\.[_a-z0-9-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,4})$"
    ),
  },
  INITIAL_VALUES: {
    LEFT: "Left",
    RIGHT: "Right",
    ENTER: "Enter",
    TOAST_POSITION: "top-right",
    DATE_FORMAT: "YYYY-MM-DD",
    ZERO_INT: 0,
    ONE_INT: 1,
    TRUE: true,
    FALSE: false,
    TWO_INT: 2,
    THREE_INT: 3,
    FOUR_INT: 4,
    FIVE_INT: 5,
    SIX_INT: 6,
    FIFTY_INT: 50,
    STRING_ALL: "all",
    NEAR_ME: "near me",
    UP_ARROW: "ArrowUp",
    DOWN_ARROW: "ArrowDown",
    AND_SEARCH: "&search=",
    AND_ISTODAY: "&is_today=",
    ONLINE: "online",
    CARD: "card",
    NA: "N/A",
  },
};



const DIALOG_CONSTANT: any = {
  DELETE_CONTENT: "Are you sure want to delete this category?",
  DELETE_PROMOTIONAL_CONTENT: "Are you sure want to delete this content?",
  VAIDATION_CONTENT: "Please enter the reason",
  TITLE_BLOCK: "Reason for Blocking",
  TITLE_REJECTION: "Reason for Rejection",
  COURSE_REJECTION: "Are you sure want to reject this course?",
  SCHOOL_INVITE: "Are you sure want to invite this school?",
  UNIVERSITY_INVITE: "Are you sure want to invite this university?",
  CHANGE_UPLOAD_TYPE:
    "Chnaging upload type may delete previous uploads. Are you sure want to change upload type?",
  TUTOR_REJECT_REASON: "Reason for Rejection",
  ACTIVATE_DISCOUNT: "Are you sure want to activate this discount?",
  DEACTIVATE_DISCOUNT: "Are you sure want to deactivate this discount?",
  REFUND: "Are you sure want to make refund?"
};

const UPLOAD_TICKET_CONSTANT: any = {
  SINGLE_PDF: "single pdf",
  MULTIPLE_IMAGES: "multiple images",
  MULTIPLE_PDF: "multiple pdf",
  MANAGE_RECREATIONAL: "manage-recreational",
  MANAGE_TUTORS: "manage-tutors",
  IMAGE: "image",
  PDF: "pdf",
};

const SORTING_CONSTANT: any = {
  ASC: "asc",
  DSC: "dsc",
  ID_ASCENDING: "id_ascending",
  ID_DESCENDING: "id_decending",
  CATEGORY_ASCENDING: "category_ascending",
  CATEGORY_DESCENDING: "category_decending",
};

const TRANSLATION_CONSTANT: any = {
  COMMON: "common",
};

const LAYOUTS: LAYOUTS_MODEL = {
  RTL: "rtl",
  LTR: "ltr",
};
const ADMIN_ROLES: ADMIN_ROLES_MODEL = {
  ROLE: "1",
};
const SERVICES_STATUS: any = {
  APPROVED: "Approved",
  PENDING: "Pending",
  REJECTED: "Rejected",
  PERSONAL_TRAINER: "Personal Trainer",
  ACTIVITY_CAMP: "Activity Camp",
  DEGREE_TYPE: "Degree Type",
  FIELD_OF_STUDY: "Field of Study",
  SPECIFICATION: "Specification",
  TUTOR: "Tutor",
  RECREATIONAL: "Recreational"

};

const NOTIFICATION_CONSTANTS: any = {
  ONE_DAY: "1 day ago",
  ONE_HOUR: "1 hour ago",
  ONE_MINUTE: "1 minute ago",
  DAYS_AGO: "days ago",
  HOURS_AGO: "hours ago",
  MINUTES_AGO: "minutes ago",
  JUST_NOW: "just now",
  NEW: "new"
}

const PDF_CDN_LINK: any = {
  PDF_JS: "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js",
  // PDF_JS: "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js",
};
const TUTOR_VERIFICATION_STEPPER_LABEL = [
  "Personal and professional details",
  "Documents and Identity details",
  "Verify Subjects",
  "Summary",
];
const PERSONAL_VERIFICATION_STEPPER_LABEL = [
  "Personal and professional details",
  "Documents and Identity details",
  "Verify Activities",
  "Summary",
];
const CAMP_VERIFICATION_STEPPER_LABEL = [
  "Personal and professional details",
  "Documents and Identity details",
  "Verify Activities",
  "Summary",
];

const SCHOOLS_VERIFICATION_STEPPER_LABEL = [
  "School and Authority details",
  "Authority Documents and Identity details",
  "Summary",
];

const UNIVERSITY_VERIFICATION_STEPPER_LABEL = [
  "University and Authority details",
  "Authority Documents and Identity details",
  "Summary",
];

const ONBOARDING_ROUTES: any = {
  SCHOOLS_STATUS: "school-status-list",
  UNIVERSITY_STATUS: "universities-status-list",
  ACTIVITIES_PERSONAL_STATUS_LIST: "activities-personal-status-list",
  ACTIVITIES_CAMPUS_STATUS_LIST: "activities-campus-status-list",
};
const RECREATIONAL_ROUTES: any = {
  ACTIVITIES_PERSONAL_LIST: "manage-activities-personal-training",
  ACTIVITIES_CAMPUS_LIST: "manage-activities-camp",
};
const ACTIVITIES_TYPE: any = {
  PERSONAL: "personal",
  CAMP: "camp",
};

/**stepper theme */
const ColorlibConnector = styled(StepConnector)(({ theme }: any) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(90deg, #8136de 0%, #d670f5 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(90deg, #8136de 0%, #d670f5 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient(90deg, #8136de 0%, #d670f5 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient(90deg, #8136de 0%, #d670f5 100%)",
  }),
}));

/* Stepper Icons for tutor/recreational verification layout */
const ColorlibStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <img className="stepper_icons" src={applied} alt="Learn and Play" />,
    2: <img className="stepper_icons" src={review} alt="Learn and Play" />,
    3: <VerifiedUserIcon className="stepper_icons verify_icon" />,
    4: <img className="stepper_icons" src={verified} alt="Learn and Play" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

/* Activity Stepper Icons for tutor/recreational verification layout */
const ActivityStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <img className="stepper_icons" src={applied} alt="Learn and Play" />,
    2: <img className="stepper_icons" src={review} alt="Learn and Play" />,
    3: <VerifiedUserIcon className="stepper_icons verify_icon" />,
    4: <img className="stepper_icons" src={verified} alt="Learn and Play" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

/* Stepper Icons for schools/university verification layout */
const ColorlibStepIconSU = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <img className="stepper_icons" src={applied} alt="Learn and Play" />,
    2: <img className="stepper_icons" src={review} alt="Learn and Play" />,
    3: <img className="stepper_icons" src={verified} alt="Learn and Play" />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};


const COMMON_CONSTANTS: any = {
  TRUE: "True",
  FALSE: "False",
  START: "start",
  END: "end",
  ONLINE: "Online",
  ZERO: 0,
  ONE: 1,
  FOUR: 4,
  FIVE: 5,
  TWO: 2,
  THREE: 3,
  ELEVEN: 11,
  TWELVE: 12,
  TWENTY: 20,
  HUNDRED: 100,
  IS_SUPER_ADMIN: "isSuperAdmin",
  TYPE: "type",
  ROLE_ID: 'role',
  ID: "id",
  CREATE: "Create",
  EDIT: "Edit",
  PHONE_FORMAT: "Ex: xxx-xxx-xx",
  ALL: "all",
  HM_FORMAT: "HH:mm",
};

export {
  TOKENS,
  SIDEBAR,
  TOASTER_CONFIGS,
  API_RESPONSE,
  LANGUAGES,
  LOGIN_FIELDS,
  LAYOUTS,
  ADMIN_ROLES,
  ROUTES,
  DASHBOARD,
  ADD_SCHOOLS,
  ADD_UNIVERSITY_FIELDS,
  VALIDATION_CONSTANT,
  SORTING_CONSTANT,
  DIALOG_CONSTANT,
  TRANSLATION_CONSTANT,
  I18_TRANSLATION_COMMON,
  TOAST_ERRORS,
  BULK_UPLOAD_CSV_LINKS,
  BULK_UPLOAD_TYPE,
  SERVICES_STATUS,
  PDF_CDN_LINK,
  TUTOR_VERIFICATION_STEPPER_LABEL,
  CAMP_VERIFICATION_STEPPER_LABEL,
  PERSONAL_VERIFICATION_STEPPER_LABEL,
  UPLOAD_TICKET_CONSTANT,
  ONBOARDING_ROUTES,
  ACTIVITIES_TYPE,
  RECREATIONAL_ROUTES,
  ColorlibConnector,
  ColorlibStepIcon,
  ActivityStepIcon,
  COMMON_CONSTANTS,
  SCHOOLS_VERIFICATION_STEPPER_LABEL,
  UNIVERSITY_VERIFICATION_STEPPER_LABEL,
  ColorlibStepIconSU,
  MANAGE_ROLES,
  NOTIFICATION_CONSTANTS,
  NUMBER_CONSTANTS
};
