import { useNavigate, useParams } from "react-router-dom";
import { COMMON_CONSTANTS, I18_TRANSLATION_COMMON, NUMBER_CONSTANTS, ROUTES } from "../../../Utilities/Constants/Constants";
import { Container, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SetSessionStorageData, nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";
import { useEffect, useState } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import tutor_img from "../../../../Assets/users/TutorImg.svg";
import activitiesIcon from "../../../../Assets/users/activitiesIcon.svg";
import "./BookingDetail.scss";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import { useTranslation } from "react-i18next";
import TutorImg from "../../Assets/Tutor/TutorImg.svg";

const BookingDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [TutorHubList, setTutorHubList] = useState<any>([]);
    const [ActivityHubList, setActivityHubList] = useState<any>([]);
    const [ActiveList, setActiveList] = useState<any>(
      NUMBER_CONSTANTS.ONE
    );
    const [sortValue, setSortValue] = useState(
      COMMON_CONSTANTS.ALL
    );
    const [searchValue, setSearchValue] = useState("");
    const [isTodayTutor, setIsTodayTutor] = useState(false);
    const [isTodayActivity, setIsTodayActivity] = useState(false);
  
    const navigateDetailes = (value: any, state: any) => {
      /* navigate(ROUTES.TUTOR_HUB_DETAILES, {
        state: { Detailes_list: value, type: ActiveList, activeState: state },
      }); */
    //   SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, value?.session_creator_name);
    };
  
    /**Loader state*/
    const Loader: any = useSelector((state: any) => {
      return state.loaderReducer.loader;
    });
  
    useEffect(() => {
      getTutorHubData("", searchValue);
      getActivityHubData("", searchValue);
    //   SetSessionStorageData(AUTHENTICATION.SHARED.MY_PORTAL_NAME, '');
    }, [isTodayTutor, isTodayActivity]);
  
    /**Get Tutor Hub List*/
    const getTutorHubData = (value: any, search: any) => {
      axiosInstance
        .get(
          `${API_END_POINTS.TUTOR_HUB_LIST + `?id=${id}` /* +
        value +
          COMMON_CONSTANTS.INITIAL_VALUES.AND_SEARCH +
          search +
          COMMON_CONSTANTS.INITIAL_VALUES.AND_ISTODAY +
          isTodayTutor */
          }`
        )
        .then((Response: any) => {
          if (Response?.data?.status) {
            setTutorHubList(Response?.data?.result?.data);
          }
        })
        .catch(() => { });
    };
  
    /**Get Activity Hub List*/
    const getActivityHubData = (value: any, search: any) => {
      axiosInstance
        .get(
          `${API_END_POINTS.RECREATIONAL_HUB + `?id=${id}` /* +
          value +
          COMMON_CONSTANTS.INITIAL_VALUES.AND_SEARCH +
          search +
          COMMON_CONSTANTS.INITIAL_VALUES.AND_ISTODAY +
          isTodayActivity */
          }`
        )
        .then((Response: any) => {
          if (Response?.data?.status) {
            setActivityHubList(Response?.data?.result?.data);
          }
        })
        .catch(() => { });
    };
  
    const handleOpenNewTab = (url: any) => {
      // Open a new tab/window
      window.open(url, "_blank");
    };
  
    const handleChange = (event: SelectChangeEvent) => {
      if (event.target.value === COMMON_CONSTANTS.ALL) {
        setSortValue(event.target.value);
        if (ActiveList === NUMBER_CONSTANTS.ONE) {
          getTutorHubData("", searchValue);
        } else {
          getActivityHubData("", searchValue);
        }
      } else {
        setSortValue(event.target.value);
        if (ActiveList === NUMBER_CONSTANTS.ONE) {
          getTutorHubData(event.target.value, searchValue);
        } else {
          getActivityHubData(event.target.value, searchValue);
        }
      }
    };
  
    const handleSwitch = (val: any) => {
      setActiveList(val);
      getTutorHubData("", "");
      getActivityHubData("", "");
      setSortValue(COMMON_CONSTANTS.ALL);
      setSearchValue("");
    };
  
    const handleInputChange = () => {
      if (ActiveList === NUMBER_CONSTANTS.ONE) {
        getTutorHubData(
          sortValue == COMMON_CONSTANTS.ALL
            ? ""
            : sortValue,
          searchValue
        );
      } else {
        getActivityHubData(
          sortValue == COMMON_CONSTANTS.ALL
            ? ""
            : sortValue,
          searchValue
        );
      }
    };
  
    return (
      <>
        <React.Fragment>
          <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
          <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="arrow-wrap">
                  <img
                    alt="Learn and Play"
                    src={BackArrow}
                    className="EditSchool-backarrow"
                    onClick={() => {
                         navigate(-1);
                    }}
                  />
                  <h5 className="name-title mt-1 ms-1">{t("Booking Detail")}</h5>
                </div>
              </div>
            </div>
            
            <div className="Tutor_hup__container">
    
              <div className="header__section">
                <div className="left__contant">
                  <div
                    className={ActiveList === 1 ? "tutor__active" : "title"}
                    onClick={() => handleSwitch(1)}
                  >
                    Tutor Bookings
                  </div>
                  <div
                    className={ActiveList === 2 ? "Activity__active" : "title"}
                    onClick={() => handleSwitch(2)}
                  >
                    Activity Bookings
                  </div>
                </div>
                <div className="left__contant">
                  {/* <div className="ParentTag">
                    <input
                      type="text"
                      className="searchInput"
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyPress={(e: any) => {
                        if (e.code === "Enter") {
                          if (searchValue.trim() === "") {
                            e.preventDefault();
                            handleInputChange();
                          } else {
                            handleInputChange();
                            e.preventDefault();
                          }
                        }
                      }}
                      placeholder="search"
                    />
                    <img
                      src={search}
                      alt=""
                      className="searchIcon"
                      onClick={handleInputChange}
                    />
                  </div> */}

                 {/*  <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={sortValue}
                      label="Sort"
                      onChange={handleChange}
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={"new"}>New</MenuItem>
                      <MenuItem value={"in_progress"}>In Progress</MenuItem>
                      <MenuItem value={"completed"}>Completed</MenuItem>
                    </Select>
                  </FormControl> */}
                </div>
              </div>
              <div className="tutor_hub__card__container row">
                {ActiveList === NUMBER_CONSTANTS.ONE && (
                  <>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                      {/* <div
                        className={
                          isTodayTutor ? "is_today__tutor__active" : "  is_today"
                        }
                        onClick={() => setIsTodayTutor(!isTodayTutor)}
                      >
                        Today
                      </div> */}
                    </div>
                    {TutorHubList?.map((data: any) => {
                      return (
                        <>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="card_wapper">
                              <div className="Profile_contant">
                                <div
                                  className="tutor__contant"
                                  onClick={() =>
                                    navigateDetailes(
                                      data,
                                      NUMBER_CONSTANTS.ONE
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      nullUndefinedEmptyCheck(
                                        data?.session_creator_profile
                                      )
                                        ? data?.session_creator_profile
                                        : tutor_img
                                    }
                                    className="profile__Img"
                                    alt=""
                                  />
                                  <div className="d-flex flex-column">
                                    <div className="Profile__name__text">
                                      {data?.session_creator_name}
                                    </div>
                                    <div className="name__text">{data?.type}</div>
                                  </div>
                                </div>
  
                                <div className="right_options">
                                  {/* <div
                                    className="message__wapeer"
                                    onClick={() =>
                                      navigateDetailes(
                                        data,
                                        NUMBER_CONSTANTS.TWO
                                      )
                                    }
                                  >
                                    <div className="badgeIcon badeg__pos"></div>
                                    <img src={messageIcon} alt="" />
                                  </div> */}
                                  {data?.is_online && (
                                    <div
                                      className="video_call__wapeer"
                                      onClick={() =>
                                        navigateDetailes(
                                          data,
                                          NUMBER_CONSTANTS.ONE
                                        )
                                      }
                                    >
                                      <div className="badgeIcon badeg__pos"></div>
                                      {/* <img src={video_call_icon} alt="" /> */}
                                    </div>
                                  )}
                                </div>
                              </div>
  
                              <div
                                className="tutor__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="tutor__img__wapper">
                                  <img src={tutor_img} alt="" />
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="Black__text">
                                    {data?.session_name[0]}
                                  </div>
                                  <div className="name__text">
                                    {data?.session_creator_name}
                                  </div>
                                </div>
                              </div>
  
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="left__light__text">
                                  No. of Sessions
                                </div>
                                <div className="right__black__text">
                                  {data?.completed_slots_count}/
                                  {data?.total_slots_count}
                                </div>
                              </div>
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="left__light__text">Date </div>
                                <div className="right__black__text">
                                  {data?.date}
                                </div>
                              </div>
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="left__light__text">
                                  Booked Time{" "}
                                </div>
                                <div className="d-flex flex-column">
                                  {data?.time?.map((item: any) => {
                                    return (
                                      <div className="right__black__text">
                                        {item}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
  
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <button
                                  className={
                                    data?.status == "completed"
                                      ? "CompletedBtn"
                                      : "inprogressBtn"
                                  }
                                >
                                  {data?.status}
                                </button>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      nullUndefinedEmptyCheck(data?.profile)
                                        ? data?.profile
                                        : tutor_img
                                    }
                                    className="child_profile_img"
                                    alt=""
                                  />
                                  <div className="createdBy ms-2">
                                    {data?.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {TutorHubList?.length === 0  && ActiveList === 1 &&  <NoProducts/>}
                  </>
                )}
  
                {ActiveList === NUMBER_CONSTANTS.TWO && (
                  <>
                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                      <div
                        className={
                          isTodayActivity
                            ? "is_today__Activity__active"
                            : "  is_today"
                        }
                        onClick={() => setIsTodayActivity(!isTodayActivity)}
                      >
                        Today
                      </div>
                    </div> */}
                    {ActivityHubList?.map((data: any) => {
                      return (
                        <>
                          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                            <div className="card_wapper">
                              <div className="Profile_contant">
                                <div
                                  className="tutor__contant"
                                  onClick={() =>
                                    navigateDetailes(
                                      data,
                                      NUMBER_CONSTANTS.ONE
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      nullUndefinedEmptyCheck(
                                        data?.session_creator_profile
                                      )
                                        ? data?.session_creator_profile
                                        : tutor_img
                                    }
                                    className="profile__Img"
                                    alt=""
                                  />
                                  <div className="d-flex flex-column">
                                    <div className="Profile__name__text">
                                      {data?.session_creator_name}
                                    </div>
                                    <div className="name__text">{data?.type}</div>
                                  </div>
                                </div>
                                <div className="right_options">
                                 {/*  <div
                                    className="message__wapeer"
                                    onClick={() =>
                                      navigateDetailes(
                                        data,
                                        NUMBER_CONSTANTS.TWO
                                      )
                                    }
                                  >
                                    <div className="badgeIcon badeg__pos"></div>
                                    <img src={messageIcon} alt="" />
                                  </div> */}
                                  {data?.is_online && (
                                    <div
                                      className="video_call__wapeer"
                                      onClick={() =>
                                        handleOpenNewTab(data?.meet_link)
                                      }
                                    >
                                      <div className="badgeIcon badeg__pos"></div>
                                      {/* <img src={video_call_icon} alt="" /> */}
                                    </div>
                                  )}
                                </div>
                              </div>
  
                              <div
                                className="tutor__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="activity__img__wapper">
                                  <img src={activitiesIcon} alt="" />
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="Black__text">
                                    {data?.session_name}
                                  </div>
                                  <div className="name__text">
                                    {data?.session_creator_name}
                                  </div>
                                </div>
                              </div>
  
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="left__light__text">
                                  No. of Sessions
                                </div>
                                <div className="right__black__text">
                                  {data?.completed_slots_count}/
                                  {data?.total_slots_count}
                                </div>
                              </div>
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="left__light__text">Date </div>
                                <div className="right__black__text">
                                  {data?.date}
                                </div>
                              </div>
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <div className="left__light__text">
                                  Booked Time{" "}
                                </div>
                                <div className="d-flex flex-column">
                                  {data?.time?.map((item: any) => {
                                    return (
                                      <div className="right__black__text">
                                        {item}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
  
                              <div
                                className="row__contant"
                                onClick={() =>
                                  navigateDetailes(
                                    data,
                                    NUMBER_CONSTANTS.ONE
                                  )
                                }
                              >
                                <button
                                  className={
                                    data?.status == "completed"
                                      ? "CompletedBtn"
                                      : "inprogressBtn"
                                  }
                                >
                                  {data?.status}
                                </button>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      nullUndefinedEmptyCheck(data?.profile)
                                        ? data?.profile
                                        : tutor_img
                                    }
                                    className="child_profile_img"
                                    alt=""
                                  />
                                  <div className="createdBy ms-2">
                                    {data?.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
                {ActivityHubList?.length === 0   && ActiveList === 2 &&   <NoProducts/>}
              </div>
            </div>
          </Container>
        </React.Fragment>
      </>
    );

};
export default BookingDetail;