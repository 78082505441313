import { useState, useEffect } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import KeywordsList from "../../../Shared/ManageKeywords/KeywordsList/KeywordsList";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";

const CourseKeywordsList = ()=> {
   
    return(
        <>
        <KeywordsList apiEndpoint={API_END_POINTS.GET_CATEGORY_LIST} courseType={VALIDATION_CONSTANT.COURSE}/>
        </>
    )
};

export default CourseKeywordsList;