import { useEffect, useState } from "react";
import "./ViewThemepark.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { Card } from "@mui/material";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import { I18_TRANSLATION_COMMON, ROUTES } from "../../../Utilities/Constants/Constants";

const ViewThemepark = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);

  const getThemeParkDetail = () => {
    axiosInstance
      .get(API_END_POINTS.CREATE_THEMEPARK + "?id=" + id)
      .then((res) => {
        setData(res?.data?.result?.data[0]);
      });
  };

  useEffect(() => {
    getThemeParkDetail();
  }, []);
  const onNavigate = () => {
    navigate(ROUTES.MANAGE_THEMEPARK);
  };
  return (
    <div className="view_theme_park">
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              onClick={onNavigate}
            />
            <h5 className="name-title mt-1 ms-2">{t("Theme park details")}</h5>
          </div>
        </div>
      </div>
      <Card className="EditSchool-cardHead">
        <div className="EditSchool-cardTitle">{t("Basic Details")}</div>
        <div id="card-wrap">
          <div className="row">
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {t("Theme Park Name")}:
                </label>
                <input
                  className="Viewtag-input col-md-6"
                  value={data?.themepark_name}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {" "}
                  {t("Phone Number")}:
                </label>
                <input
                  className="Viewtag-input col-md-6"
                  value={data?.phone_number}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {t("Minimum Price:")}
                </label>
                <input
                  className="Viewtag-input col-md-6"
                  value={`${data?.min_price} AED`}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {t("Maximum Price:")}
                </label>
                <input
                  className="Viewtag-input col-md-6"
                  value={`${data?.max_price} AED`}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">{t("Rating")}:</label>
                <input
                  className="Viewtag-input col-md-6"
                  value={data?.rating}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">{t("Email")}:</label>
                <input
                  className="Viewtag-input-email col-md-6"
                  value={data?.email}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {t("Address")}:
                </label>
                {/* <input
                    className="Viewtag-input col-md-6"
                    value={data?.address}
                    disabled
                  /> */}
                <div
                  className="Viewtag-input ticket-desc col-md-6"
                //   value={data?.description}
                //   disabled
                >
                  {data?.address}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {t("Description")}:
                </label>
                <div
                  className="Viewtag-input ticket-desc col-md-6"
                //   value={data?.description}
                //   disabled
                >
                  {data?.description}
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="row">
                <label className="Viewtag-label col-md-6">
                  {t("Theme Park Cover Image")}:
                </label>
                <div className="col-md-6">
                  {data?.themepark_cover_images ? (
                    <img
                      className="BannerImglist "
                      src={data?.themepark_cover_images}
                    />
                  ) : ("")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card className="EditSchool-cardHead mt-3">
        <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
          {t("Highlights")}
        </div>
        <div id="highlight-card-wrap">
          <div className="row">
            {data?.highlights?.map((val: any, i: any) => {
              return (
                <ul className="highlight-list">
                  <li>{val}</li>
                </ul>
              );
            })}
          </div>
        </div>
      </Card>

      <Card className="EditSchool-cardHead mt-3">
        <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
          {t("Main Events")}
        </div>
        <div id="card-wrap">
          <div className="row">
            {data?.main_events?.map((val: any, i: any) => {
              return (
                <div className="col-md-6 mt-3">
                  <div className="row">
                    <div className="col-md-5">
                      <p className="ticket-event-name">{val.event_name}</p>
                    </div>
                    <div className="col-md-6">
                      <img
                        src={val.event_image}
                        className="ViewTickets-image"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Card>
      <Card className="EditSchool-cardHead mt-3">
        <div className="EditSchool-cardTitle EditSchool-cardTitleFees">
          {t("Ticket Types")}
        </div>
        <div id="card-wrap">
          {data?.ticket_type?.length > 0 && (
            <div className="row">
              {data?.ticket_type?.map((val: any, i: any) => {
                return (
                  <div className="col-md-6 mt-3">
                    <div className="row">
                      <div className="col-md-7">
                        <p className="ticket-event-name">Ticket type :  {val?.ticket_type}</p>
                        <p className="ticket-event-name">Ticket Price : {val?.ticket_price}</p>
                        <p className="ticket-event-name">Discount Price : {val?.discount_price}</p>
                        <p className="ticket-event-name">Description : {val?.description}</p>
                      </div>
                      <div className="col-md-5">
                        <img src={val?.image} className="ViewTickets-image" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {data?.ticket_type?.length === 0 && (
            <div className="row">
              <p className="no-ticket-msg">{t('No Tickets Available!')}</p>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ViewThemepark;
