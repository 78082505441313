import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import "./AddUniversity.scss";
import { ChangeEvent, useRef, useState, useEffect } from "react";
import {
  ADD_SCHOOLS,
  ADD_UNIVERSITY_FIELDS,
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  TOASTER_CONFIGS,
  TOAST_ERRORS,
} from "../../../Utilities/Constants/Constants";
import * as yup from "yup";
import {
  ADD_SCHOOLS_ERROR_MESSAGES,
  EDIT_UNIVERSITY_ERROR_MESSAGE,
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  S3DataGetApi,
  nullUndefinedEmptyCheck,
  numberOnlyWithDecimal,
} from "../../../Services/Helpers/Helper";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import backButton from "../../../../Assets/schools/back_button.svg";
import eyeIcon from "../../../../Assets/schools/eye.svg";
import uploadFile from "../../../../Assets/schools/upload.svg";
import upload_image from "../../../../Assets/schools/upload_image.svg";
import close from "../../../../Assets/schools/close.svg";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "libphonenumber-js";
import ar from "react-phone-input-2/lang/ar.json";
import { useSelector } from "react-redux";
import {
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
// import Autocomplete from "react-google-autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { GoogleMap, Marker, MarkerF, useLoadScript, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import RoomIcon from '@mui/icons-material/Room';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileViewerDialog from "../../../Shared/FileViewerDialog/FileViewerDialog";
import { format } from "date-fns";
import { store } from "../../../Services/Redux/Store/Store";
import { setLoader } from "../../../Services/Redux/Reducer/Reducer";

const libraries: any = ['places'];

const AddUniversity = () => {
  const { isLoaded }: any = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries
  });
  const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : '';
  const formikRef = useRef<any>();
  const { id } = useParams();
  const [currentLocation, setCurrentLocation]: any = useState();
  const [mapEnabled, setMapEnabled] = useState(API_RESPONSE.TRUE);
  const ratingTerms = [
    "Excellent",
    "Outstanding",
    "Good",
    "Acceptable",
    "Unsatisfactory"
  ]

  interface UnivercitysData {
    [key: string]: any;
  }
  const [universityDetail, setUniversityDetail] = useState<UnivercitysData>({
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME]: "",
    // [ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE]: "",
    [ADD_UNIVERSITY_FIELDS.LOCATION]: "",
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES]: "",
    [ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS]: "",
    [ADD_UNIVERSITY_FIELDS.EMAIL]: "",
    [ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]: "",
    [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.RATING]: id ? "N/A" : 0,
    [ADD_UNIVERSITY_FIELDS.EMIRATES]: "",
    [ADD_UNIVERSITY_FIELDS.CITY]: "",
    [ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM]: "",
    [ADD_UNIVERSITY_FIELDS.TOTAL_COURSES]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.PRINCIPAL]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.DESCRIPTION]: "",
    [ADD_SCHOOLS.FOUNDED_YEAR]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.QS_RANKING]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.THE_RANKING]: id ? "N/A" : null,
    [ADD_UNIVERSITY_FIELDS.SH_RANKING]: id ? "N/A" : null,

  });
  const inputRef = useRef<any>(null);
  const [appProcessPoints, setAppProcessPoints]: any = useState("");
  const [applicationProcess, setApplicationProcess] = useState<any>([]);
  const [eligibilityPoints, setEligibilityPoints]: any = useState("");
  const [eligibilityCriteria, setEligibilityCriteria] = useState<any>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [classificationType, setClassificationType] = useState(" ");
  const [courseName, setCourseName] = useState(" ");
  const [courseFee, setCourseFee] = useState("");
  const [programType, setProgramType] = useState(" ")
  const [programsOffered, setProgrammsOffered] = useState<any>([]);
  const [imageBanner, setImageBanner] = useState<any>(null);
  const bannerImage = useRef<HTMLInputElement | null>(null);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");
  const [subProgramName, setSubprogramName] = useState("");
  const [programName, setProgramName] = useState("");
  const [programOffered, setProgramOffered] = useState("");
  const currentYear = new Date().getFullYear();
  const [EmiratesList, setEmiratesList]: any = useState<any>([]);
  const [autocomplete, setAutocomplete] = useState<any>(null);

  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });
  const shouldDisableYear = (year: any) => {
    return new Date(year).getFullYear() > currentYear;
  };

  useEffect(() => {
    getUniversityFields();
    if (id)
      getUniversityDetail();

    getEmiratesList();
  }, []);

  useEffect(() => {
    // Get current user's location using the browser's Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
      );
    }
  }, [])

  const [universityImages, setUniversityImages]: any = useState([]);

  const [degreeType, setDegreeType] = useState([]);
  const [fieldType, setFieldType] = useState([]);
  const [specification, setSpecification] = useState([]);
  const navigate = useNavigate();

  const getUniversityFields = () => {
    axiosInstance
      .get(API_END_POINTS.DEGREE_TYPE)
      .then((response) => {
        setDegreeType(response.data.result.data);
      });

    axiosInstance
      .get(API_END_POINTS.FIELD_OF_STUDY)
      .then((response) => {
        setFieldType(response.data.result.data);
      });

    axiosInstance
      .get(API_END_POINTS.SPECIFICATION)
      .then((response) => {
        setSpecification(response.data.result.data);
      });
  };

  const getUniversityDetail = () => {
    axiosInstance
      .get(API_END_POINTS.UNIVERSITY_API + "?id=" + id)
      .then((res) => {
        setUniversityValues(res.data.result.data);
        setUniversityImages(res.data.result.data.images);
      });
  };

  const getEmiratesList = () => {
    axiosInstance.get(API_END_POINTS.EMIRATE_LIST).then((response) => {
      setEmiratesList(response?.data?.result?.data)
    }).catch(() => { })
  }


  const setUniversityValues = (data: any) => {
    setUniversityDetail({
      [ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME]: data.university_name,
      [ADD_UNIVERSITY_FIELDS.LOCATION]: data.address,
      [ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS]: data.web_link,
      [ADD_UNIVERSITY_FIELDS.PRINCIPAL]: data.principal,

      [ADD_UNIVERSITY_FIELDS.EMAIL]: data.email,
      [ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]: data?.phone_number
        ? data?.phone_number
        : "",
      [ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED]: data.programs_offered
        ? data.programs_offered
        : "",
      [ADD_UNIVERSITY_FIELDS.RESEARCH]: data.research ? data.research : "",
      [ADD_UNIVERSITY_FIELDS.RATING]: data.rating ? data.rating === "" ? 0 : data.rating : 0,
      [ADD_UNIVERSITY_FIELDS.OVERALL_RATING]: data.overall_rating
        ? data.overall_rating
        : "",
      [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS]: data.students_count != ""
        ? data.students_count
        : "N/A",
      [ADD_UNIVERSITY_FIELDS.EMIRATES]: data.emirate
        ? data.emirate
        : "",
      [ADD_UNIVERSITY_FIELDS.CITY]: data.city
        ? data.city
        : "",
      [ADD_UNIVERSITY_FIELDS.COUNTRY]: data?.country,
      [ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING]: data.global_ranking,
      [ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING]: data.shangai_ranking,
      [ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM]: data.available_mediums,
      [ADD_UNIVERSITY_FIELDS.TOTAL_COURSES]: data.courses ? data.courses : null,
      [ADD_UNIVERSITY_FIELDS.DESCRIPTION]: data.description,
      [ADD_SCHOOLS.FOUNDED_YEAR]: data.founded_year ? data.founded_year : "",
      [ADD_UNIVERSITY_FIELDS.QS_RANKING]: data.QS_ranking ? data.QS_ranking : null,
      [ADD_UNIVERSITY_FIELDS.SH_RANKING]: data.SH_ranking ? data.SH_ranking : null,
      [ADD_UNIVERSITY_FIELDS.THE_RANKING]: data.THE_ranking ? data.THE_ranking : null,
    });
    setImageBanner(data.academic_calendar);
    setApplicationProcess(data.application_process);
    setEligibilityCriteria(data.eligibility_criteria);
    setProgrammsOffered(data.program_offered);
    if (data.latitude)
      setCurrentLocation({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) })
  };
  const addUniversityDetail = (payload: any) => {
    // if (checkEmpty(eligibilityCriteria) && checkEmpty(applicationProcess) && checkEmpty(programsOffered) && imageBanner) {
    payload.images = universityImages;
    payload.program_offered = programsOffered;
    payload.eligibility_criteria = eligibilityCriteria;
    payload.application_process = applicationProcess;
    payload.academic_calendar = imageBanner;
    payload.latitude = currentLocation.lat;
    payload.longitude = currentLocation.lng;
    payload[ADD_UNIVERSITY_FIELDS.CITY] = payload[ADD_UNIVERSITY_FIELDS.COUNTRY] === 'United Arab Emirates' ? payload[ADD_UNIVERSITY_FIELDS.CITY] : "";
    payload[ADD_UNIVERSITY_FIELDS.EMIRATES] = payload[ADD_UNIVERSITY_FIELDS.COUNTRY] === 'United Arab Emirates' ? payload[ADD_UNIVERSITY_FIELDS.EMIRATES] : "";
    payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] = (payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS];
    payload[ADD_UNIVERSITY_FIELDS.RATING] = (payload[ADD_UNIVERSITY_FIELDS.RATING] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.RATING] === "") ? 0 : payload[ADD_UNIVERSITY_FIELDS.RATING];
    payload[ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING] = (payload[ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING];
    payload[ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING] = (payload[ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING];
    payload[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES] = (payload[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES];
    payload[ADD_UNIVERSITY_FIELDS.PRINCIPAL] = (payload[ADD_UNIVERSITY_FIELDS.PRINCIPAL] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.PRINCIPAL] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.PRINCIPAL];
    payload[ADD_UNIVERSITY_FIELDS.THE_RANKING] = (payload[ADD_UNIVERSITY_FIELDS.THE_RANKING] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.THE_RANKING] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.THE_RANKING];
    payload[ADD_UNIVERSITY_FIELDS.QS_RANKING] = (payload[ADD_UNIVERSITY_FIELDS.QS_RANKING] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.QS_RANKING] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.QS_RANKING];
    payload[ADD_UNIVERSITY_FIELDS.SH_RANKING] = (payload[ADD_UNIVERSITY_FIELDS.SH_RANKING] === "N/A") || (payload[ADD_UNIVERSITY_FIELDS.SH_RANKING] === "") ? null : payload[ADD_UNIVERSITY_FIELDS.SH_RANKING];
    payload.courses = nullUndefinedEmptyCheck(payload.courses) ? payload.courses : null;
    payload.rating = payload.rating === "" ? 0 : payload.rating;
    if (id) {
      axiosInstance
        .put(API_END_POINTS.ADD_UNIVERSITY + "?id=" + id, payload)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message, TOASTER_CONFIGS);
            navigate(ROUTES.MANAGE_UNIVERSITIES);
          }
        }).catch((error) => { console.log(error) });
    } else {
      axiosInstance.post(API_END_POINTS.ADD_UNIVERSITY, payload).then((res) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          navigate(ROUTES.MANAGE_UNIVERSITIES);
        }
      }).catch((error) => { console.log(error) });
    }

    // }

  };
  const [validNumber, setValidNumber] = useState(true);
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const universityValidationSchema = yup.object({
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME]: yup
      .string()
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.UNIVERSITY_NAME),
    /* [ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE]: yup
      .string()
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.QUALITY_ASSURANCE), */
    [ADD_UNIVERSITY_FIELDS.LOCATION]: yup
      .string()
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.LOCATION_REQUIRED),
    [ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES]: yup
      .array()
      .typeError("")
      .min(1, EDIT_UNIVERSITY_ERROR_MESSAGE.UNIVERSITY_IMAGES),
    [ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS]: yup
      .string()
      .matches(REGEX.URL_VALIDATION, EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_URL)
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.WEBSITE_ADDRESS),

    // [ADD_UNIVERSITY_FIELDS.PRINCIPAL]: yup
    //   .string()
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.PRINCIPAL),

    [ADD_UNIVERSITY_FIELDS.EMAIL]: yup
      .string()
      .matches(REGEX.EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.EMAIL_REQUIRED),
    //   .email(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.INVALID_EMAIL),
    [ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]: yup
      .string()
      // .matches(REGEX.PHONE_VALIDATION, ERROR_MESSAGES.PHONE_NUMBER_FORMAT)
      .required(ERROR_MESSAGES.PHONE_NUMBER),
    [ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES]: yup.string(),
    // [ADD_SCHOOLS.FOUNDED_YEAR]: yup
    //   .string()
    //   .required(ADD_SCHOOLS_ERROR_MESSAGES.FOUNDED_YEAR_REQUIRED),
    /*  [ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED]: yup.string()
        //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),
        .required(EDIT_UNIVERSITY_ERROR_MESSAGE.PROGRAMS_OFFERED),
  
       [ADD_UNIVERSITY_FIELDS.TEACHING_RATING]: yup
        .number()
        .min(0)
        .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
        //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
        .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TEACHING_RATING)
        .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),
  
      [ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING]: yup
        .number()
        .min(0)
        .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
        //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
        .required(EDIT_UNIVERSITY_ERROR_MESSAGE.EMPLOYABILITY_RATING)
        .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),
  
      [ADD_UNIVERSITY_FIELDS.RESEARCH]: yup
        .number()
        // .min(0)
        // .max(5,EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
        //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
        .required(EDIT_UNIVERSITY_ERROR_MESSAGE.RESEARCH)
        .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT), */

    // [ADD_UNIVERSITY_FIELDS.RATING]: yup
    //   .number()
    //   .min(0)
    //   .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
    // .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS)
    // .required(EDIT_UNIVERSITY_ERROR_MESSAGE.RATING),

    [ADD_UNIVERSITY_FIELDS.DESCRIPTION]: yup
      .string()
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.DESCRIPTION_REQUIRED),
    [ADD_UNIVERSITY_FIELDS.EMIRATES]: yup
      .string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.EMIRATES),
    [ADD_UNIVERSITY_FIELDS.CITY]: yup
      .string(),
    // .required(ADD_SCHOOLS_ERROR_MESSAGES.CITY),
    [ADD_UNIVERSITY_FIELDS.COUNTRY]: yup
      .string()
      .required(ADD_SCHOOLS_ERROR_MESSAGES.COUNTRY),
    /* [ADD_UNIVERSITY_FIELDS.QS_RANKING]: yup
      .string(),
    [ADD_UNIVERSITY_FIELDS.SH_RANKING]: yup
      .string(),
    [ADD_UNIVERSITY_FIELDS.THE_RANKING]: yup
      .string(),
       */

    /* [ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.INTERNATIONALIZATION_RATING)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    [ADD_UNIVERSITY_FIELDS.FACILITIES_RATING]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),,
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.FACILITIES_RATING)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    [ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      //   .typeError(ADD_UNIVERSITY_FIELDS_ERROR_MESSAGES.ONLY_NUMBERS),
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.PROGRAMME_STRENGTH_RATING)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    [ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.INCLUSIVENESS_RATING)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    [ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.ENVIRONMENTAL)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    [ADD_UNIVERSITY_FIELDS.IMPACT_RATING]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.IMPACT_RATING)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    [ADD_UNIVERSITY_FIELDS.OVERALL_RATING]: yup
      .number()
      .min(0)
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.OVERALL_RATING)
      .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),
 */

    // [ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS]: yup
    //   .string()
    //   .matches(/^[0-9]+$/, ADD_SCHOOLS_ERROR_MESSAGES.VALID_STUDENTS)
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.NO_OF_STUDENTS),

    /*  [ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES]: yup
       .string()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TOTAL_GRADUATES),
 
     [ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES]: yup
       .string()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.NO_OF_EMIRATES),
 
     // [ADD_UNIVERSITY_FIELDS.STUDENTS]: yup
     //   .string()
     //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.STUDENTS),
 
     [ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS]: yup
       .string()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.NO_OF_EXPATS),
     [ADD_UNIVERSITY_FIELDS.MALE]: yup
       .number()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.MALE),
     [ADD_UNIVERSITY_FIELDS.FEMALE]: yup
       .number()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.FEMALE),
     [ADD_UNIVERSITY_FIELDS.TRANSGENDER]: yup
       .number()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TRANSGENDER),
     [ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS]: yup
       .string()
       .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TOTAL_EDUCATORS), */

    // [ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING]: yup
    //   .string()
    //   .matches(/^[0-9]+$/, EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_GLOBAL_RANKING)
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.GLOBAL_RATING),

    // .typeError(EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_FORMAT),

    // [ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING]: yup
    //   .string()
    //   .matches(/^[0-9]+$/, EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_SHANGAI_RANKING)
    //   .required(EDIT_UNIVERSITY_ERROR_MESSAGE.SHANGAI_RATING),

    [ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM]: yup
      .string()
      .required(EDIT_UNIVERSITY_ERROR_MESSAGE.AVAILABLE_MEDIUM),
    /* [ADD_UNIVERSITY_FIELDS.TOTAL_COURSES]: yup
      .string()
      .matches(/^[0-9]+$/, EDIT_UNIVERSITY_ERROR_MESSAGE.VALID_TOTAL_COURSES) */
    // .required(EDIT_UNIVERSITY_ERROR_MESSAGE.TOTAL_COURSES),
  });

  const uploadUniversityImages = useRef<HTMLInputElement | null>(null);

  const ratingRestriction = (event: any) => {
    const enteredValue = event.target.value + event.data;
    const parsedValue = parseFloat(enteredValue);

    if (parsedValue > 5) {
      event.preventDefault();
    } else {
      numberOnlyWithDecimal(event);
    }
  };
  useEffect(() => {
    formikRef.current.setFieldValue(
      ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES,
      universityImages
    );
  }, [universityImages]);
  const handleChange = async (event: any) => {
    // if (event.target.files[0].type.split('/')[0] === 'image') {
    const files = event.target.files;
    let typeValidation: any = Array.from(files).map((file: any) => {
      return file.type.split('/')[0] === "image"
    });
    try {
      if (universityImages.length + event.target.files.length <= 10 && !typeValidation.includes(false) && event.target.files.length !== 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          store.dispatch(setLoader(true));
          const uploadedImage = await S3DataGetApi(event);
          // schoolImages.length <=5  ?
          setTimeout(() => {
            setUniversityImages((schoolImages: any) => [
              ...schoolImages,
              uploadedImage,
            ]);
            store.dispatch(setLoader(false));
          }, 0);
        }
      } else {
        if (event.target.files.length !== 0)
          universityImages.length + event.target.files.length >= 10 ? toast.error(TOAST_ERRORS.IMAGES_LIMIT) : toast.error("Please upload only images");
      }
    } catch (error) {
      toast.error(`${TOAST_ERRORS.IMAGE_UPLOAD_ERROR}` + `${error}`);
    }

    // }
  };

  /* Updating event image */
  const onUploadEventImage = async (event: any, eventName: string) => {
    try {
      if (event.target.files[0].type.split("/")[1] === "pdf") {
        store.dispatch(setLoader(true));
        const uploadedImage = await S3DataGetApi(event);
        setImageBanner(uploadedImage);
        store.dispatch(setLoader(false));
      } else {
        // toast.error(THEMEPARK_MESSAGES.IMAGE_FORMAT);
      }
    } catch (error) { }
  };

  const onAddProgram = () => {
    let data = programsOffered;
    if (nullUndefinedEmptyCheck(programType) && nullUndefinedEmptyCheck(classificationType) && nullUndefinedEmptyCheck(courseName) && nullUndefinedEmptyCheck(courseFee)) {
      data.push({
        id: 0,
        program_id: programType,
        program_class_name: programOffered,
        university_program_name: programName,
        program_classification_id: classificationType,
        sub_program_class_name: subProgramName,
        sub_program_name_id: courseName,
        // sub_program_name: courseName,
        price: courseFee,
      });
      setProgrammsOffered([...data]);
      setClassificationType(" ");
      setCourseName(" ");
      setCourseFee("");
      setProgramType(" ");
      setProgramName("");
      setSubprogramName("");
    }

  };

  /* Delete tag from taglist */
  const handleDelete = (i: any) => () => {
    setUniversityImages(
      universityImages.filter((item: any, index: any) => index !== i)
    );
  };

  const setCurrentLatLng = (latitude: any, longitude: any) => {
    setCurrentLocation({ lat: latitude, lng: longitude });
  }

  const getFormattedAddress = (lat: any, lng: any, formik: any, check: boolean) => {
    geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
      if (status === "OK") {
        if (results[COMMON_CONSTANTS.ZERO]) {
          let filterCountry = results[COMMON_CONSTANTS.ZERO]?.address_components?.filter((res: any) => {
            return res.types[0] === 'country'
          });

          if (results[COMMON_CONSTANTS.ZERO]) {
            if (check) {
              formik.setFieldValue(
                ADD_UNIVERSITY_FIELDS.LOCATION,
                results[COMMON_CONSTANTS.ZERO].formatted_address
              )
            }
            formik.setFieldValue(
              ADD_UNIVERSITY_FIELDS.COUNTRY,
              filterCountry[0]?.long_name ? filterCountry[0]?.long_name : "N/A"
            )
            //  setAddressData(results[COMMON_CONSTANTS.ZERO].address_components,formik)
          }
        }
      }
    });
  }


  /**Handle file viewer open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  /**Handle file viewer close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(false);
    setViewerFile("");
  };

  /* To restrict inputs for only numbers */
  function numberOnly(event: any) {
    const input = event.target;
    const value = input.value;

    // Remove non-digit and non-decimal characters except the first dot
    const sanitizedValue = value.replace(
      /[^\d.]/g,
      (match: any, offset: any) => {
        if (match === "." && offset === 0) {
          return match;
        }
        return "";
      }
    );

    // Limit to two digits after the decimal point
    const decimalIndex = sanitizedValue.indexOf(".");
    if (decimalIndex !== -1 && sanitizedValue.length - decimalIndex > 2 + 1) {
      input.value = sanitizedValue.slice(0, decimalIndex + 3);
    } else {
      input.value = sanitizedValue;
    }
  }

  const onPlaceChanged = (formik: any) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place?.geometry) {
        const location = place?.geometry?.location;
        const lat = location.lat();
        const lng = location.lng();

        // Set lat/lng after fetching
        setCurrentLatLng(lat, lng);
        getFormattedAddress(lat, lng, formik, false);
        if (inputRef?.current) {
          const inputValue = inputRef.current.value;
          formik.setFieldValue(ADD_UNIVERSITY_FIELDS.LOCATION, inputValue);
        }
      }
    }
  };

  const onAutocompleteLoad = (autoC: any) => {
    setAutocomplete(autoC); // Save the autocomplete instance
  };

  return (
    <>
      <FileViewerDialog
        open={fileViewerOpen}
        handleClose={onFilerViewerDialogClose}
        viewerFile={viewerFile}
      ></FileViewerDialog>
      <Formik
        innerRef={formikRef}
        initialValues={universityDetail}
        validationSchema={universityValidationSchema}
        onSubmit={(values, actions) => {
          addUniversityDetail(values);
        }}
        enableReinitialize={true}
      >
        {(formik) => (
          <div className="school_fields_container">
            <div className="arrow-wrap">
              <img
                className="school-back"
                onClick={() => {
                  navigate(ROUTES.MANAGE_UNIVERSITIES);
                }}
                src={backButton}
                alt="Learn and Play"
              />
              <h5 className="name-title">
                {id ? t("Edit University") : t("Add University")}
              </h5>
            </div>
            <div className="row school-row-wrap">
              <div id="card-title">{t("Basic Details")}</div>
              {/* ************************Form Content Right*********************** */}
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">
                      {t("University Name")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("University Name")}
                      name={ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[
                            ADD_UNIVERSITY_FIELDS.UNIVERSITY_NAME
                            ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrappers">
                    <label className="fields_label mandatory_fields">{t("Location")}</label>
                    <div className="address-wrapper">
                      {isLoaded && (
                        // <Autocomplete
                        //   className="auto-complete"
                        //   id={ADD_UNIVERSITY_FIELDS.LOCATION}
                        //   apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                        //   value={formik.values[ADD_UNIVERSITY_FIELDS.LOCATION]}
                        //   onBlur={formik.handleBlur}
                        //   onChange={(e: any) => {
                        //     formik.setFieldValue(
                        //       ADD_UNIVERSITY_FIELDS.LOCATION,
                        //       e.target.value
                        //     );
                        //   }}
                        //   onPlaceSelected={(place: any) => {
                        //     setCurrentLatLng(
                        //       place.geometry.location.lat(),
                        //       place.geometry.location.lng()
                        //     );
                        //     getFormattedAddress(
                        //       place.geometry.location.lat(),
                        //       place.geometry.location.lng(),
                        //       formik
                        //     );
                        //   }}
                        // />

                        <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={() => onPlaceChanged(formik)}>
                          <input
                            type="text"
                            className="auto-complete"
                            placeholder="Enter a place"
                            ref={inputRef}
                            name={ADD_UNIVERSITY_FIELDS.LOCATION}
                            value={formik.values[ADD_UNIVERSITY_FIELDS.LOCATION]} // Bind formik value
                            onChange={formik.handleChange}
                          />
                        </Autocomplete>

                      )}
                      {mapEnabled && (
                        <RoomIcon
                          className="room-icon"
                          onClick={() => {
                            setMapEnabled(!mapEnabled);
                          }}
                        />
                      )}
                      {!mapEnabled && (
                        <LocationOffIcon
                          className="room-icon"
                          onClick={() => {
                            setMapEnabled(!mapEnabled);
                          }}
                        />
                      )}
                    </div>
                    {isLoaded && mapEnabled && (
                      <GoogleMap
                        mapContainerClassName="map-container"
                        center={currentLocation}
                        zoom={10}
                      >
                        <MarkerF
                          position={currentLocation}
                          draggable={true}
                          onDragEnd={(e: any) => {
                            setCurrentLatLng(e.latLng.lat(), e.latLng.lng());
                            getFormattedAddress(
                              e.latLng.lat(),
                              e.latLng.lng(),
                              formik, true
                            );
                          }}
                        />
                      </GoogleMap>
                    )}
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.LOCATION] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.LOCATION] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[ADD_UNIVERSITY_FIELDS.LOCATION]}`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Principal's Name")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Principal")}
                      name={ADD_UNIVERSITY_FIELDS.PRINCIPAL}
                      onKeyPress={(event: any) => {
                        //  validateEnglish(event)
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.PRINCIPAL] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.PRINCIPAL] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[ADD_UNIVERSITY_FIELDS.PRINCIPAL]}`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Total Courses")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Total Courses")}
                      name={ADD_UNIVERSITY_FIELDS.TOTAL_COURSES}
                      onKeyPress={(event: any) => {
                        //  validateEnglish(event)
                      }}
                    />
                    {/* <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_UNIVERSITY_FIELDS.TOTAL_COURSES]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Rating")}</label>
                    <Field
                      className="basic-select-menu"
                      placeholder={"Rating"}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.RATING}
                    />

                    {/* <Field className="basic-select-menu" as="select" defaultValue={" "} name={ADD_UNIVERSITY_FIELDS.RATING}>
                            <option value=" ">Please select the rating</option>
                            {
                              ratingTerms.map((rating:any,index:any)=>{
                                return(
                                  <option value={rating}>{rating}</option>
                                )
                              
                              })
                            }
                            
                          </Field> */}
                    {/*  <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.RATING]}`)}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  {/* <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Shangai Ranking")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Shangai Ranking")}
                      maxLength={6}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.SHANGAI_RANKING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                  {/* For New QS_ranking */}
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("QS world ranking")}</label>
                    <Field
                      className="basic-select-menu"
                      placeholder={"QS world ranking"}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.QS_RANKING}
                      value={formik.values[ADD_UNIVERSITY_FIELDS.QS_RANKING]}
                    // value={formik.values[ADD_UNIVERSITY_FIELDS.QS_RANKING] ? formik.values[ADD_UNIVERSITY_FIELDS.QS_RANKING] : "N/A"}
                    />
                    <div className="error-message-wrap"></div>
                    {/* <Field className="basic-select-menu" as="select" defaultValue={" "} name={ADD_UNIVERSITY_FIELDS.RATING}>
                            <option value=" ">Please select the rating</option>
                            {
                              ratingTerms.map((rating:any,index:any)=>{
                                return(
                                  <option value={rating}>{rating}</option>
                                )
                              
                              })
                            }
                            
                          </Field> */}
                    {/* <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.RATING]}`)}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  {/* For THE ranking */}
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("The Higher Education Ranking")}</label>
                    <Field
                      className="basic-select-menu"
                      placeholder={"The Higher Education Ranking"}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.THE_RANKING}
                    />
                    <div className="error-message-wrap"></div>
                    {/* <Field className="basic-select-menu" as="select" defaultValue={" "} name={ADD_UNIVERSITY_FIELDS.RATING}>
                            <option value=" ">Please select the rating</option>
                            {
                              ratingTerms.map((rating:any,index:any)=>{
                                return(
                                  <option value={rating}>{rating}</option>
                                )
                              
                              })
                            }
                            
                          </Field> */}
                    {/* <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.RATING]}`)}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  {/* 3 */}
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Shanghai Ranking")}</label>
                    <Field
                      className="basic-select-menu"
                      placeholder={"Shanghai Rankings"}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.SH_RANKING}
                    />
                    <div className="error-message-wrap"></div>
                    {/* <Field className="basic-select-menu" as="select" defaultValue={" "} name={ADD_UNIVERSITY_FIELDS.RATING}>
                            <option value=" ">Please select the rating</option>
                            {
                              ratingTerms.map((rating:any,index:any)=>{
                                return(
                                  <option value={rating}>{rating}</option>
                                )
                              
                              })
                            }
                            
                          </Field> */}
                    {/* <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.RATING]}`)}
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Academic Calender")}
                    </label>
                    <div className="grade-pdf-wrapper">
                      <input
                        className="form-input grade-fee-input"
                        value={
                          imageBanner?.split("/")[
                          imageBanner?.split("/").length - 1
                          ]
                        }
                        disabled
                        placeholder="Upload Academic Calender"
                      />
                      <input
                        className="image_input"
                        type="file"
                        accept="application/pdf"
                        onChange={(e: any) => {
                          onUploadEventImage(e, "banner");
                        }}
                        ref={bannerImage}
                      />
                      <div className="upload-icon">
                        {imageBanner && (
                          <VisibilityIcon
                            onClick={() => {
                              window.open(imageBanner, "_blank");
                            }}
                          />
                        )}
                        <FileUploadIcon
                          onClick={() => bannerImage.current?.click()}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {/* ***************************For right for fortion************************ */}
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Email")}</label>
                    <Field
                      className="form_inputs"
                      // placeholder={t("Email")}
                      name={ADD_UNIVERSITY_FIELDS.EMAIL}
                      placeholder="e.g., name@example.com"
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.EMAIL] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.EMAIL] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.EMAIL]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">{t("Phone Number")}</label>
                    <PhoneInput
                      inputProps={{
                        className: "",
                        name: `${ADD_UNIVERSITY_FIELDS.PHONE_NUMBER}`,
                      }}
                      country={"ae"}
                      enableSearch={true}
                      countryCodeEditable={true} // Ensures country code is fixed
                      value={formik.values[ADD_UNIVERSITY_FIELDS.PHONE_NUMBER]} // Make sure this is updated
                      onChange={(e) => {
                        // Update the form field with the full number
                        formik.setFieldValue(ADD_UNIVERSITY_FIELDS.PHONE_NUMBER, e);

                        // Optional: Validate the field
                        setTimeout(() => {
                          formik.validateField(ADD_UNIVERSITY_FIELDS.PHONE_NUMBER);
                          setValidNumber(isValidPhoneNumber(e)); // Validate number after setting
                        }, 0);
                      }}
                    />
                    <p className="mb-0 phone-validation">{COMMON_CONSTANTS.PHONE_FORMAT}</p>
                    <div className="error-message-wrap">
                      {validNumber === false &&
                        formik.touched[ADD_UNIVERSITY_FIELDS.PHONE_NUMBER] ? (
                        <div className="errorMessage">
                          {t(
                            EDIT_UNIVERSITY_ERROR_MESSAGE.INVALID_PHONE_NUMBER
                          )}
                        </div>
                      ) : null}
                      {formik.values[ADD_UNIVERSITY_FIELDS.PHONE_NUMBER] ==
                        "" &&
                        validNumber === true &&
                        formik.touched[ADD_UNIVERSITY_FIELDS.PHONE_NUMBER] ? (
                        <div className="errorMessage">
                          {t(
                            EDIT_UNIVERSITY_ERROR_MESSAGE.PHONE_NUMBER_REQUIRED
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">
                      {t("Weblink of University")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder="e.g., https://universityname.com"
                      name={ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[
                            ADD_UNIVERSITY_FIELDS.WEBSITE_ADDRESS
                            ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Number of Students")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Number of Students")}
                      onInput={numberOnly}
                      maxLength={6}
                      name={ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[
                            ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS
                            ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Global Ranking")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Global Ranking")}
                      onInput={numberOnly}
                      maxLength={6}
                      name={ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[
                            ADD_UNIVERSITY_FIELDS.GLOBAL_RANKING
                            ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label mandatory_fields">
                      {t("Language of Instruction")}
                    </label>
                    <div className="select-wrapper">
                      <Field
                        as="select"
                        className="form_inputs"
                        placeholder={t(
                          `${ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM}`
                        )}
                        name={ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM}
                      >
                        <option value="">Please select available medium</option>
                        <option value="Arabic">Arabic</option>
                        <option value="English">English</option>
                        {/* <option value="Both">Both</option> */}
                      </Field>
                    </div>

                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[
                            ADD_UNIVERSITY_FIELDS.AVAILABLE_MEDIUM
                            ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Founded Year")}</label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className="date_pickers"
                        label={t("Founded Year")}
                        shouldDisableYear={shouldDisableYear}
                        openTo="year"
                        views={["year"]}
                        // value={
                        //   new Date(formik.values[ADD_SCHOOLS.FOUNDED_YEAR])
                        // }
                        value={
                          formik.values[ADD_SCHOOLS.FOUNDED_YEAR] ?
                            new Date(formik.values[ADD_SCHOOLS.FOUNDED_YEAR])
                            : null
                        }
                        onChange={(date) => {
                          const formattedDate = date
                            ? format(date, "yyyy")
                            : "";
                          formik.setFieldValue(
                            ADD_SCHOOLS.FOUNDED_YEAR,
                            formattedDate
                          );
                        }}
                      /* onChange={(foundedYear: any) =>
                    formik.setFieldValue(
                      ADD_SCHOOLS.FOUNDED_YEAR,
                      foundedYear
                    )
                  } */
                      />
                    </LocalizationProvider>
                    <div className="error-message-wrap">
                      {formik.touched[ADD_SCHOOLS.FOUNDED_YEAR] &&
                        formik.errors[ADD_SCHOOLS.FOUNDED_YEAR] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_SCHOOLS.FOUNDED_YEAR]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="form-label mandatory_fields">{t("Country")}</label>
                    {/* <div className="select-wrapper"> */}
                    <Field
                      name={ADD_UNIVERSITY_FIELDS.COUNTRY}
                      value={formik.values[ADD_UNIVERSITY_FIELDS.COUNTRY]}
                      onBlur={formik.handleBlur}
                      placeholder={"Country"}
                      className="basic-select-menu"
                      disabled
                    />
                    {/* </div> */}


                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.CITY] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.CITY] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.CITY]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>


                  <div className="fields_label_wrapper">
                    <label className="form-label">{t("States/Emirates")}</label>
                    <div className="select-wrapper">
                      <Field
                        as="select"
                        name={ADD_UNIVERSITY_FIELDS.EMIRATES}
                        value={formik.values[ADD_UNIVERSITY_FIELDS.EMIRATES]}
                        onBlur={formik.handleBlur}
                        className="basic-select-menu"
                        // disabled={mode === MODES.VIEW ? true : false}

                        onChange={(e: any) => {
                          formik.setFieldValue(ADD_UNIVERSITY_FIELDS.EMIRATES, e.target.value)
                        }}
                      >
                        <option value={" "}>{"Please select the emirate"}</option>
                        {formik.values[ADD_UNIVERSITY_FIELDS.COUNTRY] === 'United Arab Emirates' && EmiratesList?.map((item: any) => {
                          return (
                            <option value={item.emirate_city}>{item.emirate_city}</option>
                          )
                        })}
                      </Field>
                    </div>


                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.EMIRATES] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.EMIRATES] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.EMIRATES]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="form-label">{t("City")}</label>
                    <div className="select-wrapper">
                      <Field
                        as="select"
                        name={ADD_UNIVERSITY_FIELDS.CITY}
                        value={formik.values[ADD_UNIVERSITY_FIELDS.CITY]}
                        onBlur={formik.handleBlur}
                        className="basic-select-menu"
                        onChange={(e: any) => {
                          formik.setFieldValue(ADD_UNIVERSITY_FIELDS.CITY, e.target.value)
                        }}
                      >
                        <option value={" "}>{"Please select the city"}</option>
                        {formik.values[ADD_UNIVERSITY_FIELDS.COUNTRY] === 'United Arab Emirates' && EmiratesList?.map((item: any) => {
                          return (
                            <option value={item.emirate_city}>{item.emirate_city}</option>
                          )
                        })}
                      </Field>
                    </div>


                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.CITY] &&
                        formik.errors[ADD_UNIVERSITY_FIELDS.CITY] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.CITY]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>



                  {/* for academic calender */}
                  {/* <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Academic Calender")}
                    </label>
                    <div className="grade-pdf-wrapper">
                      <input
                        className="form-input grade-fee-input"
                        value={
                          imageBanner?.split("/")[
                            imageBanner?.split("/").length - 1
                          ]
                        }
                        // value={
                        //   imageBanner 
                        //     ? imageBanner.split("/")[imageBanner.split("/").length - 1] 
                        //     : null
                        // }
                        disabled
                        placeholder="Upload Academic Calender"
                      />
                      <input
                        className="image_input"
                        type="file"
                        accept="application/pdf"
                        onChange={(e: any) => {
                          onUploadEventImage(e, "banner");
                        }}
                        ref={bannerImage}
                      />
                      <div className="upload-icon">
                        {imageBanner && (
                          <VisibilityIcon
                            onClick={() => {
                              window.open(imageBanner, "_blank");
                              //onFilerViewerDialogOpen(imageBanner);
                            }}
                          />
                        )}
                        <FileUploadIcon
                          onClick={() => bannerImage.current?.click()}
                        />
                      </div>
                    </div>
                    <div className="error-message-wrap">
                      {formSubmitted &&
                      checkValueNullUndefinedEmpty(imageBanner) ? (
                        <div className="errorMessage">
                          {t(
                            `${EDIT_UNIVERSITY_ERROR_MESSAGE.ACADEMIC_CALENDER}`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div> */}




                </div>
              </div>
              <div className="fields_label_wrapper mt-3" id="desc-card-wrap">
                <label className="fields_label mandatory_fields">{t("Description")}</label>
                <Field
                  className="description-testarea"
                  placeholder="Enter Description"
                  as="textarea"
                  name={ADD_UNIVERSITY_FIELDS.DESCRIPTION}
                  rows={4}
                  onKeyPress={(event: any) => {
                    if (250 - formik.values.description.length <= 0)
                      event?.preventDefault();
                  }}
                  onPaste={(event: any) => {
                    const updatedValue =
                      formik.values.description +
                      event.clipboardData.getData("text");
                    if (updatedValue.length > 250) {
                      event.preventDefault();
                    }
                  }}
                ></Field>
                <p className="characters-left">
                  {250 - formik.values.description?.length}{" "}
                  {t("characters left")}
                </p>
                <div className="error-message-wrap">
                  {formik.touched[ADD_UNIVERSITY_FIELDS.DESCRIPTION] &&
                    formik.errors[ADD_UNIVERSITY_FIELDS.DESCRIPTION] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.DESCRIPTION]}`)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row school-row-wrap">
              <div id="card-title">{t("Application Process")}</div>
              <div className="col-md-12">
                <div id="card-wrap">
                  <div className="application-btn-wrapper">
                    <input
                      className="form_inputs"
                      placeholder={t(
                        "Please add application process as points"
                      )}
                      onChange={(e: any) => {
                        setAppProcessPoints(e.target.value);
                      }}
                      value={appProcessPoints}
                      maxLength={750}
                    />
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        if (nullUndefinedEmptyCheck(appProcessPoints)) {
                          let data: any = applicationProcess;
                          data.push(appProcessPoints);
                          setApplicationProcess([...data]);
                          setAppProcessPoints("");
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  {applicationProcess?.length > 0 && (
                    <div className="event-wrap">
                      <List>
                        {applicationProcess?.map((point: any, index: any) => {
                          return (
                            <div>
                              <ListItemButton className="list-btn" key={index}>
                                <ListItemText primary={point} />
                                <DeleteIcon
                                  onClick={() => {
                                    let data: any = applicationProcess;
                                    data.splice(index, 1);
                                    setApplicationProcess([...data]);
                                  }}
                                />
                              </ListItemButton>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  )}
                  {/*<div className="error-message-wrap mt-2">
                    {applicationProcess?.length === 0 && formSubmitted ? (
                      <div className="errorMessage">
                        {t(`${ADD_SCHOOLS_ERROR_MESSAGES.APPLICATION_PROCESS}`)}
                      </div>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row school-row-wrap">
              <div id="card-title">{t("Eligibility Criteria")}</div>
              <div className="col-md-12">
                <div id="card-wrap">
                  <div className="application-btn-wrapper">
                    <input
                      className="form_inputs"
                      placeholder={t(
                        "Please add eligibility criteria as points"
                      )}
                      onChange={(e: any) => {
                        setEligibilityPoints(e.target.value);
                      }}
                      value={eligibilityPoints}
                      maxLength={750}
                    />
                    <button
                      type="button"
                      className="btn add-btn"
                      onClick={() => {
                        if (nullUndefinedEmptyCheck(eligibilityPoints)) {
                          let data: any = eligibilityCriteria;
                          data.push(eligibilityPoints);
                          setEligibilityCriteria([...data]);
                          setEligibilityPoints("");
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  {eligibilityCriteria?.length > 0 && (
                    <div className="event-wrap">
                      <List>
                        {eligibilityCriteria?.map((point: any, index: any) => {
                          return (
                            <div>
                              <ListItemButton className="list-btn" key={index}>
                                <ListItemText primary={point} />
                                <DeleteIcon
                                  onClick={() => {
                                    let data: any = eligibilityCriteria;
                                    data.splice(index, 1);
                                    setEligibilityCriteria([...data]);
                                    // onDeleteHighlight(index);
                                  }}
                                />
                              </ListItemButton>
                            </div>
                          );
                        })}
                      </List>
                    </div>
                  )}
                  {/* <div className="error-message-wrap mt-2">
                    {eligibilityCriteria?.length === 0 && formSubmitted ? (
                      <div className="errorMessage">
                        {t(
                          `${ADD_SCHOOLS_ERROR_MESSAGES.ELIGIBILITY_CRITERIA}`
                        )}
                      </div>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>

            <div className="row school-row-wrap">
              <div id="card-title" className="mandatory_fields">{t("Programs Offered")}</div>
              <div className="programs-options-wrapper">
                <Select
                  className="select-menu grade-fee-input"
                  defaultValue={" "}
                  value={programType}
                  onChange={(e: any) => {
                    setProgramType(e.target.value);
                    let data: any = degreeType.filter((res: any, i: any) => {
                      return res.id == e.target.value;
                    });
                    setProgramOffered(data[0].programs_offered);
                  }}
                >
                  <MenuItem value=" ">Select degree type</MenuItem>
                  {degreeType?.map((degree: any, i: any) => {
                    return (
                      <MenuItem value={degree.id}>
                        {degree.programs_offered}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  className="select-menu grade-fee-input"
                  defaultValue={" "}
                  value={classificationType}
                  onChange={(e: any) => {
                    setClassificationType(e.target.value);
                    let data: any = fieldType.filter((res: any, i: any) => {
                      return res.id == e.target.value;
                    });
                    setProgramName(data[0].programs_name);
                  }}
                >
                  <MenuItem value=" ">Select field of study</MenuItem>
                  {fieldType?.map((degree: any, i: any) => {
                    return (
                      <MenuItem value={degree.id}>
                        {degree.programs_name}
                      </MenuItem>
                    );
                  })}
                  { }
                </Select>
                <Select
                  className="select-menu grade-fee-input"
                  defaultValue={" "}
                  value={courseName}
                  onChange={(e: any) => {
                    setCourseName(e.target.value);
                    let data: any = specification.filter((res: any, i: any) => {
                      return res.id == e.target.value;
                    });
                    setSubprogramName(data[0].sub_program_name);
                  }}
                >
                  <MenuItem value=" ">Select specification type</MenuItem>
                  {specification.map((degree: any, i: any) => {
                    return (
                      <MenuItem value={degree.id}>
                        {degree.sub_program_name}
                      </MenuItem>
                    );
                  })}
                  { }
                </Select>
                <input
                  placeholder="Fee in AED"
                  value={courseFee}
                  maxLength={6}
                  onInput={numberOnly}
                  onChange={(e: any) => {
                    setCourseFee(e.target.value);
                  }}
                />
                <button className="btn btn-primary" onClick={onAddProgram}>
                  Add{" "}
                </button>
              </div>

              {programsOffered.length > 0 && (
                <div className="event-wrap">
                  <table>
                    <tr>
                      <th>Program Type</th>
                      <th>Course Domain</th>
                      <th>Course Name</th>
                      <th>Course Fee</th>
                      <th>Action</th>
                    </tr>
                  </table>
                  <table>
                    {programsOffered.map((grade: any, i: any) => {
                      return (
                        <>
                          <tr>
                            <td>{grade.program_class_name}</td>
                            <td>{grade.university_program_name}</td>
                            <td>{grade.sub_program_class_name}</td>
                            <td>{grade.price}</td>
                            <td>
                              <DeleteIcon
                                onClick={() => {
                                  let data = programsOffered;
                                  data.splice(i, 1);
                                  setProgrammsOffered([...data]);
                                }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
              )}
              <div className="error-message-wrap mt-2">
                {programsOffered.length === 0 && formSubmitted ? (
                  <div className="errorMessage">
                    {t(`${EDIT_UNIVERSITY_ERROR_MESSAGE.PROGRAMS_OFFERED}`)}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className="row school-row-wrap">
              <div id="card-title">{t("Ratings")}</div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Teaching Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Teaching Rating")}
                      name={ADD_UNIVERSITY_FIELDS.TEACHING_RATING}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.TEACHING_RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.TEACHING_RATING] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.TEACHING_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Employability Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Employability Rating")}
                      name={ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING}
                     
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING
                      ] &&
                      formik.errors[
                        ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING
                      ] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.EMPLOYABILITY_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Program Strength Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Program Strength Rating")}
                      name={ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING}
                     
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING
                      ] &&
                      formik.errors[
                        ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING
                      ] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.PROGRAMME_STRENGTH_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Inclusiveness Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Inclusiveness Rating")}
                      name={ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING}
                      
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING
                      ] &&
                      formik.errors[
                        ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING
                      ] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.INCLUSIVENESS_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Impact Rating")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Impact Rating")}
                      name={ADD_UNIVERSITY_FIELDS.IMPACT_RATING}
                      
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.IMPACT_RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.IMPACT_RATING] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_UNIVERSITY_FIELDS.IMPACT_RATING]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Quality Assurance")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Quality Assurance")}
                      name={ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE}
                    
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE
                      ] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.QUALITY_ASSURANCE
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("International Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("International Rating")}
                      name={ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING}
                      
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING
                      ] &&
                      formik.errors[
                        ADD_UNIVERSITY_FIELDS.INTERNATIONALIZATION_RATING
                      ] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS
                                  .INTERNATIONALIZATION_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Facilities Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Facilities Rating")}
                      name={ADD_UNIVERSITY_FIELDS.FACILITIES_RATING}
                      
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[
                        ADD_UNIVERSITY_FIELDS.FACILITIES_RATING
                      ] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.FACILITIES_RATING] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.FACILITIES_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Overall Rating")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Overall Rating")}
                      name={ADD_UNIVERSITY_FIELDS.OVERALL_RATING}
                     
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.OVERALL_RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.OVERALL_RATING] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.OVERALL_RATING
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Environmental")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Environmental")}
                      name={ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL}
                      
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_UNIVERSITY_FIELDS.ENVIRONMENTAL]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="row school-row-wrap">
              <div id="card-title">{t("Other Details")}</div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Number of Students")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Number of Students")}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                      name={ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.NO_OF_STUDENTS
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Total Graduates")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Total Graduates")}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                      name={ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.TOTAL_GRADUATES
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Number of Emirates")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Number of Emirates")}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                      name={ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.NO_OF_EMIRATES
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                   <div className="fields_label_wrapper">
                    <label className="fields_label">{t('Students')}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Students")}
                      onInput={numberOnly}
                      name={ADD_UNIVERSITY_FIELDS.STUDENTS}
                    />
                    <div className="error-message-wrap">
                    
                   
                  {formik.touched[ADD_UNIVERSITY_FIELDS.STUDENTS] &&
                  formik.errors[ADD_UNIVERSITY_FIELDS.STUDENTS] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.STUDENTS]}`)}
                    </div>
                  ) : null} 
                  </div>
                  </div> 
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Male")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Male")}
                      name={ADD_UNIVERSITY_FIELDS.MALE}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.MALE] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.MALE] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.MALE]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Female")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Female")}
                      name={ADD_UNIVERSITY_FIELDS.FEMALE}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.FEMALE] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.FEMALE] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.FEMALE]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Others")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Gender")}
                      name={ADD_UNIVERSITY_FIELDS.TRANSGENDER}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.TRANSGENDER] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.TRANSGENDER] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_UNIVERSITY_FIELDS.TRANSGENDER]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div id="card-wrap">
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Degrees and Fees")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Degrees and Fees")}
                      name={ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.DEGREES_AND_FEES
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Number of Expats")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Number of Expats")}
                      name={ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[ADD_UNIVERSITY_FIELDS.NO_OF_EXPATS]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Total Educators")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Total Educators")}
                      name={ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.TOTAL_EDUCATORS
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="fields_label_wrapper">
                    <label className="fields_label">
                      {t("Programms Offered")}
                    </label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Programms Offered")}
                      name={ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED] ? (
                        <div className="errorMessage">
                          {t(
                            `${
                              formik.errors[
                                ADD_UNIVERSITY_FIELDS.PROGRAMS_OFFERED
                              ]
                            }`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Research")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Research")}
                      name={ADD_UNIVERSITY_FIELDS.RESEARCH}
                      onKeyPress={(event: any) => {
                        numberOnly(event);
                      }}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.RESEARCH] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.RESEARCH] ? (
                        <div className="errorMessage">
                          {t(
                            `${formik.errors[ADD_UNIVERSITY_FIELDS.RESEARCH]}`
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="fields_label_wrapper">
                    <label className="fields_label">{t("Rating")}</label>
                    <Field
                      className="form_inputs"
                      placeholder={t("Rating")}
                      name={ADD_UNIVERSITY_FIELDS.RATING}
                      onInput={numberOnlyWithDecimal}
                    />
                    <div className="error-message-wrap">
                      {formik.touched[ADD_UNIVERSITY_FIELDS.RATING] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.RATING] ? (
                        <div className="errorMessage">
                          {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.RATING]}`)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/*  <div className="fields_label_wrapper">
              <label className="fields_label">{t('Images')}</label>
               <Field
                    className="form_inputs"
                    placeholder={t("Grade Year")}
                    name={ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES}
                  />
              <div className="error-message-wrap">
                    
                   
                  {formik.touched[ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES] &&
                  formik.errors[ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES]}`)}
                    </div>
                  ) : null} 
                  </div>
            </div> */}

            <div className="row school-row-wrap">
              <div className="col-md-12">
                <div className="fields_label_wrapper">
                  {/* <label className="fields_label">
                    {t("University Images")}
                  </label> */}
                  <input
                    className="image_input"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg,image/svg"
                    multiple
                    onChange={handleChange}
                    ref={uploadUniversityImages}
                  />
                  <div className="add_images_wrapper">
                    <div
                      className="add_images_cotainer"
                      onClick={() => uploadUniversityImages.current?.click()}
                    >
                      <img src={upload_image} alt="Learn and Play" />
                      <div>{t("Add Image")}</div>
                    </div>
                    <div className="text_contant">
                      Recommended size: 780x586 with JPEG,PNG
                    </div>
                  </div>
                  <div className="error-message-wrap">
                    {formik.touched[ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES] &&
                      formik.errors[ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES] ? (
                      <div className="errorMessage">
                        {t(
                          `${formik.errors[
                          ADD_UNIVERSITY_FIELDS.UNIVERSITY_IMAGES
                          ]
                          }`
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="image-array-wrap">
                  {universityImages.map((image: any, index: any) => {
                    return (
                      <div className="image-wrap" key={index}>
                        <img
                          src={image}
                          key={index}
                          className="schoolimage-list"
                          alt="Learn and Play"
                        />
                        <img
                          className="cancel-icon"
                          onClick={handleDelete(index)}
                          src={close}
                          alt="Learn and Play"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="actions_button_container">
              <button
                className="cancel_button"
                onClick={() => {
                  navigate("/manage-universities");
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className="update_button"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setFormSubmitted(true);
                }}
              >
                {id ? t("Update") : t("Submit")}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

export default AddUniversity;
