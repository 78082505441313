import { Dialog, DialogContent } from "@mui/material";
import "./VideoPlayerDialog.scss";

const VideoPlayerDialog = (viwerDialogProps: any) => {
  return (
    <>
      <Dialog
        onClose={viwerDialogProps.handleClose}
        open={viwerDialogProps.open}
        className="file_viewer_wrapper"
      >
        <DialogContent>
          <video
            controls
            className="file_image"
            src={viwerDialogProps.viewerFile}
          ></video>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default VideoPlayerDialog;
