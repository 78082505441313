import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface LanguageState {
  language: string;
}
export interface LoaderState {
  loader: boolean;
}
export interface profileDataModel {
  profile: {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: string;
  };
}
export interface tabStateModel {
  tabPosition: string;
}

const initialLoaderState: LoaderState = {
  loader: false,
};

export interface awsModel {
  keyValues:{
    accessKey: string;
    secretKey: string;
  }
}

const initialPermissionData :any ={
  permission:[]
}

const initialState: LanguageState = {
  language: "English",
};
const initialTabState: tabStateModel = {
  tabPosition: "",
};

const profileInitialState: profileDataModel = {
  profile: {
    firstname: "",
    lastname: "",
    email: "",
    phone_number: "",
  },
};

const awsState :awsModel= {
  keyValues:{
    accessKey: "",
    secretKey: ""
  }
}

const permissionInitialState :any={
  permission:[]
}

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state: any, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const tabSlice = createSlice({
  name: "tabs",
  initialState: initialTabState,
  reducers: {
    setTabData: (state: any, action: PayloadAction<any>) => {
      state.tabPosition = action.payload;
    },
  },
});
export const profileData = createSlice({
  name: "profile",
  initialState: profileInitialState,
  reducers: {
    setProfileData: (state: any, action: PayloadAction<string>) => {
      state.profile = action.payload;
    },
  },
});

export const awsData = createSlice({
  name:"aws",
  initialState: awsState,
  reducers:{
    setAwsData : (state:any,action: PayloadAction<string>) => {
      state.aws = action.payload;
    }
  }
})


export const PermissionData = createSlice({
  name: "permission",
  initialState:permissionInitialState,
  reducers:{
    setPermissionData : (state:any, action : PayloadAction<any>) => {
      state.permission = action.payload;
    }
  }

})

export const notificationData = createSlice({
  name:"  ",
  initialState:{},
  reducers:{
     setNotificationData :( state:any,action: PayloadAction<any>) => {
      state.notification = action.payload;
     }
  }
})


export const loaderSlice = createSlice({
  name: "loader",
  initialState: initialLoaderState,
  reducers: {
    setLoader: (state: any, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
/* export const { setLanguage } = languageSlice.actions;

export const { setProfileData } = profileData.actions;

export default languageSlice.reducer; */

export const languageReducer = languageSlice.reducer;
export const { setLanguage } = languageSlice.actions;

export const tabsReducer = tabSlice.reducer;
export const { setTabData } = tabSlice.actions;

export const profileReducer = profileData.reducer;
export const { setProfileData } = profileData.actions;

export const awsReducer = awsData.reducer;
export const { setAwsData } = awsData.actions;

export const loaderReducer = loaderSlice.reducer;
export const { setLoader } = loaderSlice.actions;

export const permissionReducer = PermissionData.reducer;
export const { setPermissionData } = PermissionData.actions;

export const notificationReducer = notificationData.reducer;
export const { setNotificationData } = notificationData.actions;