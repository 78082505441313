import { Select, Checkbox, Box, Tab, Tabs, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MenuItem } from "react-pro-sidebar";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON, SERVICES_STATUS, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./BannerImageThemepark.scss";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { toast } from "react-toastify";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import edit from "../../../../Assets/global/edit.svg";
import AddEditBannerImg from "../AddEditBannerImg/AddEditBannerImg";

const BannerImageThemepark = () => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [tabOnePermission, setTabOnePermission] = useState(API_RESPONSE.TRUE);
    const [tabTwoPermission, setTabTwoPermission] = useState(API_RESPONSE.TRUE);
    const [tabThreePermission, setTabThreePermission] = useState(API_RESPONSE.TRUE);
    const [dialogOpenStatus, setDialogOpenStatus] = useState(API_RESPONSE.FALSE);
    const [BannerList, setBannerList] = useState<any>([]);
    const [mode, setMode] = useState(COMMON_CONSTANTS.CREATE);
    const [editPayload, setEditPaylod] = useState<any>()
    const [initialRender, setInitialRender] = useState(API_RESPONSE.TRUE);

    useEffect(() => {
        getCategoryApiCall();
    }, [])

    const getCategoryApiCall = () => {
        axiosInstance
            .get(API_END_POINTS.THEME_PARK_CATEGORY_LIST)
            .then((res: any) => {
                if (res.data.status === true) {
                    setBannerList(res.data.result.data);
                }
            });
    }

    /**Reason dialog close */
    const onCloseDialog = () => {
        setDialogOpenStatus(API_RESPONSE.FALSE);
    };

    const onSubmitReason = (value: any) => {

    };


    return (
        <div className="manage-process">

            <AddEditBannerImg
                modalState={dialogOpenStatus}
                dialogStatus={onCloseDialog}
                submitReason={onSubmitReason}
                data={{ mode: mode, payload: editPayload }}
            />

            <div className="btn-wrapper">
                <button type="button" className="btn btn-primary" onClick={() => { setMode(COMMON_CONSTANTS.CREATE); setDialogOpenStatus(API_RESPONSE.TRUE); }}>Add Img</button>
            </div>
            <div className="filter_category_list_container">
                <TableContainer component={Paper} className="categoryTable">
                    <Table>
                        <TableHead className="categoryTableHead">
                            <TableRow className="categoryTableRow">
                                <TableCell>{t("S.NO")}</TableCell>
                                <TableCell>{t("Banner Img")}</TableCell>
                                <TableCell>{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {BannerList?.map((course: any, index: any) => {
                                return (
                                    <TableRow key={index + 1}>
                                        <TableCell>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell className="name-cell row-capitalize">
                                            {course?.category_name}
                                        </TableCell>
                                        <TableCell>
                                            <div className="action-wrap">
                                                <img
                                                    onClick={() => { setEditPaylod(course); setMode(COMMON_CONSTANTS.EDIT); setDialogOpenStatus(API_RESPONSE.TRUE); }}
                                                    className="view_edit_icons"
                                                    src={edit}
                                                    alt="Learn and Play"
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div>
    )
}



export default BannerImageThemepark;