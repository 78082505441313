import { useEffect, useState } from "react";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import { ACTIVITIES_TYPE, API_RESPONSE, DIALOG_CONSTANT, NUMBER_CONSTANTS, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS } from "../../Utilities/Constants/Constants";
import "./ManageCancelation.scss";
import { Box, Tabs, Tab, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Pagination } from "@mui/material";
import { Tooltip } from "highcharts";
import { t } from "i18next";
import NoProducts from "../NoProducts/NoProducts";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import ReasonDialog from "../ReasonDialog/ReasonDialog";

const ManageCancelations = () => {

    const [ usersList, setusersList ] = useState<any>([]);
    const [hasProducts, setHasProducts] = useState(API_RESPONSE.FALSE);
    const [tabValue, setTabValue] = useState(SERVICES_STATUS.PENDING);
    const location = useLocation();
    const dialogContent = DIALOG_CONSTANT.REFUND;
    const [modalStatus, setmodalStatus] = useState(false);
    const [currentElement, setCurrentElement ] = useState(0)
    const [page, setPage] = useState(1);
    const [totalPage, setTotalCount] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [dialogOpenStatus, setDialogOpenStatus] = useState(false);

    useEffect(()=>{
         getCancelList();
    },[location,page,rowPerPage,tabValue])

    useEffect(()=>{
        setTabValue(SERVICES_STATUS.PENDING);
    },[location])

      /**reset filter, search and sort values to default while swtching tabs */
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        setPage((prev)=>1);
    };


    /* Get Cancel request user list */
    const getCancelList = () => {
        //TUTOR : 1 , CAMP: 3, PT:2
        let value :any= tabValue===SERVICES_STATUS.PENDING ? 'requested' : (tabValue===SERVICES_STATUS.APPROVED ? 'cancelled' : 'rejected')
        axiosInstance.get(API_END_POINTS.TUTOR_CANCELATION_LIST +  "?page=" +
        page +
        "&limit=" +
        rowPerPage + `&teach_service_provider_id=${ location.pathname.split('/')[1] === "manage-recreational" ? 2 : 1}` +
        "&status=" +
        value
        
        ).then((response:any)=>{
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                setusersList([...response.data.result.data]);
                setTotalCount(response.data.result.total_page);
                response.data.result.data.length > 0 ? setHasProducts(API_RESPONSE.TRUE) : setHasProducts(API_RESPONSE.FALSE)
                
            }
        })
    };

    /* Opening Confirmation dialog */
    const openDialogBox = (id: any) => {
        setCurrentElement(id);
        setmodalStatus(true);
      };

     /* Opening reason dialog */
    const openReasonDialogBox = (id: any) => {
        setCurrentElement(id);
        setDialogOpenStatus(true);
    };


    /* Data from confirmation dialog */
    const  onAcceptRefund = (data:any) => {
        setmodalStatus(false);
        if(data){
            let payload:any={
                booking_id:usersList[currentElement].booking,
                booked_time_slot_id:usersList[currentElement].id,
                status:'approved'
             };
             onAcceptRejectRefund(payload);
        };
    };

    /* Accept/reject refund API call */
    const onAcceptRejectRefund = (payload:any) => {
        axiosInstance.put(API_END_POINTS.TUTOR_CANCELATION_LIST,payload).then((response:any)=>{
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                toast.success(response.data.message, TOASTER_CONFIGS);
                getCancelList();
            }
         })
    };

      /* Handle page change */
      const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
        setPage(newPage);
      };

       /**Handle row per page change */
       const onRowChange = (event: any) => {
        setPage(NUMBER_CONSTANTS.ONE);
        setRowPerPage(parseInt(event.target.value));
    };

    /* Closing Confirmation dialog */
    const onCloseDialog = () => {
        setDialogOpenStatus(false);
    };

    /* Response from Reason dialog */
    const onSubmitReason = (value: any) => {
         setDialogOpenStatus(false);
         let payload:any={
            booking_id:usersList[currentElement].booking,
            booked_time_slot_id:usersList[currentElement].id,
            status:'rejected',
            reason: value.reason
         };
         onAcceptRejectRefund(payload);        
    };



    return (
          <div className="cancelation-wrapper">
            <ConfirmationDialog
            content={dialogContent}
            modalState={modalStatus}
            dialogStatus={onAcceptRefund}
        />
            <ReasonDialog
            modalState={dialogOpenStatus}
            dialogStatus={onCloseDialog}
            submitReason={onSubmitReason}
            title={"Reason for rejection:"}
        />
            <div className="recreation_table_container">

          <div className="category_tabs_filter_container mt-1 mb-1">
            <Box sx={{ width: "100%" }}>
                <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                className="header_tabs"
                >
                <Tab
                    value={SERVICES_STATUS.PENDING}
                    label={t(SERVICES_STATUS.PENDING)}
                    // onClick={() => { navigate(ROUTES.RECREATIONAL_BOOKINGS + `?type=personal`) }}
                />
                <Tab
                    value={SERVICES_STATUS.APPROVED}
                    label={t(SERVICES_STATUS.APPROVED)}
                    // onClick={() => { navigate(ROUTES.RECREATIONAL_BOOKINGS + `?type=camp`) }}
                />                
                <Tab
                    value={SERVICES_STATUS.REJECTED}
                    label={t(SERVICES_STATUS.REJECTED)}
                    // onClick={() => { navigate(ROUTES.RECREATIONAL_BOOKINGS + `?type=camp`) }}
                />
                </Tabs>
            </Box>
            </div>

            <TableContainer component={Paper} className="categoryTable">
            <Table>
                <TableHead className="categoryTableHead">
                <TableRow className="categoryTableRow">
                    <TableCell>{t("S.NO")}</TableCell>
                    <TableCell>{t("SP Name")}</TableCell>
                    <TableCell>{t("User Name")}</TableCell>
                    <TableCell>{t("Session Title")}</TableCell>
                    <TableCell>{t("Slot Date")}</TableCell>
                    <TableCell>{t("Start Time")}</TableCell>
                    <TableCell>{t("End Time")}</TableCell>
                    <TableCell>{t("Reason")}</TableCell>
                    {tabValue===SERVICES_STATUS.PENDING && <TableCell>{t("Actions")}</TableCell>}
                </TableRow>
                </TableHead>

                <TableBody>
                {hasProducts &&
                    usersList.map((course: any, index: any) => {
                    return (
                        <TableRow>
                        <TableCell>
                            {page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}
                        </TableCell>
                        <TableCell className="name-cell row-capitalize">
                            {course?.service_provider_name}
                        </TableCell>
                        <TableCell className="name-cell row-capitalize">
                            {course?.booked_username}
                        </TableCell>
                        <TableCell>{course?.session_title}</TableCell>
                        <TableCell>
                            {course?.start_date}
                        </TableCell>
                        <TableCell>{course?.start_time}</TableCell>
                        <TableCell>{course?.end_time}</TableCell>
                        <TableCell>{course?.reason}</TableCell>
                        {tabValue===SERVICES_STATUS.PENDING && <TableCell>
                            <div className="btn-wrap">
                                { !course.is_refund && <button className="btn btn-success" onClick={()=>openDialogBox(index)}>Accept</button>}
                                { !course.is_refund && <button className="btn cancel" onClick={()=>openReasonDialogBox(index)}>Reject</button>}
                                { course.is_refund && <button className="btn btn-danger" >Accepted</button>}
                            </div>
                        </TableCell>}
                        
                        
                        </TableRow>
                    );
                    })}
                </TableBody>
            </Table>
            {!hasProducts && <NoProducts />}
            </TableContainer>
            {hasProducts && (
            <div className="tablePagination">
                <div className="row">
                <div className="col-md-7">
                    <div className="pagination-wrap">
                    <Pagination
                        count={totalPage}
                        page={page}
                        onChange={handleChangePage}
                    />
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                        className="rows-select"
                        value={rowPerPage}
                        onChange={onRowChange}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                    </select>
                    </div>
                </div>
                </div>
            </div>
            )}
           
            </div>
          </div>
    )

};

export default ManageCancelations;