import { API_END_POINTS_MODELS } from "../Models/ApiEndPointModels";

const API_END_POINTS: API_END_POINTS_MODELS = {
  LOGIN: "/api/account/login/",
  REFRESH_TOKEN: "/api/account/refresh/",
  FORGOT_PASSWORD: "/api/account/forgotpassword/",
  RESET_PASSWORD: "/api/account/resetpassword/",
  CREATE_CATEGORY: "/api/admin/category/",
  GET_CATEGORY_LIST: "/api/admin/categorylist/",
  DELETE_CATEGORY: "/api/admin/category/",
  GET_CATEGORY_DATA: "/api/admin/category/",
  DASHBOARD: "/api/admin/dashboard/",
  SCHOOL_BULK_UPLOAD: "/api/admin/school/",
  ADD_SCHOOL: "/api/admin/school_create/",
  ADD_UNIVERSITY: "api/admin/univerisity_create/",
  GET_SCHOOL_LIST: "/api/admin/school_list/",
  ADMIN_DETAIL: "/api/admin/profile/",
  UPDATE_PASSWORD: "/api/account/updatepassword/",
  GET_RECREATIONAL: "/api/admin/recreational_category_list/",
  CREATE_TAGS: "/api/admin/tag/",
  GET_TAG_DETAIL: "/api/admin/tag/",
  UNIVERSITY_API: "/api/admin/univerisity/",
  UNIVERSITY_LIST: "/api/admin/univerisity_list/",
  GET_COURSE_LIST: "/api/admin/courselist/",
  GET_COURSE_DATA: "/api/admin/course/",
  GET_TUTORS_LIST: "/api/admin/tutorlist/",
  MANAGE_COURSE: "/api/admin/manage_course/",
  MANAGE_TUTOR: "/api/admin/tutordetail/",
  THEME_PARK_LIST: "/api/admin/themeparklist/",
  THEME_PARK_BANNER_CREATE: "/api/admin/themepark_homepage/",
  THEME_PARK_CATEGORY_LIST: "api/admin/themepark_category/",
  THEME_PARK_TICKET_TYPE_LIST: "api/admin/themeparktickettypelist/?themepark_id=",
  THEME_PARK_TICKET_TYPE_CREATE: "api/admin/tickettype/",
  EMIRATES_CITY_LIST: "api/admin/emirate_city_list/?parent_id=0",
  GET_RECREATIONL_LIST: "/api/admin/recreation_courselist/",
  GET_RECREATIONAL_DETAIL: "/api/admin/recreationaluserdetail/",
  BLOCK_TUTOR: "/api/admin/block/",
  CREATE_THEMEPARK: "/api/admin/themepark/",
  THEMEPARK_TICKET_TYPE: "/api/admin/themepark_tickettype_imagelist/",
  UPLOAD_TICKETS: "/api/admin/themepark_ticket/",
  TICKETS_LIST: "/api/admin/themepark_ticket_list/",
  GET_ALLRECREATIONAL_LIST: "/api/admin/allrecreationalcategory/",
  GET_ALLCOURSE_LIST: "/api/admin/allcoursecategory/",
  INVITE_INSTITUTIONS: "/api/admin/invite/",
  EVENTS_LIST: "/api/admin/themeparktickettypelist/",
  GET_RECREATIONAL_PERSONAL_STATUS_LIST:
    "/api/admin/recreational_personal_userlist/",
  GET_RECREATIONAL_CAMPUS_STATUS_LIST:
    "/api/admin/recreational_camps_userlist/",
  GET_TUTOR_DETAILS: "/api/admin/tutordetail/",
  UPDATE_TUTOR_VERFICATION_DETAILS: "/api/admin/tutordetail/",
  FILTER_INITIAL_VALUS: "/api/admin/tutor_filter_details/",
  UPDATE_SCHOOL_STATUS: "/api/admin/school_approval/",
  UPDATE_UNIVERSITY_STATUS: "/api/admin/university_approval/",
  SCHOOL_INITIAL_FILTERS: "/api/admin/school_filter_details/",
  UNIVERSITY_INITIAL_FILTERS: "/api/admin/university_filter_details/",
  GET_CATEGORY_FILTERS: "/api/admin/course_filter_details/",
  GET_THEMEPARK_EVENTS: "/api/admin/themeparktickettypelist/",
  RECREATIONAL_FILTER_DETAILS: "/api/admin/recreational_filter_details/",
  GET_SESSIONS_LIST: "/api/admin/tutor_sessions_list/",
  GET_SESSION_DETAIL: "/api/admin/tutor_sessions_detail/",
  GET_GRADES_LIST: "/api/services/grade/",
  APPROVE_SCHOOL_UNIVERSITY: "/api/admin/authority_details/",
  ADD_HOMEPAGE_CONTENT: "/api/admin/createhomepage/",
  ADD_HOMEPAGE_SECOND: "/api/admin/createhomepagesection/",
  ADMIN_PERMISSION_LIST: "/api/account/permission/",
  CREATE_EDIT_ADMIN: "/api/admin/admin_role/",
  ADMIN_LIST: "/api/admin/admin_list/",
  SLOTS_LIST: "/api/admin/booked_sessions/",
  TUTOR_BOOOKED_SLOTS: "/api/admin/booked_slots/",
  CAMP_BOOKED_SLOTS: "/api/admin/camp_booked_userlist/",
  TUTOR_BOOKED_SLOT_DETAIL: "/api/admin/booked_time_slots/",
  ADMIN_PERMISSIONS: "/api/admin/admin_permission/",
  ACTIVATE_HOMEPAGE_CONTENT: "api/admin/activatehomepage/",
  NOTIFICATIONS_LIST: "/api/admin/admin_notification_list/",
  NOTIFICATION_READ: "/api/admin/admin_notification/",
  PAYMENT_LIST: "/api/admin/payment_history/",
  PAYMENT_SLOT_LIST: "/api/admin/payment_slot_history/",
  UPLOAD_FEE_STRUCTURE: "/api/admin/school_fee_upload/",
  EXPERTS_LIST: "/api/admin/educational_expert/",
  EXPERTS_LIST_REQUEST: "/api/admin/educationalexpert_list/",
  EXPERTS_REQUEST_LIST: "/api/admin/userexpert_request/",
  DISCOUNTS_LIST: "/api/admin/discount_list/",
  CREATE_EDIT_DISCOUNT: "/api/admin/manage_discount/",
  EXPERT_CHAT_USERS_LIST: "/api/admin/chat_data/",
  EXPERTS_CHAT_HISTORY: "/api/admin/university_chat_histroy/",
  PAYMENT_REPORT: "/api/admin/payment_report/",
  CHAT_HISTORY: "/api/user/chat_history/",
  USER_REPORT: "/api/admin/reports/",
  PAYMENT_PIE_DATA: "/api/admin/paymentpiechartdata/",
  CHAT_REQUESTED_LIST: "/api/admin/useradminchat_request/",
  ADMINS_LIST: "/api/admin/chat_admin_list/",
  CHAT_USERS_LIST: "/api/admin/admin_chat_user_list/",
  DEGREE_TYPE: "/api/admin/university_program/",
  FIELD_OF_STUDY: "/api/admin/university_program_specialisation/",
  SPECIFICATION: "/api/admin/university_program_subspecialisation/",
  USERS_LIST: "/api/admin/userlist/",
  TUTOR_HUB_LIST: "/api/admin/tutor_hub/",
  RECREATIONAL_HUB: "/api/admin/recreational_hub/",
  EMIRATE_LIST: "/api/admin/emirate_city_list/?parent_id=0",
  TUTOR_CANCELATION_LIST: "/api/admin/manage_cancellation/",
  SET_USER_READ_CHAT: "/api/user/chat_read/",
  CURRICULAM_LIST: "/api/admin/schoolcuriculumlist/",
  PROMOTIONAL_CONTENT: "/api/admin/service_provider_mainpage/",
  CAMP_DISCOUNT_LIST: "/api/admin/camp_discount_list/",
  MANAGE_CAMPS_DISCOUNT: "/api/admin/camp_manage_discount/",
  AWS_CREDENTIALS_LIST: "/api/user/s3credentials/",
  S3_STATIC_UPLOAD: "/api/user/staticupload/",
};

export default API_END_POINTS;
