import { Box, Tabs, Tab } from "@mui/material";
import {
  API_RESPONSE,
  I18_TRANSLATION_COMMON,
  SERVICES_STATUS,
} from "../../../Utilities/Constants/Constants";
import "./ProvidersList.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const ProvidersList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [value, setValue] = useState("");
  const [tabOnePermission, setTabOnePermission] = useState(API_RESPONSE.TRUE);
  const [tabTwoPermission, setTabTwoPermission] = useState(API_RESPONSE.TRUE);
  const [tabThreePermission, setTabThreePermission] = useState(
    API_RESPONSE.TRUE
  );

  /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="provider-list-wrapper">
      <div className="filter_category_list_container">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
              {tabTwoPermission && (
                <Tab
                  value={SERVICES_STATUS.PENDING}
                  label={t(SERVICES_STATUS.PENDING)}
                />
              )}
              {tabOnePermission && (
                <Tab
                  value={SERVICES_STATUS.APPROVED}
                  label={t(SERVICES_STATUS.APPROVED)}
                />
              )}
              {tabThreePermission && (
                <Tab
                  value={SERVICES_STATUS.REJECTED}
                  label={t(SERVICES_STATUS.REJECTED)}
                />
              )}
            </Tabs>
          </Box>
         
        </div>
      </div>
    </div>
  );
};

export default ProvidersList;
