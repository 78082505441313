import {
  Box,
  Checkbox,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import "./UniversitiesList.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  BULK_UPLOAD_TYPE,
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  ROUTES,
  TOASTER_CONFIGS,
  TRANSLATION_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import BulkUploadDialog from "./../../../Shared/BulkUploadDialog/BulkUploadDialog";
import ReasonDialog from "../../../Shared/ReasonDialog/ReasonDialog";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import SearchIcon from "@mui/icons-material/Search";
import view from "../../../../Assets/global/view.svg";
import edit from "../../../../Assets/global/edit.svg";
import { checkValueNullUndefinedEmpty } from "../../../Services/Helpers/Helper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const UniversitiesList = () => {
  const uploadExcel = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  /* Translation hook**/
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  /* get language from redux**/
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const [totalPage, setTotalCount] = useState(1);
  const [page, setPage] = useState(1);
  const [universityList, setUniversityList] = useState([]);
  const [rowPerPage, setRowPerPage] = useState<any>(10);
  const [isBulkUploadDialogOpen, setisBulkUploadDialogOpen] = useState(false);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const [currentDialog, setCurrentDialog] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const dialogTitle = DIALOG_CONSTANT.TITLE_BLOCK;
  const [searchValue, setSearchValue] = useState("");
  const [hasProducts, setHasProducts] = useState(true);
  const [initialRender, setInitialRender] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [ranking, setRanking]: any = useState([]);
  const [rankingList, setRankingList]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [ratingList, setRatingList]: any = useState([]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [graduatesRange, setGraduatesRange] = useState<number[]>([]);
  const [currentGraduatesRange, setCurrentGraduatesRange] = useState<number[]>([
    graduatesRange[0],
    graduatesRange[1],
  ]);
  const [studentsCountRange, setStudentsCountRange] = useState<number[]>([]);
  const [studentsCount, setStudentsCount] = useState<number[]>([
    studentsCountRange[0],
    studentsCountRange[1],
  ]);
  const [foundedYearList, setFoundedYearsList]: any = useState([]);
  const [foundedYear, setFoundedYear]: any = useState([]);

  useEffect(() => {
    getUniversityList();
  }, [page, rowPerPage, filterPayload]);

  /* Updated on change in search value */
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      getUniversityFilterValues();
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        setPage(1);
        getUniversityList();
      }
    }
  }, [searchValue]);

  /* Handling page change */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  const getUniversityFilterValues = () => {
    axiosInstance.get(API_END_POINTS.UNIVERSITY_INITIAL_FILTERS).then((res) => {
      let response = res.data.result.data[0];
      setGraduatesRange([
        response.total_graduates.Min,
        response.total_graduates.Max,
      ]);
      setCurrentGraduatesRange([
        response.total_graduates.Min,
        response.total_graduates.Max,
      ]);
      setStudentsCountRange([
        response.students_count.Min,
        response.students_count.Max,
      ]);
      setStudentsCount([
        response.students_count.Min,
        response.students_count.Max,
      ]);
      setFoundedYearsList(response.founded_year);
      setRankingList(response.ranking);
      setRatingList(response.rating);
    });
  };

  /**University list payload */
  const universityListPayload = () => {
    return {
      page: page,
      limit: parseInt(rowPerPage),
      search: searchValue.trim(),
      filter: filterPayload,
      status: "approved",
    };
  };
  /**Universities list API */
  const getUniversityList = () => {
    axiosInstance
      .post(API_END_POINTS.UNIVERSITY_LIST, universityListPayload())
      .then((res) => {
        setUniversityList(res.data.result.data);
        universityDataCheck(res.data.result.data);
        setTotalCount(res.data.result.total_page);
      });
  };
  const universityDataCheck = (universityData: any) => {
    if (universityData.length > 0) {
      setHasProducts(true);
    } else {
      setHasProducts(false);
    }
  };
  /**Open bulk Upload dialog */
  const openBulkUploadDialog = () => {
    setisBulkUploadDialogOpen(true);
  };
  /**Close bulk upload dialog */
  const closeBulkUploadDialog = () => {
    setisBulkUploadDialogOpen(false);
    getUniversityList();
  };

  const onChangeYear = (e: any) => {
    setFoundedYear(e.target.value);
  };

  const blockUnblockUniversity = (id: any, status: any) => {
    if (status === false) {
      setDialogOpenStatus(true);
    } else {
      axiosInstance
        .put(
          API_END_POINTS.BLOCK_TUTOR +
          "?id=" +
          id +
          "&block_type=" +
          "university" + "&reason="
        )
        .then((res) => {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getUniversityList();
        });
    }
  };

  /* Initializing min-max price marks in sider */
  const graduateMarks = [
    {
      value: graduatesRange[0],
      label: graduatesRange[0],
    },
    {
      value: graduatesRange[1],
      label: graduatesRange[1],
    },
  ];
  /* Initializing min-max price marks in sider */
  const countMarks = [
    {
      value: studentsCountRange[0],
      label: studentsCountRange[0],
    },
    {
      value: studentsCountRange[1],
      label: studentsCountRange[1],
    },
  ];

  function valuetext(value: number) {
    return `${value}`;
  }

  const hadleExperienceSlider = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const formattedRange: any = newValue?.map((value) => {
        const nearest = parseInt(value.toString());
        return nearest;
      });
      setCurrentGraduatesRange(formattedRange);
    }
  };
  const hadleStudentsCountSlider = (
    event: Event,
    newValue: number | number[]
  ) => {
    if (Array.isArray(newValue)) {
      const formattedRange: any = newValue?.map((value) => {
        const nearest = parseInt(value.toString());
        return nearest;
      });
      setStudentsCount(formattedRange);
    }
  };

  /* Set selected rating */
  const onChangeRating = (e: any) => {
    setRating(e.target.value);
  };

  /* Set selected ranking */
  const onChangeRanking = (e: any) => {
    setRanking(e.target.value);
  };

  useEffect(() => {
    if (
      filterApplied &&
      studentsCount[0] === studentsCountRange[0] &&
      studentsCount[1] === studentsCountRange[1]
    ) {
      onApplyFilter();
    }
  }, [studentsCount]);

  const onResetFilter = () => {
    setRating([]);
    setRanking([]);
    setCurrentGraduatesRange([]);
    setCurrentGraduatesRange([graduatesRange[0], graduatesRange[1]]);
    setStudentsCount([studentsCountRange[0], studentsCountRange[1]]);
  };

  const onApplyFilter = () => {
    setFilterPayload({
      ranking: ranking,
      rating: rating,
      total_graduates: [
        { start: currentGraduatesRange[0], end: currentGraduatesRange[1] },
      ],
      students_count: [{ start: studentsCount[0], end: studentsCount[1] }],
    });
  };

  const onCloseDialogBox = () => {
    setDialogOpenStatus(false);
  };

  const onSubmitReason = (value: any) => {
    let payload = {
      reason: value.reason,
    };
    setDialogOpenStatus(false);
    axiosInstance
      .put(
        API_END_POINTS.BLOCK_TUTOR +
        "?id=" +
        currentUser +
        "&block_type=" +
        VALIDATION_CONSTANT.UNIVERSITY +
        "&reason=" + value.reason,
        payload
      )
      .then((res) => {
        toast.success(res.data.message, TOASTER_CONFIGS);
        getUniversityList();
      });
  };

  const onRowChange = (event: any) => {
    setRowPerPage(event.target.value);
    setPage(COMMON_CONSTANTS.ONE)
  };
  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  /* Calling Search API */
  const onSearchData = () => {
    getUniversityList();
    setPage(1);
  };
  
  return (
    <>
      <div
        className="universities_wrapper"
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
      >
        <ReasonDialog
          modalState={dialogOpenStatus}
          dialogStatus={onCloseDialogBox}
          submitReason={onSubmitReason}
          title={dialogTitle}
        />
        <div className="search_add_wrapper">
          <div className="search-filter-wrapper">
            <div
              className={
                language === LANGUAGES.ENGLISH
                  ? "custom__search"
                  : "custom__search-rtl"
              }
            >
              <TextField
                id="outlined-basic"
                placeholder={t("Search") || ""}
                variant="outlined"
                value={searchValue}
                onChange={onUpdateSearch}
                onKeyUp={(event: any) => {
                  if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                    onSearchData();
                  }
                }}
              />
              <SearchIcon className="icon" onClick={onSearchData} />
            </div>
            <div
              className="filter-wrap"
              onClick={() => {
                setFilterApplied(!filterApplied);
              }}
            >
              {!filterApplied && <FilterAltIcon />}
              {filterApplied && <FilterAltOffIcon />}
            </div>
          </div>

          <div className="upload_buttons_wrapper">
            <div className="bulk_upload_wrapper">
              <button
                onClick={openBulkUploadDialog}
                className="bulk_upload_button"
              >
                {t("Upload University data")}
              </button>
              <BulkUploadDialog
                onCloseDialog={closeBulkUploadDialog}
                isOpen={isBulkUploadDialogOpen}
                uploadType={BULK_UPLOAD_TYPE.UNIVERSITY}
                uploadFunctionType={"universityData"}
              />
            </div>
            <button
              onClick={() => navigate(ROUTES.ADD_UNIVERSITY)}
              className="bulk_upload_button"
            >
              {t("Add University")}
            </button>
          </div>
        </div>

        {filterApplied && (
          <div className="filter-wrap-container">
            <div className="filter-items-wrap">
              <div className="filter-item">
                <Box className="slider-box">
                  <label className="filter-label">
                    {t("Total Graduates")}:
                  </label>
                  <Slider
                    min={graduatesRange[0]}
                    step={1}
                    max={graduatesRange[1]}
                    getAriaLabel={() => "Price Range"}
                    value={currentGraduatesRange}
                    onChange={hadleExperienceSlider}
                    valueLabelDisplay={
                      currentGraduatesRange[0] > graduatesRange[0] ||
                        currentGraduatesRange[1] < graduatesRange[1]
                        ? "on"
                        : "auto"
                    }
                    marks={graduateMarks}
                    getAriaValueText={valuetext}
                    className="slidercategory"
                  />
                </Box>
              </div>

              <div className="filter-item">
                <Box className="slider-box">
                  <label className="filter-label">{t("Students Count")}:</label>
                  <Slider
                    min={studentsCountRange[0]}
                    step={1}
                    max={studentsCountRange[1]}
                    getAriaLabel={() => "Students Count"}
                    value={studentsCount}
                    onChange={hadleStudentsCountSlider}
                    valueLabelDisplay={
                      studentsCount[0] > studentsCountRange[0] ||
                        studentsCount[1] < studentsCountRange[1]
                        ? "on"
                        : "auto"
                    }
                    marks={countMarks}
                    // getAriaValueText={valuetext}
                    className="slidercategory"
                  />
                </Box>
              </div>

              <div className="filter-item">
                <label className="filter-label">{t("Rating")}</label>
                <Select
                  multiple
                  value={rating}
                  className="filter-select"
                  onChange={onChangeRating}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {ratingList?.map((ratings: any, index: any) => {
                    return (
                      <MenuItem value={ratings}>
                        <Checkbox
                          checked={rating.includes(`${ratings}`)}
                        />
                        {ratings}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Ranking")}</label>
                <Select
                  multiple
                  value={ranking}
                  className="filter-select"
                  onChange={onChangeRanking}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {rankingList?.map((rankings: any, index: any) => {
                    return (
                      <MenuItem value={rankings}>
                        <Checkbox
                          checked={ranking.includes(`${rankings}`)}
                        />
                        {rankings}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Founded Year")}</label>
                <Select
                  multiple
                  value={foundedYear}
                  className="filter-select"
                  onChange={onChangeYear}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {foundedYearList?.map((year: any, index: any) => {
                    return (
                      <MenuItem value={year}>
                        <Checkbox
                          checked={foundedYear.includes(`${year}`)}
                        />
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-buttons-wrap">
                <button className="btn btn-primary" onClick={onResetFilter}>
                  {t("RESET")}
                </button>
                <button className="btn btn-secondary" onClick={onApplyFilter}>
                  {t("APPLY")}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="table_container">
          <TableContainer component={Paper} className="">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="list_headings">{t("S.NO")}</TableCell>
                  <TableCell className="list_headings">
                    {t("University ID")}
                  </TableCell>
                  <TableCell className="list_headings">
                    {t("University Name")}
                  </TableCell>
                  <TableCell className="list_headings">{t("Email")}</TableCell>
                  {/* <TableCell className="list_headings">{t("Phone")}</TableCell> */}
                  <TableCell className="list_headings">
                    {t("Location")}
                  </TableCell>
                  {/* <TableCell className="list_headings">
                    {t("Global Ranking")}
                  </TableCell>
                  <TableCell className="list_headings">
                    {t("Website")}
                  </TableCell>
                  <TableCell className="list_headings">
                    {t("Active Status")}
                  </TableCell> */}
                  <TableCell className="list_headings actions-ul">
                    {t("Actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {hasProducts && (
                <TableBody>
                  {universityList?.map((university: any, i: any) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {page - 1 === 0
                            ? i + 1
                            : (page - 1) * rowPerPage + (i + 1)}
                        </TableCell>
                        <TableCell>{university.id}</TableCell>
                        <TableCell>{university.university_name}</TableCell>
                        <TableCell>{university.email}</TableCell>
                        {/* <TableCell>{university.phone_number}</TableCell> */}
                        <TableCell>{university?.authority_details[0] ? university?.authority_details[0].address : university?.address}</TableCell>
                        {/* <TableCell>{university.rating}</TableCell> */}
                        {/* <TableCell>{university.web_link}</TableCell> */}
                        {/* <TableCell>
                          {university?.is_block === true
                            ? t("Blocked")
                            : t("Active")}
                        </TableCell> */}
                        <TableCell>
                          <div className="action-wrap">
                            <Tooltip
                              title={t("View University")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  navigate(
                                    `/manage-universities/${university.id}`
                                  );
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                            <Tooltip
                              title={t("Edit University")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  navigate(
                                    `/manage-universities/edit/${university.id}`
                                  );
                                }}
                                className="view_edit_icons"
                                src={edit}
                                alt="Learn and Play"
                              />
                            </Tooltip>

                            {university?.is_block === true ? (
                              <div
                                className="reject_btn_wrapper"
                                onClick={() => {
                                  setCurrentUser(university.user);
                                  blockUnblockUniversity(
                                    university.user,
                                    university?.is_block
                                  );
                                }}
                              >
                                <div className="accept_btn_label">
                                  {t("Unblock")}
                                </div>
                                <div className="lock_icon_wrapper">
                                  <HttpsIcon className="lock_icon" />
                                </div>
                              </div>
                            ) : (
                              <div
                                className="accept_btn_wrapper"
                                onClick={() => {
                                  setCurrentUser(university.user);
                                  blockUnblockUniversity(
                                    university.user,
                                    university?.is_block
                                  );
                                }}
                              >
                                <div className="unlock_icon_wrapper">
                                  <LockOpenTwoToneIcon className="unlock_icon" />
                                </div>
                                <div className="accept_btn_label">
                                  {t("Block")}
                                </div>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasProducts && <NoProducts></NoProducts>}
        </div>
      </div>
    </>
  );
};

export default UniversitiesList;
