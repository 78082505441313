import { useEffect, useState } from "react";
import "./PaymentDetail.scss";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON } from "../../../Utilities/Constants/Constants";
import { Card, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import  moment from 'moment';
import { useTranslation } from "react-i18next";
import view from "../../../../Assets/global/view.svg";
import NoProducts from "../../../Shared/NoProducts/NoProducts";

const PaymentDetail = () => {
    const { id } = useParams();
    const [ paymentDetail, setPaymentDetail ] = useState<any>();
    const [ slotsList, setSlotsList ] = useState<any>([]);
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [hasProducts, setHasProducts] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalCount] = useState(COMMON_CONSTANTS.ZERO); 
    const [rowPerPage, setRowPerPage] = useState(10);
    
    useEffect(()=>{
       getPaymentDetail();
       
    },[]);

    useEffect(()=>{
        getSlotsCount();
    },[page, rowPerPage]);

    
    const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
        setPage(newPage);
        };

    const onRowChange = (event: any) => {
    setRowPerPage(parseInt(event.target.value));
    setPage(1);
    };

    const getPaymentDetail = ()=>{
        axiosInstance.get(API_END_POINTS.PAYMENT_LIST + "?id=" + id).then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                setPaymentDetail(response.data.result.data);
            }
          })
    };

    const getSlotsCount = ()=>{
        axiosInstance.get(API_END_POINTS.PAYMENT_SLOT_LIST + "?id=" + id + "&page=" + page + "&limit=" + rowPerPage).then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                setTotalCount(response.data.result.total_page);
                setSlotsList(response.data.result.data);
                response.data.result.data.length > 0 ? setHasProducts(true) : setHasProducts(false);
            }
          })
    };

      
    return(
        <div className="payment-detail-wrapper">
            {/* <Card className="detail-card-wrapper">
               <p className="header">Payment Detail</p>

               <div className="detail-wrapper mx-auto">
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Sender:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ "Mohammed Farook" }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Receiver:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ "Mohammed Noufal" }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Total Amount:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ paymentDetail?.total_amount }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Payment Type:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ paymentDetail?.payment_type }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Payment Mode:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ paymentDetail?.payment_mode }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Transaction ID:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ paymentDetail?.transaction_id ? paymentDetail?.transaction_id : "102656693ac3ca6e0cdafbfe89ab99"}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                           <p className="heading">Date of Transaction:</p>
                        </div>
                        <div className="col-md-6">
                            <p className="value">{ moment(paymentDetail?.created_on).format("DD-MM-YYYY, HH:mm")}</p>
                        </div>
                    </div>
               </div>
            </Card> */}
            <div className="payment-data-wrapper">
                <div className="basic-data">
                    <div className="row">
                        <div className="col-md-6 basic-content">
                             <p className="data-key">Sender</p>
                             <p className="data-value">:&nbsp;{paymentDetail?.parent_name}</p>
                        </div>
                        <div className="col-md-6 basic-content">
                             <p className="data-key">Receiver</p>
                             <p className="data-value">:&nbsp;{paymentDetail?.service_provider_name}</p>
                         </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 basic-content">
                             <p className="data-key">Total Amount</p>
                             <p className="data-value">:&nbsp;{ paymentDetail?.total_amount }</p>
                        </div>
                        <div className="col-md-6 basic-content">
                             <p className="data-key">Payment Type</p>
                             <p className="data-value">:&nbsp;{ paymentDetail?.payment_type }</p>
                         </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 basic-content">
                             <p className="data-key">Transaction ID</p>
                             <p className="data-value">:&nbsp;{ paymentDetail?.transaction_id ? paymentDetail?.transaction_id : "102656693ac3ca6e0cdafbfe89ab99"}</p>
                        </div>
                        <div className="col-md-6 basic-content">
                             <p className="data-key">Date & Time</p>
                             <p className="data-value">:&nbsp;{ moment(paymentDetail?.created_on).format("DD-MM-YYYY, HH:mm")}</p>
                         </div>
                    </div>
                </div>

                <TableContainer component={Paper} className="categoryTable">
            <Table>
              <TableHead className="">
                <TableRow className="">
                  <TableCell>{t("S.NO")}</TableCell>
                  <TableCell>{t("Slot Title")}</TableCell>
                  <TableCell>{t("Date")}</TableCell>
                  <TableCell>{t("Start Time")}</TableCell>
                  <TableCell>{t("End Time")}</TableCell>
                  <TableCell>
                    {/*  onClick={() => handleSortID()} <TableSortLabel active={true} direction={idSort}> */}
                      {t("Slot Price(AED)")}
                    {/* </TableSortLabel> */}
                  </TableCell>

                  <TableCell>{t("Payment Status")}</TableCell>
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hasProducts &&
                  slotsList.map((slot: any, index: any) => {
                    return (
                      <TableRow key={index + 1}>
                        {/* <TableCell>{  index + 1 + (totalPage * rowPerPage) }</TableCell> */}
                        <TableCell>
                          {page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}
                        </TableCell>

                        <TableCell>{ slot.title }</TableCell>
                        <TableCell>{ slot.start_date }</TableCell>
                        <TableCell>{ moment(slot.start_time,"HH:mm:ss").format("hh:mm A")}</TableCell>
                        <TableCell>{moment(slot.end_time,"HH:mm:ss").format("hh:mm A")}</TableCell>
                        <TableCell>{slot.time_slot_price}</TableCell>
                        <TableCell> { slot.slot_payment_status}</TableCell>
                        <TableCell>
                          <div className="action-wrap">
                          
                              {/* <img
                                onClick={() => {
                                //   navigate(`/manage-payments/${payment.id}`);
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              /> */}

                              <button className="btn refund-btn">Refund</button>
                            
                            
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasProducts && <NoProducts />}
            </div>

            


        </div>
    )

};

export default PaymentDetail;