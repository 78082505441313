import {
  GetSessionStorageData,
  SetSessionStorageData,
  nullUndefinedEmptyCheck,
} from "../Helpers/Helper";
import {
  API_RESPONSE,
  ROUTES,
  TOKENS,
} from "../../Utilities/Constants/Constants";
import { toast } from "react-toastify";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { store } from "../Redux/Store/Store";
import { setLoader } from "../Redux/Reducer/Reducer";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_BACKEND_API_URL,
});
axiosInstance.interceptors.request.use(
  (config: any): any => {
    if ((config.url.split('?')[0] !== API_END_POINTS.EXPERT_CHAT_USERS_LIST) && (config.url.split('?')[0] !== API_END_POINTS.NOTIFICATIONS_LIST)) {
      store.dispatch(setLoader(true));
    }
    const accessToken = GetSessionStorageData(TOKENS.ACCESS_TOKEN);
    if (nullUndefinedEmptyCheck(accessToken)) {
      config.headers["Authorization"] = "Bearer " + `${accessToken}`;
    }
    if (
      config.url === API_END_POINTS.SCHOOL_BULK_UPLOAD ||
      config.url === API_END_POINTS.UNIVERSITY_API
    ) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error: any): any => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: any): any => {
    store.dispatch(setLoader(false));
    return response;
  },
  (error: any): any => {
    if (error?.response?.status === 401) {
      const refreshToken = GetSessionStorageData(TOKENS.REFRESH_TOKEN);
      if (nullUndefinedEmptyCheck(refreshToken)) {
        let payload: any = {
          refresh: refreshToken,
        };
        axiosInstance
          .post(API_END_POINTS.REFRESH_TOKEN, payload)
          .then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
              SetSessionStorageData(TOKENS.REFRESH_TOKEN, response.data.access);
            }
          });
      } else {
        store.dispatch(setLoader(false));
        useNavigate()(ROUTES.LOGIN);
      }
    } else {
      store.dispatch(setLoader(false));
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return Promise.reject(error);
  }
);
export { axiosInstance };
