import "./ContentSectionOne.scss";
import { useRef, useState, useEffect } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  uploadFileToS3,
  nullUndefinedEmptyCheck,
  checkValueNullUndefinedEmpty,
  S3DataGetApi,
} from "../../../../../Services/Helpers/Helper";
import { axiosInstance } from "../../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../../Utilities/Constants/ApiEndpoints";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../../../../Utilities/Constants/Constants";
import {
  THEMEPARK_MESSAGES,
  ERROR_MESSAGES,
  CONTENT_ERROR_MESSAGES,
  REGEX
} from "../../../../../Utilities/Constants/Validations";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloseIcon from "@mui/icons-material/Close";
import FileViewerDialog from "../../../../../Shared/FileViewerDialog/FileViewerDialog";
import { Field, Formik } from "formik";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";

const ContentSectionOne = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const bannerImage = useRef<HTMLInputElement | null>(null);
  const footerImage = useRef<HTMLInputElement | null>(null);
  const carousalImage = useRef<HTMLInputElement | null>(null);
  const cardImage = useRef<HTMLInputElement | null>(null);

  const [imageBanner, setImageBanner] = useState<any>([]);
  const [imageCard, setImageCard] = useState("");
  const [imageFooter, setImageFooter] = useState("");
  const [imageCarousal, setImageCarousal] = useState("");
  const [carousalText, setCarousalText] = useState("");
  const [carousalTextArabic, setCarousalTextArabic] = useState("");
  const [provider_id, setProviderId] = useState("");
  const [cardText, setCardText] = useState(" ");
  const [cardsList, setCardsList] = useState(["Schools", "Universities", "Tutors", "Activities", "Parks & Events"]);
  const [carousalData, setCarousalData] = useState<any>([]);
  const [cardData, setCardData] = useState<any>([]);
  const [cardOptions, setCardOptions] = useState<any>([])
  const [carousalOrder, setCarousalOrder] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    school_header: "",
    school_description: "",
    university_header: "",
    university_description: "",
    tutor_header: "",
    tutor_description: "",
    activities_header: "",
    activities_description: "",
    trainer_header: "",
    trainer_description: "",
    themepark_header: "",
    themepark_description: "",
    //
    school_header_ar: "",
    school_description_ar: "",
    university_header_ar: "",
    university_description_ar: "",
    tutor_header_ar: "",
    tutor_description_ar: "",
    activities_header_ar: "",
    activities_description_ar: "",
    trainer_header_ar: "",
    trainer_description_ar: "",
    themepark_header_ar: "",
    themepark_description_ar: ""
  });
  const [order, setOrder]: any = useState<any>([" ", " ", " ", " ", " ", " "]);
  const [layout, setLayout] = useState<any>([" ", " ", " ", " ", " ", " "]);
  const [orderMenuItem, setOrderMenuItem] = useState<any>([1, 2, 3, 4, 5, 6])
  useEffect(() => {
    getContentDetail()
  }, []);
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });

  /* Getting content detail API call */
  const getContentDetail = () => {
    if (id) {
      axiosInstance.get(API_END_POINTS.ADD_HOMEPAGE_SECOND + "?id=" + id).then((response) => {
        if (response.data.status === API_RESPONSE.TRUE) {
          setValues(response.data.result.data[COMMON_CONSTANTS.ZERO])
        }
      });
    }

  };

  /* Setting values on Edit */
  const setValues = (data: any) => {
    setCardData(data.header[COMMON_CONSTANTS.ZERO].card);
    setCarousalData(data.header[COMMON_CONSTANTS.ZERO].carousel);
    setImageBanner([...data.header[COMMON_CONSTANTS.ZERO].banner]);
    setImageFooter(data.header[COMMON_CONSTANTS.ZERO].footer);
    setInitialValues({
      school_header: data.school_section.heading,
      school_description: data.school_section.description,
      university_header: data.university_section.heading,
      university_description: data.university_section.description,
      tutor_header: data.tutor_section.heading,
      tutor_description: data.tutor_section.description,
      activities_header: data.activities_section.heading,
      activities_description: data.activities_section.description,
      trainer_header: data.trainer_section.heading,
      trainer_description: data.trainer_section.description,
      themepark_header: data.themepark_section.heading,
      themepark_description: data.themepark_section.description,
      //
      school_header_ar: data.school_section.heading_ar,
      school_description_ar: data.school_section.description_ar,
      university_header_ar: data.university_section.heading_ar,
      university_description_ar: data.university_section.description_ar,
      tutor_header_ar: data.tutor_section.heading_ar,
      tutor_description_ar: data.tutor_section.description_ar,
      activities_header_ar: data.activities_section.heading_ar,
      activities_description_ar: data.activities_section.description_ar,
      trainer_header_ar: data.trainer_section.heading_ar,
      trainer_description_ar: data.trainer_section.description_ar,
      themepark_header_ar: data.themepark_section.heading_ar,
      themepark_description_ar: data.themepark_section.description_ar,
    });
    setOrder([data.school_section.order_no, data.university_section.order_no, data.tutor_section.order_no, data.activities_section.order_no, data.trainer_section.order_no, data.themepark_section.order_no]);
    setLayout([data.school_section.layout_no, data.university_section.layout_no, data.tutor_section.layout_no, data.activities_section.layout_no, data.trainer_section.layout_no, data.themepark_section.layout_no]);
  }


  const setImage = (image: any, name: any) => {
    switch (name) {
      case "banner":
        setImageBanner(image);
        break;

      case "carousal":
        setImageCarousal(image);
        break;

      case "card":
        setImageCard(image);
        break;

      case "footer":
        setImageFooter(image);
        break;
    }
  };

  /* Updating event image */
  const onUploadEventImage = async (event: any, eventName: string) => {
    try {
      if (event.target.files[0].type.split("/")[0] === "image") {
        const uploadedImage = await S3DataGetApi(event);
        setImage(uploadedImage, eventName);
      } else {
        toast.error(THEMEPARK_MESSAGES.IMAGE_FORMAT);
      }
    } catch (error) { }
  };

  const onUploadMultipleEventImage = async (event: any, eventName: string) => {
    if (imageBanner.length < 5 && (imageBanner?.length + event.target.files?.length) <= 5) {
      try {

        for (let i = 0; i < event.target.files?.length; i++) {
          const uploadedImage = await S3DataGetApi(event);
          let imageCopy = imageBanner;
          imageCopy.push(uploadedImage);
          setImageBanner((prev: any) => [...imageCopy]);
        }
      } catch (error) { }
    } else {
      toast.error(ERROR_MESSAGES.MAXIMUM_UPLOAD);
    }

  };

  useEffect(() => {
    setImageCarousal("");
    setCarousalText("");
    setCarousalTextArabic("");
  }, [carousalData]);

  /* Combining carousal image & description */
  const onAddCarousalData = () => {
    if (
      nullUndefinedEmptyCheck(imageCarousal) &&
      nullUndefinedEmptyCheck(carousalText) &&
      nullUndefinedEmptyCheck(carousalOrder) &&
      nullUndefinedEmptyCheck(carousalTextArabic)

    ) {
      if (provider_id !== "" && !REGEX.NUMBER_REGEX.test(provider_id)) {
        toast.error("Enter valid Id");
        return; // Exit function if validation fails
      }

      let payload = {
        image: imageCarousal,
        description: carousalText,
        description_ar: carousalTextArabic,
        type: carousalOrder,
        provider_id: provider_id != "" ? provider_id : null
      };
      setCarousalData([...carousalData, ...[payload]]);
      setCarousalOrder("");
    } else {
      if (checkValueNullUndefinedEmpty(carousalText)) {
        toast.error(ERROR_MESSAGES.CAROUSAL_TEXT);
      } else if (checkValueNullUndefinedEmpty(imageCarousal)) {
        toast.error(ERROR_MESSAGES.CAROUSAL_IMAGE);
      } else if (checkValueNullUndefinedEmpty(carousalOrder)) {
        toast.error(ERROR_MESSAGES.SELECT_ORDER);
      } else if (checkValueNullUndefinedEmpty(carousalTextArabic)) {
        toast.error(ERROR_MESSAGES.CAROUSAL_TEXT_ARABIC);
      }
    }
  };

  useEffect(() => {

    setImageCard("");
    setCardText(" ");
    let filteredData: any = cardsList.filter((res: any, i: number) => {
      return !cardData.some((val: any) => val.name == res);
    });
    setCardOptions([...filteredData]);

  }, [cardData]);

  /* Combining card image & description */
  const onAddCardData = () => {
    if (
      nullUndefinedEmptyCheck(imageCard) &&
      nullUndefinedEmptyCheck(cardText)
    ) {
      let payload = {
        icon: imageCard,
        name: cardText,
      };
      setCardData([...cardData, ...[payload]]);
    } else {
      if (checkValueNullUndefinedEmpty(imageCard)) {
        toast.error(ERROR_MESSAGES.UPLOAD_IMAGE);
      } else if (checkValueNullUndefinedEmpty(cardText)) {
        toast.error(ERROR_MESSAGES.ADD_TEXT);
      }
    }
  };

  /* Removing carousal from array dunction */
  const onRemoveCarousalData = (i: any) => {
    let data = carousalData;
    data.splice(i, 1);
    setCarousalData([...data]);
  };

  /* Removing card data from array */
  const onRemoveCardData = (i: any) => {
    let cardValue = cardData;
    cardValue.splice(i, 1);
    setCardData([...cardValue]);
  };

  /**Handle file viewer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };


  /**Handle file viewer dialog close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(false);
    setViewerFile("");
  };


  /* Menu items to be rendered  */
  const menuInputs: any = [
    {
      name: "School Section",
      data: [
        {
          name: "School Header",
          schema_name: "school_header",
        },
        {
          name: "School Description",
          schema_name: "school_description",
        },
        //
        {
          name: "School Header Arabic",
          schema_name: "school_header_ar",
        },
        {
          name: "School Description Arabic",
          schema_name: "school_description_ar",
        },
      ],
    },
    {
      name: "University Section",
      data: [
        {
          name: "University Header",
          schema_name: "university_header",
        },
        {
          name: "University Description",
          schema_name: "university_description",
        },
        //
        {
          name: "University Header Arabic",
          schema_name: "university_header_ar",
        },
        {
          name: "University Description Arabic",
          schema_name: "university_description_ar",
        },
      ],
    },
    {
      name: "Tutor Section",
      data: [
        {
          name: "Tutor Header",
          schema_name: "tutor_header",
        },
        {
          name: "Tutor Description",
          schema_name: "tutor_description",
        },
        //
        {
          name: "Tutor Header Arabic",
          schema_name: "tutor_header_ar",
        },
        {
          name: "Tutor Description Arabic",
          schema_name: "tutor_description_ar",
        },
      ],
    },
    {
      name: "Activities Section",
      data: [
        {
          name: "Activities Header",
          schema_name: "activities_header",
        },
        {
          name: "Activities Description",
          schema_name: "activities_description",
        },
        //
        {
          name: "Activities Header Arabic",
          schema_name: "activities_header_ar",
        },
        {
          name: "Activities Description Arabic",
          schema_name: "activities_description_ar",
        },
      ],
    },
    {
      name: "Trainer Section",
      data: [
        {
          name: "Trainer Header",
          schema_name: "trainer_header",
        },
        {
          name: "Trainer Description",
          schema_name: "trainer_description",
        },
        //
        {
          name: "Trainer Header Arabic",
          schema_name: "trainer_header_ar",
        },
        {
          name: "Trainer Description Arabic",
          schema_name: "trainer_description_ar",
        },
      ],
    },
    {
      name: "Themepark Section",
      data: [
        {
          name: "Themepark Header",
          schema_name: "themepark_header",
        },
        {
          name: "Themepark Description",
          schema_name: "themepark_description",
        },
        //
        {
          name: "Themepark Header Arabic",
          schema_name: "themepark_header_ar",
        },
        {
          name: "Themepark Description Arabic",
          schema_name: "themepark_description_ar",
        },
      ],
    },
  ];

  /* Yup validation schema */
  const formValidationSchema = yup.object({
    school_header: yup.string().required(CONTENT_ERROR_MESSAGES.SCHOOL_HEADER),
    school_description: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.SCHOOL_DESCRIPTION),
    university_header: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.UNIVERSITY_HEADER),
    university_description: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.UNIVERSITY_DESCRIPTION),
    tutor_header: yup.string().required(CONTENT_ERROR_MESSAGES.TUTOR_HEADER),
    tutor_description: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.TUTOR_DESCRIPTION),
    activities_header: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.ACTIVITIES_HEADER),
    activities_description: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.ACTIVITIES_DESCRIPTION),
    trainer_header: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.TRAINER_HEADER),
    trainer_description: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.TRAINER_DESCRIPTION),
    themepark_header: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.THEMEPARK_HEADER),
    themepark_description: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.THEMEPARK_DESCRIPTION),
    /////
    /////
    school_header_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.SCHOOL_HEADER_ARABIC)
      .trim(),
    school_description_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.SCHOOL_DESCRIPTION_ARABIC)
      .trim(),
    university_header_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.UNIVERSITY_HEADER_ARABIC),
    university_description_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.UNIVERSITY_DESCRIPTION_ARABIC),
    tutor_header_ar: yup.string().required(CONTENT_ERROR_MESSAGES.TUTOR_HEADER_ARABIC),
    tutor_description_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.TUTOR_DESCRIPTION_ARABIC),
    activities_header_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.ACTIVITIES_HEADER_ARABIC),
    activities_description_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.ACTIVITIES_DESCRIPTION_ARABIC),
    trainer_header_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.TRAINER_HEADER_ARABIC),
    trainer_description_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.TRAINER_DESCRIPTION_ARABIC),
    themepark_header_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.THEMEPARK_HEADER_ARABIC),
    themepark_description_ar: yup
      .string()
      .required(CONTENT_ERROR_MESSAGES.THEMEPARK_DESCRIPTION_ARABIC),

  });

  /* Payload structuring and create/edit API call */
  const onSubmitData = (value: any) => {
    if (
      imageBanner?.length > 0 &&
      carousalData?.length > COMMON_CONSTANTS.ZERO &&
      cardData?.length > COMMON_CONSTANTS.ZERO &&
      nullUndefinedEmptyCheck(imageFooter) &&
      order.some((data: any) => checkValueNullUndefinedEmpty(data)) === API_RESPONSE.FALSE &&
      layout.some((data: any) => checkValueNullUndefinedEmpty(data)) === API_RESPONSE.FALSE
    ) {
      let payload = {
        banner: imageBanner,
        carousel: carousalData,
        card: cardData,
        footer: imageFooter,
        sections: {
          school_section: {
            heading: value.school_header,
            description: value.school_description,
            //
            heading_ar: value.school_header_ar,
            description_ar: value.school_description_ar,
            order_no: order[COMMON_CONSTANTS.ZERO],
            layout_no: layout[COMMON_CONSTANTS.ZERO]
          },
          university_section: {
            heading: value.university_header,
            description: value.university_description,
            //
            heading_ar: value.university_header_ar,
            description_ar: value.university_description_ar,
            order_no: order[COMMON_CONSTANTS.ONE],
            layout_no: layout[COMMON_CONSTANTS.ONE]
          },
          tutor_section: {
            heading: value.tutor_header,
            description: value.tutor_description,
            //
            heading_ar: value.tutor_header_ar,
            description_ar: value.tutor_description_ar,
            order_no: order[COMMON_CONSTANTS.TWO],
            layout_no: layout[COMMON_CONSTANTS.TWO]
          },
          trainer_section: {
            heading: value.trainer_header,
            description: value.trainer_description,
            //
            heading_ar: value.trainer_header_ar,
            description_ar: value.trainer_description_ar,
            order_no: order[COMMON_CONSTANTS.THREE],
            layout_no: layout[COMMON_CONSTANTS.THREE]
          },
          activities_section: {
            heading: value.activities_header,
            description: value.activities_description,
            //
            heading_ar: value.activities_header_ar,
            description_ar: value.activities_description_ar,
            order_no: order[COMMON_CONSTANTS.FOUR],
            layout_no: layout[COMMON_CONSTANTS.FOUR]
          },
          themepark_section: {
            heading: value.themepark_header,
            description: value.themepark_description,
            //
            heading_ar: value.themepark_header_ar,
            description_ar: value.themepark_description_ar,
            order_no: order[COMMON_CONSTANTS.FIVE],
            layout_no: layout[COMMON_CONSTANTS.FIVE]
          },
        }
      };
      if (id) {
        axiosInstance
          .put(API_END_POINTS.ADD_HOMEPAGE_SECOND + "?id=" + id, payload)
          .then((response) => {
            if (response.data.status === API_RESPONSE.TRUE) {
              toast.success(response.data.message, TOASTER_CONFIGS);
              navigate(-1);
            }
          });
      } else {
        axiosInstance
          .post(API_END_POINTS.ADD_HOMEPAGE_SECOND, payload)
          .then((response) => {
            if (response.data.status === API_RESPONSE.TRUE) {
              toast.success(response.data.message, TOASTER_CONFIGS);
              navigate(-1);
            }
          });
      }
    }
  };
  //
  const validateArabic = (e: any) => {
    if (REGEX.ARABIC_INPUT_VALIDATION.test(e.target.value)) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  const validateArabicPaste = (e: any) => {
    if (REGEX.ARABIC_INPUT_VALIDATION.test(e.clipboardData.getData("text"))) {
      return;
    } else {
      e.preventDefault();
      return;
    }
  };


  return (
    <div className="form-wrap mx-auto">
      <FileViewerDialog
        open={fileViewerOpen}
        handleClose={onFilerViewerDialogClose}
        viewerFile={viewerFile}
      ></FileViewerDialog>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formValidationSchema}
        onSubmit={(values, actions) => {
          onSubmitData(values);

        }}
      >
        {(formik: any) => {
          return (
            <>
              <div className="row row-wrap">
                <label className="form-label">Banner Image</label>
                <div className="col-md-12">
                  <div className="input-icon-wrap">
                    <input
                      className="form-input"
                      value={
                        ""
                        // imageBanner?.split("/")[imageBanner?.split("/").length - 1]
                      }
                      disabled
                      placeholder="Can upload upto 5 images"
                    />
                    <FileUploadIcon
                      className={"upload-icon"}
                      onClick={() => bannerImage.current?.click()}
                    />
                  </div>
                  <input
                    className="image_input"
                    type="file"
                    multiple
                    accept="image/png,image/jpg,image/jpeg,image/svg"
                    onChange={(e: any) => {
                      onUploadMultipleEventImage(e, "banner");
                    }}
                    ref={bannerImage}
                  />
                  <div className="RecommendSize_text">
                    Recommended size: 1920x496 with JPEG,PNG
                  </div>
                  {/* <div className="RecommendSize_text">
                    Note: Can upload maximum 5 banner images
                  </div> */}
                  <div className="content-banner-wrapper">
                    {imageBanner?.length > 0 &&
                      imageBanner.map((image: any, index: any) => {
                        return (
                          <div className="uploaded-image-wrapper">

                            <img
                              src={image}
                              onClick={() => {
                                onFilerViewerDialogOpen(image);
                              }}
                              className="uploaded-image"
                            />
                            <div className="close-icon">
                              <CloseIcon
                                className="icon"
                                onClick={() => {
                                  let images = imageBanner;
                                  images.splice(index, 1);
                                  setImageBanner([...images]);
                                }}
                              />

                            </div>
                          </div>
                        );
                      })}
                  </div>

                  {/* {imageBanner && (
                    <div className="uploaded-image-wrapper">
                      <img src={imageBanner} onClick={() => {
                        onFilerViewerDialogOpen(imageBanner);
                      }} className="uploaded-image" />
                      <div className="close-icon">
                        <CloseIcon
                          className="icon"
                          onClick={() => {
                            // setImageBanner();
                          }}
                        />
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
              {formSubmitted && imageBanner?.length === 0 && (
                <div className="errorMessage">
                  <p>Please upload banner image</p>
                </div>
              )}
              <div className="row row-wrap">
                <label className="form-label">Carousel</label>
                <div className="col-md-12">
                  <div className="carousal-add-btn-wrap ">
                    <textarea
                      className="form-input"
                      placeholder="Carousal Text"
                      value={carousalText}
                      onChange={(e: any) => {
                        setCarousalText(e.target.value);
                      }}
                    />
                    {/*  */}
                    <textarea
                      className="form-input"
                      placeholder="Carousal Text For Arabic"
                      value={carousalTextArabic}
                      onChange={(e: any) => {
                        setCarousalTextArabic(e.target.value);
                      }}
                      dir="rtl"
                    // onKeyPress={validateArabic}
                    // onPaste={validateArabicPaste}
                    />
                  </div>

                  <div className="input-icon-wrap  mt-3">
                    <input
                      className="form-input"
                      value={
                        imageCarousal?.split("/")[
                        imageCarousal?.split("/").length - 1
                        ]
                      }
                      disabled
                      placeholder="Carousal Image"
                    />
                    <FileUploadIcon
                      className={"upload-icon"}
                      onClick={() => carousalImage.current?.click()}
                    />
                  </div>
                  <input
                    className="image_input"
                    type="file"
                    ref={carousalImage}
                    onChange={(e: any) => {
                      onUploadEventImage(e, "carousal");
                    }}
                  />
                  <div className="RecommendSize_text">
                    Recommended size: 714x904 with JPEG,PNG
                  </div>
                  {imageCarousal && (
                    <div className="uploaded-image-wrapper">
                      <img
                        src={imageCarousal}
                        onClick={() => {
                          onFilerViewerDialogOpen(imageCarousal);
                        }}
                        className="uploaded-image"
                      />
                      <div className="close-icon">
                        <CloseIcon
                          className="icon"
                          onClick={() => {
                            setImageCarousal("");
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <Select
                    className="select-menu w-100 mt-3"
                    defaultValue={""}
                    value={carousalOrder}
                    onChange={(e: any) => {
                      setCarousalOrder(e.target.value);
                    }}
                  >
                    <MenuItem value="">Please Select Card</MenuItem>
                    <MenuItem value="school">School</MenuItem>
                    <MenuItem value="university">University</MenuItem>
                    <MenuItem value="tutor">Tutor</MenuItem>
                    <MenuItem value="themepark">Themepark</MenuItem>
                    <MenuItem value="camp">Camp</MenuItem>
                    <MenuItem value="trainer">Personal Trainer</MenuItem>
                  </Select>

                  {carousalOrder !== "" ?
                    <input
                      className="form-input"
                      type="text"
                      placeholder={`${carousalOrder.charAt(0).toUpperCase() + carousalOrder.slice(1)} Id`}
                      value={provider_id}
                      onChange={(e: any) => {
                        setProviderId(e.target.value);
                      }}
                    />
                    : ""}


                  <div className="add-btn-wrapper">
                    <button
                      className="btn btn-secondary mt-3"
                      onClick={onAddCarousalData}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {carousalData?.length > 0 && (
                  <div className="carousal-data-wrapper">
                    {carousalData?.map((data: any, index: any) => {
                      return (
                        <>
                          <p className="typeValue">{data.type} (ID: {data?.provider_id ? data?.provider_id : 'N/A'})</p>
                          <div className="carousal-data">
                            <div className="image-text-wrap">
                              <img
                                src={data.image}
                                onClick={() => {
                                  onFilerViewerDialogOpen(data.image);
                                }}
                              />
                              <div>
                                <p>{data.description}</p>
                                <br />
                                <p>{data.description_ar}</p>
                              </div>
                            </div>

                            <CloseIcon
                              onClick={() => {
                                onRemoveCarousalData(index);
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
              {formSubmitted && carousalData?.length === 0 && (
                <div className="errorMessage">
                  <p>Please upload atleast one carousal data</p>
                </div>
              )}

              <div className="row row-wrap">
                <label className="form-label">Card</label>
                <div className="col-md-12">
                  <div className="carousal-add-btn-wrap">
                    <Select
                      className="select-menu w-100"
                      value={cardText}
                      onChange={(e: any) => {
                        setCardText(e.target.value);
                      }}
                    >
                      <MenuItem value=" ">Please Select Card type</MenuItem>
                      {cardOptions?.map((data: any, i: number) => {
                        return <MenuItem value={data}>{data}</MenuItem>;
                      })}
                    </Select>
                  </div>

                  <div className="input-icon-wrap  mt-3">
                    <input
                      className="form-input"
                      value={
                        imageCard?.split("/")[imageCard?.split("/").length - 1]
                      }
                      placeholder="Card Image"
                      disabled
                    />
                    <FileUploadIcon
                      className={"upload-icon"}
                      onClick={() => cardImage.current?.click()}
                    />
                  </div>
                  <input
                    className="image_input"
                    type="file"
                    ref={cardImage}
                    onChange={(e: any) => {
                      onUploadEventImage(e, "card");
                    }}
                  />

                  {imageCard && (
                    <div className="uploaded-image-wrapper">
                      <img
                        src={imageCard}
                        onClick={() => {
                          onFilerViewerDialogOpen(imageCard);
                        }}
                        className="uploaded-image"
                      />
                      <div className="close-icon">
                        <CloseIcon
                          className="icon"
                          onClick={() => {
                            setImageCard("");
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="add-btn-wrapper">
                    <button
                      className="btn btn-secondary mt-3"
                      onClick={onAddCardData}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {cardData?.length > 0 && (
                  <div className="carousal-data-wrapper">
                    {cardData?.map((data: any, index: any) => {
                      return (
                        <div className="carousal-data mb-1">
                          <div className="image-text-wrap">
                            <img
                              src={data.icon}
                              onClick={() => {
                                onFilerViewerDialogOpen(data.icon);
                              }}
                            />
                            <p>{data.name}</p>
                          </div>

                          <CloseIcon
                            onClick={() => {
                              onRemoveCardData(index);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {formSubmitted && cardData?.length === 0 && (
                <div className="errorMessage">
                  <p>Please upload atleast one card data</p>
                </div>
              )}

              <div className="row row-wrap">
                <label className="form-label">Footer Image</label>
                <div className="col-md-12">
                  <div className="input-icon-wrap  mt-3">
                    <input
                      className="form-input"
                      disabled
                      value={
                        imageFooter?.split("/")[
                        imageFooter?.split("/").length - 1
                        ]
                      }
                      placeholder="Footer Image"
                    />
                    <FileUploadIcon
                      className={"upload-icon"}
                      onClick={() => footerImage.current?.click()}
                    />
                  </div>
                  <input
                    className="image_input"
                    type="file"
                    ref={footerImage}
                    onChange={(e: any) => {
                      onUploadEventImage(e, "footer");
                    }}
                  />
                  <div className="RecommendSize_text">
                    Recommended size: 647x 500 with JPEG,PNG
                  </div>
                  {imageFooter && (
                    <div className="uploaded-image-wrapper">
                      <img
                        src={imageFooter}
                        onClick={() => {
                          onFilerViewerDialogOpen(imageFooter);
                        }}
                        className="uploaded-image"
                      />
                      <div className="close-icon">
                        <CloseIcon
                          className="icon"
                          onClick={() => {
                            setImageFooter("");
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {formSubmitted && !imageFooter && (
                <div className="errorMessage">
                  <p>Please upload footer image</p>
                </div>
              )}
              {/*       <div className="submit-btn-wrapper">
        <button className="btn btn-secondary" onClick={onsubmit}>
          Submit
        </button>
      </div> */}
              <div className="row row-wrap">
                {menuInputs?.map((menu: any, index: any) => {
                  return (
                    <>
                      <label className="form-label">{menu?.name}</label>
                      <label className="form-label">English</label>
                      <div className="col-md-12">

                        <div className="input-wrapper">
                          <Field
                            name={menu?.data[0].schema_name}
                            className="form-input"
                            placeholder={menu?.data[0].name}
                          />
                          {formik.touched[menu?.data[0].schema_name] &&
                            formik.errors[menu?.data[0].schema_name] && (
                              <p className="validation">
                                {t(
                                  `${formik.touched[menu?.data[0].schema_name] &&
                                  formik.errors[menu?.data[0].schema_name]
                                  }`
                                )}
                              </p>
                            )}
                        </div>


                        <div className="input-wrapper mt-2">
                          <Field
                            as="textarea"
                            name={menu?.data[1].schema_name}
                            className="form-input"
                            placeholder={menu?.data[1].name}
                            onKeyPress={(event: any) => {
                              if (
                                250 -
                                formik.values[menu?.data[1]?.schema_name]
                                  .length <=
                                0
                              )
                                event?.preventDefault();
                            }}
                            onPaste={(event: any) => {
                              const updatedValue =
                                formik.values[menu?.data[1].schema_name] +
                                event.clipboardData.getData("text");
                              if (updatedValue?.length > 250) {
                                event.preventDefault();
                              }
                            }}
                          />
                          <p className="characters-left">
                            {250 -
                              formik.values[menu?.data[1]?.schema_name]
                                .length}{" "}
                            {t("characters left")}
                          </p>
                          {formik.touched[menu?.data[1].schema_name] &&
                            formik.errors[menu?.data[1].schema_name] && (
                              <p className="validation">
                                {t(
                                  `${formik.touched[menu?.data[1].schema_name] &&
                                  formik.errors[menu?.data[1].schema_name]
                                  }`
                                )}
                              </p>
                            )}
                        </div>

                        {/* New Text Area For Arabic */}
                        <label className="form-label">Arabic</label>
                        <div className="input-wrapper">
                          <Field
                            name={menu?.data[2].schema_name}
                            className="form-input"
                            placeholder={menu?.data[2].name}
                            dir="rtl"
                          // onKeyPress={validateArabic}
                          // onPaste={validateArabicPaste}
                          />
                          {formik.touched[menu?.data[2].schema_name] &&
                            formik.errors[menu?.data[2].schema_name] && (
                              <p className="validation">
                                {t(
                                  `${formik.touched[menu?.data[2].schema_name] &&
                                  formik.errors[menu?.data[2].schema_name]
                                  }`
                                )}
                              </p>
                            )}
                        </div>
                        <div className="input-wrapper mt-2">
                          <Field
                            as="textarea"
                            name={menu?.data[3].schema_name}
                            className="form-input"
                            placeholder={menu?.data[3].name}
                            dir="rtl"
                            onKeyPress={(event: any) => {
                              // validateArabic(event)
                              if (
                                250 -
                                formik.values[menu?.data[3]?.schema_name]
                                  .length <=
                                0
                              )
                                event?.preventDefault();
                            }}
                            onPaste={(event: any) => {
                              // validateArabicPaste(event)
                              const updatedValue =
                                formik.values[menu?.data[3].schema_name] +
                                event.clipboardData.getData("text");
                              if (updatedValue?.length > 250) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {/* <p className="characters-left">
                            {250 -
                              formik.values[menu?.data[3]?.schema_name]
                                .length}  {" "}
                            {t("characters left")}
                          </p> */}
                          {formik.touched[menu?.data[3].schema_name] &&
                            formik.errors[menu?.data[3].schema_name] && (
                              <p className="validation">
                                {t(
                                  `${formik.touched[menu?.data[3].schema_name] &&
                                  formik.errors[menu?.data[3].schema_name]
                                  }`
                                )}
                              </p>
                            )}
                        </div>

                        <div>
                          <Select
                            className="select-menu"
                            value={order[index]}
                            onChange={(e: any) => {
                              let orderCopy: any = [...order];
                              orderCopy[index] = `${e.target.value}`;
                              setOrder(orderCopy);
                              /*  let spliceIndex = orderMenuItem.indexOf(e.target.value);
                             orderMenuItem[spliceIndex]=" ";
                             setOrderMenuItem(order) */
                            }}
                          >
                            <MenuItem value=" ">Please Select Order</MenuItem>
                            {orderMenuItem.map((item: any, i: any) => (
                              <MenuItem key={i} value={item}>
                                {item + " - Order"}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select
                            className="select-menu"
                            value={layout[index]}
                            onChange={(e: any) => {
                              let layoutCopy: any = [...layout];
                              layoutCopy[index] = `${e.target.value}`;
                              setLayout(layoutCopy);
                            }}
                          >
                            <MenuItem value=" ">Please Select Layout</MenuItem>
                            <MenuItem value="1">Card</MenuItem>
                            <MenuItem value="2">Slide</MenuItem>
                            <MenuItem value="3">Grid</MenuItem>
                            {menuInputs[index].name === "Themepark Section" && (
                              <MenuItem value="4">List</MenuItem>
                            )}
                          </Select>
                          {formSubmitted &&
                            order[index] === " " &&
                            layout[index] !== " " && (
                              <p className="validation">
                                {t("Please Select order")}
                              </p>
                            )}
                          {formSubmitted &&
                            order[index] !== " " &&
                            layout[index] === " " && (
                              <p className="validation">
                                {t("Please Select layout")}
                              </p>
                            )}
                          {formSubmitted &&
                            order[index] === " " &&
                            layout[index] === " " && (
                              <p className="validation">
                                {t("Please Select order & layout")}
                              </p>
                            )}
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="submit-btn-wrapper">
                  <button
                    className="cancel_button"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-secondary submit-btn"
                    onClick={() => {
                      formik.handleSubmit();
                      setFormSubmitted(true);
                    }}
                  >
                    {id ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </Formik>

    </div>
    // </div>
  );
};

export default ContentSectionOne;
