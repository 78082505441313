import { Select, Checkbox, Box, Tab, Tabs, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { MenuItem } from "react-pro-sidebar";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON, SERVICES_STATUS, TOASTER_CONFIGS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ManagePrograms.scss";
import CreatePrograms from "../CreatePrograms/CreatePrograms";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { toast } from "react-toastify";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import edit from "../../../../Assets/global/edit.svg";

const ManagePrograms = ()=>{
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [value, setValue] = useState(SERVICES_STATUS.DEGREE_TYPE);
    const [ tabOnePermission, setTabOnePermission ]= useState(API_RESPONSE.TRUE);
    const [ tabTwoPermission, setTabTwoPermission ]= useState(API_RESPONSE.TRUE);
    const [ tabThreePermission, setTabThreePermission ]= useState(API_RESPONSE.TRUE);
    const [dialogOpenStatus, setDialogOpenStatus] = useState(API_RESPONSE.FALSE);
    const [ courseList, setCourseList ] = useState<any>([]);
    const [ mode, setMode] = useState(COMMON_CONSTANTS.CREATE);
    const [ editPayload, setEditPaylod ] = useState<any>()
    const [initialRender, setInitialRender] = useState(API_RESPONSE.TRUE);

    useEffect(()=>{
        getCourseList();
    },[value])

    const submitApiCall = (API:any) => {
        axiosInstance
        .get(API)
        .then((res:any) => {
          if (res.data.status === true) {
            setCourseList(res.data.result.data);
          }
        });
      }

    const getCourseList = () =>{
        if(value === SERVICES_STATUS.DEGREE_TYPE){
            submitApiCall(API_END_POINTS.DEGREE_TYPE);
         }else if(value === SERVICES_STATUS.FIELD_OF_STUDY){
            submitApiCall(API_END_POINTS.FIELD_OF_STUDY);
         }else{
            submitApiCall(API_END_POINTS.SPECIFICATION);
         }
    }



      /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
    /**Reason dialog close */
    const onCloseDialog = () => {
        setDialogOpenStatus(API_RESPONSE.FALSE);
        getCourseList();
      };

      const onSubmitReason = (value: any) => {
       
      };

    return(
        <div className="manage-process">
             <CreatePrograms
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
        submitReason={onSubmitReason}
        data={{value:value,mode:mode,payload:editPayload}}
      />
      <div className="btn-wrapper">
        <button type="button" className="btn btn-primary" onClick={()=>{ setMode(COMMON_CONSTANTS.CREATE);setDialogOpenStatus(API_RESPONSE.TRUE);}}>Add Data</button>
      </div>
          <div className="filter_category_list_container">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
              {tabOnePermission && <Tab
                value={SERVICES_STATUS.DEGREE_TYPE}
                label={t(SERVICES_STATUS.DEGREE_TYPE)}
              />}
              {tabTwoPermission && <Tab
                value={SERVICES_STATUS.FIELD_OF_STUDY}
                label={t(SERVICES_STATUS.FIELD_OF_STUDY)}
              />}
              {tabThreePermission && <Tab
                value={SERVICES_STATUS.SPECIFICATION}
                label={t(SERVICES_STATUS.SPECIFICATION)}
              />}
            </Tabs>
          </Box>
          </div>
          <TableContainer component={Paper} className="categoryTable">
          <Table>
            <TableHead className="categoryTableHead">
              <TableRow className="categoryTableRow">
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell>{value === SERVICES_STATUS.DEGREE_TYPE ? "Degree Type" : ( value === SERVICES_STATUS.FIELD_OF_STUDY ? "Field of Study" : "Specification")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              
                {courseList.map((course: any, index: any) => {
                  return (
                    <TableRow key={index+1}>
                      <TableCell>
                        {index+1}
                        {/* {page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)} */}
                      </TableCell>
                      <TableCell className="name-cell row-capitalize">
                        { value === SERVICES_STATUS.DEGREE_TYPE ? course.programs_offered : ( value === SERVICES_STATUS.FIELD_OF_STUDY ? course.programs_name : course.sub_program_name)}
                      </TableCell>
                      <TableCell>
                        <div className="action-wrap">
                            <img
                                onClick={()=>{ setEditPaylod(course);setMode(COMMON_CONSTANTS.EDIT);setDialogOpenStatus(API_RESPONSE.TRUE);}}
                                className="view_edit_icons"
                                src={edit}
                                alt="Learn and Play"
                              />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
          </div>
          
          <div>
          
          </div>
          </div>
      )}
   


export default ManagePrograms;