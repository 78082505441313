import "./HomePageList.scss";
import { useNavigate } from "react-router-dom";
import NoProducts from "../../../../Shared/NoProducts/NoProducts";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  Pagination,
} from "@mui/material";
import edit from "../../../../../Assets/global/edit.svg";
import FileViewerDialog from "../../../../Shared/FileViewerDialog/FileViewerDialog";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useTranslation } from "react-i18next";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import { toast } from "react-toastify";


const HomePageList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const [homeContentsList, setHomeContentsList] = useState<any>([]);
  const [hasProducts, setHasProducts] = useState(true);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [viewerFile, setViewerFile] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalCount] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);

  useEffect(() => {
    getContentsList();
  }, [page,rowPerPage]);

    /**Handle row per page change */
    const onRowChange = (event: any) => {
      setPage(COMMON_CONSTANTS.ONE)
      setRowPerPage(parseInt(event.target.value));
    };
  
    const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
      setPage(newPage);
    };
  

  /* Getting contents list,API call */
  const getContentsList = () => {
    axiosInstance.get(API_END_POINTS.ADD_HOMEPAGE_SECOND +
      "?page=" +
      page +
      "&limit=" +
      rowPerPage).then((response) => {
      if (response.data.status === API_RESPONSE.TRUE) {
        setHomeContentsList([...response.data.result.data]);
        setTotalCount(response.data.result.total_page);
        response.data.result.data.length > 0
          ? setHasProducts(API_RESPONSE.TRUE)
          : setHasProducts(API_RESPONSE.FALSE);
      }
    });
  };


  const activateContent = (id:any)=>{
    axiosInstance.put(API_END_POINTS.ACTIVATE_HOMEPAGE_CONTENT + `?id=${id}`,{}).then((response) => {
      if (response.data.status === API_RESPONSE.TRUE) {
        getContentsList();
        toast.success(response.data.message, TOASTER_CONFIGS);
      }
    })
  }

  /**Handle file viewer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(true);
  };

  /**Handle file viewer dialog close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(false);
    setViewerFile("");
  };

  return (
    <div className="home-content-wrapper">
      <div className="row create-btn-wrap">
        <button
          className="col-md-12 btn btn-secondary"
          onClick={() => {
            navigate(`${ROUTES.CREATE_HOME_PAGE_CONTENTS}`);
          }}
        >
          Create Content
        </button>
      </div>
      {/* {!hasProducts && (
        <div className="row no-data-wrapper">
          <div className="col-md-12">
            <NoProducts />
          </div>
        </div>
      )} */}
      <FileViewerDialog
        open={fileViewerOpen}
        handleClose={onFilerViewerDialogClose}
        viewerFile={viewerFile}
      ></FileViewerDialog>
      <TableContainer component={Paper} className="categoryTable">
        <Table>
          <TableHead className="">
            <TableRow className="">
              <TableCell>{"S.NO"}</TableCell>
              <TableCell>{"Content ID"}</TableCell>
              <TableCell>{"Banner Image"}</TableCell>
              <TableCell>{"Footer Image"}</TableCell>
              {/* <TableCell>{"Status"}</TableCell> */}
              <TableCell>{"Actions"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasProducts &&
              homeContentsList.map((content: any, index: any) => {
                return (
                  <TableRow key={index + 1}>
                    <TableCell>{page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}</TableCell>
                    <TableCell>{content.homepage_id}</TableCell>
                    <TableCell>
                      <img
                        className="table-image"
                        src={content?.header[0].banner[0]}
                        onClick={() => {
                          onFilerViewerDialogOpen(content?.header[0].banner[0]);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <img
                        className="table-image"
                        src={content?.header[0].footer}
                        onClick={() => {
                          onFilerViewerDialogOpen(content?.header[0].footer);
                        }}
                      />
                    </TableCell>
                   {/*  <TableCell>
                      {content?.in_use ? "Active" : "Inactive"}
                    </TableCell> */}
                    <TableCell>
                      <div className="action-wrapper">
                        <img
                          className="view_edit_icons"
                          src={edit}
                          onClick={() => {
                            navigate(
                              `/manage-contents/homepage/${content.homepage_id}`
                            );
                          }}
                        />

                        { content?.in_use === true ? (
                          <div
                            className="reject_btn_wrapper"
                            onClick={() => {
                                          }}
                          >
                            <div className="accept_btn_label">
                              {t("Active")}
                            </div>
                            <div className="lock_icon_wrapper">
                              <HttpsIcon className="lock_icon" />
                            </div>
                          </div>
                        ) : (
                          <div
                            className="accept_btn_wrapper"
                            onClick={() => {
                              activateContent(content?.homepage_id);
                            }}
                          >
                            <div className="unlock_icon_wrapper">
                              <LockOpenTwoToneIcon className="unlock_icon" />
                            </div>
                            <div className="accept_btn_label">
                              {t("Activate")}
                            </div>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {!hasProducts && <NoProducts />}
      </TableContainer>
      {hasProducts && (
          <div className="tablePagination">
            <div className="row">
              <div className="col-md-7">
                <div className="pagination-wrap">
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="rows-per-page">
                  <p>{t("Rows per page")}:</p>
                  <select
                    className="rows-select"
                    value={rowPerPage}
                    onChange={onRowChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default HomePageList;
