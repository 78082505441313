import { useEffect, useState } from "react";
import "./TutorApplicationOverview.scss";
import { API_RESPONSE, SERVICES_STATUS } from "../../../../../Utilities/Constants/Constants";
import Card from '@mui/material/Card';

const TutorApplicationOverview = (overviewProps: any) => {
  const [tutorDetails, setTutorDetails] = useState<any>({});
  const [comments, setComments] = useState<any>("");
  const [commentsTouched, setCommentsTouched] = useState<any>(API_RESPONSE.FALSE);

  useEffect(() => {
    setTutorDetails(overviewProps.tutorDetail);
    setComments(overviewProps.tutorDetail.basic_review_comment);
  }, [overviewProps.tutorDetail, overviewProps.tutorDetail.basic_review_comment]);
  return (
    <>
      <div className="application_overview_wrapper">
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-12 profile_image_wrapper">
            <img
              className="profile_image"
              src={tutorDetails?.profile_image}
              alt="Learn and Play"
            />
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">First Name</div>
              <div className="col-md-6 tutor_info">
                {tutorDetails.profile_details?.firstname}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Last Name</div>
              <div className="col-md-6 tutor_info">
                {tutorDetails?.profile_details?.lastname}
              </div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Email</div>
              <div className="col-md-6 tutor_info">
                {tutorDetails?.profile_details?.email}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Phone</div>
              <div className="col-md-6 tutor_info">
                {tutorDetails?.profile_details?.phone_number}
              </div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">DOB</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.dob}</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Gender</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.gender}</div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Nationality</div>
              <div className="col-md-6 tutor_info">
                {tutorDetails?.nationality}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Address</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.address}</div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">City</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.city}</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">State</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.state}</div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Country</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.country}</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Pincode</div>
              <div className="col-md-6 tutor_info">{tutorDetails?.pincode}</div>
            </div>
          </div>
        </div>

        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Highest Qualification</div>
              <div className="col-md-6 tutor_info">{tutorDetails.high_education}</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Degree</div>
              <div className="col-md-6 tutor_info">{tutorDetails.degree ? tutorDetails.degree : "N/A"}</div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 tutor_info_label">Degree Specification</div>
              <div className="col-md-6 tutor_info">{tutorDetails.degree_subject}</div>
            </div>
          </div>
        </div>
        <div className="row tutor_info_label_wrapper">
          <div className="chip_label_wrapper">
            <div className="fields_label_address">Skills</div>
            <div className="field_values_chips">
              {tutorDetails?.skills?.map((skillsElement: any, index: any) => {
                return (
                  <>
                    <div key={index} className="amenities_chips">
                      {skillsElement}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {tutorDetails?.tutor_subjects?.length > 0 && (
          <div className="row tutor_info_label_wrapper">
            <div className="col-md-12 mb-3">
              <div className="tutor_info_label">Course</div>
            </div>
            {tutorDetails?.tutor_subjects?.map((item: any) => {
              return (
                <Card className="col-md-4 p-3">
                  <div className="course__card__wapper">
                    <div className="col-md-6 tutor_info_label">Teaching Mode</div>
                    <div className="col-md-6 tutor_info">{item?.course_mode == 'Offline' ? 'On-premises' : item?.course_mode}</div>
                  </div>
                  <div className="course__card__wapper">
                    <div className="col-md-6 tutor_info_label">Subject</div>
                    <div className="col-md-6 tutor_info">{item?.courses}</div>
                  </div>
                  <div className="course__card__wapper">
                    <div className="col-md-6 tutor_info_label">Experience</div>
                    <div className="col-md-6 tutor_info">{item?.experience}</div>
                  </div>
                  <div className="course__card__wapper">
                    <div className="col-md-6 tutor_info_label">Grade</div>
                    <div className="col-md-6 tutor_info">{item?.grade_name}</div>
                  </div>
                </Card>
              )
            })}
          </div>
        )}
        <div className="row tutor_info_label_wrapper">
          <div className="col-md-12">
            <div className="tutor_info_label">Comments</div>
            <textarea
              value={comments}
              onChange={(event: any) => {
                setComments(event.target.value);
              }}
              onBlur={() => {
                setCommentsTouched(true);
              }}
              placeholder="Please enter your comments"
              disabled={
                tutorDetails.status !== SERVICES_STATUS.PENDING.toLowerCase()
              }
            ></textarea>
            {(comments?.length === 0 || comments == null) &&
              commentsTouched && (
                <div className="error_message">
                  Please enter some comments about the Tutor's personal and
                  professional details
                </div>
              )}
          </div>
        </div>
        <button
          className="next_button"
          onClick={() => {
            if (tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase()) {
             /*  comments?.length === 0 || comments == null
                ? setCommentsTouched(true)
                : */ overviewProps.updateTutorverification({
              basic_review: true,
              basic_review_comment: comments,
            });
            } else {
              overviewProps.handleNext();
            }
          }}
        >
          {/* {tutorDetails.status === SERVICES_STATUS.PENDING.toLowerCase()
            ? "Update & Go to Documents"
            : "Go to Documents"} */}
          Next
        </button>
      </div>
    </>
  );
};
export default TutorApplicationOverview;
