import "./SchoolStatusListLayout.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  ROUTES,
  SERVICES_STATUS,
  SORTING_CONSTANT,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
} from "../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import { setTabData } from "../../../../Services/Redux/Reducer/Reducer";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog/ConfirmationDialog";
import SchoolStatusList from "../Shared/SchoolsStatusList/SchoolsStatusList";
import {
  GetSessionStorageData,
  checkValueNullUndefinedEmpty,
  nullUndefinedEmptyCheck,
} from "../../../../Services/Helpers/Helper";
import ReasonDialog from "../../../../Shared/ReasonDialog/ReasonDialog";
import { toast } from "react-toastify";
import { Checkbox, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const SchoolStatusListLayout = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [value, setValue] = React.useState(SERVICES_STATUS.PENDING);
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [nameSort, setNameSort]: any = useState(SORTING_CONSTANT.ASC);
  const [initialRender, setInitialRender] = useState(true);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [hasProducts, setHasProducts] = useState(true);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const dialogTitle = DIALOG_CONSTANT.TITLE_REJECTION;
  const [approveStatusId, setApproveStatusId] = useState("");
  const [statusUpdated, setStatusUpdated] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [curriculamList, setCurriculamList] = useState([]);
  const [selectedCurriculam, setSelectedCurriculam]: any = useState([]);
  const [ranking, setRanking]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [searchPayloadValue, setSearchPayloadValue]: any = useState("");
  const [ foundedYearList, setFoundedYearsList]:any = useState([]);
  const [ foundedYear, setFoundedYear]:any = useState([]);
  const [ratingList, setRatingList]:any= useState([]);
  const [ rankingList , setRankingList ]:any = useState([]);
  const [ isSuperAdmin, setIsSuperAdmin ]:any= useState(API_RESPONSE.FALSE);
  const [ tabOnePermission, setTabOnePermission ]= useState(API_RESPONSE.TRUE);
  const [ tabTwoPermission, setTabTwoPermission ]= useState(API_RESPONSE.TRUE);
  const [ tabThreePermission, setTabThreePermission ]= useState(API_RESPONSE.TRUE);
  const dispatch = useDispatch();

  /* Getting permission data from reducer store */
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  })

  /* Getting data from local storage */
  useEffect(()=>{
    setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
  },[])

  /* Setting permission based on permission data */
  useEffect(()=>{
    if(isSuperAdmin===`${API_RESPONSE.TRUE}`){
      setTabOnePermission(API_RESPONSE.TRUE);
      setTabTwoPermission(API_RESPONSE.TRUE);
      setTabThreePermission(API_RESPONSE.TRUE);
    }else{
      setTabOnePermission(permissions[1]?.data[0].data[0].checked);
      setTabTwoPermission(permissions[1]?.data[0].data[1].checked);
      setTabThreePermission(permissions[1]?.data[0].data[2].checked);
    }
  },[isSuperAdmin])
  
  /**Get current language */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /**previous tab position */
  const tabsData: any = useSelector((state: any) => {
    return state.tabsReducer.tabPosition;
  });

  /**Get filter values */
  const getFilterValues = () => {
    axiosInstance.get(API_END_POINTS.SCHOOL_INITIAL_FILTERS).then((res) => {
      setCurriculamList(res.data.result.data[0].curriculam);
      setFoundedYearsList(res.data.result.data[0].founded_year);
      setRankingList(res.data.result.data[0].ranking);
      setRatingList(res.data.result.data[0].rating);
    });
  };

  useEffect(() => {
    if (nullUndefinedEmptyCheck(tabsData)) {
      setValue(tabsData);
    } else {
      setValue(SERVICES_STATUS.PENDING);
    }
    getFilterValues();
  }, []);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        onSearchData();
      }
    }
  }, [searchValue]);

  /* Handling Category code sorting */
  const handleCodeSort = () => {
    idSort === SORTING_CONSTANT.ASC
      ? setIdSort(SORTING_CONSTANT.DSC)
      : setIdSort(SORTING_CONSTANT.ASC);
    idSort === SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_ASCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_DESCENDING);
  };

  /**Handle row per page change */
  const onRowChange = (event: any) => {
    setPage(1);
    setRowPerPage(parseInt(event.target.value));
  };

  const onSetTotalCount = (event:any)=>{
     setTotalPage(event);
  }

  /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchValue("");
    setPage(1);
    setCurrentSort("");
  };

  /**Update page number */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /**handle reject reason dialog close */
  const onCloseDialogBox = () => {
    setDialogOpenStatus(false);
  };

  /** handle reject reason submit */
  const onSubmitReason = (value: any) => {
    updateSchoolStatus(approveStatusId, VALIDATION_CONSTANT.REJECTED, value);
  };

  /**handle open reject reson dialog */
  const approveReject = (id: any, status: any) => {
    setApproveStatusId(id);
    setDialogOpenStatus(true);
  };

  /**recreational status payload  */
  const getRecreationalStatusPayload = (activitiesStatus: any, reason: any) => {
    return {
      status: activitiesStatus,
      profile_verified:
        activitiesStatus === VALIDATION_CONSTANT.REJECTED ? false : true,
      reason: reason.reason,
    };
  };

  /**update school status */
  const updateSchoolStatus = (id: any, status: any, reason: any) => {
    axiosInstance
      .put(
        API_END_POINTS.UPDATE_SCHOOL_STATUS + `?id=${id}`,
        getRecreationalStatusPayload(status, reason)
      )
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        ) {
          toast.success(
            recreationalStatusResponse.data.message,
            TOASTER_CONFIGS
          );
          setStatusUpdated(!statusUpdated);
          onCloseDialogBox();
        }
      });
  };

  /* Set selected curriculam */
  const onSelectCurriculam = (e: any) => {
    setSelectedCurriculam(e.target.value);
  };

  /* Set selected rating */
  const onChangeRating = (e: any) => {
    setRating(e.target.value);
  };

  /* Set selected ranking */
  const onChangeRanking = (e: any) => {
    setRanking(e.target.value);
  };

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  /* Calling Search API */
  const onSearchData = () => {
    // getSchoolsList();
    setSearchPayloadValue(searchValue);
    setPage(1);
  };

  const onChangeYear = (e: any) => {
    setFoundedYear(e.target.value);
  };

  useEffect(() => {
    if (filterApplied && selectedCurriculam.length === 0) {
      onApplyFilter();
    }
  }, [selectedCurriculam]);

  /**Apply filter */
  const onApplyFilter = () => {
    setFilterPayload({
      ranking: ranking,
      rating: rating,
      curriculam: selectedCurriculam,
      founded_year: foundedYear
    });
  };

  /**Handle reset filter */
  const onResetFilter = () => {
    setRating([]);
    setRanking([]);
    setFoundedYear([]);
    setSelectedCurriculam([]);
  };

  return (
    <div
      className="school_status_list_wrapper"
      dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
    >
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialogBox}
        submitReason={onSubmitReason}
        title={dialogTitle}
      />
      <div className="search_add_wrapper">
        <div
          className={
            language === LANGUAGES.ENGLISH
              ? "custom__search"
              : "custom__search-rtl"
          }
        >
          <TextField
            id="outlined-basic"
            placeholder={t("Search") || ""}
            variant="outlined"
            value={searchValue}
            onChange={onUpdateSearch}
            onKeyUp={(event: any) => {
              if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                onSearchData();
              }
            }}
          />
          <SearchIcon className="icon" onClick={onSearchData} />
        </div>
        <div
          className="filter-wrap"
          onClick={() => {
            setFilterApplied(!filterApplied);
          }}
        >
          {!filterApplied && <FilterAltIcon />}
          {filterApplied && <FilterAltOffIcon />}
        </div>
      </div>

      {filterApplied && (
        <div className="filter-wrap-container">
          <div className="filter-items-wrap">
            <div className="filter-item">
              <label className="filter-label">{t("Curriculam")}</label>
              <Select
                multiple
                value={selectedCurriculam}
                className="filter-select"
                onChange={onSelectCurriculam}
                renderValue={(selected) => selected.join(", ")}
              >
                {curriculamList.map((curriculam: any, index: any) => {
                  return (
                    <MenuItem value={curriculam}>
                      <Checkbox
                        checked={selectedCurriculam.includes(`${curriculam}`)}
                      />
                      {curriculam}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="filter-item">
                <label className="filter-label">{t("Rating")}</label>
                <Select
                  multiple
                  value={rating}
                  className="filter-select"
                  onChange={onChangeRating}
                  renderValue={(selected) => selected.join(", ")}
                >
                   {ratingList.map((ratings: any, index: any) => {
                    return (
                      <MenuItem value={ratings}>
                        <Checkbox
                          checked={rating.includes(`${ratings}`)}
                        />
                        {ratings}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Ranking")}</label>
                <Select
                  multiple
                  value={ranking}
                  className="filter-select"
                  onChange={onChangeRanking}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {rankingList.map((rankings: any, index: any) => {
                    return (
                      <MenuItem value={rankings}>
                        <Checkbox
                          checked={ranking.includes(`${rankings}`)}
                        />
                        {rankings}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label className="filter-label">{t("Founded Year")}</label>
                <Select
                  multiple
                  value={foundedYear}
                  className="filter-select"
                  onChange={onChangeYear}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {foundedYearList.map((year: any, index: any) => {
                    return (
                      <MenuItem value={year}>
                        <Checkbox
                          checked={foundedYear.includes(`${year}`)}
                        />
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            <div className="filter-buttons-wrap">
              <button className="btn btn-primary" onClick={onResetFilter}>
                {t("RESET")}
              </button>
              <button className="btn btn-secondary" onClick={onApplyFilter}>
                {t("APPLY")}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="filter_category_list_container">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
              {tabTwoPermission && <Tab
                value={SERVICES_STATUS.PENDING}
                label={t(SERVICES_STATUS.PENDING)}
              />}
              {tabOnePermission && <Tab
                value={SERVICES_STATUS.APPROVED}
                label={t(SERVICES_STATUS.APPROVED)}
              />}
             {tabThreePermission && <Tab
                value={SERVICES_STATUS.REJECTED}
                label={t(SERVICES_STATUS.REJECTED)}
              />}
            </Tabs>
          </Box>
          {/* <div className="filter-wrap">
            <div className="price_filter_label_wrapper">
              <Box className="slider-box">
                <label className="filter-label">{t("Price Range")}:</label>
                <Slider
                  min={0}
                  step={1}
                  max={1000}
                  getAriaLabel={() => "Price Range"}
                  value={sliderValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay={
                    sliderValue[0] > 0 || sliderValue[1] < 1000 ? "on" : "auto"
                  }
                  marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory"
                />
              </Box>
            </div>
            <div className="filter_action_buttons">
              <button className="reset-btn" onClick={onResetFilter}>
                {t("Reset")}
              </button>
              <button className="apply-btn" onClick={onApplyFilter}>
                {t("Apply")}
              </button>
            </div>
          </div> */}
        </div>
        <SchoolStatusList
          hasProducts={hasProducts}
          handleCodeSort={handleCodeSort}
          idSort={idSort}
          totalPage={totalPage}
          page={page}
          handleChangePage={handleChangePage}
          rowPerPage={rowPerPage}
          totalCount={onSetTotalCount}
          onRowChange={onRowChange}
          tabValue={value}
          approveReject={approveReject}
          statusUpdated={statusUpdated}
          updateSchoolStatus={updateSchoolStatus}
          filterPayload={filterPayload}
          search={searchPayloadValue}
        />
      </div>
    </div>
  );
};

export default SchoolStatusListLayout;
