import "./EditKeywords.scss";
import { Chip } from "@mui/material";
import { Formik, Field } from "formik";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import {
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
  ROUTES,
  UPLOAD_TICKET_CONSTANT,
  I18_TRANSLATION_COMMON,
} from "../../../Utilities/Constants/Constants";
import { REGEX } from "../../../Utilities/Constants/Validations";
import plus from "../../../../Assets/tags/plus.svg";
import backButton from "../../../../Assets/schools/back_button.svg";

const EditKeywords = () => {
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [tagList, setTagList]: any = useState([]);
  const [currentEngTag, setCurrentEngTag] = useState("");
  const [currentArabTag, setCurrentArabTag] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const { id, type } = useParams();

  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  useEffect(() => {
    location.pathname.split("/")[1] ===
    UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL
      ? setCourseType(VALIDATION_CONSTANT.RECREATION)
      : setCourseType(VALIDATION_CONSTANT.COURSE);

    // }
  }, []);

  useEffect(() => {
    if (courseType) tageDetail();
  }, [courseType]);

  /* Setting English Input value */
  const setEngInputValue = (e: any) => {
    if (REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value)) {
      setCurrentEngTag(e.target.value);
    }
  };

  /* Setting Arabic input value */
  const setArabicInputValue = (e: any) => {
    /*   if (REGEX.ARABIC_INPUT_VALIDATION.test(e.target.value)) {
              setCurrentArabTag(e.target.value);
          }
  
   */
    if (
      REGEX.ARABIC_INPUT_VALIDATION.test(e.target.value) ||
      e.target.value.trim() === ""
    ) {
      setCurrentArabTag(e.target.value);
      return;
    } else {
      e.preventDefault();
      return;
    }
  };

  /* Tag Detail GET API */
  const tageDetail = () => {
    axiosInstance
      .get(
        API_END_POINTS.GET_TAG_DETAIL +
          "?category_type=" +
          courseType +
          "&category_id=" +
          id
      )
      .then((res: any) => {
        if (res.data.status === true) {
          setTagList(res.data.result.data);
          setCourse(res.data.result.category);
        }
      });
  };

  /* Update tag API */
  const onUpdateTags = () => {
    axiosInstance
      .put(
        API_END_POINTS.GET_TAG_DETAIL +
          "?category_type=" +
          courseType +
          "&category_id=" +
          id,
        { data: tagList }
      )
      .then((res: any) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          setTagList(res.data.result.data);
          navigatePrevious();
        }
      });
  };

  /* Delete tag from taglist */
  const handleDelete = (i: any) => () => {
    setTagList(tagList.filter((item: any, index: any) => index !== i));
  };
  /* Handling enter keypress */
  const handleKeyPress = (event: any) => {
    if (
      event.key === VALIDATION_CONSTANT.KEY_TYPE &&
      event.target.value.trim() !== ""
    ) {
      onSaveTag();
    }
  };
  /* Push new array to taglist */
  const onSaveTag = () => {
    let pushPayload = {
      id: "0",
      arabic_tag_name: currentArabTag,
      tag: currentEngTag,
    };
    setTagList([...tagList, pushPayload]);
    setCurrentEngTag("");
  };

  const navigatePrevious = () => {
    courseType === VALIDATION_CONSTANT.RECREATION
      ? navigate(ROUTES.RECREATIONAL_KEYWORDS)
      : navigate(ROUTES.COURSE_KEYWORDS);
  };

  return (
    <div
      className="tag-edit-wrap categories_wrapper"
      dir={currentLanguage === "English" ? "ltr" : "rtl"}
    >
      <div className="update-btn-wrap">
        <img
          className="arrow_icon"
          src={backButton}
          alt="Learn and Play"
          onClick={navigatePrevious}
        />
        <h5 className="name-title">{t("Edit Tags")}</h5>
      </div>
      <div className="tag-form-wrap box-wrapper row">
        <div className="col-md-5">
          <div className="tag-input-wrap">
            <label className="tag-name-label">{t("Course Type:")}</label>
            <input className="course-type-input" value={courseType} disabled />
          </div>
          <div className="tag-input-wrap">
            <label className="tag-name-label">{t("Category")}</label>
            <input className="course-type-input" value={course} disabled />
          </div>
        </div>

        <div className="col-md-7">
          <Formik
            initialValues={{ tag_name: "" }}
            onSubmit={(values: any) => {
              onSaveTag();
            }}
          >
            {(props) => {
              return (
                <div>
                  <label className="tag-name-label">
                    {t("Add English Tags")}:
                  </label>
                  <div className="edit-input-wrap">
                    <Field
                      className="course-type-input_tags"
                      value={currentEngTag}
                      onChange={setEngInputValue}
                      onKeyPress={(event: any) => {
                        handleKeyPress(event);
                      }}
                    />
                    <Field hidden onClick={props.handleSubmit} />
                    <img
                      onClick={() => props.handleSubmit()}
                      className="add-icon"
                      src={plus}
                      alt="Learn and Play"
                    />
                  </div>
                </div>
              );
            }}
          </Formik>
          <div className="tag-edit-div">
            {tagList.map((tag: any, index: any) => {
              return (
                <Chip
                  className="tag-chip"
                  label={tag.tag}
                  variant="outlined"
                  onDelete={handleDelete(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="save-tag">
        <button className="cancel_button" onClick={() => navigatePrevious}>
          {t("Cancel")}
        </button>
        <button className="save_button" onClick={onUpdateTags}>
          {t("Update")}
        </button>
      </div>
    </div>
  );
};

export default EditKeywords;
