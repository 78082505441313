import "./TutorsStatusListLayout.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  ROUTES,
  SERVICES_STATUS,
  SORTING_CONSTANT,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
} from "../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import {
  setLanguage,
  setTabData,
} from "../../../../Services/Redux/Reducer/Reducer";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog/ConfirmationDialog";
import { GetSessionStorageData, checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck } from "../../../../Services/Helpers/Helper";
import ReasonDialog from "../../../../Shared/ReasonDialog/ReasonDialog";
import TutorsStatusList from "../Shared/TutorsStatusList/TutorsStatusList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { toast } from "react-toastify";

const TutorsStatusListLayout = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [value, setValue] = React.useState("");   //SERVICES_STATUS.APPROVED
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const dialogTitle = DIALOG_CONSTANT.TITLE_REJECTION;
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [nameSort, setNameSort]: any = useState(SORTING_CONSTANT.ASC);
  const [currentSort, setCurrentSort] = useState("");
  const [rowPerPage, setRowPerPage] = useState(10);
  const [page, setPage] = useState(COMMON_CONSTANTS.ONE);
  const [filterApplied, setFilterApplied] = useState(false);
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = React.useState<number[]>([]);
  const [experienceRange, setExperienceRange] = useState<number[]>([]);
  const [tutorTypeList, setTutorTypeList] = useState([])
  const [languageList, setLanguageList] = useState([]);
  const [currentExperienceRange, setCurrentExperienceRange] = useState<number[]>([experienceRange[COMMON_CONSTANTS.ZERO], experienceRange[COMMON_CONSTANTS.ONE]]);
  const [averageFees, setAverageFees] = useState<number[]>([]);
  const [tutorType, setTutorType]: any = useState([])
  const [currentLanguage, setCurrentLanguage]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [gender, setGender]: any = useState([]);
  const [teachingMode, setTeachingMode]: any = useState([]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [courseCategoryList, setCourseCategoryList]: any = useState([]);
  const [currentCategoryList, setCurrentCategoryList]: any = useState([]);
  const [statusUpdated, setStatusUpdated] = useState(true);
  const [approveStatusId, setApproveStatusId] = useState("");
  const [searchPayloadValue, setSearchPayloadValue]: any = useState('');
  const [initialRender, setInitialRender] = useState(true);
  const [ratingList, setRatingList]: any = useState([]);
  const [isSuperAdmin, setIsSuperAdmin]: any = useState(API_RESPONSE.FALSE);
  const [tabOnePermission, setTabOnePermission] = useState(API_RESPONSE.TRUE);
  const [tabTwoPermission, setTabTwoPermission] = useState(API_RESPONSE.TRUE);
  const [tabThreePermission, setTabThreePermission] = useState(API_RESPONSE.TRUE);

  /**Get current language */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Getting permission data from reducer store */
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  })

  /* Getting data from session storage */
  useEffect(() => {
    setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
  }, [])

  /* Setting active tab based on permissions */
  const setActiveTab = (data: any, length: any) => {
    for (let i = 0; i <= length; i++) {
      if (data.data[i].checked === true) {
        switch (i) {
          case 0:
            setValue(SERVICES_STATUS.APPROVED);
            break;
          case 1:
            setValue(SERVICES_STATUS.PENDING);
            break;
          case 2:
            setValue(SERVICES_STATUS.REJECTED);
            break;
        }
        break;
      }
    }
  };

  /* Setting permission based on permission data */
  useEffect(() => {
    if (isSuperAdmin === `${API_RESPONSE.TRUE}`) {
      setTabOnePermission(API_RESPONSE.TRUE);
      setTabTwoPermission(API_RESPONSE.TRUE);
      setTabThreePermission(API_RESPONSE.TRUE);
      setValue(SERVICES_STATUS.PENDING);
    } else {
      setTabOnePermission(permissions[1]?.data[4].data[0].checked);
      setTabTwoPermission(permissions[1]?.data[4].data[1].checked);
      setTabThreePermission(permissions[1]?.data[4].data[2].checked);
      setActiveTab(permissions[1]?.data[4], permissions[1]?.data[4].data.length)
    }
  }, [isSuperAdmin])




  /**previous tab position */
  const tabsData: any = useSelector((state: any) => {
    return state.tabsReducer.tabPosition;
  });

  /*   useEffect(() => {
      if (nullUndefinedEmptyCheck(tabsData)) {
        setValue(tabsData);
      } else {
        setValue(SERVICES_STATUS.APPROVED);
     }
    }, []); */

  /**Get filter values */
  const getFilterValues = () => {
    axiosInstance
      .get(API_END_POINTS.FILTER_INITIAL_VALUS)
      .then((filterResponse: any) => {
        if (filterResponse.data.status === true) {
          let data = filterResponse.data.result.data[0];
          setExperienceRange([parseInt(data?.experience?.Min), parseInt(data?.experience?.Max)]);
          setCurrentExperienceRange([data.experience.Min, data.experience.Max])
          setTutorTypeList(data.tutor_type);
          setLanguageList(data.teach_languages);
          setCourseCategoryList(data.course_category);
          setRatingList(data.rating);
        }
      });
  };

  useEffect(() => {
    getFilterValues();
  }, []);


  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        onSearchData();
      }
    }
  }, [searchValue])

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);

  };

  /* View category Redirection */
  const viewcategory = (id: any) => {
    // dispatch(setTabData(value));
    navigate(`/tutors-status-list/tutor-verification/${id}`);
  };

  /* Handling Category code sorting */
  const handleCodeSort = () => {
    idSort === SORTING_CONSTANT.ASC
      ? setIdSort(SORTING_CONSTANT.DSC)
      : setIdSort(SORTING_CONSTANT.ASC);
    idSort === SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_ASCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_DESCENDING);
  };

  /**Handle row per page change */
  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE);
    setRowPerPage(parseInt(event.target.value));
  };

  /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchValue("");
    setPage(1);
    setCurrentSort("");
  };

  /**Update page number */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /**Handle slider change */
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as []);
  };

  /**Handle experience slider valuse change */
  const hadleExperienceSlider = (event: Event, newValue: number | number[]) => {
    setCurrentExperienceRange(newValue as number[]);
    // if (Array.isArray(newValue)) {
    //   const formattedRange: any = newValue?.map((value) => {
    //     const nearest = parseInt(value.toString());
    //     return nearest;
    //   });
    //   setCurrentExperienceRange(formattedRange);
    // }
  };

  /**Handle fees slider valuse change */
  // const hadleFeesSlider = (event: Event, newValue: number | number[]) => {
  //   if (Array.isArray(newValue)) {
  //     const formattedRange: any = newValue?.map((value) => {
  //       const nearest = parseInt(value.toString());
  //       return nearest;
  //     });
  //     setAverageFees(formattedRange);
  //   }
  // };

  function valuetext(value: number) {
    return `${value}`;
  }

  /**Handle language update */
  const onSetLanguage = (e: any) => {
    setCurrentLanguage(e.target.value);
  };

  /**Handle rating update */
  const onSetRating = (e: any) => {
    setRating(e.target.value);
  };

  /**Handle gender change */
  const onChangeGender = (e: any) => {
    setGender(e.target.value);
  };

  /**Handle teaching mode change */
  const onChangeTeachingMode = (e: any) => {
    setTeachingMode(e.target.value);
  };

  /**Handle tutor type change */
  const onChangeTutorType = (e: any) => {
    setTutorType(e.target.value);
  };

  /**Update category list */
  const setCategoryList = (e: any) => {
    setCurrentCategoryList(e.target.value);
  }

  const onSetTotalCount = (event: any) => {
    setTotalPage(event);
  }

  useEffect(() => {
    if (filterApplied && currentCategoryList.length === COMMON_CONSTANTS.ZERO) {
      onApplyFilter();
    }
  }, [currentCategoryList])

  const onApplyFilter = () => {
    let teachingPayload: any = [];
    teachingMode?.map((value: any) => {
      teachingPayload.push(value === COMMON_CONSTANTS.ONLINE ? COMMON_CONSTANTS.ONE : COMMON_CONSTANTS.TWO);
    });

    setFilterPayload({
      experience: [{
        start: currentExperienceRange[COMMON_CONSTANTS.ZERO],
        end: currentExperienceRange[COMMON_CONSTANTS.ONE]
      }],
      teaching_mode: teachingPayload,
      // avg_fees: [{
      //   start: averageFees[COMMON_CONSTANTS.ZERO],
      //   end: averageFees[COMMON_CONSTANTS.ONE]
      // }],
      // tutor_type: tutorType,
      // teach_lang: currentLanguage,
      rating: rating,
      course_category: currentCategoryList,
    });
  };


  /* Calling Search API */
  const onSearchData = () => {
    // getSchoolsList();
    setSearchPayloadValue(searchValue)
    setPage(1);
  };

  /*  const resetFilterPromise = ()=>{
        return new Promise((resolve,reject)=>{
          setTeachingMode([]);
          setTabData([]);
          setTutorType([]);
          setCurrentLanguage([]);
          setRating([]);
          setGender([]);
          setCurrentCategoryList([]);
          resolve
        })
   } */
  const resetFilter = () => {
    setTeachingMode([]);
    setTabData([]);
    setTutorType([]);
    setCurrentLanguage([]);
    setRating([]);
    setGender([]);
    setCurrentExperienceRange([experienceRange[COMMON_CONSTANTS.ZERO], experienceRange[COMMON_CONSTANTS.ONE]]);
    // setAverageFees([averageFeesList[COMMON_CONSTANTS.ZERO],averageFeesList[COMMON_CONSTANTS.ONE]]);
    setCurrentCategoryList([]);
  };



  /**handle reject reason dialog close */
  const onCloseDialogBox = () => {
    setDialogOpenStatus(false);
  };

  /** handle reject reason submit */
  const onSubmitReason = (value: any) => {
    updateRecreationalStatus(
      approveStatusId,
      VALIDATION_CONSTANT.REJECTED,
      value
    );
  };

  /**handle open reject reson dialog */
  const approveReject = (id: any, status: any) => {
    setApproveStatusId(id);
    setDialogOpenStatus(true);
  };

  /**recreational status payload  */
  const getRecreationalStatusPayload = (activitiesStatus: any, reason: any) => {
    return {
      status: activitiesStatus,
      profile_verified:
        activitiesStatus === VALIDATION_CONSTANT.REJECTED ? false : true,
      reason: reason.reason,
    };
  };

  /**update activity status */
  const updateRecreationalStatus = (id: any, status: any, reason: any) => {
    axiosInstance
      .put(
        API_END_POINTS.UPDATE_TUTOR_VERFICATION_DETAILS +
        `?id=${id}` +
        `&page=${3}`,
        getRecreationalStatusPayload(status, reason)
      )
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        ) {
          toast.success(
            recreationalStatusResponse.data.message,
            TOASTER_CONFIGS
          );
          setStatusUpdated(!statusUpdated);
          onCloseDialogBox();
        }
      });
  };
  return (
    <div
      className="school_status_list_wrapper"
      dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
    >
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialogBox}
        submitReason={onSubmitReason}
        title={dialogTitle}
      />
      <div className="search_add_wrapper">
        <div
          className={
            language === LANGUAGES.ENGLISH
              ? "custom__search"
              : "custom__search-rtl"
          }
        >
          <TextField
            id="outlined-basic"
            placeholder={t("Search") || ""}
            variant="outlined"
            value={searchValue}
            onChange={onUpdateSearch}
            onKeyUp={(event: any) => {
              if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                onSearchData();
              }
            }}
          />
          <SearchIcon className="icon" onClick={onSearchData} />
        </div>
        <div
          className="filter-wrap"
          onClick={() => {
            setFilterApplied(!filterApplied);
          }}
        >
          {!filterApplied && <FilterAltIcon />}
          {filterApplied && <FilterAltOffIcon />}
        </div>
      </div>

      {filterApplied && (
        <div className="filter-wrap-container">
          <div className="filter-items-wrap">
            <div className="filter-item">
              <Box className="slider-box">
                <label className="filter-label">{t("Experience")}:</label>
                <Slider
                  min={experienceRange[COMMON_CONSTANTS.ZERO]}
                  step={COMMON_CONSTANTS.ONE}
                  max={experienceRange[COMMON_CONSTANTS.ONE]}
                  getAriaLabel={() => "Price Range"}
                  value={currentExperienceRange}
                  onChange={hadleExperienceSlider}
                  valueLabelDisplay={"on"}
                  // marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory mt-4"
                />
              </Box>
            </div>



            <div className="filter-item">
              <label className="filter-label">{t("Teaching Mode")}</label>
              <Select
                multiple
                value={teachingMode}
                className="filter-select"
                onChange={onChangeTeachingMode}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="Online">
                  <Checkbox checked={teachingMode.includes("Online")} />
                  Online
                </MenuItem>
                <MenuItem value="Offline">
                  {" "}
                  <Checkbox checked={teachingMode.includes("Offline")} />
                  On-premises
                </MenuItem>
              </Select>
              {/*  <select className="filter-select">
                <option>-None-</option>
                <option value={"1"}>Online</option>
                <option value={"2"}>Offline</option>
              </select> */}
            </div>
            {/* <div className="filter-item">
              <label className="filter-label">{t('Tutor Type')}</label>
              <Select
                multiple
                value={tutorType}
                className="filter-select"
                onChange={onChangeTutorType}
                renderValue={(selected) => selected.join(", ")}
              >
                {tutorTypeList?.map((type: any, index: any) => {
                  return (
                    <MenuItem value={type}>
                      <Checkbox checked={tutorType.includes(`${type}`)} />
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </div> */}
            {/* <div className="filter-item">
              <label className="filter-label">{t('Teaching Language')}</label>
              <Select
                multiple
                value={currentLanguage}
                className="filter-select"
                onChange={onSetLanguage}
                renderValue={(selected) => selected.join(", ")}
              >
                {languageList?.map((language: any, index: any) => {
                  return (
                    <MenuItem value={language}>
                      <Checkbox
                        checked={currentLanguage.includes(`${language}`)}
                      />
                      {language}
                    </MenuItem>
                  );
                })}
              </Select>
            </div> */}
            <div className="filter-item">
              <label className="filter-label">{t('Rating')}</label>
              <Select
                multiple
                value={rating}
                className="filter-select"
                onChange={onSetRating}
                renderValue={(selected) => selected.join(", ")}
              >
                {ratingList?.map((ratings: any, index: any) => {
                  return (
                    <MenuItem value={ratings}>
                      <Checkbox
                        checked={rating.includes(`${ratings}`)}
                      />
                      {ratings}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {/* <div className="filter-item">
              <label className="filter-label">{t("Gender")}</label>
              <Select
                multiple
                value={gender}
                className="filter-select"
                onChange={onChangeGender}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="male">
                  <Checkbox checked={gender.includes("male")} />
                  Male
                </MenuItem>
                <MenuItem value="female">
                  {" "}
                  <Checkbox checked={gender.includes("female")} />
                  Female
                </MenuItem>
                <MenuItem value="others">
                  {" "}
                  <Checkbox checked={gender.includes("others")} />
                  Others
                </MenuItem>
              </Select>
            </div> */}
            <div className="filter-item">
              <label className="filter-label">{t("Category")}</label>
              <Select
                multiple
                value={currentCategoryList}
                className="filter-select"
                onChange={setCategoryList}
                renderValue={(selected) => selected.join(", ")}
              >
                {courseCategoryList?.map((course: any, index: any) => {
                  return (
                    <MenuItem value={course}>
                      <Checkbox
                        checked={currentCategoryList.includes(`${course}`)}
                      />
                      {course}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="filter-buttons-wrap">
            <button className="btn btn-primary" onClick={resetFilter}>
              {t("RESET")}
            </button>
            <button className="btn btn-secondary" onClick={onApplyFilter}>
              {t("APPLY")}
            </button>
          </div>
        </div>
      )}

      <div className="filter_category_list_container">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
              {tabTwoPermission && <Tab
                value={SERVICES_STATUS.PENDING}
                label={t(SERVICES_STATUS.PENDING)}
              />}
              {tabOnePermission && <Tab
                value={SERVICES_STATUS.APPROVED}
                label={t(SERVICES_STATUS.APPROVED)}
              />}
              {tabThreePermission && <Tab
                value={SERVICES_STATUS.REJECTED}
                label={t(SERVICES_STATUS.REJECTED)}
              />}
            </Tabs>
          </Box>
          {/* <div className="filter-wrap">
            <div className="price_filter_label_wrapper">
              <Box className="slider-box">
                <label className="filter-label">{t("Price Range")}:</label>
                <Slider
                  min={0}
                  step={1}
                  max={1000}
                  getAriaLabel={() => "Price Range"}
                  value={sliderValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay={
                    sliderValue[0] > 0 || sliderValue[1] < 1000 ? "on" : "auto"
                  }
                  marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory"
                />
              </Box>
            </div>
            <div className="filter_action_buttons">
              <button className="reset-btn" onClick={onResetFilter}>
                {t("Reset")}
              </button>
              <button className="apply-btn" onClick={onApplyFilter}>
                {t("Apply")}
              </button>
            </div>
          </div> */}
        </div>
        <TutorsStatusList
          handleCodeSort={handleCodeSort}
          idSort={idSort}
          currentSort={currentSort}
          totalPage={totalPage}
          page={page}
          handleChangePage={handleChangePage}
          rowPerPage={rowPerPage}
          onRowChange={onRowChange}
          viewcategory={viewcategory}
          tabValue={value}
          totalCount={onSetTotalCount}
          approveReject={approveReject}
          filterPayload={filterPayload}
          updateRecreationalStatus={updateRecreationalStatus}
          statusUpdated={statusUpdated}
          search={searchPayloadValue}
        />
      </div>
    </div>
  );
};

export default TutorsStatusListLayout;
