import { useEffect, useState } from "react";
import "./SchoolsUniversityApproval.scss";
import { API_RESPONSE, BULK_UPLOAD_TYPE, DIALOG_CONSTANT, SERVICES_STATUS, VALIDATION_CONSTANT } from "../../../../Utilities/Constants/Constants";
import ReasonDialog from "../../../../Shared/ReasonDialog/ReasonDialog";
const SchoolsUniversityApproval = (approvalProps:any)=>{
    const [ schoolUnivDetail, setSchoolUnivDetail ] = useState<any>({});
    const [dialogOpenStatus, setDialogOpenStatus] = useState(API_RESPONSE.FALSE);
    const dialogTitle = DIALOG_CONSTANT.TUTOR_REJECT_REASON;
    useEffect(()=>{
       setSchoolUnivDetail(approvalProps.schoolUnivDetail)
    },[approvalProps])

    const onCloseDialogBox = () => {
        setDialogOpenStatus(API_RESPONSE.FALSE);
      };

    const openRejectDialog = () => {
        setDialogOpenStatus(API_RESPONSE.TRUE);
      };

      const onSubmitReason = (value: any) => {
        setDialogOpenStatus(API_RESPONSE.FALSE);
        approvalProps.updateTutorverification({
          status: VALIDATION_CONSTANT.REJECTED,
          profile_verified: API_RESPONSE.FALSE,
          reason: value.reason,
        });
      };

    return(
        <div className="approval-wrapper">
             <ReasonDialog
            modalState={dialogOpenStatus}
            dialogStatus={onCloseDialogBox}
            submitReason={onSubmitReason}
            title={dialogTitle}
            />
            <div className="comment-wrap">
                <p className="comment-topic">{approvalProps.type=== BULK_UPLOAD_TYPE.SCHOOL ? 'Comments on School and Authority details' : 'Comments on University and Authority details'}</p>
                <p>{ schoolUnivDetail?.basic_review_comment }</p>
            </div>

            <div className="comment-wrap">
                <p className="comment-topic">Comments on Authority Documents and Identity details</p>
                <p>{ schoolUnivDetail?.documentation_review_comment }</p>
            </div>

            {schoolUnivDetail.status === SERVICES_STATUS.PENDING.toLowerCase() && (
          <div className="approve_reject_button_wrapper">
            <button className="reject_button" onClick={openRejectDialog}>
              Reject
            </button>
            <button
              className="approve_button"
              onClick={() => {
                approvalProps.updateTutorverification({
                  status: VALIDATION_CONSTANT.APPROVED,
                  profile_verified: API_RESPONSE.TRUE,
                  reason: "",
                });
              }}
            >
              Approve
            </button>
          </div>
        )}
        <div className="stepper_actions_wrapper">
          <button className="back_button" onClick={approvalProps.handleBack}>
            Back
          </button>
        </div>
        </div>
    )
};

export default SchoolsUniversityApproval;