import { Dialog, DialogTitle, DialogContent, Select, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { API_RESPONSE, I18_TRANSLATION_COMMON, ROUTES, SERVICES_STATUS, TOASTER_CONFIGS } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import "./AddEditCategory.scss";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck } from "../../../Services/Helpers/Helper";
import { ERROR_MESSAGES } from "../../../Utilities/Constants/Validations";

const AddEditCategory = (props: any) => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [fieldValue, setFieldValue] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
    const onClose = () => {
        setFieldValue("");
        setFormSubmitted(API_RESPONSE.FALSE);
        return props.dialogStatus(API_RESPONSE.FALSE);
    };



    useEffect(() => {
        if (props.data.mode === "Edit") {
            setFieldValue(props.data.payload.category_name)
        } else {
            setFieldValue("");
            setFormSubmitted(API_RESPONSE.FALSE);
        }
    }, [props])



    const submitApiCall = (API: any, payload: any) => {
        if (props.data.mode === "Edit") {
            payload.id = props.data.payload.id;
            axiosInstance
                .put(API, payload)
                .then((res) => {
                    if (res.data.status === API_RESPONSE.TRUE) {
                        toast.success(res.data.message, TOASTER_CONFIGS);
                        setFieldValue("");
                        setFormSubmitted(API_RESPONSE.FALSE);
                        onClose();
                    }
                });
        } else {
            axiosInstance
                .post(API, payload)
                .then((res) => {
                    if (res.data.status === API_RESPONSE.TRUE) {
                        toast.success(res.data.message, TOASTER_CONFIGS);
                        setFieldValue("");
                        setFormSubmitted(API_RESPONSE.FALSE);
                        onClose();
                    }
                });
        }

    }

    const onSubmit = () => {
        setFormSubmitted(API_RESPONSE.TRUE);
        if(nullUndefinedEmptyCheck(fieldValue.trim())){
            var payload = {
                category_name: fieldValue.trim()
            }
            submitApiCall(API_END_POINTS.THEME_PARK_CATEGORY_LIST, payload)
        }
        
    }


    return (
        <div className="program-create-wrapper">
            <Dialog
                className="program-dialog"
                open={props.modalState}
                sx={{ "& .MuiDialog-paper": "cus-dialog" }}
            >
                <DialogTitle className="title-wrap">
                    {props.data.mode === "Edit" ? "Edit Category" : "Create Category"}
                    <div className="close-wrap">
                        <CloseIcon className="close-icon" onClick={onClose} />
                    </div>
                </DialogTitle>

                <DialogContent>

                    <div className="create-wrapper">
                        <input className="program-input w-100 mt-3 p-2" placeholder="Enter Category Name" value={fieldValue} onChange={(e: any) => { setFieldValue(e.target.value) }} />
                        <div className="error-message-wrap">
                            {formSubmitted && checkValueNullUndefinedEmpty(fieldValue.trim()) ? (
                                <div className="errorMessage">
                                    {`${ERROR_MESSAGES.ENTER_VALUE}`}
                                </div>
                            ) : null}
                        </div>
                        <div className="submit-btn-wrapper">
                            <button type="button" className="btn btn-primary program-cancel-btn" onClick={onClose}>Cancel</button>
                            <button type="button" className="btn btn-primary program-save-btn" onClick={onSubmit}>Save</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
};

export default AddEditCategory;