import { configureStore } from "@reduxjs/toolkit";
import {
  languageReducer,
  loaderReducer,
  profileReducer,
  tabsReducer,
  permissionReducer,
  notificationReducer,
  awsReducer
} from "../Reducer/Reducer";

export const store = configureStore({
  reducer: { languageReducer, profileReducer, loaderReducer, tabsReducer, permissionReducer,notificationReducer,awsReducer },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
