import "./PaymentList.scss";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { API_RESPONSE, COMMON_CONSTANTS, I18_TRANSLATION_COMMON, ROUTES, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Chip, Pagination, Select } from "@mui/material";
import { Tooltip } from "highcharts";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import { useTranslation } from "react-i18next";
import view from "../../../../Assets/global/view.svg";
import { MenuItem } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { DateRangePicker } from "react-date-range";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import  moment from 'moment';
import { useNavigate } from "react-router-dom";

const PaymentList = () => {
    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const [ paymentList, setPaymentsList ] = useState([]);
    const [hasProducts, setHasProducts] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalCount] = useState(COMMON_CONSTANTS.ZERO); 
    const [rowPerPage, setRowPerPage] = useState(10);
    const [filterApplied, setFilterApplied] = useState(false);
    const [ sortValue, setSortValue ] = useState("");
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const navigate = useNavigate();
    useEffect(()=>{
        getPaymentList();
    },[page, rowPerPage,sortValue])

    const onRowChange = (event: any) => {
        setRowPerPage(parseInt(event.target.value));
        setPage(1);
      };


    const getPaymentList = () =>{
        axiosInstance.post(API_END_POINTS.PAYMENT_LIST,{
            page:page,
            limit:rowPerPage,
            period: sortValue ? `${sortValue}` :"",
            from_date: startDate ? moment(startDate).format("YYYY-MM-DD") : '',
            to_date: endDate ? moment(endDate).format("YYYY-MM-DD") :''
        }).then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
                setPaymentsList(response.data.result.data);
                setTotalCount(response.data.result.total_page);
                response.data.result.data.length > 0 ? setHasProducts(true) : setHasProducts(false);
              /* setPermissionData([...response.data.result.data]);
              setValues(response.data.result.admin_info); */
            }
          })
        };

    const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
        setPage(newPage);
      };


  const handleDateChange = (newDate:any) => {
    setStartDate(newDate);
  };

  const onApplyFilter = ()=>{
    page===1 ? getPaymentList() :setPage(1);
    
  }

  useEffect(()=>{
    if(startDate==='' && endDate==='')
      page==1 ? getPaymentList() :setPage(1);
  },[endDate])

  const onResetFilter = ()=>{
    if(startDate || endDate){
      setStartDate("");
      setEndDate("");
      
    }
     
  }

    return(
        <div className="payment-list-wrapper">
            <div className="filter-wrapper">

                <Select
                 value={sortValue}
                 displayEmpty
                 inputProps={{ 'aria-label': 'Without label' }}
                 onChange={(e:any)=>{ setSortValue(e.target.value)}}
                  className="filter-select">
                    <MenuItem value={""}>Sort By</MenuItem>
                    <MenuItem value={1}>1 Week</MenuItem>
                    <MenuItem value={2}>1 Month</MenuItem>
                    <MenuItem value={3}>3 Months</MenuItem>
                </Select>

                <div
                className="filter-wrap"
                onClick={() => {
                  if(filterApplied===true){
                    if(startDate || endDate)
                      onResetFilter()
                  }
                setFilterApplied(!filterApplied);
                }}
            >
                {!filterApplied && <FilterAltIcon />}
                {filterApplied && <FilterAltOffIcon />}
            </div>
            </div>

           { filterApplied && <div className="category_tabs_filter_container">
          <div className="filter-wrap">
            <div className="price_filter_label_wrapper">
            {/* <div> */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
              className="date-picker-one"
              value={startDate}
              maxDate={endDate}
              onChange={handleDateChange}/>
              <DatePicker
              className="date-picker-two"
                value={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={(e:any)=>{setEndDate(e)}}/>
            </LocalizationProvider>
         
            <div className="filter_action_buttons">
              <button className="reset-btn" onClick={onResetFilter}>
                {t("Reset")}
              </button>
              <button className="apply-btn" onClick={onApplyFilter}>
                {t("Apply")}
              </button>
            </div>
          </div>
          {/* </div> */}
            </div>
            
        </div>}
            <div className="tableWrapper">
        <div>
          <TableContainer component={Paper} className="categoryTable">
            <Table>
              <TableHead className="">
                <TableRow className="">
                  <TableCell>{t("S.NO")}</TableCell>
                  <TableCell>{t("Sender")}</TableCell>
                  <TableCell>{t("Receiver")}</TableCell>
                  <TableCell>{t("Service Provider Type")}</TableCell>
                  <TableCell>
                    {/*  onClick={() => handleSortID()} <TableSortLabel active={true} direction={idSort}> */}
                      {t("Total Amount")}
                    {/* </TableSortLabel> */}
                  </TableCell>

                  <TableCell>{t("Payment Type")}</TableCell>
                  <TableCell>{t("Payment Mode")}</TableCell>
                  <TableCell>{t("Payment Status")}</TableCell>
                  <TableCell className="date-cell">{t("Date")}</TableCell>
                {/*   <TableCell>{t("Qualification")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>{t("Active Status")}</TableCell> */}
                  <TableCell>{t("Actions")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {hasProducts &&
                  paymentList.map((payment: any, index: any) => {
                    return (
                      <TableRow key={index + 1}>
                        {/* <TableCell>{  index + 1 + (totalPage * rowPerPage) }</TableCell> */}
                        <TableCell>
                          {page - 1 === 0
                            ? index + 1
                            : (page - 1) * rowPerPage + (index + 1)}
                        </TableCell>

                        <TableCell>{payment.parent_name}</TableCell>
                        <TableCell>{payment.service_provider_name}</TableCell>
                        <TableCell className="capitalize">{payment.service_provider_type}</TableCell>
                        <TableCell>{payment.total_amount}</TableCell>

                        <TableCell className="name-cell row-capitalize">
                           { payment.payment_type}
                        </TableCell>
                        <TableCell className="name-cell row-capitalize">
                           { payment.payment_mode}
                        </TableCell>
                        <TableCell> { payment.payment_status}</TableCell>
                        <TableCell className="date">
                          {  moment(payment.created_on).format("DD-MM-YYYY, HH:mm")}
                        </TableCell>
                        <TableCell>
                          <div className="action-wrap">
                          
                              <img
                                onClick={() => {
                                  navigate(`/manage-payments/${payment.id}`);
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            
                            
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {hasProducts && (
            <div className="tablePagination">
              <div className="row">
                <div className="col-md-7">
                  <div className="pagination-wrap">
                    <Pagination
                      count={totalPage}
                      page={page}
                      onChange={handleChangePage}
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="rows-per-page">
                    <p>{t("Rows per page")}:</p>
                    <select
                      className="rows-select"
                      value={rowPerPage}
                      onChange={onRowChange}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!hasProducts && <NoProducts />}
        </div>
      </div>
        </div>
    )
};

export default PaymentList;