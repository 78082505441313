import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import "./TicketsList.scss";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  SORTING_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  SetSessionStorageData,
  checkValueNullUndefinedEmpty,
  nullUndefinedEmptyCheck,
} from "../../../Services/Helpers/Helper";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import UploadTickets from "../UploadTickets/UploadTickets";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import view from "../../../../Assets/global/view.svg";
import edit from "../../../../Assets/global/edit.svg";
import delete_icon from "../../../../Assets/global/delete.svg";
import AddEditBannerImg from "../AddEditBannerImg/AddEditBannerImg";

const TicketsList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [themeParkList, setThemeparkList] = useState([]);
  const [ allThemeParkList, setAllThemeParkList ] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("");
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [initialRender, setInitialRender] = useState(true);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [id, currentId] = useState();
  const [dialogStatus, setDialogStatus] = useState(false);
  const [hasProducts, setHasProducts] = useState(true);
  const [currentThemePark, setCurrentThemepark] = useState();
  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const [ticketsTotalPage, setTicketsTotalCount] = useState(0);
  const [ticketsPage, setticketsPage] = useState(1);
  const [ticketsRowPerPage, setTicketsRowPerPage] = useState(10);

  /* BannerList  */
  const [BannerList, setBannerList] = useState<any>([]);
  const [dialogOpenStatus, setDialogOpenStatus] = useState(API_RESPONSE.FALSE);

  /* Handling page change */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /* Handling page change */
  const handleTicketsChangePage = (
    event: React.ChangeEvent<any>,
    newPage: number
  ) => {
    setticketsPage(newPage);
  };

  /* Theme park list API call */
  const getThemeparkList = () => {
    let payload = {
      page: page,
      limit: rowPerPage,
      search: searchValue.trim(),
      sort: currentSort,
    };
    axiosInstance.post(API_END_POINTS.THEME_PARK_LIST, payload).then((res) => {
      if (res.data.status === true) {
        setThemeparkList(res.data.result.data);
        getTicketsLength(res.data.result.data);
        setTotalCount(res.data.result.total_page);
      }
    });
  };

  const getAllThemeparkList = () => {
    let payload = {
      page: 1,
      limit: 100,
      search: "",
      sort: "",
    };
    axiosInstance.post(API_END_POINTS.THEME_PARK_LIST, payload).then((res) => {
      if (res.data.status === true) {
        setAllThemeParkList(res.data.result.data);
        getTicketsLength(res.data.result.data);
        // setTotalCount(res.data.result.total_page);
      }
    });
  };

  /* TicketsList API call */
  const getTicketsList = () => {
    let payload = {
      themepark_id: currentThemePark,
      page: ticketsPage,
      limit: ticketsRowPerPage,
    };
    nullUndefinedEmptyCheck(currentThemePark)
      ? axiosInstance.post(API_END_POINTS.EVENTS_LIST, payload).then((res) => {
        if (res.data.status === true) {
          setEventsList(res.data.result.data);
          setTicketsTotalCount(res.data.result.total_page);
        }
      })
      : setEventsList([]);
  };

  /* Updated on change in search,page,sort value */

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        getThemeparkList();
      }
    }
    getAllThemeparkList()
  }, [searchValue, page, currentSort]);

  /* Tickets List API call on every themepark id change */
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      getTicketsList();
    }
  }, [currentThemePark, ticketsRowPerPage, ticketsPage]);

  const getTicketsLength = (tickets: any) => {
    tickets.length > 0 ? setHasProducts(true) : setHasProducts(false);
  };

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  /* Handling ID sort */
  const handleSortID = () => {
    idSort == SORTING_CONSTANT.ASC
      ? setIdSort(SORTING_CONSTANT.DSC)
      : setIdSort(SORTING_CONSTANT.ASC);
    idSort == SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_DESCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_ASCENDING);
  };

  const onRowChange = (event: any) => {
    setRowPerPage(parseInt(event.target.value));
  };
  const onTicketsRowChange = (event: any) => {
    setTicketsRowPerPage(event.target.value);
  };

  useEffect(() => {
    getThemeparkList();
  }, [rowPerPage]);

  /* Updating themepark ID */
  const onSelectThemepark = (event: any) => {
    setCurrentThemepark(event.target.value);
  };

  /**Reason dialog close */
  const onCloseDialog = () => {
    setDialogOpenStatus(API_RESPONSE.FALSE);
  };

  const onSubmitReason = (value: any) => {

  };

  return (
    <>
      <AddEditBannerImg
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
        submitReason={onSubmitReason}
        data={{ mode: COMMON_CONSTANTS.CREATE, payload: [] }}
      />
      <div className="row theme-list-wrap">
        <div className="col-md-12">
          <UploadTickets
            dialogState={dialogStatus}
            id={id}
            handleClose={() => {
              setDialogStatus(false);
              getTicketsList();
              getThemeparkList();
            }}
          />
          <div className="search_add_wrapper">
            <div
              className={
                currentLanguage === "English"
                  ? "custom__search"
                  : "custom__search-rtl"
              }
            >
              <TextField
                id="outlined-basic"
                placeholder={t("Search") || ""}
                variant="outlined"
                value={searchValue}
                onChange={onUpdateSearch}
                onKeyUp={(event: any) => {
                  if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                    setPage(1);
                    getThemeparkList();
                  }
                }}
              />
              <SearchIcon
                className="icon"
                onClick={() => {
                  getThemeparkList();
                }}
              />
            </div>
            <div className="btn-wrap-theme">
              <button
                className="add_button_wrapper me-3"
                onClick={() => { setDialogOpenStatus(API_RESPONSE.TRUE); }}
              >
                {t("Add Banner Image")}
              </button>
              <button
                className="add_button_wrapper"
                onClick={() => {
                  navigate(ROUTES.CREATE_THEMEPARK);
                }}
              >
                {t("Add Theme Park")}
              </button>
            </div>
          </div>

          {/*  <div className="btn-wrap-theme">
                    <button className="btn btn-primary" onClick={()=>{ navigate('/manage-tickets/theme-park')}}>{t('Add Theme Park')}</button>
                </div> */}
          <div className="tableWrapper">
            <TableContainer component={Paper} className="categoryTable">
              <Table>
                <TableHead className="theme-table-head">
                  <TableRow className="theme-table-row">
                    <TableCell className="list-headings">{t("S.NO")}</TableCell>
                    <TableCell className="list-headings">
                      {t("Theme Park ID")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Theme Park Name")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Email")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Phone")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Location")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Total Tickets")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Sold Tickets")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Remaining Tickets")}
                    </TableCell>
                    {/* <TableCell className="list-headings">
                    {t("Ticket Price")}
                  </TableCell> */}
                    <TableCell className="list-headings">
                      {t("Actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {hasProducts &&
                    themeParkList.map((ticket: any, index: any) => {
                      return (
                        <TableRow>
                          <TableCell>
                            {page - 1 === 0
                              ? index + 1
                              : (page - 1) * rowPerPage + (index + 1)}
                          </TableCell>
                          <TableCell className="name-cell row-capitalize">
                            {ticket.id}
                          </TableCell>
                          <TableCell className="name-cell row-capitalize">
                            {ticket.themepark_name}
                          </TableCell>
                          <TableCell className="row-capitalize">
                            {ticket.email}
                          </TableCell>
                          <TableCell className="row-capitalize">
                            {ticket.phone_number}
                          </TableCell>
                          <TableCell className="row-capitalize">
                            {ticket.address}
                          </TableCell>
                          <TableCell className="row-capitalize">
                            {ticket.overall_ticket}
                          </TableCell>
                          <TableCell className="row-capitalize">
                            {ticket.sold_ticket}
                          </TableCell>
                          <TableCell className="row-capitalize">
                            {ticket.available_ticket}
                          </TableCell>
                          <TableCell>
                            <div className="action-wrap">
                              <Tooltip
                                title={t("View Themepark detail")}
                                placement="bottom"
                              >
                                <img
                                  onClick={() => {
                                    navigate(`/manage-themepark/list/${ticket.id}`);
                                  }}
                                  className="view_edit_icons"
                                  src={view}
                                  alt="Learn and Play"
                                />
                              </Tooltip>
                              <Tooltip
                                title={t("Edit Themepark detail")}
                                placement="bottom"
                              >
                                <img
                                  onClick={() => {
                                    navigate(
                                      `/manage-themepark/list/theme-park/${ticket.id}`
                                    );
                                  }}
                                  className="view_edit_icons"
                                  src={edit}
                                  alt="Learn and Play"
                                />
                              </Tooltip>
                            </div>

                            {/* <Tooltip
                          title={t("View Themepark Tickets")}
                          placement="bottom"
                          onClick={() => {
                            navigate(`/manage-themepark/tickets/${ticket.id}`)
                          }}
                        >
                          <LocalActivityIcon className="tag-edit-icon" />
                        </Tooltip> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {hasProducts && (
              <div className="tablePagination">
                <div className="row">
                  <div className="col-md-7">
                    <div className="pagination-wrap">
                      <Pagination
                        count={totalPage}
                        page={page}
                        onChange={handleChangePage}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="rows-per-page">
                      <p>{t("Rows per page")}:</p>
                      <select
                        className="rows-select"
                        value={rowPerPage}
                        onChange={onRowChange}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!hasProducts && <NoProducts />}
          </div>
        </div>
      </div>

      <div className="row ticket-list-wrap">
        <div className="col-md-12">
          <div>
            {/* <p> Themepark Name: <span className="theme-park-name row-capitalize">{themeParkDetail?.themepark_name}</span> </p>  */}
          </div>
          <div className="tableWrapper">
            <div className="themepark-select-wrap">
              <div>
                <label className="select-label">
                  {t("Select Themepark to view tickets type")}:
                </label>
                <select
                  className="themepark-select name-cell"
                  onChange={onSelectThemepark}
                >
                  <option value={""}>-None-</option>(
                  {allThemeParkList?.map((ticket: any, index: any) => {
                    return (
                      <option className="name-cell" value={ticket?.id}>
                        {ticket?.themepark_name}
                      </option>
                    );
                  })}{" "}
                  )
                </select>
              </div>

              <button
                className="add_button_wrapper"
                onClick={() => {
                  setDialogStatus(true);
                }}
              >
                {t("Add Tickets")}
              </button>
            </div>
            <TableContainer component={Paper} className="categoryTable">
              <Table>
                <TableHead className="theme-table-head">
                  <TableRow className="theme-table-row">
                    <TableCell className="list-headings">{t("S.NO")}</TableCell>
                    <TableCell className="list-headings">
                      {t("Tickets type")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Total Tickets")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Sold Tickets")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Remaining Tickets")}
                    </TableCell>
                    <TableCell className="list-headings">
                      {t("Actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {eventsList.length > 0 && eventsList.map((ticket: any, index: any) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {ticketsPage - 1 === 0
                            ? index + 1
                            : (ticketsPage - 1) * rowPerPage + (index + 1)}
                        </TableCell>
                        <TableCell className="name-cell row-capitalize">
                          {ticket.ticket_type}
                        </TableCell>
                        <TableCell className="row-capitalize">
                          {ticket.overall_ticket}
                        </TableCell>
                        <TableCell className="row-capitalize">
                          {ticket.sold_ticket}
                        </TableCell>
                        <TableCell className="row-capitalize">
                          {ticket.available_ticket}
                        </TableCell>
                        <TableCell className="row-capitalize">
                          <div className="action-wrap">
                            <Tooltip
                              title={t("View Event Tickets")}
                              placement="bottom"
                            >
                              <img
                                onClick={() => {
                                  navigate(
                                    `/manage-themepark/tickets/list/${ticket.id}`
                                  );
                                }}
                                className="view_edit_icons"
                                src={view}
                                alt="Learn and Play"
                              />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {eventsList.length > 0 && (
              <div className="tablePagination">
                <div className="row">
                  <div className="col-md-7">
                    <div className="pagination-wrap">
                      <Pagination
                        count={ticketsTotalPage}
                        page={ticketsPage}
                        onChange={handleTicketsChangePage}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="rows-per-page">
                      <p>{t("Rows per page")}:</p>
                      <select
                        className="rows-select"
                        value={ticketsRowPerPage}
                        onChange={onTicketsRowChange}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketsList;
