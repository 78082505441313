import { env } from "process";
import { REGEX } from "../../Utilities/Constants/Validations";
import AWS from "aws-sdk";
import moment from "moment";
import axios from "axios";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
//  import S3 from 'react-aws-s3';

/** store data in session storage */
const SetSessionStorageData = (sessionKey: string, payload: any) => {
  return sessionStorage.setItem(sessionKey, payload);
};

/** get data from session storage */
const GetSessionStorageData = (sessionKey: string) => {
  return sessionStorage.getItem(sessionKey);
};
/** check for null, undefined and empty value */
const nullUndefinedEmptyCheck = (data: any) => {
  return data != null && data !== undefined && data !== "" && data !== " ";
};

const checkValueNullUndefinedEmpty = (data: any) => {
  return data === null || data === undefined || data === "" || data === " ";
};

/* To clear sessin storage */
const clearSessionStorage = () => {
  return sessionStorage.clear();
};

/* To validate only english on enter */
const validateEnglish = (e: any) => {
  if (
    REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value) &&
    !/[0-9.,]/.test(e.key)
  ) {
    return true;
  } else {
    e.preventDefault();
    return;
  }
};

const uploadFileToS3 = (file: any, credValue: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      accessKeyId: atob(credValue[0].value),
      secretAccessKey: atob(credValue[1].value),
      region: process.env.REACT_APP_S3_REGION,
    });

    const s3 = new AWS.S3();
    let splittedValue: any = file.name.split(' ');
    const params: any = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: splittedValue.length > 1 ? splittedValue.join('_') : file.name,
      Body: file,
      ACL: "public-read",
      ContentType: file?.type,
    };

    s3.upload(params, (err: any, data: any) => {
      if (err) {
        console.error("Error uploading file:", err);
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });
};

/* S3 upload from api backend  */
export const S3DataGetApi = async (event: any) => {
  // Check if event.target and event.target.files are defined
  const targetFiles = event?.target?.files;
  // Check if event.dataTransfer and event.dataTransfer.files are defined
  const dataTransferFiles = event?.dataTransfer?.files;
  let file;
  if (targetFiles && targetFiles.length > 0) {
    file = targetFiles[0];
  } else if (dataTransferFiles && dataTransferFiles.length > 0) {
    file = dataTransferFiles[0];
  } else {
    console.error('No files found in event', event);
    return; // Exit the function if no file is found
  }
  const formData = new FormData();
  formData.append('file', file);
  const url = `${process.env.REACT_APP_BASE_BACKEND_API_URL}${API_END_POINTS.S3_STATIC_UPLOAD}`;
  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response?.data?.result?.data[0];
  } catch (error) { }
};

/* To restrict inputs for only numbers and two numbers after decimal points */
const numberOnlyWithDecimal = (event: any) => {
  const input = event.target;
  let value = input.value;

  // Remove non-digit and non-decimal characters except the first dot
  const sanitizedValue = value.replace(
    /[^0-9.]/g,
    (match: any, offset: any) => {
      if (match === "." && offset === 0) {
        return match;
      }
      return "";
    }
  );

  // Limit to two digits after the decimal point
  const decimalIndex = sanitizedValue.indexOf(".");
  if (decimalIndex !== -1) {
    const decimalPart = sanitizedValue.substring(decimalIndex + 1);
    if (decimalPart.length > 2) {
      value = sanitizedValue.slice(0, decimalIndex + 3);
    } else {
      value = sanitizedValue;
    }
  } else {
    value = sanitizedValue;
  }

  // Update the input value
  input.value = value;

  // Set the state or perform any other necessary actions
  // For example, you can store the sanitized value in the component state
  // this.setState({ inputValue: value });
};
/* Only accepts number without decimal */

const numberOnly = (event: any) => {
  const input = event.target;
  const value = input.value;

  // Remove non-digit and non-decimal characters except the first dot
  const sanitizedValue = value.replace(/[^\d.]/g, (match: any, offset: any) => {
    if (match === "." && offset === 0) {
      return match;
    }
    return "";
  });

  // Limit to two digits after the decimal point
  const decimalIndex = sanitizedValue.indexOf(".");
  if (decimalIndex !== -1 && sanitizedValue.length - decimalIndex > 2 + 1) {
    input.value = sanitizedValue.slice(0, decimalIndex + 3);
  } else {
    input.value = sanitizedValue;
  }
};

const dateTimeConversion = (date: any) => {
  const utcDateTime = "2023-05-22T12:56:33.609409Z";
  const localDateTime = moment
    .utc(utcDateTime)
    .local()
    .format("DD-MM-YYYY HH:mm");
  return localDateTime;
};

/**empty length check */
const checkEmpty = (data: any): boolean => {
  return data.length !== 0;
};

const fetchLatLngByPlaceId = async (placeId: string,) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  );

  return await response.json();
};

const fetchGooglePlaceList = async (value: string) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${process.env.REACT_APP_GOOGLE_API_KEY}&input=${value}`, {
      headers: {
        'Origin': `${process.env.REACT_APP_BASE_BACKEND_API_URL}`, // Replace with your domain
        'Access-Control-Request-Method': 'GET',
        'Access-Control-Request-Headers': 'Authorization'
      }
    }
    );

    if (!response.ok) {
      // If the response is not okay, throw an error
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    if (data.status !== 'OK') {
      // If the API response status isn't OK, throw an error
      throw new Error(`Google API error: ${data.status}`);
    }
    // Extract predictions (place suggestions) from the API response
    const placesList = data?.predictions;

    return placesList; // This will return an array of place predictions

  } catch (error) {
    console.error('Error fetching places:', error);
    return []; // Return an empty array in case of an error
  }
};


export {
  SetSessionStorageData,
  GetSessionStorageData,
  nullUndefinedEmptyCheck,
  checkValueNullUndefinedEmpty,
  clearSessionStorage,
  validateEnglish,
  uploadFileToS3,
  numberOnlyWithDecimal,
  numberOnly,
  dateTimeConversion,
  checkEmpty,
  fetchGooglePlaceList,
  fetchLatLngByPlaceId
};
