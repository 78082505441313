import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./SessionsList.scss";
import { useTranslation } from "react-i18next";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  UPLOAD_TICKET_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import view from "../../../../Assets/global/view.svg";
import NoProducts from "../../NoProducts/NoProducts";
import { useLocation, useNavigate } from "react-router-dom";

const SessionsList = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [sessionList, setSessionList] = useState([]);
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [ hasProducts, setHasProducts ]= useState(API_RESPONSE.TRUE);
  const navigate = useNavigate();
  const [ categoryType, setCategoryType ]:any=useState();
  const location:any = useLocation();
 
  useEffect(() => {
    if(location.pathname.split('/')[COMMON_CONSTANTS.ONE]===UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL){
      setHasProducts(API_RESPONSE.FALSE)
      setCategoryType(VALIDATION_CONSTANT.RECREATION)
    }else{
      getSessionsList();
      setCategoryType(VALIDATION_CONSTANT.COURSE)
    }
    
  }, [props]);

  /**Tutors list payload */
  const getRecreationalStatusListPayload = () => {
    return {
      page: props.page,
      limit: props.rowPerPage,
      status: props.tabValue.toLowerCase(),
      /*   sort: props.currentSort,
        search: props.search,
        filter: props.filterPayload, */
    };
  };

  const getSessionsList = () => {
    axiosInstance
      .post(
        API_END_POINTS.GET_SESSIONS_LIST,
        getRecreationalStatusListPayload()
      )
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setSessionList(response.data.result.data);
          response.data.result.data.length > 0  ? setHasProducts(API_RESPONSE.TRUE) : setHasProducts(API_RESPONSE.FALSE);
        }
      });
  };

  return (
    <div className="sessions-list-wrapper">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("S.NO")}</TableCell>
              <TableCell>{t("Session Title")}</TableCell>
              <TableCell>{t("Category Name")}</TableCell>
              <TableCell>{t("Tutor Name")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell>{t("Price Per Slot(AED)")}</TableCell>
              <TableCell>{t("Action")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {hasProducts && sessionList.map((session: any,index:any) => {
              return (
                <TableRow>
                  <TableCell>
                   {page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}
                  </TableCell>
                  <TableCell>
                      {session.session_title}
                  </TableCell>
                  <TableCell>
                      {session.category_name}
                  </TableCell>
                  <TableCell>
                      {session.profile.firstname}
                  </TableCell>
                  <TableCell>
                      {session.profile.email}
                  </TableCell>
                  <TableCell>
                      {session.avg_fees_per_slot}
                  </TableCell>
                  <TableCell>
                     <div>
                      <img className="table-icon" src={view} onClick={()=>{navigate(`/manage-recreational/sessions/${session.id}`)}}/>
                     </div>
                  </TableCell>
                </TableRow>
              );
            })}
            
          </TableBody>
         
        </Table>
        {
             !hasProducts  && <div>
                <NoProducts/>
              </div>
            }
      </TableContainer>
    </div>
  );
};

export default SessionsList;
