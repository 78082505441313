import "./ActivitiesStatusListLayout.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  ACTIVITIES_TYPE,
  API_RESPONSE,
  COMMON_CONSTANTS,
  DIALOG_CONSTANT,
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  ONBOARDING_ROUTES,
  SERVICES_STATUS,
  SORTING_CONSTANT,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
} from "../../../../Utilities/Constants/Constants";
import { axiosInstance } from "../../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../../Utilities/Constants/ApiEndpoints";
import { setTabData } from "../../../../Services/Redux/Reducer/Reducer";
import ConfirmationDialog from "../../../../Shared/ConfirmationDialog/ConfirmationDialog";
import { GetSessionStorageData, checkValueNullUndefinedEmpty, nullUndefinedEmptyCheck } from "../../../../Services/Helpers/Helper";
import ReasonDialog from "../../../../Shared/ReasonDialog/ReasonDialog";
import ActivitiesStatusList from "../Shared/ActivitiesStatusList/ActivitiesStatusList";
import { toast } from "react-toastify";
import { Checkbox, MenuItem, Select, Slider, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

const ActivitiesStatusListLayout = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [value, setValue] = React.useState(SERVICES_STATUS.PENDING);
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);
  const [nameSort, setNameSort]: any = useState(SORTING_CONSTANT.ASC);
  const [initialRender, setInitialRender] = useState(true);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [approveStatusId, setApproveStatusId] = useState("");
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const dialogTitle = DIALOG_CONSTANT.TITLE_REJECTION;
  const [statusUpdated, setStatusUpdated] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const [filterApplied, setFilterApplied] = useState(false);
  const [gender, setGender]: any = useState([]);
  const [ratingList, setRatingList]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [activityList, setActivityList]: any = useState([]);
  const [activity, setActivity]: any = useState([]);
  const [experienceRange, setExperienceRange] = useState<number[]>([]);
  const [currentExperienceRange, setCurrentExperienceRange] = useState<number[]>([
    experienceRange[COMMON_CONSTANTS.ZERO],
    experienceRange[COMMON_CONSTANTS.ONE],
  ]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [userType, setUserType] = useState();
  const [isSuperAdmin, setIsSuperAdmin]: any = useState(API_RESPONSE.FALSE);
  const [tabOnePermission, setTabOnePermission] = useState(API_RESPONSE.TRUE);
  const [tabTwoPermission, setTabTwoPermission] = useState(API_RESPONSE.TRUE);
  const [tabThreePermission, setTabThreePermission] = useState(API_RESPONSE.TRUE);
  const permissions: any = useSelector((state: any) => {
    return state.permissionReducer.permission;
  })

  useEffect(() => {
    location.pathname.split("/")[1] === ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST ? setUserType(ACTIVITIES_TYPE.PERSONAL) : setUserType(ACTIVITIES_TYPE.CAMP);
  }, [location]);

  useEffect(() => {
    setIsSuperAdmin(GetSessionStorageData(COMMON_CONSTANTS.IS_SUPER_ADMIN));
  }, [])

  const setActiveTab = (data: any, length: any) => {
    for (let i = 0; i <= length; i++) {
      if (data.data[i].checked === API_RESPONSE.TRUE) {
        switch (i) {
          case 0:
            setValue(SERVICES_STATUS.APPROVED);
            break;
          case 1:
            setValue(SERVICES_STATUS.PENDING);
            break;
          case 2:
            setValue(SERVICES_STATUS.REJECTED);
            break;
        }
        break;
      }
    }
  };


  useEffect(() => {
    if (isSuperAdmin === `${API_RESPONSE.TRUE}`) {
      setTabOnePermission(API_RESPONSE.TRUE);
      setTabTwoPermission(API_RESPONSE.TRUE);
      setTabThreePermission(API_RESPONSE.TRUE);
      setValue(SERVICES_STATUS.PENDING);
    } else {
      if (userType === ACTIVITIES_TYPE.PERSONAL) {
        setTabOnePermission(permissions[1]?.data[2].data[0].checked);
        setTabTwoPermission(permissions[1]?.data[2].data[1].checked);
        setTabThreePermission(permissions[1]?.data[2].data[2].checked);
        setActiveTab(permissions[1]?.data[2], permissions[1]?.data[2].data.length)
      } else {
        setTabOnePermission(permissions[1]?.data[3].data[0].checked);
        setTabTwoPermission(permissions[1]?.data[3].data[1].checked);
        setTabThreePermission(permissions[1]?.data[3].data[2].checked);
        setActiveTab(permissions[1]?.data[3], permissions[1]?.data[2].data.length)
      }
    }
  }, [userType])

  useEffect(() => {
    getFilterDetails();
    setFilterApplied(false);
    setFilterPayload({})
    setSearchValue("")
  }, [location])

  const getFilterDetails = () => {
    axiosInstance.get(API_END_POINTS.RECREATIONAL_FILTER_DETAILS + "?type=" +
      (location.pathname.split("/")[1] ===
        ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST
        ? ACTIVITIES_TYPE.PERSONAL
        : ACTIVITIES_TYPE.CAMP)).then(response => {
          if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
            let data = response.data.result.data[0];
            setExperienceRange([parseInt(data.experience.Min), parseInt(data.experience.Max)]);
            setCurrentExperienceRange([data.experience.Min, data.experience.Max]);
            setRatingList(data.rating);
            setActivityList(data.activity);
          }
        })
  }


  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        onSearchData();
      }
    }
  }, [searchValue]);


  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /**Get current language */
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /**previous tab position */
  const tabsData: any = useSelector((state: any) => {
    return state.tabsReducer.tabPosition;
  });

  useEffect(() => {
    if (nullUndefinedEmptyCheck(tabsData)) {
      setValue(tabsData);
    } else {
      setValue(SERVICES_STATUS.PENDING);
    }
  }, []);


  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const hadleExperienceSlider = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const formattedRange: any = newValue.map((value) => {
        const nearest = parseInt(value.toString());
        return nearest;
      });
      setCurrentExperienceRange(formattedRange);
    }
  };


  function valuetext(value: number) {
    return `${value}`;
  }

  /* Handling Category code sorting */
  const handleCodeSort = () => {
    idSort === SORTING_CONSTANT.ASC
      ? setIdSort(SORTING_CONSTANT.DSC)
      : setIdSort(SORTING_CONSTANT.ASC);
    idSort === SORTING_CONSTANT.ASC
      ? setCurrentSort(SORTING_CONSTANT.ID_ASCENDING)
      : setCurrentSort(SORTING_CONSTANT.ID_DESCENDING);
  };

  const onChangeGender = (e: any) => {
    setGender(e.target.value);
  };

  const onSetRating = (e: any) => {
    setRating(e.target.value);
  };
  const onSetActivity = (e: any) => {
    setActivity(e.target.value);
  };

  /**Handle row per page change */
  const onRowChange = (event: any) => {
    setRowPerPage(parseInt(event.target.value));
  };

  /**reset filter, search and sort values to default while swtching tabs */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchValue("");
    setPage(1);
    setCurrentSort("");
  };

  /**Update page number */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  /**handle reject reason dialog close */
  const onCloseDialogBox = () => {
    setDialogOpenStatus(false);
  };

  /** handle reject reason submit */
  const onSubmitReason = (value: any) => {
    updateRecreationalStatus(
      approveStatusId,
      VALIDATION_CONSTANT.REJECTED,
      value
    );
  };

  /**handle open reject reson dialog */
  const approveReject = (id: any, status: any) => {
    setApproveStatusId(id);
    setDialogOpenStatus(true);
  };

  const [searchPayloadValue, setSearchPayloadValue]: any = useState("");
  /* Calling Search API */
  const onSearchData = () => {
    // getSchoolsList();
    setSearchPayloadValue(searchValue);
    setPage(1);
  };


  /*  Setting Filter payload */
  const onApplyFilter = () => {
    setFilterPayload({
      experience: [
        {
          start: currentExperienceRange[COMMON_CONSTANTS.ZERO],
          end: currentExperienceRange[COMMON_CONSTANTS.ONE],
        },
      ],
      rating: rating,
      activity: activity,
      gender: gender
    });
  };

  /**recreational status payload  */
  const getRecreationalStatusPayload = (activitiesStatus: any, reason: any) => {
    return {
      status: activitiesStatus,
      profile_verified:
        activitiesStatus === VALIDATION_CONSTANT.REJECTED ? false : true,
      reason: reason.reason,
    };
  };

  /**update activity status */
  const updateRecreationalStatus = (id: any, status: any, reason: any) => {
    axiosInstance
      .put(
        API_END_POINTS.GET_RECREATIONAL_DETAIL +
        `?id=${id}&type=` +
        (location.pathname.split("/")[1] ===
          ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST
          ? ACTIVITIES_TYPE.PERSONAL
          : ACTIVITIES_TYPE.CAMP) +
        `&page=${3}`,
        getRecreationalStatusPayload(status, reason)
      )
      .then((recreationalStatusResponse: any) => {
        if (
          recreationalStatusResponse.data[API_RESPONSE.STATUS] ===
          API_RESPONSE.TRUE
        ) {
          toast.success(
            recreationalStatusResponse.data.message,
            TOASTER_CONFIGS
          );
          setStatusUpdated(!statusUpdated);
          onCloseDialogBox();
        }
      });
  };

  /* View category Redirection */
  const viewcategory = (id: any) => {
    dispatch(setTabData(value));
    navigate(
      location.pathname.split("/")[1] ===
        ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST
        ? `/activities-personal-status-list/${id}`
        : `/activities-campus-status-list/${id}`
    );
  };

  useEffect(() => {
    if (filterApplied && activity.length === COMMON_CONSTANTS.ZERO) {
      onApplyFilter();
    }
  }, [activity])

  const resetFilter = () => {

    setRating([]);
    setGender([]);
    setCurrentExperienceRange([
      experienceRange[COMMON_CONSTANTS.ZERO],
      experienceRange[COMMON_CONSTANTS.ONE],
    ]);
    setActivity([]);

  };

  return (
    <div
      className="school_status_list_wrapper"
      dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
    >
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialogBox}
        submitReason={onSubmitReason}
        title={dialogTitle}
      />
      <div className="search_add_wrapper">
        <div
          className={
            language === LANGUAGES.ENGLISH
              ? "custom__search"
              : "custom__search-rtl"
          }
        >
          <TextField
            id="outlined-basic"
            placeholder={t("Search") || ""}
            variant="outlined"
            value={searchValue}
            onChange={onUpdateSearch}
            onKeyUp={(event: any) => {
              if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                onSearchData();
              }
            }}
          />
          <SearchIcon className="icon" onClick={onSearchData} />
        </div>
        <div
          className="filter-wrap"
          onClick={() => {
            setFilterApplied(!filterApplied);
          }}
        >
          {!filterApplied && <FilterAltIcon />}
          {filterApplied && <FilterAltOffIcon />}
        </div>
      </div>

      {filterApplied && (
        <div className="filter-wrap-container">
          <div className="filter-items-wrap">
            <div className="filter-item">
              <Box className="slider-box">
                <label className="filter-label">{t("Experience")}:</label>
                <Slider
                  min={experienceRange[0]}
                  step={1}
                  max={experienceRange[1]}
                  getAriaLabel={() => "Price Range"}
                  value={currentExperienceRange}
                  onChange={hadleExperienceSlider}
                  valueLabelDisplay={"on"}
                  // marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory"
                />
              </Box>
            </div>

            {/*   <div className="filter-item">
              <label className="filter-label">Teaching Language</label>
              <Select
                multiple
                value={selectedLanguage}
                className="filter-select"
                onChange={onSetLanguage}
                renderValue={(selected) => selected.join(", ")}
              >
                {languageList.map((language: any, index: any) => {
                  return (
                    // <option value={language}>{language}</option>
                    <MenuItem value={language}>
                      <Checkbox
                        checked={selectedLanguage.includes(`${language}`)}
                      />
                      {language}
                    </MenuItem>
                  );
                })}
              </Select>
            </div> */}
            <div className="filter-item">
              <label className="filter-label">{t('Activity')}</label>
              <Select
                multiple
                value={activity}
                className="filter-select"
                onChange={onSetActivity}
                renderValue={(selected) => selected.join(", ")}
              >
                {activityList.map((type: any, index: any) => {
                  return (
                    <MenuItem value={type}>
                      <Checkbox checked={activity.includes(`${type}`)} />
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div className="filter-item">
              <label className="filter-label">{t('Rating')}</label>
              <Select
                multiple
                value={rating}
                className="filter-select"
                onChange={onSetRating}
                renderValue={(selected) => selected.join(", ")}
              >
                {ratingList.map((ratings: any, index: any) => {
                  return (
                    <MenuItem value={ratings}>
                      <Checkbox
                        checked={rating.includes(`${ratings}`)}
                      />
                      {ratings}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="filter-item">
              <label className="filter-label">{t('Gender')}</label>
              <Select
                multiple
                value={gender}
                className="filter-select"
                onChange={onChangeGender}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="male">
                  <Checkbox checked={gender.includes("male")} />
                  {t('Male')}
                </MenuItem>
                <MenuItem value="female">
                  {" "}
                  <Checkbox checked={gender.includes("female")} />
                  {t('Female')}
                </MenuItem>
                <MenuItem value="others">
                  {" "}
                  <Checkbox checked={gender.includes("others")} />
                  {('Others')}
                </MenuItem>
              </Select>
            </div>

          </div>
          <div className="filter-buttons-wrap">
            <button className="btn btn-primary" onClick={resetFilter}>
              {t('RESET')}
            </button>
            <button className="btn btn-secondary" onClick={onApplyFilter}>
              {t('APPLY')}
            </button>
          </div>
        </div>
      )}

      <div className="filter_category_list_container">
        <div className="category_tabs_filter_container">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
               {tabTwoPermission && <Tab
                value={SERVICES_STATUS.PENDING}
                label={t(SERVICES_STATUS.PENDING)}
              />}
              {tabOnePermission && <Tab
                value={SERVICES_STATUS.APPROVED}
                label={t(SERVICES_STATUS.APPROVED)}
              />}
              {tabThreePermission && <Tab
                value={SERVICES_STATUS.REJECTED}
                label={t(SERVICES_STATUS.REJECTED)}
              />}
            </Tabs>
          </Box>
          {/* <div className="filter-wrap">
            <div className="price_filter_label_wrapper">
              <Box className="slider-box">
                <label className="filter-label">{t("Price Range")}:</label>
                <Slider
                  min={0}
                  step={1}
                  max={1000}
                  getAriaLabel={() => "Price Range"}
                  value={sliderValue}
                  onChange={handleSliderChange}
                  valueLabelDisplay={
                    sliderValue[0] > 0 || sliderValue[1] < 1000 ? "on" : "auto"
                  }
                  marks={marks}
                  getAriaValueText={valuetext}
                  className="slidercategory"
                />
              </Box>
            </div>
            <div className="filter_action_buttons">
              <button className="reset-btn" onClick={onResetFilter}>
                {t("Reset")}
              </button>
              <button className="apply-btn" onClick={onApplyFilter}>
                {t("Apply")}
              </button>
            </div>
          </div> */}
        </div>
        <ActivitiesStatusList
          handleCodeSort={handleCodeSort}
          idSort={idSort}
          totalPage={totalPage}
          setTotalPage={setTotalPage}
          page={page}
          handleChangePage={handleChangePage}
          rowPerPage={rowPerPage}
          onRowChange={onRowChange}
          tabValue={value}
          approveReject={approveReject}
          updateRecreationalStatus={updateRecreationalStatus}
          currentSort={currentSort}
          statusUpdated={statusUpdated}
          viewcategory={viewcategory}
          search={searchPayloadValue}
          userType={userType}
          filterPayload={filterPayload}
        />
      </div>
    </div>
  );
};

export default ActivitiesStatusListLayout;
