import "./AddEditContent.scss";
import { Formik, Field } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useSelector } from "react-redux";
import {
    ERROR_MESSAGES,
    PROMOTIONAL_CONTENT_ERROR_MESSAGES,
    REGEX,
} from "../../../Utilities/Constants/Validations";
import { toast } from "react-toastify";
import {
    API_RESPONSE,
    COMMON_CONSTANTS,
    I18_TRANSLATION_COMMON,
    ROUTES,
    TOASTER_CONFIGS,
    UPLOAD_TICKET_CONSTANT,
    VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import {
    S3DataGetApi,
    checkValueNullUndefinedEmpty,
    nullUndefinedEmptyCheck,
    uploadFileToS3,
} from "../../../Services/Helpers/Helper";
import backButton from "../../../../Assets/schools/back_button.svg";
import FileViewerDialog from "../../FileViewerDialog/FileViewerDialog";
import CloseIcon from "@mui/icons-material/Close";
import { store } from "../../../Services/Redux/Store/Store";
import { setLoader } from "../../../Services/Redux/Reducer/Reducer";
import { ConstructionOutlined } from "@mui/icons-material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";


const AddEditContent = () => {

    const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
    const navigate = useNavigate();
    const [contentValue, setContentValue] = useState({
        id: null,
        description: "",
        description_ar: "",
        image: "",
        web_link: "",
        mobile_link: "",
        service_provider_type: "",
        provider_id: null,
    });
    // min_experience, max_experience
    const [minPrice, setMinPrice] = useState();
    const [categoryType, setCategoryType] = useState("none");
    const [currentEngName, setCurrentEngName] = useState("");
    const [currentArabicName, setCurrentArabicName] = useState("");
    const [contentImage, setContentImage] = useState<any>("");
    const [displayImage, setDisplayImage] = useState<any>("");
    const uploadDisplayImage = useRef<HTMLInputElement | null>(null);
    const contentImageRef = useRef<HTMLInputElement | null>(null);
    const [fileViewerOpen, setFileViewerOpen] = useState(false);
    const [viewerFile, setViewerFile] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
    const [gradesList, setGradesList]: any = useState([]);
    const [type, setType] = useState("");
    const { id } = useParams();
    const currentLanguage = useSelector((state: any) => {
        return state.languageReducer.language;
    });
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState<any>(location?.state?.index);
    const aws: any = useSelector((state: any) => {
        return state.awsReducer.aws;
    });
    /* To get id from params */
    useEffect(() => {
        if (location.pathname.split("/")[1] === UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL && id) {
            // if()
            // setType(COMMON_CONSTANTS.TWO)
            setType(location?.state?.userType?.value)
        } else if (location.pathname.split("/")[1] === UPLOAD_TICKET_CONSTANT.MANAGE_TUTORS) {
            setType(COMMON_CONSTANTS.ONE)
        }


    }, []);

    useEffect(() => {
        if (nullUndefinedEmptyCheck(id) /* && nullUndefinedEmptyCheck(type) */) {
            getContent(id);
        } else {
            setContentValue({
                id: null,
                description: "",
                description_ar: "",
                image: "",
                web_link: "",
                mobile_link: "",
                service_provider_type: "",
                provider_id: null
            });
        }
    }, [id]);

    /* To restrict inputs for only numbers */
    function numberOnly(event: any) {
        const input = event.target;
        const value = input.value;

        // Remove non-digit and non-decimal characters except the first dot
        const sanitizedValue = value.replace(
            /[^\d.]/g,
            (match: any, offset: any) => {
                if (match === "." && offset === 0) {
                    return match;
                }
                return "";
            }
        );

        // Limit to two digits after the decimal point
        const decimalIndex = sanitizedValue.indexOf(".");
        if (decimalIndex !== -1 && sanitizedValue.length - decimalIndex > 2 + 1) {
            input.value = sanitizedValue.slice(0, decimalIndex + 3);
        } else {
            input.value = sanitizedValue;
        }
    }

    const error_message = type === COMMON_CONSTANTS.ONE ? "Please enter valid Tutor Id" : type === COMMON_CONSTANTS.TWO ? "Please enter valid Personal Trainer Id" : ""

    /* Form Validation using yup */
    const contentValidationSchema = yup.object({
        description: yup
            .string()
            .min(3, 'Description must be at least 3 characters long')
            .max(65, 'Description must be at most 65 characters long')
            .required(PROMOTIONAL_CONTENT_ERROR_MESSAGES.DESCRIPTION_REQUIRED),
        //
        description_ar: yup
            .string()
            .min(3, 'Description must be at least 3 characters long')
            .max(65, 'Description must be at most 65 characters long')
            .required(PROMOTIONAL_CONTENT_ERROR_MESSAGES.DESCRIPTION_REQUIRED_ARABIC),
        provider_id: yup
            .string()
            .matches(REGEX.NUMBER_REGEX, error_message)
            .nullable(),
        // web_link: yup
        //     .string()
        //     .matches(REGEX.URL_VALIDATION, PROMOTIONAL_CONTENT_ERROR_MESSAGES.INVALID_WEB_LINK)
        //     .required(PROMOTIONAL_CONTENT_ERROR_MESSAGES.WEB_LINK_REQUIRED),
        // mobile_link: yup
        //     .string()
        //     .matches(REGEX.URL_VALIDATION, PROMOTIONAL_CONTENT_ERROR_MESSAGES.INVALID_MOBILE_LINK)
        //     .required(PROMOTIONAL_CONTENT_ERROR_MESSAGES.MOBILE_LINK_REQUIRED),
        image: yup
            .string()
            .required(PROMOTIONAL_CONTENT_ERROR_MESSAGES.IMAGE_REQUIRED)

    });


    /* Get content API call */
    const getContent = (id: any) => {
        axiosInstance
            .get(
                API_END_POINTS.PROMOTIONAL_CONTENT +
                "?id=" +
                id +
                "&service_provider_type=" +
                type
            )
            .then((resp) => {
                if (resp.data.status === true) {
                    let data = resp.data.result.data;
                    setContentValue(data);
                    setType(data?.service_provider_type)
                    setContentImage(resp.data.result.data.image)
                }
            });
    };



    /* Save Category API call */
    function onSaveContent(event: any) {
        let data = event;

        axiosInstance
            .post(
                API_END_POINTS.PROMOTIONAL_CONTENT,
                data
            )
            .then((res: any) => {
                if (res.data.status === true) {
                    onNavigate();
                    toast.success(res.data.message, TOASTER_CONFIGS);
                }
            });
    }

    /* Edit content API call */
    function onEditContent(event: any) {
        let data = event;
        data.id = event.id;
        data.description = event?.description;
        data.description_ar = event?.description_ar;
        data.image = event?.image;
        data.web_link = event?.web_link;
        data.mobile_link = event?.mobile_link;
        data.provider_id = event?.provider_id != "" ? `${event?.provider_id}` : null;
        axiosInstance
            .put(
                API_END_POINTS.PROMOTIONAL_CONTENT, data
            )
            .then((res: any) => {
                if (res.data.status === true) {
                    toast.success(res.data.message, TOASTER_CONFIGS);
                    onNavigate();
                }
            });
    }

    /* For Navigating */
    const onNavigate = () => {

        if (type === COMMON_CONSTANTS.ONE) {
            navigate(ROUTES.TUTOR_PROMOTIONAL_CONTENT_LIST)
        } else {
            navigate(ROUTES.RECREATIONAL_PROMOTIONAL_CONTENT_LIST)
        }

    };

    const handleChange = (event: any) => {
        setCategoryType(event.target.value);
    };

    const handleImageChange = async (event: any) => {


        try {

            const file = event.target.files[0];

            if (!file.type.startsWith('image/')) {
                toast.error('Invalid file type. Only image files are allowed.');
                return;
            }


            store.dispatch(setLoader(true));

            // Upload image to S3
            const uploadedImage: string = await S3DataGetApi(event);

            // Update contentImage state with the uploaded image URL
            setContentImage(uploadedImage);

            store.dispatch(setLoader(false));
        } catch (error) {
            console.error('Error uploading image:', error);
            store.dispatch(setLoader(false));
        }
    }

    const validateArabic = (e: any) => {
        if (REGEX.ARABIC_INPUT_VALIDATION.test(e.target.value)) {
            return;
        } else {
            e.preventDefault();
            return;
        }
    };

    const validateArabicPaste = (e: any) => {
        if (REGEX.ARABIC_INPUT_VALIDATION.test(e.clipboardData.getData("text"))) {
            return;
        } else {
            e.preventDefault();
            return;
        }
    };

    const validateEnglish = (e: any) => {
        if (
            REGEX.ENGLISH_INPUT_VALIDATION.test(e.target.value) &&
            !/[0-9.,]/.test(e.key)
        ) {
            return;
        } else {
            e.preventDefault();
            return;
        }
    };

    const validateEnglishPaste = (e: any) => {
        if (REGEX.ENGLISH_INPUT_VALIDATION.test(e.clipboardData.getData("text"))) {
            return;
        } else {
            e.preventDefault();
            return;
        }
    };

    const onEnglishNameChange = (event: any) => {
        if (REGEX.ENGLISH_INPUT_VALIDATION.test(event.target.value)) {
            //event.target.value.trim() !='' &&
            setCurrentEngName(event.target.value);
        }
    };

    const onArabicNameChange = (event: any) => {
        if (REGEX.ARABIC_INPUT_VALIDATION.test(event.target.value)) {
            setCurrentArabicName(event.target.value);
        }
    };

    /**Handle file viwer dialog close */
    const onFilerViewerDialogClose = () => {
        setFileViewerOpen(API_RESPONSE.FALSE);
        setViewerFile("");
    };

    /**Handle file viwer dialog open */
    const onFilerViewerDialogOpen = (url: any) => {
        setViewerFile(url);
        setFileViewerOpen(true);
    };

    return (
        <div
            className="add-category-container"
            dir={currentLanguage === "English" ? "ltr" : "rtl"}
        >
            <div className="page-title">
                <img
                    className="arrow_icon"
                    src={backButton}
                    alt="Learn and Play"
                    onClick={onNavigate}
                />
                <h5 className="name-title">
                    {id ? t("Edit") : t("Add")} {t("Promotional Content")}
                </h5>
            </div>

            <FileViewerDialog
                open={fileViewerOpen}
                handleClose={onFilerViewerDialogClose}
                viewerFile={viewerFile}
            ></FileViewerDialog>

            <Formik
                enableReinitialize={true}
                validationSchema={contentValidationSchema}
                initialValues={{
                    id: contentValue.id ? contentValue.id : "",
                    description: contentValue.description,
                    description_ar: contentValue.description_ar,
                    image: contentImage,
                    mobile_link: contentValue.mobile_link,
                    web_link: contentValue.web_link,
                    service_provider_type: type,
                    provider_id: contentValue.provider_id != "" ? contentValue.provider_id : null,
                }}
                onSubmit={(values) => {
                    if (formSubmitted && (type == COMMON_CONSTANTS.TWO || type == COMMON_CONSTANTS.THREE) && type !== "") {
                        setFormSubmitted(API_RESPONSE.FALSE)
                        if (id) {
                            onEditContent(values);
                        } else {
                            onSaveContent(values);
                        }
                    } else if (formSubmitted && type === COMMON_CONSTANTS.ONE) {
                        setFormSubmitted(API_RESPONSE.FALSE)
                        if (id) {
                            onEditContent(values);
                        } else {
                            onSaveContent(values);
                        }
                    }

                }}
            >
                {(props) => {
                    return (
                        <div className="">
                            <div className="box-wrapper">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="categoryWrap">
                                            <label className="nameLabel">{t("Promotional Description")}</label>
                                            <Field
                                                className="input"
                                                placeholder={t("Promotional Description")}
                                                name="description"
                                                maxLength={65}
                                                minLength={3}
                                                onChange={(e: any) => setContentValue({ ...contentValue, 'description': e.target.value })}
                                            />

                                            <p className="category-note mb-1">Note: Max limit 65 char </p>
                                            <div className="errorMessage">
                                                {props.touched.description &&
                                                    props.errors.description && (
                                                        <p>{t(`${props.errors.description}`)}</p>
                                                    )}
                                            </div>
                                            {/*  */}
                                            <Field
                                                className="input"
                                                placeholder={t("Promotional Description Arabic")}
                                                name="description_ar"
                                                maxLength={65}
                                                minLength={3}
                                                onChange={(e: any) => setContentValue({ ...contentValue, 'description_ar': e.target.value })}
                                                dir="rtl"
                                            // onKeyPress={validateArabic}
                                            // onPaste={validateArabicPaste}
                                            />

                                            <p className="category-note mb-1">Note: Max limit 65 char </p>
                                            <div className="errorMessage">
                                                {props.touched.description_ar &&
                                                    props.errors.description_ar && (
                                                        <p>{t(`${props.errors.description_ar}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>



                                    {type !== COMMON_CONSTANTS.ONE && <div className="col-md-6">
                                        <div className="categoryWrap">

                                            <label className="nameLabel">
                                                {t("Recreational Type")}:
                                            </label>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={type}
                                                onChange={(e: any) => {
                                                    setType(e.target.value);
                                                }}
                                            >
                                                <FormControlLabel className="discount-form-label" value={2} control={<Radio className="radio-btn" />} label="Personal Trainer" />
                                                <FormControlLabel className="discount-form-label" value={3} control={<Radio className="radio-btn" />} label="Camp" />
                                            </RadioGroup>
                                        </div>
                                        <div className="errorMessage">
                                            {
                                                formSubmitted && type === "" && <p>
                                                    {"Please select recreational type"}
                                                </p>
                                            }
                                        </div>
                                    </div>}

                                    <div className="col-md-6">
                                        <div className="categoryWrap">
                                            <label className="nameLabel ">
                                                {type === COMMON_CONSTANTS.ONE ? t("Tutor Id") : type === COMMON_CONSTANTS.TWO ? t("Personal Trainer Id") : "Camp Id"}
                                            </label>
                                            <Field
                                                className="input"
                                                placeholder="e.g., 13"
                                                name="provider_id"
                                                type="text"
                                                onChange={(e: any) => setContentValue({ ...contentValue, 'provider_id': e.target.value })}
                                            />
                                            {/* <p className="category-note mb-1">Note: Get  </p> */}
                                            <div className="errorMessage">
                                                {props.touched.provider_id &&
                                                    props.errors.provider_id && (
                                                        <p>{t(`${props.errors.provider_id}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6">
                                        <div className="categoryWrap">
                                            <label className="nameLabel ">
                                                {t("Web Link")}
                                            </label>
                                            <Field
                                                className="input"
                                                placeholder="e.g., https://schoolname.com"
                                                name="web_link"
                                            />
                                            <div className="errorMessage">
                                                {props.touched.web_link &&
                                                    props.errors.web_link && (
                                                        <p>{t(`${props.errors.web_link}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="categoryWrap">
                                            <label className="nameLabel ">
                                                {t("Mobile Link")}
                                            </label>
                                            <Field
                                                className="input"
                                                placeholder="e.g., https://schoolname.com"
                                                name="mobile_link"
                                            />
                                            <div className="errorMessage">
                                                {props.touched.mobile_link &&
                                                    props.errors.mobile_link && (
                                                        <p>{t(`${props.errors.mobile_link}`)}</p>
                                                    )}
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="col-md-6">

                                        <input
                                            className="image_input"
                                            type="file"
                                            accept="image/png,image/jpg,image/jpeg,image/svg"
                                            // multiple
                                            onChange={handleImageChange}
                                            ref={contentImageRef}
                                        />

                                        <div className="categoryWrap">
                                            <label className="nameLabel">
                                                {t("Promotional Image")}:
                                            </label>
                                            <div className="display-img-wrapper">
                                                {contentImage &&
                                                    <div className="image-close-wrapper">
                                                        <img className="display-image" src={contentImage} onClick={() => {
                                                            onFilerViewerDialogOpen(contentImage);
                                                        }} />
                                                        <CloseIcon className="image-close-icon" onClick={() => {
                                                            setContentImage("");
                                                        }} />
                                                    </div>}
                                                <button className="btn btn-secondary" onClick={() => contentImageRef.current?.click()}>Upload Image</button>
                                            </div>
                                            {(type === COMMON_CONSTANTS.ONE && activeIndex <= 1) ?
                                                <div className="text_contant">Recommended size: 216x134 with JPEG,PNG</div> :
                                                (type === COMMON_CONSTANTS.TWO && activeIndex === 0) ?
                                                    <div className="text_contant">Recommended size: 195x129 with JPEG,PNG</div> :
                                                    <div className="text_contant">Recommended size: 216x275 with JPEG,PNG</div>}

                                        </div>

                                        <div className="errorMessage">
                                            {formSubmitted && contentImage === '' && (
                                                <p>{t(`Image is required`)}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="button-wrap">
                                <button className="cancel_button" onClick={onNavigate}>
                                    {t("Cancel")}
                                </button>
                                <button
                                    className="save_button"
                                    type="submit"
                                    onClick={() => {
                                        props.handleSubmit();
                                        setFormSubmitted(API_RESPONSE.TRUE)
                                    }}
                                >
                                    {id ? t("Update") : t("Save")}
                                </button>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

export default AddEditContent;
