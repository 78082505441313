import { Field, Formik } from "formik";
import "./CreateAdmin.scss";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../../Utilities/Constants/Validations";
import {
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  MANAGE_ROLES,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../../Utilities/Constants/Constants";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useEffect, useRef, useState } from "react";
import { S3DataGetApi, uploadFileToS3 } from "../../../Services/Helpers/Helper";
import CloseIcon from "@mui/icons-material/Close";
import FileViewerDialog from "../../../Shared/FileViewerDialog/FileViewerDialog";
import { Box, Checkbox, Tab, Tabs } from "@mui/material";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import { useSelector } from "react-redux";

const CreateAdmin = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<any>("");
  const uploadDisplayImage = useRef<HTMLInputElement | null>(null);
  const [tabValue, setTabValue] = useState(COMMON_CONSTANTS.ZERO);
  const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
  const [initialValues, setInitialValues] = useState({
    [MANAGE_ROLES.FIRST_NAME]: "",
    [MANAGE_ROLES.EMAIL]: "",
    [MANAGE_ROLES.LAST_NAME]: "",
  })
  const roleValidationSchema = yup.object({
    [MANAGE_ROLES.FIRST_NAME]: yup
      .string()
      .min(4)
      .required(ERROR_MESSAGES.FIRST_NAME)
      .trim(),
    [MANAGE_ROLES.EMAIL]: yup
      .string()
      .required(ERROR_MESSAGES.EMAIL_REQUIRED)
      .email(ERROR_MESSAGES.INVALID_EMAIL)
      .trim(),
    [MANAGE_ROLES.LAST_NAME]: yup
      .string()
      .min(4)
      .required(ERROR_MESSAGES.LAST_NAME)
      .trim(),
  });
  const [fileViewerOpen, setFileViewerOpen] = useState(API_RESPONSE.FALSE);
  const [viewerFile, setViewerFile] = useState("");
  const [permissionData, setPermissionData]: any = useState([]);
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });

  const getPermissionsList = () => {
    axiosInstance
      .get(API_END_POINTS.ADMIN_PERMISSION_LIST)
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setPermissionData(response.data.result.data);
        }
      });
  };

  /* Getting Admin detail for edit */
  const getAdminDetail = () => {
    axiosInstance.get(API_END_POINTS.CREATE_EDIT_ADMIN + '?admin_id=' + id).then((response: any) => {
      if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
        setPermissionData([...response.data.result.data]);
        setValues(response.data.result.admin_info);
      }
    })
  };

  const setValues = (value: any) => {
    setInitialValues({
      [MANAGE_ROLES.FIRST_NAME]: value.firstname,
      [MANAGE_ROLES.EMAIL]: value.email,
      [MANAGE_ROLES.LAST_NAME]: value.lastname,
    });
    setProfileImage(value.image);
  };


  useEffect(() => {
    getPermissionsList();
    if (id) {
      getAdminDetail();
    }
  }, []);

  /* Uploading Image to S3 bucket */
  const handleImageChange = async (event: any) => {
    const uploadedImage = await S3DataGetApi(event);
    setProfileImage(uploadedImage);
  };

  /**Handle file viwer dialog open */
  const onFilerViewerDialogOpen = (url: any) => {
    setViewerFile(url);
    setFileViewerOpen(API_RESPONSE.TRUE);
  };

  /**Handle file viwer dialog close */
  const onFilerViewerDialogClose = () => {
    setFileViewerOpen(API_RESPONSE.FALSE);
    setViewerFile("");
  };

  /* Handle checking main menu checkbox */
  const checkMainMenu = () => {
    const updatedData = [...permissionData];
    updatedData[tabValue].checked = !updatedData[tabValue].checked;

    setPermissionData(updatedData);
  };

  /* Create Admin API call */
  const createAdminCall = (value: any) => {
    let validateData = permissionData.some(
      (obj: any) => obj.checked === API_RESPONSE.TRUE
    );
    if (validateData === API_RESPONSE.TRUE) {
      value.role = COMMON_CONSTANTS.ELEVEN;
      value.profile_image = profileImage;
      value.permissions = permissionData;
      if (id) {
        axiosInstance
          .put(API_END_POINTS.CREATE_EDIT_ADMIN + '?admin_id=' + id, value)
          .then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
              toast.success(response.data.message, TOASTER_CONFIGS);
              navigate(-1);
            }
          });
      } else {
        axiosInstance
          .post(API_END_POINTS.CREATE_EDIT_ADMIN, value)
          .then((response: any) => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
              toast.success(response.data.message, TOASTER_CONFIGS);
              navigate(ROUTES.MANAGE_ROLES);
            }
          });
      }

    } else {
      toast.error("Please select permissions");
    }
  };

  /* Validation check for permissions */
  const checkValidations = (value: any) => {
    let isValid: boolean = API_RESPONSE.TRUE;
    for (const permission of permissionData) {
      if (permission.checked === API_RESPONSE.TRUE) {
        if (permission.data.length > COMMON_CONSTANTS.ZERO) {
          for (const subMenus of permission.data) {
            let submenuChecked = permission.data.some(
              (obj: any) => obj.checked === API_RESPONSE.TRUE
            );
            if (!submenuChecked) {
              isValid = API_RESPONSE.FALSE;
              toast.error("Please select atleaset one submenu");
              return;
            }
            if (
              subMenus.checked === API_RESPONSE.TRUE &&
              subMenus.data.length > COMMON_CONSTANTS.ZERO
            ) {
              let submenuItemsChecked = subMenus.data.filter(
                (item: any, i3: any) => {
                  return item.checked === API_RESPONSE.TRUE;
                }
              );
              if (submenuItemsChecked.length === COMMON_CONSTANTS.ZERO) {
                isValid = API_RESPONSE.FALSE;
                toast.error("Please select atleaset one submenu under submenu");
                return;
              }
            }
          }
        }
      }
    }

    if (isValid) {
      createAdminCall(value);
    }
  };

  /* Handling Submit */
  const onsubmitPermission = (value: any) => {
    checkValidations(value);
  };

  /* Handling stepper permissions with validations */
  const handleStepperPermissions = (i1: any, i2: any, i3: any) => {
    let dats1 = permissionData;
    if (dats1[tabValue]?.data[i1]?.data[i2]?.data.length - 1 === i3) {
      if (
        dats1[tabValue]?.data[i1]?.data[i2]?.data[i3].checked ===
        API_RESPONSE.TRUE
      ) {
        dats1[tabValue].data[i1].data[i2].data[i3].checked = API_RESPONSE.FALSE;
      } else {
        for (let j = i3; j >= COMMON_CONSTANTS.ZERO; j--) {
          dats1[tabValue].data[i1].data[i2].data[j].checked = API_RESPONSE.TRUE;
        }
      }
      setPermissionData([...dats1]);
    } else {
      if (
        dats1[tabValue]?.data[i1]?.data[i2]?.data[i3 + 1].checked ===
        API_RESPONSE.FALSE
      ) {
        if (
          dats1[tabValue]?.data[i1]?.data[i2]?.data[i3].checked ===
          API_RESPONSE.TRUE
        ) {
          dats1[tabValue].data[i1].data[i2].data[i3].checked =
            API_RESPONSE.FALSE;
        } else {
          for (let j = i3; j >= COMMON_CONSTANTS.ZERO; j--) {
            dats1[tabValue].data[i1].data[i2].data[j].checked =
              API_RESPONSE.TRUE;
          }
        }
      }
      setPermissionData([...dats1]);
    }
  };

  return (
    <div className="create-admin-wrapper">
      <FileViewerDialog
        open={fileViewerOpen}
        handleClose={onFilerViewerDialogClose}
        viewerFile={viewerFile}
      ></FileViewerDialog>
      <Formik
        enableReinitialize
        validationSchema={roleValidationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onsubmitPermission(values);
        }}
      >
        {(formik: any) => {
          return (
            <div className="form-wrapper">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="arrow-wrap">
                    <img
                      src={BackArrow}
                      className="EditSchool-backarrow"
                      alt="Learn and Play"
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <h5 className="name-title ">
                      {id ? "Edit Admin" : "Create Admin"}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row row-wrap">
                <div className="col-md-6">
                  <div className="form-items">
                    <label className="form-label">First Name</label>
                    <Field
                      className="email-input"
                      name={MANAGE_ROLES.FIRST_NAME}
                      placeholder="First Name"
                    />
                  </div>
                  {formik.touched[MANAGE_ROLES.FIRST_NAME] &&
                    formik.errors[MANAGE_ROLES.FIRST_NAME] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[MANAGE_ROLES.FIRST_NAME]}`)}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-items">
                    <label className="form-label">Last Name</label>
                    <Field
                      className="email-input"
                      name={MANAGE_ROLES.LAST_NAME}
                      placeholder="Last Name"
                    />
                  </div>
                  {formik.touched[MANAGE_ROLES.LAST_NAME] &&
                    formik.errors[MANAGE_ROLES.LAST_NAME] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[MANAGE_ROLES.LAST_NAME]}`)}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row row-wrap">
                <div className="col-md-6">
                  <div className="form-items">
                    <label className="form-label">Email</label>
                    <Field
                      className="email-input"
                      placeholder={"Email"}
                      name={MANAGE_ROLES.EMAIL}
                    />
                  </div>
                  {formik.touched[MANAGE_ROLES.EMAIL] &&
                    formik.errors[MANAGE_ROLES.EMAIL] ? (
                    <div className="errorMessage">
                      {t(`${formik.errors[MANAGE_ROLES.EMAIL]}`)}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <div className="form-items">
                    <label className="form-label">Profile Image</label>
                    <div className="input-btn-wrapper">
                      <input
                        className="email-input"
                        placeholder="Profile Image"
                        value={
                          profileImage?.split("/")[
                          profileImage?.split("/").length - 1
                          ]
                        }
                        disabled
                        onClick={() => uploadDisplayImage.current?.click()}
                      />
                      <FileUploadIcon
                        className="upload-icon"
                        onClick={() => uploadDisplayImage.current?.click()}
                      />
                    </div>

                    <input
                      className="image_input"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg,image/svg"
                      multiple
                      onChange={handleImageChange}
                      ref={uploadDisplayImage}
                    />
                  </div>
                  {profileImage && (
                    <div className="display-img-wrapper">
                      <img
                        className="display-image"
                        src={profileImage}
                        onClick={() => {
                          onFilerViewerDialogOpen(profileImage);
                        }}
                      />
                      <CloseIcon
                        className="close-icon"
                        onClick={() => {
                          setProfileImage("");
                        }}
                      />
                    </div>
                  )}
                  {formSubmitted && profileImage === "" && (
                    <div className="errorMessage">
                      {t(`Please upload profile image`)}
                    </div>
                  )}
                </div>
              </div>
              <div className="row row-wrap"></div>
              <div className="row row-wrap">
                <div className="col-md-12">
                  <label className="form-label">Permissions</label>
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <Tabs

                      value={tabValue}
                      onChange={(e: any, value: number) => {
                        setTabValue(value);
                      }}
                      indicatorColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      {permissionData.map((tab: any, index: any) => {
                        return (
                          <Tab className="tab-text" key={index + 1} value={index} label={tab.name} />
                        );
                      })}
                    </Tabs>
                  </Box>

                  <div>
                    <div className="parent-menu">
                      <Checkbox
                        checked={
                          permissionData[tabValue]?.checked ||
                          API_RESPONSE.FALSE
                        }
                        onChange={(e: any) => {
                          checkMainMenu();
                        }}
                      />
                      {permissionData[tabValue]?.name}
                    </div>
                    {permissionData[tabValue]?.data.length > COMMON_CONSTANTS.ZERO &&
                      permissionData[tabValue]?.checked ===
                      API_RESPONSE.TRUE && (
                        <div className="submenu-loop1 row">
                          {permissionData[tabValue].data.map(
                            (subMenu: any, i: any) => {
                              return (
                                <div
                                  className="submenu-loop2 col-md-6"
                                  key={i + 1}
                                >
                                  <Checkbox
                                    checked={subMenu.checked}
                                    onChange={(e: any) => {
                                      let dat = permissionData;
                                      dat[tabValue].data[i].checked ===
                                        API_RESPONSE.TRUE
                                        ? (dat[tabValue].data[i].checked =
                                          API_RESPONSE.FALSE)
                                        : (dat[tabValue].data[i].checked =
                                          API_RESPONSE.TRUE);
                                      setPermissionData([...dat]);
                                    }}
                                  />
                                  {subMenu.name}
                                  {subMenu.data.length > COMMON_CONSTANTS.ZERO &&
                                    subMenu.checked === API_RESPONSE.TRUE &&
                                    subMenu.data.map(
                                      (resp: any, index2: any) => {
                                        return (
                                          <div
                                            className="submenu-loop"
                                            key={index2 + 1}
                                          >
                                            <Checkbox
                                              checked={resp.checked}
                                              onChange={(e: any) => {
                                                let dats = permissionData;
                                                dats[tabValue].data[i].data[
                                                  index2
                                                ]?.checked === API_RESPONSE.TRUE
                                                  ? (dats[tabValue].data[
                                                    i
                                                  ].data[index2].checked =
                                                    API_RESPONSE.FALSE)
                                                  : (dats[tabValue].data[
                                                    i
                                                  ].data[index2].checked =
                                                    API_RESPONSE.TRUE);
                                                setPermissionData([...dats]);
                                              }}
                                            />
                                            {resp.name}

                                            {resp.checked ===
                                              API_RESPONSE.TRUE &&
                                              resp.data.length > COMMON_CONSTANTS.ZERO &&
                                              resp.data.map(
                                                (
                                                  lastResp: any,
                                                  index3: any
                                                ) => {
                                                  return (
                                                    <div
                                                      className="submenu-loop"
                                                      key={index3 + 1}
                                                    >
                                                      <Checkbox
                                                        checked={
                                                          lastResp.checked
                                                        }
                                                        onChange={(e: any) => {
                                                          handleStepperPermissions(
                                                            i,
                                                            index2,
                                                            index3
                                                          );
                                                        }}
                                                      />
                                                      {lastResp.name}
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className="submit-btn-wrapper">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={() => {
                    setFormSubmitted(API_RESPONSE.TRUE);
                    formik.handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateAdmin;
