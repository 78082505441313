import "./PromotionalContentList.scss";
import CategoriesList from "../../../Shared/ManageCategories/CategoriesList/CategoriesList";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { COMMON_CONSTANTS, VALIDATION_CONSTANT } from "../../../Utilities/Constants/Constants";
import ConentList from "../../../Shared/ManagePromotionalContent/ContentList/ContentList";

const TutorPromotionalContentList = () => {
  return (
    <>
      <ConentList
        apiEndpoint={API_END_POINTS.PROMOTIONAL_CONTENT}
        serviceProviderType={COMMON_CONSTANTS.ONE}
      />
    </>
  );
};

export default TutorPromotionalContentList;
