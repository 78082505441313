import "./ForgotPassword.scss";
import { Formik } from "formik";
import * as yup from "yup";
import loginBanner from "../../../Assets/Login/login_banner.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../Services/Redux/Reducer/Reducer";
import { useEffect, useState } from "react";
import { ERROR_MESSAGES, REGEX } from "../../Utilities/Constants/Validations";
import { axiosInstance } from "../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../Utilities/Constants/ApiEndpoints";
import LanguageIcon from "@mui/icons-material/Language";
import {
  TOASTER_CONFIGS,
  API_RESPONSE,
  TOKENS,
  LANGUAGES,
  LOGIN_FIELDS,
  LAYOUTS,
  ADMIN_ROLES,
  ROUTES,
  I18_TRANSLATION_COMMON,
} from "../../Utilities/Constants/Constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import learnAndPlay from "../../../Assets/Login/phygital_Logo.svg";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [language, setCurrentLanguage] = useState(LANGUAGES.ENGLISH);
  useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Use effect for language */
  useEffect(() => {}, [language]);

  /* Defining validation using yup */
  const formValidationSchema: any = yup.object({
    email: yup
      .string()
      .matches(REGEX.EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
      .required(ERROR_MESSAGES.EMAIL_REQUIRED)
      .trim(),
  });

  /* Triggers while changing language */
  function onLanguageChange(lang: any) {
    i18n.changeLanguage(`${lang}`);
    setCurrentLanguage(
      lang === LANGUAGES.ARABIC_ABBRIVATION
        ? LANGUAGES.ARABIC
        : LANGUAGES.ENGLISH
    );
    dispatch(
      setLanguage(
        lang === LANGUAGES.ARABIC_ABBRIVATION
          ? LANGUAGES.ARABIC
          : LANGUAGES.ENGLISH
      )
    );
  }

  /* Handling submit & API call */
  function handleSubmit(values: any) {
    axiosInstance
      .post(API_END_POINTS.FORGOT_PASSWORD, values)
      .then((res: any) => {
        /* handling response */
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          navigate(ROUTES.LOGIN);
        }
      });
  }
  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={formValidationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          return (
            <div
              className="forget_main_wrapper"
              dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
            >
              <div className="background_color_wrapper">
                <div className="background_fix">
                  <div className="background_color_container">
                    <div className="yellow_round"></div>
                    <div className="violet_round"></div>
                    <div className="blue_round"></div>
                  </div>
                </div>
              </div>

              <div className="forgot_password_wrapper">
                <img
                  className="login_banner"
                  src={loginBanner}
                  alt="Learn and Play"
                />
                <div className="forgotPassContainer">
                  <div className="lang_switch_header">
                    <div className=" lang_switch_contianer">
                      <LanguageIcon className="language_icon" />
                      <button
                        className="language_button"
                        onClick={() => {
                          onLanguageChange(
                            language === LANGUAGES.ENGLISH
                              ? LANGUAGES.ARABIC_ABBRIVATION
                              : LANGUAGES.ENGLISH_ABBRIVATION
                          );
                        }}
                      >
                        {language === LANGUAGES.ENGLISH
                          ? LANGUAGES.ARABIC
                          : LANGUAGES.ENGLISH}
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="learn_and_play_logo">
                      <img
                        className="learn_and_play"
                        src={learnAndPlay}
                        alt="Learn and Play"
                      />
                    </div>
                    <div className="reset_card_container">
                      <div className="reset_wrapper">
                        <div className="reset_password_title">
                          {t(`Forgot Your Password?`)}
                        </div>
                        {/* <h4>{t("Reset Your Password")}</h4> */}
                        <div className="inputBox">
                          <div>
                            <input
                              className="inputForm"
                              name="email"
                              type="email"
                              placeholder={t("Email Address") || ""}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.email}
                            />
                            {props.touched.email && props.errors.email && (
                              <p className="validation">
                                {t(
                                  `${props.touched.email && props.errors.email}`
                                )}
                              </p>
                            )}
                          </div>
                          <div className="reset_button_wrapper">
                            <button
                              className="back-button"
                              onClick={() => {
                                navigate("/login");
                              }}
                            >
                              {t("Back to Login")}
                            </button>
                            <button
                              disabled={
                                props.isValid && props.values.email != ""
                                  ? false
                                  : true
                              }
                              className={
                                props.isValid && props.values.email != ""
                                  ? "submitBtn"
                                  : "submitBtnDisabled"
                              }
                              type="submit"
                              onClick={() => {
                                props.handleSubmit();
                              }}
                            >
                              {t("Submit")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ForgotPassword;
