import { useSelector } from "react-redux";
import Header from "../../Shared/Header/Header";
import SideBar from "../../Shared/Sidebar/Sidebar";
import "./Layout.scss";
import { Outlet, useLocation } from "react-router-dom";
import { LANGUAGES, LAYOUTS } from "../../Utilities/Constants/Constants";
import { useEffect } from "react";

const Layout = () => {
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    });
  return (
    <>
      <div
        className="sidebar_container_ltr"
        dir={language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL}
      >
        <SideBar />
        <div className="header_outlet_wrapper">
          <div className="background_color_wrapper">
            <div className="background_fix">
              <div className="background_color_container">
                <div className="yellow_round"></div>
                <div className="violet_round"></div>
                <div className="blue_round"></div>
              </div>
            </div>
          </div>
          <Header currentPage={pathname.split("/")[1]} />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
