import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./BookingsList.scss";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import {
  ACTIVITIES_TYPE,
  API_RESPONSE,
  COMMON_CONSTANTS,
  ROUTES,
} from "../../../Utilities/Constants/Constants";
import {
  Card,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import NoProducts from "../../NoProducts/NoProducts";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import moment from "moment";
import { t } from "i18next";
import Rating from "@mui/material/Rating";

const BookingsList = () => {
  const { slot_id, user_type } = useParams();
  const [bookingsList, setBookingsList] = useState<any>([]);
  const [bookingDetail, setBookingDetail] = useState<any>({});
  const [slotIndex, setSlotIndex] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlParam = searchParams.get(COMMON_CONSTANTS.TYPE);
  useEffect(() => {
    getBookingsList();
  }, []);

  /* Getting booking list API call */
  const getBookingsList = () => {
    let queryTerm = location.pathname.split("/")[4];
    let teachId =
      location.pathname.split("/")[1] === "manage-tutors"
        ? 1
        : queryTerm === "personal"
          ? 2
          : 3;
    let payload = {
      service_provider_id: slot_id,
      teach_service_provider: teachId,
      page: 1,
      limit: 20,
    };
    axiosInstance
      .post(
        queryTerm === "camp"
          ? API_END_POINTS.CAMP_BOOKED_SLOTS
          : API_END_POINTS.TUTOR_BOOOKED_SLOTS,
        payload
      )
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setBookingsList([...response.data.result.data]);
          if (response.data.result.data.length > 0 && queryTerm !== "camp") {
            getBookingDetail(response.data.result.data[0]);
          }
        }
      });
  };

  /* Get booking detail API call */
  const getBookingDetail = (Data: any) => {
    let queryTerm = location.pathname.split("/")[4];
    let teachId =
      location.pathname.split("/")[1] === "manage-tutors"
        ? 1
        : queryTerm === "personal"
          ? 2
          : 3;
    let payload = {
      service_provider_id: slot_id,
      teach_service_provider: teachId,
      user_id: Data?.user_id,
      is_primary: Data?.is_primary,
      booked_email: Data?.booked_email,
      booking_id: Data?.id,
    };
    axiosInstance
      .post(API_END_POINTS.TUTOR_BOOKED_SLOT_DETAIL, payload)
      .then((response: any) => {
        if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
          setBookingDetail(response.data.result.data[0]);
          setSlotIndex(response.data.result.data[0]?.slot_list[0]);
        }
      });
  };

  return (
    <div className="booking-detail-wrapper">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="arrow-wrap">
            <img
              src={BackArrow}
              className="EditSchool-backarrow"
              alt="Learn and Play"
              onClick={() => {
                navigate(-1);
              }}
            />
            <h5 className="name-title ">{"Booked Slots"}</h5>
          </div>
        </div>
      </div>
      {bookingsList?.length > 0 &&
        location.pathname.split("/")[4] !== "camp" && (
          <div className="row">
            <div className="col-md-4">
              <div className="booking-list-wrapper">
                {bookingsList?.map((booking: any, index: any) => {
                  return (
                    <Card
                      key={index + 1}
                      className={
                        currentIndex === index
                          ? "booking-card-active"
                          : "booking-card"
                      }
                      onClick={() => {
                        getBookingDetail(booking);
                        setCurrentIndex(index);
                      }}
                    >
                      <p className="booking-title session-title">
                        {booking.session_title}
                      </p>
                      <p>
                        <span className="booking-title">Student </span>:{" "}
                        {booking.name}
                      </p>
                      <p>
                        {" "}
                        <span className="booking-title">Slots</span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                        {booking.slots_count}
                      </p>
                    </Card>
                  );
                })}
              </div>
            </div>
            <div className="col-md-8">
              <div className="booking-detail">
                <h5 className="mb-4">Booking Detail</h5>
                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Session Title</p>
                  </div>
                  <div className="col-md-8">
                    <p>{bookingDetail.session_title}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Course Mode</p>
                  </div>
                  <div className="col-md-8">
                    <p>{slotIndex?.course_mode == 'Offline' ? 'On-premises' : slotIndex?.course_mode}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Student Name</p>
                  </div>
                  <div className="col-md-8">
                    <p>{bookingDetail?.student_name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Parent Email</p>
                  </div>
                  <div className="col-md-8">
                    <p>{bookingDetail.parent_email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Student Rating</p>
                  </div>
                  <div className="col-md-8">
                    {slotIndex?.rating !== 0 && slotIndex && slotIndex.rating !== undefined && (
                      <Rating
                        name="half-rating"
                        value={slotIndex?.rating}
                        onChange={(event, newValue) => {
                          // setRatingData(newValue);
                        }}
                        readOnly
                        precision={1}
                        className="rating__icon"
                        size="large"
                      />
                    )}
                    {slotIndex?.rating === 0 && "N/A"}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Feedback</p>
                  </div>
                  <div className="col-md-8">
                    <p>{slotIndex?.feedback ? slotIndex.feedback : "-"}</p>
                  </div>
                </div>
                {/* <div className="row">
                            <div className="col-md-4">
                                <p className="booking-title">Rating</p>
                            </div>
                            <div className="col-md-8">
                                <p>{slotIndex?.feedbacks ? slotIndex.feedbacks : '-'}</p>
                            </div>
                        </div> */}
                <div className="row">
                  <div className="col-md-4">
                    <p className="booking-title">Student Attendance Status</p>
                  </div>
                  <div className="col-md-8">
                    <p>
                      {t(
                        slotIndex?.is_didnot
                          ? moment(new Date(slotIndex?.start)).format(
                            COMMON_CONSTANTS.YMD_FORMAT
                          ) <
                            moment(new Date()).format(
                              COMMON_CONSTANTS.YMD_FORMAT
                            )
                            ? "Did not attend"
                            : "Will not attend"
                          : moment(new Date(slotIndex?.start)).format(
                            COMMON_CONSTANTS.YMD_FORMAT
                          ) <
                            moment(new Date()).format(
                              COMMON_CONSTANTS.YMD_FORMAT
                            )
                            ? "Attended"
                            : "Will attend"
                      )}
                    </p>
                  </div>
                </div>
                {/* <div className="row">
                            <div className="col-md-4">
                                <p className="booking-title">Paid Amount</p>
                            </div>
                            <div className="col-md-8">
                                <p>{bookingDetail.paid_amount !== '' ? bookingDetail.paid_amount : '-'}</p>
                            </div>
                        </div> */}

                <div>
                  <p className="booking-title session-title">Slots</p>
                  {bookingDetail?.slot_list?.length > 0 && (
                    <div className="row slot-list-wrapper">
                      {bookingDetail?.slot_list.map((slot: any, i2: any) => {
                        return (
                          <div
                            className="col-md-6"
                            onClick={() => setSlotIndex(slot)}
                          >
                            <Card
                              className={
                                slot.id == slotIndex?.id
                                  ? "slot-card-wrap slot-card-wrap_active"
                                  : "slot-card-wrap"
                              }
                            >
                              <p>
                                {moment(slot.session_slot_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                              <p>
                                {slot.start_time} to {slot.end_time}
                              </p>
                            </Card>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      {location.pathname.split("/")[4] === "camp" && (
        <>
          <div className="recreation_table_container">
            <TableContainer component={Paper} className="categoryTable">
              <Table>
                <TableHead className="categoryTableHead">
                  <TableRow className="categoryTableRow">
                    <TableCell>{t("S.NO")}</TableCell>
                    <TableCell>{t("Child Name")}</TableCell>
                    <TableCell>{t("Email")}</TableCell>
                    <TableCell>{t("Phone")}</TableCell>
                    {/* <TableCell>{t("Location")}</TableCell> */}
                    {/* <TableCell>{t("Rating")}</TableCell> */}
                    {/* <TableCell>{t("Status")}</TableCell> */}
                    {/* <TableCell>{t("Actions")}</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    /* hasProducts &&
                     */ bookingsList.map((course: any, index: any) => {
                    return (
                      <TableRow>
                        <TableCell>
                          {/* {page - 1 === 0
                                    ? index + 1
                                    : (page - 1) * rowPerPage + (index + 1)} */}
                          {index + 1}
                        </TableCell>
                        <TableCell className="name-cell row-capitalize">
                          {course?.child_name}
                        </TableCell>
                        <TableCell>{course?.booked_email}</TableCell>
                        <TableCell>{course?.booked_phone_number}</TableCell>
                        {/* <TableCell>{course?.address}</TableCell>
                                <TableCell>{course?.rating}</TableCell> */}
                      </TableRow>
                    );
                  })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {/* <div className="tablePagination">
                    <Pagination
                        count={totalPage}
                        page={page}
                        onChange={handleChangePage}
                    />
                    </div> */}
            {/* {hasProducts && (
                    <div className="tablePagination">
                        <div className="row">
                        <div className="col-md-7">
                            <div className="pagination-wrap">
                            <Pagination
                                count={totalPage}
                                page={page}
                                onChange={handleChangePage}
                            />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="rows-per-page">
                            <p>{t("Rows per page")}:</p>
                            <select
                                className="rows-select"
                                value={rowPerPage}
                                onChange={onRowChange}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                            </select>
                            </div>
                        </div>
                        </div>
                    </div>
                    )}
                    {!hasProducts && <NoProducts />} */}
          </div>
        </>
      )}
      {bookingsList.length === 0 && (
        <>
          <NoProducts />
        </>
      )}
    </div>
  );
};

export default BookingsList;
