import { useEffect } from "react";
import NoProducts from "../NoProducts/NoProducts";
import { useLocation } from "react-router-dom";
import { UPLOAD_TICKET_CONSTANT } from "../../Utilities/Constants/Constants";
import ManageTutors from "../../Components/ManageTutors/TutorsList/TutorsList";
import RecreationalList from "../../Components/ManageRecreational/RecreationalList/RecreationalList";

const ManageBookings = ()=>{

  const location = useLocation();
 
  return (
    <div className="bookings-wrap">

         {
          location.pathname.split('/')[1] !==UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL && <>
         <ManageTutors />
          </>
         }
         {
           location.pathname.split('/')[1]===UPLOAD_TICKET_CONSTANT.MANAGE_RECREATIONAL && <>
           <RecreationalList />
           </>
         }
    </div>
    
  )
};


export default ManageBookings;