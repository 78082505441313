import "./ConfirmationDialog.scss";
import * as React from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { I18_TRANSLATION_COMMON } from "../../Utilities/Constants/Constants";

const ConfirmationDialog = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  const handleClose = (status: any) => {
    status === "yes" ? props.dialogStatus(true) : props.dialogStatus(false);
    setOpen(false);
  };

  return (
    <div
      className="confirmationDialog"
      dir="currentLanguage==='English'?'ltr':'rtl'"
    >
      <Dialog
        fullScreen={fullScreen}
        open={props.modalState}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>{t(`${props.content}`)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-no"
            autoFocus
            onClick={() => handleClose("no")}
          >
            {t("NO")}
          </Button>
          <Button onClick={() => handleClose("yes")} autoFocus>
            {t("YES")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
