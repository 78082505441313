import "./ChangePassword.scss";
import { useState, useEffect, useRef } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  ERROR_MESSAGES,
  REGEX,
} from "../../../Utilities/Constants/Validations";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import { toast } from "react-toastify";
import {
  I18_TRANSLATION_COMMON,
  LANGUAGES,
  LAYOUTS,
  LOGIN_FIELDS,
  ROUTES,
  TOASTER_CONFIGS,
} from "../../../Utilities/Constants/Constants";
import passwordVisible from "../../../../Assets/Login/password_visible.svg";
import passwordInvisible from "../../../../Assets/Login/password_invisible.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { clearSessionStorage } from "../../../Services/Helpers/Helper";
import passwordTick from "../../../../Assets/global/password_tick.svg";
import passwordCross from "../../../../Assets/global/password_cross.svg";

const ChangePassword = (props: any) => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const strengthCard = useRef<HTMLDivElement>(null);
  const [oneSpecialCheck, setOneSpecialCheck] = useState(false);
  const [oneCapitalCheck, setOneCapitalCheck] = useState(false);
  const [oneSmallCheck, setOneSmallCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [minimumLengthCheck, setMinimumLengthCheck] = useState(false);
  const [maximumLengthCheck, setMaximumLengthCheck] = useState(false);
  /* Getting current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });

  /* Validation schema */
  const changePassValidation = yup.object({
    old_password: yup
      .string()
      .required(ERROR_MESSAGES.PASSWORD_REQUIRED)
      .min(8, ERROR_MESSAGES.MINIMUM_PASSWORD)
      .max(16, ERROR_MESSAGES.MAXIMUM_PASSWORD)
      .matches(REGEX.PASSWORD, ERROR_MESSAGES.INVALID_PASSWORD),
    new_password: yup
      .string()
      .required(ERROR_MESSAGES.PASSWORD_REQUIRED)
      .min(8, ERROR_MESSAGES.MINIMUM_PASSWORD)
      .max(16, ERROR_MESSAGES.MAXIMUM_PASSWORD)
      .matches(REGEX.PASSWORD, ERROR_MESSAGES.INVALID_PASSWORD),
    confirm_password: yup
      .string()
      .required(ERROR_MESSAGES.CONF_PASS_MSG)
      .min(8, ERROR_MESSAGES.MINIMUM_PASSWORD)
      .max(16, ERROR_MESSAGES.MAXIMUM_PASSWORD)
      .matches(REGEX.PASSWORD, ERROR_MESSAGES.INVALID_PASSWORD)
      .oneOf([yup.ref("new_password")], ERROR_MESSAGES.PASSWORD_MATCH),
  });

  /* Updating open state of dialog */
  useEffect(() => {}, [open]);

  const onLogOut = () => {
    clearSessionStorage();
    navigate(ROUTES.LOGIN);
  };

  /* Update password API call */
  const updatePassword = (values: any) => {
    axiosInstance.put(API_END_POINTS.UPDATE_PASSWORD, values).then((res) => {
      if (res.data.status === true) {
        toast.success(res.data.message, TOASTER_CONFIGS);
        props.handleCheck();
        onLogOut();
      }
    });
  };
  const hidePasswordStrength = () => {
    const elementStyle = strengthCard.current?.style;
    elementStyle!.setProperty("display", "none");
  };
  const showPasswordStrength = () => {
    const elementStyle = strengthCard.current?.style;
    elementStyle!.setProperty("display", "block");
  };
  const checkPasswordStrength = (event: any) => {
    let passwordValue = event.target.value;
    setOneCapitalCheck(REGEX.UPPER_CASE_REGEX.test(passwordValue));
    setOneSmallCheck(REGEX.LOWER_CASE_REGEX.test(passwordValue));
    setOneNumberCheck(REGEX.NUMBER_REGEX.test(passwordValue));
    setOneSpecialCheck(REGEX.SPECIAL_CHARACTERS_REGEX.test(passwordValue));
    setMinimumLengthCheck(passwordValue.length > 7);
    setMaximumLengthCheck(passwordValue.length < 16);
  };
  return (
    <div className="change_password_container" dir={currentLanguage === "English" ? "ltr" : "rtl"}>
      <Dialog
        className="change-pass-title"
        open={props.dialogState}
        sx={{ "& .MuiDialog-paper": "cus-dialog" }}
      >
        <DialogTitle className="title-wrap">
          {t("Change Password")}
          <div className="close-wrap">
            <CloseIcon
              className="close-icon"
              onClick={() => props.handleCheck()}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <Formik
            validationSchema={changePassValidation}
            initialValues={{
              old_password: "",
              new_password: "",
              confirm_password: "",
            }}
            onSubmit={(values: any) => {
              updatePassword(values);
            }}
          >
            {(propsFormik) => {
              return (
                <div
                  dir={
                    language === LANGUAGES.ENGLISH ? LAYOUTS.LTR : LAYOUTS.RTL
                  }
                >
                  <div className="row row-input">
                    <div className="col-12">
                      <div className="change_password_wrapper">
                        <label className="nameLabel">
                          {t("Old Password:")}
                        </label>
                        <div className="input_icon_wrapper">
                          <Field
                            name="old_password"
                            className="old_password input"
                            type={showPassword ? "text" : LOGIN_FIELDS.PASSWORD}
                          />
                          <img
                            className={
                              language === LANGUAGES.ENGLISH
                                ? "password_visibility_cp"
                                : "password_visibility_arabic_cp"
                            }
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            src={
                              showPassword ? passwordInvisible : passwordVisible
                            }
                            alt=""
                          />
                        </div>

                        <div className="error-message">
                          {propsFormik.touched.old_password &&
                            propsFormik.errors.old_password && (
                              <p>{t(`${propsFormik.errors.old_password}`)}</p>
                            )}
                        </div>
                      </div>

                      <div className="change_password_wrapper">
                        <label className="nameLabel">
                          {t("New Password:")}
                        </label>
                        <div className="input_error_wrapper">
                          <div className="input_icon_wrapper">
                            <Field
                              name="new_password"
                              className="new_password input"
                              type={
                                showNewPassword ? "text" : LOGIN_FIELDS.PASSWORD
                              }
                              onChange={(event: any) => {
                                propsFormik.handleChange(event);
                                checkPasswordStrength(event);
                              }}
                              onBlur={(event: any) => {
                                propsFormik.handleBlur(event);
                                hidePasswordStrength();
                              }}
                              onFocus={showPasswordStrength}
                            />
                            <img
                              className={
                                language === LANGUAGES.ENGLISH
                                  ? "password_visibility_cp"
                                  : "password_visibility_arabic_cp"
                              }
                              onClick={() => {
                                setNewPassword(!showNewPassword);
                              }}
                              src={
                                showNewPassword
                                  ? passwordInvisible
                                  : passwordVisible
                              }
                              alt=""
                            />
                          </div>

                          <div className="error-message">
                            {propsFormik.touched.new_password &&
                              propsFormik.errors.new_password && (
                                <p>{t(`${propsFormik.errors.new_password}`)}</p>
                              )}
                          </div>
                          <div
                            ref={strengthCard}
                            className="password_strength_wrapper"
                          >
                            <div className="password_title">
                              Password must meet the following requirements:
                            </div>
                            <div className="password_status_wrapper">
                              {oneSpecialCheck ? (
                                <img
                                  className="password_tick"
                                  src={passwordTick}
                                  alt="Learn and Play"
                                />
                              ) : (
                                <img
                                  className="password_cross"
                                  src={passwordCross}
                                  alt="Learn and Play"
                                />
                              )}
                              <div
                                className={
                                  oneSpecialCheck
                                    ? "password_satus_pass"
                                    : "password_satus_fail"
                                }
                              >
                                At least 1 Special character
                              </div>
                            </div>
                            <div className="password_status_wrapper">
                              {oneCapitalCheck ? (
                                <img
                                  className="password_tick"
                                  src={passwordTick}
                                  alt="Learn and Play"
                                />
                              ) : (
                                <img
                                  className="password_cross"
                                  src={passwordCross}
                                  alt="Learn and Play"
                                />
                              )}
                              <div
                                className={
                                  oneCapitalCheck
                                    ? "password_satus_pass"
                                    : "password_satus_fail"
                                }
                              >
                                At least 1 Uppercase letter
                              </div>
                            </div>
                            <div className="password_status_wrapper">
                              {oneSmallCheck ? (
                                <img
                                  className="password_tick"
                                  src={passwordTick}
                                  alt="Learn and Play"
                                />
                              ) : (
                                <img
                                  className="password_cross"
                                  src={passwordCross}
                                  alt="Learn and Play"
                                />
                              )}
                              <div
                                className={
                                  oneSmallCheck
                                    ? "password_satus_pass"
                                    : "password_satus_fail"
                                }
                              >
                                At least 1 Lowercase letter
                              </div>
                            </div>
                            <div className="password_status_wrapper">
                              {oneNumberCheck ? (
                                <img
                                  className="password_tick"
                                  src={passwordTick}
                                  alt="Learn and Play"
                                />
                              ) : (
                                <img
                                  className="password_cross"
                                  src={passwordCross}
                                  alt="Learn and Play"
                                />
                              )}
                              <div
                                className={
                                  oneNumberCheck
                                    ? "password_satus_pass"
                                    : "password_satus_fail"
                                }
                              >
                                At least 1 number
                              </div>
                            </div>
                            <div className="password_status_wrapper">
                              {minimumLengthCheck ? (
                                <img
                                  className="password_tick"
                                  src={passwordTick}
                                  alt="Learn and Play"
                                />
                              ) : (
                                <img
                                  className="password_cross"
                                  src={passwordCross}
                                  alt="Learn and Play"
                                />
                              )}
                              <div
                                className={
                                  minimumLengthCheck
                                    ? "password_satus_pass"
                                    : "password_satus_fail"
                                }
                              >
                                Be at least 8 characters
                              </div>
                            </div>
                            <div className="password_status_wrapper">
                              {minimumLengthCheck && maximumLengthCheck ? (
                                <img
                                  className="password_tick"
                                  src={passwordTick}
                                  alt="Learn and Play"
                                />
                              ) : (
                                <img
                                  className="password_cross"
                                  src={passwordCross}
                                  alt="Learn and Play"
                                />
                              )}
                              <div
                                className={
                                  minimumLengthCheck && maximumLengthCheck
                                    ? "password_satus_pass"
                                    : "password_satus_fail"
                                }
                              >
                                Be at most 16 characters
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="change_password_wrapper">
                        <label className="nameLabel">
                          {t("Confirm password:")}
                        </label>
                        <div className="input_icon_wrapper">
                          <Field
                            name="confirm_password"
                            className="confirm_password input"
                            type={
                              showConfirmPassword
                                ? "text"
                                : LOGIN_FIELDS.PASSWORD
                            }
                          />
                          <img
                            className={
                              language === LANGUAGES.ENGLISH
                                ? "password_visibility_cp"
                                : "password_visibility_arabic_cp"
                            }
                            onClick={() => {
                              setConfirmPassword(!showConfirmPassword);
                            }}
                            src={
                              showConfirmPassword
                                ? passwordInvisible
                                : passwordVisible
                            }
                            alt=""
                          />
                        </div>
                        <div className="error-message">
                          {propsFormik.touched.confirm_password &&
                            propsFormik.errors.confirm_password && (
                              <p>
                                {t(`${propsFormik.errors.confirm_password}`)}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-wrap-password">
                      <button
                        className="btn  cancel-btn  mt-2"
                        type="submit"
                        onClick={() => props.handleCheck()}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className="btn save-btn  mt-2"
                        type="submit"
                        onClick={() => {
                          propsFormik.handleSubmit();
                        }}
                      >
                        {t("Update")}
                      </button>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangePassword;
