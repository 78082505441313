import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import "./RecreationalList.scss";
import {
  Box,
  Checkbox,
  Chip,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Slider,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  checkValueNullUndefinedEmpty,
  dateTimeConversion,
} from "../../../Services/Helpers/Helper";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { toast } from "react-toastify";
import {
  DIALOG_CONSTANT,
  RECREATIONAL_ROUTES,
  I18_TRANSLATION_COMMON,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
  COMMON_CONSTANTS,
  ONBOARDING_ROUTES,
  ACTIVITIES_TYPE,
  API_RESPONSE,
  SERVICES_STATUS,
  ROUTES,
  SORTING_CONSTANT
} from "../../../Utilities/Constants/Constants";
import ConfirmationDialog from "../../../Shared/ConfirmationDialog/ConfirmationDialog";
import ReasonDialog from "../../../Shared/ReasonDialog/ReasonDialog";
import NoProducts from "../../../Shared/NoProducts/NoProducts";
import HttpsIcon from "@mui/icons-material/Https";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { TableSortLabel } from "@mui/material";

const RecreationalList = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [page, setPage] = useState(1);
  const [courseList, setCourseList] = useState([]);
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalCount] = useState(1);
  const [initialRender, setInitialRender] = useState(API_RESPONSE.TRUE);
  const [currentCourse, setCurrentCourse] = useState("");
  const [dialogOpenStatus, setDialogOpenStatus] = useState(API_RESPONSE.FALSE);
  const [modalStatus, setmodalStatus] = useState(API_RESPONSE.FALSE);
  const [hasProducts, setHasProducts] = useState(API_RESPONSE.TRUE);
  /* Get current language from store */
  const currentLanguage = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const [rowPerPage, setRowPerPage] = useState<any>(10);
  const [currentUser, setCurrentUser] = useState("");
  const [idSort, setIdSort]: any = useState(SORTING_CONSTANT.ASC);

  const dialogContent = DIALOG_CONSTANT.COURSE_REJECTION;
  const dialogTitle = DIALOG_CONSTANT.TITLE_REJECTION;
  const location = useLocation();

  const [filterApplied, setFilterApplied] = useState(API_RESPONSE.FALSE);
  const [gender, setGender]: any = useState([]);
  const [ratingList, setRatingList]: any = useState([]);
  const [rating, setRating]: any = useState([]);
  const [activityList, setActivityList]: any = useState([]);
  const [activity, setActivity]: any = useState([]);
  const [experienceRange, setExperienceRange] = useState<number[]>([]);
  const [currentExperienceRange, setCurrentExperienceRange] = useState<number[]>([
    experienceRange[COMMON_CONSTANTS.ZERO],
    experienceRange[COMMON_CONSTANTS.ONE],
  ]);
  const [filterPayload, setFilterPayload]: any = useState();
  const [showTabs, setShowTabs] = useState(API_RESPONSE.FALSE);
  const [tabValue, setTabValue] = useState(SERVICES_STATUS.PERSONAL_TRAINER);
  const searchParams = new URLSearchParams(location.search);
  const urlParam = searchParams.get(COMMON_CONSTANTS.TYPE);
  useEffect(() => {
    if (location.pathname === ROUTES.RECREATIONAL_BOOKINGS) {
      if (!urlParam) {
        navigate(ROUTES.RECREATIONAL_BOOKINGS + `?${COMMON_CONSTANTS.TYPE}=${ACTIVITIES_TYPE.PERSONAL}`)
      }
      if (urlParam === ACTIVITIES_TYPE.PERSONAL || ACTIVITIES_TYPE.CAMP) {
        setShowTabs(API_RESPONSE.TRUE)
        urlParam === ACTIVITIES_TYPE.PERSONAL ? setTabValue(SERVICES_STATUS.PERSONAL_TRAINER) : setTabValue(SERVICES_STATUS.ACTIVITY_CAMP)
      } else {
        setShowTabs(API_RESPONSE.FALSE)
      }
    }
     setPage(1);
    getFilterDetails();
    setFilterApplied(API_RESPONSE.FALSE);
    // resetFilter();
    setFilterPayload('')
  }, [location]);

  /* Calls get list payload on filterPayload change */
  useEffect(() => {
    getRecreationalList();
  }, [filterPayload]);

  /* Filter API call */
  const getFilterDetails = () => {
    axiosInstance.get(API_END_POINTS.RECREATIONAL_FILTER_DETAILS + `?${COMMON_CONSTANTS.TYPE}=` +
      (showTabs ? (urlParam === ACTIVITIES_TYPE.PERSONAL ? ACTIVITIES_TYPE.PERSONAL
        : ACTIVITIES_TYPE.CAMP) : (location.pathname.split("/")[1] ===
          ONBOARDING_ROUTES.ACTIVITIES_PERSONAL_STATUS_LIST
          ? ACTIVITIES_TYPE.PERSONAL
          : ACTIVITIES_TYPE.CAMP))).then(response => {
            if (response.data[API_RESPONSE.STATUS] === API_RESPONSE.TRUE) {
              let data = response.data.result.data[0];
              setExperienceRange([parseInt(data.experience.Min), parseInt(data.experience.Max)]);
              setCurrentExperienceRange([data.experience.Min, data.experience.Max]);
              setRatingList(data.rating);
              setActivityList(data.activity);
            }
          })
  }

  /**Recreational payload */
  const recreationalListPayload = () => {
    return {
      page: page,
      limit: parseInt(rowPerPage),
      status: VALIDATION_CONSTANT.APPROVED,
      sort: currentSort,
      search: searchValue.trim(),
      start_date: "",
      end_date: "",
      filter: filterPayload
    };
  };

  /**Get recreational list */
  const getRecreationalList = () => {
    axiosInstance
      .post(
        showTabs ?
          urlParam === ACTIVITIES_TYPE.PERSONAL
            ? API_END_POINTS.GET_RECREATIONAL_PERSONAL_STATUS_LIST
            : API_END_POINTS.GET_RECREATIONAL_CAMPUS_STATUS_LIST
          :
          location.pathname.split("/")[1] ===
            RECREATIONAL_ROUTES.ACTIVITIES_PERSONAL_LIST
            ? API_END_POINTS.GET_RECREATIONAL_PERSONAL_STATUS_LIST
            : API_END_POINTS.GET_RECREATIONAL_CAMPUS_STATUS_LIST,
        recreationalListPayload()
      )
      .then((res) => {
        setCourseList(res.data.result.data);
        getRecreationalLength(res.data.result.data);
        setTotalCount(res.data.result.total_page);
      });
  };

  /**Handle row per page change */
  const onRowChange = (event: any) => {
    setPage(COMMON_CONSTANTS.ONE)
    setRowPerPage(event.target.value);
  };

  /**Check for data availability */
  const getRecreationalLength = (recreational: any) => {
    recreational.length > 0 ? setHasProducts(API_RESPONSE.TRUE) : setHasProducts(API_RESPONSE.FALSE);
  };

  /* Updated on change in search value */
  useEffect(() => {
    if (initialRender) {
      setInitialRender(API_RESPONSE.FALSE);
    } else {
      if (checkValueNullUndefinedEmpty(searchValue)) {
        getRecreationalList();
      }
    }
  }, [searchValue]);

  /**Handle page change */
  const handleChangePage = (event: React.ChangeEvent<any>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if(location.pathname.split("/")[1] === RECREATIONAL_ROUTES.ACTIVITIES_PERSONAL_LIST){
      setType("personal")
    }else{
      setType("camp")
    }
    
    getRecreationalList();
  }, [page, currentSort, rowPerPage, location]);

  /* Setting sort type */
  const onChangeSelect = (e: any) => {
    setCurrentSort(e.target.value);
  };

  /**Reason dialog close */
  const onCloseDialog = () => {
    setDialogOpenStatus(API_RESPONSE.FALSE);
  };

  /* Updating search text */
  const onUpdateSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  /**Block unblock recreational user */
  const blockUnblockTutor = (id: any, status: any) => {
    if (status === API_RESPONSE.FALSE) {
      setDialogOpenStatus(API_RESPONSE.TRUE);
    } else {
      let userType = location.pathname === ROUTES.MANAGE_RECREATIONAL_PERSONAL ? VALIDATION_CONSTANT.TRAINER  : VALIDATION_CONSTANT.CAMP
      axiosInstance
        .put(
          API_END_POINTS.BLOCK_TUTOR +
          "?id=" +
          id +
          "&block_type=" +
          userType + "&reason="
        )
        .then((res) => {
          toast.success(res.data.message, TOASTER_CONFIGS);
          getRecreationalList();
        });
    }
  };

  /** update reason for blocking the recreational user */
  const onSubmitReason = (value: any) => {
    let payload = {
      reason: value.reason,
    };
    setDialogOpenStatus(API_RESPONSE.FALSE);
    let userType = location.pathname === ROUTES.MANAGE_RECREATIONAL_PERSONAL ? VALIDATION_CONSTANT.TRAINER  : VALIDATION_CONSTANT.CAMP
    axiosInstance
      .put(
        API_END_POINTS.BLOCK_TUTOR +
        "?id=" +
        currentUser +
        "&block_type=" +
        userType + 
        "&reason=" + value.reason,
        payload
      )
      .then((res) => {
        toast.success(res.data.message, TOASTER_CONFIGS);
        getRecreationalList();
      });
  };

  const hadleExperienceSlider = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      const formattedRange: any = newValue.map((value) => {
        const nearest = parseInt(value.toString());
        return nearest;
      });
      setCurrentExperienceRange(formattedRange);
    }
  };

    /* Handling ID sort */
    const handleSortID = () => {
      idSort == SORTING_CONSTANT.ASC
        ? setIdSort(SORTING_CONSTANT.DSC)
        : setIdSort(SORTING_CONSTANT.ASC);
      idSort == SORTING_CONSTANT.ASC
        ? setCurrentSort(SORTING_CONSTANT.ID_DESCENDING)
        : setCurrentSort(SORTING_CONSTANT.ID_ASCENDING);
        getRecreationalList();
    };

  function valuetext(value: number) {
    return `${value}`;
  };

  const onChangeGender = (e: any) => {
    setGender(e.target.value);
  };

  const onSetRating = (e: any) => {
    setRating(e.target.value);
  };
  const onSetActivity = (e: any) => {
    setActivity(e.target.value);
  };

  useEffect(() => {
    if (filterApplied && activity.length === COMMON_CONSTANTS.ZERO) {
      onApplyFilter();
    }
  }, [activity])

  const resetFilter = () => {
    setRating([]);
    setGender([]);
    setCurrentExperienceRange([
      experienceRange[COMMON_CONSTANTS.ZERO],
      experienceRange[COMMON_CONSTANTS.ONE],
    ]);
    setActivity([]);

  };

  const onApplyFilter = () => {
    setFilterPayload({
      experience: [
        {
          start: currentExperienceRange[COMMON_CONSTANTS.ZERO],
          end: currentExperienceRange[COMMON_CONSTANTS.ONE],
        },
      ],
      rating: rating,
      activity: activity,
      gender: gender
    });
  };


  /**Navigate to detail view */
  const goToViewDetail = (id: any) => {
    return location.pathname.split("/")[1] ===
      RECREATIONAL_ROUTES.ACTIVITIES_PERSONAL_LIST
      ? `/manage-activities-personal-training/activities-personal/${id}`
      : `/manage-activities-camp/activities-camp/${id}`;
  };

  return (
    <div className="recreational-wrapup">
      <ReasonDialog
        modalState={dialogOpenStatus}
        dialogStatus={onCloseDialog}
        submitReason={onSubmitReason}
        title={dialogTitle}
      />
      <div className="select-wrap">
        <div className="btn-wrap">
          <div className="search-wrapup">
            <div className="custom__search">
              <TextField
                id="outlined-basic"
                placeholder={t("Search") || ""}
                variant="outlined"
                value={searchValue}
                onChange={onUpdateSearch}
                onKeyPress={(event: any) => {
                  if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                    getRecreationalList();
                  }
                }}
              />
              <SearchIcon
                className="icon"
                onClick={() => {
                  getRecreationalList();
                }}
              />
            </div>
            <div
              className="filter-wrap"
              onClick={() => {
                setFilterApplied(!filterApplied);
              }}
            >
              {!filterApplied && <FilterAltIcon />}
              {filterApplied && <FilterAltOffIcon />}
            </div>
          </div>


          {filterApplied && (
            <div className="filter-wrap-container">
              <div className="filter-items-wrap">
                <div className="filter-item">
                  <Box className="slider-box">
                    <label className="filter-label">{t("Experience")}:</label>
                    <Slider
                      min={experienceRange[COMMON_CONSTANTS.ZERO]}
                      step={COMMON_CONSTANTS.ONE}
                      max={experienceRange[COMMON_CONSTANTS.ONE]}
                      getAriaLabel={() => "Price Range"}
                      value={currentExperienceRange}
                      onChange={hadleExperienceSlider}
                      valueLabelDisplay={"on"}
                      // marks={marks}
                      getAriaValueText={valuetext}
                      className="slidercategory"
                    />
                  </Box>
                </div>

                {/*   <div className="filter-item">
              <label className="filter-label">Teaching Language</label>
              <Select
                multiple
                value={selectedLanguage}
                className="filter-select"
                onChange={onSetLanguage}
                renderValue={(selected) => selected.join(", ")}
              >
                {languageList.map((language: any, index: any) => {
                  return (
                    // <option value={language}>{language}</option>
                    <MenuItem value={language}>
                      <Checkbox
                        checked={selectedLanguage.includes(`${language}`)}
                      />
                      {language}
                    </MenuItem>
                  );
                })}
              </Select>
            </div> */}
                <div className="filter-item">
                  <label className="filter-label">Activity</label>
                  <Select
                    multiple
                    value={activity}
                    className="filter-select"
                    onChange={onSetActivity}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {activityList.map((type: any, index: any) => {
                      return (
                        <MenuItem value={type}>
                          <Checkbox checked={activity.includes(`${type}`)} />
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>

                <div className="filter-item">
                  <label className="filter-label">Rating</label>
                  <Select
                    multiple
                    value={rating}
                    className="filter-select"
                    onChange={onSetRating}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {ratingList.map((ratings: any, index: any) => {
                      return (
                        <MenuItem value={ratings}>
                          <Checkbox
                            checked={rating.includes(`${ratings}`)}
                          />
                          {ratings}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="filter-item">
                  <label className="filter-label">Gender</label>
                  <Select
                    multiple
                    value={gender}
                    className="filter-select"
                    onChange={onChangeGender}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    <MenuItem value="male">
                      <Checkbox checked={gender.includes("male")} />
                      Male
                    </MenuItem>
                    <MenuItem value="female">
                      {" "}
                      <Checkbox checked={gender.includes("female")} />
                      Female
                    </MenuItem>
                    <MenuItem value="others">
                      {" "}
                      <Checkbox checked={gender.includes("others")} />
                      Others
                    </MenuItem>
                  </Select>
                </div>

              </div>
              <div className="filter-buttons-wrap">
                <button className="btn btn-primary" onClick={resetFilter}>
                  RESET
                </button>
                <button className="btn btn-secondary" onClick={onApplyFilter}>
                  APPLY
                </button>
              </div>
            </div>
          )}


        </div>
      </div>
      <div className="recreation_table_container">

        {showTabs && <div className="category_tabs_filter_container mt-1 mb-1">
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={tabValue}
              // onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              className="header_tabs"
            >
              <Tab
                value={SERVICES_STATUS.PERSONAL_TRAINER}
                label={t(SERVICES_STATUS.PERSONAL_TRAINER)}
                onClick={() => { navigate(ROUTES.RECREATIONAL_BOOKINGS + `?type=personal`) }}
              />
              <Tab
                value={SERVICES_STATUS.ACTIVITY_CAMP}
                label={t(SERVICES_STATUS.ACTIVITY_CAMP)}
                onClick={() => { navigate(ROUTES.RECREATIONAL_BOOKINGS + `?type=camp`) }}
              />
            </Tabs>
          </Box>
        </div>}

        <TableContainer component={Paper} className="categoryTable">
          <Table>
            <TableHead className="categoryTableHead">
              <TableRow className="categoryTableRow">
                <TableCell>{t("S.NO")}</TableCell>
                <TableCell onClick={() => handleSortID()}>
                    <TableSortLabel active={true} direction={idSort}>
                      {location.pathname === ROUTES.MANAGE_RECREATIONAL_PERSONAL ? t("Personal Trainer ID") : t("Camp ID")}
                    </TableSortLabel>
                  </TableCell>
                {location.pathname !== ROUTES.MANAGE_RECREATIONAL_PERSONAL && <TableCell>{t("Camp Name")}</TableCell>}
                {location.pathname !== ROUTES.MANAGE_RECREATIONAL_PERSONAL && <TableCell>{t("Camp Sessions")}</TableCell> }
                <TableCell>{t("Institute/Trainer Name")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Phone")}</TableCell>
                <TableCell>{t("Location")}</TableCell>
                {urlParam === ACTIVITIES_TYPE.PERSONAL && <TableCell>{t("Rating")}</TableCell>}
                {/* <TableCell>{t("Status")}</TableCell> */}
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {hasProducts &&
                courseList.map((course: any, index: any) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {page - 1 === 0
                          ? index + 1
                          : (page - 1) * rowPerPage + (index + 1)}
                      </TableCell>
                      <TableCell>{ course.id}</TableCell>
                      {location.pathname !== ROUTES.MANAGE_RECREATIONAL_PERSONAL && <TableCell>{course.campname}</TableCell>}
                      {location.pathname !== ROUTES.MANAGE_RECREATIONAL_PERSONAL ? <TableCell>{
                      course?.camp_session?.length > 0 ?
                      course.camp_session.map((res:any,index:number)=>{
                        return(
                          <>{res}{index +1 !== course.camp_session.length && <>,</>}</>
                        )
                      })
                      :
                      "N/A"
                      }</TableCell> : ""}

                      <TableCell className="name-cell row-capitalize">
                        {course?.profile_details.firstname}
                      </TableCell>
                      <TableCell>{course?.profile_details.email}</TableCell>
                      <TableCell>
                        {course?.profile_details.phone_number}
                      </TableCell>
                      <TableCell>{course?.address}</TableCell>
                      {urlParam === ACTIVITIES_TYPE.PERSONAL && <TableCell>{course?.rating}</TableCell>}
                      {/* <TableCell>
                        {" "}
                        {course.status === VALIDATION_CONSTANT.PENDING ? (
                          <Chip
                            className="row-capitalize"
                            label={course.status}
                            color="warning"
                          />
                        ) : course.status === VALIDATION_CONSTANT.APPROVED ? (
                          <Chip
                            className="row-capitalize"
                            label={course.status}
                            color="success"
                          />
                        ) : (
                          <Chip
                            className="row-capitalize"
                            label={course.status}
                            color="error"
                          />
                        )}
                      </TableCell> */}
                      {/* <TableCell>{course?.status}</TableCell> */}
                      <TableCell>
                        <div className="action-wrap">
                          <Tooltip
                            title={t("View Recreational")}
                            placement="bottom"
                            onClick={() => {
                              location.pathname === ROUTES.RECREATIONAL_BOOKINGS ?
                                navigate(`/manage-recreational/bookings/${course.user}?type=${urlParam === ACTIVITIES_TYPE.PERSONAL ? 'personal' : 'camp'}`)
                                :
                                navigate(goToViewDetail(course.user));
                            }}
                          >
                            <VisibilityIcon className="tag-edit-icon" />
                          </Tooltip>

                          {location.pathname !== ROUTES.RECREATIONAL_BOOKINGS && (
                            <>
                              {course?.is_block === true ? (
                                <div
                                  className="reject_btn_wrapper"
                                  onClick={() => {
                                    setCurrentUser(course.user);
                                    blockUnblockTutor(course.user, course?.is_block);
                                  }}
                                >
                                  <div className="accept_btn_label">
                                    {t("Unblock")}
                                  </div>
                                  <div className="lock_icon_wrapper">
                                    <HttpsIcon className="lock_icon" />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="accept_btn_wrapper"
                                  onClick={() => {
                                    setCurrentUser(course.user);
                                    blockUnblockTutor(course.user, course?.is_block);
                                  }}
                                >
                                  <div className="unlock_icon_wrapper">
                                    <LockOpenTwoToneIcon className="unlock_icon" />
                                  </div>
                                  <div className="accept_btn_label">{t("Block")}</div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div className="tablePagination">
          <Pagination
            count={totalPage}
            page={page}
            onChange={handleChangePage}
          />
        </div> */}
        {hasProducts && (
          <div className="tablePagination">
            <div className="row">
              <div className="col-md-7">
                <div className="pagination-wrap">
                  <Pagination
                    count={totalPage}
                    page={page}
                    onChange={handleChangePage}
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="rows-per-page">
                  <p>{t("Rows per page")}:</p>
                  <select
                    className="rows-select"
                    value={rowPerPage}
                    onChange={onRowChange}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {!hasProducts && <NoProducts />}
      </div>
    </div>
  );
};

export default RecreationalList;
