import { Field, Formik } from "formik";
import "./CreateThemepark.scss";
import { Card, ListItemButton } from "@mui/material";
import List from "@mui/material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  S3DataGetApi,
  checkValueNullUndefinedEmpty,
  nullUndefinedEmptyCheck,
  validateEnglish,
} from "../../../Services/Helpers/Helper";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSelector } from "react-redux";
import * as yup from "yup";
import {
  EDIT_UNIVERSITY_ERROR_MESSAGE,
  ERROR_MESSAGES,
  REGEX,
  THEMEPARK_MESSAGES,
} from "../../../Utilities/Constants/Validations";
import {
  ADD_SCHOOLS,
  ADD_UNIVERSITY_FIELDS,
  API_RESPONSE,
  COMMON_CONSTANTS,
  I18_TRANSLATION_COMMON,
  ROUTES,
  TOASTER_CONFIGS,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
// import Autocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import UploadTickets from "../UploadTickets/UploadTickets";
import BackArrow from "../../../../Assets/schools/back_button.svg";
import { isValidPhoneNumber } from "libphonenumber-js";
import { GoogleMap, Marker, MarkerF, useLoadScript, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import RoomIcon from '@mui/icons-material/Room';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import upload_image from "../../../../Assets/schools/upload_image.svg";
import close from "../../../../Assets/schools/close.svg";
const libraries: any = ['places'];
const CreateThemePark = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const navigate = useNavigate();
  const [highlights, setHighlights]: any = useState([]);
  const [currentHighlight, setCurrentHighlight] = useState("");
  const [events, setEvents]: any = useState([]);
  const [currentEvent, setCurrentEvent]: any = useState("");
  const [currentCategory, setCurrentCategory]: any = useState("");
  const [currentImage, setCurrentEventImage]: any = useState("");
  const [themeCoverImage, setThemeCoverImage]: any = useState([]);
  const [themePromotionImage, setThemePromotionImage]: any = useState("");
  const themeParkCoverImage = useRef<HTMLInputElement | null>(null);
  const themeParkPromotionImage = useRef<HTMLInputElement | null>(null);
  const eventImage = useRef<HTMLInputElement | null>(null);
  const ticketImage = useRef<HTMLInputElement | null>(null);
  const [ticketArray, setTicketArray]: any = useState([]);
  const [currentTicketPrice, setCurrentTicketPrice]: any = useState("");
  const [currentTicketOfferPrice, setCurrentTicketOfferPrice]: any = useState("");
  const [currentTicketDescription, setCurrentTicketDescription]: any = useState("");
  const [currentTicketType, setCurrentTicketType]: any = useState('');
  const [currentTicketImage, setCurrentTicketImage]: any = useState("");
  const [maxPrice, setMaxPrice]: any = useState(0);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [validNumber, setValidNumber] = useState(true);
  const [CategoryList, setCategoryList] = useState<any>([]);
  const [EmiratesList, setEmiratesList] = useState<any>([]);
  const [TicketTypeList, setTicketTypeList] = useState<any>([]);
  const [splashList, setSplashList] = useState([]);
  const [currentLocation, setCurrentLocation]: any = useState();
  const [themeParkId, setThemeParkId]: any = useState();
  const [mapEnabled, setMapEnabled] = useState(API_RESPONSE.TRUE);
  const [formSubmitted, setFormSubmitted] = useState(API_RESPONSE.FALSE);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const inputRef = useRef<any>(null);
  useEffect(() => { }, [highlights]);
  const { id } = useParams();
  const language: any = useSelector((state: any) => {
    return state.languageReducer.language;
  });
  const aws: any = useSelector((state: any) => {
    return state.awsReducer.aws;
  });
  const { isLoaded }: any = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    libraries
  });
  const geocoder: any = isLoaded ? new window.google.maps.Geocoder() : '';
  const [themeParkDetail, setThemeParkDetail]: any = useState();
  /* {
        themepark_name: "",
        email: "",
        phone_number: "",
        min_price: "",
        max_price: "",
        address: "",
        rating: "",
        // latitude:"",
        // longitude:"",
        description: "",
      } */

  const getThemeParkDetail = () => {
    axiosInstance
      .get(API_END_POINTS.CREATE_THEMEPARK + "?id=" + id)
      .then((res) => {
        var DataResponse = res.data.result.data[0];
        setThemeParkId(res.data.result.data[0]?.id)
        setThemeParkDetail({
          themepark_name: res.data.result.data[0]?.themepark_name,
          email: res.data.result.data[0]?.email,
          phone_number: res.data.result.data[0]?.phone_number,
          min_price: res.data.result.data[0]?.min_price,
          max_price: res.data.result.data[0]?.max_price,
          address: res.data.result.data[0]?.address,
          rating: res.data.result.data[0]?.rating ? res.data.result.data[0]?.rating : null,
          city: res.data.result.data[0]?.city,
          // latitude:"",
          // longitude:"",
          description: res.data.result.data[0]?.description,
          splash_sequence: res.data.result.data[0]?.splash_sequence,
        });
        setCurrentLocation({ lat: parseFloat(DataResponse?.latitude), lng: parseFloat(DataResponse?.longitude) });
        setValidNumber(
          isValidPhoneNumber(res.data.result.data[0]?.phone_number)
        );
        // setThemeParkData(res.data.result.data[0]);
        setHighlights(res.data.result.data[0]?.highlights);
        setEvents(res.data.result.data[0]?.main_events);
        setTicketArray(res.data.result.data[0]?.ticket_type)
        setMaxPrice(parseInt(res.data.result.data[0]?.max_price))
        setThemeCoverImage(DataResponse?.themepark_cover_images?.length > 0 ? DataResponse?.themepark_cover_images : [])
        setThemePromotionImage(DataResponse?.promotion_image ? DataResponse?.promotion_image : null)
      });
  };

  useEffect(() => {
    // Get current user's location using the browser's Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
      );
    }
  }, [])

  useEffect(() => {
    if (id)
      getSplashSequenceList();

  }, []);

  useEffect(() => {
    if (id)
      getThemeParkDetail();
  }, [splashList])

  useEffect(() => {
    getCategoryApiCall();
    getEmiratesApiCall();
  }, []);


  const getSplashSequenceList = () => {
    axiosInstance.get(API_END_POINTS.THEME_PARK_LIST).then((response: any) => {
      if (response.data.status === API_RESPONSE.TRUE) {
        setSplashList(response.data.result.data);
      }
    })
  };

  const getCategoryApiCall = () => {
    axiosInstance
      .get(API_END_POINTS.THEME_PARK_CATEGORY_LIST)
      .then((res: any) => {
        if (res.data.status === true) {
          setCategoryList(res.data.result.data);
        }
      });
  }

  const getEmiratesApiCall = () => {
    axiosInstance
      .get(API_END_POINTS.EMIRATES_CITY_LIST)
      .then((res: any) => {
        if (res.data.status) {
          setEmiratesList(res?.data?.result?.data);
        }
      });
  }

  /* Yup initialization value */
  const themeInitialValues = id
    ? {
      themepark_name: themeParkDetail?.themepark_name,
      email: themeParkDetail?.email,
      phone_number: themeParkDetail?.phone_number,
      min_price: themeParkDetail?.min_price,
      max_price: themeParkDetail?.max_price,
      address: themeParkDetail?.address,
      rating: themeParkDetail?.rating,
      city: themeParkDetail?.city,
      // latitude:"",
      // longitude:"",
      description: themeParkDetail?.description,
      splash_sequence: themeParkDetail?.splash_sequence,
    }
    : {
      themepark_name: "",
      email: "",
      phone_number: "",
      min_price: "",
      max_price: "",
      address: "",
      rating: null,
      city: "",
      splash_sequence: null,
      // latitude:"",
      // longitude:"",
      description: "",
    };

  /* Yup validation schema */
  const themeValidationSchema = yup.object({
    themepark_name: yup
      .string()
      .required(THEMEPARK_MESSAGES.THEMEPARK_NAME)
      .trim(),
    email: yup
      .string()
      .required(ERROR_MESSAGES.EMAIL_REQUIRED)
      .matches(REGEX.EMAIL, ERROR_MESSAGES.INVALID_EMAIL)
      .email(ERROR_MESSAGES.INVALID_EMAIL)
      .trim(),

    phone_number: yup.string().required(ERROR_MESSAGES.PHONE_NUMBER),
    min_price: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value); // Allow empty value
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value.toString()) &&
            !value.toString().endsWith(".")
          );
        }
      )
      .required(ERROR_MESSAGES.MIN_PRICE_MSG),
    max_price: yup
      .mixed()
      .test(
        VALIDATION_CONSTANT.VALID_NUMBER,
        ERROR_MESSAGES.AMOUNT_FORMAT,
        function (value: any) {
          checkValueNullUndefinedEmpty(value);
          const decimalRegex = REGEX.DECIMAL_VALIDATION;
          return (
            decimalRegex.test(value.toString()) &&
            !value.toString().endsWith(".")
          );
        }
      )
      .test("superior", ERROR_MESSAGES.MIN_MAX_MATCH, function (value: any) {
        const ref: any = yup.ref(VALIDATION_CONSTANT.MIN_PRICE);
        return parseFloat(value) > parseFloat(this.resolve(ref));
      })
      .typeError("typo")
      .required(ERROR_MESSAGES.MAX_PRICE_MSG)
      .nullable(),
    address: yup.string().required(THEMEPARK_MESSAGES.ADDRESS_ERR),
    city: yup.string().required(THEMEPARK_MESSAGES.CITY_ERR),
    splash_sequence: yup
      .string()
      .nullable(),
    // .typeError(THEMEPARK_MESSAGES.NUMBER_FORMAT)
    // .required(THEMEPARK_MESSAGES.SPLASH_ERROR),
    rating: yup
      .number()
      .nullable()
      .max(5, EDIT_UNIVERSITY_ERROR_MESSAGE.MAXIMUM_RATING)
      .typeError(THEMEPARK_MESSAGES.NUMBER_FORMAT),
    // .required(THEMEPARK_MESSAGES.RATING_ERROR),
    /*   latitude:yup.string()
            .matches(REGEX.LATITUDE_VALIDATION,THEMEPARK_MESSAGES.LATITUDE_FORMAT)
            .required(THEMEPARK_MESSAGES.LATITUDE),
            // .matches('^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$','ooo')
        longitude:yup.string()
            .matches(REGEX.LONGITUDE_VALIDATION,THEMEPARK_MESSAGES.LONGITUDE_FORMAT)
            .required(THEMEPARK_MESSAGES.LONGITUDE),
        */ // .matches('^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$','ooo')
    description: yup
      .string()
      .required(THEMEPARK_MESSAGES.DESCRIPTION)
      .max(450)
      .min(1),
  });

  /* Adding highlights */
  const onAddHighlights = () => {
    if (
      nullUndefinedEmptyCheck(currentHighlight.trim()) &&
      highlights.filter((item: any) => item === currentHighlight).length === 0
    ) {
      setHighlights([...highlights, currentHighlight]);
    } else {
      if (checkValueNullUndefinedEmpty(currentHighlight.trim()))
        toast.error(t(THEMEPARK_MESSAGES.HIGHLIGHT_PLACEHOLDER));
    }
    setCurrentHighlight("");
  };

  /* Setting highlight value */
  const setHighlightValue = (event: any) => {
    setCurrentHighlight(event.target.value);
  };

  /* Deleting highlights */
  const onDeleteHighlight = (i: any) => {
    setHighlights(highlights.filter((item: any, index: any) => index !== i));
  };

  /* Delete event from events */
  const onDeleteEvent = (i: any) => {
    setEvents(events.filter((item: any, index: any) => index !== i));
  };

  /* Delete Ticket from TicketArray */
  const onDeleteTicketArray = (i: any) => {
    setTicketArray(ticketArray.filter((item: any, index: any) => index !== i));
  };

  /* Updating cover image */
  const onUploadPromotionImge = async (event: any) => {
    try {
      if (event.target.files[0].type.split("/")[0] === "image") {
        const uploadedImage = await S3DataGetApi(event);
        setThemePromotionImage(uploadedImage);
      } else {
        toast.error(t(THEMEPARK_MESSAGES.IMAGE_FORMAT));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  /* Updating cover image */
  const onUploadThemeParkImage = async (event: any) => {
    try {
      if (event.target.files[0].type.split("/")[0] === "image") {
        const uploadedImage = await S3DataGetApi(event);
        setThemeCoverImage((prev: any) => [
          ...prev,
          uploadedImage,
        ]);
      } else {
        toast.error(t(THEMEPARK_MESSAGES.IMAGE_FORMAT));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  /* Updating event image */
  const onUploadEventImage = async (event: any) => {
    try {
      if (event.target.files[0].type.split("/")[0] === "image") {
        const uploadedImage = await S3DataGetApi(event);
        setCurrentEventImage(uploadedImage);
      } else {
        toast.error(t(THEMEPARK_MESSAGES.IMAGE_FORMAT));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  /* Updating ticket image */
  const onUploadTicketImage = async (event: any) => {
    try {
      if (event.target.files[0].type.split("/")[0] === "image") {
        const uploadedImage = await S3DataGetApi(event);
        setCurrentTicketImage(uploadedImage);
      } else {
        toast.error(t(THEMEPARK_MESSAGES.IMAGE_FORMAT));
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  /* Delete cover image  */
  const handleDeleteCoverImg = (i: any) => () => {
    setThemeCoverImage(
      themeCoverImage.filter((item: any, index: any) => index !== i)
    );
  };

  /* Set event value */
  const setEvent = (event: any) => {
    setCurrentEvent(event.target.value);
  };

  /* Adding Event tags */
  const onAddEventTags = () => {
    if (currentEvent !== "" && currentImage !== "" && currentCategory !== "") {
      let pushPayload = {
        id: 0,
        event_name: currentEvent,
        event_image: currentImage,
        category_id: currentCategory
      };
      setEvents([...events, pushPayload]);

      setCurrentEvent("");
      setCurrentEventImage("");
      setCurrentCategory("")
    } else {

      if (currentEvent === "") {
        toast.error(t(THEMEPARK_MESSAGES.EVENT_NAME));
      } else if (currentImage === "") {
        toast.error(t(THEMEPARK_MESSAGES.EVENT_IMAGE));
      } else {
        toast.error(t(THEMEPARK_MESSAGES.SELECT_CATEGORY));
      }
    }
  };

  /* Adding Ticket tags */
  const onAddTicketTags = () => {
    const ticketData = TicketTypeList.filter((data: any) => data.id == currentTicketType);
    if (currentTicketPrice !== "" && currentTicketOfferPrice !== "" && currentTicketType !== "" && currentTicketDescription !== "") {
      let pushPayload = {
        ticket_type: ticketData[0]?.value,
        image: ticketData[0]?.icon,
        ticket_price: currentTicketPrice,
        description: currentTicketDescription,
        discount_price: currentTicketOfferPrice,
      };
      setTicketArray([...ticketArray, pushPayload]);

      setCurrentTicketImage("");
      setCurrentTicketDescription("")
      setCurrentTicketPrice("");
      setCurrentTicketType("")
      setCurrentTicketOfferPrice("");
    } else {

      if (currentTicketType === "") {
        toast.error(t(THEMEPARK_MESSAGES.TICKET_TYPE));
      } else if (currentTicketDescription === "") {
        toast.error(t(THEMEPARK_MESSAGES.DESCRIPTION));
      } else if (currentTicketOfferPrice === "") {
        toast.error(t(THEMEPARK_MESSAGES.TICKET_PRICE));
      } else {
        toast.error(t(THEMEPARK_MESSAGES.TICKET_OFFER_PRICE));
      }
    }
  };

  /* To restrict inputs for only numbers */
  function numberOnly(event: any) {
    const input = event.target;
    const value = input.value;

    // Remove non-digit and non-decimal characters except the first dot
    const sanitizedValue = value.replace(
      /[^\d.]/g,
      (match: any, offset: any) => {
        if (match === "." && offset === 0) {
          return match;
        }
        return "";
      }
    );

    // Limit to two digits after the decimal point
    const decimalIndex = sanitizedValue.indexOf(".");
    if (decimalIndex !== -1 && sanitizedValue.length - decimalIndex > 2 + 1) {
      input.value = sanitizedValue.slice(0, decimalIndex + 3);
    } else {
      input.value = sanitizedValue;
    }
  }

  /* Create theme park API call */
  const onCreateThemePark = (payload: any) => {
    if (events.length !== 0 && highlights.length !== 0) {
      payload.latitude = currentLocation?.lat?.toString();
      payload.longitude = currentLocation?.lng?.toString();
      payload.highlights = highlights;
      payload.main_events = events;
      payload.rating = payload.rating !== "" ? payload.rating : null;
      payload.splash_sequence = payload.splash_sequence;
      // payload.ticket_type = ticketArray;
      payload.themepark_cover_images = themeCoverImage;
      payload.arabic_highlights = [];
      payload.promotion_image = themePromotionImage ? themePromotionImage : null;
      id
        ? axiosInstance
          .put(API_END_POINTS.CREATE_THEMEPARK + "?id=" + id, payload)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message, TOASTER_CONFIGS);
              navigate(`/manage-themepark/list/ticket-list/${themeParkId}`);
              // navigate(ROUTES.MANAGE_THEMEPARK);
            }
          })
        : axiosInstance
          .post(API_END_POINTS.CREATE_THEMEPARK, payload)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message, TOASTER_CONFIGS);
              navigate(`/manage-themepark/list/ticket-list/${res?.data?.result?.themepark_id}`);
              // navigate(ROUTES.MANAGE_THEMEPARK);
            }
          });
    } /* else {
      if (events.length === 0) {
        toast.error(t(THEMEPARK_MESSAGES.HIGHLIGHT_POINT_REQ));
      } else if (highlights.length === 0) {
        toast.error(t(THEMEPARK_MESSAGES.HIGHLIGHT_POINT_VAL))
      }
    } */
  };

  const setCurrentLatLng = (latitude: any, longitude: any) => {
    setCurrentLocation({ lat: latitude, lng: longitude });
  }

  const getFormattedAddress = (lat: any, lng: any, formik: any, check: boolean) => {
    geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
      if (status === "OK") {
        if (results[COMMON_CONSTANTS.ZERO] && check) {
          formik.setFieldValue(
            "address",
            results[COMMON_CONSTANTS.ZERO].formatted_address
          )
          //  setAddressData(results[COMMON_CONSTANTS.ZERO].address_components,formik)
        }
      }
    });
  }

  const onPlaceChanged = (formik: any) => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place?.geometry?.location;
        const lat = location.lat();
        const lng = location.lng();

        // Set lat/lng after fetching
        setCurrentLatLng(lat, lng);
        getFormattedAddress(lat, lng, formik, false);
        if (inputRef?.current) {
          const inputValue = inputRef.current.value;
          formik.setFieldValue("address", inputValue);
        }
      }
    }
  };

  const onAutocompleteLoad = (autoC: any) => {
    setAutocomplete(autoC); // Save the autocomplete instance
  };

  return (
    <>
      <div>
        <Formik
          initialValues={themeInitialValues}
          validationSchema={themeValidationSchema}
          onSubmit={(values: any) => {
            onCreateThemePark(values);
          }}
          enableReinitialize={true}
        >
          {(formik) => {
            return (
              <div className="theme-wrap">
                <div className="buttons-wrap">
                  <div className="arrow-wrap">
                    <img
                      src={BackArrow}
                      className="EditSchool-backarrow"
                      onClick={() => {
                        navigate(ROUTES.MANAGE_THEMEPARK);
                      }}
                      alt="Learn and Play"
                    />
                    <h5 className="name-title">
                      {id ? t("Edit Theme Park") : t("Create Theme Park")}
                    </h5>
                  </div>

                  {/*  <div className="park-buttons-wrap">
                    {id && (
                      <div className="upload-ticket-button ">
                        <button
                          className="btn btn-secondary ticket-btn"
                          onClick={() => {
                            setDialogStatus(true);
                          }}
                        >
                          {t("Upload Tickets")}
                        </button>
                      </div>
                    )}
                    {id && (
                      <div className="view-ticket-button">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            navigate(`/manage-themepark/tickets/${id}`);
                          }}
                        >
                          {t("View Tickets")}
                        </button>
                      </div>
                    )}
                  </div> */}

                  <UploadTickets
                    dialogState={dialogStatus}
                    id={id}
                    handleClose={() => {
                      setDialogStatus(false);
                    }}
                  />
                </div>

                <Card className="basic-card-wrap">
                  <h5 className="theme-card-title">{t("Basic Details")}</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <label className="nameLabel mandatory_fields">
                          {t("Theme Park Name")}
                        </label>
                        <Field
                          name="themepark_name"
                          value={formik.values.themepark_name}
                          className="themepark_name theme-input"
                          placeholder={t("Theme Park Name")}
                          onKeyPress={validateEnglish}
                          onPaste={validateEnglish}
                        />
                        <div className="errorMessage">
                          {formik.touched.themepark_name &&
                            formik.errors.themepark_name && (
                              <p>{t(`${formik.errors.themepark_name}`)}</p>
                            )}
                        </div>
                      </div>

                      <div>
                        <label className="nameLabel mandatory_fields mandatory_fields">
                          {t("Phone Number")}
                        </label>
                        <PhoneInput
                          inputProps={{
                            className:
                              "phone_number phone_num_input theme-input",
                            name: "phone_number",
                          }}
                          country={"ae"}
                          // placeholder=""
                          value={formik.values.phone_number}
                          onBlur={formik.handleBlur("phone_number")}
                          onClick={formik.handleBlur("phone_number")}
                          onChange={(e) => {
                            formik.setFieldValue("phone_number", `+${e}`);
                            setTimeout(() => {
                              setValidNumber(isValidPhoneNumber(`+${e}`));
                            }, 0);
                          }}
                          countryCodeEditable={true}
                        />
                        <p className="mb-0 phone-validation">{COMMON_CONSTANTS.PHONE_FORMAT}</p>
                        <div className="errorMessage">
                          {validNumber === false && (
                            <p>{t(`Please enter valid phone number`)}</p>
                          )}
                          {formik.touched.phone_number &&
                            formik.errors.phone_number && (
                              <p>{t(`${formik.errors.phone_number}`)}</p>
                            )}
                        </div>
                      </div>

                      <div>
                        <label className="nameLabel mandatory_fields">
                          {t("Minimum Price(AED)")}
                        </label>
                        <Field
                          name="min_price"
                          className="min_price theme-input"
                          onInput={numberOnly}
                          onPaste={numberOnly}
                          maxLength={6}
                          placeholder={t("Minimum Price(AED)")}
                        />
                        <div className="errorMessage">
                          <div className="errorMessage">
                            {formik.touched.min_price &&
                              formik.errors.min_price && (
                                <p>{t(`${formik.errors.min_price}`)}</p>
                              )}
                          </div>
                        </div>
                      </div>

                      <div>
                        <label className="nameLabel">{t("Rating")}</label>
                        <Field
                          name="rating"
                          className="min_price theme-input"
                          onInput={numberOnly}
                          onPaste={numberOnly}
                          maxLength={1}
                          placeholder={t("Rating")}
                        />
                        <div className="errorMessage">
                          <div className="errorMessage">
                            {formik.touched.rating && formik.errors.rating && (
                              <p>{t(`${formik.errors.rating}`)}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="nameLabel mandatory_fields">{t("Theme Park Cover Image")}</label>
                        <div className="ThemePark__Banner_img__wapper">
                          <input
                            className="image_input"
                            type="file"
                            accept="image/png,image/jpg,image/jpeg,image/svg"
                            onChange={onUploadThemeParkImage}
                            ref={themeParkCoverImage}
                          />
                          <div className="add_images_wrapper">
                            <div
                              className="add_images_cotainer"
                              onClick={() => themeParkCoverImage.current?.click()}
                            >
                              <img src={upload_image} alt="Learn and Play" />
                              <div>{t("Add Image")}</div>
                            </div>
                            <div className="text_contant">Recommended size: 720x896 with JPEG,PNG</div>
                          </div>
                        </div>
                        {themeCoverImage?.length > 0 && (
                          <div className="CoverImg__image-array-wrap">
                            {themeCoverImage?.map((image: any, index: any) => {
                              return (
                                <div className="BannerImg__image-wrap" key={index}>
                                  <img
                                    src={image}
                                    key={index}
                                    className="BannerImglist"
                                    alt="Learn and Play"
                                  />
                                  <img
                                    className="cancel-icon"
                                    onClick={handleDeleteCoverImg(index)}
                                    src={close}
                                    alt="Learn and Play"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {formSubmitted && themeCoverImage?.length === 0 && <div className="errorMessage">
                          Please upload cover image
                        </div>}
                      </div>
                      {/* <div>
                                <label className='nameLabel mandatory_fields'>{t('Latitude')}</label>
                                <Field
                                name="latitude"
                                className="max_price theme-input"
                                placeholder={t('Latitude')}
                                validate={validateNumber}
                                onKeyPress={(e:any) => {
                                    const validCharacters = /^[0-9.]$/; // Allow numbers and dot
                                    if (!validCharacters.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                                />
                             <div className="errorMessage">
                                 {formik.touched.latitude && formik.errors.latitude && (
                                <p>
                                    {t(`${formik.errors.latitude}`)}
                                </p>
                                )}  
                                </div>
                            </div> */}
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label className="nameLabel mandatory_fields">{t("Email")}</label>
                        <Field
                          name="email"
                          className="email theme-input"
                          placeholder="ex., name@example.com"
                        />
                        <div className="errorMessage">
                          {formik.touched.email && formik.errors.email && (
                            <p>{t(`${formik.errors.email}`)}</p>
                          )}
                        </div>
                      </div>

                      {/* <div>
                        <label className="nameLabel mandatory_fields">{t("Address")}</label>
                        <Field
                          name="address"
                          className="arabic_coursecategory_name theme-input"
                          placeholder={t("Address")}
                        />
                        <div className="errorMessage">
                          {formik.touched.address && formik.errors.address && (
                            <p>{t(`${formik.errors.address}`)}</p>
                          )}
                        </div>
                      </div> */}

                      <div className="fields_label_wrappers">
                        <label className="fields_label nameLabel mandatory_fields">{t("Location")}</label>
                        <div className="address-wrapper">
                          {isLoaded &&
                            // <Autocomplete
                            //   className="auto-complete"
                            //   id={ADD_SCHOOLS.LOCATION}
                            //   name="address"
                            //   apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            //   onBlur={formik.handleBlur}
                            //   value={formik?.values?.address}
                            //   onChange={(e: any) => {
                            //     formik.setFieldValue("address", e.target.value)
                            //   }}

                            //   onPlaceSelected={(place: any) => {
                            //     setCurrentLatLng(place.geometry.location.lat(), place.geometry.location.lng());
                            //     getFormattedAddress(place.geometry.location.lat(), place.geometry.location.lng(), formik);
                            //   }}
                            // />

                            <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={() => onPlaceChanged(formik)}>
                              <input
                                type="text"
                                className="auto-complete"
                                name={ADD_SCHOOLS.LOCATION}
                                placeholder="Enter a place"
                                ref={inputRef}
                                value={formik.values.address} // Bind formik value
                                onChange={formik.handleChange}
                              />
                            </Autocomplete>
                          }
                          {mapEnabled && <RoomIcon className="room-icon" onClick={() => { setMapEnabled(!mapEnabled) }} />}
                          {!mapEnabled && <LocationOffIcon className="room-icon" onClick={() => { setMapEnabled(!mapEnabled) }} />}
                        </div>
                        {isLoaded && mapEnabled && <GoogleMap
                          mapContainerClassName="map-container"
                          center={currentLocation}
                          zoom={10}
                        >
                          <MarkerF
                            position={currentLocation}
                            draggable={true}
                            onDragEnd={
                              (e: any) => {
                                setCurrentLatLng(e.latLng.lat(), e.latLng.lng());
                                getFormattedAddress(e.latLng.lat(), e.latLng.lng(), formik, true);
                              }}
                          />
                        </GoogleMap>}
                        <div className="errorMessage">
                          {formik.touched.address && formik.errors.address && (
                            <p>{t(`${formik.errors.address}`)}</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label className="nameLabel mandatory_fields">{t("City")}</label><br />
                        <div className="select-wrapper">
                          <Field as="select" className="basic-select-menu_city" name="city"
                            value={formik.values["city"]}
                            onChange={(e: any) => {
                              formik.setFieldValue("city", e.target.value)
                            }}>
                            <option value="">Please select the city</option>
                            {EmiratesList.map((city: any, index: any) => {
                              return (
                                <option value={city?.emirate_city}>{city.emirate_city}</option>
                              )
                            })}
                          </Field>
                        </div>

                        <div className="errorMessage">
                          {formik.touched.city &&
                            formik.errors.city && (
                              <p>{t(`${formik.errors.city}`)}</p>
                            )}
                        </div>
                      </div>

                      <div>
                        <label className="nameLabel mandatory_fields">
                          {t("Maximum Price(AED)")}
                        </label>
                        <Field
                          name="max_price"
                          keypress="numberOnly($event)"
                          className="theme-input"
                          onInput={numberOnly}
                          maxLength={6}
                          onChange={(e: any) => {
                            setMaxPrice(e.target.value)
                            formik.setFieldValue("max_price", e.target.value)
                          }}
                          placeholder={t("Maximum Price(AED)")}
                        />
                        <div className="errorMessage">
                          {formik.touched.max_price &&
                            formik.errors.max_price && (
                              <p>{t(`${formik.errors.max_price}`)}</p>
                            )}
                        </div>
                      </div>

                      {id && <div className="mb-3">
                        <label className="nameLabel mandatory_fields ">{t("Splash Sequence")}</label>
                        <Field
                          name="splash_sequence"
                          as="select"
                          className="min_price theme-input"
                        /* onInput={numberOnly}
                        onPaste={numberOnly}
                        maxLength={6}
                        placeholder={t("Splash Sequence")} */
                        >
                          {splashList.map((splash: any, index: any) => {
                            return (
                              <option value={splash}>{splash}</option>
                            )
                          })}
                        </Field>
                        <div className="errorMessage">
                          <div className="errorMessage">
                            {formik.touched.splash_sequence && formik.errors.splash_sequence && (
                              <p>{t(`${formik.errors.splash_sequence}`)}</p>
                            )}
                          </div>
                        </div>
                      </div>}

                      {/* <div>
                        <label className="nameLabel mandatory_fields">{t("Theme Park Promotional Image")}</label>
                        <div className="ThemePark__Banner_img__wapper">
                          <input
                            className="image_input"
                            type="file"
                            accept="image/png,image/jpg,image/jpeg,image/svg"
                            onChange={onUploadPromotionImge}
                            ref={themeParkPromotionImage}
                          />
                          <div className="add_images_wrapper">
                            <div
                              className="add_images_cotainer"
                              onClick={() => themeParkPromotionImage.current?.click()}
                            >
                              <img src={upload_image} alt="Learn and Play" />
                              <div>{t("Add Image")}</div>
                            </div>
                            <div className="text_contant">Recommended size: 1080x360 with JPEG,PNG</div>
                          </div>
                        </div>
                        {nullUndefinedEmptyCheck(themePromotionImage) && (
                          <img
                            src={themePromotionImage}
                            className="PromotionImglist"
                            alt="Learn and Play"
                          />
                        )}
                        {formSubmitted && checkValueNullUndefinedEmpty(themePromotionImage) && <div className="errorMessage">
                           Please upload promotional image
                        </div>}
                      </div> */}

                      {/* <div >
                                <label className='nameLabel mandatory_fields'>{t('Longitude')}</label>
                                <Field
                                name="longitude"
                                className="theme-input"
                                placeholder={t('Longitude')}
                                validate={validateNumber}
                                onKeyPress={(e:any) => {
                                    const validCharacters = /^[0-9.]$/; // Allow numbers and dot
                                    if (!validCharacters.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                                />
                           <div className="errorMessage">
                             {formik.touched.longitude && formik.errors.longitude && (
                                <p>
                                    {t(`${formik.errors.longitude}`)}
                                </p>
                                )}
                            </div> 
                            </div> */}
                    </div>

                    <div>
                      <label className="nameLabel mandatory_fields">{t("Description")}</label>
                      <div className="highlight-wrap">
                        <Field
                          as="textarea"
                          className="theme-textarea"
                          name="description"
                          maxLength={1000}
                          /* onKeyPress={(event: any) => {
                            if (250 - formik.values.description.length <= 0)
                              event?.preventDefault();
                          }}
                          onPaste={(event: any) => {
                            const updatedValue =
                              formik.values.description +
                              event.clipboardData.getData("text");
                            if (updatedValue.length > 250) {
                              event.preventDefault();
                            }
                          }} */
                          placeholder={t(THEMEPARK_MESSAGES.DESC_PLACEHOLDER)}
                        />
                      </div>
                      <p className="characters-left">
                        {1000 - formik.values.description?.length}{" "}
                        {t("characters left")}
                      </p>
                      <div className="errorMessage">
                        {formik.touched.description &&
                          formik.errors.description && (
                            <p>{t(`${formik.errors.description}`)}</p>
                          )}
                      </div>
                    </div>
                  </div>
                </Card>

                <Card className="highlight-card-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <label className="nameLabel mandatory_fields">{t("Highlights")}</label>
                        <div className="highlight-wrap">
                          <Field
                            name="highlights"
                            className="arabic_coursecategory_name theme-input highlight-input"
                            value={currentHighlight}
                            onChange={setHighlightValue}
                            placeholder={t(THEMEPARK_MESSAGES.HIGHLIGHT_POINT)}
                            // dir="rtl"
                            onKeyPress={(event: any) => {
                              if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                                onAddHighlights();
                              }
                            }}
                          // onPaste={validateArabicPaste}
                          />

                          <button
                            className="btn btn-info highlight-add"
                            onClick={onAddHighlights}
                          >
                            {t("Add")}
                          </button>
                        </div>
                      </div>
                      {formSubmitted && highlights?.length === 0 && <div className="errorMessage">
                        {THEMEPARK_MESSAGES.HIGHLIGHT_POINT_VAL}
                      </div>}
                      {highlights.length > 0 && (
                        <div className="event-wrap">
                          <List>
                            {highlights.map((point: any, index: any) => {
                              return (
                                <div>
                                  <ListItemButton
                                    className="list-btn"
                                    key={index}
                                  >
                                    <ListItemText primary={point} />
                                    <DeleteIcon
                                      onClick={() => {
                                        onDeleteHighlight(index);
                                      }}
                                    />
                                  </ListItemButton>
                                </div>
                              );
                            })}
                          </List>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>

                <Card className="event-card-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="nameLabel mandatory_fields">{t("Events")}</label><br />
                      <Field as="select" className="basic-select-menu" name="category"
                        value={currentCategory}
                        onChange={(e: any) => {
                          setCurrentCategory(e.target.value)
                        }}>
                        <option value="">Please select the category</option>
                        {CategoryList.map((name: any, index: any) => {
                          return (
                            <option value={name?.id}>{name.category_name}</option>
                          )
                        })}
                      </Field>

                      <div className="file-upload-wrap">
                        <div className="highlight-wrap">
                          <Field
                            name="event_name"
                            className="theme-input event-input"
                            onChange={setEvent}
                            value={currentEvent}
                            // dir="rtl"
                            // onKeyPress={validateArabic}
                            // onPaste={validateArabicPaste}
                            placeholder={t("Event Name")}
                          />
                          {/* <div className="file-upload-wrap"> */}
                          <Field
                            disabled
                            name="event-name"
                            className=" theme-input event-input image-input"
                            value={currentImage}
                            onKeyPress={(event: any) => {
                              if (event.key === VALIDATION_CONSTANT.KEY_TYPE) {
                                onAddEventTags();
                              }
                            }}
                            placeholder={t("Event Image")}
                          />
                          <FileUploadIcon
                            className={
                              language === "English"
                                ? "theme-upload-icon"
                                : "theme-upload-icon-ar"
                            }
                            onClick={() => eventImage.current?.click()}
                          />
                          <input
                            className="image_input"
                            type="file"
                            accept="image/png,image/jpg,image/jpeg,image/svg"
                            onChange={onUploadEventImage}
                            ref={eventImage}
                          />
                          {/* </div> */}

                          <button
                            className="btn btn-info highlight-add"
                            onClick={onAddEventTags}
                          >
                            {t("Add")}
                          </button>
                        </div>
                      </div>
                      {formSubmitted && events?.length === 0 && <div className="errorMessage">
                        {THEMEPARK_MESSAGES.HIGHLIGHT_POINT_REQ}
                      </div>}
                      {events?.length > 0 && (
                        <div className="event-wrap">
                          <List>
                            {events.map((point: any, index: any) => {
                              return (
                                <div>
                                  <ListItemButton
                                    className="event-list-btn"
                                    key={index}
                                  >
                                    <ListItemText primary={point.event_name} />

                                    <img
                                      className="event-image"
                                      src={point.event_image}
                                    />
                                    <DeleteIcon
                                      className="delete-event"
                                      onClick={() => {
                                        onDeleteEvent(index);
                                      }}
                                    />
                                  </ListItemButton>
                                </div>
                              );
                            })}
                          </List>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
                {/* <Card className="event-card-wrap mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="file-upload-wrap">
                        <div className="tcount-twrap-wrap">
                          <div>
                            <label className="nameLabel mandatory_fields">{t("Ticket Type")}</label> <br />
                            <Field as="select" className="ticket_type-select-menu"
                              value={currentTicketType}
                              onChange={(e: any) => {
                                setCurrentTicketType(e.target.value)
                              }}>
                              <option value="">Please select ticket type</option>
                              {TicketTypeList.map((name: any, index: any) => {
                                return (
                                  <option value={name?.id}>{name?.value}</option>
                                )
                              })}
                            </Field>
                          </div>
                          <div>
                          </div>
                        </div>
                      </div>
                      <div className="tcount-twrap-wrap mt-3">
                        <div>
                          <label className="nameLabel mandatory_fields">{t("Ticket Price")}</label><br />
                          <Field
                            className="ticket-price-input theme-input"
                            onInput={numberOnly}
                            onPaste={numberOnly}
                            value={currentTicketPrice}
                            onChange={(e: any) => {
                              if (e.target.value <= parseInt(maxPrice)) {
                                setCurrentTicketPrice(e.target.value)
                              }
                            }}
                            maxLength={6}
                            placeholder={t("Ticket Price")}
                          />
                        </div>
                        <div>
                          <label className="nameLabel mandatory_fields">{t("Discount Price")}</label><br />
                          <Field
                            className="ticket-price-input theme-input"
                            onInput={numberOnly}
                            onPaste={numberOnly}
                            value={currentTicketOfferPrice}
                            onChange={(e: any) => {
                              if (e.target.value <= parseInt(currentTicketPrice)) {
                                setCurrentTicketOfferPrice(e.target.value)
                              }
                            }}
                            maxLength={6}
                            placeholder={t("Discount Price")}
                          />
                        </div>
                      </div>
                      <div className="highlight-wrap">
                        <Field
                          as="textarea"
                          className="theme-textarea mt-3"
                          onKeyPress={(event: any) => {
                            if (150 - currentTicketDescription?.length <= 0)
                              event?.preventDefault();
                          }}
                          value={currentTicketDescription}
                          onChange={(e: any) => setCurrentTicketDescription(e.target.value)}
                          placeholder={t("Description")}
                        />
                      </div>
                      <p>
                        {150 - formik.values.description?.length}{" "}
                        {t("characters left")}
                      </p>

                      <button
                        className="btn btn-info highlight-add"
                        onClick={onAddTicketTags}
                      >
                        {t("Add Ticket")}
                      </button>

                      {ticketArray?.length > 0 && (
                        <div className="event-wrap">
                          <List>
                            {ticketArray?.map((point: any, index: any) => {
                              return (
                                <div>
                                  <ListItemButton
                                    className="event-list-btn"
                                    key={index}
                                  >
                                    <ListItemText primary={"Ticket type : " + point?.ticket_type} />
                                    <ListItemText primary={"Ticket price : " + point?.ticket_price} />
                                    <ListItemText primary={"Discount price : " + point?.discount_price} />
                                    <ListItemText primary={"Description : " + point?.description} />

                                    <img
                                      className="event-image"
                                      src={point.image}
                                    />
                                    <DeleteIcon
                                      className="delete-event"
                                      onClick={() => {
                                        onDeleteTicketArray(index);
                                      }}
                                    />
                                  </ListItemButton>
                                </div>
                              );
                            })}
                          </List>
                        </div>
                      )}
                    </div>
                  </div>
                </Card> */}
                <div className="submit-btn-wrap">
                  <button
                    className="btn cancel"
                    onClick={() => {
                      navigate(ROUTES.MANAGE_THEMEPARK);
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn btn-secondary theme-submit-btn"
                    type="submit"
                    onClick={() => {
                      setFormSubmitted(true);
                      formik.handleSubmit();
                    }}
                  >
                    {t("Submit & continue")}
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CreateThemePark;
