import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../Services/Interceptor/Interceptor";
import API_END_POINTS from "../../../Utilities/Constants/ApiEndpoints";
import "./TutorsDetail.scss";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card } from "@mui/material";
import profileImg from "../../../../Assets/Login/loginBanner.png";
import DescriptionIcon from "@mui/icons-material/Description";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  ROUTES,
  I18_TRANSLATION_COMMON,
  TOASTER_CONFIGS,
  TRANSLATION_CONSTANT,
  VALIDATION_CONSTANT,
} from "../../../Utilities/Constants/Constants";
import { toast } from "react-toastify";
import ReasonDialog from "../../../Shared/ReasonDialog/ReasonDialog";
import { useTranslation } from "react-i18next";
import BackArrow from "../../../../Assets/schools/back_button.svg";

const TutorsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  const [currentUser, setCurrentUser] = useState("");
  const [tutorData, setTutorData]: any = useState();
  const [dialogOpenStatus, setDialogOpenStatus] = useState(false);
  const tutorDetail = () => {
    axiosInstance.get(API_END_POINTS.MANAGE_TUTOR + "?id=" + id).then((res) => {
      setTutorData(res.data.result.data[0]);
    });
  };

  const approveRejectTutor = (payload: any) => {
    axiosInstance
      .put(API_END_POINTS.MANAGE_TUTOR + "?id=" + currentUser, payload)
      .then((res: any) => {
        if (res.data.status === true) {
          toast.success(res.data.message, TOASTER_CONFIGS);
          //   getTutorsList();
        }
      });
  };

  useEffect(() => {
    tutorDetail();
  }, []);

  const onCloseDialog = () => {
    setDialogOpenStatus(false);
  };

  const onSubmitReason = (value: any) => {
    let payload = {
      status: VALIDATION_CONSTANT.REJECTED,
      reason: value.reason,
    };
    setDialogOpenStatus(false);
    approveRejectTutor(payload);
  };

  return (
    <>
      <div className="row view-course-wrap">
        <ReasonDialog
          modalState={dialogOpenStatus}
          dialogStatus={onCloseDialog}
          submitReason={onSubmitReason}
        />
        {/* <div className='course-title-wrap'>
                <ArrowBackIcon className='back_arrow_icon' onClick={()=>{navigate('/manage-tutors')}} />
                <h5>{t('Tutor Detail')}</h5>
            </div> */}
        <div className="row">
          <div className="col-md-12">
            <div className="arrow-wrap">
              <img
                src={BackArrow}
                alt="Learn and Play"
                className="EditSchool-backarrow"
                onClick={() => {
                  navigate(ROUTES.MANAGE_TUTORS);
                }}
              />
              <h5 className="name-title mt-1 ms-2">{t("Tutor Detail")}</h5>
            </div>
          </div>
        </div>
        {tutorData?.status === VALIDATION_CONSTANT.REJECTED && (
          <div className="button-wrap-td">
            <button className="btn btn-danger rejected-btn">
              {t("Rejected")}
            </button>
          </div>
        )}
        {tutorData?.status === VALIDATION_CONSTANT.APPROVED && (
          <div className="button-wrap-td">
            <button className="btn btn-success accepted-btn">
              {t("Approved")}
            </button>
          </div>
        )}

        <Card className="card-data">
          <div className="row">
            <div className="col-md-5">
              <div className="tutor-image-div">
                <img
                  alt="Learn and Play"
                  className="tutor-profile"
                  src={
                    tutorData?.profile_image
                      ? tutorData?.profile_image
                      : profileImg
                  }
                />
                {/* <p className="tutor-name">{ tutorData?.profile_details.firstname }</p> */}
              </div>
              <div className="row mt-3">
                <label id="course-label-name" className="col-md-6">
                  {t("Name")}:
                </label>
                <p className="col-md-6 values">
                  {tutorData?.profile_details.firstname}
                </p>

                <label id="course-label-name" className="col-md-6">
                  {t("Email")}:
                </label>
                <p className="col-md-6 values value-email">
                  {tutorData?.profile_details.email}
                </p>

                <label id="course-label-name" className="col-md-6">
                  {t("Phone Number")}:
                </label>
                <p className="col-md-6 values">
                  {tutorData?.profile_details.phone_number}
                </p>

                <label id="course-label-name" className="col-md-6">
                  {t("Gender")}:
                </label>
                <p className="col-md-6 values">{tutorData?.gender}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("D.O.B")}:
                </label>
                <p className="col-md-6 values">{tutorData?.dob}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("Educational Qualification")}:
                </label>
                <p className="col-md-6 values">{tutorData?.degree}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("Emigrant ID")}:
                </label>
                <p className="col-md-6 values">{tutorData?.emirates_id}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("Nationality")}:
                </label>
                <p className="col-md-6 values">{tutorData?.nationality ? tutorData?.nationality : "N/A"}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("Address")}:
                </label>
                <p className="col-md-6 values">{tutorData?.address}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("City")}:
                </label>
                <p className="col-md-6 values">{tutorData?.city}</p>

                <label id="course-label-name" className="col-md-6">
                  {t("Pincode")}:
                </label>
                <p className="col-md-6 values">{tutorData?.pincode}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("ID Proof")}:
                </label>
                <div className="descriptive-div col-md-6">
                  <DescriptionIcon
                    className="descriptive-icon"
                    onClick={() => {
                      window.open(tutorData?.emirates_front);
                    }}
                  />
                  {/* <PictureAsPdfIcon className="descriptive-icon"/> */}
                  {/* <FileOpenIcon className="descriptive-icon"/> */}
                </div>

                <label id="course-label-name" className="col-md-6">
                  {t("Curriculam Vitae")}:
                </label>
                <div className="descriptive-div col-md-6">
                  <DescriptionIcon
                    className="descriptive-icon"
                    onClick={() => {
                      window.open(tutorData?.tutor_cv);
                    }}
                  />
                  {/* <PictureAsPdfIcon className="descriptive-icon"/> */}
                  {/* <FileOpenIcon className="descriptive-icon"/> */}
                </div>

              </div>
            </div>
            <div className="col-md-7">
              <div className="row">
                <label id="course-label-name" className="col-md-6">
                  {t("Introduction Video")}:
                </label>
                <div className={tutorData?.intro_video ? "video-wrap col-md-12" :"video-wrap col-md-6"}>
                  {tutorData?.intro_video && (
                    <video className="video-controls" height="200" controls>
                      <source
                        src={tutorData?.intro_video}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  {!tutorData?.intro_video && (
                    <p className="values">{"N/A"}</p>
                  )}
                </div>
                <label id="course-label-name" className="col-md-6">
                  {t("Skills")}:
                </label>
                <p className="col-md-6 values ">{tutorData?.skills?.length ===0 ? "N/A" : 
                tutorData?.skills?.map((skill:string,i:number)=>{
                  return  (<>{skill} {i+1 !==tutorData?.skills.length && ","}</>)
                })
                }</p>

                {/* <label id="course-label-name" className="col-md-6">
                  {t("Tutor Type")}:
                </label>
                <p className="col-md-6 values">
                  {tutorData?.tutor_type ? tutorData?.tutor_type : "N/A"}
                </p> */}

                {/* <label id="course-label-name" className="col-md-6">
                  {t("Teaching Language")}:
                </label>
                <p className="col-md-6 values">{tutorData?.skills}</p> */}

                <label id="course-label-name" className="col-md-6">
                  {t("Approval Status")}:
                </label>
                <p className="col-md-6 values">{tutorData?.status}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("Rating")}:
                </label>
                <p className="col-md-6 values">{tutorData?.rating}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("Bank Name")}:
                </label>
                <p className="col-md-6 values">{tutorData?.bank_name}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("Bank Branch Name")}:
                </label>
                <p className="col-md-6 values">{tutorData?.bank_branch_name}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("Account Holder Name")}:
                </label>
                <p className="col-md-6 values">{tutorData?.account_holder_name}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("Account Number")}:
                </label>
                <p className="col-md-6 values">{tutorData?.bank_ac_number}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("SWIFT code")}:
                </label>
                <p className="col-md-6 values">{tutorData?.bank_ac_swift_code}</p>
                <label id="course-label-name" className="col-md-6">
                  {t("IBAN Number")}:
                </label>
                <p className="col-md-6 values">{tutorData?.bank_iban_number}</p>

                {tutorData?.reason && (
                  <div>
                    <label id="course-label-name" className="col-md-6">
                      {t("Rejected Reason")}:
                    </label>
                    <p className="col-md-6 values">{tutorData?.reason}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default TutorsDetail;
