import { Dialog, DialogContent } from "@mui/material";
import "./FileViewerDialog.scss";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useEffect, useState } from "react";
import { PDF_CDN_LINK } from "../../Utilities/Constants/Constants";
import { Document } from 'react-pdf';
import closeIcons from "../../../Assets/TutorVerification/closeFile.svg";

const FileViewerDialog = (viwerDialogProps: any) => {
  const [isPdf, setIsPdf] = useState(false);
  useEffect(() => {
    if (viwerDialogProps.viewerFile.split(".").slice(-1)[0] == "pdf") {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  }, [viwerDialogProps]);
  return (
    <>
      <Dialog
        onClose={viwerDialogProps.handleClose}
        open={viwerDialogProps.open}
        className={isPdf ? "file_viewer_pdf_wrapper" : "file_viewer_wrapper"}
      >
        <img
          onClick={viwerDialogProps.handleClose}
          className="close_button"
          src={closeIcons}
          alt="Learn and Play"
        />
        {!isPdf && (
          <DialogContent>
            <img
              className="file_image"
              src={viwerDialogProps.viewerFile}
              alt="Learn and Play"
            />
          </DialogContent>
        )}
        {isPdf && (
          <DialogContent>
          <Worker workerUrl={PDF_CDN_LINK.PDF_JS}>
            <Viewer fileUrl={viwerDialogProps.viewerFile} />
           </Worker>
        </DialogContent>
        )}
      </Dialog>
    </>
  );
};
export default FileViewerDialog;
