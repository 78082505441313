import "./NoProducts.scss";
import notingFound from "../../../Assets/global/nothing_found.svg";
import { useTranslation } from "react-i18next";
import { I18_TRANSLATION_COMMON } from "../../Utilities/Constants/Constants";

const NoProducts = () => {
  const { t } = useTranslation(I18_TRANSLATION_COMMON.COMMON);
  return (
    <>
      <div className="empty_image_wrapper">
        <img src={notingFound} alt="Learn and Play" />
        <div className="nothing_found_label">{t('No Results Found!')}</div>
      </div>
    </>
  );
};
export default NoProducts;
